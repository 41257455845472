import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-validator',
  templateUrl: './validator.component.html',
  styleUrls: ['./validator.component.scss']
})
export class ValidatorComponent implements DoCheck {

  @Input()
  public For: AbstractControl|null = null;
  @Input()
  ErrorDefs: any;
  public ErrorMessage: string = '';
  @Input() 
  public MustContain: string[] = [];

  ngDoCheck(): void {
    this.ErrorMessage = '';
    if (this.For == null) {
      return;
    }
    if(this.For.pristine)
      return;

    if (this.For != null && this.For.errors) {
      //reactive forms validation
      for (let prop in this.For.errors) {
        if (this.ErrorMessage != '') this.ErrorMessage += ", ";
        switch (prop) {
          case 'required':
            this.ErrorMessage += "Required";
            break;
          case 'minlength':
            this.ErrorMessage += "Value must be at least " + this.For.errors[prop].requiredLength + " characters";
            break;
          case 'maxlength':
            this.ErrorMessage += "Value must be less than " + this.For.errors[prop].requiredLength + " characters";
            break;
          case 'min':
            this.ErrorMessage += "Value must be at least " + this.For.errors[prop].min;
            break;
          case 'max':
            this.ErrorMessage += "Value must be less than " + this.For.errors[prop].max;
            break;
          case 'email':
            this.ErrorMessage += "must be a valid email address";
            break;
          case 'samepassword':
            this.ErrorMessage += "passwords must match";
            break;
          case 'containsvalues':
            this.ErrorMessage += "Value must contain one of the following: " + this.MustContain.join(", ")
            break;

          default:
            this.ErrorMessage += "Error";
        }
      }
    }
  }

}
