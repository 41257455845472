import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { PermissionsService } from 'src/app/Services/permissions.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionCheckGuard implements CanActivate {
  constructor(private permissionService: PermissionsService, private toastr: ToastrService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      return new Observable<boolean>((observer) => {
        let requiredLevel = 0;
        if(route.data['requiredLevel'] != null) requiredLevel = route.data['requiredLevel'];
        this.permissionService.CheckAdminPermission(route.data['permission'], requiredLevel).then((result:boolean) => {
          if (!result) 
            this.toastr.error("You do not have permission to access this resource", "Error");
          observer.next(result);
          observer.complete();
        });
      });
  }

}
