import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { Busyable } from 'src/app/shared/editors/busyable';

@Component({
  selector: 'app-signdisplayruleset',
  templateUrl: './signdisplayruleset.component.html',
  styleUrls: ['./signdisplayruleset.component.scss']
})
export class SigndisplayrulesetComponent extends Busyable implements OnInit {
  
  public ModelId: any;
  public Filters: any;
  public Model: any;
  DataTypeMap: Map<string, string> = new Map<string, string>();
  
  constructor(private apiService: ApiServiceBase, private parentRoute: ActivatedRoute) {
    super();
    this.DataTypeMap.set('88', 'fa fa-times')
    this.DataTypeMap.set('160', 'fa fa-arrow-up');
    this.DataTypeMap.set('161', 'fa fa-arrow-up-right');
    this.DataTypeMap.set('162', 'fa fa-arrow-right');
    this.DataTypeMap.set('163', 'fa fa-arrow-down-right');
    this.DataTypeMap.set('164', 'fa fa-arrow-down');
    this.DataTypeMap.set('165', 'fa fa-arrow-down-left');
    this.DataTypeMap.set('166', 'fa fa-arrow-left');
    this.DataTypeMap.set('167', 'fa fa-arrow-up-left');
  }

  ngOnInit(): void {
    this.Loading();
    this.parentRoute.params.subscribe(params => { this.ModelId = params['id']; });
    this.apiService.Get<any>("infrastructure/displayrulesets/" + this.ModelId).then(result => {
      this.Model = result;
      this.StopLoading();
    });
  }

  public GetContentIcon(datatype : string) : string|undefined {
    if (datatype == '0'|| datatype == '2')
      return '';
    return this.DataTypeMap.get(datatype);
  }
}
