<div *ngIf="sensorChipId != null" class="field has-addons">
    <div class="control is-expanded">
        <input class="input" [value]="sensorChipId" disabled>

    </div>
    <div class="control">
        <button type="button" class="button is-light" (click)="ClearSensor()">
            <i class="fa fa-trash"></i>
        </button>
    </div>

</div>
<div *ngIf="sensorChipId == null" class="ng-autocomplete position" style="width:100%;" (keypress)="KeyPress($event)">
    <ng-autocomplete [data]="searchResults" [searchKeyword]="searchTerm" placeholder="Select Sensor"
        (selected)='ItemSelected($event)' (inputChanged)='SearchChanged($event)' [itemTemplate]="itemTemplate"
        [notFoundTemplate]="notFoundTemplate" [debounceTime]="300" [isLoading]="isLoading" (inputCleared)="Cleared()">
    </ng-autocomplete>
    <ng-template #itemTemplate let-item>
        <a [innerHTML]="'Chip Id: '+item.ChipId"></a>
    </ng-template>

    <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="notFound"></div>
    </ng-template>
</div>