<app-loader [Parent]="this"></app-loader>
<div *ngIf="FormLoaded">
    <app-pageheader [Parent]="this" [EnableDelete]="true"
        DeleteUrl="/infrastructure/parkinglevels/" [DeleteCheck]="false"
        [DeleteNavLink]='"/parking/parking/lots/" + route.snapshot.params["lotid"]'>
        <form-edit-button class="mr-2">Edit</form-edit-button>
        <form-edit-button routerLink="design/edit" icon="pen-ruler">Designer</form-edit-button>
    </app-pageheader>


    <div class="columns">
        <div class="column is-6">
            <article class="panel">
                <p class="panel-heading">
                    Level {{Model.Level}}
                </p>
                <a class="panel-block">
                    <span class="label">Id</span>
                    <span class="value">{{Model.Id}}</span>
                </a>
                <a class="panel-block">
                    <span class="label">Location</span>
                    <span class="value">{{Model.LocationString}}</span>
                </a>
                <a class="panel-block">
                    <span class="label">Spaces</span>
                    <span class="value">Vacant: {{Model.AvailableSpaces}} Occupied: {{Model.OccupiedSpaces}}
                        ({{Model.TotalSpaces}} total)</span>
                </a>

                <a class="panel-block">
                    <span class="label">Percent Occupied</span>
                    <span class="value">
                        <div [class]="getTextClass()"
                            style="width:7em; margin-right: 10px; vertical-align: middle; text-align:center; height: 1.6em; border-radius:5px; border: 1px solid black; float:left;"
                            [ngStyle]="{'background-color': '#' + Model.ThresholdColor}">{{Model.PercentOccupied}} %
                        </div>
                    </span>
                </a>
                <a class="panel-block empty">
                </a>
                <a class="panel-block empty">
                </a>
                <a class="panel-block empty">
                </a>
                <a class="panel-block empty">
                </a>
            </article>
        </div>
        <div class="column is-6">
            <app-structureviewermaps [StructureId]="Model.Id" [Layers]="StructureViewerLayers"></app-structureviewermaps>
        </div>
    </div>
    <div class="columns">
        <div class="column is-6">
            <app-dashboardbox>
                <app-occupancyserieschart Title="Traffic - Today" [StructureId]="Model.Id" StructureType="parkinglevel"
                    ChartType="line" Period="today" DataType="inflows"></app-occupancyserieschart>
            </app-dashboardbox>
        </div>
        <div class="column is-6">
            <app-dashboardbox>
                <app-occupancyserieschart Title="Traffic - Last 7 Days" [StructureId]="Model.Id"
                    StructureType="parkinglevel" ChartType="bar" Period="last7days" DataType="inflows">
                </app-occupancyserieschart>
            </app-dashboardbox>
        </div>
    </div>

</div>