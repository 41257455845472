<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Title]="Form.get('Name')?.value" [Parent]="this" [Form]="Form">
        <app-form-delete-button *ngIf="ModelIsNew==false" [Parent]="this" [Form]="Form" [Model]="Model" DeleteUrl="parking/pricerules/"
        [DeleteCheck]="true" Api="parking" TypeName="ParkingPriceRule" DeleteNavLink="parking/rates/pricerules">
        </app-form-delete-button>
        <app-form-save-button [Parent]="this" [Form]="Form" class="button is-primary" (OnSave)="Save()">
        </app-form-save-button>
    </app-pageheader>
    <app-field Label="Rule Type" *ngIf="ModelIsNew">
        <div class="control">
            <div class="select">
                <select formControlName="ClassName" (change)="ClassNameChanged()">
                    <option value="LinearPriceRule">Linear</option>
                    <option value="TieredPriceRule">Tiered</option>
                    <option value="TieredPeriodCappedPriceRule">Period Capped Tiered</option>
                    <option value="FlatRatePriceRule">Flat Rate</option>
                    <option value="NoParkingPriceRule">No Parking</option>
                    <option value="FreeParkingPriceRule">Free Parking</option>
                </select>
            </div>
        </div>
    </app-field>
    <app-field Label="Rule Name">
        <div class="control">
            <input formControlName="Name" class="input">
            <app-validator [For]="Form.get('Name')"></app-validator>
        </div>
    </app-field>
    <app-field Label="Color">
        <div class="control">
            <input formControlName="Color" class="input is-shorter " [colorPicker]="Form.get('Color')?.value"
                (colorPickerChange)="Form.get('Color')?.setValue($event)"
                [ngStyle]="{ 'background-color' : Form.get('Color')?.value }" [ngClass]="ClassForColor()">
            <app-validator [For]="Form.get('Color')"></app-validator>
        </div>
    </app-field>
    <app-field Label="Price" [HasAddons]="true" *ngIf="Form.get('ClassName')?.value == 'LinearPriceRule'">
        <div class="control">
            <app-currency-input formControlName="FeePerBlock">
            </app-currency-input>
            <app-validator [For]="Form.get('FeePerBlock')"></app-validator>
        </div>
        <div class="control">
            <a class="button is-light">
                per
            </a>
        </div>
        <div class="control">
            <input formControlName="MinutesPerBlock" class="input" type="number" step="1">
            <app-validator [For]="Form.get('MinutesPerBlock')"></app-validator>
        </div>
        <div class="control">
            <a class="button is-light">
                minutes
            </a>
        </div>
    </app-field>
    <app-field Label="Fee Cap" [HasAddons]="true" *ngIf="Form.get('ClassName')?.value == 'LinearPriceRule' || Form.get('ClassName')?.value == 'TieredPriceRule'">
        <div class="control">
            <app-currency-input formControlName="MaxFee">
            </app-currency-input>
            <app-validator [For]="Form.get('MaxFee')"></app-validator>
        </div>
    </app-field>
    <app-field Label="Fee Cap" [HasAddons]="true" *ngIf="Form.get('ClassName')?.value == 'TieredPeriodCappedPriceRule'">
        <div class="control">
            <app-currency-input formControlName="MaxFee">
            </app-currency-input>
            <app-validator [For]="Form.get('MaxFee')"></app-validator>
        </div>
        <div class="control">
            <a class="button is-light">
                per
            </a>
        </div>
        <div class="control">
            <input formControlName="PeriodQuantity" class="input" type="number" step="1">
            <app-validator [For]="Form.get('PeriodQuantity')"></app-validator>
        </div>
        <div class="control">
            <div class="select">
                <select formControlName="PeriodUnit">
                    <option value="3">Hours</option>
                    <option value="4">Days</option>
                </select>
            </div>
        </div>
    </app-field>
    <app-field Label="Prices" [HasAddons]="true" *ngIf="Form.get('ClassName')?.value == 'TieredPriceRule' || Form.get('ClassName')?.value == 'TieredPeriodCappedPriceRule'">
        <div class="control" formArrayName="RuleLines">
            <div *ngFor="let line of FormArray(Form, 'RuleLines').controls; let i = index">
                <div [formGroupName]="i">
                    <div class="field has-addons">
                        <div class="control">
                            <app-currency-input formControlName="FeePerBlock">
                            </app-currency-input>
                            <app-validator [For]="line.get('FeePerBlock')"></app-validator>

                        </div>
                        <div class="control">
                            <a class="button is-light">
                                per
                            </a>
                        </div>
                        <div class="control">
                            <input formControlName="MinutesPerBlock" class="input" type="number">
                            <app-validator [For]="line.get('MinutesPerBlock')"></app-validator>
                        </div>
                        <div class="control">
                            <a class="button is-light">
                                minutes, max of
                            </a>
                        </div>
                        <div class="control">
                            <input formControlName="MaxMinutes" class="input" type="number" step="1">
                            <app-validator [For]="line.get('MaxMinutes')"></app-validator>
                        </div>
                        <div class="control">
                            <a class="button is-light">
                                minutes
                            </a>
                        </div>
                        <div class="control">
                            <button *ngIf="FormArray(Form, 'RuleLines').controls.length > 1" class="button is-light"
                                (click)="RemoveTieredLine(i)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="button is-primary" (click)="AddTieredLine()">
                Add Line
            </div>
        </div>
    </app-field>
    <app-field *ngIf="Form.get('ClassName')?.value == 'FlatRatePriceRule'" Label="Price">
        <div class="control">
            <app-currency-input formControlName="Fee">
            </app-currency-input>
            <app-validator [For]="Form.get('Fee')"></app-validator>

        </div>
    </app-field>
    <app-field Label="Max Parking Time" *ngIf="Form.get('ClassName')?.value != 'NoParkingPriceRule' && Form.get('ClassName')?.value != 'FreeParkingPriceRule' && Form.get('ClassName')?.value != 'TieredPriceRule' && Form.get('ClassName')?.value != 'TieredPeriodCappedPriceRule'">
        <div class="field has-addons">
            <div class="control">
                <input class="input" formControlName="MaxParkingMinutes" type="number" min="0" />
                <app-validator [For]="Form.get('MaxParkingMinutes')"></app-validator>
                <i style="font-size:small;">Set to 0 for no cap</i>
            </div>
            <div class="control">
                <a class="button is-light">
                    minutes
                </a>
            </div>
        </div>
    </app-field>
</div>