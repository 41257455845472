import { Component, OnInit } from '@angular/core';
import { truncate } from '@turf/turf';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { StructureViewerLayer } from 'src/app/shared/structureviewermaps/structureviewerlayers';

@Component({
  selector: 'app-IRsensorslist',
  templateUrl: './IRsensorslist.component.html',
  styleUrls: ['./IRsensorslist.component.scss']
})
export class IRSensorsListComponent  implements OnInit {

  constructor() { }

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("HID", "ChipId"),
    new Datalistviewcolumn("UniqueName", "UniqueName"),
    new Datalistviewcolumn("IsOccupied", "IsOccupied"),
    new Datalistviewcolumn("Last Check In", "LastCheckedInOnString"),
    new Datalistviewcolumn("Location", "LocationString"),
  ];

  public StructureViewerLayers: StructureViewerLayer[] = [
    new StructureViewerLayer("ParkingLot"), 
    new StructureViewerLayer("ParkingLevel"), 
    new StructureViewerLayer("IRSensor", "Sensors", true),
  ]

  ngOnInit(): void {
  }
}
