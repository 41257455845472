import { Component, Injector, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { UntypedFormArray, } from '@angular/forms';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ModelSelectorComponent } from '../../../../shared/modals/ModelSelector/modelselector.component';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { AddVehicleComponent } from 'src/app/features/users/users/modals/add-vehicle/add-vehicle.component';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { IconService } from 'src/app/Services/icon.service';

@Component({
  selector: 'app-editvaletaddon',
  templateUrl: './editvaletaddon.component.html',
  styleUrls: ['./editvaletaddon.component.scss']
})
export class EditValetAddonComponent extends ModelEditor implements OnInit {
  
  public orgService !: OrganizationsService;
  public IconDisplayName: string | null = null;
  public HasOptions : boolean = false;

  constructor(private modalService: SimpleModalService, private apiService: ApiServiceBase, private injector: Injector, public orgservice: OrganizationsService, public iconService: IconService) {
    super("valet/addons", injector);
    this.orgService = orgservice;
    var symbol = orgservice.OrgCurrencySymbol;
  }

  public override DefaultModel(): any {
    return {
      Id: null,
      IconName: "",
      Color: "000000",
      Name: "",
      Amount: 0.00,
      Options: [],
      ClassName: "BasicValetAddon",
      RequiresExtraTime: false,
      DefaultExtraTime: 0
    }
  }

  public override AfterModelLoaded(): void {
    if(this.Form.get('IconName')?.value){
      this.IconDisplayName = this.iconService.findIconName(this.Form.get('IconName')?.value);
    }

    this.Form.get('IconName')?.valueChanges.subscribe((newValue) => {
      this.IconDisplayName = this.iconService.findIconName(newValue);
    });

    this.HasOptions = (this.Model.ClassName === 'ConfigurableValetAddon');
  }

  public AddOption() {
    let newOption = { Description: "", Amount: 0.00 };
    this.AddToFormArray(this.FormArray(this.Form, "Options"), newOption, "Options");
  }
  public RemoveOption(index: number) {
    this.RemoveFromFormArray(this.FormArray(this.Form, "Options"), index);
  }

  public ToggleType(event: any) {
    this.HasOptions = event.target.checked;
    if(!event.target.checked){
      this.Form.get('ClassName')?.setValue('BasicValetAddon');
      this.Form.get('Amount')?.setValue('0.00');
    }
    else{
      this.Form.get('ClassName')?.setValue('ConfigurableValetAddon');
      this.Form.get('Amount')?.setValue(null);
    }
  }

  public override BeforeSave(): void {

  }

  public override Validators(): any {
  }
}
