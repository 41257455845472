import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { FeaturesService } from 'src/app/Services/features.service';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';

@Component({
  selector: 'app-settingshome',
  templateUrl: './settingshome.component.html',
  styleUrls: ['./settingshome.component.scss']
})
export class SettingshomeComponent extends ModelEditor implements OnInit, Busyable {
  
  public Busyable = new Busyable();
  public HasSettings : boolean = true;

  public DefaultModel() 
  {
    return { Features: [], Settings : []}
  }

  public BeforeSave(): void {
  }

  public Validators() {
  }
  
  override Form !: UntypedFormGroup;
  public features : any[] = [];
  public Settings : any[] = [];
  constructor(private apiService: ApiServiceBase, private toastService: ToastrService, public featuresService: FeaturesService, private injector: Injector) { 
    super("features/installed/settings", injector)
    this.Busyable.Loading();
  }

  public ProcessChildren(children: any){
    children.forEach((child : any) => {
      child.Settings?.forEach((featureSetting: any) => {
        this.Settings.push({Name: featureSetting.SettingPath, Value: featureSetting.Value, Id: featureSetting.Id});  
      })
      if(child.Children != null){
        this.ProcessChildren(child.Children);
      }
    })
  }

  public AfterModelLoaded(): void {
    if(this.Model == null || this.Model.length == 0){
      this.HasSettings = false;
    }
    this.Busyable.StopLoading();
    this.Model.forEach((featuresettings : any) => {
      featuresettings.Settings?.forEach((featureSetting: any) => {
        this.Settings.push({Name: featureSetting.SettingPath, Value: featureSetting.Value, Id: featureSetting.Id});  
      })
      this.ProcessChildren(featuresettings.Children);
    });
  };

  
  public updateSettingValue(item: any){
    var s = this.Settings.find((x:any) => x.Name == item.SettingPath);
    if(s == null){
        this.Settings.push({Name: item.SettingPath, Value: item.Value});
    }
    else{
      s.Value = item.Value;
    }
  }

  public OnSave(){
    this.Busyable.Busy();
    this.apiService.Put("features/installed/settings", {Settings: this.Settings}).then((x: any) => {
      this.toastService.success("Settings Updated Successfully");
      this.Busyable.StopBusy();
    })
  }


  ngAfterLoad(): void{
  }
}
