<app-pageheader [Parent]="this">
  <add-button AddRoute="/permits/permittypes/new/edit"></add-button>
</app-pageheader>

<app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="parking/permittypes/searches"
  [AllowEdit]="true" EditUrl="/permits/permittypes/:id">
  <app-filter-set>
    <div app-search-filter></div>
    <div app-permittypes-filter></div>
    <div app-search-button></div>
  </app-filter-set>
</app-datalistview>