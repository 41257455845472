import { EditcontrolledareagateComponent } from "src/app/features/parking/accesscontrol/controlledareas/edit/editcontrolledareagate/editcontrolledareagate.component";
import { LevelEditorComponent } from "../../leveleditor.component";
import { PolygonMapComponent } from "./PolygonMapComponent";
import { FormArray } from "@angular/forms";
import { Lane } from "./Lane";


export class Gate extends PolygonMapComponent{
    public override ClassName: string = "Gate"
    public override FillColor: string = "#4CA64C";
    public override StrokeColor: string = "#FFFFFF";
    public override StrokeWidth: number = 3;
    public override PatternFill = true;
    public override PatternFillColor = "#4CA64C";
    public override ShowLabel: boolean = true;
    public override zIndex: number = 90;
    public override ChildrenArrays = ["Lanes"];

    constructor(leveleditorcomponent: LevelEditorComponent, canvas: any, formItem: any, parentFormItem: any, forceClone: boolean = false) {
        super(leveleditorcomponent, canvas, formItem, parentFormItem, forceClone);
        this.AddFabricItem();
    }

    public override Clone(formitem: any){
        return new Gate(this.leveleditorcomponent, this.canvas, formitem, this.parentFormItem, true);
    }

    public override ShowModal(): void {
        this.leveleditorcomponent.modalService.addModal(EditcontrolledareagateComponent, { Form: this.formItem })
        .subscribe((result) => {
        });
    }
}