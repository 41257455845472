<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Parent]="this" [Form]="Form" class="button is-primary" (OnSave)="Save()">
        </app-form-save-button>
    </app-pageheader>
    <div>
        <app-field Label="Cluster Design Name">
            <div class="control">
                <input formControlName="Name" type="text" class="input">
            </div>
        </app-field>
    </div>
    <app-tabs [AllowAddRequest]="true" (TabAddRequested)="AddDesign()" [AllowDeleteRequest]="true" (TabDeleteRequested)="DeleteDesign($event)" (SelectedIndexChanged)="DesignIndexChanged">
        <app-tab *ngFor="let d of Designs" [Title]="'&lt;' + d.get('MaxTotalSpaces').value + ' spaces'" >
            <div style="position: relative; min-height: 70vh;">
                <app-clusterdesigner [Parent]="this" [Form]="d"></app-clusterdesigner>
            </div>
        </app-tab>  
    </app-tabs>
    
</div>
