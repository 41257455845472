import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { FeaturesService } from 'src/app/Services/features.service';
import { Busyable } from 'src/app/shared/editors/busyable';

@Component({
  selector: 'app-hardwarehome',
  templateUrl: './hardwarehome.component.html',
  styleUrls: ['./hardwarehome.component.scss']
})

export class HardwarehomeComponent extends Busyable implements OnInit, AfterViewInit {

  public alerts: any = [];
  public HardwareOverview : any | null = null;
  public Percent: number = 0;
  public rightColumnheight: number = 0;

  @ViewChild("leftColumn")
  public leftColumn!: ElementRef;
  public apiService: ApiService;
  public cdr: ChangeDetectorRef;
  public showAlerts: boolean = false;

  constructor(private featuresService: FeaturesService, cdr: ChangeDetectorRef, apiservice: ApiService) { 
    super();
    console.log("Hardware Home Constructor");
    this.cdr = cdr;
    this.apiService = apiservice;
  }

  ngOnInit(): void {
    this.Loading();
    this.apiService.Get<any>("infrastructure/hardware/overview").then(result =>{
      this.HardwareOverview = result;
      this.StopLoading();
      this.showAlerts = true;
    });
  }

  public UpdateColumnHeight(){
    if (this.leftColumn) {
      this.rightColumnheight = this.leftColumn.nativeElement.offsetHeight;
    }
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    this.UpdateColumnHeight();
  }
}
