import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceBase } from 'src/app/Services/api.service.base';

@Component({
  selector: 'app-close-session',
  templateUrl: './close-session.component.html',
  styleUrls: ['./close-session.component.scss']
})
export class CloseSessionComponent extends SimpleModalComponent<{session: any}, null> implements OnInit {
  public form: FormGroup;
  public loading = false;
  private session: any;

  constructor(
    private modalService: SimpleModalService,
    private fb: FormBuilder,
    private apiService: ApiServiceBase,
    private toastService: ToastrService,
  ) {
    super();
    // Format current date and time to match datetime-local input format
    const now = new Date();
    const localDateTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, 16);

    this.form = this.fb.group({
      SessionKey: ['', Validators.required],
      ControlledAreaExitDate: [localDateTime, Validators.required]
    });
  }

  ngOnInit() {
    this.form.patchValue({
      SessionKey: this.session.SessionKey
    });
  }


  public setSession(session: any) {
    this.session = session;
    this.form.patchValue({
      SessionKey: session.SessionKey
    });
  }

  public async confirm() {
    if (this.form.invalid) {
      this.toastService.error('Please fill in all required fields');
      return;
    }

    this.loading = true;
    try {
      const dateString = this.form.get('ControlledAreaExitDate')?.value;
      const date = new Date(dateString);

      const utcDateTicks = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
        date.getUTCDate(), date.getUTCHours(),
        date.getUTCMinutes(), date.getUTCSeconds());

      const utcDate = new Date(utcDateTicks);
      
      this.form.patchValue({
        ControlledAreaExitDate: utcDate.toISOString()
      });
      const response = await this.apiService.Post('parking/parkingsessions', this.form.value);
      if (response) {
        this.toastService.success('Parking session closed successfully');
        this.close();
      }
    } catch (error) {
      this.toastService.error('Failed to close parking session');
    } finally {
      this.loading = false;
    }
  }
}
