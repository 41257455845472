import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { AddsessionmanualpaymentmodalComponent } from './addsessionmanualpaymentmodal.component';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-addsessionmanualpayment',
  templateUrl: './addsessionmanualpayment.component.html',
  styleUrls: ['./addsessionmanualpayment.component.scss']
})
export class AddsessionmanualpaymentComponent implements OnInit {

    public PaymentOptions: any[] = [];
    @Input()
    public Session: any = null;

    public SelectedOption: any = null;

    public Animate : boolean = false;

    constructor(private modalService: SimpleModalService, private route: ActivatedRoute, private apiService: ApiServiceBase, private organizationsService: OrganizationsService, private toastService: ToastrService, private _parent: AddsessionmanualpaymentmodalComponent) {
      apiService.Get<any>("payments/providers/options/manual").then(result => {
        this.PaymentOptions = result;
      });
  }

  public ngOnInit(): void {
    this.apiService.Post<any>("parking/sessions/" + this.Session.Id + "/quote", {}).then(result => {
      this.Session = result;
    });
  }

  public DefaultModel() {
    
  }
  public AfterModelLoaded(): void {
    
  }
  public BeforeSave(): void {
    
  }
  public Validators() {
    
  }

  public confirm() {
    this.Animate = true;
    this.Session.PaidAmount = this.Session.QuotedAmount;
    this.Session.PaymentType = this.SelectedOption.Type;
    this.Session.PaidTo = new Date();
    this.Session.TransactionId = uuidv4();
    this.Session.TransactionReference = "manual payment";

    this.apiService.Post("parking/parkingsessions", this.Session).then(result => {
      this.Animate = false;
      this._parent.close();
    })
  }

}
