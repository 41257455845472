<app-loader [Parent]="this"></app-loader>
<form #form *ngIf="Model != null">
    <app-pageheader [Parent]="this">
        <app-form-delete-button *ngIf="ModelIsNew==false" [Parent]="this" [Model]="Model" DeleteUrl="parking/ratesets/"
        DeleteNavLink="parking/rates/ratesets" [DeleteCheck]="true" Api="parking" TypeName="ParkingRateSet"></app-form-delete-button>
        <app-form-save-button [Parent]="this" [Form]="this.form" (OnSave)="Save()">Save</app-form-save-button>
    </app-pageheader>

    <app-field Label="Name">
        <div class="control">
            <input name="Name" class="input" type="text" [(ngModel)]="Model.Name" required />
            <app-validator [For]="this.form['Name']" [ErrorDefs]="{ 'valueMissing' : 'Name is required' }">
            </app-validator>
        </div>
    </app-field>
    <app-tabs (SelectedIndexChanged)="TabChanged($event)" [AllowInsertRequest]="true"
        (TabInsertRequested)="SplitPeriod($event)">
        <app-tab *ngFor="let p of Grids; let pIndex = index"
            Title="{{p.Period.StartDay}} {{calendarService.GetMonth(p.Period.StartMonth).ShortName}} to {{p.Period.EndDay}} {{calendarService.GetMonth(p.Period.EndMonth).ShortName}}">
           
            <div class="columns is-gapless">
                <div class="column is-10 calendar">
                    <div class="timecolumn">
                        <div class="timelabel" *ngFor="let h of TimeHeaders" [ngStyle]="{ 'height' : SlotHeight + 'px'}">
                            <span class="time">{{h}}</span>
                        </div>
                    </div>

                    <div class="daycolumn" style="position: relative;" *ngFor="let day of p.Days; let dIndex = index"
                        (mousemove)="ColumnMouseMove($event)" (mouseup)="EndDrag($event)" (mouseleave)="CancelDrag($event)">
                        <div class="colhead">
                            <div style="padding-left: 2em; margin-bottom: 4px;">{{day.Header}}
                                <button class="button is-default is-small addsplitbutton" (click)="AddTrack(day)">
                                    <i class="fa fa-plus"></i>
                                    Add Split
                                </button>
                            </div>
                        </div>
                        <div class="columns is-gapless">
                            <div [class]="'column is-' + (12/day.Tracks.length)" style="position: relative; " *ngFor="let track of day.Tracks">
                                <div class="slot" *ngFor="let slot of track.Slots" [ngStyle]="{ 'height' : SlotHeight + 'px'}"
                                    (click)="EmptySlotClicked(slot, track)">
                                </div>
                                <div class="timespan {{t.TextClass}}" *ngFor="let t of track.Timespans; let tIndex = index;"
                                    [ngClass]="{ 'is-dragging': t == CurrentResizeTimeSpan }"
                                    [ngStyle]="{ top: t.Top + 'px', 'height': t.Height + 'px', 'background': t.PriceRuleColor}">
                                    <div class="has-text-centered">
                                        {{t.PriceRuleName}}
                                        <span (click)="DeleteTimespan(track, tIndex)" style="cursor: pointer;">
                                            <i class="fa fa-trash"></i>
                                        </span>
                                    </div>
                                    <div class="has-text-centered">
                                        {{t.StartHour}}:{{(t.StartMinute < 10 ? '0' : '' ) + t.StartMinute}} -
                                            {{t.EndHour}}:{{(t.EndMinute < 10 ? '0' : '' ) + t.EndMinute}} 
                                    </div>
                                    <div class="has-text-centered" tippy="Blocks that follow directly on from the previous block and have the same price rule will be considered a single continuous block. Enabling this option forces this block to not merge with the prior block even if they have the same price rule.">
                                        <input type="checkbox" [(ngModel)]="t.DisableMergePrior" [name]="'DisableMergePrior_'+pIndex+'_'+dIndex+'_'+tIndex" style="vertical-align: middle;">
                                        <label>Do not merge with previous</label>
                                    </div>        
                                    <div class="has-text-centered" tippy="If enabled then the block acts as a 'No Parking' block but will continue to charge any existing sessions according to the selected price rule.">
                                        <input type="checkbox" [(ngModel)]="t.DenyEntry" [name]="'DenyEntry_'+pIndex+'_'+dIndex+'_'+tIndex" style="vertical-align: middle;">
                                        <label>Deny New Entries</label>
                                    </div>        
                                    <div class="draghandlebottom" (mousedown)="StartDrag($event, 'bottom', t, track)"
                                        (mouseup)="EndDrag($event)">
                                    </div>
                                    <div class="draghandletop" (mousedown)="StartDrag($event, 'top', t, track)"
                                        (mouseup)="EndDrag($event)">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-1">
                    </div>
                    <div class="column is-2">
                       
                    </div>

                </div>
                
                <div class="column is-2">
                    <div class="field pl-5">
                            <label class="label">Starts</label>
                        <div class="field-body">
                            <div class="field has-addons">
                                <div class="control">
                                    <div class="select">
                                        <select [name]="'StartDay'+pIndex" [(ngModel)]="p.Period.StartDay"
                                            (change)="PeriodChanged(p.Period, pIndex)" [disabled]="pIndex == 0">
                                            <option
                                                *ngFor="let i of calendarService.GetIntArrayForMonth(p.Period.StartMonth)"
                                                [ngValue]="i">{{i}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="control">
                                    <div class="select">
                                        <select [name]="'StartMonth'+pIndex" [(ngModel)]="p.Period.StartMonth"
                                            (change)="PeriodMonthChanged(p.Period, pIndex)"
                                            [disabled]="pIndex == 0">
                                            <option *ngFor="let i of calendarService.GetMonthArray()"
                                                [ngValue]="i.Index">{{i.Name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="field pl-5">
                            <label class="label">Ends</label>
                        <div class="field-body">
                            <div class="field has-addons">
                                <div class="control">
                                    <div class="select">
                                        <select [name]="'EndDay'+pIndex" [(ngModel)]="p.Period.EndDay"
                                            (change)="PeriodChanged(p.Period, pIndex)">
                                            <option
                                                *ngFor="let i of calendarService.GetIntArrayForMonth(p.Period.EndMonth)"
                                                [ngValue]="i">{{i}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="control">
                                    <div class="select">
                                        <select [name]="'EndMonth'+pIndex" [(ngModel)]="p.Period.EndMonth"
                                            (change)="PeriodMonthChanged(p.Period, pIndex)">
                                            <option *ngFor="let i of calendarService.GetMonthArray()"
                                                [ngValue]="i.Index">{{i.Name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="is-size-4 has-text-centered mt-2">Price Rules</div>
                    <div style="max-height: 30em; overflow-y: scroll;">
                        <table class="table is-striped is-bordered is-fullwidth">
                            <tbody>
                                <tr *ngFor="let p of PriceRules" class="pricerule" (click)="SelectPriceRule(p)"
                                    [ngClass]="{ 'is-selected' : p.Selected }">
                                    <td class="swatch" [ngStyle]="{'background' : p.Color }"></td>
                                    <td class="name">{{p.Name}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </app-tab>
    </app-tabs>
</form>