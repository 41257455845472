import { Component, Input, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { v4 as uuidv4 } from 'uuid';
interface CategoryValue {
  Value: string;
  Description: string;
  Name: string;
  AllowArguments: boolean;
}

@Component({
  selector: 'app-editterminalevents',
  templateUrl: './editterminalevents.component.html',
  styleUrls: ['./editterminalevents.component.scss']
})
export class EditterminaleventsComponent  extends SimpleModalComponent<any, any> {
  
  @Input()
  public Model : any = {};

  @Input()
  public Events : { [key: string]: CategoryValue[] } = {};

  public ExitTypes = [
    { Name : "No Fee Payable", Value: "NoFeePayable" },
    { Name : "Fee Already Paid", Value: "FeePaidPrior" },
    { Name : "Paid at Exit", Value: "FeePaidAtGate" },
    { Name : "Refused to Pay", Value: "FeeDeclinedAtGate" },
    { Name : "Barrier Override", Value: "GateOverride" },
    { Name : "Tailgated", Value: "Tailgated" },
    { Name : "Barrier Override", Value: "GateOverride" },
    { Name : "No Session Found", Value: "SessionNotFound" },
  ];

  public SelectedEvent : any = null;

  public onEventSelected(selection: { event: any }) {
    this.SelectedEvent = {
      Value: selection.event.Value,
      Name: selection.event.Name,
      Description: selection.event.Description,
      AllowArguments: selection.event.AllowArguments
    };
  }

  constructor() {
    super();
  }
  
  public Confirm() {
    this.result = this.Model;
    this.close();
  }

  public DeleteEvent(index : number){
    this.Model.EntryEvents.splice(index, 1);
  }
  public AddSelectedEvent(){
    this.Model.EntryEvents.push({ id : uuidv4(), Event : this.SelectedEvent.Value, AllowArguments: this.SelectedEvent.AllowArguments, Arguments: null })
  }

  public MoveUp(index: number) {
    this.Move(index);
  }

  public MoveDown(index: number) {
    this.Move(index + 1)
  }

  public Move(index: number) {
    let entryEvents = this.Model.EntryEvents;
    if (index <= 0) {
      // this is the top element, return
      return;
    }
    if (index >= entryEvents.length) {
      // try to move the last one down
      return;
    }

    let am = entryEvents[index];
    entryEvents.splice(index, 1);
    entryEvents.splice(index - 1, 0, am);
  }
}