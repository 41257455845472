
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { LevelEditorComponent } from "../../leveleditor.component";
import { PointTool } from "./PointTool";
import { Sign } from "../../components/PointComponents/Sign";


export class SignTool extends PointTool{
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }

    public override CreateNew(canvasX : number, canvasY: number){
        let signs = this.editor.ModelEditor.FormArray(this.editor.Form, "SignConfigurations");
        let newSignFormGroup = new FormGroup({
            Id: new FormControl(null),
            Name: new FormControl("Sign"),
            Mode: new FormControl(0),
            DayBrightness: new FormControl(0),
            NightBrightness: new FormControl(0),
            DisplayRuleSetId: new FormControl(null),
            ChipId: new FormControl(null),
            WakeUpTime: new FormControl(null),
            SleepTime: new FormControl(null),
            StartOfDayTime: new FormControl("00:00:00"),
            EndofDayTime: new FormControl("00:00:00"),
            StructureId: new FormControl(null),
            DisplayAngle: new FormControl(0),
            EditorLocked: new FormControl(false),
            LocationPoints: new FormControl(this.CreatePointFromXY(canvasX, canvasY))
        });

        (signs as FormArray).push(newSignFormGroup);
        var component = new Sign(this.editor, this.canvas, newSignFormGroup, null);
        this.editor.Form.markAsDirty();
        return component;
    }
}