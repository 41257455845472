import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { FormArray, FormControl, UntypedFormArray, } from '@angular/forms';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ModelSelectorComponent } from '../../../../shared/modals/ModelSelector/modelselector.component';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { AddVehicleComponent } from 'src/app/features/users/users/modals/add-vehicle/add-vehicle.component';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { ValetService } from 'src/app/Services/valet.service';
import { MedianewComponent } from 'src/app/shared/forms/inputs/mediaselector/medianew/medianew.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ValetAddonsModalComponent } from 'src/app/shared/modals/valetaddons/valetaddonsmodal.component';

@Component({
  selector: 'app-editvaletsession',
  templateUrl: './editvaletsession.component.html',
  styleUrls: ['./editvaletsession.component.scss']
})
export class EditValetSessionComponent extends ModelEditor implements OnInit {
  
  public orgService !: OrganizationsService;
  public ValetTypes: any = null;
  public SelectedValetType: any = null;
  public RateSets: any[] | null = null;
  public Colors !: any[];
  public Makes !: any[];
  public isDragging: boolean = false;

  public previewFile?: SafeUrl
  public selectedFile?: any;
  public fileInvalid: boolean = false;
  public isImageFile: boolean = true;
  public RequiredFileType: string = 'image/*';
  public OverrideUploadUrl ?: string | null;
  public ImageCategory?: string;
  
  constructor(private modalService: SimpleModalService, private apiService: ApiServiceBase, private injector: Injector, public orgservice: OrganizationsService, public valetService : ValetService, private domSanitizer: DomSanitizer, private toastr: ToastrService, private cdr: ChangeDetectorRef) {
    super("valet/sessions", injector);
    this.orgService = orgservice;

    this.apiService.Get("valet/types").then((result : any) => {
      this.ValetTypes = result;
    });

    this.apiService.Post<any>("parking/ratesets/searches", { PageNumber: 1, PageSize: 9999, Page: 1 })
    .then(result => {
      this.RateSets = result.Results;
    });

    this.apiService.Get('/parking/vehicles/makesandcolors').then((result: any) => {
      this.Colors = result.Colors.filter((x: any) => x.Name != "");
      this.Makes = result.Makes.filter((x: any) => x.Name != "");
      this.StopLoading();
    });

    valetService.ValetSessionChanged.subscribe(result => {
      if(result != null && result != undefined && result.Id == this.Model.Id){
        if(this.Makes?.filter(x => x.Id == result.VehicleMakeId).length == 0){
          this.Makes?.push({Id: result.VehicleMakeId, Name: result.VehicleMakeName});
        }
        if(this.Colors?.filter(x => x.Id == result.VehicleColorId).length == 0){
          this.Colors?.push({Id: result.VehicleColorId, Name: result.VehicleColorName});
        }

        for(let prop in result){
            let formControl = this.Form.get(prop);
            if(formControl != null && !formControl.dirty)
            formControl.setValue(result[prop]);
        }
      }
    });
  }

  selectValetMethod(event: any) {
    var value = event.target.value;
    this.Form.get('ValetTypeId')?.setValue(value);

    var selectedType = this.ValetTypes.filter((x: any) => x.Id == value)[0];

    if(this.Form.get('ValetTypeBillingKeyName')?.value != selectedType.BillingKeyName){
    this.Form.get('ValetTypeBillingKeyName')?.setValue(selectedType.BillingKeyName);
    this.Form.get('BillingKey')?.setValue(null);
    }

    if(selectedType.DefaultParkingRateSetId != null){
      this.Form.get('RateSetId')?.setValue(selectedType.DefaultParkingRateSetId);
    }
    else{
      this.Form.get('RateSetId')?.setValue(null);
    }
  }

  public override DefaultModel(): any {
    return {
      VehicleMakeName: null,
      VehicleColorName: null,
      VehiclePlateNumber: "",
      ValetTypeId: null,
      BillingKey: "",
      PhoneNumber: "",
      FirstName: "",
      LastName: "",
      ValetTypeBillingKeyName: "Billing To",
      Status: "New",
      RateSetId: null,
      TicketNumber: null
    }
  }

  public override AfterModelLoaded(): void {
    this.Model.VehicleMakeName = this.Model.VehicleMakeName?.replaceAll("\u0000", "");
    this.Form.get("VehicleMakeName")?.setValue(this.Model.VehicleMakeName);
  }

  public AddVehicle(){
    this.modalService.addModal(AddVehicleComponent).subscribe(result => {
      console.log(result);
      this.Form.get("VehicleId")?.setValue(result.Id);
      this.Form.get("VehiclePlateNumber")?.setValue(result.PlateNumber);
      this.Form.get("VehicleDescription")?.setValue(result.VehicleMake + " - " + result.VehicleColor);
    })
  };

  public override BeforeSave(): void {

  }

  public override AfterSave(): void {
  }

  public override Validators(): any {
  }
}
