import * as THREE from "three";
import { Scene, Vector2, Vector3 } from "three";

export class SolidWallMesh extends THREE.Mesh {
    constructor(scene : Scene, wall: any, offset: Vector3, height: number, scale: Vector2){
        super();
        let p = wall.PolygonPoints;
        let shape = new THREE.Shape()
        shape.moveTo(p[0][0]*scale.x + offset.x, -1*p[0][1]*scale.y + offset.y);
        for(let i=1; i < p.length; i++){
            shape.lineTo(p[i][0]*scale.x + offset.x, -1*p[i][1]*scale.y + offset.y);
        }
        const extrudeSettings = {
            steps: 2,
            depth: height,
            bevelEnabled: false,
        };
        let geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
        geometry.translate(0,0,offset.z + 0.0001);
        let material = new THREE.MeshBasicMaterial({ color: new THREE.Color("#" + wall.FillColor) });
        this.geometry = geometry;
        this.material = material;
        this.type = "solidwall";
        scene.add(this);
    }
}
