<div class="columns is-narrow" style="position: relative;">
    <div class="column is-1 has-text-right" style="padding-right: 0;">
        <button class="button" (click)="SlideLeft()" [ngStyle]="{ 'height': Height + 'px' }">
            <i class="fa fa-chevron-left"></i>
        </button>
    </div>
    <div class="column is-10" style="padding-left: 0; padding-right: 0; position: relative; overflow: hidden;" #lotviewerparent>
        <canvas #canvas id="canvas" [ngStyle]="{ }">
        </canvas>
        <div *ngFor="let lot of Lots; let index = index;" style="bottom: 25px; position: absolute; background: #eee; border: solid 1px #dbdbdb; border-radius: 10px; text-align: center; padding-left: 1em; padding-right: 1em;" [ngStyle]="{ 'left' : lot.LabelPosition + 'px' }">
            <div>{{lot.Name}}</div>
            <hr style="margin: 2px; border-top: solid 1px #aaa;"/>
            <div style="font-size: 0.5em; color: #aaa;">
                OCCUPANCY
            </div>
            <div>
                {{lot.OccupiedSpaces}}/{{lot.TotalSpaces}}
            </div>
        </div>
    </div>
    <div class="column is-1" style="padding-left: 0;">
        <button class="button" (click)="SlideRight()" [ngStyle]="{ 'height': Height + 'px' }">
            <i class="fa fa-chevron-right"></i>
        </button>
    </div>
</div>
