import { MeshConstants } from "src/app/util/mesh-constants";
import * as THREE from "three";
import { Mesh, Scene, Vector2, Vector3 } from "three";

export class PillarMesh extends THREE.Mesh {
    constructor(scene : Scene, shape: any, offset: Vector3, scale: Vector2){
        super();
        let p = shape.PolygonPoints;
        
        let line = new THREE.Shape()
        line.moveTo(p[0][0] * scale.x + offset.x, -1 * p[0][1] * scale.y + offset.y);
        for (let i = 1; i < p.length; i++) {
            line.lineTo(p[i][0] * scale.x + offset.x, -1 * p[i][1] * scale.y + offset.y);
        }
        line.lineTo(p[0][0] * scale.x + offset.x, -1 * p[0][1] * scale.y + offset.y);

        const extrudeSettings = {
            steps: 1,
            depth: MeshConstants.levelHeight,
            bevelEnabled: false
        };

        let geometry = new THREE.ExtrudeGeometry(line, extrudeSettings);
        const texture = new THREE.TextureLoader().load('/assets/textures/concrete.jpg');
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.offset.set( 0, 0.5 );
        texture.repeat.set( 100,100 );
        texture.needsUpdate = true;

        let material = new THREE.MeshLambertMaterial({ color: 0x555555, map : texture });

        let mesh = new Mesh(geometry, material);
        this.geometry = geometry;
        this.material = material;
        this.material.opacity = 0.4; 
        this.material.transparent = true;
        mesh.translateZ(offset.z+0.0001);
        this.type = "pillar";
        scene.add(mesh);
    }
}
