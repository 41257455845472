
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { v4 as uuidv4 } from 'uuid';
import { DocumentToolBaseComponent } from "./DocumentToolBaseComponent";
import { DocumentEditorComponent } from "../documenteditor.component";
import { FabricHelpers } from "src/app/features/parking/parking/parkinglevels/leveleditor/fabric.js/helpers";
import { Helpers } from "../Helpers/Helpers";
import { RectangleComponent } from "../Components/RectangleComponent";
import { Style } from "../HtmlRenderComponents";


export class DocumentRectangleTool extends DocumentToolBaseComponent{

    public Styles !: Style;
    
    constructor(editor: DocumentEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }

    public override CreateNew(canvasX : number, canvasY: number){
        const adjustedpoints = FabricHelpers.GetAdjustedXY(this.canvas, canvasX, canvasY);

        this.Styles = {
            fontSize: "12px",
            fontFamily: "Arial",
            color: "black",
            left: adjustedpoints[0] - this.editor.offsets.x - (Helpers.convertInchesToPixels("3in")/2),
            top: adjustedpoints[1] - this.editor.offsets.y - (Helpers.convertInchesToPixels("3in")/2),
            width : Helpers.convertInchesToPixels("3in"),
            height: Helpers.convertInchesToPixels("3in"),
            fontWeight: null,
            textAlign: null,
            boxShadow: null,
            borderRadius: 5,
            borderStyle: "1px solid black",
            IsLink: false,
            backgroundColor: '#ffffff',
            textDecoration: null,
            fontStyle: null,
            transform: null
        };

        var component = new RectangleComponent(this.editor, this.Styles);

        this.editor.DocumentComponents.push(component);
        component.Draw(this.canvas);

        this.editor.Form.markAsDirty();
        return component;
    }
}
