import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {

  @Input() 
  public Title: string = "";

  @Input() 
  public MaxHeight: number = 0;
  
  @Input() 
  public Active = false;

  constructor() { }

  ngOnInit(): void {
  }

}
