import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-businessselector',
  templateUrl: './businessselector.component.html',
  styleUrls: ['./businessselector.component.scss']
})
export class BusinessSelectorComponent implements OnInit, ControlValueAccessor {

  public BusinessDirectoryId: string | null = null;
  @Output()
  public BusinessIdChange = new EventEmitter<string | null>();
  public InitialString: string = "";
  @Input()
  public Business: any = { Name: ""};
  public SearchResults: any[] = [];
  public SearchTerm: string = "Name";
  public IsLoading: boolean = false;

  constructor(private apiService: ApiServiceBase, @Self() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
  }

  public writeValue(id: string | null): void {
    this.BusinessDirectoryId = id;
    if (this.BusinessDirectoryId == "") this.Business = null;
  }
  public onChange = (id: string | null) => { };
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public onTouched = () => { };
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }


  public ItemSelected(item: any) {
    if (item && item != null && item.Id) {
      this.Business = { Id: item.Id, Name: item.Name};
      this.BusinessDirectoryId = item.Id;
      this.onChange(item.Id);
      this.BusinessIdChange.emit(this.Business);
    }
  }

  public SearchChanged(val: string) {
    console.log("Business Selector search event: '" + val + "'");
    if (val == null || val == "") return;
    this.Business = null;
    this.IsLoading = true;
    this.apiService.Post<any>("parking/businesses/searches", { PageNumber: 1, PageSize: 8, SearchTerm: val }).then(result => {
      this.SearchResults = result.Results;
      this.IsLoading = false;
    });
  }
  public ClearUser() {
    this.BusinessDirectoryId = null;
    this.onChange(null);
  }
  public Cleared() {
    this.BusinessDirectoryId = null;
    this.onChange(null);
  }
  public KeyPress(evt: any) {
    if (evt.keyCode == 13) {
      evt.stopPropagation();
      console.log("intercepted enter key on user selector");
    }
  }
  public InputKeyPress(evt: any) {

  }

}
