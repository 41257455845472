import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { BusyableModalComponent } from 'src/app/shared/editors/busyableModalComponent';

@Component({
  selector: 'app-addvaletsessionpaymentmodal',
  templateUrl: './addvaletsessionpaymentmodal.component.html',
  styleUrls: ['./addvaletsessionpaymentmodal.component.scss']
})
export class AddValetSessionPaymentModalComponent extends BusyableModalComponent<any, null> implements OnInit {

  public Session: any = null;
  public PaymentValue: number = 0;
  public ValueControl: FormControl = new FormControl(0);
  public ReferenceControl: FormControl = new FormControl("");

  constructor(private modalService: SimpleModalService, private apiService: ApiServiceBase) {
    super();
  }

  ngOnInit(): void {
    this.PaymentValue = this.Session.TotalOutstanding;
    this.ValueControl = new FormControl(this.PaymentValue);
  }

  cancel() {
    this.close()
  }

  addPayment() {
    this.Busy();
    this.apiService.Post("valet/sessions/" + this.Session.Id + "/payments", {"Amount": this.ValueControl.value, "TransactionReference": this.ReferenceControl.value}).then((value: any) => this.close());
  }

}
