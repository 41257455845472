<div class="modal is-active">
    <div class="modal-background">
    </div>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Terminal Config Json</p>
        </header>
        <section class="modal-card-body" style="min-width: 800px;">
            <app-codeeditor Mode="Json" [(Model)]="ModelJson">
            </app-codeeditor>
        </section>
        <footer class="modal-card-foot">
            <button class="button is-success" (click)="confirm()">Save</button>
          </footer>
    </div>
</div>
