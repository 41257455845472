<app-pageheader [Parent]="this">
    <add-button AddRoute="/parking/discounts/codes/new/edit"></add-button>
  </app-pageheader>
  
  <app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="parking/discounts/searches"
    [AllowEdit]="true" EditUrl="/parking/discounts/codes/:id">
    <app-filter-set>
      <div app-search-filter></div>
      <div app-search-button></div>
    </app-filter-set>
  </app-datalistview>