<div class="modal is-active">
    <div class="modal-background" [@modalbackground]="Closing ? 'close' : ''"></div>
    <div class="modal-card" [@modalopen]="Closing ? 'close' : ''">
        <header class="modal-card-head">
            <p class="modal-card-title">Edit Display Rule</p>
        </header>
        <section [formGroup]="Form" class="modal-card-body modal-slide-transition" [ngClass]="Form.get('Mode')?.value == 2 ? 'modal-large' : 'modal-small'">
            <div>
                <div [@leftpanelslide]="DisablePanelAnim && Form.get('Mode')?.value == 1 ? 'biginit' : DisablePanelAnim && Form.get('Mode')?.value == 2 ? 'smallinit' : HalfWidth ? 'half' : 'full'">
                    <app-field Label="Threshold" [HasAddons]="true" class="control mb-3 three-quarters-width">
                        <p class="control">
                            <span class="select">
                                <select formControlName="TargetThresholdProperty">
                                    <option *ngFor="let item of this.ModelProperties" value="{{item.Name}}">{{item.Name}}</option>
                                </select>
                            </span>
                        </p>
                        <p class="control">
                            <span class="select">
                                <select formControlName="TargetThresholdOperator">
                                    <option value="=">=</option>
                                    <option value="<"><</option>
                                    <option value="<="><=</option>
                                    <option value=">">></option>
                                    <option value=">=">>=</option>
                                </select>
                            </span>
                        </p>
                        <p class="control is-expanded">
                            <input formControlName="Threshold" class="input"/>
                        </p>
                    </app-field>
                </div>
                <div class="horizontal-column">
                    <div [@leftpanelslide]="DisablePanelAnim && Form.get('Mode')?.value == 1 ? 'biginit' : DisablePanelAnim && Form.get('Mode')?.value == 2 ? 'smallinit' : HalfWidth ? 'half' : 'full'">
                        <app-field Label="Brightness" class="control mb-3">
                            <input formControlName="Brightness" class="input three-quarters-width"/>
                        </app-field>
                        <app-field Label="Mode" class="control mb-3">
                            <span class="select three-quarters-width">
                                <select formControlName="Mode" (change)="ModeChanged()">
                                    <option value="1">Single Display</option>
                                    <option value="2">Dual Display</option>
                                </select>
                            </span>
                        </app-field>
                    </div>
                    <div *ngIf="Form.get('Mode')?.value == '2'" @rightpanelslide [@.disabled]="DisablePanelAnim" style="width:50%">
                        <app-field [Visible]="Form.get('Mode')?.value == '2'" Label="Split Mode" class="control mb-3">
                            <span class="select three-quarters-width">
                                <select formControlName="SplitMode">
                                    <option value="0">Toggle</option>
                                    <option value="1">Horizontal Split</option>
                                    <option value="2">Vertical Split</option>
                                </select>
                            </span>
                        </app-field>
                        <app-field [Visible]="Form.get('SplitMode')?.value == '0'" Label="Toggle" class="control mb-3">
                            <input formControlName="SecondsBetweenToggling" class="input three-quarters-width"/>
                        </app-field>
                    </div>
                </div>
            </div>
            <div class="signs">
                <div [@leftpaneldisplay]="DisablePanelAnim && Form.get('Mode')?.value == 1 ? 'biginit' : DisablePanelAnim && Form.get('Mode')?.value == 2 ? 'smallinit' : HalfWidth ? 'half' : 'full'" ngClass="sign-content content-alignment-{{Form.get('TextAlignment')?.value}} content-color-{{Form.get('TextColor')?.value}}">
                    <span *ngIf="Form.get('DataType')?.value == 0 || Form.get('DataType')?.value == 2">{{Form.get('Content')?.value}}</span>
                    <span *ngIf="Form.get('DataType')?.value != 0" ngClass="fa {{DataTypeClass}} fa-lg"></span>
                </div>
                <div *ngIf="Form.get('Mode')?.value == '2'" @rightpaneldisplay [@.disabled]="DisablePanelAnim" style="width:50%" ngClass="sign-content content-alignment-{{Form.get('SecondDisplayTextAlignment')?.value}} content-color-{{Form.get('SecondDisplayTextColor')?.value}}">
                    <span *ngIf="Form.get('SecondDisplayDataType')?.value == 0 || Form.get('SecondDisplayDataType')?.value == 2">{{Form.get('SecondDisplayContent')?.value}}</span>
                    <span *ngIf="Form.get('SecondDisplayDataType')?.value != 0" ngClass="fa {{SecondDataTypeClass}} fa-lg"></span>
                </div>
            </div>
            <div class="horizontal-column">
                <div [@leftpanelslide]="DisablePanelAnim && Form.get('Mode')?.value == 1 ? 'biginit' : DisablePanelAnim && Form.get('Mode')?.value == 2 ? 'smallinit' : HalfWidth ? 'half' : 'full'">
                    <app-field Label="Content Type" class="control mb-3">
                        <span class="select three-quarters-width">
                            <select formControlName="DataType" (change)="DataTypeChanged()">
                                <option value="0">Text</option>
                                <option value="2">Rate</option>
                                <option value="88">Cross</option>
                                <option value="160">Up Arrow</option>
                                <option value="161">Up Right Arrow</option>
                                <option value="162">Right Arrow</option>
                                <option value="163">Down Right Arrow</option>
                                <option value="164">Down Arrow</option>
                                <option value="165">Down Left Arrow</option>
                                <option value="166">Left Arrow</option>
                                <option value="167">Up Left Arrow</option>
                            </select>
                        </span>
                    </app-field>
                    <app-field [Visible]="Form.get('DataType')?.value == '0' || Form.get('DataType')?.value == '2'" Label="Content" class="control mb-3">
                        <input formControlName="Content" class="input three-quarters-width"/>
                    </app-field>
                    <app-field Label="Alignment" class="control mb-3">
                        <span class="select three-quarters-width">
                            <select formControlName="TextAlignment">
                                <option value="0">Left Aligned</option>
                                <option value="1">Right Aligned</option>
                                <option value="2">Centered</option>
                            </select>
                        </span>
                    </app-field>
                    <app-field Label="Text Color" class="control mb-3">
                        <span class="select three-quarters-width">
                            <select formControlName="TextColor">
                                <option value="0">Red</option>
                                <option value="1">Green</option>
                                <option value="2">Yellow</option>
                                <option value="3">Blue</option>
                                <option value="4">Purple</option>
                                <option value="5">Cyan</option>
                                <option value="6">White</option>
                            </select>
                        </span>
                    </app-field>
                    <app-field Label="Show Style" class="control mb-3">
                        <span class="select three-quarters-width">
                            <select formControlName="ShowStyle">
                                <option value="0">Static</option>
                                <option value="6">Move Left with Pause</option>
                                <option value="7">Move Right with Pause</option>
                                <option value="8">Move Up with Pause</option>
                                <option value="9">Move Down with Pause</option>
                                <option value="33">Enter From Top Left</option>
                                <option value="34">Enter From Top Right</option>
                                <option value="35">Enter From Bottom Left</option>
                                <option value="36">Enter From Bottom Right</option>
                            </select>
                        </span>
                    </app-field>
                    <app-field [Visible]="Form.get('ShowStyle')?.value != 0" Label="Speed" class="control mb-3">
                        <input formControlName="Speed" class="input three-quarters-width"/>
                    </app-field>
                    <app-field [Visible]="Form.get('ShowStyle')?.value != 0" Label="Stay Time (Seconds)" class="control mb-3">
                        <input formControlName="StayTimeInSeconds" class="input three-quarters-width"/>
                    </app-field>
                </div>
                <div *ngIf="Form.get('Mode')?.value == '2'" @rightpanelslide [@.disabled]="DisablePanelAnim" style="width:50%">
                    <app-field Label="Content Type" class="control mb-3">
                        <span class="select three-quarters-width">
                            <select formControlName="SecondDisplayDataType" (change)="SecondDataTypeChanged()">
                                <option value="0">Text</option>
                                <option value="2">Rate</option>
                                <option value="88">Cross</option>
                                <option value="160">Up Arrow</option>
                                <option value="161">Up Right Arrow</option>
                                <option value="162">Right Arrow</option>
                                <option value="163">Down Right Arrow</option>
                                <option value="164">Down Arrow</option>
                                <option value="165">Down Left Arrow</option>
                                <option value="166">Left Arrow</option>
                                <option value="167">Up Left Arrow</option>
                            </select>
                        </span>
                    </app-field>
                    <app-field [Visible]="Form.get('SecondDisplayDataType')?.value == '0' || Form.get('SecondDisplayDataType')?.value == '2'" Label="Content" class="control mb-3">
                        <input formControlName="SecondDisplayContent" class="input three-quarters-width"/>
                    </app-field>
                    <app-field Label="Alignment" class="control mb-3">
                        <span class="select three-quarters-width">
                            <select formControlName="SecondDisplayTextAlignment">
                                <option value="0">Left Aligned</option>
                                <option value="1">Right Aligned</option>
                                <option value="2">Centered</option>
                            </select>
                        </span>
                    </app-field>
                    <app-field Label="Text Color" class="control mb-3">
                        <span class="select three-quarters-width">
                            <select formControlName="SecondDisplayTextColor">
                                <option value="0">Red</option>
                                <option value="1">Green</option>
                                <option value="2">Yellow</option>
                                <option value="3">Blue</option>
                                <option value="4">Purple</option>
                                <option value="5">Cyan</option>
                                <option value="6">White</option>
                            </select>
                        </span>
                    </app-field>
                    <app-field Label="Show Style" class="control mb-3">
                        <span class="select three-quarters-width">
                            <select formControlName="SecondDisplayShowStyle">
                                <option value="0">Static</option>
                                <option value="6">Move Left with Pause</option>
                                <option value="7">Move Right with Pause</option>
                                <option value="8">Move Up with Pause</option>
                                <option value="9">Move Down with Pause</option>
                                <option value="33">Enter From Top Left</option>
                                <option value="34">Enter From Top Right</option>
                                <option value="35">Enter From Bottom Left</option>
                                <option value="36">Enter From Bottom Right</option>
                            </select>
                        </span>
                    </app-field>
                    <app-field [Visible]="Form.get('SecondDisplayShowStyle')?.value != 0" Label="Speed" class="control mb-3">
                        <input formControlName="SecondDisplaySpeed" class="input three-quarters-width"/>
                    </app-field>
                    <app-field [Visible]="Form.get('SecondDisplayShowStyle')?.value != 0" Label="Stay Time" class="control mb-3">
                        <input formControlName="SecondDisplayStayTimeInSeconds" class="input three-quarters-width"/>
                    </app-field>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button is-success" (click)="confirm()">OK</button>
        </footer>
    </div>
</div>
