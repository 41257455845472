<div class="modal is-active">
    <div class="modal-background">
    </div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Date Range</p>
      </header>
      <section class="modal-card-body" style="min-width: 30em;">
        <input type="datetime-local" (change)="changeStartDate($event)">&#x2192;

        <input type="datetime-local" (change)="changeEndDate($event)">
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" (click)="Confirm()">OK</button>
      </footer>
    </div>
  </div>