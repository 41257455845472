import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { catchError, throwError } from 'rxjs';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { DatalistRawImageColumn, Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { MedianewComponent } from './medianew/medianew.component';

export interface MediaResponse {
  Id: string;
  Name: string;
  BlobName: string
}

export interface MediaSelectorInfo {
  ImageCategory?: string;
}

@Component({
  selector: 'app-mediaselector',
  templateUrl: './mediaselector.component.html',
  styleUrls: ['./mediaselector.component.scss']
})
export class MediaSelectorComponent extends SimpleModalComponent<MediaSelectorInfo, any> implements OnInit {

  public selectedItem?: any;
  Form !: UntypedFormGroup;

  public ImageCategory?: string;

  constructor(public apiService: ApiServiceBase, private simpleModualService: SimpleModalService) {
    super();
  }

  public MediaList: any[] = [
    { Id: 1, Name: "Demo Media", BlobName: "BlobName" }
  ];

  public IsLoaded: boolean = false;
  ngOnInit(): void {

  }

  public Columns: Array<Datalistviewcolumn> = [
    //new Datalistviewcolumn("Id", "Id"),
    new Datalistviewcolumn("Name", "Name"),
    new DatalistRawImageColumn("Preview", "Base64String")
  ];

  public ItemSelected(item: any) {
    this.selectedItem = item;
  }

  confirm() {
    this.result = this.selectedItem.Id + this.selectedItem.Extension;
    this.close();
  }

  addNew() {
    this.simpleModualService.addModal(MedianewComponent, { 
      RequiredFileType: 'image/jpg, image/jpeg, image/png, image/svg+xml, video/mp4, video/webm', 
      ImageCategory: this.ImageCategory }, { closeOnEscape: true, closeOnClickOutside: true })
      .subscribe(newItem => {
        if (newItem != null) {
          this.result = newItem;
          this.close();
        }

      });
  }
}
