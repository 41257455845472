import { Component, EventEmitter, Injector, Input, OnInit, Output, Self, forwardRef } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Color } from 'src/app/util/color';

@Component({
  selector: 'app-colorpicker',
  templateUrl: './colorpicker.component.html',
  styleUrls: ['./colorpicker.component.scss']
})
export class ColorpickerComponent implements OnInit, ControlValueAccessor {

  @Input()
  public ColorValue : string = "ffffff";
  @Output() ColorChanged = new EventEmitter();
  public DisplayValue !: string;

  constructor(private injector: Injector) { 
    const ngControl: NgControl | null = this.injector.get(NgControl, null);
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.GetDisplayValue();
  }

  public GetDisplayValue(){
    this.DisplayValue = this.ColorValue != "" ? '#' + this.ColorValue : "";
  }

  public writeValue(val : string): void {
    this.ColorValue = val;
    this.GetDisplayValue();
  }

  public onChange = (val : string | null) => {};
  public registerOnChange(fn: any): void {
      this.onChange = fn;
  }
  public onTouched = () => {};
  public registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }

  public ValueChanged(evt : string){
    this.ColorValue = evt.substring(1);
    this.GetDisplayValue();
    this.onChange(this.ColorValue);
    this.ColorChanged.emit(this.ColorValue);
  }

  public ManualUpdate(evt: any){
    this.ColorValue = evt.target.value;
    this.GetDisplayValue();
    this.onChange(this.ColorValue);
    this.ColorChanged.emit(this.ColorValue);
  }

  public ClassForColor(){
    return Color.GetVisibleTextClassFor(this.ColorValue);
  }
}
