<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Form]="Form" [Parent]="this" (click)="Save()"></app-form-save-button>
    </app-pageheader>
    <app-validationsummary [Form]="Form"></app-validationsummary>
    <div class="columns">
        <div class="column is-6">
            <article class="panel">
                <p class="panel-heading">
                    Permit Type Details
                </p>
                <div class="panel-block" style="display: block;">
                    <app-field Label="Name" tippy="The name that users will see when buying permits">
                        <p class="control">
                            <input formControlName="Name" class="input" type="text" />
                            <app-validator [For]="this.Form.get('Name')"></app-validator>
                        </p>
                    </app-field>      
                    <app-field Label="Active Period" [HasAddons]="true" tippy="Defines the active period that the permit can be used. Start and end dates can be left blank to indicate they are open-ended.">
                        <div class="control">
                            <input formControlName="ActiveStartDateLocal" class="input" type="date" [readonly]="this.Form.get('PermitsCount')?.value > 0">
                            <app-validator [For]="this.Form.get('ActiveStartDateLocal')"></app-validator> 
                        </div>
                        <div class="control">
                            <button class="button is-static"> to </button>
                        </div>
                        <div class="control">
                            <input formControlName="ActiveEndDateLocal" class="input" type="date" [readonly]="this.Form.get('PermitsCount')?.value > 0">
                            <app-validator [For]="this.Form.get('ActiveEndDateLocal')"></app-validator>
                        </div>
                    </app-field>

                    <app-field Label="Purchase Period" tippy="The period in which users may buy permit of this type. If the purchase period starts prior to the Active Period then permits will be on pre-sale and only usable during the Active Period." [HasAddons]="true" tippy="">
                        <div class="control">            
                            <input formControlName="PurchaseStartDateLocal" class="input" type="date">
                            <app-validator [For]="this.Form.get('PurchaseStartDateLocal')"></app-validator> 

                        </div>
                        <div class="control">
                            <button class="button is-static"> to </button>
                        </div>
                        <div class="control">
                            <input formControlName="PurchaseEndDateLocal" class="input" type="date">
                            <app-validator [For]="this.Form.get('PurchaseEndDateLocal')"></app-validator>
                        </div>
                    </app-field>

                    
                    <app-field Label="Allocation Mode" [HasAddons]="false" tippy="Governs how users will obtain permits. 'User Can Purchase' means users can buy in permit or portal, 'System Administrator Must Grant' means users cannot buy this permit, it must be assigned through this administration panel.">
                        <p class="control select">
                            <select formControlName="PurchasingOption" class="input">
                                    <option value="0">User can Purchase Permit</option>
                                    <option value="1">System Administrator must Grant Permit</option>
                            </select>
                        </p>
                    </app-field> 
         
                    <app-field Label="Maximum Active Permits" tippy="The maximum number of permits available to users, or unlimited if no limit is specified. Once a permit is cancelled it no longer counts towards this limit.">
                        <p class="control">
                            <input formControlName="MaxActivePermits" class="input" type="number" min="0" placeholder="No Limit"  />
                            <app-validator [For]="this.Form.get('MaxActivePermits')"></app-validator>
                        </p>
                    </app-field>  

                    <app-field Label="Limit Usage" [HasAddons]="true" tippy="If specified, this restricts how many times each permit may be used over a given time period.">
                        <p class="control">
                            <input formControlName="MaxUsesPerPeriod" class="input" type="number" min="0" placeholder="No Limit"  />
                            <app-validator [For]="this.Form.get('MaxUsesPerPeriod')"></app-validator>
                        </p>
                        <p class="control select">   
                            <select formControlName="MaxUsePeriod" class="input">
                                    <option value="101">Total</option>
                                    <option value="0">Daily</option>
                                    <option value="1">Weekly</option>
                                    <option value="3">Monthly</option>
                                    <option value="4">Yearly</option>
                            </select>
                        </p>
                    </app-field>   

                    <app-field Label="Assigned Parking Spaces" tippy="If enabled, each permit will be allocated a specific parking space and the permit may only be used for that parking space.">
                        <div class="control">
                            <app-toggleswitch formControlName="LimitToParkingSpace"></app-toggleswitch>
                        </div>
                    </app-field>

                    <app-field Label="Require Registered Vehicles" tippy="If enabled, requires users to enter vehicle License Plate details when buying a permit so that permitted vehicles may be identified by plate.">
                        <div class="control">
                            <app-toggleswitch formControlName="RequireRegisteredVehicles"></app-toggleswitch>
                        </div>
                    </app-field>

                    <app-field Label="Max Vehicles" [HasAddons]="true" *ngIf="this.Form.get('RequireRegisteredVehicles')?.value" tippy="The maximum number of vehicles that can be added to a permit. The cooldown prevents users from changing vehicle details regularly to circumvent the vehicle limit.">
                        <p class="control">
                            <input formControlName="MaxVehicles" class="input" type="number" min="0" placeholder="No Limit"  />
                            <app-validator [For]="this.Form.get('MaxVehicles')"></app-validator>
                        </p>
                        <div class="control">
                            <button class="button is-static"> vehicles max, with a cooldown period of </button>
                        </div>
                         <p class="control">
                            <input formControlName="VehicleModificationTimeout" class="input" type="number" min="0" placeholder="No Limit"  />
                            <app-validator [For]="this.Form.get('VehicleModificationTimeout')"></app-validator>
                        </p>
                        <p class="control">
                            <button class="button is-static"> hrs </button>
                        </p>
                    </app-field>  
                </div>
            </article>
            <article class="panel">
                <div class="panel-heading">User Settings</div>
                <div class="panel-block" style="display:block;" >
                    <app-field Label="Users" tippy="'Single User' means the permit is for one user only. 'Primary User with Shared Users' means the owner pays for the entire permit but may add additional users who can also use the permit. 'Multiple Users' means co-ownership and each user will need to pay a share of the permit price.">
                        <div class="control select">
                            <select class="input" (change)="UpdateUserSetting($event)" [value]="selectedUserType">
                                <option value="SingleUser">Single User</option>
                                <option value="PrimaryUser">Primary User with Shared Users</option>
                                <option value="MultiUser">Multiple Users that Pay Equally</option>
                            </select>
                        </div>
                    </app-field>
                    <div *ngIf="this.Form.get('MultiUser')?.value">
                    <app-field Label="User Limits" [HasAddons]="true" tippy="The minimum and maximum amount of users allowed on permits of this type. A permit will not activate until the minumum threshold has been reached. Each permit must maintain the minumum number of users to stay active.">
                        <div class="control">
                            <input formControlName="MinUsers" class="input" type="number" min="0" placeholder="No Limit"  />
                            <app-validator [For]="this.Form.get('MinUsers')"></app-validator>
                        </div>
                        <div class="control">
                            <button class="button is-static"> to </button>
                        </div>
                        <div class="control">
                            <input formControlName="MaxUsers" class="input" type="number" min="0" placeholder="No Limit"  />
                            <app-validator [For]="this.Form.get('MaxUsers')"></app-validator>
                        </div>
                    </app-field>  
                    <app-field Label="Allow users to leave" *ngIf="this.Form.get('MultiUser')?.value && this.Form.get('SplitPayments')?.value" tippy="If enabled then users may quit a permit. Users that quit no longer count towards the min and max user limits.">
                        <div class="control">
                            <app-toggleswitch formControlName="CanMultiUsersLeave"></app-toggleswitch>
                        </div>
                    </app-field>
                    <app-field Label="Require Confirmations" tippy="If enabled, requires other users in the permit to confirm each time a permit is used. Can be used to ensure carpool style permits are being used appropriately.">
                        <div class="control">
                            <app-toggleswitch formControlName="RequireConfirmations"></app-toggleswitch>
                        </div>
                    </app-field>
                    <app-field Label="Confirmation Distance" *ngIf="this.Form.get('RequireConfirmations')?.value" [HasAddons]="true" tippy="Can be used to ensure that confirming users on a permit are present and not falsely confirming from another location.">
                        <div class="control">
                            <button class="button is-static"> Users must confirm within </button>
                        </div>
                        <div class="control">
                            <input formControlName="MaxConfirmationDistance" class="input" type="number" min="0" placeholder="No Limit"  />
                            <app-validator [For]="this.Form.get('MaxConfirmationDistance')"></app-validator>
                        </div>
                        <div class="control">
                            <button class="button is-static"> {{orgService.KMorMiles()}} </button>
                        </div>
                    </app-field>
                    <app-field *ngIf="this.Form.get('RequireConfirmations')?.value" Label="Suspension" [HasAddons]="true" tippy="If enabled, will automatically suspend permits that fail to receive the required number of confirmations.">
                        <div class="control">
                            <button class="button is-static"> After </button>
                        </div>
                        <p class="control">
                            <input formControlName="MaxNonConfirmingUsers" class="input" type="number" min="0" placeholder="No Limit"  />
                            <app-validator [For]="this.Form.get('MaxNonConfirmingUsers')"></app-validator>
                        </p>
                        <p class="control">
                            <!--Get this value from Organization Settings-->
                            <button class="button is-static"> users fail to confirm on</button>
                        </p>
                        <p class="control">
                            <input formControlName="MaxConfirmationFails" class="input" type="number" min="0" placeholder="No Limit"  />
                            <app-validator [For]="this.Form.get('MaxConfirmationFails')"></app-validator>
                        </p>
                        <div class="control">
                            <button class="button is-static"> occasions </button>
                        </div>
                    </app-field>  
                </div>
                </div>
            </article>
            <article class="panel">
                <div class="panel-heading">Wait List Settings</div>
                <div class="panel-block" style="display:block;" >
                    <app-field Label="Wait list" tippy="If enabled, the permit will still show in the list of available permits even after the Maximum Active Permits limit is reached, and allow users to join a waitlist for when a permit becomes available.">
                        <div class="control">
                            <app-toggleswitch formControlName="EnableWaitlist"></app-toggleswitch>
                        </div>
                    </app-field>
                    <div *ngIf="this.Form.get('EnableWaitlist')?.value">

                        <app-field Label="Invite Timeout" [HasAddons]="true" tippy="The number of hours a users has to buy the allocated permit after it becomes available. After this time the user will be skipped and another user offered the permit.">
                            <div style="max-width: 30%;" class="control">
                                <input formControlName="InviteTimeout" class="input" type="number" min="0" placeholder="No Limit"  />
                                <app-validator [For]="this.Form.get('InviteTimeout')"></app-validator>
                            </div>
                            <div class="control">
                                <button class="button is-static"> hours </button>
                            </div>
                        </app-field>
                        <app-field Label="Timeout Action" tippy="Sets the action if a user fails to buy the allocated permit before the timeout period elapses.">
                            <div class="control select">
                                <select formControlName="TimeoutAction" class="input" (change)="checkTimeoutAction($event)">
                                    <option value="0">Leave User at Front</option>
                                    <option value="1">Send User to Back</option>
                                    <option value="2">Remove User from Queue</option>
                                </select>
                            </div>
                        </app-field>
                        <app-field Label="Invite retries" *ngIf="this.Form.get('TimeoutAction')?.value != 2" [HasAddons]="true" tippy="The number unsuccessful attempts to allocate a permit to a waitlist user before the user is removed from the waitlist.">
                            <div class="control">
                                <button class="button is-static"> Remove users from queue after </button>
                            </div>
                            <div class="control">
                                <input formControlName="MaxInviteAttempts" class="input" type="number" min="0" placeholder="No Limit"  />
                                <app-validator [For]="this.Form.get('MaxInviteAttempts')"></app-validator>
                            </div>
                            <div class="control">
                                <button class="button is-static"> invitations </button>
                            </div>
                        </app-field>  
                    </div>
                </div>
            </article>
        </div>
        <div class="column is-6">
            <article class="panel">
                <div class="panel-heading">Permit Restrictions</div>
                <div class="panel-block" style="display:block;" >
                    <app-field Label="Restrict To User Roles" [HasAddons]="this.Form.get('RestrictToUserRoles')?.value" tippy="Restricts a permit to only users with certain roles. For multi-user and shared permits all users must have the role to join.">
                        <div class="control">
                            <div class="field is-grouped is-grouped-multiline">
                                    <app-toggleswitch formControlName="RestrictToUserRoles"></app-toggleswitch>
                                    <app-selectortaglist *ngIf="this.Form.get('RestrictToUserRoles')?.value" [FollowsCheckbox]="true" formArrayName="ParkingPermitTypeRoleRestrictions" ModelType="Role Restriction" [IsMultiSelect]="true" AddItemApi="organization/roles/search/enduser" itemsDisplayProperty="RoleName" [MapProperties]="[['RoleId', 'Id'], ['RoleName', 'Name']]">
                                    </app-selectortaglist>
                                </div>
                            </div>
                    </app-field>    
                    <app-field Label="Limit To Parking Space Types" [HasAddons]="this.Form.get('LimitToSpaceTypes')?.value" tippy="Restricts use of permits to only the specified types of parking space. If no types are specified then permits may be applied to spaces of any type.">
                        <div class="control">
                            <div class="field is-grouped is-grouped-multiline">
                                <app-toggleswitch formControlName="LimitToSpaceTypes"></app-toggleswitch>
                                <app-selectortaglist *ngIf="this.Form.get('LimitToSpaceTypes')?.value" [FollowsCheckbox]="true" formArrayName="ParkingSpaceTypeRestrictions" ModelType="Parking Space Type" [IsMultiSelect]="true" AddItemApi="infrastructure/spacetypes/searches" itemsDisplayProperty="SpaceTypeName" [MapProperties]="[['SpaceTypeId', 'Id'], ['SpaceTypeName', 'Name']]">
                                </app-selectortaglist>
                            </div>
                        </div>
                    </app-field>
                    <app-field Label="Limited To Parking Structures" [HasAddons]="true" tippy="Restricts use of permits to only spaces within the specified structures. If no structures are specified then permits may be applied to any space, subject to other restrictions.">
                        <div class="control">
                            <div class="field is-grouped is-grouped-multiline">
                                <div class="tags has-addons" *ngFor="let x of this.Form.get('ParkingStructures')?.value; let i = index">
                                  <a class="tag">{{x.LocationString}}</a>
                                </div>
                                <div class="tags has-addons">
                                    <a class="tag button is-primary" (click)="AddParkingStructureRestriction()">Edit</a>
                                </div>
                            </div>
                        </div>
                    </app-field>
                    <app-field Label="Permit Exclusions" [HasAddons]="true" tippy="A user cannot hold this permit type if they hold any permit of an excluded types. Exclusions apply both ways so any excluded type will also have an exclusion against this permit type. ">
                        <div class="control">
                            <app-selectortaglist formArrayName="MutuallyExclusivePermitTypes" ModelType="Permit Type" [IsMultiSelect]="true" AddItemApi="parking/permittypes/searches" itemsDisplayProperty="Name">
                            </app-selectortaglist>
                        </div>
                    </app-field>
                    <app-field Label="Restrict Times of Day" tippy="Restricts the times of day that permits may be used. If a user attempts to use a permit outside these times they will receive an error.">
                        <div class="control">
                            <app-toggleswitch formControlName="RestrictTimesOfDay" (change)="TimesOfDay($event)"></app-toggleswitch>
                        </div>
                    </app-field>
                    <app-field Label="Time Restrictions" *ngIf="this.Form.get('RestrictTimesOfDay')?.value" formArrayName="ParkingPermitTypeDailyUseHours" [HasAddons]="true">
                        <div class="control">
                            <span>
                                <div *ngFor="let price of FormArray(this.Form, 'ParkingPermitTypeDailyUseHours').controls; index as i">
                                    <div [formGroupName]="i" >
                                        <div style="display: flex;">
                                            <div class="control select has-addons">
                                                <select formControlName="DayOfWeek" class="input">
                                                    <option value="0">Sunday</option>
                                                    <option value="1">Monday</option>
                                                    <option value="2">Tuesday</option>
                                                    <option value="3">Wednesday</option>
                                                    <option value="4">Thursday</option>
                                                    <option value="5">Friday</option>
                                                    <option value="6">Saturday</option>
                                                </select>
                                            </div>
                                            <div class="control has-addons">
                                                <input type="time" class="input time" formControlName="StartTime">
                                            </div>
                                            <div class="control has-addons">
                                                <button class="button is-static"> &#x2192; </button>
                                            </div>
                                            <div class="control has-addons">
                                                <input type="time" class="input time" formControlName="EndTime">
                                            </div>
                                            <div class="control has-addons">
                                                <button class="button is-light ng-star-inserted" (click)="RemoveTimeSpan(i)"><i class="fa fa-trash"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <add-button Class="is-small" (AddObject)="AddTimeSpan()"></add-button>
                        </div>
                    </app-field>
                </div>
            </article>
            <article class="panel">
                <div class="panel-heading">Pricing</div>
                <div class="panel-block" style="display:block;" >
                    <app-field Label="One-Off Payment" tippy="Permits may be a one-time purchase or a recurring payment. Recurring payments will bill the selected Credit Card automatically if saved by the user, or ask them to pay again at the end of each period.">
                        <div class="control">
                            <app-toggleswitch formControlName="SinglePayment"></app-toggleswitch>
                        </div>
                    </app-field>
                    <app-field Label="Charge Period" *ngIf="!this.Form.get('SinglePayment')?.value" [HasAddons]="true" tippy="The period for recurring charges on permits. Users will be billed again one period after purchase or last renewal.">
                        <p class="control select">
                            <select formControlName="ChargePeriod" class="input">
                                <option value="0">Daily</option>
                                <option value="1">Weekly</option>
                                <option value="2">2-Weekly</option>
                                <option value="3">Monthly</option>
                                <option value="4">Yearly</option>
                            </select>
                        </p>
                    </app-field>   
                    <app-field Label="Price" *ngIf="this.Form.get('Id')?.value == null" [HasAddons]="true" tippy="The price of the permit. For One-Off Payments, this is the total payable. For recurring payments this is the price per period.">
                        <div class="control">
                            <app-currency-input formControlName="Price">
                            </app-currency-input>
                        </div>
                        <div class="control select">
                            <select class="input" *ngIf="this.Form.get('MultiUser')?.value" [value]="this.Form.get('ChargePerUser')?.value" (change)="updateChargePerUser($event)">
                                <option value="true">Charge Per User</option>
                                <option value="false">Charge Per Permit</option>
                            </select>
                        </div>
                    </app-field>
                    <app-field Label="Prices" formArrayName="Prices" *ngIf="this.Form.get('Id')?.value != null" tippy="The price of the permit. For One-Off Payments, this is the total payable. For recurring payments this is the price per period." [HasAddons]="true">
                        <div class="control">
                            <span>
                                <div *ngFor="let price of FormArray(this.Form, 'Prices').controls; index as i">
                                    <div [formGroupName]="i" >
                                        <div style="display: flex;">
                                            <div class="control has-addons">
                                                <app-currency-input formControlName="Price">
                                                </app-currency-input>
                                            </div>
                                            <div class="control select has-addons">
                                                <select class="input" *ngIf="this.Form.get('MultiUser')?.value" [value]="this.Form.get('ChargePerUser')?.value" (change)="updateChargePerUser($event)" [disabled]="i != 0">
                                                    <option value="true">Charge Per User</option>
                                                    <option value="false">Charge Per Permit</option>
                                                </select>
                                            </div>
                                            <div class="control has-addons">
                                                <button class="button is-static"> from </button>
                                            </div>
                                            <div class="control has-addons">
                                                <input formControlName="EffectiveStartDateLocal" class="input" type="date">
                                            </div>
                                            <div class="control has-addons">
                                                <button class="button is-light ng-star-inserted" [disabled]="price.get('NotificationSent')?.value" (click)="RemovePrice(i)"><i class="fa fa-trash"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <add-button Class="is-small" (AddObject)="AddPrice()"></add-button>
                        </div>
                </app-field>
                <app-field Label="Automatic Removal" [HasAddons]="true" tippy="If specified, permits will be cancelled after the specified number of days of non-payment. For Multi-User permits only the non-paying user will be removed, unless they are the last remaining user in which case the permit will also be cancelled.">
                    <div class="control">
                        <button class="button is-static"> {{(this.Form.get('MultiUser')?.value ? 'Remove users after' : 'Cancel Permit after')}}</button>
                    </div>
                    <div class="control">
                        <input formControlName="DaysUntilCancellation" class="input" type="number" min="0" placeholder="No Limit"  />
                        <app-validator [For]="this.Form.get('DaysUntilCancellation')"></app-validator>
                    </div>
                    <div class="control">
                        <button class="button is-static"> {{((this.Form.get('DaysUntilCancellation')?.value == 1) ? 'day' : 'days')}} of non-payment </button>
                    </div>
                </app-field>  
                    
                </div>
            </article>
        </div>
    </div>
</div>