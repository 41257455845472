import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-editalerttriggerrecipientrole',
  templateUrl: './editalerttriggerrecipientrole.component.html',
  styleUrls: ['./editalerttriggerrecipientrole.component.scss']
})
export class EditalerttriggerrecipientroleComponent extends SimpleModalComponent<any, any> implements OnInit {
  Model: any = {};
  User: any = {};
  public Roles: any[] | null = null;

  public Form = new UntypedFormGroup({
    UserId: new UntypedFormControl(null)
  });

  constructor(private apiService: ApiServiceBase) {
    super();
  }

  public InitialUser = {};

  ngOnInit(): void {
    this.apiService.Post<any>("organization/roles/search", { PageNumber: 1, PageSize: 9999, Page: 1 })
      .then(result => {
        this.Roles = result.Results;
      });
  }

  public RoleChanged() {
    if (this.Roles == null) {
      return;
    }
    let set = this.Roles.filter(x => x.Id == this.Model.Roles)[0];
    this.Model.Name = set.Name;
    this.Model.RecipientRoleId = set.Id;
  }

  confirm() {
    this.result = this.Model;
    this.close();
  }
}

