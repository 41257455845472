
export class Helpers {

    private static dpi = 96;

    public static convertInchesToPixels(inches: string): number {
        if (inches.includes('in')) {
          const inchesValue = parseFloat(inches.replace('in', ''));
          return inchesValue * this.dpi;
        } else {
          return parseFloat(inches);
        }
    }

    public static convertPixelsToInches(pixels: number): number {
      const result = pixels / this.dpi;
      return parseFloat(result.toFixed(3));
  }

    public static convertStyleValue(value: string): number | null {
      if (value.endsWith("in")) {
        return this.convertInchesToPixels(value);
      }
      if (value.endsWith("px")) {
        return parseFloat(value.replace("px", "")); // Replace 'px' with 0
      }
      return parseFloat(value) || null;
    }

    public static isPointInPolygon(point: any, polygon: any) {
      const [x, y] = point;
      let isInside = false;
      const { points } = polygon; // Assuming polygon.points is an array of {x, y} objects
  
      for (let i = 0, j = points.length - 1; i < points.length; j = i++) {
          const xi = points[i].x, yi = points[i].y;
          const xj = points[j].x, yj = points[j].y;
  
          const intersect = ((yi > y) !== (yj > y)) &&
                            (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
          if (intersect) isInside = !isInside;
      }
  
      return isInside;
  }
}