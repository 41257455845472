import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { Busyable } from 'src/app/shared/editors/busyable';
import { DeleteCheckComponent } from 'src/app/shared/modals/delete-check/delete-check.component';
import { MediaModalComponent } from 'src/app/shared/modals/media-modal/media-modal.component';

@Component({
  selector: 'app-media-thumbnail',
  templateUrl: './media-thumbnail.component.html',
  styleUrls: ['./media-thumbnail.component.scss']
})
export class MediaThumbnailComponent implements OnInit {

  @Input()
  public ModelId: any | undefined;
  @Input()
  public DoModal: boolean = true;
  @Input()
  public Title: String = "Image";
  @Input()
  public MediaId: any = "";
  @Input()
  public ModalImageWidth: any = "100%";
  @Input()
  public ModalImageHeight: any = "100%";
  @Input()
  public ThumbnailWidth: any = "50px";
  @Input()
  public ThumbnailHeight: any = "auto";
  @Input()
  public ShowDelete: boolean = false;

  @Output() Deleted = new EventEmitter<any>();

  constructor(private modal: SimpleModalService) { }

  ngOnInit(): void {
  }

  public Delete(){
    this.Deleted.emit(this.ModelId);
  }

  public Clicked() {
    if(this.DoModal)
    this.modal.addModal(MediaModalComponent, { 
        'ModalTitle': this.Title, 
        'MediaId': this.MediaId,
        'Width': this.ModalImageWidth,
        'Height': this.ModalImageHeight
      });
  }
}
