import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';
import { OrganizationsService } from 'src/app/Services/organizations.service';

@Component({
  selector: 'app-editparkinglevel',
  templateUrl: './editparkinglevel.component.html',
  styleUrls: ['./editparkinglevel.component.scss']
})
export class EditparkinglevelComponent extends ModelEditor {
  public ThresholdSets: any[] = [];
  public RateSets: any[] = [];

  constructor(private apiService: ApiService, public route: ActivatedRoute, private injector: Injector) {
    super("infrastructure/parkinglots/" + route.snapshot.params["lotid"] + "/levels", injector);
    this.apiService.Get<any>("infrastructure/thresholdsets").then(result => {
      this.ThresholdSets = result;
    });
    this.apiService.Get<any>("parking/ratesets").then(result => {
      this.RateSets = result;
      if(this.Model != null && this.Model != undefined) {
        this.StopLoading()
      }
    });
  }

  public override DefaultModel(): any {
    return { Name: "New Parking Level", Rows: [], Gates: [], Lanes: []};
  }

  public override AfterSave(): void {

  }

  public MapItems: any[] = [];

  public override AfterModelLoaded(): void {
    for (let r of this.Model.Rows) {
      this.MapItems.push(r.GeoPolygonPoints);
      if (r.Spaces != null) {
        for (let s of r.Spaces) {
          this.MapItems.push(s.GeoPolygonPoints);
        }
      }
    }
  }

  public override BeforeSave(): boolean | void {
  }

  public override Validators(): any {
    return {
      'Name': [Validators.required],
    };
  }

  private externalCountProviders: any[] | null = null;
  private fetchingProviders = false;
  private fetchProvidersFailed = false;

  GetExternalCountProviders() {
    if (this.externalCountProviders == null && !this.fetchingProviders && !this.fetchProvidersFailed) {
      this.fetchingProviders = true;
      this.Loading();
      this.apiService.Get<any>("integrations/countproviders/options").then(result => {
        this.externalCountProviders = result.ProviderOptions;
      }).catch(onreject => 
        { 
          this.fetchProvidersFailed = true;
          this.toast.error("Failed to fetch provider options");
        }
      )
      .finally(() => { this.StopLoading(); this.fetchingProviders = false; });
    }

    return this.externalCountProviders;
  }

  GetExternalCountSources(providerName: string) {
    var provider = this.externalCountProviders?.filter(x => x.Name == providerName)[0];
    return provider?.Options;
  }

}
