import { Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { CalendarService } from 'src/app/Services/calendar.service';
import { ColorService } from 'src/app/Services/color.service';
import { Color } from 'src/app/util/color';
import { MediaService } from 'src/app/Services/media.service';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';

@Component({
  selector: 'app-editcalendardaytype',
  templateUrl: './editcalendardaytype.component.html',
  styleUrls: ['./editcalendardaytype.component.scss']
})

export class EditCalendarDayType extends ModelEditor {

  public TextColor = Color.GetVisibleTextClassFor;

  constructor(public calendarService: CalendarService, private injector: Injector) {
    super("calendar/daytypes", injector);
  }

  public override DefaultModel(): any {
    return {
      Name: "New Calendar Day Type",
      Color: "ffffff",
      Description: "Add a Description"
    };
  }

  public override AfterModelLoaded(): void {
  }

  public override BeforeSave(): void {

  }
  
  public override Validators(): any {
  }

  public ClassForColor() {
    let c = this.Form.get("Color")?.value;
    return Color.GetVisibleTextClassFor(c);
  }
}

