import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { access } from 'fs';

@Component({
  selector: 'app-editcontrolledareaaccessmethod',
  templateUrl: './editcontrolledareaaccessmethod.component.html',
  styleUrls: ['./editcontrolledareaaccessmethod.component.scss']
})
export class EditcontrolledareaaccessmethodComponent extends SimpleModalComponent<any, any> implements OnInit {
  Form !: UntypedFormGroup;

  public RateSets: any[] | null = null;
  public AccessLists: any[] | null = null;
  constructor(private apiService: ApiServiceBase) {
    super();
  }

  ngOnInit(): void {
    this.apiService.Post<any>("parking/ratesets/searches", { PageNumber: 1, PageSize: 9999, Page: 1 })
      .then(result => {
        this.RateSets = result.Results;
      });
    this.apiService.Post<any>("parking/plateaccess/searches", { PageNumber: 1, PageSize: 9999, Page: 1 })
      .then(result => {
        this.AccessLists = result.Results;
      });
  }

  public GetClassName(): any {
    return this.Form.get('ClassName')?.value;
  }

  public ClassChanged() {
    let name = this.GetClassName();
    if (name == 'ControlledAreaAccessMethodWhiteList') {
      let accessListName = this.Form.get('PlateAccessListName');
      if (accessListName == null) {
        this.Form.addControl('PlateAccessListName', new UntypedFormControl());
      }

      let accessListId = this.Form.get('PlateAccessListId');
      if (accessListId == null) {
        this.Form.addControl('PlateAccessListId', new UntypedFormControl());
      }
    }
    if (name == 'ControlledAreaAccessMethodPublic') {
      let accessListName = this.Form.get('PlateAccessListName');
      if (accessListName != null) {
        accessListName.setValue(null);
      }

      let accessListId = this.Form.get('PlateAccessListId');
      if (accessListId != null) {
        accessListId.setValue(null);
      }
    }
  }
  public RateSetChanged() {
    let x = this.Form.get('RateSetName');
    let id = this.Form.get('RateSetId')?.value;
    let set = this.RateSets?.filter(x => x.Id == id)[0];
    x?.setValue(set.Name);
    /*if (this.RateSets == null) {
      return;
    }
    let set = this.RateSets.filter(x => x.Id == this.Model.RateSetId)[0];
    this.Model.RateSetName = set.Name;*/
  }
  public AccessListChanged() {
    let x = this.Form.get('PlateAccessListName');
    let id = this.Form.get('PlateAccessListId')?.value;
    let set = this.AccessLists?.filter(x => x.Id == id)[0];
    x?.setValue(set.Name);
    /*if (this.AccessLists == null) {
      return;
    }
    let set = this.AccessLists.filter(x => x.Id == this.Model.PlateAccessListId)[0];
    this.Model.PlateAccessListName = set.Name;
    */
  }

  confirm() {
    this.close();
  }
}