<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Form]="Form" [Parent]="this" (click)="Save()"></app-form-save-button>
        <span class="mr-3 ml-3">
            <add-button *ngIf="ModelIsNew==false" [AddRoute]="'parking/validation/profiles/' + Model.Id + '/users/new/edit'"></add-button>
        </span>
    </app-pageheader>

    <div class="columns">
        <div class="column is-12">
          <article class="panel">
            <p class="panel-heading">
              Validation Profile Details
            </p>
            <div class="panel-block" style="display: block;">
                <div class="columns">
                    <div class="column is-6">
                        <app-field Label="Name">
                            <p class="control">
                                <input formControlName="Name" class="input is-fullwidth mb-3" type="text" />
                                <app-validator [For]="this.Form.get('Name')"></app-validator>
                            </p>
                        </app-field>
                        <app-field Label="Code Generation Mode" class="control mb-3">
                            <span class="select is-fullwidth">
                                <select formControlName="CodeGenerationMode">
                                    <option value="PerValidation">Per Validation</option>
                                    <option value="Hourly">Hourly</option>
                                    <option value="Daily">Daily</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Manual">Manual</option>
                                </select>
                            </span>
                        </app-field>
                        <app-field Label="Active Overlap Hours">
                            <p class="control">
                                <input formControlName="CodeGenerationOverlapHours" class="input is-fullwidth mb-3" type="number" min="0"/>
                                <app-validator [For]="this.Form.get('CodeGenerationOverlapHours')"></app-validator>
                            </p>
                        </app-field>
                        <app-field Label="Controlled Area" class="control mb-3">
                            <span class="select is-fullwidth">
                                <select formControlName="ControlledAreaId">
                                    <option [ngValue]="null">None</option>
                                    <option *ngFor="let item of ControlledAreas.Results" value="{{item.Id}}">{{item.Name}}</option>
                                </select>
                            </span>
                        </app-field>
                    </div>
                    <div class="column is-6">
                        <app-field Label="Code Template" tippy="How a code should look, use X for placeholder and any other character will be literal">
                            <p class="control">
                                <input formControlName="CodeTemplate" class="input is-fullwidth mb-3" type="text" maxlength="20"/>
                                <app-validator [For]="this.Form.get('CodeTemplate')" [MustContain]="CodeMustContainValues" ></app-validator>
                            </p>
                        </app-field>
                        <app-field Label="Validation Action" class="control mb-3">
                            <span class="select is-fullwidth">
                                <select formControlName="ValidationAction" required>
                                    <option value="ModifyRateSet">Modify Price Set</option>
                                    <option value="ApplyDiscount">Apply Discount</option>
                                </select>
                            </span>
                        </app-field>
                        <app-field [Visible]="this.Form.get('ValidationAction')?.value == 'ModifyRateSet'" Label="Price Set" class="control mb-3">
                            <span class="select is-fullwidth">
                                <select formControlName="ParkingRateSetId" [required]="this.Form.get('ValidationAction')?.value == 'ModifyRateSet'">
                                    <option *ngFor="let item of RateSets.Results" value="{{item.Id}}">{{item.Name}}</option>
                                </select>
                            </span>
                        </app-field>
                        <app-field [Visible]="this.Form.get('ValidationAction')?.value == 'ApplyDiscount'" Label="Discount Type" class="control mb-3">
                            <span class="select is-fullwidth">
                                <select formControlName="DiscountTypeId" [required]="this.Form.get('ValidationAction')?.value == 'ApplyDiscount'">
                                    <option *ngFor="let item of Discounts.Results" value="{{item.Id}}">{{item.Name}}</option>
                                </select>
                            </span>
                        </app-field>
                    </div>
                </div>
            </div>
          </article>
        </div>
    </div>
    <div class="columns">
        <div class="column is-12">
            <article class="panel">
                <p class="panel-heading">
                    Users
                </p>
                <div class="panel-block" style="display: block;">
                    <app-datalistview *ngIf="!ModelIsNew && Model != null && Model.Id != null" [Columns]="Columns" [PageSize]="10"
                        [ShowMap]="false" [ApiUrl]="'parking/validation/profiles/' + Model?.Id + '/users/searches'" [AllowEdit]="true"
                        [EditUrl]="'parking/validation/profiles/' + Model?.Id + '/users/:id'">
                        <app-filter-set>
                            <div app-search-filter></div>
                            <div app-search-button></div>
                        </app-filter-set>
                    </app-datalistview>
                </div>
            </article>
        </div>
    </div>
</div>