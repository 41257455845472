import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { AddUserComponent } from 'src/app/features/users/users/modals/add-user/add-user.component';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ModalBaseComponent } from 'src/app/shared/modals/modal-base/modal-base.component';

@Component({
  selector: 'app-editvalidationuser',
  templateUrl: './editvalidationuser.component.html',
  styleUrls: ['./editvalidationuser.component.scss']
})
export class EditValidationUserComponent extends ModelEditor {

  public InitialUser: any = {};
  public DeleteUrl: String = 'parking/validation/profiles/' + this.route.snapshot.params['listid'] + '/users/';
  public DeleteNavLink: String = "parking/validation/profiles/" + this.route.snapshot.params['listid'];

  public DocumentTemplates: any = [];

  constructor(private route: ActivatedRoute, private modalService: SimpleModalService, private injector: Injector, private apiService: ApiService, private toastService: ToastrService) {
    super("parking/validation/profiles/" + route.snapshot.params['listid'] + "/users", injector);
    this.Loading();
    this.apiService.Get("parking/validation/profiles/" + this.route.snapshot.params['listid']).then((result: any) => {
      if(this.ModelIsNew){
        this.Model.ValidationProfileName = result["Name"];
      }
      this.StopLoading();
    });
    this.apiService.Get("communications/documenttemplates").then((result: any) => {
      this.DocumentTemplates = result;
    });
  }

  public override DefaultModel() {
    return { UserId: null, Name: "", SendToUser: false, SendToEmail: false, EmailAddress: null, DocumentTemplateId: null };
  }
  public override AfterModelLoaded(): void {
    this.InitialUser = { FirstName: this.Model.UserFirstName, LastName: this.Model.UserLastName, Id: this.Model.UserId };
  }
  public override BeforeSave(): boolean | void {
    
  }
  public override Validators() {
    
  }

  public addUser() {
    this.modalService.addModal(AddUserComponent).subscribe(result => {
      console.log(result);
      this.InitialUser = result;
    })
  }

}
