<app-loader [Parent]="this"></app-loader>
<div *ngIf="Model != null">
  <app-pageheader [Parent]="this">
    <form-edit-button>Edit</form-edit-button>
    <app-form-delete-button [Parent]="this" [Model]="Model" DeleteUrl="valet/addons/" Api="valet" TypeName="Addon"
    DeleteNavLink="/valet/addons"></app-form-delete-button>
  </app-pageheader>
  <div class="columns">
    <div class="column is-6">
      <article class="panel">
        <p class="panel-heading">
          Addon Details
        </p>
        <div class="panel-block" style="margin-top:1em; justify-content: center;">
          <div class="bigIcon"><i [ngClass]="'Icon fa fa-' + Model.IconName" style="width:100%em !important" [ngStyle]="{color: '#' + Model.Color}"></i></div>
        </div>
        <div class="panel-block" style="justify-content: center; margin-top:-50px;" [ngStyle]="{color: '#' + Model.Color}"><h1>{{Model.Name}}</h1></div>
        <div class="panel-block" style="justify-content: center;">    <h2>{{Model.Description}} {{Model.RequiresExtraTime ? '(' + Model.DefaultExtraTime + ' minutes)' : ''}}</h2>
        </div>
        <div *ngIf="Model.ClassName == 'ConfigurableValetAddon'" class="panel-block" style="justify-content: center;"><div class="tags" style="display: inline-flex; margin-bottom: 1em;"><div *ngFor="let option of Model.Options" class="tag" >{{option.Description}} {{orgService.OrgCurrencySymbol}}{{option.Amount.toFixed(2)}}</div></div></div>
        <div *ngIf="Model.ClassName == 'BasicValetAddon'" class="panel-block" style="justify-content: center;"><div class="tags" style="display: inline-flex; margin-bottom: 1em;"><div class="tag" >{{orgService.OrgCurrencySymbol}}{{Model.Amount.toFixed(2)}}</div></div></div>

      </article>
    </div>
    <div class="column is-6 image-container">
      <img src="../../../../../assets/images/valet_addon.png" alt="FrogOne Valet Addon" />
    </div>
  </div>
  <div class="columns">
    <div class="column is-12">
      <article class="panel">
        <p class="panel-heading">
          Valet Sessions with Addon
        </p>
        <app-datalistview #DataList [Columns]="Columns" [ShowMap]="false" [ApiUrl]="'valet/addons/' + Model.Id + '/sessions/searches'"
          [AllowEdit]="false" [AllowCustomAction]="true" CustomActionIcon="chevron-right"
          [CustomAction]="CustomAction" [HighLightCondition]="['Open']" [UseQueryParamStates]="false" [ShowIssues]="true" [HideSearch]="true" [AllowDropdownActions]="true" [UseActionsOnEachItem]="true" (HandleDropdownAction)="performAddonAction($event)">
          <app-filter-set>
            <div app-search-filter></div>
            <div app-search-button></div>
          </app-filter-set>
      </app-datalistview>
      </article>
    </div>
  </div>
</div>