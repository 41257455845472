import { DocumentEditorComponent } from "../documenteditor.component";
import { DocumentComponentBase } from "./DocumentComponentBase";
import { Style } from "../HtmlRenderComponents";
import { fabric } from 'fabric';
import { Helpers } from "../Helpers/Helpers";


export class TextComponent extends DocumentComponentBase {

    public Text !: string;
    public IsDataBound : boolean = false;

    public DataType !: string | null;
    public BoundValue !: string | null;
    public DataExpression !: string | null;

    public Href !: string | null;
    public Styles !: Style;

    constructor(documentEditorComponent: DocumentEditorComponent, Styles: Style, Text: string, Href: string | null = null, public dataType: string | null = null, public boundValue: string | null = null,
    public dataExpression: string | null = null) {
        super(documentEditorComponent);

        this.Text = Text;
        this.DataType = dataType;
        this.BoundValue = boundValue;
        this.DataExpression = dataExpression;
        this.Href = Href;
        this.Styles = Styles
    }

    public override RenderHtml() : string {
      let styleString = '';

      if (this.Styles.fontSize) styleString += `font-size: ${this.Styles.fontSize}; `;
      if (this.Styles.fontFamily) styleString += `font-family: ${this.Styles.fontFamily}; `;
      if (this.Styles.color) styleString += `color: ${this.Styles.color}; `;
      if (this.Styles.left !== null) styleString += `left: ${Helpers.convertPixelsToInches(this.Styles.left ?? 0)}in; `;
      if (this.Styles.top !== null) styleString += `top: ${Helpers.convertPixelsToInches(this.Styles.top ?? 0)}in; `;
      if (this.Styles.width !== null) styleString += `width: ${Helpers.convertPixelsToInches(this.Styles.width ?? 0)}in; `;
      if (this.Styles.height !== null) styleString += `height: ${Helpers.convertPixelsToInches(this.Styles.height ?? 0)}in; `;
      if (this.Styles.fontWeight) styleString += `font-weight: ${this.Styles.fontWeight}; `;
      if (this.Styles.textAlign) styleString += `text-align: ${this.Styles.textAlign}; `;
      if (this.Styles.borderStyle) styleString += `border-style: ${this.Styles.borderStyle}; `;
      if (this.Styles.borderRadius !== null) styleString += `border-radius: ${this.Styles.borderRadius}px; `;
      if (this.Styles.boxShadow) styleString += `box-shadow: ${this.Styles.boxShadow}; `;
      if (this.Styles.textDecoration) styleString += `text-decoration: ${this.Styles.textDecoration}; `;
      if (this.Styles.fontStyle) styleString += `font-style: ${this.Styles.fontStyle}; `;
      if (this.Styles.transform) styleString += `transform: (0deg); `;

      styleString += `position: absolute;`

      let htmlString = `<div ` + `${(this.BoundValue ? (`bound-value='${(this.BoundValue)}' `) : '')}`  + `${(this.DataExpression ? (`data-expression='${(this.DataExpression)}' `) : '')}`  + `${(this.DataType ? (`data-type='${(this.DataType)}' `) : '')}` + `style="${styleString}" ${(this.Styles.IsLink ? '><a style="color:' + `${this.Styles.color};"` + 'href="' + this.Href + '"' : '')}>${this.Text}<${(this.Styles.IsLink ? '/a><' : '')}/div>`;
      return htmlString;
  }


  override Draw(canvas: fabric.Canvas){

        // var angle = 0;
        // if(this.Styles.transform){
        //   const angleMatch = this.Styles.transform.match(/rotate\(([-+]?[0-9]*\.?[0-9]+)deg\)/);
        //   if(angleMatch){
        //     angle = parseFloat(angleMatch[1]);
        //     console.log(angle);
        //   }
        // }

        this.fabricItem = new fabric.Textbox(this.Text, {
          left: (this.Styles.left ?? 0) + this.documentEditorComponent.offsets.x,
          top: (this.Styles.top ?? 0) +  this.documentEditorComponent.offsets.y,
          fontSize: parseInt(this.Styles.fontSize ?? ""),
          fontFamily: this.Styles.fontFamily ?? 'Tahoma',
          fill: this.Styles.color ?? 'black', // Set text color
          fontWeight: this.Styles.fontWeight ?? 'normal',
          editable: true,
          textAlign: this.Styles.textAlign ?? 'left',
          underline: this.Styles.IsLink,
          fontStyle: (this.Styles.fontStyle == "normal" ? "normal" : "italic"),
          lockRotation: true
        });


        if(this.Styles.width && (this.Styles.width != 0) ){
          this.fabricItem.set({'width': this.Styles.width})
        }

        this.fabricItem.set({ 'width': this.fabricItem.width }); // Ensure width is set correctly after rendering

        this.fabricItem.on("selected", () => {
          this.documentEditorComponent.selectedProperty = this.BoundValue ?? "";
          this.documentEditorComponent.ItemSelected.emit(this);
       });

       this.fabricItem.on("deselected", () => {
          this.documentEditorComponent.ItemDeselected.emit(this);
        });

        this.fabricItem.on("changed", () => {
            this.Text = (this.fabricItem as fabric.Text).text ?? "";
            // Optionally, trigger change detection or additional updates here
            this.documentEditorComponent.cdr.detectChanges();
            this.documentEditorComponent.RenderHtml();
        });

        this.fabricItem.on("modified", () => {
          // Get the new position of the fabricText object
          const newLeft = this.fabricItem?.left ?? 0;
          const newTop = this.fabricItem?.top ?? 0;
          // this.Styles.transform = 'rotate(' + Math.round(this.fabricText?.angle ?? 0) + 'deg)';
          // console.log(this.Styles.transform);

          if(!Helpers.isPointInPolygon([newLeft, newTop], this.documentEditorComponent.RenderedDocument.Document)){
            this.documentEditorComponent.toastService.info("Element cannot exist out side of document. Reverting move.");
            this.fabricItem?.set({top: ((this.Styles.top ?? 0) + this.documentEditorComponent.offsets.y)});
            this.fabricItem?.set({left:((this.Styles.left ?? 0) + this.documentEditorComponent.offsets.x)});
          }
          else{
            this.Styles.left = (newLeft - this.documentEditorComponent.offsets.x);
            this.Styles.top = (newTop - this.documentEditorComponent.offsets.y);
            this.Styles.width = this.fabricItem?.getScaledWidth() ?? this.Styles.width; // Adjusted for zoom level
            this.Styles.height = this.fabricItem?.getScaledHeight() ?? this.Styles.height; // Adjusted for zoom level
            this.documentEditorComponent.cdr.detectChanges();
            this.documentEditorComponent.RenderHtml();
          }
      });

      canvas.add(this.fabricItem);
    }

    public override Remove(canvas: any){
      canvas.remove(this.fabricItem);
    }

    public override BringToFront(){
      this.fabricItem?.bringToFront();
    }

    public override SendToBack(){
      this.fabricItem?.sendToBack();
    }

    public override BringForward(){
      this.fabricItem?.bringForward();
    }

    public override SendBackward(){
      this.fabricItem?.sendBackwards();
    }
}