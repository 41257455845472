import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { ChartData, ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { ChartDataResponse, ChartResponse } from '../dashboardchart/ChartResponse';
import { Busyable } from '../../editors/busyable';
import { ChartConfiguration } from '../dashboardchart/ChartConfiguration';

interface DateRange {
  start: string;
  end: string;
}

@Component({
  selector: 'app-generic-chart',
  template: ''
})
export class GenericChartComponent extends Busyable {

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  public processedChartData!: ChartData;
  public chartOptions!: ChartOptions;

  public processChartData() {
  }

  protected setupChartOptions() {
  }

  protected createChartOptions() {
  }

  public updateSingleValue(datasetIndex: number, valueIndex: number, newValue: number) {
  }

  public updateChart() {
    if (this.chart) {
      this.chart.update();
    }
  }
}