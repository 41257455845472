import { LevelEditorComponent } from "../../../leveleditor.component";
import { MapItemTool } from "./MapItemTool";

export class GrassIslandTool extends MapItemTool{
    public override ItemWidth: number = 60;
    public override ItemHeight: number = 30;
    public override Type = "GrassIsland";
    public override OutlineColor = "033e12";
    public override Opacity =  1;
    public override FillColor =  "4db35f";
    public override OutlineThickness = 2;
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }
}