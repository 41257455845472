import { LevelEditorComponent } from "../../../leveleditor.component";
import { MapItemTool } from "./MapItemTool";

export class TallTreeTool extends MapItemTool {
    public override ItemWidth: number = 15;
    public override ItemHeight: number = 15;
    public override Type = "TallTree";
    public override OutlineColor = "0d5100";
    public override Opacity =  0.8;
    public override FillColor =  "025818";
    public override OutlineThickness = 2;
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }
}
