<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Parent]="this" [Form]="Form" class="button is-primary" (OnSave)="Save()">
        </app-form-save-button>
    </app-pageheader>
    <div class="columns">
        <div class="column is-6">
            <app-field Label="Name">
                <div class="control">
                    <input formControlName="Name" class="input">
                    <app-validator [For]="Form.get('Name')"></app-validator>
                </div>
            </app-field>
            <app-field Label="Description">
                <div class="control">
                    <input formControlName="Description" class="input">
                    <app-validator [For]="Form.get('Description')"></app-validator>
                </div>
            </app-field>
            <app-field Label="Color">
                <div class="control">
                    <app-colorpicker formControlName="Color"></app-colorpicker>
                </div>
            </app-field>
        </div>
    </div>