import { Component } from '@angular/core';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { StructureViewerLayer } from 'src/app/shared/structureviewermaps/structureviewerlayers';

@Component({
  selector: 'app-spaces',
  templateUrl: './spaces.component.html',
  styleUrls: ['./spaces.component.scss']
})
export class SpacesComponent {
  constructor() { }

  ngOnInit(): void {
  }

  public Columns: Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Id", "IId"),
    new Datalistviewcolumn("Device", "DeviceChipId"),
    new Datalistviewcolumn("Location", "LocationString")
  ];

  public StructureViewerLayers: StructureViewerLayer[] = [
    new StructureViewerLayer("ParkingLot", null, false, true),
    new StructureViewerLayer("ParkingLevel"),
    new StructureViewerLayer("ParkingSpace", "Spaces")
  ];

}
