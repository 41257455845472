import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceBetweenCapitalized'
})
export class SpaceBetweenCapitalizedPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/([A-Z])/g, ' $1').trim();
  }
}
