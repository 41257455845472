import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoginServiceBase } from 'src/app/auth/login.service.base';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { MediaService } from 'src/app/Services/media.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-terminalconfigurations',
  templateUrl: './terminalconfigurations.component.html',
  styleUrls: ['./terminalconfigurations.component.scss']
})
export class TerminalconfigurationsComponent extends Busyable implements OnInit {

  constructor(private apiService: ApiServiceBase, private router: Router, private mediaService: MediaService, public sanitizer: DomSanitizer, private loginService: LoginServiceBase) { super(); }

  public Workflows: any = [];
  public WorkflowThumbnails: any = {};
  private imagePlaceHolder = "https://bulma.io/images/placeholders/1280x960.png";
  ngOnInit(): void {
    this.FetchList();
  }



  public FetchList() {
    this.Loading();

    this.apiService.Get<any>("infrastructure/localcontrollers/workflows").then(result => {
        if (result != null) {
          this.Workflows = result;
          console.log(result);
        }

        this.StopLoading();

      });
  }


  DisplayWorkFlowThumbnail(mediaId: any | null) {

    let thumbnailPath = '<img style="height:9em; width:16em;" src="' + this.imagePlaceHolder + '">';

    if (mediaId != null) {
      thumbnailPath = '<img style="height:9em; width:16em;" src="' + this.mediaService.GetMediaUrl(mediaId) + '">';
    }

    //      <img [src]="WorkflowThumbnails[workflow.Id]" width="256" height="192" alt="Placeholder image">
    return thumbnailPath;
  }
}
