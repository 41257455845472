import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-editalerttriggerrecipientuser',
  templateUrl: './editalerttriggerrecipientuser.component.html',
  styleUrls: ['./editalerttriggerrecipientuser.component.scss']
})
export class EditalerttriggerrecipientuserComponent extends SimpleModalComponent<any, any> implements OnInit {
  Model: any = {};
  User: any = {};

  public Form = new UntypedFormGroup({
    UserId: new UntypedFormControl(null)
  });

  constructor(private apiService: ApiServiceBase) {
    super();
  }

  public InitialUser = {};

  ngOnInit(): void {
  }

  UserIdChange(user: any) {
    var t = user;
    this.Model.RecipientUserId = t.Id;
    this.Model.Name = t.FirstName + " " + t.LastName + " (" + t.Email + ")";
  }

  confirm() {
    this.result = this.Model;
    this.close();
  }
}

