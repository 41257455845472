import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceBase } from './api.service.base';
import { ServiceBusClient} from '@azure/service-bus'
import { OrganizationsService } from './organizations.service';
import { PermissionsService } from './permissions.service';
import { LoginServiceBase } from '../auth/login.service.base';

@Injectable({
  providedIn: 'root'
})
export class OccupancyService {

  private LatestData? : OccupancyTotalsResponse;
  private Lookup : any = {};
  public OccupancyChanged = new EventEmitter<any>();
  public CurrentConnectionString : string = "";
  private Client? : ServiceBusClient;

  constructor(private apiService : ApiServiceBase, private organizationsService : OrganizationsService, private loginService : LoginServiceBase, private permissionService : PermissionsService) { 

    apiService.Get<OccupancyTotalsResponse>("infrastructure/occupancy").then(result => {
      this.Update(result as OccupancyTotalsResponse);
    });
    organizationsService.AvailableOrganizationsChanged.subscribe(result =>{
      this.ConnectServiceBus(); //connect to the bus if we aren't already connected
    });
    loginService.OrganizationChanged.subscribe(result => {
      this.ConnectServiceBus(); //disconnect from previous bus and connect to new org
    });
    this.ConnectServiceBus();
  }

  private Update(data : OccupancyTotalsResponse){
    console.log("Occupancy Service received update, " + data.ParkingLots?.length + " lots, " + data.OccupiedSpaces + " total occupied spaces");
    if(data != null){
      this.LatestData = data;
      if(this.LatestData != null){
        this.Lookup[this.LatestData.Id] = this.LatestData;
      }
      if(this.LatestData?.ParkingLots != null){
        for(let lot of this.LatestData.ParkingLots){
          this.Lookup[lot.Id] = lot;
          if(lot.Levels != null){
            for(let level of lot.Levels){
              this.Lookup[level.Id] = level;
            }
          }
        }
      }
      this.OccupancyChanged.emit(null);
    }
  }

  private ConnectServiceBus(){
    this.organizationsService.GetServiceBusListenerConnectionString().then((x: any) => {
      if(this.CurrentConnectionString == x){
        //same connection as before
        return;
      }
      if(this.Client != null){
        //if we were connected before then close
        this.Client.close();
      }
      if(x == null || x == ''){
        return; //can't connect with no connecton string
      }
  
     this.apiService.Post<any>("infrastructure/servicebus/topics/Occupancy/" + this.loginService.UserId() + this.permissionService.GetSessionIdentifier(), {}).then(result => {
      this.Client = new ServiceBusClient(x);
      const receiver = this.Client.createReceiver("Occupancy", this.loginService.UserId() + this.permissionService.GetSessionIdentifier());
      const SBMessageHandler = async (messageReceived:any) => {
        this.Update(<OccupancyTotalsResponse>(messageReceived.body));
      };
      const SBErrorHandler = async (error:any) => {
        console.log(error);
      };
      receiver.subscribe({
        processMessage: SBMessageHandler,
        processError: SBErrorHandler
      });
     });
    });
  }
  public GetOccupancy(structureId : string | null) : any | null{
    var newpromise = new Promise<any>((resolve, reject) => {
      if(structureId == '' || structureId == null){
        if(this.LatestData != null)
          resolve(this.LatestData);
      }
      if(structureId != null && this.Lookup[structureId] != null){
        resolve(this.Lookup[structureId]);
      }
    });
    return newpromise;
  }
}

export class OccupancyTotalsResponse implements IOccupancyValue{
  
  public Id! : string;
  public Name! : string;
  public TotalSpaces! : number;
  public OccupiedSpaces! : number;
  public AvailableSpaces! : number;
  public ParkingLots? : OccupancyTotalsResponseLot[];
}
export class OccupancyTotalsResponseLot implements IOccupancyValue{
  public Id! : string;
  public Name! : string;
  public TotalSpaces! : number;
  public OccupiedSpaces! : number;
  public AvailableSpaces! : number;
  public Levels? : OccupancyTotalsResponseLevel[];
}
export class OccupancyTotalsResponseLevel implements IOccupancyValue{
  public Id! : string;
  public Name! : string;
  public TotalSpaces! : number;
  public OccupiedSpaces! : number;
  public AvailableSpaces! : number;
}

export interface IOccupancyValue{
  TotalSpaces : number;
  OccupiedSpaces : number;
  AvailableSpaces : number;
}
