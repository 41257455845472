import { AbstractControl, FormGroup } from "@angular/forms";

export class Deviceicons {

    public static GenerateIconName(fg : AbstractControl | any, marker : boolean = false, markerselected : boolean = false) : string{
        if(fg == null)
            return "";
        let className = fg instanceof AbstractControl ? fg.get('ClassName')?.value : fg.ClassName;
        let status = fg instanceof AbstractControl ? fg.get('DeviceStatus')?.value : fg.DeviceStatus;

        let filename = "/assets/deviceicons/" + className;

        if(marker == true){
            if(markerselected == true)
                filename += "_Marker_Selected";
            else
               filename += "_Marker_NotSelected";
        }
        else{
            if(status == "New" || status == "0") status = "Ok";
            filename += "_"+ status;
        }

        return filename + ".svg";
    }
}
