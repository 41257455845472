import { AfterContentInit, AfterViewInit, Component, ContentChild, ContentChildren, Injector, QueryList } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { TabsComponent } from 'src/app/shared/forms/tabs/tabs.component';

@Component({
  selector: 'app-editclusterdesign',
  templateUrl: './editclusterdesign.component.html',
  styleUrls: ['./editclusterdesign.component.scss']
})
export class EditclusterdesignComponent extends ModelEditor {

  public Designs : any | null = null;

  constructor(private injector: Injector) {
    super("infrastructure/clusterdesigns", injector);
  }

  public DefaultModel() {
    return {
      Name : "New Design",
      Designs : [{
          MaxTotalSpaces : 999,
          Elements: [
            {Type: "rect", X: "10", Y: "10", Width: "100", Height: 100, Fill: "000000", StrokeColor: "FF0000", StrokeWidth: 3, Text: "", CornerRadius: "0", FontSize: "10", Opacity: "1", SpaceTypeCountIndex: "0"},
            {Type: "rect", X: "200", Y: "10", Width: "100", Height: 100, Fill: "00FF00", StrokeColor: "000000", StrokeWidth: 3, Text: "", CornerRadius: "0", FontSize: "10", Opacity: "1", SpaceTypeCountIndex: "0"}
          ]
      }]
    };
  }

  public AfterModelLoaded(): void {
    this.Designs = this.FormArray(this.Form, "Designs").controls;
  }
  public BeforeSave(): boolean | void {
  }
  public Validators() {
  }
  public override AfterSave(): void {
    this.Designs = this.FormArray(this.Form, "Designs").controls;
  }

  public AddDesign(){
    this.AddToFormArray(this.FormArray(this.Form, "Designs"), this.DefaultModel().Designs[0], 'Designs');
  }

  public DeleteDesign(index: number){
    let fa = this.FormArray(this.Form, "Designs");
    if(fa.length > 1)
      fa.removeAt(index);
  }

  public DesignIndexChanged(index: number){
  }
}
