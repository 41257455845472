import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import * as THREE from "three";
import { Camera, Vector2, Vector3 } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Geo } from 'src/app/util/geo';
import  * as TWEEN from '@tweenjs/tween.js';
import { LevelMesh } from '../levelmapviewer/geometry/level-mesh';
import { meshanimationresult } from '../levelmapviewer/geometry/meshanimationresult';

@Component({
  selector: 'app-lotviewer',
  templateUrl: './lotviewer.component.html',
  styleUrls: ['./lotviewer.component.scss']
})
export class LotviewerComponent implements AfterViewInit {
 
  @Input()
  public ParkingLotId : string = "";

  @ViewChild("canvas")
  public Canvas!: ElementRef;
  private Camera!: THREE.Camera;
  private TextureLoader!: THREE.TextureLoader;
  private Renderer!: THREE.WebGLRenderer;
  private Scene = new THREE.Scene();
  private Orbit !: OrbitControls;
  private CurrentLevelIndex = 0;
  private Levels : LevelMesh[] = [];
  public mixers !: meshanimationresult[];

  constructor(private apiService : ApiService) { }

  ngAfterViewInit(): void {
    this.Scene.background = new THREE.Color(0x49b6c9);
    this.Renderer = new THREE.WebGLRenderer({ canvas : this.Canvas.nativeElement, antialias: true });
    this.Renderer.setPixelRatio(1);
    this.Renderer.setSize(300,200);

    this.apiService.Get<any>("infrastructure/parkinglots/" + this.ParkingLotId + "/geometry").then(result =>  {
      let level1 = result.Levels[0];
      let levelBounds = Geo.GetPolygonPointBounds(level1);
      let w = levelBounds.Right-levelBounds.Left;
      let x = levelBounds.Left + (w)/2;
      let y = -1*(levelBounds.Top + (levelBounds.Bottom-levelBounds.Top)/2);

      //this.Camera = new THREE.PerspectiveCamera(50, 1, 0.0001, 2000);
      this.Camera = new THREE.OrthographicCamera(0, 0.16, 0.1, -0.12, 0.00001, 2000);

      const controls = new OrbitControls(this.Camera, this.Renderer.domElement);
      this.Camera.up.set( 0, 0, 1 );
      this.Camera.position.x = -0.3;
      this.Camera.position.y = -0.3;
      this.Camera.position.z = 0.3;
      this.Orbit = controls;

      const light = new THREE.PointLight( 0xffffff, 4, 3 );
      light.position.set( -1, 1, 1 );
      this.Scene.add( light );
      const light2 = new THREE.PointLight( 0xffffff, 5, 3 );
      light2.position.set( levelBounds.Right + 1, -1*levelBounds.Bottom - 1, 1 );
      this.Scene.add( light2 );

      if(result.Levels != null && result.Levels.length > 0){
        let bounds = Geo.GetPolygonPointBounds(result.Levels[0].PolygonPoints);
        let scaleX :number = 1, scaleY :number = 1;
        if(bounds.Width > bounds.Height){
          scaleX = (bounds.Height/bounds.Width);
          scaleY = 0.1/bounds.Height;
          scaleX *= scaleY;
        }
        else{
          scaleY = (bounds.Width/bounds.Height);
        }

        for(let i=0; i < result.Levels.length; i++){
          let level = result.Levels[i];
          if(level.PolygonPoints != null && level.PolygonPoints.length > 1){
            let l = new LevelMesh(this.Scene, level, new Vector3(0,0, 0.02 * i), 0.02, new Vector2(scaleX, scaleY), this.Camera, this.mixers, {ShowAccessControl: false, ShowSpaces: true, ShowShapes: true, ShowWalls:true, ShowGardens:false});
            this.Levels.push(l);
          }
        }
      }
      controls.target = new Vector3(.00,.00,0.06);

      this.MainRenderLoop();
    });
  }

  


  private MainRenderLoop(){
    let renderer = this.Renderer;
    let scene = this.Scene;
    let camera = this.Camera;
    let controls = this.Orbit;
    (function render() {
      if(controls)
        controls.update();
      TWEEN.update();
      requestAnimationFrame(render);
      renderer.render(scene, camera);
    }());
  }
}
