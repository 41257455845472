import { Component, Injector, OnInit } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { StructureSelectorComponent } from 'src/app/shared/modals/StructureSelector/structureselector.component';

@Component({
  selector: 'app-editsign',
  templateUrl: './editsign.component.html',
  styleUrls: ['./editsign.component.scss']
})
export class EditsignComponent extends ModelEditor implements OnInit {

  public DisplayRules: any = [];
  private searchResults: any = { PageNumber: 1, PageSize: 0, Results: null };
  public FirmwareVersions: any = [];

  constructor(private apiService: ApiService, public organizationsService: OrganizationsService, private route: ActivatedRoute, private toastService: ToastrService, private modalService: SimpleModalService, private injector: Injector) {
    super("infrastructure/signconfigurations", injector);
    this.apiService.Get("infrastructure/displayrulesets/minimal").then(result => {
      if (result != null){
        this.searchResults = result;
        this.DisplayRules = this.searchResults;
      }
    });
  }
  
  public override DefaultModel() {
    return {};
  }
  public override AfterModelLoaded(): void {
    if(this.Model.Device != null){
      this.apiService.Get("hardware/versions/"+this.Model.Device.HardwareVersionId+"/firmware").then(result => {
        if (result != null) {
          this.FirmwareVersions = result;
        }
      });
    }

    if(this.Form.get("TargetStructures")?.value == null){
      this.Form.get("TargetStructures")?.setValue([]);
    }
  }

  public AddParkingStructure(){
    this.modalService.addModal(StructureSelectorComponent,
      { Structures : this.Form.get('TargetStructures')?.value})
      .subscribe((result: any) => {
        if (result != null) {
          var fa = this.FormArray(this.Form, "TargetStructures");
          fa.clear();
          result.forEach((value : any) => {
            var control = new FormControl(value);
            fa.push(control);
          })
          fa.markAsTouched();
          this.Form.markAsDirty();
        }
      });
  }
  
  public override BeforeSave(): boolean | void {
  }
  public override Validators() {
  }
  
}
