import { EventEmitter, Injectable } from '@angular/core';
import { ServiceBusClient } from '@azure/service-bus';
import { ApiServiceBase } from './api.service.base';
import { OrganizationsService } from './organizations.service';
import { PermissionsService } from './permissions.service';
import { LoginServiceBase } from '../auth/login.service.base';

@Injectable({
  providedIn: 'root'
})
export class ValetService {

  public ValetSessionChanged = new EventEmitter<any>();
  public CurrentConnectionString : string = "";
  private Client? : ServiceBusClient;
  public SessionAddons : any[] = [];
  
    constructor(private apiService : ApiServiceBase, private organizationsService : OrganizationsService, private loginService : LoginServiceBase, private permissionService : PermissionsService) { 
  
      
      organizationsService.AvailableOrganizationsChanged.subscribe(result =>{
        this.ConnectServiceBus(); //connect to the bus if we aren't already connected
      });
      loginService.OrganizationChanged.subscribe(result => {
        this.ConnectServiceBus(); //disconnect from previous bus and connect to new org
      });
      this.ConnectServiceBus();

      this.apiService.Get("valet/addons").then((result : any)=> {
        this.SessionAddons = result;
      })
    }


    public GetSessionAddon(addonId: number){
      return this.SessionAddons.find(Addon => Addon.Id === addonId);
    }

   
    public valetSessionDescription(addonId: number, optionId: number | null = null): string | null {
      const addon = this.SessionAddons.find(Addon => Addon.Id === addonId);
      if (!addon) {
        return null;
      }
      if (optionId === null) {
        return `${addon.Name} (Fixed Amount - ${this.organizationsService.OrgCurrencySymbol}${addon.Amount})`;
      } else {
        const option = addon.Options.find((option: any) => option.Id === optionId);
        if (!option) {
          return null;
        }
        return `${addon.Name} (${option.Description} - ${this.organizationsService.OrgCurrencySymbol}${option.Amount.toFixed(2)})`;
      }
    }
    
  private ConnectServiceBus(){
    this.organizationsService.GetServiceBusListenerConnectionString().then((x: any) => {
      if(this.CurrentConnectionString == x){
        //same connection as before
        return;
      }
      if(this.Client != null){
        //if we were connected before then close
        this.Client.close();
      }
      if(x == null || x == ''){
        return; //can't connect with no connecton string
      }
  
     this.apiService.Post<any>("infrastructure/servicebus/topics/Valet/" + this.loginService.UserId() + this.permissionService.GetSessionIdentifier(), {}).then(result => {
      this.Client = new ServiceBusClient(x);
      const receiver = this.Client.createReceiver("Valet", this.loginService.UserId() + this.permissionService.GetSessionIdentifier());
      const SBMessageHandler = async (messageReceived:any) => {
        this.ValetSessionChanged.emit(<any>messageReceived.body);
      };
      const SBErrorHandler = async (error:any) => {
        console.log(error);
      };
      receiver.subscribe({
        processMessage: SBMessageHandler,
        processError: SBErrorHandler
      });
     });
    });
  }
}
