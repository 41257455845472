import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { EditcustomgroupstructuresComponent } from '../editcustomgroupstructures/editcustomgroupstructures.component';

@Component({
  selector: 'app-editcustomgroup',
  templateUrl: './editcustomgroup.component.html',
  styleUrls: ['./editcustomgroup.component.scss']
})
export class EditcustomgroupComponent extends ModelEditor {

  public PermitTypes: any[] = [];
  constructor(private modalService: SimpleModalService, private injector: Injector) {
    super("infrastructure/customgroups", injector);

  }

  public override DefaultModel(): any {
    return {
      Name: "New Structure Group",
      Structures: []
    }
  }

  public override AfterModelLoaded(): void {
  }

  public override BeforeSave(): void {

  }
  public override Validators(): any {
  }

  public AddStructure(){
    this.modalService.addModal(EditcustomgroupstructuresComponent, {"Structures" :this.Model.Structures })
    .subscribe((result) => {
      result.forEach((x: any) => {
        let structure = { ParkingStructureId: x.ParkingStructureId, ParkingStructureName: x.ParkingStructureName, ParkingStructureLocationString: x.ParkingStructureLocationString};
        this.AddToFormArray(this.FormArray(this.Form, "Structures"), structure, "Structures");
      });
    });
  }

  public removeStructure(index: number) {
    this.RemoveFromFormArray(this.FormArray(this.Form, "Structures"), index);
  }
}
