import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { LoginServiceBase } from '../login.service.base';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent extends SimpleModalComponent<any, any> implements OnInit {

  constructor(private loginService: LoginServiceBase) {
    super();
  }

  ngOnInit() {
    this.loginService.LoginStatusChanged.subscribe(x => {
      if (this.loginService.IsLoggedIn()) {
        this.close();
      }
    });
  }
}