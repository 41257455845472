<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Form]="Form" [Parent]="this" (click)="Save()"></app-form-save-button>
    </app-pageheader>
    <app-validationsummary [Form]="Form"></app-validationsummary>
  <div class="columns">
    <div class="column is-6">
      <article class="panel">
        <p class="panel-heading">
          Discount Type Details
        </p>
        <div class="panel-block" style="display: block;">
          <app-field Label="Name">
              <p class="control">
                  <input formControlName="Name" class="input is-fullwidth" type="text" />
                  <app-validator [For]="this.Form.get('Name')"></app-validator>
              </p>
          </app-field>
          <app-field Label="Active Period" [HasAddons]="true" tippy="Defines the times any code made of this type will expire by. Any codes made with an expiry after or activity before these dates will not work.">
            <div class="control is-fullwidth">
                <input formControlName="ActiveStartDateLocal" class="input" type="date">
                <app-validator [For]="this.Form.get('ActiveStartDateLocal')"></app-validator> 
            </div>
            <div class="control">
                <button class="button is-static"> to </button>
            </div>
            <div class="control">
                <input formControlName="ActiveEndDateLocal" class="input" type="date">
                <app-validator [For]="this.Form.get('ActiveEndDateLocal')"></app-validator>
            </div>
          </app-field>
          <app-field Label="Discount Effect" tippy="When this code is used, how the price will be modified.">
            <div class="control select">
              <select formControlName="ClassName">
                <option value="FreeParkingDiscountType">Free Parking</option>
                <option value="FreeMinutesOffStartDiscountType">Free Minutes</option>
                <option value="PercentageOffDiscountType">Percentage off</option>
                <option value="FixedAmountOffDiscountType">Fixed amount off</option>
              </select>
            </div>
          </app-field> 
          <app-field [HasAddons]="true" Label="Discount Amount" tippy="When this code is used, how much the price will be modified." [Visible]="this.Form.get('ClassName')?.value != 'FreeParkingDiscountType'">
            <div class="control">
              <input *ngIf="this.Form.get('ClassName')?.value == 'PercentageOffDiscountType'" class="input is-short" type="number" min="0" max="100" formControlName="DiscountAmount"/>
              <input *ngIf="this.Form.get('ClassName')?.value == 'FreeMinutesOffStartDiscountType'" class="input is-short" type="number" min="0" formControlName="DiscountAmount"/>
              <app-currency-input *ngIf="this.Form.get('ClassName')?.value == 'FixedAmountOffDiscountType'" formControlName="DiscountAmount"></app-currency-input>
            </div>
            <div class="control">
              <p class="button is-static" *ngIf="this.Form.get('ClassName')?.value == 'FreeMinutesOffStartDiscountType'">minutes</p>
              <p class="button is-static" *ngIf="this.Form.get('ClassName')?.value == 'PercentageOffDiscountType'">% off</p>
              <p class="button is-static" *ngIf="this.Form.get('ClassName')?.value == 'FixedAmountOffDiscountType'">off</p>
            </div>
          </app-field> 
          <app-field Label="Apply Free Minutes" tippy="From the start of paid parking, how should this discount apply?" *ngIf="this.Form.get('ClassName')?.value == 'FreeMinutesOffStartDiscountType'">
            <div class="control select">
              <select formControlName="ApplyToFreeBlocks">
                <option value="true">Always, including already free minutes</option>
                <option value="false">Only apply to paid minutes</option>
              </select>
            </div>
          </app-field>
        </div>
      </article>

      <article class="panel">
        <p class="panel-heading">
          Code Generation Details
        </p>
        <div class="panel-block" style="display: block;">
          <app-field Label="Default Max uses per code" tippy="How many uses a generated code will have by default">
            <div class="control">
              <input class="input is-short" formControlName="DefaultMaxUsesPerCode" type="number" placeholder="No Limit">
            </div>
          </app-field>
          <app-field Label="Code Template" tippy="How a code should look, use X for placeholder and any other character will be literal">
            <div class="control">
              <input class="input is-short" min="4" max="20" formControlName="CodeTemplate">
            </div>
          </app-field>
          <app-field [HasAddons]="true" Label="Default Code Active Duration" tippy="How long a code should be valid for, by default">
            <div class="control">
              <input class="input is-short" min="0" formControlName="DefaultCodeValidTime" type="number" placeholder="No Default Expiry">
            </div>
            <div class="control select">
              <select class="input is-short" formControlName="DefaultCodeValidTimeUnits">
                <option value="1">Minutes</option>
                <option value="2">Hours</option>
                <option value="3">Days</option>
                <option value="4">Weeks</option>
                <option value="5">Months</option>
                <option value="6">Years</option>
              </select>
            </div>
          </app-field>
          <app-field Label="Auto Generate Codes">
            <div class="control">
              <app-toggleswitch formControlName="AutoGenerateCodes"></app-toggleswitch>
            </div>
          </app-field>
          <app-field Label="Time of Day to Generate" tippy="" [Visible]="this.Form.get('AutoGenerateCodes')?.value">
            <div class="control">
              <input type="time" step="3600" class="input time is-short" formControlName="GenerationTime">
            </div>
          </app-field>
          <app-field [HasAddons]="true" Label="How often to generate" tippy="" [Visible]="this.Form.get('AutoGenerateCodes')?.value">
            <div class="control">
              <input class="input" type="number" formControlName="GenerationFrequency" />
            </div>
            <div class="control select">
              <select class="input" formControlName="GenerationFrequencyUnits">
                <option value="2">Hours</option>
                <option value="3">Days</option>
                <option value="4">Weeks</option>
                <option value="5">Months</option>
                <option value="6">Years</option>
              </select>
            </div>
          </app-field>
        </div>
      </article>
    </div>
    <div class="column is-6">
      <article class="panel">
        <p class="panel-heading">
          Discount Restrictions
        </p>
        <div class="panel-block" style="display: block;">
          <app-field Label="Restrict to RateSets">
              <p class="control">
                <app-selectortaglist formArrayName="RateSetRestrictions" ModelType="Rateset" [IsMultiSelect]="true" AddItemApi="parking/ratesets/searches" itemsDisplayProperty="RateSetName" [MapProperties]="[['RateSetId', 'Id'], ['RateSetName', 'Name']]">
                </app-selectortaglist>
              </p>
          </app-field>
          <app-field Label="Limited To Parking Structures" [HasAddons]="true" tippy="Restricts use of permits to only spaces within the specified structures. If no structures are specified then permits may be applied to any space, subject to other restrictions.">
            <div class="control">
                <div class="field is-grouped is-grouped-multiline">
                    <div class="tags has-addons" *ngFor="let x of this.Form.get('ParkingStructures')?.value; let i = index">
                      <a class="tag">{{x.LocationString}}</a>
                    </div>
                    <div class="tags has-addons">
                        <a class="tag button is-primary" (click)="AddParkingStructureRestriction()">Edit</a>
                    </div>
                </div>
            </div>
          </app-field>
          <app-field Label="Time Restrictions" formArrayName="UseHoursRestrictions" [HasAddons]="true">
            <div class="control">
                <span>
                    <div *ngFor="let price of FormArray(this.Form, 'UseHoursRestrictions').controls; index as i">
                        <div [formGroupName]="i" >
                            <div style="display: flex;">
                                <div class="control select has-addons">
                                    <select formControlName="DayOfWeek" class="input">
                                        <option value="0">Sunday</option>
                                        <option value="1">Monday</option>
                                        <option value="2">Tuesday</option>
                                        <option value="3">Wednesday</option>
                                        <option value="4">Thursday</option>
                                        <option value="5">Friday</option>
                                        <option value="6">Saturday</option>
                                    </select>
                                </div>
                                <div class="control has-addons">
                                    <input type="time" class="input time" formControlName="StartTime">
                                </div>
                                <div class="control has-addons">
                                    <button class="button is-static"> &#x2192; </button>
                                </div>
                                <div class="control has-addons">
                                    <input type="time" class="input time" formControlName="EndTime">
                                </div>
                                <div class="control has-addons">
                                    <button class="button is-light ng-star-inserted" (click)="RemoveTimeSpan(i)"><i class="fa fa-trash"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
                <add-button Class="is-small" (AddObject)="AddTimeSpan()"></add-button>
            </div>
        </app-field>
        </div>
      </article>
    </div>
  </div>
</div>