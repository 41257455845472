import { Component, Input, OnInit } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import { ApiService } from 'src/app/Services/api.service';
import { OrganizationsService } from 'src/app/Services/organizations.service';

@Component({
  selector: 'app-percentagechart',
  templateUrl: './percentagechart.component.html',
  styleUrls: ['./percentagechart.component.scss']
})
export class PercentageChartComponent implements OnInit {

  @Input()
  public Percent: number = 0;

  @Input()
  public Height: number = 0;

  @Input()
  public Color: string = 'froggreen';

  constructor(private apiService : ApiService, public organizationService: OrganizationsService) { }

  ngOnInit(): void {
   
  }

}
