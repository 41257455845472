import { AfterContentInit, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MapInfoWindow, MapMarker, MapPolygon } from '@angular/google-maps';
import { LotmultiviewerComponent } from 'src/app/components/lotmultiviewer/lotmultiviewer.component';
import { ApiService } from 'src/app/Services/api.service';
import { FeaturesService } from 'src/app/Services/features.service';
import { OccupancyService } from 'src/app/Services/occupancy.service';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ChartData, ChartOptions } from 'chart.js';
import { Router } from '@angular/router';
import { ChartConfiguration, DateRangeOption, GroupByOption } from 'src/app/shared/charts/dashboardchart/ChartConfiguration';

@Component({
  selector: 'app-parkingstatistics',
  templateUrl: './parkingstatistics.component.html',
  styleUrls: ['./parkingstatistics.component.scss']
})

export class ParkingStatisticsComponent extends Busyable implements OnInit {
  public occupancyService: OccupancyService;
  public apiService: ApiService;
  public router: Router;

  public charts: ChartConfiguration[] = [
    {
      chartType: 'bar',
      stacked: false,
      dataSetRequests: [{
          ChartType: 'bar',
          ReportDateGrouping: 'Concurrent',
          TargetType: 'ParkingSession',
          Label: 'Parking Sessions',
          AggregateType: 'Count',
          Metric: 'ModelId',
          DisplayColor: '#006400'
        },
        {
          ChartType: 'bar',
          ReportDateGrouping: 'Concurrent',
          TargetType: 'SpaceOccupancy',
          Label: 'Occupancies',
          AggregateType: 'Count',
          Metric: 'ModelId',
          DisplayColor: '#19BE19'
        }
      ],
      title: 'Occupancies',
      showFilters: true,
      isFullPage: true,
      groupBy: 'd' as GroupByOption,
      asPercentage: false,
      selectedDateRange: 'thisMonth' as DateRangeOption,
      hideStatistics: false
    },
    {
      chartType: 'line',
      stacked: false,
      dataSetRequests: [{
          ChartType: 'line',
          TargetType: 'ParkingSession',
          Label: 'Parking Sessions',
          ReportDateGrouping: 'Concurrent',
          AggregateType: 'Sum',
          Metric: 'ParkingLotUtilization',
          DisplayColor: '#006400'
          },
        {
          ChartType: 'line',
          TargetType: 'SpaceOccupancy',
          Label: 'Occupancies',
          ReportDateGrouping: 'Concurrent',
          AggregateType: 'Sum',
          Metric: 'ParkingLotUtilization',
          DisplayColor: '#19BE19'
        }
      ],
      title: 'Utilization',
      showFilters: true,
      isFullPage: true,
      groupBy: 'd' as GroupByOption,
      asPercentage: true,
      selectedDateRange: 'thisMonth' as DateRangeOption,
      hideStatistics: false
    },
    {
      chartType: 'line',
      stacked: false,
      dataSetRequests: [{
        ChartType: 'line',
        ReportDateGrouping: 'Concurrent',
        TargetType: 'ParkingSession',
        Label: 'Parking Sessions',
        AggregateType: 'None',
        Metric: 'Turnover',
        DisplayColor: '#006400',
        SplitBy: 'ParkingLotId'
        },
        {
          ChartType: 'line',
          TargetType: 'SpaceOccupancy',
          Label: 'Occupancies',
          AggregateType: 'None',
          Metric: 'Turnover',
          ReportDateGrouping: 'Concurrent',
          DisplayColor: '#19BE19',
          SplitBy: 'ParkingLotId'
        },
      ],
      title: 'Turnover',
      showFilters: true,
      isFullPage: true,
      groupBy: 'd' as GroupByOption,
      asPercentage: false,
      selectedDateRange: 'thisMonth' as DateRangeOption,
      hideStatistics: false,
    },
    {
      chartType: 'bar',
      stacked: false,
      dataSetRequests: [{
        ChartType: 'bar',
        ReportDateGrouping: 'Concurrent',
        TargetType: 'ParkingSession',
        Label: 'Parking Sessions',
        AggregateType: 'Count',
        Metric: 'Duration',
        DisplayColor: '#006400'
      }],
      title: 'Average Session Duration',
      showFilters: true,
      isFullPage: true,
      groupBy: 'metric' as GroupByOption,
      bucketSize: 60,
      asPercentage: false,
      selectedDateRange: 'thisMonth' as DateRangeOption,
      hideStatistics: true
    }
  ];

  constructor(occupancyService: OccupancyService, apiservice: ApiService, router: Router) { 
    super();
    this.occupancyService = occupancyService;
    this.apiService = apiservice;
    this.router = router
  }
  
  ngOnInit(): void {
    
  }

  openFullPageStats(chart: ChartConfiguration) {
    this.router.navigate(['/parking/parking/statistics/full'], {
      state: {
        chartType: chart.chartType,
        stacked: chart.stacked,
        selectedDateRange: chart.selectedDateRange,
        title: chart.title,
        dataSetRequests: chart.dataSetRequests,
        isFullPage: chart.isFullPage,
        groupBy: chart.groupBy,
        bucketSize: chart.bucketSize,
        asPercentage: chart.asPercentage,
        hideStatistics: chart.hideStatistics
      }
    });
  }
}
