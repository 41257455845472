<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{Title}}</p>
      </header>
      <section class="modal-card-body" style="min-width: 800px;">
        <app-loader [Parent]="Busyable"></app-loader>
        <app-datalistview #DataList [Columns]="Columns" [ShowMap]="false" [ApiUrl]="Api" [HideSearch]="true"
            [AllowEdit]="false" [PreLoad]="false">
            <app-filter-set>
            </app-filter-set>
        </app-datalistview>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-danger" (click)="close()">Close</button>
      </footer>
    </div>
  </div>