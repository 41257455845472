import { Component, Input, OnInit } from '@angular/core';
import { Datalistrequestfilter } from '../../request/datalistrequestfilter';
import { IFilterFilters } from '../ifilter';

@Component({
  selector: '[app-enabled-filter]',
  templateUrl: './enabled-filter.component.html',
  styleUrls: ['./enabled-filter.component.scss'],
  host: { 'class': 'control' }
})
export class EnabledFilterComponent implements IFilterFilters, OnInit {
  @Input()
  public Enabled: any = "true";
  public FilterName: string = "enabled";
  QueryParamPrefix: string= '';
  constructor() { 
  }
  ngOnInit(): void {
    this.Filters.push({
      Property: "Enabled",
      Comparator: "==",
      Value: "true",
      FilterName: this.FilterName
    });
  }

  public Filters: Datalistrequestfilter[] = [];

  public adjustUseFilter(event: any) {
    var val = event.target.value;
    this.Filters = [];

    if (val != "") {
      this.Filters.push({
        Property: "Enabled",
        Comparator: "==",
        Value: val,
        FilterName: this.FilterName
      });
    } 
  }
}
