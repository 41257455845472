import { Component, OnInit, Input } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-media-modal',
  templateUrl: './media-modal.component.html',
  styleUrls: ['./media-modal.component.scss']
})


export class MediaModalComponent extends SimpleModalComponent<any, any> implements OnInit {
  MediaId: string = "";
  ModalTitle: string = "";
  Width: string = "";
  Height: string = "";
  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
