<app-loader [Parent]="this"></app-loader>
<div>
    <app-pageheader [Parent]="this">
    </app-pageheader>
    <!-- <app-validationsummary [Form]="Form"></app-validationsummary> -->
    <div class="columns">
        <div class="column is-half">
            <article class="panel" *ngIf="this.Model != null">
                <p class="panel-heading">Transaction <span class="has-bold-text">#{{this.Model.Id}}</span></p>
                <div class="panel-block">
                    <span class="label">External Id</span>
                    <span class="value">{{Model.TransactionId}}</span>
                </div>
                <div class="panel-block">
                    <span class="label">Reference</span>
                    <span class="value">{{Model.TransactionReference}}</span>
                </div>
                <div class="panel-block">
                    <span class="label">Amount</span>
                    <span class="value">{{orgService.GetCurrencySymbol()}}{{Model.Amount.toFixed(2)}}</span>
                </div>
                <div class="panel-block">
                    <span class="label">Payment Origin</span>
                    <span class="value">{{Model.Source}}</span>
                </div>
                <div class="panel-block">
                    <span class="label">Transaction Date</span>
                    <span class="value">{{Model.TransactionDateLocalFriendlyString}}</span>
                </div>
                <div class="panel-block">
                    <span class="label">Settlement Date</span>
                    <span class="value">{{Model.SettlementDateLocalFriendlyString}}</span>
                </div>
                <div class="panel-block">
                    <span class="label">Masked Card Number</span>
                    <span class="value">{{Model.MaskedAccountNumber}}</span>
                </div>
                <div class="panel-block" *ngIf="Model.MatchedProviderStatementRowId != null">
                    <span class="label">Statement</span>
                    <span class="value"><a target="_blank" href="/revenue/reconciliation/statements/{{Model.MatchedProviderStatementRowProviderStatementId}}?searchTerm={{Model.MatchedProviderStatementRowId}}">
                        Provider Statement {{Model.MatchedProviderStatementRowProviderStatementId}}, Row {{Model.MatchedProviderStatementRowId}}
                    </a></span>
                </div>
            </article>
        </div>
        <div class="column is-half">
            <article class="panel"> 
            </article>
        </div>
    </div>
    <div class="columns">
        <div class="column is-half">
            <article class="panel" *ngIf="this.Model != null">
                <p class="panel-heading">Reference Details</p>
                <div class="panel-block" *ngIf="Model.PlateNumber">
                    <span class="label">Plate Number</span>
                    <span class="value">{{Model.PlateNumber}}</span>
                </div>
                <div class="panel-block" *ngIf="Model.ControlledAreaName">
                    <span class="label">Controlled Area</span>
                    <span class="value">{{Model.ControlledAreaName}}</span>
                </div>
                <div class="panel-block" *ngIf="Model.ParkingLotName">
                    <span class="label">Parking Lot</span>
                    <span class="value">{{Model.ParkingLotName}}</span>
                </div>
                <div class="panel-block" *ngIf="Model.ParkingSpaceName">
                    <span class="label">Parking Space Name</span>
                    <span class="value">{{Model.ParkingSpaceName}}</span>
                </div>
                <div class="panel-block" *ngIf="Model.CardType">
                    <span class="label">Card Type</span>
                    <span class="value">{{Model.CardType}}</span>
                </div>
                <div class="panel-block" *ngIf="Model.TargetType">
                    <span class="label">Target</span>
                    <span class="value">{{Model.TargetType}} {{Model.TargetId}}</span>
                </div>

            </article>
        </div>
        <div class="column is-half">
            <article class="panel"> 
            </article>
        </div>
    </div>
</div>