<app-pageheader [Parent]="this">
</app-pageheader>

<app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="accounting/transactions/searches" [UseQueryParamStates]="true"
[AllowEdit]="true" EditUrl="/revenue/transactions/:id" >
  <app-filter-set>
    <div app-search-filter></div>
    <div app-date-filter DateSearchProperty="TransactionDate"></div>
    <div app-search-button></div>
  </app-filter-set>
</app-datalistview>