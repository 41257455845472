import { Component, OnInit } from '@angular/core';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-documenttemplates',
  templateUrl: './documenttemplates.component.html',
  styleUrls: ['./documenttemplates.component.scss']
})
export class DocumentTemplatesComponent implements OnInit {
  
  constructor() { 
  }

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Paper Size", "PaperSize"),
    new Datalistviewcolumn("Target", "TargetType"),
  ];

  ngOnInit(): void {
  }


}
