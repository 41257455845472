import { Component, OnInit } from '@angular/core';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-alerttriggers',
  templateUrl: './alerttriggers.component.html',
  styleUrls: ['./alerttriggers.component.scss']
})
export class AlerttriggersComponent implements OnInit {

  constructor() { }

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "EventName"),
    new Datalistviewcolumn("Recipients", "RecipientCount"),
    new Datalistviewcolumn("Auto Resolve Event", "AutoResolveEvent")
  ];

  ngOnInit(): void {
  }

}
