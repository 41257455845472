import { Component, Input, OnInit } from '@angular/core';
import { ISubsetsFilter } from '../ifilter';

@Component({
  selector: '[app-subset-filter]',
  templateUrl: './subset-filter.component.html',
  styleUrls: ['./subset-filter.component.scss'],
  host: {'class': 'control'}
})
export class SubsetFilterComponent implements ISubsetsFilter {

  @Input()
  public Subsets: Array<string> = [];

  @Input()
  public Subset: string = '';

  QueryParamPrefix: string= '';

  constructor() { }
}
