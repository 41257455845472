import { Component, OnInit } from '@angular/core';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { StructureViewerLayer } from 'src/app/shared/structureviewermaps/structureviewerlayers';

@Component({
  selector: 'app-carcounters',
  templateUrl: './carcounters.component.html',
  styleUrls: ['./carcounters.component.scss']
})
export class CarcountersComponent  implements OnInit {

  constructor() { }

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Id", "Id"),
    new Datalistviewcolumn("HID", "ChipId"),
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Location", "LocationString"),
    new Datalistviewcolumn("Relationship Count", "RelationshipCount"),
  ];

  public StructureViewerLayers: StructureViewerLayer[] = [
    new StructureViewerLayer("ParkingLot"), 
    new StructureViewerLayer("ParkingLevel"), 
    new StructureViewerLayer("CarCounterConfiguration", null, true),
  ]

  ngOnInit(): void {
  }



}
