import { DocumentEditorComponent } from "../documenteditor.component";
import { Helpers } from "../Helpers/Helpers";
import { DocumentComponentBase } from "./DocumentComponentBase";
import { HtmlRenderComponents, Style } from "../HtmlRenderComponents";
import { fabric } from 'fabric';
import { FabricHelpers } from "src/app/features/parking/parking/parkinglevels/leveleditor/fabric.js/helpers";

export class RectangleComponent extends DocumentComponentBase {

    public Styles : Style;

    constructor(documentEditorComponent: DocumentEditorComponent, Styles: Style) {
        super(documentEditorComponent);
        this.Styles = Styles;
    }

    public override RenderHtml() : string {
        let styleString = '';
  
        if (this.Styles.fontSize) styleString += `font-size: ${this.Styles.fontSize}; `;
        if (this.Styles.fontFamily) styleString += `font-family: ${this.Styles.fontFamily}; `;
        if (this.Styles.color) styleString += `color: ${this.Styles.color}; `;
        if (this.Styles.backgroundColor) styleString += `background-color: ${this.Styles.backgroundColor}; `;
        if (this.Styles.left !== null) styleString += `left: ${Helpers.convertPixelsToInches(this.Styles.left ?? 0)}in; `;
        if (this.Styles.top !== null) styleString += `top: ${Helpers.convertPixelsToInches(this.Styles.top ?? 0)}in; `;
        if (this.Styles.width !== null) styleString += `width: ${Helpers.convertPixelsToInches(this.Styles.width ?? 0)}in; `;
        if (this.Styles.height !== null) styleString += `height: ${Helpers.convertPixelsToInches(this.Styles.height ?? 0)}in; `;
        if (this.Styles.fontWeight) styleString += `font-weight: ${this.Styles.fontWeight}; `;
        if (this.Styles.textAlign) styleString += `text-align: ${this.Styles.textAlign}; `;
        if (this.Styles.borderStyle) styleString += `border: ${this.Styles.borderStyle}; `;
        if (this.Styles.borderRadius !== null) styleString += `border-radius: ${this.Styles.borderRadius}px; `;
        if (this.Styles.boxShadow) styleString += `box-shadow: ${this.Styles.boxShadow}; `;
  
        styleString += `position: absolute;`
  
        let htmlString = `<div style="${styleString}"></div>`;
        return htmlString;
    }

    override Draw(canvas: fabric.Canvas){
        if(this.fabricItem){
            canvas.remove(this.fabricItem);
            this.fabricItem = null;
        }
        
        if(this.Styles.borderRadius || this.Styles.borderStyle){
            var bStyle = this.Styles.borderStyle;
            var style = []

            if(bStyle){
                style = bStyle.split(" ");
            }
            else{
                style = "1px solid black".split(" ");
                if (style.length > 3) {
                    // Join the extra items into the third item
                    style[2] = style.slice(2).join(' ');
                    // Trim down to only three items
                    style.length = 3;
                }
            }


            // Create Fabric.js polygon for the border
            this.fabricItem = new fabric.Rect({
                left: (this.Styles.left ?? 0) + this.documentEditorComponent.offsets.x,
                top: (this.Styles.top ?? 0) + this.documentEditorComponent.offsets.y,
                width: this.Styles.width ?? 0,
                height: this.Styles.height ?? 0,
                fill: this.Styles.backgroundColor ?? '#ffffff',
                stroke: style[2],
                strokeWidth: Helpers.convertStyleValue(style[0]) ?? 1,
                rx: this.Styles.borderRadius ?? 0, // horizontal radius for rounded corners
                ry: this.Styles.borderRadius ?? 0,
                lockRotation: true
            });
    
            if(this.Styles.boxShadow){
                this.AddBoxShadow()
            }

            this.fabricItem.on("selected", () => {
                this.documentEditorComponent.ItemSelected.emit(this);
             });
    
             this.fabricItem.on("deselected", () => {
                this.documentEditorComponent.ItemDeselected.emit(this);
            });

            this.fabricItem.on("modified", () => {  
                const newLeft = this.fabricItem?.left ?? 0;
                const newTop = this.fabricItem?.top ?? 0;

                if(!Helpers.isPointInPolygon([newLeft, newTop], this.documentEditorComponent.RenderedDocument.Document)){
                    this.documentEditorComponent.toastService.info("Element cannot exist out side of document. Reverting move.");
                    this.fabricItem?.set({top: ((this.Styles.top ?? 0) + this.documentEditorComponent.offsets.y)});
                    this.fabricItem?.set({left:((this.Styles.left ?? 0) + this.documentEditorComponent.offsets.x)});
                }
                else{
                    this.Styles.width = this.fabricItem?.getScaledWidth() ?? this.Styles.width; // Adjusted for zoom level
                    this.Styles.height = this.fabricItem?.getScaledHeight() ?? this.Styles.height; // Adjusted for zoom level
                    this.Styles.left = (newLeft - this.documentEditorComponent.offsets.x);
                    this.Styles.top = (newTop - this.documentEditorComponent.offsets.y);
    
                    console.log(this.Styles.left, this.Styles.top);
                    this.documentEditorComponent.cdr.detectChanges();
                    this.documentEditorComponent.RenderHtml();
                }
            });

            canvas.add(this.fabricItem);
        }
    }  

    public AddBoxShadow(){
        if(this.Styles.boxShadow){
            var shadowValues = this.Styles.boxShadow.match(/rgba\(.*?\)\s\d*px\s\d*px\s\d*px/g);
    
            if(shadowValues){
                console.log(shadowValues[0]);
                this.fabricItem?.set({'shadow': shadowValues[0]});
            }
        }
        else{
            this.fabricItem?.set({'shadow': undefined});
        }
    }

    public override Remove(canvas: any){
        canvas.remove(this.fabricItem);
    }

    public override BringToFront(){
        this.fabricItem?.bringToFront();
      }
  
      public override SendToBack(){
        this.fabricItem?.sendToBack();
      }
  
      public override BringForward(){
        this.fabricItem?.bringForward();
      }
  
      public override SendBackward(){
        this.fabricItem?.sendBackwards();
      }
}