import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';
import { ConditionalValidator } from 'src/app/util/conditional-validator';
import { AddUserComponent } from 'src/app/features/users/users/modals/add-user/add-user.component';
import { BusyableModalComponent } from 'src/app/shared/editors/busyableModalComponent';
import { ModalBaseComponent } from 'src/app/shared/modals/modal-base/modal-base.component';

@Component({
  selector: 'app-editplateaccesslistplate',
  templateUrl: './editplateaccesslistplate.component.html',
  styleUrls: ['./editplateaccesslistplate.component.scss']
})
export class EditplateaccesslistplateComponent extends ModalBaseComponent<any, any> implements OnInit {

  public Form !: UntypedFormGroup;
  
  public InitialUser: any = {};
  public DeleteUrl: String = 'parking/plateaccess/' + this.route.snapshot.params['listid'] + '/plates/';
  public DeleteNavLink: String = "parking/accesscontrol/accesslists/" + this.route.snapshot.params['listid'];

  public Model: any = {};

  constructor(private route: ActivatedRoute, private modalService: SimpleModalService,  private apiService: ApiService) {
    super();

  }
  ngOnInit(): void {
    console.log(this.Model);
    this.Form = new UntypedFormGroup({});
    this.Form.addControl("plateNumber", new UntypedFormControl(this.Model.PlateNumber));
    this.Form.addControl("UserId", new UntypedFormControl(this.Model.UserId));

    this.InitialUser = {
      Id: this.Model.UserId,
      FirstName: this.Model.UserFullName,
      LastName: '',
    }
  }

  cancel() {
    this.result = null;
    super.confirm();
  }

  public AddPlate() {
    this.result = {
      PlateNumber : this.Form.get("plateNumber")?.value,
      UserId : this.Form.get("UserId")?.value,
      Id: this.Model.Id,
      PlateAccessListId: this.Model.PlateAccessListId,
    }

    super.confirm();
  }

  // public addUser() {
  //   this.modalService.addModal(AddUserComponent).subscribe(result => {
  //     console.log(result);
  //     this.InitialUser = result;
  //   })
  // }

}
