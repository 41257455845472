<div style="margin: 1em;">
    <div class="has-text-centered">{{Title}}</div>
    <canvas *ngIf="ChartType == 'line'" baseChart 
    [height]="Height"
      [data]="ChartData"
      [options]="ChartOptions"
      [type]="'line'">
    </canvas>
    <canvas *ngIf="ChartType == 'bar'" baseChart 
    [height]="Height"
        [data]="ChartData"
        [options]="ChartOptions"
        [type]="'bar'">
    </canvas>
</div>