import { Component, OnInit } from '@angular/core';
import { Datalistviewcolumn, DeviceIconcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { StructureViewerLayer } from 'src/app/shared/structureviewermaps/structureviewerlayers';


@Component({
  selector: 'app-terminals',
  templateUrl: './terminals.component.html',
  styleUrls: ['./terminals.component.scss']
})
export class TerminalsComponent implements OnInit {

  constructor() { }

  public Columns : Array<Datalistviewcolumn> = [
    new DeviceIconcolumn(""),
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Area", "ControlledAreaName"),
    new Datalistviewcolumn("Status", "DeviceStatus")
  ];

  public StructureViewerLayers: StructureViewerLayer[] = [new StructureViewerLayer("ParkingLot"), new StructureViewerLayer("LocalController", "Terminal", true)]
  ngOnInit(): void {
  }

}
