import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginServiceBase } from './login.service.base';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { LocalStorageService } from '../Services/local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class LoginService implements LoginServiceBase {
  @Output()
  public LoginStatusChanged = new EventEmitter<any>();
  @Output()
  public OrganizationChanged = new EventEmitter<any>();
  @Output()
  public OrganizationDeleted = new EventEmitter<any>();
  
  public RequiresPasswordReset: boolean = false;
  
  constructor(private httpClient: HttpClient, private router: Router, private modalService: SimpleModalService, private localStorageService: LocalStorageService) {
    let cachedUserData = localStorageService.Get(this.localStorageKey);
    if (cachedUserData != null) {
      let deserializedUserData = cachedUserData;
      if (deserializedUserData.SID != null) {
        this.UserData = deserializedUserData;
        this.LoginStatusChanged.emit(true);
        this.OrganizationChanged.emit(this.UserData.OrganizationId);
      }
    }
  }

  private localStorageKey = "UserData";

  private UserData = {
    UserId: "00000000-0000-0000-0000-000000000000",
    FirstName: "anonymous user",
    LastName: "last name",
    SID: "",
    OrganizationId: "",
    Permissions: []
  };


  public IsLoggedIn() {
    return this.UserData.SID != "";
  }
  public LoggedInName() {
    return this.UserData.LastName != null ? this.UserData.FirstName + " " + this.UserData.LastName : this.UserData.FirstName;
  }
  public LoggedInNameChanged(firstName: string, lastName: string) {
    this.UserData.FirstName = firstName;
    this.UserData.LastName = lastName;
    this.localStorageService.Save(this.localStorageKey, this.UserData);
  }
  public CurrentToken() {
    //console.log("SID " + this.UserData.SID)
    return this.UserData.SID;
  }
  public CurrentOrganizationId() {
    return this.UserData.OrganizationId;
  }
  public UserId() {
    return this.UserData.UserId;
  }

  public async DoLogin(username: string, password: string): Promise<any> {
    console.log("Attempting login for " + username);
    try {
      let data: any = await lastValueFrom(this.httpClient.post<any>(environment.serverBaseUrl + "/auth/adminlogin", { username: username, password: password }));
      if (data.SID != null) {
        this.UserData = data;
        this.localStorageService.Save(this.localStorageKey, data);
        console.log("Login Success for " + username);
        this.RequiresPasswordReset = data.PasswordResetRequired;
        this.LoginStatusChanged.emit(true);
        return { Success: true };
      }
    } catch (error: any) {
      return { Success: false, Error: error.error.Error };
    }
    return { Success: false };
  }
  public DoLogout() {
    this.UserData = {
      UserId: "00000000-0000-0000-0000-000000000000",
      SID: "",
      FirstName: "anonymous user",
      LastName: "last name",
      OrganizationId: "",
      Permissions: []
    };
    this.localStorageService.Clear(this.localStorageKey);
    // Clear all stored data against with the current user
    this.localStorageService.ClearAll();
    this.LoginStatusChanged.emit(false);
    this.router.navigate(['login']);
  }

  public DoOrganizationSwitch(id: string) {
    let headers = new HttpHeaders().set("authorization", "Bearer " + this.UserData.SID);
    this.httpClient.post<any>(environment.serverBaseUrl + "/auth/currentorganization", { OrganizationId: id }, { headers })
      .subscribe(result => {
        this.UserData.SID = result.SID;
        this.UserData.OrganizationId = id;
        this.localStorageService.Save(this.localStorageKey, this.UserData);
        this.OrganizationChanged.emit(this.UserData.OrganizationId);
        this.router.navigate(['']);
      });
  }

  public NewTestOrganization(name: string) {
    let headers = new HttpHeaders().set("authorization", "Bearer " + this.UserData.SID);
    this.httpClient.post<any>(environment.serverBaseUrl + "/organization/newtestorg", {UserId: this.UserData.UserId, Name: name} , { headers })
      .subscribe(result => {
        this.DoOrganizationSwitch(result.Id);
      });
  }

  public DeleteNewTestOrganization(name: string) {
    let headers = new HttpHeaders().set("authorization", "Bearer " + this.UserData.SID);
    this.httpClient.post<any>(environment.serverBaseUrl + "/organization/deleteorg", {Name: name} , { headers })
      .subscribe(result => {
        this.OrganizationDeleted.emit(true);
      });
  }

  private LoginModal: any = null;
  public NotifyAuthExpired() {
    console.log('Auth service notified of expired auth. Showing login');
    if (this.LoginModal != null) {
      //already a login modal present
      return;
    }
    this.LoginModal = this.modalService.addModal(LoginModalComponent, {})
      .subscribe((result: any) => {
        this.LoginModal = null;
        if (result != null) {

        }
      });
  }



  public DebugBreakAuthToken() {
    this.UserData.SID = "----";
    this.localStorageService.Save(this.localStorageKey, this.UserData);
  }

}
