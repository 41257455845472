import { Component, OnInit } from '@angular/core';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-customgroups',
  templateUrl: './customgroups.component.html',
  styleUrls: ['./customgroups.component.scss']
})
export class CustomgroupsComponent implements OnInit {

  constructor() { }

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Total Spaces", "TotalSpaces"),
    new Datalistviewcolumn("Occupied Spaces for Display", "OccupiedSpacesForDisplay")
  ];

  ngOnInit(): void {
  } 

}
