<app-pageheader [Parent]="this">
  <add-button AddRoute="/permits/parkingpermits/new/edit"></add-button>
</app-pageheader>

<app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="parking/permits/searches"
  [AllowEdit]="true" EditUrl="/permits/parkingpermits/:id">
  <app-filter-set>
    <div app-search-filter></div>
    <div app-subset-filter [Subsets]="Subsets"></div>
    <div app-search-button></div>
  </app-filter-set>
</app-datalistview>