<app-loader [Parent]="this"></app-loader>
<div *ngIf="Model != null">
  <app-pageheader [Parent]="this">
    <form-edit-button>Edit</form-edit-button>
    <app-form-delete-button [Model]="Model" [Parent]="this" DeleteUrl="/infrastructure/customgroups/" [DeleteCheck]="false" DeleteNavLink="/parking/accesscontrol/customgroups"
    Api="Infrastructure" TypeName="ParkingStructureGroup">
    </app-form-delete-button>
  </app-pageheader>
  <div class="columns" style="padding-left:10px;">
    <div class="column is-12">
      <app-tabs>
        <app-tab Title="Custom Group Information">
            <div class="columns">
                <div class="column is-9">
                  <app-field Label="Permit Type">
                    <div class="control">
                      <p class="input" disabled>{{Model.Name}}</p>
                    </div>
                  </app-field>
                <app-field Label="Structures">
                    <div class="control" *ngFor="let i of Model.Structures">
                        <p class="tag" style="width:50%; padding-top:2px; justify-content: left; font-size: large;"  disabled>{{i.ParkingStructureLocationString}}</p>
                    </div>
                  </app-field>
                </div>
            </div>
        </app-tab>
      </app-tabs>
    </div>
  </div>
</div>