import { edit } from "ace-builds";
import { LevelEditorComponent } from "../leveleditor.component";
import { PolygonMapComponent } from "../components/PolygonComponents/PolygonMapComponent";
import { UndoAction } from "./undoaction";
import { FormArray } from "@angular/forms";
import { LevelEditorHelper } from "../helper";

export class CloneUndoAction extends UndoAction {
    public ParentFormItem !: any;
    public ParentChildArrayName !: string | undefined;

    constructor(
        canvasState: any,
        editor?: LevelEditorComponent,
        formItem?: any,
        children?: UndoAction[],
        parentFormItem ?: any,
        parentchildArrayName? : string
    ) {
        super(canvasState, editor, formItem, undefined, undefined, undefined, children);
        if (children) {
            this.Children = children;
        } else {
            this.Editor = editor!;
            this.CanvasState = canvasState!;
            this.FormItem = formItem!;
            this.ParentChildArrayName = parentchildArrayName;
            this.ParentFormItem = parentFormItem;
        }
    }

    public override Undo() {
        if (this.Children) {
            this.Children.forEach(child => child.Undo());
        } else {
            if(!this.ParentFormItem){
                this.ParentFormItem = this.Editor.Form;
            }
            const items = this.ParentFormItem.get(this.ParentChildArrayName) as FormArray;
                                
            let parentItemsArray: any[] = [];
            for (let i = 0; i < items.length; i++) {
                parentItemsArray.push(items.at(i));
            }

            var parentChildIndex = LevelEditorHelper.findFormItemIndex(items, this.FormItem);
            items.removeAt(parentChildIndex);

            var mapItem = this.Editor.MapItems.find(x => x.Id == this.FormItem.get('Id')?.value);
            const index = this.Editor.MapItems.findIndex(x => x.Id == this.FormItem.get('Id')?.value);
            if (index !== -1) {
                this.Editor.MapItems.splice(index, 1);
            }

            if(mapItem){
                this.Editor.canvas.remove(mapItem.FabricItem);  
            }

            this.Editor.Form.markAsDirty();
            this.Editor.canvas.discardActiveObject();
            this.Editor.SelectedItems = [];
        }
    }
}
