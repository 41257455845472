<div class="modal is-active">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Add Magic Money</p>
    </header>
    <section class="modal-card-body" style="min-width: 800px;">
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Magic Money</label>
        </div>
        <div class="field-body">
          <div class="field">
            <input class="input" type="number" placeholder="0" [(ngModel)]="User.MagicMoney">
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Description</label>
        </div>
        <div class="field-body">
          <div class="field">
            <input class="input" type="text" placeholder="Description" [(ngModel)]="User.Description">
          </div>
        </div>
      </div>


    </section>
    <footer class="modal-card-foot">
      <div *ngIf="startRequesting; else hasResponse">
        <button class="button is-success" (click)="confirm()">Updating...</button>
      </div>

      <ng-template #hasResponse>
        <button class="button is-success" (click)="confirm()">OK</button>
      </ng-template>

    </footer>
  </div>
</div>