<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Parent]="this" [Form]="Form" class="button is-primary" (OnSave)="Save()">
        </app-form-save-button>
    </app-pageheader>

    <app-field Label="Name">
        <div class="control">
            <input formControlName="Name" class="input">
            <app-validator [For]="Form.get('Name')"></app-validator>
        </div>
    </app-field>
    <app-field Label="Thresholds" [HasAddons]="true">
        <div class="control" formArrayName="ParkingThresholdColors">
            <div *ngFor="let line of FormArray(Form, 'ParkingThresholdColors').controls; let i = index">
                <div [formGroupName]="i">
                    <div class="field has-addons">
                        <div class="control">
                            <app-colorpicker formControlName="Color"></app-colorpicker>
                        </div>
                        <div class="control">
                            <a class="button is-light">
                                Threshold (%)
                            </a>
                        </div>
                        <div class="control">
                            <input formControlName="ThresholdPercent" class="input" type="number">
                            <app-validator [For]="line.get('ThresholdPercent')"></app-validator>
                        </div>
                        <div class="control">
                            <a class="button is-light">
                                Description:
                            </a>
                        </div>
                        <div class="control">
                            <input formControlName="Description" class="input" type="text">
                        </div>
                        <div class="control">
                            <div class="select">
                                <select formControlName="CloseWhenReached">
                                    <option [value]="false">No Action</option>
                                    <option [value]="true">Close Parking</option>
                                </select>
                            </div>
                        </div>
                        <div class="control">
                            <button *ngIf="FormArray(Form, 'ParkingThresholdColors').controls.length > 1"
                                class="button is-light" (click)="RemoveParkingThresholdColor(i)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="button is-primary" (click)="AddParkingThresholdColor()">
                Add Line
            </div>
        </div>
    </app-field>

</div>