import { Component, OnInit } from '@angular/core';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-ratesets',
  templateUrl: './ratesets.component.html',
  styleUrls: ['./ratesets.component.scss']
})
export class RatesetsComponent implements OnInit {

  constructor() { }

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Description", "DescriptionString"),
  ];

  ngOnInit(): void {
  }

}
