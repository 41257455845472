import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { every, ignoreElements, timestamp } from 'rxjs';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';
import { CalendarService } from 'src/app/Services/calendar.service';
import { ColorService } from 'src/app/Services/color.service';

@Component({
  selector: 'app-editrateset',
  templateUrl: './editrateset.component.html',
  styleUrls: ['./editrateset.component.scss']
})
export class EditratesetComponent extends ModelEditor implements OnInit {

  public override ReactiveFormsEnabled: boolean = false;
  public Grids: any[] = []
  public DayHeaders: string[] = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];
  public Months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public TimeHeaders: string[] = [];
  public TableHeight: number = 700;
  public SlotMinutes: number = 30;
  public SlotHeight: number = 2;
  public CurrentResizeTimeSpan: any = null;
  public CurrentResizeTrack: any = null;
  public CurrentResizeHandle: string = "";
  public CurrentResizeStartPosition: any = null;
  public CurrentPriceRule: any = null;
  public PriceRules: any[] = [];

  constructor(private colorService: ColorService, public calendarService: CalendarService, private apiService: ApiServiceBase, private injector: Injector) {
    super("parking/ratesets", injector);

  }

  public override DefaultModel(): any {
    return {
      Name: "New Rate Set",
      Periods: [
        { StartDay: 1, StartMonth: 1, EndDay: 31, EndMonth: 12, Timespans: [] }
      ]
    };
  }
  public override AfterModelLoaded(): void {
    if(this.TimeHeaders.length == 0){ //only do this on initial load, not after save
      this.BuildTimeHeaders();
      this.BuildGrids();
      this.apiService.Get<any>("parking/pricerules").then(result => {
        this.PriceRules = result;
      });
    }
  }
  public override BeforeSave(): void {
    //rebuild each period's timespans from the days displayed in Grids
    let periods = [];
    for (let g of this.Grids) {
      periods.push(g.Period);
      let timespans = [];
      for (let d of g.Days) {
        for(let tr of d.Tracks)
        {
          for (let t of tr.Timespans) {
            t.DayOfWeek = d.Index;
            timespans.push(t);
          }
        }
      }
      g.Period.Timespans = timespans;
    }
    this.Model.Periods = periods;
  }
  public override Validators(): any {

  }

  private BuildGrids() {
    this.SlotHeight = this.TableHeight / (1440 / this.SlotMinutes);
    this.Grids = [];
    for (let p = 0; p < this.Model.Periods.length; p++) {
      let period: any = { Period: this.Model.Periods[p], Days: [] };
      this.Grids.push(period);
      this.BuildEmptyDays(period);
    }
  }
  private BuildEmptyDays(period: any) {
    for (let d = 0; d < 7; d++) {
      let day: any = { Index: d, Header: this.DayHeaders[d], Tracks: [{ Slots: [], Timespans: [] }] };
      period.Days.push(day);
      if (period.Period.Timespans == null || !Array.isArray(period.Period.Timespans)) {
        period.Period.Timespans = [];
      }
      for (let i = 0; i < period.Period.Timespans.length; i++) {
        if (period.Period.Timespans[i].DayOfWeek == d) {
          let span = period.Period.Timespans[i];
          this.UpdateTimeSpanPosition(span);
          if(span.TrackIndex >= day.Tracks.length) 
            day.Tracks.push({ Slots: [], Timespans: [], Index: day.Tracks.length });
          day.Tracks[span.TrackIndex].Timespans.push(span);
        }
      }
      for (let minute = 0; minute < 1440; minute += this.SlotMinutes) {
        for(let t of day.Tracks){
          let slot: any = { StartsAt: minute, EndsAt: minute + this.SlotMinutes };
          t.Slots.push(slot);
        }
      }
    }
  }
  private BuildTimeHeaders() {
    if(this.TimeHeaders.length > 0) return;
    this.TimeHeaders = [];
    for (let i = 0; i < 1440; i += this.SlotMinutes) {
      this.TimeHeaders.push(Math.floor(i / 60) + ":" + ((i % 60) < 10 ? '0' : '') + (i % 60));
    }
    this.TimeHeaders.push("0:00")
  }

  private UpdateTimeSpanPosition(timespan: any) {
    if (timespan.EndHour >= 24) {
      timespan.EndHour = 24;
      timespan.EndMinute = 0;
    }
    if (timespan.StartHour < 0) {
      timespan.StartHour = 0;
      timespan.StartMinute = 0;
    }

    let top = ((timespan.StartHour * 60 + timespan.StartMinute) / 1440) * this.TableHeight;
    let bottom = ((timespan.EndHour * 60 + timespan.EndMinute) / 1440) * this.TableHeight;
    let height = bottom - top;
    timespan.Top = top;
    timespan.PreDragTop = top;
    timespan.Height = height;
    timespan.PreDragHeight = height;
    timespan.StartMinuteIndex = timespan.StartHour * 60 + timespan.StartMinute;
    timespan.EndMinuteIndex = timespan.EndHour * 60 + timespan.EndMinute;
    timespan.TextClass = this.colorService.GetVisibleTextClassFor(timespan.PriceRuleColor);
  }

  public TabChanged(index: Number) {
  }

  public StartDrag(evt: any, handle: string, timespan: any, track: any) {
    this.CurrentResizeTimeSpan = timespan;
    this.CurrentResizeTrack = track;;
    this.CurrentResizeHandle = handle;
    this.CurrentResizeStartPosition = evt.pageY;
  }
  public EndDrag(evt: any) {
    if (this.CurrentResizeTimeSpan == null) {
      return;
    }
    let timespan = this.CurrentResizeTimeSpan;
    if (this.CurrentResizeHandle == 'bottom') {
      let newEndMins = timespan.StartHour * 60 + timespan.StartMinute + (Math.round(timespan.Height / this.SlotHeight) * this.SlotMinutes);
      this.CurrentResizeTimeSpan.EndHour = Math.floor(newEndMins / 60);
      this.CurrentResizeTimeSpan.EndMinute = newEndMins % 60;
      this.UpdateTimeSpanPosition(this.CurrentResizeTimeSpan);
      this.CheckCollisions(this.CurrentResizeTrack, this.CurrentResizeTimeSpan);
    }
    else if (this.CurrentResizeHandle == 'top') {
      let newStartMins = Math.round(timespan.Top / this.SlotHeight) * this.SlotMinutes;
      this.CurrentResizeTimeSpan.StartHour = Math.floor(newStartMins / 60);
      this.CurrentResizeTimeSpan.StartMinute = newStartMins % 60;
      this.UpdateTimeSpanPosition(this.CurrentResizeTimeSpan);
      this.CheckCollisions(this.CurrentResizeTrack, this.CurrentResizeTimeSpan);
    }
    this.CurrentResizeTimeSpan = null;
  }
  public CancelDrag(evt: any) {
    if (this.CurrentResizeTimeSpan != null) {
      this.UpdateTimeSpanPosition(this.CurrentResizeTimeSpan);
      this.CurrentResizeTimeSpan = null;
    }
  }

  public ColumnMouseMove(evt: any) {
    if (this.CurrentResizeTimeSpan != null) {
      if (evt.buttons != 1) {//if not still dragging then cancel resize
        this.CurrentResizeTimeSpan = null;
        return;
      }
      let totalYdrag = evt.pageY - this.CurrentResizeStartPosition;
      if (totalYdrag != 0) {
        if (this.CurrentResizeHandle == "bottom") {
          this.CurrentResizeTimeSpan.Height = this.CurrentResizeTimeSpan.PreDragHeight + totalYdrag;

        }
        else if (this.CurrentResizeHandle == "top") {
          this.CurrentResizeTimeSpan.Top = this.CurrentResizeTimeSpan.PreDragTop + totalYdrag;
          this.CurrentResizeTimeSpan.Height = this.CurrentResizeTimeSpan.PreDragHeight - totalYdrag;
        }
        if (this.CurrentResizeTimeSpan.Height < this.SlotHeight) {
          this.CurrentResizeTimeSpan.Height = this.SlotHeight;
          if (this.CurrentResizeHandle == "top") {
            this.CurrentResizeTimeSpan.Top = this.CurrentResizeTimeSpan.PreDragTop + this.CurrentResizeTimeSpan.PreDragHeight - this.SlotHeight;
          }
        }
      }
    }
  }

  public CheckCollisions(track: any, timespanMoved: any) {
    for (let i = 0; i < track.Timespans.length; i++) {
      if (track.Timespans[i] == timespanMoved) {
        continue;
      }
      let t = track.Timespans[i];
      if (timespanMoved.StartMinuteIndex < t.StartMinuteIndex && timespanMoved.EndMinuteIndex > t.StartMinuteIndex) {
        //moved timespan ran over the start of the old timespan
        if (timespanMoved.EndMinuteIndex < t.EndMinuteIndex) {
          //it took time off the start but there is still some of t remaining
          t.StartHour = timespanMoved.EndHour;
          t.StartMinute = timespanMoved.EndMinute;
          this.UpdateTimeSpanPosition(t);
        }
        else {
          //the move timespan eliminated t
          track.Timespans.splice(i, 1);
          i -= 1;
        }
      }
      else if (timespanMoved.StartMinuteIndex > t.StartMinuteIndex && timespanMoved.StartMinuteIndex < t.EndMinuteIndex) {
        //moved timespan up and ran over the end of another timespan
        t.EndHour = timespanMoved.StartHour;
        t.EndMinute = timespanMoved.StartMinute;
        this.UpdateTimeSpanPosition(t);
      }
      else if (timespanMoved.StartMinuteIndex < t.StartMinuteIndex && timespanMoved.EndMinuteIndex > t.EndMinuteIndex) {
        //moved timespan has eliminated t
        track.Timespans.splice(i, 1);
        i -= 1;
      }
      else if (timespanMoved.StartMinuteIndex == t.EndMinuteIndex && timespanMoved.PriceRuleId == t.PriceRuleId && t.DenyEntry == timespanMoved.DenyEntry && timespanMoved.DisableMergePrior == t.DisableMergePrior) {
        //butted up against another timespan that is the same rule. remove t and move the start up to where it was
        timespanMoved.StartHour = t.StartHour;
        timespanMoved.StartMinute = t.StartMinute;
        this.UpdateTimeSpanPosition(timespanMoved);
        track.Timespans.splice(i, 1);
        i -= 1;
      }
      else if (timespanMoved.EndMinuteIndex == t.StartMinuteIndex && timespanMoved.PriceRuleId == t.PriceRuleId && t.DenyEntry == timespanMoved.DenyEntry && timespanMoved.DisableMergePrior == t.DisableMergePrior) {
        //butted up against another timespan that is the same rule. remove t and move the start up to where it was
        timespanMoved.EndHour = t.EndHour;
        timespanMoved.EndMinute = t.EndMinute;
        this.UpdateTimeSpanPosition(timespanMoved);
        track.Timespans.splice(i, 1);
        i -= 1;
      }
    }
  }

  public SelectPriceRule(rule: any) {
    if (this.CurrentPriceRule != null) {
      this.CurrentPriceRule.Selected = false;
      if (this.CurrentPriceRule == rule) {
        this.CurrentPriceRule = null; //deselect
        return;
      }
    }
    this.CurrentPriceRule = rule;
    rule.Selected = true;
  }

  public EmptySlotClicked(slot: any, track: any) {
    if (this.CurrentPriceRule == null) {
      return;
    }
    let newTimespan = {
      StartHour: Math.floor(slot.StartsAt / 60),
      StartMinute: slot.StartsAt % 60,
      EndHour: Math.floor(slot.EndsAt / 60),
      EndMinute: slot.EndsAt % 60,
      PriceRuleColor: this.CurrentPriceRule.Color,
      PriceRuleId: this.CurrentPriceRule.Id,
      PriceRuleName: this.CurrentPriceRule.Name,
      DenyEntry: false,
      TrackIndex: track.Index,
    };
    this.UpdateTimeSpanPosition(newTimespan);
    track.Timespans.push(newTimespan);
  }

  public PeriodMonthChanged(period: any, index: number) {
    let maxDays = this.calendarService.MaxDaysInMonth(period.StartMonth);
    if (period.StartDay > maxDays) {
      period.StartDay = maxDays;
    }
    maxDays = this.calendarService.MaxDaysInMonth(period.EndMonth);
    if (period.EndDay > maxDays) {
      period.EndDay = maxDays;
    }
    this.PeriodChanged(period, index);
  }

  public PeriodChanged(period: any, index: number) {

    if (index == this.Grids.length - 1 && (period.EndMonth != 12 || period.EndDay != 31)) {
      //the last period's end was brought forward so we need a new period to cover to the end of the year
      this.AddEndPeriod();
    }
    if (period.EndMonth < period.StartMonth || (period.EndMonth == period.StartMonth && period.EndDay < period.StartDay)) {
      //shifting dates caused end to be before start
      period.EndMonth = period.StartMonth;
      period.EndDay = period.StartDay;
    }

    if (index > 0) {
      //set end of prev period to day before the start of this period
      let newEnd = this.calendarService.GetPrevDay(period.StartDay, period.StartMonth);
      this.Grids[index - 1].Period.EndMonth = newEnd.getMonth() + 1; //js date months are 0 based
      this.Grids[index - 1].Period.EndDay = newEnd.getDate();
    }
    if (index < this.Grids.length - 1) {
      //set start of next period to day after the end of this period
      let newStart = this.calendarService.GetNextDay(period.EndDay, period.EndMonth);
      this.Grids[index + 1].Period.StartMonth = newStart.getMonth() + 1; //js date months are 0 based
      this.Grids[index + 1].Period.StartDay = newStart.getDate();
      this.PeriodChanged(this.Grids[index + 1].Period, index + 1); //ripple changes forward to clean up the trailing tabs
    }
    if (period.EndMonth == 12 && period.EndDay == 31 && index < this.Grids.length - 1) {
      //this is not the last period, but its end date has been set to the end of the year
      while (this.Grids.length > index + 1) {
        this.Grids.splice(this.Grids.length - 1, 1);
      }
    }

  }

  public AddEndPeriod() {
    let newPeriod = { Period: { StartDay: 1, StartMonth: 1, EndDay: 31, EndMonth: 12, Timespans: [] }, Days: [] };
    this.BuildEmptyDays(newPeriod);
    this.Grids.push(newPeriod);
  }

  public SplitPeriod(index: number) {
    let period = this.Grids[index].Period;
    if (period.EndDay == 1 && period.EndMonth == 1) {//can't do this if split period ends Jan 1
      return;
    }

    let newPeriod = { Period: { StartDay: period.EndDay, StartMonth: period.EndMonth, EndDay: period.EndDay, EndMonth: period.EndMonth, Timespans: [] }, Days: [] };
    this.BuildEmptyDays(newPeriod);
    this.Grids.splice(index + 1, 0, newPeriod);

    let newEnd = this.calendarService.GetPrevDay(period.EndDay, period.EndMonth);
    period.EndDay = newEnd.getDate();
    period.EndMonth = newEnd.getMonth() + 1;

  }

  public DeleteTimespan(track: any, index: number) {
    track.Timespans.splice(index, 1);
  }

  public AddTrack(day: any){
    let newTrack = { Slots: <any>[], Timespans: <any>[], Index: day.Tracks.length };
    day.Tracks.push(newTrack);
    for (let minute = 0; minute < 1440; minute += this.SlotMinutes) {
        let slot: any = { StartsAt: minute, EndsAt: minute + this.SlotMinutes };
        newTrack.Slots.push(slot);
    }
  }

}
