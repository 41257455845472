import { Component, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { SimpleModalComponent } from "ngx-simple-modal";

@Component({
    template: ''
  })

export class BusyableModalComponent<T1, T2> extends SimpleModalComponent<any, any> {

    @Output()
    public BusyStarted = new EventEmitter<any>();
    @Output()
    public BusyEnded = new EventEmitter<any>();

    @Output()
    public LoadingStarted = new EventEmitter<any>();
    @Output()
    public LoadingEnded = new EventEmitter<any>();

    public IsBusy : boolean = false;
    public IsLoading : boolean = false;
    
    public Busy(){
        this.IsBusy = true;
        this.BusyStarted.emit();
    }

    public StopBusy(){
        this.IsBusy = false;
        this.BusyEnded.emit();
    }

    public Loading(){
        this.IsLoading = true;
        this.LoadingStarted.emit();
    }
    public StopLoading(){
        this.IsLoading = false;
        this.LoadingEnded.emit();
    }
}
