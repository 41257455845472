import { EventEmitter, Injectable, Output } from '@angular/core';
import { OrganizationsService } from './organizations.service';
import { ServiceBusClient } from '@azure/service-bus';
import { ApiService } from './api.service';
import { PermissionsService } from './permissions.service';
import { debug } from 'console';
import { LoginServiceBase } from '../auth/login.service.base';

@Injectable({
  providedIn: 'root'
})
export class RevenueService {

  @Output()
  public TransactionsChanged = new EventEmitter<any>();

  public CurrentConnectionString : string = "";
  private Client? : ServiceBusClient;
  private TodaysTransactions : any = null;
  private ThisWeeksTransactions : any = null;
  private Last6MonthsTransactions : any = null;
  private ThisWeekByControlledArea : any = null;
  private LastFetched : Date = new Date(0);
  

  constructor(private apiService : ApiService, private permissionService : PermissionsService, private organizationsService : OrganizationsService, private loginService : LoginServiceBase) {

    permissionService.CheckAdminPermission("RevenueFeature", 0).then(x => {
      if(x){
        this.ConnectServiceBus();
      }
    });
    loginService.OrganizationChanged.subscribe((orgid) => {
        this.ClearData();
        this.ConnectServiceBus();
      });
  }

  private ClearData(){
    this.TodaysTransactions = null;
    this.ThisWeeksTransactions = null;
    this.Last6MonthsTransactions = null;
    this.ThisWeekByControlledArea = null;
    this.LastFetched = new Date(0); 
  }

  private ConnectServiceBus(){
    this.organizationsService.GetServiceBusListenerConnectionString().then((x: any) => {
      if(this.CurrentConnectionString == x){
        //same connection as before
        return;
      }
      if(this.Client != null){
        //if we were connected before then close
        this.Client.close();
      }
      if(x == null || x == ''){
        return; //can't connect with no connecton string
      }

    var subscriptionKey = this.permissionService.GetSessionIdentifier();
     this.apiService.Post<any>("infrastructure/servicebus/topics/Revenue/" + this.loginService.UserId() + subscriptionKey, {}).then(result => {
      this.Client = new ServiceBusClient(x);
      this.Refresh();
      const receiver = this.Client.createReceiver("Revenue", this.loginService.UserId() + subscriptionKey);
      const SBMessageHandler = async (messageReceived:any) => {
        if(this.TodaysTransactions != null){
          this.TodaysTransactions.TotalTransactions += 1;
          this.TodaysTransactions.TotalAmount += messageReceived.body.Amount;
          //find the value for the current hour and increment it
          let currentHour = new Date().getHours();
          if(this.TodaysTransactions.Values.length < currentHour) this.TodaysTransactions.Values.push(0);
          this.TodaysTransactions.Values[currentHour] += messageReceived.body.Amount
        }
        if(this.ThisWeeksTransactions != null){
          this.ThisWeeksTransactions.TotalTransactions += 1;
          this.ThisWeeksTransactions.TotalAmount += messageReceived.body.Amount;
          var dayindex = new Date().getDay();
          if(this.ThisWeeksTransactions.Values.length < dayindex) this.ThisWeeksTransactions.Values.push(0);
          this.ThisWeeksTransactions.Values[dayindex] += messageReceived.body.Amount;
        }
        if(this.Last6MonthsTransactions != null){
          this.Last6MonthsTransactions.TotalTransactions += 1;
          this.Last6MonthsTransactions.TotalAmount += messageReceived.body.Amount;
          var monthIndex = 5;
          if(this.Last6MonthsTransactions.Values.length < monthIndex) this.Last6MonthsTransactions.Values.push(0);
          this.Last6MonthsTransactions.Values[monthIndex] += messageReceived.body.Amount;
        }
        if(this.ThisWeekByControlledArea != null){
          //find the controlled area and increment the value
          for(var i = 0; i < this.ThisWeekByControlledArea.Structures.length; i++){
            if(this.ThisWeekByControlledArea.Structures[i].StructureName == messageReceived.body.ControlledAreaName){
              this.ThisWeekByControlledArea.Structures[i].TotalTransactions += 1;
              this.ThisWeekByControlledArea.Structures[i].TotalAmount += messageReceived.body.Amount;
              break;
            }
          }
            
        }

        this.TransactionsChanged.emit();

      };
      const SBErrorHandler = async (error:any) => {
        console.log(error);
      };
      receiver.subscribe({
        processMessage: SBMessageHandler,
        processError: SBErrorHandler
      });
     });
    });
  }

  private async Refresh(){
    await Promise.all([
      this.RefreshTodaysTransactions(),
      this.RefreshThisWeeksTransactions(),
      this.RefreshLast6MonthsTransactions(),
      this.RefreshThisWeekByControlledArea()
    ]);
    this.LastFetched = new Date();
  }

  private TodaysTransactionsPromise : Promise<any> | null = null;
  private async RefreshTodaysTransactions(){
    if(this.TodaysTransactionsPromise != null)
      return;
    this.TodaysTransactionsPromise = this.apiService.Get<any>("parking/parkingsessions/revenue/series/today");
    await this.TodaysTransactionsPromise.then(result => {
      this.TodaysTransactionsPromise = null;
      this.TodaysTransactions = result;
      this.TransactionsChanged.emit();
    });
  }
  private ThisWeeksTransactionsPromise : Promise<any> | null = null;
  private async RefreshThisWeeksTransactions(){
    if(this.ThisWeeksTransactionsPromise != null)
      return;
    this.ThisWeeksTransactionsPromise = this.apiService.Get<any>("parking/parkingsessions/revenue/series/thisweek");
    await this.ThisWeeksTransactionsPromise.then(result => {
      this.ThisWeeksTransactionsPromise = null;
      this.ThisWeeksTransactions = result;
      this.TransactionsChanged.emit();
    });
  }

  private Last6MonthsTransactionsPromise : Promise<any> | null = null;
  private async RefreshLast6MonthsTransactions(){
    if(this.Last6MonthsTransactionsPromise != null)
      return;
    this.Last6MonthsTransactionsPromise = this.apiService.Get<any>("parking/parkingsessions/revenue/series/sixmonths");
    await this.Last6MonthsTransactionsPromise.then(result => {
      this.Last6MonthsTransactionsPromise = null;
      this.Last6MonthsTransactions = result;
      this.TransactionsChanged.emit();
    });
  }
  private ThisWeekByControlledAreaPromise : Promise<any> | null = null;
  private async RefreshThisWeekByControlledArea(){
    if(this.ThisWeekByControlledAreaPromise != null)
      return;
    this.ThisWeekByControlledAreaPromise = this.apiService.Get<any>("parking/parkingsessions/revenue/thisweek/all");
    await this.ThisWeekByControlledAreaPromise.then(result => {
      this.ThisWeekByControlledAreaPromise = null;
      this.ThisWeekByControlledArea = result;
      this.TransactionsChanged.emit();
    });
  }

  public async GetTodaysTransactions(){
    if(this.TodaysTransactions == null || this.LastFetched.getDay() != new Date().getDay() || (this.LastFetched.getTime() - new Date().getTime()) > 1000 * 60 * 60){
      await this.Refresh();
    }
    return this.TodaysTransactions;
  }
  public async GetThisWeeksTransactions(){
    if(this.TodaysTransactions == null || this.LastFetched.getDay() != new Date().getDay() || (this.LastFetched.getTime() - new Date().getTime()) > 1000 * 60 * 60){
      await this.Refresh();
    }
    return this.ThisWeeksTransactions;
  }
  public async GetLast6MonthsTransactions(){
    if(this.TodaysTransactions == null || this.LastFetched.getDay() != new Date().getDay() || (this.LastFetched.getTime() - new Date().getTime()) > 1000 * 60 * 60){
      await this.Refresh();
    }
    return this.Last6MonthsTransactions;
  }
  public async GetThisWeekByControlledArea(){
    if(this.ThisWeekByControlledArea == null || this.LastFetched.getDay() != new Date().getDay() || (this.LastFetched.getTime() - new Date().getTime()) > 1000 * 60 * 60){
      await this.Refresh();
    }
    return this.ThisWeekByControlledArea;
  }

}
