//#region imports
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgModule, OnInit, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, UntypedFormArray, UntypedFormGroup } from "@angular/forms";
import { ModelEditor } from "src/app/shared/editors/modeleditor";
import { fabric } from 'fabric';
import { MapComponentBase} from "./components/MapComponentBase";
import { ApiService } from "src/app/Services/api.service";
import { MediaService } from "src/app/Services/media.service";
import { ToastrService } from "ngx-toastr";
import { FormArray } from '@angular/forms';
import { RowTool } from "./tools/PolygonTools/RowTool";
import { GateTool } from "./tools/PolygonTools/GateTool";
import { LaneTool } from "./tools/PolygonTools/LaneTool";
import { SpaceTool } from "./tools/PolygonTools/SpaceTool";
import { ArrowTool } from "./tools/PolygonTools/MapItems/ArrowTool";
import { IslandTool } from "./tools/PolygonTools/MapItems/IslandTool";
import { BusinessTool } from "./tools/PolygonTools/MapItems/BusinessTool";
import { GrassIslandTool } from "./tools/PolygonTools/MapItems/GrassIslandTool";
import { GardenBedTool } from "./tools/PolygonTools/MapItems/GardenBedTool";
import { SolidWallTool } from "./tools/PolygonTools/MapItems/SolidWallTool";
import { PedestrianZoneTool } from "./tools/PolygonTools/MapItems/PedestrianZoneTool";
import { PillarTool } from "./tools/PolygonTools/MapItems/PillarTool";
import { RampTool } from "./tools/PolygonTools/MapItems/RampTool";
import { BushesTool } from "./tools/PolygonTools/MapItems/BushesTool";
import { TallTreeTool } from "./tools/PolygonTools/MapItems/TallTreeTool";
import { SmallTreeTool } from "./tools/PolygonTools/MapItems/SmallTreeTool";
import { ControllerTool } from "./tools/PointTools/ControllerTool";
import { CameraTool } from "./tools/PointTools/CameraTool";
import { BarrierTool } from "./tools/PointTools/BarrierTool";
import { TrafficLightTool } from "./tools/PointTools/TrafficLightTool";
import { GatewayTool } from "./tools/PointTools/GatewayTool";
import { SignTool } from "./tools/PointTools/SignTool";
import { GuidanceLightTool } from "./tools/PointTools/GuidanceLightTool";
import { Level } from "./components/PolygonComponents/Level";
import { Row } from "./components/PolygonComponents/Row";
import { Space } from "./components/PolygonComponents/Space";
import { Gate } from "./components/PolygonComponents/Gate";
import { Lane } from "./components/PolygonComponents/Lane";
import { Camera } from "./components/PointComponents/Camera";
import { Barrier } from "./components/PointComponents/Barrier";
import { TrafficLight } from "./components/PointComponents/TrafficLight";
import { Controller } from "./components/PointComponents/Controller";
import { Sign } from "./components/PointComponents/Sign";
import { Gateway } from "./components/PointComponents/Gateway";
import { MapItem } from "./components/PolygonComponents/MapItem";
import { PolygonMapComponent } from "./components/PolygonComponents/PolygonMapComponent";
import { PointMapComponent } from "./components/PointComponents/PointMapComponent";
import { SimpleModalService } from "ngx-simple-modal";
import { CarCounter } from "./components/PointComponents/CarCounter";
import { GuidanceLight } from "./components/PointComponents/GuidanceLight";
import { BehaviorSubject, map, timeout } from "rxjs";
import { Geography } from "src/app/util/geography";
import { Geo } from "src/app/util/geo";
import * as turf from '@turf/turf'
import { GoogleMap, MapPolygon } from "@angular/google-maps";
import { Tool } from "./tools/tool";
import { TabsComponent } from "src/app/shared/forms/tabs/tabs.component";
import { FabricHelpers } from "./fabric.js/helpers";
import { Point, Polygon } from "fabric/fabric-impl";
import { LeftArrowTool } from "./tools/PolygonTools/MapItems/LeftArrowTool";
import { RightArrowTool } from "./tools/PolygonTools/MapItems/RightArrowTool";
import { BiDirectionalArrow } from "./tools/PolygonTools/MapItems/BiDirectionalArrow";
import { LeftStraightArrowTool } from "./tools/PolygonTools/MapItems/LeftStraightArrowTool";
import { RightStraightArrowTool } from "./tools/PolygonTools/MapItems/RightStraightArrowTool";
import { UndoAction } from "./UndoActions/undoaction";
import { CloneUndoAction } from "./UndoActions/cloneundoaction";
import { LevelEditorHelper } from "./helper";
import { v4 as uuidv4 } from 'uuid';
import { FormRefreshUndoAction } from "./UndoActions/deleteundoaction";
//#endregion imports

@Component({
  selector: 'app-leveleditor',
  templateUrl: './leveleditor.component.html',
  styleUrls: ['./leveleditor.component.scss']
})
export class LevelEditorComponent implements OnInit,AfterViewInit {
  canvas: any;
  public ScaleFactor = 10000;
  public Form !: UntypedFormGroup;
  @Input()
  public ModelEditor !: ModelEditor;
  
  @ViewChild("DesignSurface")
  public DesignSurface !: ElementRef;

  @ViewChild("appTabs")
  public appTabs !: TabsComponent;

  @ViewChild("RightPanel")
  public RightPanel !: ElementRef;

  @ViewChild("LeftPanel")
  public LeftPanel !: ElementRef;

  public MapItems: MapComponentBase[] = [];
  public Tools : any[] = [];
  public SelectedItems: any[] = [];
  public MapItemsByType: Map<string, MapComponentBase[]> = new Map<string, MapComponentBase[]>();
  domSanitizer: any;
  ParkingSpaceTypes : any = null;
  public creationTool : any;
  private isDragging: boolean = false;
  private lastPosX: number = 0;
  private lastPosY: number = 0;
  public ToolInUse !: any;
  public TimeOfLastClick: number | null = null;
  public propertiesPaneVisible : boolean = false;
  public searchTerm !: string;
  public zoomLevel: number = 100;
  public EditingPolygon: boolean = false;
  public DeletePressed: boolean = false;
  ItemSelected = new EventEmitter<any>();
  ItemDeselected = new EventEmitter<any>();
  AllowedFormControls = new EventEmitter<string[]>();
  private allowedFormControlsSubject = new BehaviorSubject<string[]>([]);
  public AllowedFormControls$ = this.allowedFormControlsSubject.asObservable();
  isLeftPanelCollapsed: boolean = true;
  isRightPanelCollapsed: boolean = true;
  timeoutId: NodeJS.Timeout | null = null;
  public Panning : boolean = false;
  public PanX0 : number = 0;
  public PanY0 : number = 0;
  public MiddlePaneSize = 12;
  public ZoomHistory: number = 1;

  public LastXActions : any[] = [];
  public LevelEditorUndoActions: UndoAction[] = []
  public StoreXActions: number = 10;

  @ViewChild(GoogleMap)
  public GoogleMap !: GoogleMap;
  public GoogleMapLevelPath: google.maps.LatLngLiteral[] = [];
  public GoogleMapSpaces: google.maps.LatLngLiteral[][] = [];
  public GoogleMapCenter: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  public GoogleMapRotation !: number;
  public GoogleMapScaleX: number = 1;
  public GoogleMapScaleY: number = 1;
  @ViewChildren(MapPolygon)
  public MapPolygons: QueryList<MapPolygon> | null = null;

  @ViewChild('DesignSurface') designSurface !: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasContainer') canvasContainer !: ElementRef<HTMLDivElement>;

  updateHeightDebounced = this.debounce(this.updateHeightFn, 500);
  updateWidthDebounced = this.debounce(this.updateWidthFn, 500);
  updateLeftDebounced = this.debounce(this.updateLeftFn, 500);
  updateTopDebounced = this.debounce(this.updateTopFn, 500);
  updateAngleDebounced = this.debounce(this.updateAngleFn, 500);
  updateControlValues = this.debounce(this.updateControlValuesFn.bind(this), 500);

  public BusinessDirectories: any[] = [];
  public ThresholdSets: any[] = [];

  @Input()
  public set Level(value: UntypedFormGroup) {  
    this.Form = value;
    this.Form.markAsPristine();
  }

  constructor(private apiService: ApiService, public mediaService: MediaService, private cdr: ChangeDetectorRef, public toastService: ToastrService, public modalService: SimpleModalService, public fb: FormBuilder) {
    this.apiService.Get<any>("infrastructure/thresholdsets").then(result => {
      this.ThresholdSets = result;
    });
  }

  toggleLeftPanel(){
    this.isLeftPanelCollapsed = !this.isLeftPanelCollapsed;
    console.log("left panel collapsed: " + this.isLeftPanelCollapsed);
    if(this.isLeftPanelCollapsed){
      this.MiddlePaneSize = this.MiddlePaneSize + 2 
    }
    else{
      this.MiddlePaneSize = this.MiddlePaneSize - 2
    }
    
  }

  itemIsPolygon(){
     if(this.SelectedItems[0] instanceof PolygonMapComponent){
      return true;
     }
     return false;
  }


  toggleRightPanel(){
    this.isRightPanelCollapsed = !this.isRightPanelCollapsed;
    console.log("right panel collapsed: " + this.isRightPanelCollapsed);
    if(this.isRightPanelCollapsed){
      this.MiddlePaneSize = this.MiddlePaneSize + 3 
    }
    else{
      this.MiddlePaneSize = this.MiddlePaneSize - 3
    }
  }


  Undo(){
    if(this.LastXActions.length > 0){
      const lastAction = this.LastXActions.pop();
      lastAction.forEach((itemId: any)=> {

        if(itemId == 0){
          var lastFormAction = this.LevelEditorUndoActions.pop();
          lastFormAction?.Undo();
          return;
        }

        var item = this.MapItems.find(x => x.Id == itemId);
        item?.UndoLastAction();
      });
    }
    else{
      this.toastService.info("No Actions left to Undo");
    }
  }
  
  //#region toggle

  TogglePane(){
    this.propertiesPaneVisible = !this.propertiesPaneVisible;
  }

  HandleSingleClick(tool: any) {
    if(this.ToolInUse != null && this.ToolInUse.Name == tool.Name && !this.ToolInUse.Tool.Locked){
      this.ToolInUse = null;
      return;
    }
    if(this.ToolInUse != null && this.ToolInUse.Tool.Locked && (this.ToolInUse.Name != tool.Name)){
      this.toastService.error("You have another tool locked. Please unlock the tool to swap tools.")
      return;
    }
    this.ToolInUse = tool;

    this.TurnOffSelect();
  }

  HandleDoubleClick(event: any, tool: any) {
    this.ToggleLock(event, tool);
  }

  ToggleParentVisibility(parent: any) {
    parent.isVisible = !parent.isVisible;
    this.cdr.detectChanges();
  }

  ToggleLock(event: any, tool: any) {
    event.preventDefault();
    event.stopPropagation();

    if(this.ToolInUse != null && this.ToolInUse.Tool.Locked && this.ToolInUse.Name != tool.Name){
      this.toastService.error("You have another tool locked. Please unlock the tool to swap tools.")
      return;
    }

    this.ToolInUse = tool;
    (this.ToolInUse.Tool).Locked = !(this.ToolInUse.Tool).Locked;

    if(!(this.ToolInUse.Tool).Locked){
      this.ToolInUse = null;
      this.toastService.info("Tool unlocked");
      this.TurnOnSelect();
    }

    if(this.ToolInUse){
      this.toastService.info("Tool locked. Click on the Canvas to add an item for every click. You will need to unlock this tool before you can use other tools.");
    }
  }

  TurnOnSelect(){
    this.canvas.forEachObject((obj :any) => {
      // Check if the object is an instance of fabric.Line
      if (!(obj instanceof fabric.Line)) {
        // Make all other objects selectable
        obj.selectable = true;
      } else {
        // Ensure lines are not selectable
        obj.selectable = false;
      }
    });
  }

  TurnOffSelect(){
    this.canvas.forEachObject((obj :any) => {
      if ((obj instanceof fabric.Object)) {
        // Make all other objects unselectable
        obj.selectable = false;
      }
    });
  }

  //#region tools
  public UseTool(evt: any){
      //lock all items.
    var undoActions : any[] = []
      this.TurnOffSelect();
      if(this.ToolInUse.Tool instanceof Tool){
        const canvasX = evt.pointer.x;
        const canvasY = evt.pointer.y;
        var item = (this.ToolInUse.Tool as Tool).CreateNew(canvasX, canvasY);
        (item as MapComponentBase).IsToolItem = true;
        (item as MapComponentBase).Zoom = 1;
        if(item){
          if(item instanceof PointMapComponent){
            item.ImageLoadedAndUpdated.subscribe((result: PointMapComponent) => {
              this.AddMapItem(result);
              item?.FabricItem.fire('modified');
              if(!this.ToolInUse.Tool.Locked){
                this.ToolInUse = null;
                this.TurnOnSelect();
                result?.SetSelectedObject();
              }
            })
          }
          else{        
              this.AddMapItem(item);
              (item as PolygonMapComponent).Polygon.fire('modified');
              if(!this.ToolInUse.Tool.Locked){
                this.ToolInUse = null;
                this.TurnOnSelect();
                item?.SetSelectedObject();
              }
          }

          item.UndoActions.push(new CloneUndoAction(this.getCanvasState(), this, item.formItem, undefined, item.parentFormItem, item.ClassName + 's'))
          undoActions.push(item.Id);
        }
      }
      this.ItemModified(undoActions);
  }

  public ToolIsSelected(tool: any): boolean {
    if(this.ToolInUse == null){
      return false;
    }
    return tool.Name == this.ToolInUse?.Name;
  }

  public GetTools() {
   return [
    {Name: "Parking Tools", isVisible: true, Tools: [
          { Name: "Row", Tool: new RowTool(this, this.canvas), Icon: ""},
          { Name: "Gate", Tool: new GateTool(this, this.canvas), Icon: "" },
          { Name: "Lane", Tool: new LaneTool(this, this.canvas), Icon: "" },
          { Name: "Space", Tool: new SpaceTool(this, this.canvas, this.ParkingSpaceTypes), Icon: "" }]},
    {Name: "Devices", isVisible: true, Tools: [
            { Name: "Controller", Tool: new ControllerTool(this, this.canvas), Icon: "" },
            { Name: "Camera", Tool: new CameraTool(this, this.canvas), Icon: "" },
            { Name: "Barrier", Tool: new BarrierTool(this, this.canvas), Icon: "" },
            { Name: "Traffic Light", Tool: new TrafficLightTool(this, this.canvas), Icon: "" },
            { Name: "Gateway", Tool: new GatewayTool(this, this.canvas), Icon: "" },
            { Name: "Sign", Tool: new SignTool(this, this.canvas), Icon: "" },
            { Name: "Guidance Light", Tool: new GuidanceLightTool(this, this.canvas), Icon: "" }]},
    { Name: "Map Extras", isVisible: true, Tools: [
            { Name: "Arrow", Tool: new ArrowTool(this, this.canvas), Icon: "" },
            { Name: "Left Arrow", Tool: new LeftArrowTool(this, this.canvas), Icon: "" },
            { Name: "Right Arrow", Tool: new RightArrowTool(this, this.canvas), Icon: "" },
            { Name: "BiDirectional Arrow", Tool: new BiDirectionalArrow(this, this.canvas), Icon: "" },
            { Name: "Forward or Left Arrow", Tool: new LeftStraightArrowTool(this, this.canvas), Icon: "" },
            { Name: "Forward or Right Arrow", Tool: new RightStraightArrowTool(this, this.canvas), Icon: "" },
            { Name: "Island", Tool: new IslandTool(this, this.canvas), Icon: "" },
            { Name: "Business", Tool: new BusinessTool(this, this.canvas), Icon: "" },
            { Name: "Grass Island", Tool: new GrassIslandTool(this, this.canvas), Icon: "" },
            { Name: "Garden Bed", Tool: new GardenBedTool(this, this.canvas), Icon: "" },
            { Name: "Wall", Tool: new SolidWallTool(this, this.canvas), Icon: "" },
            { Name: "Pedestrian Zone", Tool: new PedestrianZoneTool(this, this.canvas), Icon: "" },
            { Name: "Pillar", Tool: new PillarTool(this, this.canvas), Icon: "" },
            { Name: "Ramp", Tool: new RampTool(this, this.canvas), Icon: "" },
            { Name: "Bushes", Tool: new BushesTool(this, this.canvas), Icon: "" },
            { Name: "Tall Tree", Tool: new TallTreeTool(this, this.canvas), Icon: "" },
            { Name: "Small Tree", Tool: new SmallTreeTool(this, this.canvas), Icon: "" },
      ]
    }
  ];
  }
  //#endregion tools

  //#region init
  ngOnInit(): void {
      //ensure space types are loaded before init
      this.apiService.Get<any>("infrastructure/spacetypes").then((result) => {
        this.ParkingSpaceTypes = result;

        this.GetBusinessDirectories();
        this.LoadCanvas();

        this.ParkingSpaceTypes.forEach(async (o : any) => {
          await this.mediaService.GetBase64Media(o.MediaId).subscribe(result => {
            var string = result.Base64Prefix + result.Base64String;
            o["HtmlImage"] = string;
          });
      });
    });
  }

  ngAfterViewInit(): void {
    this.appTabs.SelectedIndexChanged.subscribe(x => {
      this.GoogleMapRotation = this.Form.get("GeoRotation")?.value ?? 90;
      this.UpdateGoogleMapPath(null, null);
    })
  }

  LoadCanvas(){
    if(this.canvas){
      //if canvas is not empty, completely reinit it.
      this.MapItems = [];
      this.MapItemsByType = new Map<string, MapComponentBase[]>();
      this.MapPolygons = null;
      this.canvas.off();
      this.canvas.dispose(); // This will remove all Fabric.js objects and internal references
      this.canvas = null;
    }
    this.canvas = new fabric.Canvas('mapCanvas', { width: 2500, height: 1000, preserveObjectStacking: true, fireMiddleClick: true, perPixelTargetFind: false, fireRightClick: true, defaultCursor: 'default'});
    this.zoomLevel = this.canvas.getZoom();
    this.CreateBlueprintBackground();
    this.DrawMapComponents();
    this.AddEventListeners();
    this.Tools = this.GetTools();
  }

  StartPan(event: any){
    this.PanX0 = event.e.screenX,
    this.PanY0 = event.e.screenY;
  }

  ContinuePan(event: any) {
    var x = event.e.screenX,
        y = event.e.screenY;
    this.canvas.relativePan({ x: x - this.PanX0, y: y - this.PanY0 });
    this.PanX0 = x;
    this.PanY0 = y;
  }

  StopPan(event: any){
    this.canvas.off('mousemove', this.ContinuePan);
    this.canvas.off('mouseup', this.StopPan);
  }

  //Event Listeners for Canvas
  AddEventListeners(){
    const wrapper = document.getElementById('wrapper');
    if (wrapper) {
        wrapper.addEventListener('wheel', this.handleMouseScroll.bind(this));
    }
    
    this.canvas.on('selection:created', (e: any) => {
      //prevent marquee selection on polygon edit mode.
      if(e.selected.length > 1 && this.EditingPolygon) {
        this.canvas.discardActiveObject();
        this.toastService.info("Cannot use marquee selection on polygon edit mode.")
      }
    });


    this.canvas.on('mouse:down', (event : any) => {
        if(event.e.button == 1){
          this.Panning = true;
          this.StartPan(event);
          return;
        }
    });

    this.canvas.on('mouse:up', (event: any) => {
      if(event.e.button == 1){
        this.Panning = false;
        this.StopPan(event);
      }
      else if(this.ToolInUse != null){ 
        this.UseTool(event);
      }
    })

    this.canvas.on('mouse:move', (event: any) => {
      if(this.Panning){
        this.ContinuePan(event);
      }
    });




    this.canvas.on('object:modified', (options: any) => {
      var undoActions: any[] = [];

      this.SelectedItems.sort((a: MapComponentBase, b: MapComponentBase) => {
        // Check if 'a' has ParentItems and 'b' does not
        if (a.RequiresParent && !b.RequiresParent) {
            return 1; // 'a' should come after 'b'
        }
        // Check if 'b' has ParentItems and 'a' does not
        if (!a.RequiresParent && b.RequiresParent) {
            return -1; // 'a' should come before 'b'
        }   
        return 0;
      });

      this.SelectedItems.forEach((item: MapComponentBase) => {
        if(item instanceof PointMapComponent){
          //we do this one because some items shift Ids
          var formUndoAction = new FormRefreshUndoAction(this.getCanvasState(), this, this.copyFormGroup(this.Form, false), undefined, undefined, undefined, undefined);
          item.FabricItem.fire('modified');
          formUndoAction.newFormItem = this.Form;
          item.UndoActions.push(formUndoAction);
          undoActions.push(item.Id);
        }

        if(item instanceof PolygonMapComponent){
          console.log('calling polygon modified');
          var polyAction = new UndoAction(this.getCanvasState(), this, item.formItem, 'PolygonPoints', item.formItem.get('PolygonPoints')?.value, undefined);
          item.Polygon.fire('modified');
          polyAction.NewValue = item.formItem.get('PolygonPoints')?.value;
          item.UndoActions.push(polyAction);
          undoActions.push(item.Id);
        }
      });

      this.ItemModified(undoActions);
    });

    this.ItemSelected.subscribe((item: any) => {

      console.log(item.ClassName + 'has been Selected');
      if(this.Panning){
        console.log("Panning action, do nothing");
        return;
      }

      var objects = this.canvas.getActiveObjects();
      if (objects && (objects.length === 1 || objects.length === 0)) {
          this.SelectedItems = [];
      }
      this.SelectedItems.push(item);

      const firstItemControls = Object.keys(item.formItem.controls);

      // Check if each form control exists in all selected items
      const allowedFormControls = firstItemControls.filter(controlName =>
          this.isAllowedControl(controlName) &&
          this.SelectedItems.every(item => item.formItem.get(controlName))
      );

      // Emit the list of allowed form controls
      this.allowedFormControlsSubject.next(allowedFormControls);
    });


    this.ItemDeselected.subscribe((item: any) => {
      if(item.Editing){
        this.toastService.info("cannot deselect a polygon in edit mode");
        this.canvas.SetSelectedObject(item.FabricItem);
        this.SelectedItems.push(item);
        return;
      }
      const index = this.SelectedItems.indexOf(item);
      if (index !== -1) {
          this.SelectedItems.splice(index, 1);
      }
    })

    window.addEventListener('keyup', (event) => {
      switch (event.key) {
        case 'Del': 
          if(this.DeletePressed){
            this.deleteSelectedObjects();
            this.DeletePressed = false;
          }
          break;
        case 'Delete': 
          if(this.DeletePressed){
            this.deleteSelectedObjects();
            this.DeletePressed = false;
          }
      }
    })

    window.addEventListener('keydown', (event) => {
          const isInputFocused = document.activeElement instanceof HTMLInputElement
            || document.activeElement instanceof HTMLTextAreaElement;
        
          if (isInputFocused) {
            return; // Do nothing if an input field is focused
          }

          switch (event.key) {
              case 'Del': 
                this.DeletePressed = true;
              break;
              case 'Delete': 
                this.DeletePressed = true;
              break;
              case 'ArrowLeft':
                var selectedItems : MapComponentBase[] = [];
                if (this.SelectedItems.length > 0) {
                  // Iterate over each selected object
                  var itemsUpdated: any = [];
                  this.SelectedItems.forEach((mapItem: MapComponentBase) => {
                    selectedItems.push(mapItem);
                        if(mapItem instanceof PolygonMapComponent){
                          var undo = new UndoAction(this.getCanvasState(), this, mapItem.formItem, 'PolygonPoints', mapItem.formItem.get('PolygonPoints')?.value);
                          mapItem.FabricItem.set({ left: (mapItem.FabricItem.left ?? 0) - 1 });
                          mapItem.Polygon.fire('modified');
                          mapItem.FabricItem.setCoords();
                          undo.NewValue = mapItem.formItem.get('PolygonPoints')?.value;

                          mapItem.UndoActions.push(undo);
                          itemsUpdated.push(mapItem.Id);
                        }
                        if(mapItem instanceof PointMapComponent){
                          var undo = new UndoAction(this.getCanvasState(), this, mapItem.formItem, 'LocationPoints', mapItem.formItem.get('LocationPoints')?.value);
                          mapItem.FabricItem.set({ left: (mapItem.FabricItem.left ?? 0) - 1 });
                          mapItem.FabricItem.fire('modified');
                          mapItem.FabricItem.setCoords();
                          undo.NewValue = mapItem.formItem.get('LocationPoints')?.value;
                          mapItem.UndoActions.push(undo);
                          itemsUpdated.push(mapItem.Id);
                        }
                  });
                  this.LastXActions.push(itemsUpdated);

                  this.canvas.discardActiveObject(); // Deselect all objects first
                  const selection = new fabric.ActiveSelection(selectedItems.map(item => item.FabricItem), {
                      canvas: this.canvas
                  });

                  this.canvas.setActiveObject(selection);
                  this.Form.markAsDirty();

                  this.SelectedItems = [];
              
                  selectedItems.forEach((newItem: any) => {
                    this.SelectedItems.push(newItem);
                  });

                }
                break;
              case 'ArrowRight':
                var selectedItems : MapComponentBase[] = [];
                if (this.SelectedItems.length > 0) {
                  var itemsUpdated: any = [];
                  // Iterate over each selected object
                  this.SelectedItems.forEach((mapItem: MapComponentBase) => {
                    selectedItems.push(mapItem);
                        if(mapItem instanceof PolygonMapComponent){
                          var undo = new UndoAction(this.getCanvasState(), this, mapItem.formItem, 'PolygonPoints', mapItem.formItem.get('PolygonPoints')?.value);
                          mapItem.FabricItem.set({ left: (mapItem.FabricItem.left ?? 0) + 1 });
                          mapItem.Polygon.fire('modified');
                          mapItem.FabricItem.setCoords();
                          undo.NewValue = mapItem.formItem.get('PolygonPoints')?.value;

                          mapItem.UndoActions.push(undo);
                          itemsUpdated.push(mapItem.Id);
                        }
                        if(mapItem instanceof PointMapComponent){
                          var undo = new UndoAction(this.getCanvasState(), this, mapItem.formItem, 'LocationPoints', mapItem.formItem.get('LocationPoints')?.value);
                          mapItem.FabricItem.set({ left: (mapItem.FabricItem.left ?? 0) + 1 });
                          mapItem.FabricItem.fire('modified');
                          mapItem.FabricItem.setCoords();
                          undo.NewValue = mapItem.formItem.get('LocationPoints')?.value;
                          mapItem.UndoActions.push(undo);
                          itemsUpdated.push(mapItem.Id);
                        }
                  });
                  this.LastXActions.push(itemsUpdated);

                  this.canvas.discardActiveObject(); // Deselect all objects first
                  const selection = new fabric.ActiveSelection(selectedItems.map(item => item.FabricItem), {
                      canvas: this.canvas
                  });
                  this.canvas.setActiveObject(selection);
                  this.Form.markAsDirty();

                  this.SelectedItems = [];
              
                  selectedItems.forEach((newItem: any) => {
                    this.SelectedItems.push(newItem);
                  });
                }
              break;
              case 'ArrowUp':
                var selectedItems : MapComponentBase[] = [];
                if (this.SelectedItems.length > 0) {
                  var itemsUpdated: any = [];
                  // Iterate over each selected object
                  this.SelectedItems.forEach((mapItem: MapComponentBase) => {
                    selectedItems.push(mapItem);
                        if(mapItem instanceof PolygonMapComponent){
                          var undo = new UndoAction(this.getCanvasState(), this, mapItem.formItem, 'PolygonPoints', mapItem.formItem.get('PolygonPoints')?.value);
                          mapItem.FabricItem.set({ top: (mapItem.FabricItem.top ?? 0) - 1 });
                          mapItem.Polygon.fire('modified');
                          mapItem.FabricItem.setCoords();
                          undo.NewValue = mapItem.formItem.get('PolygonPoints')?.value;

                          mapItem.UndoActions.push(undo);
                          itemsUpdated.push(mapItem.Id);
                        }
                        if(mapItem instanceof PointMapComponent){
                          var undo = new UndoAction(this.getCanvasState(), this, mapItem.formItem, 'LocationPoints', mapItem.formItem.get('LocationPoints')?.value);                          mapItem.FabricItem.set({ top: (mapItem.FabricItem.top ?? 0) - 1 });
                          mapItem.FabricItem.set({ top: (mapItem.FabricItem.top ?? 0) - 1 });
                          mapItem.FabricItem.fire('modified');
                          undo.NewValue = mapItem.formItem.get('LocationPoints')?.value;
                          mapItem.UndoActions.push(undo);
                          itemsUpdated.push(mapItem.Id);
                        }
                  });
                  this.LastXActions.push(itemsUpdated);

                  this.canvas.discardActiveObject(); // Deselect all objects first
                  const selection = new fabric.ActiveSelection(selectedItems.map(item => item.FabricItem), {
                      canvas: this.canvas
                  });
                  this.canvas.setActiveObject(selection);
                  this.Form.markAsDirty();

                  this.SelectedItems = [];
              
                  selectedItems.forEach((newItem: any) => {
                    this.SelectedItems.push(newItem);
                  });
                }
              break;
              case 'ArrowDown':
              var selectedItems : MapComponentBase[] = [];
                if (this.SelectedItems.length > 0) {
                  var itemsUpdated: any = [];

                  // Iterate over each selected object
                  this.SelectedItems.forEach((mapItem: MapComponentBase) => {
                    selectedItems.push(mapItem);
                        if(mapItem instanceof PolygonMapComponent){
                          var undo = new UndoAction(this.getCanvasState(), this, mapItem.formItem, 'PolygonPoints', mapItem.formItem.get('PolygonPoints')?.value);
                          mapItem.FabricItem.set({ top: (mapItem.FabricItem.top ?? 0) + 1 });
                          mapItem.Polygon.fire('modified');
                          mapItem.FabricItem.setCoords();
                          undo.NewValue = mapItem.formItem.get('PolygonPoints')?.value;
                          mapItem.UndoActions.push(undo);
                          itemsUpdated.push(mapItem.Id); 
                        }
                        if(mapItem instanceof PointMapComponent){
                          var undo = new UndoAction(this.getCanvasState(), this, mapItem.formItem, 'LocationPoints', mapItem.formItem.get('LocationPoints')?.value);
                          mapItem.FabricItem.set({ top: (mapItem.FabricItem.top ?? 0) + 1 });
                          mapItem.FabricItem.fire('modified');
                          undo.NewValue = mapItem.formItem.get('LocationPoints')?.value;
                          mapItem.UndoActions.push(undo);
                          itemsUpdated.push(mapItem.Id);                        
                        }
                  });
                  this.LastXActions.push(itemsUpdated);

                  this.canvas.discardActiveObject(); // Deselect all objects first
                  const selection = new fabric.ActiveSelection(selectedItems.map(item => item.FabricItem), {
                      canvas: this.canvas
                  });
                  this.canvas.setActiveObject(selection);
                  this.Form.markAsDirty();

                  this.SelectedItems = [];
              
                  selectedItems.forEach((newItem: any) => {
                    this.SelectedItems.push(newItem);
                  });
                }
                  break;
              default:
                  // Other keys pressed
                  break;
            }
      });
  }

  //#endregion init

  areAllItemsPolygonMapComponents(): boolean {
    return this.SelectedItems.every(item => item instanceof PolygonMapComponent);
  }

  BuildInitialChipId(){
    return { ChipId: this.SelectedItems[0].formItem.get("ChipId")?.value}
  }

  isAllowedControl(controlName: string): boolean {
    const allowedControls = ['Name','OutlineColor', 'FillColor', 'EditorLocked', "ParkingSpaceTypeId", "ChipId", 'LocalControllerId', 'EntrySegment', 'ExitSegment', 'IsRising', 'BusinessDirectoryId', 'ParkingThresholdSetId', 'AvailabilityCountMode'];
    return allowedControls.includes(controlName);
  }

  BusinessIdChange(business: any) {
    var t = business;
    this.SelectedItems[0].formItem.get('BusinessDirectoryId').setValue(t.Id);
    this.Form.markAsDirty();
  }

  ItemModified(children: any[]){
    this.LastXActions.push(children);
  }

  UpdateItemLocked(event: any){
    var selectedItems: MapComponentBase[] = []
    this.SelectedItems.forEach((item: MapComponentBase) => {
      selectedItems.push(item);
      item.Locked = event?.target.checked;
      item.formItem.get('EditorLocked').setValue(event?.target.checked);
      item.AddFabricItem();
    });

    this.SelectedItems = selectedItems;
  }

  updateControlValuesFn(controlName: string, event: any): void {
    let undoActions: any[] = []; 

      let value: any;
      if (typeof event === 'string') {
        value = event;
      }
      else if (event.target.type === 'checkbox') {
          value = event.target.checked;
      }
      else if (!isNaN(parseFloat(event.target.value))) {
          // Check if the value is a valid number
          value = parseFloat(event.target.value); // or parseInt(event.target.value) for integer
      } else {
          value = event.target.value;
      }

      value = (value === 'null' ? null : value);

      var selectedItems: MapComponentBase[] = []
      this.SelectedItems.forEach((item: MapComponentBase) => {  
        selectedItems.push(item);
          item.UndoActions.push(new UndoAction(this.getCanvasState(), this,  item.formItem, controlName, item.formItem.get(controlName).value, value))
          item.formItem.get(controlName)?.setValue(value, { emitEvent: false });
          item.AddFabricItem();
          undoActions.push(item.Id);
      });

      //re-add new items to selected items list, and active selection

      const newGroups = selectedItems.map((newItem: any) => newItem.FabricItem);
      if (newGroups.length > 0) {
        const activeSelection = new fabric.ActiveSelection(newGroups, {
            canvas: this.canvas,
        });
        this.canvas.setActiveObject(activeSelection);
      }
      this.SelectedItems = selectedItems;
      
      this.ItemModified(undoActions); 
      this.Form.markAsDirty();
  }

  getTop(){
    if(this.SelectedItems[0] instanceof MapComponentBase){
      return Math.round(this.SelectedItems[0].FabricItem.top ?? 0) / 10;
    }
    return null;
  }

  getLeft(){
    if(this.SelectedItems[0] instanceof MapComponentBase){
      return Math.round(this.SelectedItems[0].FabricItem.left ?? 0) / 10;
    }
    return null;
  }


  getAngle(){
    if(this.areValuesEqual("DisplayAngle")){
      return Math.round(this.SelectedItems[0].FabricItem.angle ?? 0);
    }
    return null;
  }

  updateAngle(event: any){
    this.SelectedItems.forEach((mapItem: MapComponentBase) => {
      mapItem.Rotate(event.target.value);
    })
  }

  updateHeight(event: any): void {
    let undoActions: any[] = [];
    this.SelectedItems.forEach(item => {
      var undoAction = new UndoAction(this.getCanvasState(), this,  item.formItem, 'PolygonPoints', item.formItem.get('PolygonPoints').value, undefined)
      if (item instanceof PolygonMapComponent) {
        const polyPoints = item.Polygon.points;
        if (polyPoints) {
          const minY = Math.min(...polyPoints.map(point => point.y));
          const maxY = Math.max(...polyPoints.map(point => point.y));
          const currentHeight = maxY - minY;
  
          // Calculate the scaling factor
          const scaleY = (event.target.value*10) / currentHeight;
  
          // Calculate the center y-coordinate of the polygon
          const centerY = (minY + maxY) / 2;
  
          // Update the y-coordinates of the points
          const updatedPoints = polyPoints.map(point => {
            return new fabric.Point(point.x, centerY + (point.y - centerY) * scaleY);
          });
  
          // Set the updated points to the polygon
          item.Polygon.set({ points: updatedPoints });
          item.Polygon.setCoords();
          item.Polygon.getBoundingRect();
          item.Polygon.fire('modified');
          item.FabricItem.setCoords();
          item.FabricItem.getBoundingRect();
          undoAction.NewValue = item.formItem.get('PolygonPoints')?.value;
          item.UndoActions.push(undoAction)
          this.canvas.renderAll();
        }
      }
      undoActions.push(item.Id);
    });

    this.ItemModified(undoActions);
    // Mark the form as dirty
    this.Form.markAsDirty();
  }

  updateWidth(event: any): void {
    let undoActions: any[] = [];
    this.SelectedItems.forEach((item: MapComponentBase)=> {
      var undoAction = new UndoAction(this.getCanvasState(), this,  item.formItem, 'PolygonPoints', item.formItem.get('PolygonPoints').value, undefined)
      if (item instanceof PolygonMapComponent) {
        const polyPoints = item.Polygon.points;
        if (polyPoints) {
          const minX = Math.min(...polyPoints.map(point => point.x));
          const maxX = Math.max(...polyPoints.map(point => point.x));
          const currentWidth = maxX - minX;
          // Calculate the scaling factor
          const scaleX = (event.target.value* 10) / currentWidth;
          // Calculate the center x-coordinate of the polygon
          const centerX = (minX + maxX) / 2;
          // Update the x-coordinates of the points
          const updatedPoints = polyPoints.map(point => {
            return new fabric.Point(centerX + (point.x - centerX) * scaleX, point.y);
          });

          item.Polygon.set({ points: updatedPoints });
          item.Polygon.fire('modified');

          item.Polygon.setCoords();
          item.FabricItem.setCoords();
          this.canvas.renderAll();

          undoAction.NewValue = item.formItem.get('PolygonPoints')?.value;
          item.UndoActions.push(undoAction);
          item.DrawMeasurementLines();
          this.canvas.renderAll();
        }
      }

      undoActions.push(item.Id);
    });

    this.ItemModified(undoActions);
  
    // Mark the form as dirty
    this.Form.markAsDirty();
  }

  updateLeft(event: any): void {
    let undoActions: any[] = [];
    const newValue = parseFloat(event.target.value) * 10;
    this.SelectedItems.forEach(item => {
      var undoAction = new UndoAction(this.getCanvasState(), this,  item.formItem, 'PolygonPoints', item.formItem.get('PolygonPoints').value, undefined)
        if (item instanceof PolygonMapComponent) {
            // Update the group's left position in canvas coordinates
            item.FabricItem.set({ left: newValue });
            item.FabricItem.setCoords(); // Update the coordinates
            // Trigger the 'modified' event to update bounding box and controls
            item.Polygon.fire('modified');
        }
        if (item instanceof PointMapComponent){
            item.FabricItem.set({ left: newValue });
            item.FabricItem.fire('modified');
        }
        undoAction.NewValue = item.formItem.get('PolygonPoints')?.value;
        item.UndoActions.push(undoAction)
        undoActions.push(item.Id);
        this.canvas.renderAll();
    });
    this.ItemModified(undoActions);
    this.Form.markAsDirty();
  }

  updateTop(event: any): void {
    let undoActions: any[] = [];
    const newValue = parseFloat(event.target.value) * 10;
    this.SelectedItems.forEach((item : MapComponentBase) => {
          var undoAction = new UndoAction(this.getCanvasState(), this,  item.formItem, 'PolygonPoints', item.formItem.get('PolygonPoints').value, undefined)
          item.FabricItem.set({ top: newValue });
          item.FabricItem.setCoords();
          item.FabricItem.fire('modified');
          undoAction.NewValue = item.formItem.get('PolygonPoints')?.value;
          item.UndoActions.push(undoAction);
          undoActions.push(item.Id);
          this.canvas.renderAll();
    });
    this.ItemModified(undoActions);
    this.Form.markAsDirty();
  }

  areValuesEqual(controlName: string) {
      const firstValue = this.SelectedItems[0].formItem.get(controlName)?.value;
      if(this.SelectedItems.every(item => item.formItem.get(controlName)?.value === firstValue))
      {
        if (typeof firstValue === 'number') {
          return Math.round(firstValue);
        }
        if (typeof firstValue === 'boolean') {
          return firstValue;
        }
        return firstValue;
      }
      return null;
  }

  areWidthValuesEqual() {
      var width : number = 0;
      if(this.SelectedItems[0] instanceof PolygonMapComponent){
        width = this.SelectedItems[0].Polygon.width ?? 0;
      }

      if(this.SelectedItems.every(item => (item instanceof PolygonMapComponent && item.Polygon.width == width)))
      {
        return Math.round(width) / 10;
      }
      return null;
  }

  showMeasurementLinesEqual(){
    if(this.SelectedItems.some((item: PolygonMapComponent) => !item.ShowMeasurementLines)){
      return false;
    }
    return true;
  }

  ShowMeasurementLines(event: any){
    this.SelectedItems.forEach((item: PolygonMapComponent) => {
      item.ShowMeasurementLines = event.target.checked;
      item.DrawMeasurementLines();
      this.canvas.renderAll();
    });
  }

  areHeightValuesEqual() {
      var height : number = 0;
      if(this.SelectedItems[0] instanceof PolygonMapComponent){
        height = this.SelectedItems[0].Polygon.height ?? 0;
      }

      if(this.SelectedItems.every(item => (item instanceof PolygonMapComponent && item.Polygon.height == height)))
      {
        return Math.round(height) / 10;
      }
      return null;
  }

  getValueForControl(controlName: string): any {
    return this.areValuesEqual(controlName) ? this.SelectedItems[0].formItem.get(controlName)?.value : '';
  }

  //#region mapcomponents

  ItemsAreBarriers(){
    return this.SelectedItems.every(x => x instanceof Barrier);
  }

  public CreateBlueprintBackground(){
  this.canvas.setBackgroundColor('#3b6d40', this.canvas.renderAll.bind(this.canvas));
      for (let i = -4 * this.canvas.height; i <= 4 * this.canvas.height; i += 20) {
        const strokeWidth = i % 100 === 0 ? 2 : 1; // Set thicker strokeWidth for every 5th line
        const strokeDashArray = strokeWidth === 2 ? null : [3, 3]; // Set strokeDashArray for non-5th lines
      
        const line = new fabric.Line([-4 * this.canvas.width, i, 4 * this.canvas.width, i], {
          stroke: 'white',
          strokeWidth: strokeWidth,
          selectable: false,
          opacity: 0.1, // Set opacity to 0.2 for all lines
          strokeDashArray: strokeDashArray ?? [], // Set strokeDashArray
        });
        this.canvas.add(line);
      }
      
      // Draw vertical lines
      for (let i = -4 * this.canvas.width; i <= 4 * this.canvas.width; i += 20) {
        const strokeWidth = i % 100 === 0 ? 2 : 1; // Set thicker strokeWidth for every 5th line
        const strokeDashArray = strokeWidth === 2 ? null : [3, 3]; // Set strokeDashArray for non-5th lines
      
        const line = new fabric.Line([i, -4 * this.canvas.height, i, 4 * this.canvas.height], {
          stroke: 'white',
          strokeWidth: strokeWidth,
          selectable: false,
          opacity: 0.1, // Set opacity to 0.2 for all lines
          strokeDashArray: strokeDashArray ?? [], // Set strokeDashArray
        });
        this.canvas.add(line);
      }
  }

  public DrawMapComponents(){
    if(this.Form.get("PolygonPoints")?.value.length == 0){
      const polygonPoints = [
        [0.02897601782376105, 0.013558977889468412],
        [0.04437601782376104, 0.013558977889468412],
        [0.05607601782376105, 0.013558977889468412],
        [0.08527601782376105, 0.013558977889468412],
        [0.09087601782376105, 0.013558977889468412],
        [0.09087601782376105, 0.03335897788946842],
        [0.09087601782376105, 0.06175897788946841],
        [0.05607601782376105, 0.06175897788946841],
        [0.02897601782376105, 0.06175897788946841],
        [0.02897601782376105, 0.013558977889468412]
      ];  
      if (this.Form.get("PolygonPoints")?.value.length === 0) {
        this.Form.get("PolygonPoints")?.setValue(polygonPoints);
      }
    }

    var levelComponent = new Level(this, this.canvas, this.Form, null);
    this.AddMapItem(levelComponent);
    let rows = this.Form.get("Rows") as UntypedFormArray;
    if (rows == null) {
      rows = new UntypedFormArray([]);
      this.Form.setControl("Rows", rows);
    }
    for (let row of rows.controls) {
      let rowComponent = new Row(this, this.canvas, row, null);
      this.AddMapItem(rowComponent);
      let spaces = row.get("Spaces") as UntypedFormArray;
      if (spaces != null) {
          for (let space of spaces.controls) {
            let spaceComponent = new Space(this, this.canvas, space, row, this.ParkingSpaceTypes);
            this.AddMapItem(spaceComponent);
          }
      }
      let guidancelights = row.get("GuidanceLightConfigurations") as UntypedFormArray;
      for (let guidancelight of guidancelights.controls) {
        let guidancelightComponent = new GuidanceLight(this, this.canvas, guidancelight, row);
        this.AddMapItem(guidancelightComponent);
      }
    }
    let gates = this.Form.get("Gates") as UntypedFormArray;
    for (let gate of gates.controls) {
      let gateComponent = new Gate(this, this.canvas, gate, null);
      this.AddMapItem(gateComponent);
      let lanes = gate.get("Lanes") as UntypedFormArray;
      if (lanes != null) {
        for (let lane of lanes.controls) {
          let laneComponent = new Lane(this, this.canvas, lane, gate);
          this.AddMapItem(laneComponent);
          let cameras = lane.get("Cameras") as UntypedFormArray;
          if (cameras != null) {
            for (let camera of cameras.controls) {
              let CameraComponent = new Camera(this, this.canvas, camera, lane);
              this.AddMapItem(CameraComponent);
            }
          }
          let barriers = lane.get("Barriers") as UntypedFormArray;
              if (barriers != null) {
                for (let barrier of barriers.controls) {
                  let BarrierComponent = new Barrier(this, this.canvas, barrier, lane); 
                  this.AddMapItem(BarrierComponent);
              }
            }
          let trafficLights = lane.get("TrafficLights") as UntypedFormArray;
          if (trafficLights != null) {
            for (let trafficLight of trafficLights.controls) {
              let TrafficLightComponent = new TrafficLight(this, this.canvas, trafficLight, lane); 
              this.AddMapItem(TrafficLightComponent);
          }
        }
      }
      }
    }    
    let controllers = this.Form.get("Controllers") as UntypedFormArray;
    for (let controller of controllers.controls) {
      var parent = this.MapItems.find(x => x.formItem.get('LocalControllerId')?.value === controller.get('Id')?.value)
      let controllerComponent = new Controller(this, this.canvas, controller, parent?.formItem);
      this.AddMapItem(controllerComponent);
    }
    let signs = this.Form.get("SignConfigurations") as UntypedFormArray;
    for (let sign of signs.controls) {
      let signComponent = new Sign(this, this.canvas, sign, null);
      this.AddMapItem(signComponent);
    }

    let gateways = this.Form.get("GatewayConfigurations") as UntypedFormArray;
    for (let gateway of gateways.controls) {
      let gatewayComponent = new Gateway(this, this.canvas, gateway, null);
      this.AddMapItem(gatewayComponent);
    }

    let carcounters = this.Form.get("CarCounterConfigurations") as UntypedFormArray;
    for (let carcounter of carcounters.controls) {
      let carCounterComponent = new CarCounter(this, this.canvas, carcounter, null);
      this.AddMapItem(carCounterComponent);
    }

    //mapitems
    let mapItems = this.Form.get("MapItems") as UntypedFormArray;
    for (let mapItem of mapItems.controls) {
        let mapItemComponent = new MapItem(this, this.canvas, mapItem, null);
        this.AddMapItem(mapItemComponent);
    } 
  }

  public AddMapItem(item: MapComponentBase) {
    this.MapItems.push(item);
    if (this.MapItemsByType.has(item.ClassName)) {
      this.MapItemsByType.get(item.ClassName)?.push(item);
    }
    else {
      this.MapItemsByType.set(item.ClassName, [item]);
    }
  }

  public GetBusinessDirectories() {
    this.apiService.Get<any>("parking/businesses").then((result) => {
      this.BusinessDirectories = result;
      return;
    });
  }

  //#endregion mapcomponents

  //#region eventhandlers
  handleMouseScroll(event: any) {
    event.preventDefault();

    // Define zoom limits and step
    const MIN_ZOOM = 0.5;
    const MAX_ZOOM = 3.0;
    const ZOOM_STEP = 0.075;

    // Calculate the new zoom level based on the scroll event
    const delta = event.deltaY;
    const zoom = this.canvas.getZoom();
    let newZoom = zoom;

    if (delta < 0) {
        // Scrolling up, zooming in
        newZoom = zoom + ZOOM_STEP;
    } else if (delta > 0) {
        // Scrolling down, zooming out
        newZoom = zoom - ZOOM_STEP;
    }

    // Clamp the new zoom level within the defined limits
    newZoom = Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, newZoom));
    
    // Get canvas dimensions and mouse position relative to the canvas
    const canvasRect = this.canvas.getElement().getBoundingClientRect();
    const mouseCanvasX = event.clientX - canvasRect.left;
    const mouseCanvasY = event.clientY - canvasRect.top;

    // Calculate the zoom ratio
    const zoomRatio = newZoom / zoom;
    const offsetX = mouseCanvasX - this.canvas.width / 2;
    const offsetY = mouseCanvasY - this.canvas.height / 2;
    
    // Update the zoom level and apply the zoom to the canvas
    this.zoomLevel = newZoom;
    this.ZoomHistory = this.zoomLevel;
    this.canvas.zoomToPoint({ x: this.canvas.width / 2 + offsetX * zoomRatio, y: this.canvas.height / 2 + offsetY * zoomRatio }, this.ZoomHistory);
  }

  deleteSelectedObjects = () => {
    var undoAction = new FormRefreshUndoAction(this.getCanvasState(), this, this.copyFormGroup(this.Form, false), undefined, undefined, undefined, undefined);
    const selectedObjects = this.canvas.getActiveObjects();
    if (selectedObjects.length > 0) {
        selectedObjects.forEach((object: any) => {
            this.deleteObjectAndRelatedFormItems(object);
        });

        this.canvas.discardActiveObject();
        this.canvas.renderAll();
    }
    else{
      if (this.SelectedItems.length === 0) {
        this.toastService.info("No items selected to Delete.");
      }
    }
    undoAction.newFormItem = this.Form;
    this.LevelEditorUndoActions.push(undoAction);
    this.ItemModified([0]);
  };

  deleteObjectAndRelatedFormItems = (object: any) => {
      // Handle the main object
      this.removeObjectAndUpdateForm(object);

      // Check if the object is a group
      if (object.type === 'group' && object._objects) {
          object._objects.forEach((subObject: any) => {
              this.deleteObjectAndRelatedFormItems(subObject);
          });
      }

      // Finally remove the object from the canvas
      this.canvas.remove(object);
  };

  removeObjectAndUpdateForm = (object: any) => {
      const mapItem = this.MapItems.find(x => x.FabricItemId == (object.id));

      if (mapItem) {
          const formItem = mapItem.formItem;
          const parentItem = mapItem.parentFormItem;

          if(mapItem instanceof PolygonMapComponent){
            if(mapItem.ShowLabel){
              this.canvas.remove(mapItem.Label);
            }
            if(mapItem.ShowMeasurementLines){
              mapItem.ClearMeasurementLines();
            }
            mapItem.ClearSegmentLines();
          }

          if (!parentItem || mapItem.ParentLinkId) {
              const items = this.Form.get(mapItem.ClassName + "s") as FormArray;
              const index = LevelEditorHelper.findFormItemIndex(items, formItem);

              (mapItem as MapComponentBase).DeleteChildren();

              if (index !== -1) {
                  items.removeAt(index);
                  this.Form.markAsDirty();
              } else {
                  console.log("Item not found in FormArray");
              }

              if(mapItem.ParentLinkId){
                mapItem.parentFormItem.get(mapItem.ParentLinkId)?.setValue(null);
              }
          } else {
              const list = parentItem.get(mapItem.ClassName + "s") as FormArray;
              const index = LevelEditorHelper.findFormItemIndex(list, formItem);

              (mapItem as MapComponentBase).DeleteChildren();

              if (index !== -1) {
                  list.removeAt(index);
                  this.Form.markAsDirty();
              } else {
                  console.log("Item not found in nested FormArray");
              }
          }
      }
  };

  getCanvasState() {
      return {
          zoom: this.canvas.getZoom(),
          viewport: this.canvas.viewportTransform.slice(0)
      };
  }

  setCanvasState(state: any) {
    this.canvas.setZoom(state.zoom);
    this.canvas.viewportTransform = state.viewport;
    this.canvas.renderAll();
}

  performSearch(event: any){
    this.searchTerm = event.target.value.toLowerCase();
    if(this.searchTerm == ""){
      this.MapItems.forEach((item: MapComponentBase) => {
        if(item instanceof PolygonMapComponent){
          if(item.MeasurementLines){
            item.MeasurementLines.opacity = 1;
          }
          if(item.SegmentLines){
            item.SegmentLines.forEach((line: any) => {
              line.set('opacity', 1);
            });
          }
        }

        item.FabricItem.opacity = item.Opacity ?? 1; 
        this.canvas.renderAll();
      });
    }
    else{
      var mapitems = this.MapItems.filter(item => {
        const form = item.formItem;
        const name = form.get('Name')?.value;
        const id = form.get('Id')?.value;
        const className = form.get('ClassName')?.value;
        const chipId = form.get('ChipId')?.value;
    
        return (typeof name === 'string' && name.toLowerCase().includes(this.searchTerm)) ||
              (typeof className === 'string' && className.toLowerCase().includes(this.searchTerm)) ||
              (typeof chipId === 'string' && chipId.toLowerCase().includes(this.searchTerm)) ||
              (typeof id === 'string' && (id.toLowerCase() == this.searchTerm)
              );
      });

      this.MapItems.forEach((item: MapComponentBase) => {
        if(item instanceof PolygonMapComponent){
          if(item.MeasurementLines){
            item.MeasurementLines.opacity = 0.1;
          }
          if(item.SegmentLines){
            item.SegmentLines.forEach((line: any) => {
              line.set('opacity', 0.1);
            });
          }
        }
        item.FabricItem.opacity = 0.1; 
        this.canvas.renderAll();
      });

      mapitems.forEach((item: MapComponentBase) => {
        if(item instanceof PolygonMapComponent){
          if(item.MeasurementLines){
            item.MeasurementLines.opacity = 1;
          }
          if(item.SegmentLines){
            item.SegmentLines.forEach((line: any) => {
              line.set('opacity', 1);
            });
          }
        }
        item.FabricItem.opacity = 1; 
        this.canvas.renderAll();
      });
    }
  }

  onZoomChange(event: any): void {
    event.preventDefault();
    this.canvas.setZoom(event.target.value);
  }

  alignByBottomPoints(): void {

    let undoActions : UndoAction[] = [];
    if (this.SelectedItems.length < 2) {
        this.toastService.error("Please select more than 1 item to align");
    }

    let maxY = -Infinity;
    
    // Find the maximum y-coordinate among bottom points
    for (const item of this.SelectedItems) {
        if (item instanceof PolygonMapComponent) {
            const bottomPoint = (item.FabricItem.top ?? 0) + ((item.FabricItem.height ?? 0) * (item.FabricItem.scaleY ?? 0));
            if (bottomPoint > maxY) {
                maxY = bottomPoint;
            }
        }
    }

    for (const item of this.SelectedItems) {
        var undoAction = new UndoAction(this.getCanvasState(), this,  item.formItem, 'PolygonPoints', item.formItem.get('PolygonPoints')?.value, undefined);
        if (item instanceof PolygonMapComponent) {
            const currentLeft = item.FabricItem.left;
            const currentHeight = ((item.FabricItem.height ?? 0) * (item.FabricItem.scaleY ?? 0));
            const newTop = maxY - currentHeight;
            item.FabricItem.set({ left: currentLeft, top: newTop });
            item.Polygon.fire('modified');
            item.FabricItem.setCoords();
            undoAction.NewValue = item.formItem.get('PolygonPoints')?.value;
            this.canvas.requestRenderAll();

            item.UndoActions.push(undoAction);
        }
        undoActions.push(item.Id);
        console.log('Action Pushed 1266');
    }
    this.ItemModified(undoActions); 
}


  alignByTopPoints(): void {
    let undoActions: any[] = []; 
    if (this.SelectedItems.length < 2) {
      this.toastService.error("Please select more than 1 item to align");
      return;
    }

    let minY = Infinity;
    // Find the maximum y-coordinate among top points
    for (const item of this.SelectedItems) {
      if (item instanceof PolygonMapComponent) {
        const topPoint = item.FabricItem.top ?? 0;
        if (topPoint < minY) {
          minY = topPoint;
        }
      }
    }

    // Align polygons by translating vertically
    for (const item of this.SelectedItems) {
      var undoAction = new UndoAction(this.getCanvasState(), this,  item.formItem, 'PolygonPoints', item.formItem.get('PolygonPoints').value, undefined) 
      if(item instanceof PolygonMapComponent){
      if(item.FabricItem.top){
        var left = item.FabricItem.left;
        item.FabricItem.set({ left: left, top: minY });
        item.Polygon.fire('modified');
        item.FabricItem.setCoords();
        undoAction.NewValue = item.formItem.get('PolygonPoints')?.value; 
        item.UndoActions.push(undoAction);
        }
        undoActions.push(item.Id); 
        console.log('Action Pushed 1301');
      } 
    }
    this.ItemModified(undoActions); 
    this.canvas.renderAll();
  }

  collapseHorizontally(): void {
    let undoActions: any[] = []; 
    if (this.SelectedItems.length < 2) {
      this.toastService.error("Please select more than 1 item to collapse");
      return;
    }
  
    // Extract items that are instances of PolygonMapComponent and have a defined group
    const polygonItems = this.SelectedItems.filter(item => item instanceof PolygonMapComponent) as PolygonMapComponent[];
  
    // If no valid items found, return
    if (polygonItems.length === 0) {
      this.toastService.error("No Polygon items selected.");
      return;
    }
  
    // Sort items by their left position in ascending order
    polygonItems.sort((a, b) => (a.FabricItem.left ?? 0) - (b.FabricItem.left ?? 0));
  
    // Initialize the starting left position as the leftmost item's left position
    let currentLeft = polygonItems[0].FabricItem.left ?? 0;
  
    // Align items horizontally
    for (const item of polygonItems) {
      var undoAction = new UndoAction(this.getCanvasState(), this,  item.formItem, 'PolygonPoints', item.formItem.get('PolygonPoints').value, undefined) 
      if (item.FabricItem.width !== undefined) {
        const top = item.FabricItem.top ?? 0;
        item.FabricItem.set({ left: currentLeft, top });
        
        // Update currentLeft to the right edge of the current item
        currentLeft += item.FabricItem.width;
        
        item.Polygon.fire('modified');
        item.FabricItem.setCoords();
        undoAction.NewValue = item.formItem.get('PolygonPoints')?.value; 
        item.UndoActions.push(undoAction);
      }
      undoActions.push(item.Id);
      console.log('Action Pushed 1345');
    }
  
    // Render the canvas after adjusting the positions
    this.ItemModified(undoActions); 
    this.canvas.renderAll();
  }

  collapseVertically(): void {
    let undoActions: any[] = []; 
    if (this.SelectedItems.length < 2) {
      this.toastService.error("Please select more than 1 item to collapse");
      return;
    }
    
    // Sort selected items by their top points (from lowest to highest)
    const sortedItems = this.SelectedItems.sort((a, b) => {
      const topA = (a instanceof PolygonMapComponent) ? (a.FabricItem.top ?? 0) : Infinity;
      const topB = (b instanceof PolygonMapComponent) ? (b.FabricItem.top ?? 0) : Infinity;
      return topA - topB;
    });

    let topPoint = sortedItems[0].FabricItem.top ?? 0;
    const leftPoint = sortedItems[0].FabricItem.left ?? 0; // Maintain the left position

    // Align items vertically
    for (const item of sortedItems) {
      if (item instanceof PolygonMapComponent && item.FabricItem.height) {
        var undoAction = new UndoAction(this.getCanvasState(), this,  item.formItem, 'PolygonPoints', item.formItem.get('PolygonPoints').value, undefined) 
        item.FabricItem.set({ left: leftPoint, top: topPoint });
        topPoint += item.FabricItem.height;
        item.Polygon.fire('modified');
        item.FabricItem.setCoords();
        undoAction.NewValue = item.formItem.get('PolygonPoints')?.value; 
        item.UndoActions.push(undoAction);
        undoActions.push(item.Id);
      } 
    }
    this.ItemModified(undoActions); 
    this.canvas.renderAll();
  }

  copyFormGroup(formGroup: FormGroup, overrideValues: boolean = false): FormGroup {
    const formGroupCopy = new FormGroup({});

    Object.keys(formGroup.controls).forEach(controlName => {
        const control = formGroup.get(controlName);

        if (control instanceof FormControl) {
            let controlValue: any;

            if(overrideValues){
              if (controlName === 'Id') {
                controlValue = uuidv4(); // Set Id to new UUIDv4
              }
              else if(controlName.endsWith('Id')) {
                controlValue = null; // Set any linking items to null
              } else if (controlName === 'PolygonPoints') {
                  // Handle PolygonPoints specifically
                  controlValue = control.value ? control.value.map((point: number[]) => {
                      return point.map(coord => coord + (10 / 10000));
                  }) : [];
              } else {
                  controlValue = control.value;
              }
            }else {
                controlValue = control.value;
            }
            const controlValidators = control.validator;
            const newControl = new FormControl(controlValue, controlValidators);
            formGroupCopy.addControl(controlName, newControl);
        } else if (control instanceof FormGroup) {
            formGroupCopy.addControl(controlName, this.copyFormGroup(control, overrideValues));
        } else if (control instanceof FormArray) {
            formGroupCopy.addControl(controlName, this.copyFormArray(control, overrideValues));
        }
    });

    return formGroupCopy;
}

copyFormArray(formArray: FormArray, overrideValues: boolean = false): FormArray {
  const formArrayCopy = new FormArray<FormControl | FormGroup | FormArray>([]);

  formArray.controls.forEach(control => {
      if (control instanceof FormControl) {
          const controlValue = control.value;
          const controlValidators = control.validator;
          const newControl = new FormControl(controlValue, controlValidators);
          formArrayCopy.push(newControl);
      } else if (control instanceof FormGroup) {
          formArrayCopy.push(this.copyFormGroup(control, overrideValues));
      } else if (control instanceof FormArray) {
          formArrayCopy.push(this.copyFormArray(control, overrideValues));
      }
  });

  return formArrayCopy;
}

  async duplicateObjects() {
    var undoActions : CloneUndoAction[] = []
    var newItems: MapComponentBase[] = [];

    console.log(this.SelectedItems);

  //incase any items dont process their deselected on in time to remove from selected item list.
    const uniqueSelectedItems = Array.from(new Set(this.SelectedItems.map(item => item.FabricItemId)))
      .map(id => this.SelectedItems.find(item => item.FabricItemId === id));

    this.SelectedItems = uniqueSelectedItems;


    if (this.SelectedItems.length === 0) {
      this.toastService.info("No items selected to duplicate.");
      return;
    }

    if (this.SelectedItems.some((x: MapComponentBase) => !x.CanDuplicate)) {
      this.toastService.info("You cannot duplicate one or more of these items.");
      return;
    }

    const allNamesFollowPattern = this.SelectedItems.every((item: MapComponentBase) => {
      const name = item.formItem.get("Name")?.value;
      // Check if the name follows either of the patterns:
      // - Letters followed by digits: /^[A-Za-z]+\d+$/
      // - Just a number: /^\d+$/
      return /^[A-Za-z]+\d+$/.test(name) || /^\d+$/.test(name);
    });

    let overrideName = false;
    let overridePrefix = "";
    let overrideNumber = 0;

  if (allNamesFollowPattern) {
      // Extract the prefix and numbers
      const prefixes = this.SelectedItems.map((item: MapComponentBase) => {
          const name = item.formItem.get("Name")?.value;
          const match = name.match(/^([A-Za-z]*)(\d+)$/); // Capture the prefix and number separately
          return match ? { prefix: match[1], number: parseInt(match[2], 10) } : { prefix: '', number: parseInt(name, 10) };
      });

      // Find unique prefixes
      const uniquePrefixes = new Set(prefixes.map(p => p.prefix));

      // If there is only one unique prefix, set override details
      if (uniquePrefixes.size === 1) {
          overrideName = true;
          overridePrefix = uniquePrefixes.values().next().value; // Get the single unique prefix
          overrideNumber = Math.max(...prefixes.map(p => p.number));
      }
  }

    //update id and name fields
    this.SelectedItems.forEach((item: any, index: number) => {
      const formItemCopy: FormGroup = this.copyFormGroup(item.formItem, true);
      if(item.ChildrenArrays){
        item.ChildrenArrays.forEach((childArray: string) => {
          const formArray = formItemCopy.get(childArray) as FormArray;
          if (formArray) {
            // Clear all controls from the FormArray
            while (formArray.length !== 0) {
                formArray.removeAt(0);
            }
          }
        });
      }

      if (overrideName) {
          const newName = (overridePrefix + (overrideNumber + 1)).toString();
          overrideNumber++;
          formItemCopy.get("Name")?.setValue(newName);
      }

      var newItem = item.Clone(formItemCopy);
      newItem.IsClone = true;
      newItem.Zoom = item.Zoom;  
      newItem.parentFormItem = item.parentFormItem;
      newItem.AddFabricItem();
        
      newItems.push(newItem);
      this.AddMapItem(newItem);
       //add to form array
        if (item.parentFormItem == null) {
            const items = this.Form.get(item.ClassName + "s") as FormArray;
            items.push(newItem.formItem);
            this.Form.markAsDirty();
        } 
        else {
          let controls = this.ModelEditor.FormArray(item.parentFormItem, item.ClassName + "s");
          (controls as FormArray).push(newItem.formItem);
            this.Form.markAsDirty();
        }

        item.UndoActions.push(new CloneUndoAction(this.getCanvasState(), this, newItem.formItem, undefined, item.parentFormItem, item.ClassName + 's'));
        undoActions.push(item.Id);
    });
      
    const newGroups = newItems.map((newItem: any) => newItem.FabricItem);
    if (newGroups.length > 0) {
      const activeSelection = new fabric.ActiveSelection(newGroups, {
          canvas: this.canvas,
      });
      this.canvas.setActiveObject(activeSelection);
    }

    this.SelectedItems = [];

    newItems.forEach((newItem: any) => {
      this.SelectedItems.push(newItem);
    });

    this.canvas.renderAll(); // Ensure the canvas renders the changes

    this.ItemModified(undoActions)
    return;
  }

  //#endregion eventhandlers

  SelectChildren(){
    var objectsToAdd: fabric.Object[] = [];

    this.SelectedItems.forEach((item: MapComponentBase) => {
      var children = item.SelectChildren();
      children?.forEach((child: any) => {
        this.SelectedItems.push(child);
        if(child instanceof MapComponentBase){
          objectsToAdd.push(child.FabricItem);
        }
      });
    });
    var activeSelect = new fabric.ActiveSelection(objectsToAdd, { canvas: this.canvas} );
    this.canvas.setActiveObject(activeSelect);
    this.canvas.renderAll();
  }

  
  HasChildrenArrays(){
    return (this.SelectedItems[0] as MapComponentBase).ChildrenArrays != null;
  }


  public OpenBarriers(){
    this.SelectedItems.forEach((item: any) => {
      this.apiService.Post("/infrastructure/barriers/" + item.formItem.get("Id")?.value + "/open", {});
    });
  }

  public KeepBarriersOpen(){
    this.SelectedItems.forEach((item: any) => {
      this.apiService.Post("/infrastructure/barriers/" + item.formItem.get("Id")?.value + "/keepopen", {});
    });
  }

  public CloseBarriers(){
    this.SelectedItems.forEach((item: any) => {
      this.apiService.Post("/infrastructure/barriers/" + item.formItem.get("Id")?.value + "/close", {});
    });
  }

  EditModal(){
    this.SelectedItems[0].ShowModal();
  }

  //#region Edit Polygon
  BlockAllComponents(obj: fabric.Group){
    this.canvas.forEachObject(function (obj: any) {
      if (obj instanceof fabric.Circle) {
        // Do nothing because we use these for editing.
      } else {
          // For non-circle objects, make them unselectable and disable controls
          obj.selectable = false; // Make object unselectable
          obj.hasControls = false; // Disable controls (resizing, rotating)
          obj.lockMovementX = true; // Optionally lock movement on X axis
          obj.lockMovementY = true; // Optionally lock movement on Y axis
          obj.opacity = 0.2;
      }
    });

    obj.set({'opacity': 1});
    this.canvas.renderAll();
  }

  EnableAllComponents(){
    this.canvas.forEachObject((obj: any) => {
    if (obj instanceof fabric.Group || obj instanceof fabric.Polygon || obj instanceof fabric.Image) {
          // For circles and polygons, allow selection and controls
          obj.selectable = true;
          obj.hasControls = true;
          obj.lockMovementX = false; // Unlock movement on X axis if needed
          obj.lockMovementY = false; // Unlock movement on Y axis if needed
          obj.opacity = 1;
      }
    });
    var lockedItems = this.MapItems.filter(x => x.Locked);
    lockedItems.forEach((item: MapComponentBase) => {
      item.FabricItem.selectable = false;
      item.FabricItem.hasControls = true;
      item.FabricItem.lockMovementX = false;
      item.FabricItem.lockMovementY = false;
    });
  }

  ToggleEditPolygon() {
    this.EditingPolygon = !this.EditingPolygon;

    if(!this.EditingPolygon){
      this.EnableAllComponents();
      if(this.SelectedItems[0] instanceof PolygonMapComponent){
        this.SelectedItems[0].ToggleEditPolygon(false);
      }
    }
    else{
      if(this.SelectedItems[0] instanceof PolygonMapComponent){
        this.SelectedItems[0].ToggleEditPolygon(true);
        this.SelectedItems[0].Polygon.opacity = 1;
      }
      this.canvas.requestRenderAll();
    }
  }
  //#endregion Edit Polygon

  //#region GoogleMaps
  public UpdateGoogleMapPath(rotateDegrees: number | null, scaleFactor: number | null) {
    let geoCenter = this.Form.get("GeoLocationPoints")?.value;
    let levelPoints: number[][] = this.Form.get('PolygonPoints')?.value;
    let levelBounds = Geo.GetPolygonPointBounds(levelPoints);
    this.Form.get("GeoRotation")?.setValue(this.GoogleMapRotation);
    let geoCoords: turf.Polygon = Geography.ConvertLocalCoordinatesToTurfPolygon(levelPoints, 1000, geoCenter, levelBounds.Center, this.GoogleMapRotation);

    this.GoogleMapLevelPath = Geography.PolygonToGoogleMaps(geoCoords);
    this.Form.get("GeoPolygonPoints")?.setValue(geoCoords.coordinates[0]);

    this.GoogleMapSpaces = [];
    let spaces = this.MapItemsByType?.get('Space');
    if (spaces != null) {
      for (let s of spaces) {
        var polyPoints = s.formItem.get("PolygonPoints")?.value;
        let geoCoords: turf.Polygon = Geography.ConvertLocalCoordinatesToTurfPolygon(polyPoints, 1000, geoCenter, levelBounds.Center, this.GoogleMapRotation);
        this.GoogleMapSpaces.push(Geography.PolygonToGoogleMaps(geoCoords));
      }
    }
    if (this.GoogleMapCenter.lat == 0)
      this.GoogleMapCenter = { lat: geoCenter[1], lng: geoCenter[0] };
    return;
  }

  public CenterLevelOnMap() {
    let x: any = this.GoogleMap.getCenter();
    this.Form.get("GeoLocationPoints")?.setValue([x.lng(), x.lat()]);
    this.UpdateGoogleMapPath(null, null);
  }

  public GoogleMapLevelDragged(evt: any) {
    if (this.MapPolygons != null) {
      let p = this.MapPolygons.last.getPaths().getArray()[0].getArray();
      //find how far the polygon was dragged
      let d = turf.distance(turf.point([this.GoogleMapLevelPath[0].lng, this.GoogleMapLevelPath[0].lat]), turf.point([p[0].lng(), p[0].lat()]));
      let a = turf.bearing(turf.point([this.GoogleMapLevelPath[0].lng, this.GoogleMapLevelPath[0].lat]), turf.point([p[0].lng(), p[0].lat()]));
      let formControl = this.Form.get("GeoLocationPoints");
      let formValue: number[] = formControl?.value as number[];
      let currentGeoLocation = turf.point(formValue);
      let newGeoLocation = turf.destination(currentGeoLocation, d, a);
      formControl?.setValue([newGeoLocation.geometry.coordinates[0], newGeoLocation.geometry.coordinates[1]]);
      this.UpdateGoogleMapPath(null, null);
    }
  }
  //#endregion GoogleMaps

  public GetBusiness(businessId: any) {
    if (businessId == null || businessId == "") {
      return;
    }
    var business = this.BusinessDirectories.filter(x => x.Id == businessId);
    return business[0];
  }

  //#region Debounce
  updateHeightFn(event: any): void {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
    }
    // Call the debounced version of the method
    this.updateHeight(event);
  }
  
  updateWidthFn(event: any): void {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
    }
    // Call the debounced version of the method
    this.updateWidth(event);
  }
  
  updateLeftFn(event: any): void {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
    }
    // Call the debounced version of the method
    this.updateLeft(event);
  }
  
  updateTopFn(event: any): void {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
    }
    // Call the debounced version of the method
    this.updateTop(event);
  }

  updateAngleFn(event: any): void {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
    }
    // Call the debounced version of the method
    this.updateAngle(event);
  }

  debounce(func: Function, delay: number): Function {
    let timeoutId: ReturnType<typeof setTimeout> | null;
    return  (...args: any[]) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  //#endregion Debounce
};
