<app-loader [Parent]="this"></app-loader>
<div *ngIf="Model != null">
    <app-pageheader [Parent]="this">
        <form-edit-button routerLink="edit">Edit</form-edit-button>
    </app-pageheader>

    <div style="margin:0 !important;" class="columns">
        <div class="column is-6">
            <article class="panel">
                <p class="panel-heading">{{Model.Name}}</p>
                <div class="panel-block">
                    <div>
                        <app-field Label="Name" class="control flex-center">{{Model.Name}}</app-field>
                        <app-field Label="Target" class="control flex-center">{{Model.TargetType}}</app-field>
                    </div>
                </div>
            </article>
        </div>
        <div class="column is-6">
        </div>
    </div>
    <div style="margin:0 !important;" class="columns">
        <div class="column is-12">
            <article class="panel">
                <p class="panel-heading">Display Rules</p>
                <div class="panel-block">
                    <div class="rule-container">
                        <div *ngFor="let line of Model.DisplayRules">
                            <div class="rule">
                                <div class="rule-cell">
                                    <label class="label">Threshold</label>
                                    <span>{{Model.TargetType + '.' + line.TargetThresholdProperty + ' ' + line.TargetThresholdOperator + ' ' + line.Threshold}}</span>
                                </div>
                                <div class="rule-cell">
                                    <label class="label">Mode</label>
                                    <span>{{line.Mode == 1 ? 'Single Display' : 'Dual Display'}}</span>
                                    <span *ngIf="line.Mode == 2">({{line.SplitMode == 0 ? 'Toggle' : (line.SplitMode == 1 ? 'Horizontal Split' : 'Vertical Split')}})</span>
                                </div>
                                <div class="rule-cell">
                                    <label class="label">Content</label>
                                    <span *ngIf="line.DataType > 2" [ngClass]="GetContentIcon(line.DataType)"></span>
                                    <span *ngIf="line.DataType <= 2">{{line.Content}}</span>
                                    <span *ngIf="line.Mode == 2">|</span>
                                    <span *ngIf="line.Mode == 2 && line.SecondDisplayDataType <= 2">{{line.SecondDisplayContent}}</span>
                                    <span *ngIf="line.Mode == 2 && line.SecondDisplayDataType > 2" [ngClass]="GetContentIcon(line.SecondDisplayDataType)"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    </div>
</div>