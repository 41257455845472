import { EditSignConfigurationComponent } from "../../../modals/editsignconfiguration/editsignconfiguration.component";
import { LevelEditorComponent } from "../../leveleditor.component";
import { PointMapComponent } from "./PointMapComponent";

export class Sign extends PointMapComponent{
    public override ClassName: string = "SignConfiguration";
    public override ImageUrl: string = "/assets/leveleditor/sign.png";
    public override ImageScale = 0.06;
    public override CanDuplicate: boolean = false;
    public override IsGuidId: boolean = false;
    
    constructor(leveleditorcomponent: LevelEditorComponent, canvas: any, formItem: any, parentFormItem: any, forceClone: boolean = false) {
        super(leveleditorcomponent, canvas, formItem, parentFormItem, forceClone);
        this.AddFabricItem();
    }

    public override Clone(formitem: any){
        return new Sign(this.leveleditorcomponent, this.canvas, formitem, this.parentFormItem, true);
    }

    public override ShowModal(): void {
        this.leveleditorcomponent.modalService.addModal(EditSignConfigurationComponent, {Form: this.formItem })
        .subscribe((result) => {
        });
    }
}