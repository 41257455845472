import { Component, ElementRef, HostListener, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiService } from 'src/app/Services/api.service';
import { ColorService } from 'src/app/Services/color.service';
import { StructureViewerLayer } from 'src/app/shared/structureviewermaps/structureviewerlayers';
import { EnforcementService } from 'src/app/Services/enforcement.service';
import { ActionviolationmodalComponent } from '../../modals/actionviolation/actionviolationmodal.component';

@Component({
  selector: 'app-violation',
  templateUrl: './violation.component.html',
  styleUrls: ['./violation.component.scss']
})


export class ViolationComponent extends ModelEditor {
  public StructureViewerLayers: StructureViewerLayer[] = [];
  public StructureSearchTerm!: string;
  public StructureId !: string;
  public StructureViewerForType !: string;
  
  public DefaultModel() {

  }
  public AfterModelLoaded(): void {
    this.StructureViewerLayers = [];
    if(this.Model.ParkingSpaceId != null){
      this.StructureViewerLayers.push(new StructureViewerLayer("ParkingLot"));
      this.StructureViewerLayers.push(new StructureViewerLayer("ParkingLevel"));
      this.StructureViewerLayers.push(new StructureViewerLayer("ParkingSpace"));
      this.StructureSearchTerm = this.Model.ParkingSpaceId;
      this.StructureId = this.Model.ParkingSpaceId;
      this.StructureViewerForType = "ParkingSpace";
    }
  }
  public BeforeSave(): boolean | void {

  }
  public Validators() {

  }

  openActionViolationModal() {
    this.modalService.addModal(ActionviolationmodalComponent, {
      violation: this.Model
    }).subscribe(() => {
      window.location.reload();
    });
  }

  constructor(private apiService: ApiService, public route: ActivatedRoute, 
    private cService: ColorService, public enforcementService: EnforcementService, private injector: Injector, private modalService: SimpleModalService) {
    super("enforcement/violations", injector);
  }

  getTextClass() {
    return this.cService?.GetVisibleTextClassFor(this.Model.ThresholdColor);
  }
}
