import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { FeaturesService } from 'src/app/Services/features.service';
import { RevenueService } from 'src/app/Services/revenue.service';
import { Deviceicons } from 'src/app/util/deviceicons';

@Component({
  selector: 'app-revenueoverview',
  templateUrl: './revenueoverview.component.html',
  styleUrls: ['./revenueoverview.component.scss']
})
export class RevenueOverviewComponent implements OnInit {

  public ControlledAreaSummary : any | null = null;

  constructor(private apiService : ApiService, private featuresService : FeaturesService, private revenueService : RevenueService) { }

  public TableData : any[] = [];

  ngOnInit(): void {
      this.revenueService.GetThisWeekByControlledArea().then(result => {
        this.TableData = result?.Structures;
    });
    this.revenueService.TransactionsChanged.subscribe(() => {
      this.revenueService.GetThisWeekByControlledArea().then(result => {
        if(result != null)
          this.TableData = result.Structures;
      });
    });
  }
  public GenerateIconName = Deviceicons.GenerateIconName;

}
