<div class="image-carousel">
    <div class="carousel-image" *ngIf="Images.length == 0">
        <i> No images </i>
    </div>
    <div *ngIf="Images.length > 0">
        <div class="carousel-wrapper">
            <div class="carousel-images" [ngStyle]="{'transform': 'translateX(' + (165 + -currentIndex * 33) + '%)'}">
                <div *ngFor="let image of Images; let i = index" [style.opacity]="getOpacity(i)" [class.active]="i === currentIndex" (click)="show(i)">
                    <img [src]="image" alt="Image">
                    <span *ngIf="ImageCaptions.length == Images.length">
                        {{ImageCaptions[i]}}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="dots">
        <span *ngFor="let image of Images; let i = index" class="dot" [class.active]="i === currentIndex" (click)="show(i)"></span>
    </div>
</div>
  