<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Messages</p>
      </header>
      <section class="modal-card-body" style="min-width: 800px;">
        <app-tabs>
          <app-tab Title="Checkins">
                <table *ngIf="CheckinData != null" class="table is-fullwidth is-small is-striped">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let d of CheckinData.Results">
                            <td>{{d.Timestamp}}</td>
                        </tr>
                    </tbody>
                </table>
          </app-tab>
          <app-tab Title="Events">
           
          </app-tab>
        </app-tabs>

      </section>
      <footer class="modal-card-foot">
      </footer>
    </div>
  </div>