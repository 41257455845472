import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SimpleModalService } from 'ngx-simple-modal';
import { UnsavedchangesdialogComponent } from '../modals/unsavedchangesdialog/unsavedchangesdialog.component';

export interface ComponentCanDeactivate {
  CanDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
    
    constructor(private modalService : SimpleModalService){
          
    }
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    
    if(component.CanDeactivate()){
        return true;
    }
    return this.modalService.addModal(UnsavedchangesdialogComponent, { }, { draggable : false});
  }
}