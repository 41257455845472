import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.scss']
})

export class AddButton implements OnInit {

  @Output()
  public AddObject = new EventEmitter<any>();
  @Input()
  public AddRoute : string | undefined;
  @Input()
  public ButtonType: string = "button";
  @Input()
  public ButtonText: string = "Add New";
  @Input()
  public Class: string = "";
  
  @Input()
  public Icon: string = "plus";

  @Input()
  public RouteData: string = "";

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public Clicked(evt: any) {
    var routeDataItem = {};
    if(this.RouteData != "" && this.RouteData != undefined){
      routeDataItem = { "routeData" : this.RouteData }
    }
    if(this.AddRoute != undefined){
      this.router.navigate([this.AddRoute], { state: routeDataItem });
    }
    this.AddObject.emit();
  }
}
