import { Component, OnInit } from '@angular/core';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { BooleanCheckOrCrossViewColumn, BooleanValuesViewColumn, CurrencyViewColumn, Datalistviewcolumn, DateRangeViewColumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-permittypes',
  templateUrl: './permittypes.component.html',
  styleUrls: ['./permittypes.component.scss']
})
export class PermittypesComponent implements OnInit {

  public orgService: OrganizationsService;
  public Subsets: string[] = [];
  public Columns: Datalistviewcolumn[] = [];

  constructor(orgService: OrganizationsService) {
    this.orgService = orgService;

    this.Subsets = ['Active','Expired']
    this.Columns = [
      new Datalistviewcolumn("Name", "Name"),
      new Datalistviewcolumn("Price", "PricingInfoString"),
      new Datalistviewcolumn("Active Start Date", "ActiveStartDateLocalFriendlyString"),
      new Datalistviewcolumn("Active End Date", "ActiveEndDateLocalFriendlyString"),
      new Datalistviewcolumn("User Count", "UserInfoString"),
      new Datalistviewcolumn("Active Permits", "PermitsAllocatedString")
    ];
   }

  ngOnInit(): void {
  }

}
