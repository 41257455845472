import { MeshConstants } from "src/app/util/mesh-constants";
import * as THREE from "three";
import { Mesh, Scene, Vector2, Vector3 } from "three";

export class BusinessMesh extends THREE.Line {
    constructor(scene : Scene, business: any, offset: Vector3, scale: Vector2){
        super();
        const shopGroup = new THREE.Group();
        let p = business.PolygonPoints;

        if(business.EntrySegment != null){
            for (let i = 0; i < p.length; i++) {
                const currentPoint = p[i];
                const nextPointIndex = (i + 1) % p.length;
                const nextPoint = p[nextPointIndex];

                if (i == (business.EntrySegment as number)) {
                    continue; // Skip this iteration of the loop
                }
                
                const shape = new THREE.Shape();
                shape.moveTo(currentPoint[0] * scale.x + offset.x, -1 * currentPoint[1] * scale.y + offset.y);
                shape.lineTo(nextPoint[0] * scale.x + offset.x, -1 * nextPoint[1] * scale.y + offset.y);
              
                // Create a geometry for the shape
                const extrudeSettings = {
                    steps: 2,
                    depth: MeshConstants.levelHeight,
                    bevelEnabled: false,
                };

                let geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
                geometry.translate(0,0,offset.z + 0.0001);


                let texture = new THREE.TextureLoader().load('/assets/textures/concrete.jpg');
                texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
                texture.offset.set( 0, 0.5 );
                texture.repeat.set( 100,100 );
                texture.needsUpdate = true;
                let material = new THREE.MeshLambertMaterial({ color: 0x555555, map : texture });
                this.geometry = geometry;
                this.material = material;
                let wallmesh = new Mesh(geometry, material);
                shopGroup.add(wallmesh);
              }
		}

        scene.add(shopGroup);
    }
}
