import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-unsavedchangesdialog',
  templateUrl: './unsavedchangesdialog.component.html',
  styleUrls: ['./unsavedchangesdialog.component.scss']
})
export class UnsavedchangesdialogComponent extends SimpleModalComponent<any, any> {
  
  constructor() {
    super();
  }

  public AbandonChanges(){
    this.result = true;
    this.close();
  }
  public Cancel(){
    this.result = false;
    this.close();
  }
}