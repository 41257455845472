import { Geo } from "src/app/util/geo";
import { MeshConstants } from "src/app/util/mesh-constants";
import * as THREE from "three";
import { Mesh, Scene, Vector2, Vector3 } from "three";


export class RampMesh extends THREE.Mesh {
    constructor(scene : Scene, shape: any, offset: Vector3, scale: Vector2, isBelow: boolean){
        super();
        const earcut = require('earcut');
        let p = shape.PolygonPoints.map((point:any) => ({ x: (point[0] * scale.x + offset.x), y: -1 * point[1] * scale.y + offset.y }));
        let EntryIndex1 = Number(shape.EntrySegment);
        let EntryIndex2 = Number(shape.EntrySegment) +1;
        let ExitIndex1 = Number(shape.ExitSegment);
        let ExitIndex2 = Number(shape.ExitSegment)+ 1;

        p[EntryIndex1].isIndex = true;
        p[EntryIndex2].isIndex = true;
        p[ExitIndex1].isIndex = true;
        p[ExitIndex2].isIndex = true;

        let left = [];
        let right = [];
        if(EntryIndex1 < ExitIndex1){
            right = p.slice(ExitIndex2);
            right = right.concat(p.slice(0, EntryIndex1 + 1));
            left = p.slice(EntryIndex1+1, ExitIndex1+1);
        }
        else{
            left = p.slice(EntryIndex2);
            left = left.concat(p.slice(0, ExitIndex1 + 1));
            right = p.slice(ExitIndex2, EntryIndex1+1);
        }

        //calculate length of left 
        const leftpath = new THREE.Path(left);
        const leftlength = leftpath.getLength();
        left[0].percent = 0;
        left[0].height = 0;

        //calculate for each item, its percentage of length to work out its height
        for (let i = 1; i < left.length; i++) {
            let leftslice = left.slice(0, i+ 1);
            var path = new THREE.Path(leftslice);
            const pathlength = path.getLength();
            left[i].percent = (pathlength / leftlength) * 100
            left[i].height = (left[i].percent / 100) * (MeshConstants.levelHeight)
        }

        //calculate length of left 
        const rightpath = new THREE.Path(right);
        const rightlength = rightpath.getLength();
        right[0].percent = 100;
        right[0].height = MeshConstants.levelHeight;

        //calculate for each item, its percentage of length to work out its height
        for (let i = 1; i < right.length; i++) {
            let rightslice = right.slice(0, i+ 1);
            var path = new THREE.Path(rightslice);
            const pathlength = path.getLength();
            right[i].percent = (pathlength / rightlength) * 100
            right[i].height = ((100 - right[i].percent) / 100) * (MeshConstants.levelHeight)
        }
        

        var allVectors = Geo.RoundEdges(left.concat(right), 1).map((point:any) => new THREE.Vector3(point.x, point.y, point.height));
        const geometry = new THREE.BufferGeometry();

        // Create arrays to store the vertex positions and indices
        const positions = [];
        const indices = [];

        // Add the points to the geometry
        for (const point of allVectors) {
            positions.push(point.x, point.y, point.z);
        }

        // Triangulate the polygon using earcut
        const flatPoints = allVectors.map((point: any) => [point.x, point.y, point.z]);
        const flattenedIndices = earcut(flatPoints.flat(), null, 3);

        for (let i = 0; i < flattenedIndices.length; i += 3) {
        indices.push(flattenedIndices[i], flattenedIndices[i + 1], flattenedIndices[i + 2]);
        }
        
        geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
        geometry.setIndex(indices);

        const material = new THREE.MeshBasicMaterial({ color: 0x404243, side: THREE.DoubleSide });
        const mesh = new THREE.Mesh(geometry, material);

        if(isBelow){
            mesh.position.z = -(MeshConstants.levelHeight- MeshConstants.floorThickness);
        }
        else if (shape.IsRising == (null || undefined) && !shape.IsRising){
            mesh.position.z = -(MeshConstants.levelHeight-MeshConstants.floorThickness);
        }
        else{
            mesh.position.z = mesh.position.z + MeshConstants.floorThickness;
        }

        mesh.receiveShadow = true;
        mesh.castShadow = true;
        
        
        // Add the mesh to the scene
        scene.add(mesh);
    }
}