<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Parent]="this" [Form]="Form" class="button is-primary" (OnSave)="Save()">
        </app-form-save-button>
    </app-pageheader>

<div class="columns">
    <div class="column is-6">
        <article class="panel">
            <p class="panel-heading">{{Model.Name}}</p>
            <div class="panel-block">
                <div class="three-quarters-width">
                    <app-field Label="Name" class="control">
                        <input formControlName="Name" class="input mb-3" type="text"/>
                        <app-validator [For]="this.Form.get('Name')"></app-validator>
                    </app-field>
                    <app-field Label="Target Type" class="control mb-3">
                        <span class="select is-fullwidth">
                            <select formControlName="TargetType">
                                <option *ngFor="let item of ModelTypes" value="{{item.Name}}">{{item.Name}}</option>
                            </select>
                        </span>
                    </app-field>
                </div>
            </div>
        </article>
    </div>
</div>
<div class="columns">
    <div class="column is-12">
        <article class="panel">
            <p class="panel-heading">Display Rules</p>
            <div class="panel-block">
                <div class="rule-container">
                    <div [@move]="line.value.DisplayIndex" (@move.done)="TriggerSwap(i, $event)" [@.disabled]="!UseAnimation" formArrayName="DisplayRules" @fadeInOut *ngFor="let line of FormArray(Form, 'DisplayRules').controls; let i = index;">
                        <div [formGroupName]="i" class="rule">
                            <div class="first-rule-cell">
                                <span [ngClass]="line.get('Index')?.value == 1 ? 'hide' : 'show'" (click)="moveUp(i)" class="fa fa-chevron-up"></span>
                                <span [ngClass]="line.get('Index')?.value < this.Form.get('DisplayRules')?.value.length ? 'show' : 'hide'" (click)="moveDown(i)" class="fa fa-chevron-down"></span>
                            </div>
                            <div class="rule-cell">
                                <label class="label">Threshold</label>
                                <span *ngIf="line.get('TargetThresholdProperty')?.value != null">{{this.Form.get('TargetType')?.value + '.' + line.get('TargetThresholdProperty')?.value + ' ' + line.get('TargetThresholdOperator')?.value + ' ' + line.get('Threshold')?.value}}</span>
                            </div>
                            <div class="rule-cell">
                                <label class="label">Mode</label>
                                <span>{{line.get('Mode')?.value == 1 ? 'Single Display' : 'Dual Display'}}</span>
                                <span *ngIf="line.get('Mode')?.value == 2">({{line.get('SplitMode')?.value == 0 ? 'Toggle' : (line.get('SplitMode')?.value == 1 ? 'Horizontal Split' : 'Vertical Split')}})</span>
                            </div>
                            <div class="rule-cell">
                                <label class="label">Content</label>
                                <span *ngIf="line.get('DataType')?.value > 2" [ngClass]="GetContentIcon(line.get('DataType')?.value)"></span>
                                <span *ngIf="line.get('DataType')?.value <= 2">{{line.get('Content')?.value}}</span>
                                <span *ngIf="line.get('Mode')?.value == 2">|</span>
                                <span *ngIf="line.get('Mode')?.value == 2 && line.get('SecondDisplayDataType')?.value <= 2">{{line.get('SecondDisplayContent')?.value}}</span>
                                <span *ngIf="line.get('Mode')?.value == 2 && line.get('SecondDisplayDataType')?.value > 2" [ngClass]="GetContentIcon(line.get('SecondDisplayDataType')?.value)"></span>
                            </div>
                            <div class="last-rule-cell">
                                <button type="button" (click)="EditDisplayRule(i)" class="button is-small">
                                    <i class="fa fa-pencil"></i>
                                </button>
                                <button type="button" (click)="RemoveDisplayRule(i)" class="button is-danger is-small ng-star-inserted  level-item">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button class="button is-primary" (click)="AddDisplayRule()">Add New</button>
                    </div>  
                </div> 
            </div>
        </article>
    </div>
</div>
</div>