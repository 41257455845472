<app-pageheader [Parent]="this">
  <add-button AddRoute="/valet/addons/new/edit"></add-button>
</app-pageheader>

<app-datalistview #DataList [Columns]="Columns" [ShowMap]="false" ApiUrl="valet/addons/searches"
  [AllowEdit]="true" EditUrl="/valet/addons/:id" [HighLightCondition]="['Open']" [UseQueryParamStates]="false">
  <app-filter-set>
    <div app-search-filter></div>
    <div app-search-button></div>
  </app-filter-set>
</app-datalistview>