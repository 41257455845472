<app-loader [Parent]="this"></app-loader>
<div *ngIf="ShowPage">
  <app-pageheader [Parent]="this">
    <div class="dropdown is-right mr-3" [ngClass]="{ 'is-active' : ShowAlertsDropdown }"
      (click)="ShowAlertsDropdown = !ShowAlertsDropdown">
      <div class="dropdown-trigger">
        <button class="button is-primary" aria-haspopup="true" aria-controls="dropdown-menu">
          <span> Terminal Control</span>
          <span class="icon is-small">
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>
        <div class="dropdown-menu" id="dropdown-menu" role="menu">
          <div class="dropdown-content">
            <a href="#" class="dropdown-item" (click)="PowerCycle('v12')">
              Power Cycle 12v
            </a>
            <a class="dropdown-item" (click)="PowerCycle('v48')">
              Power Cycle 48v
            </a>
            <a href="#" class="dropdown-item" (click)="PowerCycle('ALL')">
              Power Cycle All
            </a>
            <hr class="dropdown-divider">
            <a href="#" class="dropdown-item" (click)="DatabaseReset()">
              Reset Database
            </a>
          </div>
        </div>

      </div>
    </div>
    <button class="button is-link" routerLink="/parking/accesscontrol/terminals/{{Model.Id}}/edit"
      routerLinkActive="active">
      <span class="icon-text">
        <span>Edit</span>
        <span class="icon">
          <i class="fa fa-chevron-right"></i>
        </span>
      </span>
    </button>
    <app-form-delete-button [Model]="Model" [Parent]="this" DeleteUrl="/infrastructure/localcontrollers/" DeleteNavLink="/parking/accesscontrol/terminals">
    </app-form-delete-button>
  </app-pageheader>
  <div class="columns">
    <div class="column is-6">
      <article class="panel">
        <p class="panel-heading">
          Terminal {{Model.Name}}
        </p>

        <a class="panel-block">
          <span class="label">Name</span>
          <span class="value">{{Model.Name}}</span>
        </a>

        <a class="panel-block">
          <span class="label">Checkins</span>
          <span class="value">Every <span class="has-text-weight-bold">{{Model.CheckinIntervalMinutes}}</span>
            minutes, offline if {{Model.OfflineThresholdMinutes}} minutes with no checkin</span>
        </a>
        <a class="panel-block">
          <span class="label">Location</span>
          <span class="value">
            <a *ngIf="Model.ParkingLevelName != '' && Model.ParkingLevelName != null"
              [routerLink]="['/parking/parking/lots', Model.ParkingLotId, 'levels', Model.ParkingLevelId]">{{Model.ParkingLevelName}}</a>
            <a *ngIf="Model.ParkingLotName != '' && Model.ParkingLotName != null"
              [routerLink]="['/parking/parking/lots/', Model.ParkingLotId]">{{(Model.ParkingLevelName != '' &&
              Model.ParkingLevelName != null) ? ', ' : ''}}{{Model.ParkingLotName}}</a>
            <p *ngIf="Model.ParkingLevelName == null && Model.ParkingLotName == null">Not Set</p>
          </span>
        </a>
        <a class="panel-block">
          <span class="label">Controlled Area</span>
          <span class="value"><a *ngIf="Model.ControlledAreaId != null"
              [routerLink]="['/parking/accesscontrol/controlledareas', Model.ControlledAreaId]">{{Model.ControlledAreaName}}</a></span>
        </a>
        <a class="panel-block">
          <span class="label">Last Checkin</span>
          <span class="value"><span *ngIf="deviceCheckin != null">{{deviceCheckin.LocalTimeStamp}}</span></span>
        </a>
      </article>
    </div>
    <div class="column is-6 has-text-centered">
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" height="250">
        <defs>
          <style>
            .cls-1 {
              fill: #116233;
              stroke: #116233;
              stroke-miterlimit: 10;
              stroke-width: 0.25px;
            }
          </style>
        </defs>
        <path class="cls-1"
          d="M18.69,27.13H10.78V15l.49.25a7.18,7.18,0,0,0,6.92-.14l.5-.29Zm-7.24-.68H18V16a8,8,0,0,1-6.57.13Z" />
        <path class="cls-1"
          d="M14.6,16.76A7.93,7.93,0,0,1,6.68,8.84a7.92,7.92,0,1,1,7.92,7.92Zm0-15.16a7.24,7.24,0,0,0-3.33,13.67,7.18,7.18,0,0,0,6.92-.14A7.24,7.24,0,0,0,14.6,1.6Z" />
        <path class="cls-1" d="M19.86,12.85H9.34V4.78H19.86ZM10,12h9.16V5.64H10Z" />
        <rect class="cls-1" x="10.84" y="8.47" width="7.51" height="0.67" />
        <path class="cls-1" d="M20.74,29.74h-12V26.45h12ZM9.4,29.06H20.07V27.13H9.4Z" />
      </svg>
    </div>
  </div>
  <div class="columns">
    <div class="column is-6">
      <article class="panel">
        <p class="panel-heading">
          Terminal Configuration
        </p>

        <app-tabs>
          <app-tab Title="Configurations">
            <a class="panel-block">
              <span class="label">Device Id</span>
              <span class="value">{{Model.Id}}</span>
            </a>

            <a class="panel-block">
              <span class="label">Key</span>
              <span class="value">{{Model.PrivateKey}}</span>
            </a>

            <a class="panel-block">
              <span class="label">Checkin Interval</span>
              <span class="value">{{Model.CheckinIntervalMinutes}}</span>
            </a>

            <a class="panel-block">
              <span class="label">Organization Id</span>
              <span class="value">{{loginService.CurrentOrganizationId()}}</span>
            </a>

            <a class="panel-block">
              <span class="label">Server Url</span>
              <span class="value">{{GetServerUrl()}}</span>
            </a>

            <a class="panel-block">
              <span class="label">Log Token</span>
              <span class="value">{{Model.LogToken}}</span>
            </a>



          </app-tab>
          <app-tab Title="Json">
            <app-codeeditor Mode="json" [(Model)]="terminalConfig">
            </app-codeeditor>
          </app-tab>
        </app-tabs>

      </article>
    </div>
    <div class="column is-6">
      <article class="panel">
        <p class="panel-heading">
          Stats
        </p>
        <div class="panel-block">
          <app-devicecheckinchart *ngIf="Model != null" [DeviceId]="Model.Id"></app-devicecheckinchart>
        </div>
      </article>
    </div>
  </div>
</div>