<p>debugmessage works!</p>


<div class="modal is-active" [formGroup]="Form">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Debug Message Details</p>
    </header>

    <div *ngIf="IsLoaded; else elseBlock">
      <section class="modal-card-body" style="width: 80em; min-width: 80em; max-width: 100em;">
        <app-tabs *ngIf="decodeMessageDetails; else errorDecode">
          <app-tab Title="Details">
            <div style="word-wrap: break-word">
              {{decodeMessageDetails.MessageDetails}}
            </div>

          </app-tab>
          <app-tab Title="NmeaMessage">
            <div style="word-wrap: break-word">
              {{decodeMessageDetails.NmeaMessage}}
            </div>
            <br>
            <div style="text-align: left;">
              <b>NmeaMessageValues</b>
              <div *ngFor="let item of decodeMessageDetails.NmeaMessageValues">
                {{item}}
              </div>

            </div>
          </app-tab>
          <app-tab Title="FrogMessage">
            <div *ngFor="let item of decodeMessageDetails.FrogMessageValues">
              {{item}}
            </div>


          </app-tab>
        </app-tabs>

        <ng-template #errorDecode style="width: 600px; min-width: 600px; max-width: 1000px;">
          Unable to decode message <br>
          <div>
            <label>Message Details</label>

            <p>{{DebugMessage}}</p>
          </div>
          <div>
            <label>Message Type</label>
            <p>{{PayloadType}}</p>
          </div>
        </ng-template>

      </section>

    </div>

    <ng-template #elseBlock>
      <section class="modal-card-body" style="width: 600px; min-width: 600px; max-width: 1000px;">
        <p>Decoding... Please wait</p>
      </section>
    </ng-template>


    <footer class="modal-card-foot">
      <button class="button is-success" (click)="confirm()">OK</button>
    </footer>
  </div>

</div>