import { Component, ElementRef, HostListener, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiService } from 'src/app/Services/api.service';
import { ColorService } from 'src/app/Services/color.service';
import { StructureViewerLayer } from 'src/app/shared/structureviewermaps/structureviewerlayers';

@Component({
  selector: 'app-parkinglevel',
  templateUrl: './parkinglevel.component.html',
  styleUrls: ['./parkinglevel.component.scss']
})




export class ParkinglevelComponent extends ModelEditor {
  @ViewChild('levelViewer') levelViewerRef!: ElementRef;

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log("set full screen to false");

    this.isFullScreen = false;
  }

  public StructureViewerLayers: StructureViewerLayer[] = [
    new StructureViewerLayer("ParkingLot", null, false, true), 
    new StructureViewerLayer("ParkingLevel"), 
    new StructureViewerLayer("ParkingSpace"), 
    new StructureViewerLayer("ParkingLevelMapItem", "Floor Map Items", false, false, "FloorItems"), 
    new StructureViewerLayer("ParkingLevelMapItem", "Raised Map Items", false, false, "RaisedItems"), 
    new StructureViewerLayer("SignConfiguration", "Signs")
  ]
  
  public DefaultModel() {

  }
  public AfterModelLoaded(): void {

  }
  public BeforeSave(): boolean | void {

  }
  public Validators() {

  }

  public thresholdsets: any;

  public isFullScreen = false;

  constructor(private apiService: ApiService, public route: ActivatedRoute, private cService: ColorService, private injector: Injector) {
    super("infrastructure/parkinglots/" + route.snapshot.params["lotid"] + "/levels", injector);
    this.apiService.Get<any>("infrastructure/thresholdsets").then(result => {

        console.log("Level data received");
        console.log("Level id" + result.Id);

        this.thresholdsets = result;

      });
  }

  getTextClass() {
    return this.cService?.GetVisibleTextClassFor(this.Model.ThresholdColor);
  }
}
