import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-edituserrole',
  templateUrl: './edituserrole.component.html',
  styleUrls: ['./edituserrole.component.scss']
})
export class EdituserroleComponent extends SimpleModalComponent<any, any> implements OnInit {
  Model: any = {};
  public Roles: any[] | null = null;
  constructor(private apiService: ApiServiceBase) {
    super();
  }

  ngOnInit(): void {
    this.apiService.Post<any>("organization/roles/search", { PageNumber: 1, PageSize: 9999, Page: 1 })
      .then(result => {
        this.Roles = result.Results;
      });
  }

  public RoleChanged() {
    if (this.Roles == null) {
      return;
    }
    let set = this.Roles.filter(x => x.Id == this.Model.Roles)[0];
    this.Model.RoleName = set.Name;
    this.Model.RoleId = set.Id;
  }

  confirm() {
    this.result = this.Model;
    this.close();
  }
}


