<div class="modal is-active" [formGroup]="Form">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit Gate</p>
      </header>
      <section class="modal-card-body"style="min-width: 800px;">
            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="label">Name</label>
                </div>
                <div class="field-body">
                    <div class="field">
                    <p class="control">
                        <input class="input" type="text" placeholder="Name" formControlName="Name" />
                    </p>
                    </div>
                </div>  
            </div>
            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="label">Location</label>
                </div>
                <div class="field-body">
                    <div class="field">
                        <p class="control">
                            <app-map-input formControlName="PolygonPoints" MapWidth="400" MapHeight="400"></app-map-input>
                            <!-- <app-mapeditor MapWidth="500" [(PolygonPoints)]="Model.PolygonPoints"></app-mapeditor> -->
                        </p>
                    </div>
                </div>  
            </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" (click)="confirm()">OK</button>
      </footer>
    </div>
  </div>