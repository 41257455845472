import { Component, EventEmitter, Input, OnChanges, OnInit, Output, Self, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ApiServiceBase } from 'src/app/Services/api.service.base';

@Component({
  selector: 'app-search-selector',
  templateUrl: './search-selector.component.html',
  styleUrls: ['./search-selector.component.scss']
})
export class SearchSelectorComponent implements OnInit, ControlValueAccessor {

  @Input()
  Items !: any[];

  FilteredItems !:  any[];

  public SearchTerm: string = "";
  public IsDropdownOpen: boolean = false;
  public currentIndex = 0;

  constructor(private apiService: ApiServiceBase, @Self() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  public writeValue(val: string | null): void {
    this.SearchTerm = val ?? "";
  }

  public onChange = (id: string | null) => {
   };
   
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public onTouched = () => { };
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  ngOnInit(): void {
    this.FilteredItems = this.Items;
  }

  SetSearchTermEvent(event : any){
    this.currentIndex = 0;
    this.IsDropdownOpen = true;
    this.SearchTerm = event.target.value;
    this.onChange(event.target.value);
    this.FilterItems();
  }

  SelectItem(value : string){
    this.SearchTerm = value;
    this.onChange(value);
    this.IsDropdownOpen = false;
  }

  toggleDropdown() {
    this.IsDropdownOpen = !this.IsDropdownOpen;
  }

   HandleKeyDown(event: any){
    switch(event.key){
      case 'ArrowUp':
        if(this.currentIndex > 0){
          this.currentIndex = this.currentIndex - 1;
        }
        break; 
      case 'ArrowDown':
        if(this.currentIndex < (this.FilteredItems.length -1)){
          this.currentIndex = this.currentIndex + 1;
        } 
        break;
      case 'Enter':
        this.SearchTerm = this.FilteredItems[this.currentIndex].Name;
        this.onChange(this.FilteredItems[this.currentIndex].Name);
        this.IsDropdownOpen = false;
        break;
    }
  }

  FilterItems() {
    if (this.SearchTerm.trim() === '') {
      // If search term is empty, display all items
      this.FilteredItems = this.Items;
    } else {
      // Filter items based on search term
      this.FilteredItems = this.Items?.filter(item =>
        item.Name.toLowerCase().includes(this.SearchTerm.trim().toLowerCase())
      );

      if(this.FilteredItems.length == 0){
        this.IsDropdownOpen = false;
      }
    }
  }

}
