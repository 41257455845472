<app-loader [Parent]="this"></app-loader>

<form *ngIf="!HideSearch">
    <div class="dlv-bar mr-3 mb-5 p3">
        <ng-content></ng-content>
    </div>
</form>
<div class="columns loading-container">
    <div class="column" [ngClass]="ShowMap ? 'is-6' : 'is-12'">
        <div class="level mb-0 pb-0">
            <div class="level-left pl-2">
                <!-- <div *ngIf="AllowAdd == true && AddHasOptions == true" class="level-item">
                    <div class="dropdown" (click)="showAddDropdown=!showAddDropdown"
                        [ngClass]="showAddDropdown ? 'is-active' : ''">
                        <div class="dropdown-trigger">
                            <button class="button is-primary is-small" aria-haspopup="true"
                                aria-controls="dropdown-menu">
                                <span class="fa fa-plus"></span>
                                <span>Add</span>
                                <span class="icon is-small">
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                </span>
                            </button>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content">
                                <a class="dropdown-item" *ngFor="let a of AddUrl" (click)="AddNewByRoute(a[1])">
                                    {{a[0]}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div *ngIf="Data.Results != null && ShowPaging == true" class="level-item">
                    <button class="button pagination-button is-small mr-3" (click)="PrevPage()"><i class="fa fa-chevron-left"></i></button>
                    Showing &nbsp;<span *ngIf="Data.Results.length != 0" class="has-text-weight-bold">{{(Data.PageSize * (Data.PageNumber-1))+1}}</span>
                    <span *ngIf="Data.Results.length != 0">&nbsp; to &nbsp;</span>
                    <span class="has-text-weight-bold">{{(Data.TotalRecords <= ((Data.PageSize * (Data.PageNumber-1))+Data.PageSize)) ? Data.TotalRecords :
                            ((Data.PageSize * (Data.PageNumber-1))+Data.PageSize)}}</span>
                            &nbsp; of &nbsp;
                            <span class="has-text-weight-bold">{{Data.TotalRecords}}</span>
                    <button class="button pagination-button is-small ml-3" (click)="NextPage()"><i class="fa fa-chevron-right"></i></button>
                </div>
            </div>

            <div class="level-right">
                <div class="level-item">
                    <div class="select" *ngIf="ShowPaging == true">
                        <select [(ngModel)]="Filter.PageSize" (change)="PageSizeChanged()">
                            <option [value]="10">10</option>
                            <option [value]="15">15</option>
                            <option [value]="25">25</option>
                            <option [value]="50">50</option>
                            <option [value]="100">100</option>
                            <option [value]="250">250</option>
                            <option [value]="500">500</option>
                        </select>
                    </div>
                </div>
            </div>

        </div>

        <table class="table is-striped">
            <thead *ngIf="ShowColumnHeaders">
                <tr>
                    <th *ngFor="let h of Columns" (click)="ApplySort(h)" [ngStyle]='{width: h.Width}'>{{h.Header}} 
                        <div style="display:inline; position:relative; margin-left:2em;" *ngIf="h.EnableFilter">
                            <i style="position:absolute; top:-4px;" [ngStyle]="{'color': IsFilterOn(h, true) ? 'black' : 'lightgrey'}" class="fa fa-chevron-up"></i>
                            <i style="position:absolute; top:7px;" [ngStyle]="{'color': IsFilterOn(h, false) ? 'black' : 'lightgrey'}" class="fa fa-chevron-down"></i>
                        </div>
                    </th>
                    <th *ngIf="ShowIssues" style="width:4em">
                        <!-- to mark items as issue -->
                       </th>
                    <th class="buttoncolumn">
                        
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of Data.Results" [ngClass]="{'is-selected': (row.Id === SelectedRow && HighlightOnSelect),'issue': (row.Issues != null && row.Issues.length > 0)}"
                    (click)="RowClicked(row)" (dblclick)="EditItem(row)">
                    <td *ngFor="let col of Columns" [ngStyle]="col.IsIcon ? { 'width': '1em' } : null" [innerHTML]="sanitizer.bypassSecurityTrustHtml(col.Render(row))"
                        [ngClass]="getClass(row)">
                    </td>
                    <td *ngIf="ShowIssues">
                        <div *ngIf="row.Issues.length > 0" class="icon-container">
                            <i *ngFor="let issue of row.Issues"  [tippy]="issue.IssueDescription" [ngClass]="'Icon fa fa-' + issue.IssueIcon + ' Flash ' + issue.Severity" [ngStyle]="{'color': '#' + issue.IssueIconColor}" style="font-size: 1.5em; margin-top:5px; text-align:center;"></i>
                        </div>                    
                    </td>
                    <td class="buttoncolumn" [ngClass]="getClass(row)">
                        <div *ngIf="AllowSelectBoxes">
                            <app-toggleswitch [value]="IsInSelectedItems(row)" (change)="RowSelectChanged($event, row)"></app-toggleswitch>
                        </div>
                        <button *ngIf="AllowEdit" type="button" class="button is-link is-small"
                            (click)="EditItem(row)"><i class="fa fa-chevron-right"></i></button>
                        <button *ngIf="AllowSelect" type="button" class="button is-primary" (click)="SelectItem(row)">
                            <span class="icon-text">
                                <span class="icon">
                                    <i class="fa fa-chevron-right"></i>
                                </span><span>Select</span>
                            </span>
                        </button>
                        <button *ngIf="ShowCustomAction(row)" type="button" class="button is-primary is-small"
                            (click)="CustomActionOnAction(row)">
                            <span class="icon-text">
                                <span>{{CustomActionText}}</span>
                                <span class="icon pt-2">
                                    <i class="fa fa-{{CustomActionIcon}}"></i>
                                </span>
                            </span>
                        </button>
                        <div class="is-right" style="display: inline-flex;width: 2em;margin-left: 3em;" *ngIf="AllowDropdownActions && ActionsOnRow(row)">
                            <div class="dropdown is-hoverable is-right" style="color:black">
                                <div class="dropdown-trigger"> 
                                    <span class="icon"> 
                                        <i class="fa-solid fa-ellipsis fa-lg" aria-hidden="true"></i> 
                                    </span> 
                                </div> 
                                <div class="dropdown-menu" id="dropdown-menu2" role="menu">
                                    <div class="dropdown-content" *ngIf="!UseActionsOnEachItem">
                                        <ng-container *ngFor="let action of this.DropdownActions; let i = index" >
                                            <a *ngIf="ShowAction(row, i)" class="dropdown-item" (click)="DropdownActionCalled(row, i)">
                                              <p>{{ action.text }}</p>
                                            </a>
                                        </ng-container>
                                    </div>
                                    <div class="dropdown-content" *ngIf="UseActionsOnEachItem">
                                        <ng-container *ngFor="let action of GetActionsForItem(row); let i = index" >
                                            <a class="dropdown-item" (click)="DropdownActionOnItemCalled(row, action)">
                                              <p>{{ action.text }}</p>
                                            </a>
                                        </ng-container>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <button *ngIf="AllowDelete" type="button" class="button is-danger is-small"
                        (click)="DeleteOnAction(row)">
                        <span class="icon-text">
                            <span class="icon">
                                <i class="fa fa-trash"></i>
                            </span>
                        </span>
                    </button>

                    </td>
                </tr>
            </tbody>
            <tfoot *ngIf="Data.Results != null && Data.Results.length == 0">
                <td colspan="999" class="has-text-centered">No Results!</td>
            </tfoot>
        </table>
        <div class="pr-3 mt-3" *ngIf="ShowPaging == true">
            <nav class="pagination  is-right" role="navigation" aria-label="pagination">
                <ul class="pagination-list">
                    <li>
                        <a class="pagination-previous" (click)="PrevPage()"><i class="fa fa-chevron-left"></i></a>
                    </li>
                    <li *ngFor="let p of Pages">
                        <a *ngFor="let i of p" class="pagination-link"
                            [ngClass]="{ 'is-current' : i == Filter.PageNumber}" (click)="GotoPage(i)">{{i}}</a>
                        <a class="pagination-ellipsis" *ngIf="p != Pages[Pages.length-1]">&hellip;</a>
                    </li>
                    <li>
                        <a class="pagination-next" (click)="NextPage()"><i class="fa fa-chevron-right"></i></a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <div *ngIf="ShowMap" class="column is-6" style="min-height: 80vh">
        <app-structureviewermaps #StructureViewer [Layers]="StructureViewerLayers" [ShowLotSelect]="true" [ShowLevelSelect]="true" [StructureViewerForType]="StructureViewerForType"></app-structureviewermaps>
    </div>

</div>