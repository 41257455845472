import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { LevelEditorComponent } from "../../leveleditor.component";
import { PolygonTool } from "./PolygonTool";
import { v4 as uuidv4 } from 'uuid';
import { Gate } from "../../components/PolygonComponents/Gate";

export class GateTool extends PolygonTool{
    public override ItemWidth: number = 200;
    public override ItemHeight: number = 200;
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }

    public override CreateNew(canvasX : number, canvasY: number){
        let gates = this.editor.ModelEditor.FormArray(this.editor.Form, "Gates");
        let newGateFormGroup = new FormGroup({
            Id: new FormControl(uuidv4()),
            Name: new FormControl("Gate"),
            Lanes: new FormControl([]),
            PolygonPoints: new FormControl(this.CreatePolygonFromXY(canvasX, canvasY)),
            EditorLocked: new FormControl(false)
        });
        (gates as FormArray).push(newGateFormGroup);

        var component = new Gate(this.editor, this.canvas, newGateFormGroup, null);
        this.editor.Form.markAsDirty();
        return component;
    }
}
