<div>
    <div class="control">
      <div class="field is-grouped is-grouped-multiline" *ngIf="formArray != undefined">
        <div class="tags has-addons" *ngFor="let itemControl of formArray.controls; let i = index">
          <span *ngIf="itemControl.get('Email')?.value != null" class="user-text">
            <a class="tag">{{ itemControl?.get('Email')?.value }}</a>
            <a class="tag" (click)="removeUser(i)"><i class="fa fa-trash"></i></a>
          </span>
        </div>
        <div class="tags has-addons">
          <a class="tag button is-primary" *ngIf="AllowCreateUser && (MaxUsers - formArray.length > 0)" (click)="addUser()">Add</a>
        </div>
      </div>
    </div>
  
    <div *ngIf="formArray != undefined">
    <div *ngFor="let itemControl of formArray.controls; let i = index">
        <span *ngIf="itemControl.get('Email')?.value == null" class="user-text">
          <div class="addComponent">
            <!-- Remove ngModel and formControl directives from the input field -->
              <div style="display:flex">
                  <input class="input" (keyup.enter)="onEnterKeyPressed($event)" [ngStyle]="{ 'border-color': (!EmailValid && (SetEmail != '')) ? 'red' : '' }" placeholder="enter users email address" (input)="SetNewEmail($event)">
                  <div class="control">
                  <a class="button is-primary" [ngClass]="{ 'disabled': !EmailValid }" (click)="AddEmailToList(itemControl)"><i class="fa fa-check"></i></a>
                  </div>
                  <div class="control">
                    <a class="button is-light ng-star-inserted" (click)="RemoveItem(i)"><i class="fa fa-trash"></i></a>
                    </div>
              </div>
              <div *ngIf="!EmailValid && (SetEmail != '')" class="error-message" style="color:red; font-size: small;">Invalid email format.</div>
          </div>
        </span>
      </div>
      </div>
    </div>
  