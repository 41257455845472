import { Component, EventEmitter, Input, OnChanges, OnInit, Output, Self, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ApiServiceBase } from 'src/app/Services/api.service.base';

@Component({
  selector: 'app-space-selector',
  templateUrl: './space-selector.component.html',
  styleUrls: ['./space-selector.component.scss']
})
export class SpaceSelectorComponent implements OnInit, ControlValueAccessor, OnChanges {

  public spaceId: string | null = null;
  @Output()
  public spaceIdChanged = new EventEmitter<string | null>();

  public InitChipId = "";
  @Input()
  public Space: any = {};

  public searchResults: any[] = [];
  public searchTerm: string = "LocationString";
  public isLoading = false;

  @Input()
  public parkingLotId: string | null = null;
  @Input()
  public parkingLevelId: string | null = null;

  constructor(private apiService: ApiServiceBase, @Self() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.spaceId = this.Space.Id;
    this.onChange(this.Space.Id);
    this.spaceIdChanged.emit(this.Space);
  }

  public writeValue(id: string | null): void {
    console.log("Write value");

    this.spaceId = id;
    this.Space.Id = id;
    if (this.spaceId == "") this.Space = null;
  }
  public onChange = (id: string | null) => {
    this.spaceIdChanged.emit(id);
   };
   
  public registerOnChange(fn: any): void {
    console.log("Reg on change");

    this.onChange = fn;
  }
  public onTouched = () => { };
  public registerOnTouched(fn: any): void {
    console.log("On Touched");

    this.onTouched = fn;
  }
  ngOnInit(): void {
  }


  public ItemSelected(item: any) {
    if (item && item != null && item.Id) {
      this.Space = { Id: item.Id, LocationString: item.LocationString };
      this.spaceId = item.Id;
      this.onChange(item.Id);
      this.spaceIdChanged.emit(this.Space);
    }
  }
  public SearchChanged(val: string) {
    console.log("Sensor Selector search event: '" + val + "'");
    if (val == null || val == "") return;
    this.Space = null;
    this.isLoading = true;
    //"infrastructure/sensor/searches"
    this.apiService.Post<any>("infrastructure/parkinglots/" + this.parkingLotId + "/levels/" + this.parkingLevelId + "/spaces/searches", { PageNumber: 1, PageSize: 8, SearchTerm: val }).then(result => {
      this.searchResults = [];
      result.Results.forEach((element: any) => {
        this.searchResults.push({
          Id: element.Id,
          LocationString: element.LocationString
        });
      });

      console.log("Search results: ");

      console.log(this.searchResults);

      this.isLoading = false;
    });
  }
  public ClearSpace() {
    this.spaceId = null;
    this.onChange(null);
  }

  public Cleared() {
    this.spaceId = null;
    this.onChange(null);
  }
  public KeyPress(evt: any) {
    if (evt.keyCode == 13) {
      evt.stopPropagation();
    }
  }
  public InputKeyPress(evt: any) {

  }

}
