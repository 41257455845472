import { Injectable, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ChartConfiguration } from "../ChartConfiguration";
import { DashboardChartComponent } from "../dashboard-chart.component";

const CHART_CONFIG_CACHE_KEY = 'dashboard-chart-config';

@Injectable({
    providedIn: 'root'
})
export class ChartStateHandler {
    private dashboardChart!: DashboardChartComponent;
    chartConfig!: ChartConfiguration;

    constructor(public router: Router, dashboardChart: DashboardChartComponent) {
        this.dashboardChart = dashboardChart;
    }
    /**
     * Loads the chart state from either router navigation state or localStorage.
     * First attempts to get state from router navigation, then falls back to cached state if needed.
     * If a valid configuration is found, this updates the dashboard chart properties.
    */
    public loadState() {
        const navigation = this.router.getCurrentNavigation();
        const chartConfig = navigation?.extras?.state as ChartConfiguration;
        this.dashboardChart.chartConfig = chartConfig;
    
        if (!this.dashboardChart.chartConfig) {
          const cachedConfig = localStorage.getItem(CHART_CONFIG_CACHE_KEY);
          this.dashboardChart.chartConfig = cachedConfig ? JSON.parse(cachedConfig) : null;
        }

        if (this.dashboardChart.chartConfig) {
          const { chartType, title, dataSetRequests, endDate, startDate, stacked, isFullPage, groupBy, bucketSize, selectedDateRange, asPercentage, hideStatistics } = this.dashboardChart.chartConfig;
          this.updateChartProperties({
            chartType, title, dataSetRequests, endDate, startDate, stacked, isFullPage, groupBy, bucketSize, selectedDateRange, asPercentage, hideStatistics
          });
        }
    }

    /**
     * Saves the current chart configuration to localStorage.
     * Only saves if the chart is in full page mode, so we dont save the chart state when it is embedded in a widget
     */
    public saveState(): void {
        if (this.dashboardChart.chartConfig?.isFullPage) {
            localStorage.setItem(CHART_CONFIG_CACHE_KEY, JSON.stringify(this.dashboardChart.chartConfig));
        }
    }

    /**
     * Removes the saved chart configuration from localStorage.
     */
    public clearState() {
        if (this.dashboardChart.chartConfig?.isFullPage) {
            localStorage.removeItem(CHART_CONFIG_CACHE_KEY);
        }
    }

    /**
     * Updates the dashboard chart properties with new values while preserving existing ones if not provided.
     */
    private updateChartProperties(props: Partial<ChartConfiguration>) {
        Object.assign(this.dashboardChart, {
          chartType: props.chartType ?? this.dashboardChart.chartType,
          title: props.title ?? this.dashboardChart.title,
          dataSetRequests: props.dataSetRequests ?? this.dashboardChart.dataSetRequests,
          EndDate: props.endDate ?? this.dashboardChart.EndDate,
          StartDate: props.startDate ?? this.dashboardChart.StartDate,
          stacked: props.stacked ?? this.dashboardChart.stacked,
          isFullPage: props.isFullPage ?? this.dashboardChart.isFullPage,
          GroupBy: props.groupBy ?? this.dashboardChart.GroupBy,
          bucketSize: props.bucketSize ?? this.dashboardChart.bucketSize,
          selectedDateRange: props.selectedDateRange ?? this.dashboardChart.selectedDateRange,
          asPercentage: props.asPercentage ?? this.dashboardChart.asPercentage,
          hideStatistics: props.hideStatistics ?? this.dashboardChart.hideStatistics
        });
    }
}