<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Form]="Form" [Parent]="this" (click)="Save()"></app-form-save-button>
    </app-pageheader>

    <div class="columns">
        <div class="column is-6">
            <article class="panel">
                <p class="panel-heading">
                    Permit Details
                </p>
                <div class="panel-block" style="display: block;">
                    <app-field Label="Permit Type">
                        <p class="control select">
                            <select class="input" formControlName="ParkingPermitTypeId" (change)="UpdateParkingPermitType()">
                                <option *ngFor="let c of PermitTypes" [ngValue]="c.Id" [disabled]="c.AvailableCount <= 0 && c.MaxActivePermits != null && c.MaxActivePermits != 0">
                                    {{c.Name}} ({{c.UsedCount}}{{c.MaxActivePermits != null && c.MaxActivePermits != 0 ? '/' + c.MaxActivePermits : ''}} used)
                                </option>
                            </select>
                        </p>
                    </app-field>       
                    <app-field Label="Active From" [HasAddons]="true" tippy="">
                        <div class="control">
                            <input formControlName="ActiveStartDateLocal" class="input" type="date">
                            <app-validator [For]="this.Form.get('ActiveStartDateLocal')"></app-validator> 
                        </div>
                    </app-field>
                    <app-field Label="Users">
                        <div class="control">
                            <div class="field is-grouped is-grouped-multiline">
                                <div class="tags has-addons" *ngFor="let user of FormArray(this.Form, 'Users').controls; let i = index;">
                                <a class="tag">{{user.get('FirstName')?.value}} {{user.get('LastName')?.value}} ({{user.get('Email')?.value}})</a>
                                <a class="tag is-dark" *ngIf="(this.Form.get('PurchasingPermitUserId')?.value == user.get('Id')?.value) && Model.ParkingPermitType.MultiUser && !Model.ParkingPermitType.SplitPayments ">Owner</a>
                                <a class="tag" (click)="removeUser(i)"><i class="fa fa-trash"></i></a>
                                </div>
                            </div>
                          </div>
                    </app-field>
                    <app-field Label="Invitees">
                        <app-emailtaglist formArrayName="Invitees" [AllowCreateUser]="true" [MaxUsers]="(!Model.ParkingPermitType?.MultiUser ? 1 - (Form.get('Users')?.value.length || 0) : Model.ParkingPermitType?.MaxUsers - (Form.get('Users')?.value.length || 0))"></app-emailtaglist>
                    </app-field>
                    <app-field Label="Limit To Parking Space" *ngIf="this.Model.ParkingPermitType.LimitToParkingSpace" [HasAddons]="true" tippy="">
                        <div class="control">
                            <div class="field is-grouped is-grouped-multiline">
                                <div class="tags has-addons">
                                    <a class="tag" *ngIf="this.Form.get('ParkingSpaceLocationString')?.value != null">{{this.Form.get('ParkingSpaceLocationString')?.value}}</a>
                                    <a class="tag button is-primary" (click)="AddParkingSpace()">Add/Change</a>
                                </div>
                            </div>
                        </div>
                    </app-field>
                    <app-field Label="Assigned Vehicles" [HasAddons]="true" tippy="">
                        <div class="control">
                            <div class="field is-grouped is-grouped-multiline">
                                <div class="field is-grouped is-grouped-multiline">
                                    <div class="tags has-addons" *ngFor="let x of this.Form.get('Vehicles')?.value; let i = index">
                                    <a class="tag">{{x.VehicleColor}} {{x.VehicleMake}} ({{x.VehiclePlateNumber}})</a>
                                    <a class="tag" (click)="RemoveVehicle(i)"><i class="fa fa-trash"></i></a>
                                    </div>
                                    <div class="tags has-addons">
                                        <a class="tag button is-primary" *ngIf="Model.ParkingPermitType.MaxVehicles == 0 || (Model.ParkingPermitType.MaxVehicles - (Form.get('Vehicles')?.value.length || 0) > 0)" (click)="AddVehicle()">Add</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </app-field>
                </div>
            </article>
        </div>
        <div class="column is-6" *ngIf="this.Form.get('ParkingPermitTypeId')?.value != null">
            <article class="panel">
                <p class="panel-heading">
                    {{Model.ParkingPermitType.Name}}
                </p>
                <div class="panel-block">
                    <span class="label">Active Dates</span>
                    <span class="value"><app-daterangedisplay FieldName="Active Dates" [StartDate]="Model.ParkingPermitType.ActiveStartDateLocalFriendlyString" [EndDate]="Model.ParkingPermitType.ActiveEndDateLocalFriendlyString"></app-daterangedisplay></span>
                </div>
                <div class="panel-block">
                    <span class="label">Price</span>
                    <span class="value">{{orgService.GetCurrencySymbol()}}{{Model.ParkingPermitType.Price.toFixed(2)}} {{Model.ParkingPermitType.ChargePeriodString}} {{((Model.ParkingPermitType.MultiUser && Model.ParkingPermitType.SplitPayments) ? ' - Split Payment' : '')}}</span>
                </div>
                <div class="panel-block">
                    <span class="label">Users</span>
                    <span class="value">{{BuildUsersString()}}</span>
                </div>
                <div class="panel-block">
                    <span class="label">Restrictions</span>
                    <span class="value" style="width:65%"><div class="is-display" [innerHtml]="Model.ParkingPermitType.Restriction" style="font-size:small;"></div></span>
                </div>
                <div class="panel-block">
                    <span class="label">Confirmations</span>
                    <span class="value">{{(Model.ParkingPermitType.RequireConfirmations ? 'Confirmation by members required within ' + Model.ParkingPermitType.MaxConfirmationDistance + orgService.KMorMiles() : 'Not Required')}}</span>
                </div>
            </article>
        </div>
    </div>