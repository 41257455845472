import { Component, Input, OnInit, Self, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

@Component({
  selector: 'app-daterangedisplay',
  templateUrl: './daterangedisplay.component.html',
  styleUrls: ['./daterangedisplay.component.scss']
})

export class DateRangeDisplayComponent implements OnInit {
  @Input() 
  StartDate: string = "            ";
  @Input() 
  EndDate: string = "            ";

  @Input() 
  FieldName: string = "";
  ngOnInit(): void {
  }
}
