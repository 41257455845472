<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form">
    <app-pageheader [Parent]="this">
        <app-form-save-button (click)="Save()" [Parent]="this" [Form]="Form"></app-form-save-button>
    </app-pageheader>

    <article class="panel">
        <div class="columns" style="margin: 0;">
            <div class="column is-6">
                <app-field Label="First Name">
                    <div class="control">
                        <input formControlName="FirstName" class="input" type="text" />
                    </div>
                </app-field>
                <app-field Label="Last Name">
                    <div class="control">
                        <input formControlName="LastName" class="input" type="text" />
                    </div>
                </app-field>
                <app-field Label="Email">
                    <div class="control">
                        <input formControlName="Email" class="input" type="email" />
                    </div>
                </app-field>
                <app-field Label="Password">
                    <div class="control">
                        <div class="field has-addons" style="width:100%;">
                            <input value="Password123" class="input" type="password" disabled />
                            <div class="control">
                                <a class="button is-primary" routerLink="/account/changepassword">Change
                                    Password</a>
                            </div>
                        </div>
                    </div>
                </app-field>
            </div>
        </div>
    </article>
</div>