<app-loader [Parent]="this"></app-loader>
<div>
  <app-pageheader [Parent]="this">
    <form-edit-button>Edit</form-edit-button>
    <app-form-delete-button [Model]="Model" [Parent]="this" DeleteUrl="/infrastructure/spacetypes/" [DeleteCheck]="false" DeleteNavLink="/parking/parking/spacetypes"
    Api="Infrastructure" TypeName="ParkingSpaceType">
    </app-form-delete-button>
  </app-pageheader>
  <div class="columns" *ngIf="Model != null">
    <div class="column is-6">
      <article class="panel" style="height:100%">
        <p class="panel-heading">
          {{Model.Name}}
        </p>
        <a class="panel-block">
          <span class="label">Id</span>
          <span class="value">{{Model.Id}}</span>
        </a>
        <a class="panel-block">
          <span class="label">Description</span>
          <span class="value">{{Model.Description}}</span>
        </a>
        <a class="panel-block">
          <span class="label">Include In Counts</span>
          <span class="value">{{Model.IncludeInCounts}}</span>
        </a>
      </article>
    </div>
    <div class="column is-6">
      <article class="panel" style="height:100%">
        <p class="panel-heading">
          Display
        </p>
        <a class="panel-block" style="height:100%" [ngStyle]="{'background-color': '#' + Model.Color}"
          style="justify-content:center;">
          <app-mediaviewer [MediaId]="Model.MediaId" Height="7em" Width="7em">
          </app-mediaviewer>
        </a>
      </article>
    </div>
  </div>
  <article class="panel" *ngIf="Model != null">
    <p class="panel-heading">
      Locations of {{Model.Name}} Spaces
    </p>
    <app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="infrastructure/spaces/searches"
      [AllowEdit]="false">
      <app-filter-set>
        <div app-search-filter></div>
        <div app-search-button></div>
        <app-property-filter Property="ParkingSpaceTypeId" Comparitor="==" [Value]="Model.Id"></app-property-filter>
      </app-filter-set>
    </app-datalistview>
  </article>
</div>