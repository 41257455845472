<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader Title="Legacy Sign" [Form]="Form" [Parent]="this">
        <app-form-save-button [Parent]="this" [Form]="Form" class="button is-primary" (OnSave)="Save()">
        </app-form-save-button>
    </app-pageheader>

    <app-tabs>
        <app-tab Title="Details">
            <div class="columns">
                <div class="column is-6">
                    <app-field Label="Chip ID" [HasAddons]="true">
                        <div class="control flex-grow">
                            <input formControlName="ChipId" class="input disabled" readonly>
                        </div>
                        <div class="control">
                            <a class="button is-static">
                                HID
                            </a>
                        </div>
                    </app-field>
                    <app-field Label="Frog ID">
                        <div class="control">
                            <input formControlName="Id" class="input disabled" readonly>
                        </div>
                    </app-field>
                    <app-field Label="PEK">
                        <div class="control">
                            <input formControlName="PrivateEncryptionKey" class="input disabled" readonly>
                        </div>
                    </app-field>
                    <app-field Label="Activated">
                        <div class="control">
                            <input formControlName="IsActivated" class="input disabled" readonly>
                        </div>
                    </app-field>
                    <app-field Label="Display Count For">
                        <div class="select is-fullwidth">
                            <select formControlName="ParkingLotId">
                                <option [ngValue]="null">Legacy (Association Set) Mode</option>
                                <option *ngFor="let p of ParkingLots" [ngValue]="p.Id">{{p.Name}}</option>
                            </select>
                        </div>
                    </app-field>
                    <app-field Label="Level" *ngIf="ParkingLots != null && Form.get('ParkingLotId')?.value != null" [HasAddons]="true">
                        <div class="select is-fullwidth">
                            <select formControlName="ParkingLevelId">
                                <option [ngValue]="null">All</option>
                                <option *ngFor="let l of LevelsForLot(Form.get('ParkingLotId')?.value)"
                                    [ngValue]="l.Id">{{l.Name}}</option>
                            </select>
                        </div>
                    </app-field>
                    <app-field Label="Row" *ngIf="ParkingLots != null && Form.get('ParkingLevelId')?.value != null" [HasAddons]="true">
                        <div class="select is-fullwidth">
                            <select formControlName="ParkingRowId">
                                <option [ngValue]="null">All</option>
                                <option
                                    *ngFor="let r of RowsForLevel(Form.get('ParkingLotId')?.value, Form.get('ParkingLevelId')?.value)"
                                    [ngValue]="r.Id">{{r.Name}}</option>
                            </select>
                        </div>
                    </app-field>
                    <app-field Label="Last Check In" [HasAddons]="true">
                        <div class="control flex-grow">
                            <input formControlName="LastCheckinString" class="input disabled" readonly>
                        </div>
                        <div class="control">
                            <a class="button is-static">
                                {{TimeZoneName}}
                            </a>
                        </div>
                    </app-field>
                </div>
                <div class="column is-6">
                    <app-map-input formControlName="LocationPoints" Mode="marker" MapWidth="100%"></app-map-input>
                </div>
            </div>
        </app-tab>
        <app-tab Title="Device Settings">
            <div class="columns">
                <div class="column is-6">
                    <app-field Label="Radio Channel" [HasAddons]="true">
                        <div class="select is-fullwidth">
                            <select class="input" formControlName="RadioChannel">
                                <option *ngFor="let i of [0,1,2,3,4,5,6,7]" [ngValue]="i">{{i}}</option>
                            </select>
                        </div>
                    </app-field>
                    <app-field Label="Tx Power" [HasAddons]="true">
                        <div class="select is-fullwidth">
                            <select class="input" formControlName="TxPower">
                                <option *ngFor="let i of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]"
                                    [ngValue]="i">
                                    {{i}}</option>
                            </select>
                        </div>
                    </app-field>
                    <app-field Label="Mode" [HasAddons]="true">
                        <div class="select is-fullwidth">
                            <select class="input" formControlName="Mode">
                                <option value="0">0. Polling</option>
                                <option value="1">1. Local Device</option>
                                <option value="2">2. Polling Master</option>
                                <option value="3">3. Local device Master</option>
                                <option value="4">4. Slave</option>
                                <option value="5">5. Push</option>
                                <option value="6">6. Reserved</option>
                            </select>
                        </div>
                    </app-field>
                </div>
            </div>
        </app-tab>
    </app-tabs>


</div>