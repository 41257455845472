import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/auth/login.service';
import { MediaService } from 'src/app/Services/media.service';
import { ColorDisplaycolumn, Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-calendardaytypes',
  templateUrl: './calendardaytypes.component.html',
  styleUrls: ['./calendardaytypes.component.scss']
})
export class CalendarDayTypesComponent implements OnInit {
  
  constructor(private loginService: LoginService) { }

  public Columns : Array<Datalistviewcolumn> = [
    new ColorDisplaycolumn("Color", "Color", "5em"),
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Description", "Description")
  ];

  ngOnInit(): void {
  }
}