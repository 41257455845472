import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import { DocumentEditorComponent } from '../documenteditor.component';
import { Style } from '../HtmlRenderComponents';

export abstract class DocumentComponentBase {
    public Id !: string;
    public documentEditorComponent !: DocumentEditorComponent;
    public fabricItem !: fabric.Object | fabric.Text | null;

    constructor(documentEditorComponent: DocumentEditorComponent) {
        this.Id = uuidv4(); // Generate a new UUID for each document
        this.documentEditorComponent = documentEditorComponent;
    }

    public Remove(canvas: any){
        return;
    }

    public RenderHtml() : string {
        return "";
    }

    public Draw(canvas: fabric.Canvas){
        return;
    }

    public BringToFront(){
        return;
    }

    public SendToBack(){
        return;
    }

    public BringForward(){
        return;
    }

    public SendBackward(){
        return;
    }
}