<app-pageheader [Parent]="this">
</app-pageheader>

<app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="parking/parkingsessions/searches"
  [AllowEdit]="true" EditUrl="/parking/parking/sessions/:id" HighLightRowColumn="Status"
  [HighLightCondition]="['Open']" [UseQueryParamStates]="true" [DelayedSearchFields]="['rateset', 'permit']">
  <app-filter-set>
    <div app-search-filter></div>
    <div app-date-filter DateSearchProperty="StartDate"></div>
    <div app-rates-filter *ngIf="this.ShowRatesFilter"></div>
    <div app-permits-filter *ngIf="this.ShowPermitFilter"></div>
    <div app-search-button></div>

    <!-- <app-filter-set title="Advanced Search" [subSection]=true advanced>
      <app-subset-filter></app-subset-filter>
      <app-admin-filter></app-admin-filter>

    </app-filter-set> -->
    <button type="button" class="button is-primary ml-auto" (click)="AddSession()">
      <i class="fa fa-plus"></i> 
      Add Session
    </button>
  </app-filter-set>
</app-datalistview>