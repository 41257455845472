import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ApiServiceBase } from 'src/app/Services/api.service.base';

@Component({
  selector: 'app-customgroup',
  templateUrl: './customgroup.component.html',
  styleUrls: ['./customgroup.component.scss']
})
export class CustomgroupComponent extends Busyable implements OnInit {

  public ModelId: any;
  public Filters: any;
  public Model: any;

  constructor(private apiService: ApiServiceBase, private parentRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.Loading();
    this.parentRoute.params.subscribe(params => { this.ModelId = params['id']; });
    this.apiService.Get<any>("infrastructure/customgroups/" + this.ModelId).then(result => {
      this.Model = result;
      this.StopLoading();
    });
  }
}
