import { Component, OnInit, ViewChild } from '@angular/core';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ApiService } from 'src/app/Services/api.service';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { DatalistviewComponent } from 'src/app/shared/datalistview/datalistview.component';

@Component({
  selector: 'app-editvalidationcode',
  templateUrl: './editvalidationcode.component.html',
  styleUrls: ['./editvalidationcode.component.scss']
})

export class EditValidationCodeComponent extends SimpleModalComponent<any, any> implements OnInit {

  constructor(public apiService: ApiService, public toastService: ToastrService, public modalService: SimpleModalService) {
      super();
  }

  public ValidationProfileId : string = "";
  public ValidationUserId : string = "";
  public Busyable: Busyable = new Busyable();
  public ValidationCode: any;
  @ViewChild("DataList")
  public DataList!: DatalistviewComponent;

  ngOnInit(): void {
    this.ValidationCode = { Code: "", MaxUses: null, ActiveStartDateLocal: null, ActiveEndDateLocal: null}

    this.Busyable.Loading();
    this.apiService.Get("/parking/validation/profiles/" + this.ValidationProfileId + "/users/" + this.ValidationUserId + '/codes').then((result: any) => {
      this.ValidationCode.Code = result.Code;
      this.Busyable.StopLoading();
    }).catch((reason: any) => {
      this.toastService.error(reason.error.Error.Message, "There was an error generating a code for the given template");
      this.Busyable.StopLoading();
    });
  }

  public Save(){
    this.apiService.Post("/parking/validation/profiles/" + this.ValidationUserId + '/users/' + this.ValidationUserId + "/codes", this.ValidationCode).then(result => {
      this.Busyable.StopLoading();
      this.result = true;
      this.close();
    });
  }

  public update(property: any, evt: any){
    this.ValidationCode[property] = evt.target.value;
  }
}
