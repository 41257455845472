import { Component, OnInit } from '@angular/core';
import { BooleanCheckOrCrossViewColumn, Datalistviewcolumn, DateRangeViewColumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-validationprofiles',
  templateUrl: './validationprofiles.component.html',
  styleUrls: ['./validationprofiles.component.scss']
})
export class ValidationprofilesComponent implements OnInit{
  constructor() { }

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Code Template", "CodeTemplate"),
    new Datalistviewcolumn("Users", "UsersCount"),
  ];

  ngOnInit(): void {
  }
}
