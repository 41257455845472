import { Component, Input, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { Datalistrequestfilter } from 'src/app/shared/datalistview/request/datalistrequestfilter';
import { DebugmessageComponent } from './debugmessage/debugmessage.component';


@Component({
  selector: 'app-debugview',
  templateUrl: './debugview.component.html',
  styleUrls: ['./debugview.component.scss']
})
//export class DebugviewComponent extends ModelEditor {
export class DebugviewComponent implements OnInit {

  @Input()
  public ApiUrl: string = "/infrastructure/debugmessages/searches";
  @Input()
  public DecodeUrl: string = "/infrastructure/debugmessages/decode";
  @Input()
  public ModelId: string = "";

  public IsLoaded: boolean = false;

  constructor(private modalService: SimpleModalService) {

  }

  ngOnInit(): void {
    this.Filters = [
      new Datalistrequestfilter("Id", "=", this.ModelId),
    ];
    this.IsLoaded = true;
  }

  public Selected(item: any) {

    this.modalService.addModal(DebugmessageComponent,
      { Id: item.Id, PayloadType: item.MessageType, DebugMessage: item.Details, PostUrl: this.DecodeUrl })
      .subscribe((result) => {
        if (result != null) {
          //this.AddToFormArray(this.FormArray(this.Form, "DebugMessage"), result, "terminals")
          //this.RefreshAreaBackgroundItems();
        }
      });
  }

  public Columns: Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Occurred On", "OccurredOn"),
    new Datalistviewcolumn("Message Type", "MessageType"),
    new Datalistviewcolumn("Details", "Details", 20),
  ];
  public Filters: Array<Datalistrequestfilter> = [];

}
