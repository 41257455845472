import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Datalistrequestfilter } from '../../request/datalistrequestfilter';
import { IFilterFilters } from '../ifilter';

@Component({
  selector: '[app-admin-role-filter]',
  templateUrl: './admin-role-filter.component.html',
  styleUrls: ['./admin-role-filter.component.scss'],
  host: { 'class': 'control select' }
})
export class AdminRoleFilterComponent implements IFilterFilters, OnInit {
  @Input()
  public SelectedIsAdmin: any = false;
  @Output()
  public ValueOutput = new EventEmitter<string>();
  constructor() { }
  ngOnInit(): void {
    this.Filters.push({
      Property: "Discriminator",
      Comparator: "==",
      Value: this.SelectedIsAdmin == "true" ? "EndUserRole" : "AdminRole",
      FilterName: this.FilterName
    });

    this.ValueOutput.emit(this.SelectedIsAdmin);
  }
  public Filters: Datalistrequestfilter[] = [];
  public FilterName: string = "role";
  QueryParamPrefix: string= '';

  public addIsAdminFilter(event: any) {
    var val = event.target.value;

    if (val != "") {
      this.Filters = [];
      this.Filters.push({
        Property: "Discriminator",
        Comparator: "==",
        Value: val == "true" ? "EndUserRole" : "AdminRole",
        FilterName: this.FilterName
      });
      
      this.ValueOutput.emit(val);
    }

  }
}
