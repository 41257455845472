import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceBase } from 'src/app/Services/api.service.base';

@Component({
  selector: 'app-add-session',
  templateUrl: './add-session.component.html'
})
export class AddSessionComponent extends SimpleModalComponent<any, null> implements OnInit {
  public form: FormGroup;
  public controlledAreas: any[] = [];
  public parkingLots: any[] = [];
  public rateSets: any[] = [];
  public loading = false;

  constructor(
    private modalService: SimpleModalService,
    private fb: FormBuilder,
    private apiService: ApiServiceBase,
    private toastService: ToastrService,
  ) {
    super();
    // Format current date and time to match datetime-local input format
    const now = new Date();
    const localDateTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, 16);

    this.form = this.fb.group({
      EntryLanePlate1Number: ['', Validators.required],
      ControlledAreaId: ['', Validators.required],
      ParkingLotId: ['', Validators.required],
      ParkingRateSetId: ['', Validators.required],
      ControlledAreaEntryDate: [localDateTime, Validators.required]
    });
  }

  ngOnInit() {
    this.loadData();
  }

  private async loadData() {
    this.loading = true;
    try {
      // Load controlled areas
      const areas = await this.apiService.Get<any[]>('infrastructure/controlledareas');
      this.controlledAreas = areas;

      // Load parking lots
      const lots = await this.apiService.Get<any[]>('infrastructure/parkinglots');
      this.parkingLots = lots;

      // Load rate sets
      const rates = await this.apiService.Get<any[]>('parking/ratesets');
      this.rateSets = rates;
    } catch (error) {
      this.toastService.error('Failed to load required data');
    } finally {
      this.loading = false;
    }
  }

  public async confirm() {
    if (this.form.invalid) {
      this.toastService.error('Please fill in all required fields');
      return;
    }

    this.loading = true;
    try {
      const dateString = this.form.get('ControlledAreaEntryDate')?.value;
      const date = new Date(dateString);

      const utcDateTicks = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
        date.getUTCDate(), date.getUTCHours(),
        date.getUTCMinutes(), date.getUTCSeconds());

      const utcDate = new Date(utcDateTicks);
      
      this.form.patchValue({
        ControlledAreaEntryDate: utcDate.toISOString()
      });
      const response = await this.apiService.Post('parking/parkingsessions', this.form.value);
      if (response) {
        this.toastService.success('Parking session created successfully');
        this.close();
      }
    } catch (error) {
      this.toastService.error('Failed to create parking session');
    } finally {
      this.loading = false;
    }
  }
}
