import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { DatalistviewComponent } from 'src/app/shared/datalistview/datalistview.component';
import { Busyable } from 'src/app/shared/editors/busyable';
import { StructureViewerLayer } from 'src/app/shared/structureviewermaps/structureviewerlayers';
import { EditValidationCodeComponent } from '../validationprofileusers/details/editcodes/editvalidationcode.component';

@Component({
  selector: 'app-validationprofile',
  templateUrl: './validationprofile.component.html',
  styleUrls: ['./validationprofile.component.scss']
})
export class ValidationprofileComponent extends Busyable implements OnInit{
  
  public ModelId : any;
  public Model: any;
  public ApiUrl: any;
  public modalService : SimpleModalService;  
  public toastService : ToastrService;
  @ViewChild("DataList")
  public DataList!: DatalistviewComponent;

  public Columns: Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("User", "UserFullName"),
    new Datalistviewcolumn("Last Code Generation On", "CodeLastGeneratedAtLocalString")
  ];

  constructor(private apiService: ApiService, private parentRoute: ActivatedRoute, modalService: SimpleModalService, toastService: ToastrService) {
    super();
    this.modalService = modalService;
    this.toastService = toastService;
}

  ngOnInit(): void {
    this.Loading();
    this.parentRoute.params.subscribe(params => { this.ModelId = params['listid']; });
    //this.ApiUrl = "parking/validationprofiles/" + this.ModelId + "/searches";
    this.apiService.Get<any>("parking/validation/profiles/" + this.ModelId).then(result => {
        this.Model = result;
        this.StopLoading();
    });
  }
}
