import { EventEmitter, Injectable, Output } from '@angular/core';
import { LoginService } from '../auth/login.service';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ApiServiceBase } from './api.service.base';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class MediaService {

    @Output()
    public UserAlertsUpdated = new EventEmitter<any>();
    private MediaId: any = 0;

    constructor(private apiService: ApiServiceBase, private loginService: LoginService) {
    }

    public UploadMedia(file: any, name: string, category?: string, isPublic?: boolean, overrideUrl : string | null = null): Observable<number> {
        var request = overrideUrl != null ? { Extension: "." + name.split('.').pop(), Bytes: file} :  { NameWithExt: name, Content: file, IsPublic: isPublic, Category: category };
        let obs = new Observable<number>(subscriber => {
            this.apiService.Post<any>(overrideUrl ?? "media/upload", request).then(result => {
                this.MediaId = result.Id;
                subscriber.next(result.Id);
            });
        })
        return obs;
    };

    public GetMediaUrl(id: string, thumbnail: boolean = false): string {
        return (id != null && id != "") ? environment.serverBaseUrl + "/media/" + id + (thumbnail ? "/thumbnail" : "") + "?token=" + encodeURIComponent(this.loginService.CurrentToken()) : "";
    }

    public GetMedia(id: string): Observable<any> {
        var x = "media/" + id;
        let obs = new Observable<string>(subscriber => {
            this.apiService.Get<any>(x).then(result => {
                subscriber.next(result);
            });
        })
        return obs;
    }

    public GetBase64Media(id: string): Observable<any> {
        var x = "media/" + id + "/base64";
        let obs = new Observable<string>(subscriber => {
            this.apiService.Get<any>(x).then(result => {
                subscriber.next(result);
            });
        })
        return obs;
    }

    public GetByteArrayMedia(id: string): Observable<any> {
        var x = "media/" + id + "/bytes";
        let obs = new Observable<string>(subscriber => {
            this.apiService.Get<any>(x).then(result => {
                subscriber.next(result);
            });
        })
        return obs;
    }
}