import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { LocalStorageService } from 'src/app/Services/local-storage.service';
import { IFilter, IFilterFilters } from '../ifilter';

@Component({
  selector: '[app-permittypes-filter]',
  templateUrl: './permittypes-filter.component.html',
  styleUrls: ['./permittypes-filter.component.scss'],
  host: {'class':'control select'}
})
export class PermitTypesFilterComponent implements OnInit, IFilterFilters {
  constructor(private apiService: ApiServiceBase, private localStorageService: LocalStorageService, private router: Router) {
  }

  public Filters: any[] = [];
  public SelectedPermitType: any = "";
  public FilterName: string = "permitType";
  QueryParamPrefix: string= '';

  ngOnInit(): void {
    if (this.SelectedPermitType != "") {
      this.Filters.push({
        Property: "PermitTypeStatus",
        Comparator: "==",
        Value: "Active",
        FilterName: this.FilterName
      })
    }
  }

  public addPermitTypeFilter(event: any) {
    var val = event.target.value;
    this.Filters = [];

    if (val != "") {
      this.Filters.push({
        Property: "PermitTypeStatus",
        Comparator: "==",
        Value: val,
        FilterName: this.FilterName
      });

      this.SelectedPermitType = val;
    }
  }
}
