<app-loader [Parent]="this"></app-loader>


<div class="columns m-2">
    <div class="column is-2">
        <nav class="panel">
            <p class="panel-heading">
                Parameters
            </p>
            <div class="panel-block" *ngFor="let param of Report.Parameters">
                <div class="field">
                    <label class="label">{{param.Description}}</label>
                    <div class="control">
                        <input *ngIf="param.Type=='Text'" class="input" type="text" [(ngModel)]="param.Value">
                        <input *ngIf="param.Type=='Date'" class="input" type="date" [(ngModel)]="param.Value">
                        <input *ngIf="param.Type=='Number'" class="input" type="number" [(ngModel)]="param.Value">
                        <div *ngIf="param.Type =='Dropdown'" class="select">
                            <select class="input" [(ngModel)]="param.Value">
                                <option *ngFor="let opt of param.Options" [ngValue]="opt.Value">{{opt.Name}}</option>
                            </select>
                        </div>
                        <div *ngIf="param.Type =='MultiSelect'">
                            <app-dropdown-multiselector [(ngModel)]="param.Value" [Options]="param.Options"></app-dropdown-multiselector>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel-block">
                <div class="field">
                    <label class="label">Output</label>
                    <div class="control">
                        <div class="select is-fullwidth">
                            <select class="input" [(ngModel)]="ReportRequest.ReportTarget" style="width: 100%;" >
                                <option value="BrowserReportTarget">View in Browser</option>
                                <option value="PdfReportTarget">PDF</option>
                                <option value="CsvReportTarget">CSV Download</option>
                            </select>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="panel-block">
                
                <button class="button is-primary is-fullwidth" (click)="RunReport()">
                    Run
                </button>
            </div>
        </nav>
    </div>
    <div class="column is-10" style="position: relative;">
        <div class="report-background">
            <div class="report-container">
                <i class="fa fa-note-sticky is-size-1"></i>
                <app-loader [Parent]="ReportLoading" Mode="cube"></app-loader>
                <iframe *ngIf="ResultMode == 'BrowserReportTarget' && Output != null" class="html-report" [srcdoc]="Output">
                </iframe>
                <iframe *ngIf="ResultMode == 'PdfReportTarget' && Output != null" class="html-report" [src]="Output" >
                </iframe>
            </div>
        </div>
    </div>
</div>