import { LevelEditorComponent } from "../../../leveleditor.component";
import { MapItemTool } from "./MapItemTool";

export class SolidWallTool extends MapItemTool{
    public override ItemWidth: number = 60;
    public override ItemHeight: number = 5;
    public override Type = "SolidWall";
    public override OutlineColor = "000000";
    public override Opacity =  0.8;
    public override FillColor =  "555555";
    public override OutlineThickness = 2;
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }
}