<section class="modal-card-body" style="min-width: 500px;">
  <form [formGroup]="form">
    <article class="panel" *ngIf="violation != null">
      <div class="columns">
        <div class="column is-12">
          <a class="panel-block">
            <span class="label">Space</span>
            <span class="value">{{violation.ParkingSpaceName}}</span>
          </a>
          <a class="panel-block">
            <span class="label">Status</span>
            <span class="value">{{violation.ViolationStatus}}</span>
          </a>
          <a class="panel-block">
            <span class="label">Created</span>
            <span class="value">{{violation.Created | date:'medium'}}</span>
          </a>
        </div>
      </div>
    </article>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Actioned By *</label>
      </div>
      <div class="field-body">
        <div class="field">
          <app-singleuserselector formControlName="actionedByUserId"></app-singleuserselector>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">License Plate *</label>
      </div>
      <div class="field-body">
        <div class="field">
          <input class="input" type="text" formControlName="plateNumber" placeholder="Enter license plate number">
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Ticket Reference *</label>
      </div>
      <div class="field-body">
        <div class="field">
          <input class="input" type="text" formControlName="ticketReference" placeholder="Enter ticket reference">
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Notes</label>
      </div>
      <div class="field-body">
        <div class="field">
          <textarea class="textarea" formControlName="notes" placeholder="Enter any additional notes"></textarea>
        </div>
      </div>
    </div>
  </form>
</section>

<footer class="modal-card-foot">
  <app-form-save-button 
    [Parent]="this"
    [Form]="form"
    (OnSave)="confirm()">
  </app-form-save-button>
</footer> 