<div class="modal is-active">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ModalTitle}}</p>
    </header>
    <section class="modal-card-body" style="min-width: 800px;">
      <div class="has-text-centered">
        <app-mediaviewer [MediaId]="MediaId" [Width]="Width" [Height]="Height"></app-mediaviewer>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-normal button-width" (click)="close()">Close</button>
    </footer>
  </div>
</div>