import { DocumentComponentBase } from "../Components/DocumentComponentBase";
import { DocumentEditorComponent } from "../documenteditor.component";

export class DocumentToolBaseComponent {

    public canvas !: fabric.Canvas;
    public editor !: DocumentEditorComponent;
    public ScaleFactor = 10000;

    constructor(editor: DocumentEditorComponent, canvas: fabric.Canvas){
        this.canvas = canvas;
        this.editor = editor;
    }

    public CreateNew(canvasX : number, canvasY: number): DocumentComponentBase | null {
        return null;
    }
}