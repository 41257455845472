import { AfterViewInit, ChangeDetectorRef, Component, ContentChild, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { MediaService } from 'src/app/Services/media.service';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { fabric } from 'fabric';
import { HtmlRenderComponents } from './HtmlRenderComponents';
import { Helpers } from './Helpers/Helpers';
import { TextComponent } from './Components/TextComponent';
import { DocumentRender } from './DocumentRender';
import { DocumentComponentBase } from './Components/DocumentComponentBase';
import { DocumentTextTool } from './Tools/DocumentTextTool';
import { DocumentImageTool } from './Tools/DocumentImageTool';
import { DocumentRectangleTool } from './Tools/DocumentRectangleTool';
import { DocumentToolBaseComponent } from './Tools/DocumentToolBaseComponent';
import { FabricHelpers } from 'src/app/features/parking/parking/parkinglevels/leveleditor/fabric.js/helpers';
import { RectangleComponent } from './Components/RectangleComponent';
import { ImageComponent } from './Components/ImageComponent';
import { Image, Rect } from 'fabric/fabric-impl';
import { TabsComponent } from 'src/app/shared/forms/tabs/tabs.component';

@Component({
  selector: 'app-documenteditor',
  templateUrl: './documenteditor.component.html',
  styleUrls: ['./documenteditor.component.scss']
})

export class DocumentEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  canvas: any;
  public Form !: UntypedFormGroup;
  @Input()
  public ModelEditor !: ModelEditor;
  @ViewChild("DesignSurface")
  public DesignSurface !: ElementRef;
  @ViewChild("Wrapper")
  public Wrapper !: ElementRef;
  @ViewChild("Properties")
  public Properties !: ElementRef;

  @ViewChild("apptabs")
  public apptabs !: TabsComponent;

  public Tools : any[] = [];
  public TargetTypes : any[] = [];
  public TargetType : any;
  public SelectedItem : DocumentComponentBase | null = null;
  public ToolInUse !: any;
  public zoomLevel: number = 100;
  public TimeOfLastClick: number | null = null;
  public PagePolygon: any;
  public EditorHeight: number = 0;
  public EditorWidth: number = 0;
  public PageSize!: string;
  public Dimensions: any;
  public Panning : boolean = false;
  public EditingText : boolean = false;
  public PanX0 : number = 0;
  public PanY0 : number = 0;
  public offsets = { x: 0, y: 0 }; // Initialize offsets
  public DocumentComponents : DocumentComponentBase[] = [];
  public RenderedDocument !: DocumentRender;
  ItemSelected = new EventEmitter<any>();
  ItemDeselected = new EventEmitter<any>();
  selectedProperty!: string | null;

  @Input()
  public set Document(value: UntypedFormGroup) {  
    this.Form = value;
    this.Form.markAsPristine();
  }

  constructor(private apiService: ApiService, public mediaService: MediaService, public cdr: ChangeDetectorRef, public toastService: ToastrService, public modalService: SimpleModalService) { 
  }
  ngOnDestroy(): void {
    window.removeEventListener('keydown', this.windowKeyDownEventListener);
  }

  ngOnInit(): void {
    this.Body = this.Form.get('Body')?.value;

    this.apiService.Get("/communications/documenttemplates/types").then((result: any) => {
      this.TargetTypes = result;
      if(this.Form.get('TargetType')?.value){
        this.TargetType = this.TargetTypes.find(x => x.DisplayName == this.Form.get('TargetType')?.value);
      }
    });

  }

  private calculateOffsets(): void {
    this.offsets.x = (this.canvas.getWidth() - this.Dimensions.width) / 2;
    this.offsets.y = (this.canvas.getHeight() - this.Dimensions.height) / 2;
  }

  ngAfterViewInit(): void {
    this.PageSize = this.Form.get("PaperSize")?.value;
    this.Dimensions = this.SetPaperSizeDimensions(this.PageSize);

    const containerDiv = this.Wrapper.nativeElement;
    this.EditorWidth = containerDiv.clientWidth;
    this.EditorHeight = this.Dimensions.height + 100;

    this.InitCanvas();

    this.apptabs.SelectedIndexChanged.subscribe(index => {
      if(index == 0){
        this.InitCanvas();
      }
      else if(index == 1){
        this.RenderHtml();
      }
    })
    // this.zoomToFitPolygon();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const containerDiv = this.Wrapper.nativeElement;
    this.EditorWidth = containerDiv.clientWidth;
    this.InitCanvas();
  }

  public Body : string | null = null;

  public BodyChanged(event: any){
    this.Form.get('Body')?.setValue(event);
  }

  InitCanvas(){
    if(this.canvas){
      this.canvas.off();
      this.canvas.dispose(); // This will remove all Fabric.js objects and internal references
      this.canvas = null;
    }
    this.canvas = new fabric.Canvas('documentCanvas', { height: this.EditorHeight, width: this.EditorWidth, preserveObjectStacking: true, fireMiddleClick: true, perPixelTargetFind: false, fireRightClick: true, defaultCursor: 'default', selection: true});
    this.canvas.setBackgroundColor('#F9FBFD', this.canvas.renderAll.bind(this.canvas));
    this.zoomLevel = this.canvas.getZoom();

    this.Tools = this.GetTools();
    this.calculateOffsets();
    this.AddEventListeners();
    this.renderHtmlContent();
  }

  private renderHtmlContent(): void {
    this.DocumentComponents = [];
    // Example HTML content as string (replace with your actual HTML)
    const htmlContent = this.Form.get("Body")?.value;

    // Parse HTML content to extract text and image sections
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlContent;

    new HtmlRenderComponents(this, tempElement, null);
    this.RenderedDocument = new DocumentRender(this, this.DocumentComponents, this.canvas);

    this.canvas.renderAll();
  };

  public GetTools() {
    return [
     { Name: "Basic", isVisible: true, Tools: [
           { Name: "Text", Tool: new DocumentTextTool(this, this.canvas), Icon: "" },
           { Name: "Image", Tool: new DocumentImageTool(this, this.canvas), Icon: "" }
     ]},
     { Name: "Extras", isVisible: true, Tools: [
             { Name: "Rectangle", Tool: new DocumentRectangleTool(this, this.canvas), Icon: "" },
     ]}
    ];
   }

  public UseTool(evt: any){
    if(this.ToolInUse != null){

      //lock all items.
      this.canvas.forEachObject((obj :any) => {
        if (obj instanceof fabric.Object) {
          obj.selectable = false;
        }
      }); 

      if (this.ToolInUse.Tool instanceof DocumentToolBaseComponent) {
        // Get the pointer's screen coordinates
        const canvasX = evt.pointer.x;
        const canvasY = evt.pointer.y;
            // Call the CreateNew method with the adjusted coordinates
        (this.ToolInUse.Tool as DocumentToolBaseComponent).CreateNew(canvasX, canvasY);
    }
      
      if(!(this.ToolInUse.Tool.Locked)){
        this.ToolInUse = null;
        this.canvas.forEachObject((obj :any) => {
          // Check if the object is an instance of fabric.Line
          if (!(obj instanceof fabric.Line)) {
            // Make all other objects selectable
            obj.selectable = true;
          } else {
            // Ensure lines are not selectable
            obj.selectable = false;
          }
        }); 
      }
    } 
   }

  RenderHtml(){
    this.Form.get('Body')?.setValue(this.RenderedDocument.RenderHtml());
    this.Body = this.Form.get('Body')?.value;
    this.Form.markAsDirty();
  }


  ChangeBindingType(event: any){
    if(event.target.value == ""){
      (this.SelectedItem as TextComponent).DataType = null;
      (this.SelectedItem as TextComponent).DataExpression = null;
      (this.SelectedItem as TextComponent).BoundValue = null;
    }

    (this.SelectedItem as TextComponent).DataType = event.target.value;
  }

  GetBindingType(){
    return (this.SelectedItem as TextComponent).DataType ?? "";
  }

  BindingIs(string: any){
    return (this.SelectedItem as TextComponent).DataType == string;
  }

  IsItemDisabled(){
    return (this.SelectedItem as TextComponent).DataType == 'value';
  }

  SetBoundValue(event: any){
    var target = this.TargetType.Properties.find((x: any) => x.PropertyName == event);
    (this.SelectedItem as TextComponent).Text = target.ExampleValue;
    (this.SelectedItem as TextComponent).fabricItem?.set({'text': target.ExampleValue });
    (this.SelectedItem as TextComponent).BoundValue = target.PropertyName;
    (this.SelectedItem as TextComponent).DataExpression = '@Model.' + target.PropertyName + (target.BindingAddon ? target.BindingAddon : '');
    this.canvas.renderAll();
    this.RenderHtml();
  }

  DeselectItems(){
    this.SelectedItem = null;
  }

  GetBoundValue(){
    return (this.SelectedItem as TextComponent).BoundValue;
  }

  SetExpression(event: any){
    (this.SelectedItem as TextComponent).DataExpression = event.target.value;
    this.canvas.renderAll();
    this.RenderHtml();
  }

  GetDataExpression(){
    return (this.SelectedItem as TextComponent).DataExpression;
  }

  UpdateSelectedItemText(event: any){
    (this.SelectedItem as TextComponent).Text = event.target.value;
    (this.SelectedItem as TextComponent).fabricItem?.set({'text': event.target.value});
    this.canvas.renderAll();
    this.RenderHtml();
  }

  UpdateSelectedItemTextWidth(event: any){
    (this.SelectedItem as TextComponent).Styles.width = parseInt(event.target.value);
    (this.SelectedItem as TextComponent).fabricItem?.set({'width': parseInt(event.target.value)});
    this.canvas.renderAll();
    this.RenderHtml();
  }

  AddEventListeners(){

    this.ItemSelected.subscribe((item: any) => {
      this.SelectedItem = item;
      this.cdr.detectChanges();
    });

    this.ItemDeselected.subscribe((item: any) => {
      this.SelectedItem = null;
      this.cdr.detectChanges();
    })



    const wrapper = document.getElementById('wrapper');
      if (wrapper) {
          console.log("Adding event listeners to wrapper");
          wrapper.addEventListener('wheel', this.handleMouseScroll.bind(this));
      } else {
          console.error("Wrapper element not found");
      }

      this.canvas.on('mouse:down', (event : any) => {
          console.log('Mouse down event detected:', event);
          this.UseTool(event);
    });

    
    window.addEventListener('keyup', (event) => {
      switch (event.key) {
        case 'Del': 
            this.deleteSelectedObject();
          break;
        case 'Delete': 
            this.deleteSelectedObject();
        }
    });

    this.canvas.on('mouse:down', (event : any) => {
      if(event.e.button == 1){
        this.Panning = true;
        this.StartPan(event);
        return;
      }
    });
    
    this.canvas.on('mouse:up', (event: any) => {
      if(event.e.button == 1){
        console.log('Panning Ended');
        this.Panning = false;
        this.StopPan(event);
      }
    })

    this.canvas.on('mouse:move', (event: any) => {
      if(this.Panning){
        console.log('Panning Continuing');
        this.ContinuePan(event);
      }
    });

    this.canvas.on('mouse:dblclick', (event: any) => {
      const target = event.target;
      
      if (target && target.type === 'text') {
        // Activate editing mode
        target.enterEditing();
        this.canvas.renderAll();
      }
    });

    this.windowKeyDownEventListener = this.windowKeyDownEventHandler.bind(this);

    window.addEventListener('keydown', this.windowKeyDownEventListener);
  }
  
  private windowKeyDownEventListener!: (event: KeyboardEvent) => void;

  windowKeyDownEventHandler(event: KeyboardEvent) {
    const isInputFocused = document.activeElement instanceof HTMLInputElement
      || document.activeElement instanceof HTMLTextAreaElement;
  
    if (isInputFocused) {
      return; // Do nothing if an input field is focused
    }

    switch (event.key) {
        case 'ArrowLeft':
            event.preventDefault();
            this.SelectedItem?.fabricItem?.set({'left': (this.SelectedItem.fabricItem.left  ?? 0)- 1});
            this.SelectedItem?.fabricItem?.fire('modified');
            this.canvas.renderAll();
          break;
        case 'ArrowRight':  
          event.preventDefault();
          this.SelectedItem?.fabricItem?.set({'left': (this.SelectedItem.fabricItem.left  ?? 0) + 1});
          this.SelectedItem?.fabricItem?.fire('modified');
          this.canvas.renderAll();
          break;
        case 'ArrowUp':
          event.preventDefault();
          this.SelectedItem?.fabricItem?.set({'top': (this.SelectedItem.fabricItem.top  ?? 0) - 1});
          this.SelectedItem?.fabricItem?.fire('modified');
          this.canvas.renderAll();
          break;
        case 'ArrowDown':
          event.preventDefault();
          this.SelectedItem?.fabricItem?.set({'top': (this.SelectedItem.fabricItem.top  ?? 0) + 1});
          this.SelectedItem?.fabricItem?.fire('modified');
          this.canvas.renderAll();
          break;
        default:
            // Other keys pressed
        break;
      }
  }

  deleteSelectedObject(){ 
    const index = this.RenderedDocument.documentComponents.findIndex(component => component.Id === this.SelectedItem?.Id);

    if (index !== -1) {
        this.RenderedDocument.documentComponents.splice(index, 1);
    }

    if(this.SelectedItem instanceof DocumentComponentBase){
      this.SelectedItem.Remove(this.canvas);
    }

    this.RenderHtml();
    this.canvas.renderAll();
  }

  StartPan(event: any){
    this.PanX0 = event.e.screenX,
    this.PanY0 = event.e.screenY;
    console.log(this.PanX0, this.PanY0)
  }

  ContinuePan(event: any) {
    var x = event.e.screenX,
        y = event.e.screenY;
    this.canvas.relativePan({ x: x - this.PanX0, y: y - this.PanY0 });
    this.PanX0 = x;
    this.PanY0 = y;

    console.log(this.PanX0, this.PanY0)
  }

  StopPan(event: any){
    this.canvas.off('mousemove', this.ContinuePan);
    this.canvas.off('mouseup', this.StopPan);
  }

  handleMouseScroll(event: any) {
    event.preventDefault();

    // Define zoom limits
    const MIN_ZOOM = 0.5;
    const MAX_ZOOM = 2.0;

    // Calculate the new zoom level based on the scroll event
    const delta = event.deltaY;
    const zoom = this.canvas.getZoom();
    const zoomMultiplier = 0.999 ** delta;
    let newZoom = zoom * zoomMultiplier;

    // Clamp the new zoom level within the defined limits
    newZoom = Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, newZoom));

    // Get canvas dimensions and mouse position relative to the canvas
    const canvasRect = this.canvas.getElement().getBoundingClientRect();
    const mouseCanvasX = event.clientX - canvasRect.left;
    const mouseCanvasY = event.clientY - canvasRect.top;

    // Calculate the zoom ratio
    const zoomRatio = newZoom / zoom;
    const offsetX = mouseCanvasX - this.canvas.width / 2;
    const offsetY = mouseCanvasY - this.canvas.height / 2;
    const newCenterX = this.canvas.width / 2 + offsetX * zoomRatio;
    const newCenterY = this.canvas.height / 2 + offsetY * zoomRatio;

    // Update the zoom level and apply the zoom to the canvas
    this.zoomLevel = newZoom;
    this.canvas.zoomToPoint({ x: newCenterX, y: newCenterY }, newZoom);
  }



   SetPaperSizeDimensions(paperSize: string): { width: number, height: number } {
    let width: number, height: number;

    switch (paperSize) {
        case 'Letter':
            width = Helpers.convertInchesToPixels('8.5in');
            height = Helpers.convertInchesToPixels('11in');
            break;
        case 'Legal':
            width = Helpers.convertInchesToPixels('8.5in');
            height = Helpers.convertInchesToPixels('14in');
            break;
        case 'A5':
            width = Helpers.convertInchesToPixels('5.83in');
            height = Helpers.convertInchesToPixels('8.27in');
            break;
        case 'A4':
            width = Helpers.convertInchesToPixels('8.27in');
            height = Helpers.convertInchesToPixels('11.69in');
            break;
        case 'A3':
            width = Helpers.convertInchesToPixels('11.69in');
            height = Helpers.convertInchesToPixels('16.54in');
            break;
        case 'A2':
            width = Helpers.convertInchesToPixels('16.54in');
            height = Helpers.convertInchesToPixels('23.39in');
            break;
        default:
            width = 0;
            height = 0;
            console.error('Unknown paper size:', paperSize);
            break;
    }
    return { width, height };
  }

  onZoomChange(event: any): void {
    event.preventDefault();
    this.canvas.setZoom(event.target.value);
  }

  ToggleParentVisibility(parent: any) {
    parent.isVisible = !parent.isVisible;
    console.log(`Parent ${parent.Name} visibility toggled to ${parent.isVisible}`);
    this.cdr.detectChanges();
  }

  HandleDoubleClick(event: any, tool: any) {
    this.ToggleLock(event, tool);
  }

  public ToolIsSelected(tool: any): boolean {
    if(this.ToolInUse == null){
      return false;
    }
    return tool.Name == this.ToolInUse?.Name;
  }

  ToggleLock(event: any, tool: any) {
    event.preventDefault();
    event.stopPropagation();

    if(this.ToolInUse != null && this.ToolInUse.Tool.Locked && this.ToolInUse.Name != tool.Name){
      return;
    }

    this.ToolInUse = tool;
    (this.ToolInUse.Tool).Locked = !(this.ToolInUse.Tool).Locked;

    if(!(this.ToolInUse.Tool).Locked){
      this.ToolInUse = null;
      this.canvas.forEachObject((obj :any) => {
        if (obj instanceof fabric.Text || obj instanceof fabric.Image) {
          obj.selectable = true;
        }
      }); 
    }
    else{
      this.canvas.forEachObject((obj :any) => {
        if (obj instanceof fabric.Text || obj instanceof fabric.Image) {
          obj.selectable = false;
        }
      });
    }
  }

  ToggleSelect(tool: any){
    const now = new Date().getTime();

    if (this.TimeOfLastClick && (now - this.TimeOfLastClick) < 400) {
      this.HandleDoubleClick(event, tool);
    } else {
      // Handle single click
      this.HandleSingleClick(tool);
    }

    this.TimeOfLastClick = now;
  }

  HandleSingleClick(tool: any) {
    if(this.ToolInUse != null && this.ToolInUse.Name == tool.Name && !this.ToolInUse.Tool.Locked){
      this.ToolInUse = null;
      this.canvas.forEachObject((obj :any) => {
        if (obj instanceof fabric.Image || obj instanceof fabric.Text) {
          obj.selectable = true;
        }
      }); 
      return;
    }
    if(this.ToolInUse != null && this.ToolInUse.Tool.Locked && (this.ToolInUse.Name != tool.Name)){
      return;
    }
    this.ToolInUse = tool;
    this.canvas.forEachObject((obj :any) => {
      if (obj instanceof fabric.Image || obj instanceof fabric.Text) {
        obj.selectable = false;
      }
    });
  }

  private calculatePolygonPoints(rect: DOMRect, borderRadius: number): fabric.Point[] {
    const points: fabric.Point[] = [];
    const numSegments = 20; // Number of segments to approximate the curved edge

    // Calculate points for the top border
    for (let i = 0; i < numSegments; i++) {
      const angle = (Math.PI / 2) * (i / numSegments);
      const x = rect.left + borderRadius + borderRadius * Math.cos(angle);
      const y = rect.top + borderRadius * Math.sin(angle);
      points.push(new fabric.Point(x, y));
    }

    // Calculate points for the right border
    for (let i = 0; i < numSegments; i++) {
      const angle = (Math.PI / 2) * (i / numSegments);
      const x = rect.right - borderRadius + borderRadius * Math.sin(angle);
      const y = rect.top + borderRadius + borderRadius * Math.cos(angle);
      points.push(new fabric.Point(x, y));
    }

    // Calculate points for the bottom border
    for (let i = 0; i < numSegments; i++) {
      const angle = (Math.PI / 2) * (i / numSegments);
      const x = rect.right - borderRadius - borderRadius * Math.cos(angle);
      const y = rect.bottom - borderRadius + borderRadius * Math.sin(angle);
      points.push(new fabric.Point(x, y));
    }

    // Calculate points for the left border
    for (let i = 0; i < numSegments; i++) {
      const angle = (Math.PI / 2) * (i / numSegments);
      const x = rect.left + borderRadius - borderRadius * Math.sin(angle);
      const y = rect.bottom - borderRadius - borderRadius * Math.cos(angle);
      points.push(new fabric.Point(x, y));
    }

    return points;
  }


  public SelectedItemIsText(){
    return (this.SelectedItem instanceof TextComponent);
  }

  public GetPageSize(){
    return this.PageSize;
  }

  public AddTextDataField(event: any) {
    var text = (((this.SelectedItem as TextComponent).fabricItem as fabric.Text).text);
    if(text){
      text = text += ` {{ ${event.target.value} }} `;
    }
    else{
      text = ` {{ ${event.target.value} }} `
    };

    (this.SelectedItem as TextComponent).Text = text;
    (this.SelectedItem as TextComponent).fabricItem?.set({'text': text});
    this.canvas.renderAll();
    this.RenderHtml();
  }

  public UpdatePageSize(event: any){
    this.PageSize = event.target.value;
    this.SetPaperSizeDimensions(this.PageSize)
    this.Form.get("PaperSize")?.setValue(this.PageSize);
    this.Dimensions = this.SetPaperSizeDimensions(this.PageSize);

    this.RenderedDocument.RenderDocument();

    return this.PageSize;
  }


  public GetTargetType(){
    // console.log(this.Form.get('TargetType')?.value);
    return this.Form.get('TargetType')?.value;
  }

  public UpdateTargetType(event: any){
    this.TargetType = this.TargetTypes.find(x => x.DisplayName == event.target.value);
    return this.Form.get('TargetType')?.setValue(event.target.value);
  }

  public SelectedItemIsImage(){
    return (this.SelectedItem instanceof ImageComponent);
  }

  public SelectedItemIsRectangle(){
    return (this.SelectedItem instanceof RectangleComponent);
  }

  public SelectedItemIsLink(){
    return (this.SelectedItem as TextComponent).Styles.IsLink;
  }

  public SelectedItemText(){
    return (this.SelectedItem as TextComponent).Text;
  }

  public SelectedItemTextWidth(){
    return (this.SelectedItem as TextComponent).fabricItem?.width ?? 0;
  }

  public SelectedItemLink(){
    return (this.SelectedItem as TextComponent).Href;
  }

  public SelectedItemBorderRadius(){
    return (this.SelectedItem as RectangleComponent).Styles.borderRadius ?? 0;
  }

  public SelectedItemFontSize(){
    return ((this.SelectedItem as TextComponent).fabricItem as fabric.Text).fontSize;
  }

  public SelectedItemFontColor(){
    var textColor = (this.SelectedItem as TextComponent).fabricItem?.fill?.toString();
    return this.toHexColor(textColor ?? "white");
  }

  public SelectedItemFontFamily(){
    return ((this.SelectedItem as TextComponent).fabricItem as fabric.Text).fontFamily ?? 'arial'
  }

  public SelectedItemBorderColor(){
    var borderColor = (this.SelectedItem as RectangleComponent).fabricItem?.stroke?.toString();
    return this.toHexColor(borderColor ?? "white");
  }

  toHexColor(color: string): string {
    // Check if the color is already in hex format
    if (color.startsWith('#')) {
        return color.replace('#', '');
    }

    // Create a dummy canvas to use its context for color manipulation
    const canvas = document.createElement('canvas');
    canvas.width = 1;
    canvas.height = 1;
    const context = canvas.getContext('2d');

    if (!context) return '000000'; // Fallback to black if context is not available

    // Set the fill style to the provided color and get the computed color
    context.fillStyle = color;
    const computedColor = context.fillStyle;

    // Apply the computed color to the canvas
    context.fillRect(0, 0, 1, 1);

    // Read the pixel's color in RGBA format
    const [r, g, b] = context.getImageData(0, 0, 1, 1).data;

    // Convert to hex format
    return `${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}

  public SelectedItemBorderWidth(){
    return (this.SelectedItem as RectangleComponent).fabricItem?.strokeWidth ?? 0;
  }

  public SelectedItemFillColor(){
    var fillcolor = (this.SelectedItem as TextComponent).Styles?.backgroundColor?.toString();
    return this.toHexColor(fillcolor ?? "white");
  }

  public SelectedItemTextAlign(align: string){
    return (((this.SelectedItem as TextComponent).fabricItem as fabric.Text).textAlign?.toLowerCase() === align.toLowerCase());
  }

  public SelectedItemHasBoxShadow(){
    return (this.SelectedItem as RectangleComponent).Styles.boxShadow != null;
  }

  public GetDescriptionOfProperty(){
    var x = this.TargetType.Properties.find((x: any) => x.PropertyName == (this.SelectedItem as TextComponent).BoundValue);
    if(x){
      return x.Description;
    }
  }

  public SelectedItemMediaId(){
    return (this.SelectedItem as ImageComponent).MediaId ?? null;
  }

  public IsSelectedTextBold(){
    return (this.SelectedItem as TextComponent).Styles.fontWeight == 'bold';
  }

  private findSelectedItemIndex(): number {
    return this.RenderedDocument.documentComponents.findIndex(item => item.Id === this.SelectedItem?.Id);
}

  public BringToFront(){
    (this.SelectedItem as DocumentComponentBase).BringToFront();
    const index = this.findSelectedItemIndex();
    if (index !== -1) {
        const item = this.RenderedDocument.documentComponents.splice(index, 1)[0]; // Remove item from current position
        this.RenderedDocument.documentComponents.push(item); // Add item to the end
    }
    this.RenderHtml();
  }

  public SendToBack(){
    (this.SelectedItem as DocumentComponentBase).SendToBack();
    const index = this.findSelectedItemIndex();
    if (index !== -1) {
        const item = this.RenderedDocument.documentComponents.splice(index, 1)[0]; // Remove item from current position
        this.RenderedDocument.documentComponents.unshift(item); // Add item to the beginning
    }
    this.RenderHtml();
  }

  public BringForward(){
    (this.SelectedItem as DocumentComponentBase).BringForward();
    const index = this.findSelectedItemIndex();
    if (index !== -1 && index < this.RenderedDocument.documentComponents.length - 1) {
        [this.RenderedDocument.documentComponents[index], this.RenderedDocument.documentComponents[index + 1]] = [this.RenderedDocument.documentComponents[index + 1], this.RenderedDocument.documentComponents[index]];
    }
    this.RenderHtml();
  }

  public SendBackward(){
    (this.SelectedItem as DocumentComponentBase).SendBackward();
    const index = this.findSelectedItemIndex();
    if (index > 0) {
        [this.RenderedDocument.documentComponents[index], this.RenderedDocument.documentComponents[index - 1]] = [this.RenderedDocument.documentComponents[index - 1], this.RenderedDocument.documentComponents[index]];
    }
    this.RenderHtml();
  }

  public MediaIdChanged(event : any){
    this.mediaService.GetBase64Media(event).subscribe(result => {
      (this.SelectedItem as ImageComponent).Src = result.Base64Prefix + result.Base64String;
      (this.SelectedItem as ImageComponent).MediaId = event;
      (this.SelectedItem as ImageComponent).Draw(this.canvas);
      this.cdr.detectChanges();
      return;
    });
    this.RenderHtml();
  }

  public MediaIdNotNull(){
    return (this.SelectedItem as ImageComponent).MediaId != null;
  }

  public GetHref(){
    return (this.SelectedItem as ImageComponent).Href;
  }

  public IsSelectedTextItalic(){
    return (this.SelectedItem as TextComponent).Styles.fontStyle == "italic";
  }

  public IsSelectedTextUnderlined(){
    return (this.SelectedItem as TextComponent).Styles.textDecoration == 'underline';
  }

  public UpdateSelectedTextBold(){
    if((this.SelectedItem as TextComponent).Styles.fontWeight == 'bold'){
      (this.SelectedItem as TextComponent).Styles.fontWeight = 'normal';
      (this.SelectedItem as TextComponent).fabricItem?.set({'fontWeight': 'normal'});
    }
    else{
      (this.SelectedItem as TextComponent).Styles.fontWeight = 'bold';
      (this.SelectedItem as TextComponent).fabricItem?.set({'fontWeight': 'bold'});
    }
    this.canvas.renderAll();
    this.RenderHtml();
  }

  UpdateSelectedItemBorderColor(event: any){
    var borderStyle = (this.SelectedItem as RectangleComponent).Styles.borderStyle.split(' ');

    if (borderStyle.length > 3) {
      // Join the extra items into the third item
      borderStyle[2] = borderStyle.slice(2).join(' ');
      // Trim down to only three items
      borderStyle.length = 3;
  }

    borderStyle[2] = '#' + event;
    (this.SelectedItem as RectangleComponent).Styles.borderStyle = borderStyle.join(' ');
    (this.SelectedItem as RectangleComponent).fabricItem?.set({'stroke': borderStyle[2]});
    this.canvas.renderAll();
    this.RenderHtml();
  }

  UpdateSelectedItemBorderWidth(event: any){
    var borderStyle = (this.SelectedItem as RectangleComponent).Styles.borderStyle.split(' ');

    if (borderStyle.length > 3) {
      // Join the extra items into the third item
      borderStyle[2] = borderStyle.slice(2).join(' ');
      // Trim down to only three items
      borderStyle.length = 3;
    }

    borderStyle[0] = event.target.value + 'px';
    (this.SelectedItem as RectangleComponent).Styles.borderStyle = borderStyle.join(' ');
    (this.SelectedItem as RectangleComponent).fabricItem?.set({'strokeWidth': Helpers.convertStyleValue(borderStyle[0]) ?? 1});
    this.canvas.renderAll();    
    this.RenderHtml();
  }
  
  public UpdateSelectedItemFont(event: any){
    (this.SelectedItem as TextComponent).Styles.fontSize = event.target.value + 'px';
    (this.SelectedItem as TextComponent).fabricItem?.set({'fontSize': event.target.value});
    this.canvas.renderAll();
    this.RenderHtml();
  }

  public UpdateHref(event: any){
    (this.SelectedItem as ImageComponent).Href = event.target.value;
    this.canvas.renderAll();
    this.RenderHtml();
  }

  public UpdateSelectedTextAlign(align: string){
    (this.SelectedItem as TextComponent).Styles.textAlign = align.toLowerCase();
    (this.SelectedItem as TextComponent).fabricItem?.set({'textAlign': align.toLowerCase()});
    this.canvas.renderAll();
    this.RenderHtml();
  }

  public UpdateSelectedItemBoxShadow(event: any){
    if(event.target.checked){
      (this.SelectedItem as RectangleComponent).Styles.boxShadow = 'rgba(0, 0, 0, 0.2) 0px 1px 5px 0px, rgba(0, 0, 0, 0.19) 0px 1px 1px 0px';
      (this.SelectedItem as RectangleComponent).AddBoxShadow();
    }
    else{
      (this.SelectedItem as RectangleComponent).Styles.boxShadow = null;
      (this.SelectedItem as RectangleComponent).AddBoxShadow();
    }
    this.canvas.renderAll();
    this.RenderHtml();
  }

  public UpdateBorderRadius(event: any){
    (this.SelectedItem as RectangleComponent).Styles.borderRadius = event.target.value;
    ((this.SelectedItem as RectangleComponent).fabricItem as fabric.Rect).set({'rx': event.target.value});
    ((this.SelectedItem as RectangleComponent).fabricItem as fabric.Rect).set({'ry': event.target.value});
    this.canvas.renderAll();
    this.RenderHtml();
  }

  public UpdateSelectedItemFontFamily(event : any){
    (this.SelectedItem as TextComponent).Styles.fontFamily = event.target.value;
    (this.SelectedItem as TextComponent).fabricItem?.set({'fontFamily': event.target.value});
    this.canvas.renderAll();
    this.RenderHtml();
  }

  public UpdateSelectedItemFontColor(event: any){
    (this.SelectedItem as TextComponent).Styles.color = "#" + event;
    (this.SelectedItem as TextComponent).fabricItem?.set({'fill': "#" + event});
    this.canvas.renderAll();
    this.RenderHtml();
  }

  public UpdateSelectedItemFillColor(event: any){
    (this.SelectedItem as RectangleComponent).Styles.backgroundColor = "#" + event;
    (this.SelectedItem as RectangleComponent).fabricItem?.set({'fill': "#" + event});
    this.canvas.renderAll();
    this.RenderHtml();
  }

  public UpdateSelectedTextItalic(){
    if((this.SelectedItem as TextComponent).Styles.fontStyle == 'normal'){
      (this.SelectedItem as TextComponent).Styles.fontStyle = 'italic';
      (this.SelectedItem as TextComponent).fabricItem?.set({'fontStyle': 'italic'});
    }
    else{
      (this.SelectedItem as TextComponent).Styles.fontStyle = 'normal';
      (this.SelectedItem as TextComponent).fabricItem?.set({'fontStyle': 'normal'});
    }
    this.canvas.renderAll();
    this.RenderHtml();
  }

  public UpdateSelectedTextUnderlined(){
    if((this.SelectedItem as TextComponent).Styles.textDecoration == 'none'){
      (this.SelectedItem as TextComponent).Styles.textDecoration = 'underline';
      (this.SelectedItem as TextComponent).fabricItem?.set({'underline': true});
    }
    else{
      (this.SelectedItem as TextComponent).Styles.textDecoration = 'none';
      (this.SelectedItem as TextComponent).fabricItem?.set({'underline': false});
    }
    this.canvas.renderAll();
    this.RenderHtml();
  }

  public UpdateSelectedTextHref(){
    if((this.SelectedItem as TextComponent).Styles.IsLink == false){
      (this.SelectedItem as TextComponent).Styles.IsLink = true;
      (this.SelectedItem as TextComponent).Styles.textDecoration = 'underline';
      (this.SelectedItem as TextComponent).fabricItem?.set({'underline': true});
    }
    else{
      (this.SelectedItem as TextComponent).Styles.IsLink = false;
      (this.SelectedItem as TextComponent).Styles.textDecoration = 'none';
      (this.SelectedItem as TextComponent).fabricItem?.set({'underline': false});
    }
    this.canvas.renderAll();
    this.RenderHtml();
  }

  public UpdateSelectedTextHrefLink(event: any){
    (this.SelectedItem as TextComponent).Href = event.target.value;
    this.canvas.renderAll();
    this.RenderHtml();
  }
  
}

