<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Structures</p>
        </header>
        <section class="modal-card-body" style="min-width: 800px;">
          
        <app-datalistview [Columns]="Columns" [AllowSelectBoxes]="true" [DisableIfSelected]="true" [ShowPaging]="false" [ShowMap]="false" ApiUrl="infrastructure/customgroups/structures/searches" 
        [SelectedItems]="SelectedItems" [AllowEdit]="false" EditUrl="/parking/accesscontrol/customgroups/:id" (SelectBoxChanged)="ItemAddOrRemove($event)">
          
        <app-filter-set>
            <div app-search-filter></div>
            <div app-search-button></div>
        </app-filter-set>
        </app-datalistview>

        </section>
        <footer class="modal-card-foot">
            <button class="button is-success" (click)="confirm()">OK</button>
            <button class="button is-success" (click)="close()">Cancel</button>
        </footer>
    </div>
</div>