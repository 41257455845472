<app-tabs #appTabs>
    <app-tab Title="Designer">
        <div class="mapeditor" style="height:100%; width:100%; overflow:hidden">
            <div class="columns" style="height: 100%;">
              <div *ngIf="!isLeftPanelCollapsed" class="column is-2" style="min-width: 18em;" #LeftPanel>
                <div class="toolbar" style="width: 100%; height: 100%;">
                    <div class="card-container">
                      <div *ngFor="let Parent of Tools">
                        <div class="parent-header" (click)="ToggleParentVisibility(Parent)">
                          <span [ngClass]="Parent.isVisible ? 'chevron-down' : 'chevron-right'"></span>
                          {{ Parent.Name }}
                        </div>
                        <div *ngIf="Parent.isVisible" style="margin-bottom:10px;">
                            <div class="card tool-card" *ngFor="let tool of Parent.Tools"
                                (click)="HandleSingleClick(tool)" (dblclick)="HandleDoubleClick($event, tool)"
                                [ngClass]="ToolIsSelected(tool) ? 'selected' : ''">
                                <img [src]="'/assets/leveleditor/tools/' + tool.Name.toLowerCase()  + '.png'" alt="{{ tool.Name }}" class="tool-image">
                                <div class="lock-icon" *ngIf="tool.Tool.Locked" (click)="ToggleLock($event, tool)"><i [ngClass]="tool.Tool.Locked ? 'fa fa-lock' : 'fa fa-unlock'"></i></div>
                            <div class="tool-name">{{ tool.Name }}</div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div [ngClass]="'column is-' + MiddlePaneSize + ' gridview'" #MiddlePanel>
                    <div class="quickactions" style="height:4em; width:100%;">
                    <input class="search input" type="text" placeholder="Search Items" (input)="performSearch($event)" [disabled]="EditingPolygon">
                    <button class="button" title="Delete an Item" [disabled]="EditingPolygon" (click)="deleteSelectedObjects()">
                        <i class="fa fa-trash"></i>
                    </button>
                    <button class="button" title="Undo action" [disabled]="EditingPolygon || this.LastXActions.length == 0" (click)="Undo()">
                      <i class="fa-solid fa-rotate-left"></i>
                    </button>
                    <div class="icon-separator"></div>
                    <button class="button" title="Bottom Align" [disabled]="EditingPolygon" (click)="alignByBottomPoints()">
                        <i class="fa fa-objects-align-bottom"></i>
                    </button>
                    <button class="button" title="Top Align" [disabled]="EditingPolygon" (click)="alignByTopPoints()">
                        <i class="fa fa-objects-align-top"></i>
                    </button>
                    <button class="button" title="Collapse Horizontally" [disabled]="EditingPolygon" (click)="collapseHorizontally()">
                        <i class="fa fa-distribute-spacing-horizontal" ></i>
                    </button>
                    <button class="button" title="Collapse Vertically" [disabled]="EditingPolygon" (click)="collapseVertically()">
                        <i class="fa-solid fa-distribute-spacing-vertical"></i>
                    </button>
                    <div class="icon-separator"></div>
                    <button class="button" title="Duplicate Objects" [disabled]="EditingPolygon" (click)="duplicateObjects()">
                      <i class="fa-solid fa-copy"></i>
                    </button>
                    <button class="button" title="Edit Polygon Points" (click)="ToggleEditPolygon()" [disabled]="(SelectedItems.length > 1 || SelectedItems.length === 0 || !itemIsPolygon())" >
                        <i class="fa-solid fa-draw-polygon"></i>
                    </button>
                    <ng-container *ngIf="SelectedItems.length == 1 && HasChildrenArrays()">
                      <button class="button" title="Select Children" (click)="SelectChildren()" [disabled]="(SelectedItems.length > 1)" >
                        <i class="fa-regular fa-child"></i>
                      </button>
                    </ng-container>
                    <button class="button" title="Edit Form Item" [disabled]="EditingPolygon" (click)="EditModal()" [disabled]="(SelectedItems.length > 1)">
                      <i class="fa-solid fa-edit"></i>
                  </button>
                  <div class="icon-separator"></div>
                    <input title="Change Zoom Level" type="range" min="1" max="3" step="0.075" [(ngModel)]="zoomLevel" (input)="onZoomChange($event)"/>
                    </div>
                    <div class="canvas-wrapper" id="wrapper" style="height:100%; overflow: hidden;">
                        <canvas id="mapCanvas" style="z-index: 1;" #DesignSurface></canvas>
                    </div>
                </div>
                <div *ngIf="!isRightPanelCollapsed" class="column is-3 properties-pane" style="padding:1em;" #RightPanel>
                    <div *ngIf="SelectedItems.length > 0" class="is-small">
                      <table class="table is-striped properties-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngIf="AllowedFormControls$ | async as allowedFormControls">
                            <ng-container *ngFor="let controlName of allowedFormControls">
                              <ng-container [ngSwitch]="controlName">
                                <tr *ngSwitchCase="'EditorLocked'">
                                  <td>Locked</td>
                                  <td>
                                    <input type="checkbox" [checked]="areValuesEqual('EditorLocked')" (change)="UpdateItemLocked($event)" />
                                  </td>
                                </tr>
                              <tr *ngSwitchCase="'Name'">
                                <td>Name</td>
                                <td>
                                  <input [value]="areValuesEqual('Name')" class="input three-quarters-width" (input)="updateControlValues('Name', $event)" />
                                </td>
                              </tr>
                              <tr *ngSwitchCase="'LocalControllerId'">
                                <td>Local Controller</td>
                                <td>
                                  <select class="input" [formControl]="SelectedItems[0].formItem.get('LocalControllerId')">
                                    <option [value]="null"></option>
                                    <option *ngFor="let c of ModelEditor.FormArray(ModelEditor.Form, 'Controllers').controls" [ngValue]="c.get('Id')?.value">{{c.get('Name')?.value}}</option>
                                  </select>                                 
                                </td>
                              </tr>
                              <tr *ngSwitchCase="'OutlineColor'">
                                <td>Outline Color</td>
                                <td>
                                  <app-colorpicker [ColorValue]="SelectedItems[0].formItem.get('OutlineColor')?.value" (ColorChanged)="updateControlValues('OutlineColor', $event)"></app-colorpicker>
                                </td>
                              </tr>
                              <tr *ngSwitchCase="'FillColor'">
                                <td>Fill Color</td>
                                <td>
                                  <app-colorpicker [ColorValue]="SelectedItems[0].formItem.get('FillColor')?.value" (ColorChanged)="updateControlValues('FillColor', $event)"></app-colorpicker>
                                </td>
                              </tr>
                              <tr *ngSwitchCase="'ParkingSpaceTypeId'">
                                <td>Type</td>
                                <td>
                                  <select class="input" [value]="SelectedItems[0].formItem.get('ParkingSpaceTypeId')?.value" (change)="updateControlValues('ParkingSpaceTypeId', $event)">
                                    <option [value]="null"></option>
                                    <option *ngFor="let a of ParkingSpaceTypes" [value]="a.Id">
                                      <img [src]="a.HtmlImage" />{{a.Name}}
                                    </option>
                                  </select>
                                </td>
                              </tr>
                              <ng-container *ngIf="SelectedItems.length === 1 && SelectedItems[0].Type == 'Business'">
                                <tr *ngSwitchCase="'BusinessDirectoryId'">
                                  <td>Business Name</td>
                                  <td>
                                    <app-businessselector [formControl]="SelectedItems[0].formItem.get('BusinessDirectoryId')" [Business]="GetBusiness(SelectedItems[0].formItem.get('BusinessDirectoryId')?.value)" (BusinessIdChange)="BusinessIdChange($event)" class="newclass">
                                    </app-businessselector>
                                  </td>
                                </tr>
                              </ng-container>
                              <ng-container *ngIf="SelectedItems.length === 1 && (SelectedItems[0].Type == 'Business' || SelectedItems[0].Type == 'Ramp')">
                                <tr *ngSwitchCase="'EntrySegment'">
                                  <td>Entry</td>
                                  <td>
                                    <input [value]="areValuesEqual('EntrySegment')" class="input three-quarters-width" type="number"  min="0" (input)="updateControlValues('EntrySegment', $event)" />
                                  </td>
                                </tr>
                              </ng-container>
                              <ng-container *ngIf="SelectedItems.length === 1 && SelectedItems[0].Type == 'Ramp'">
                                <tr *ngSwitchCase="'IsRising'">
                                  <td>IsRising</td>
                                  <td>
                                    <input [checked]="SelectedItems[0].formItem.get('IsRising')?.value" type="checkbox" (input)="updateControlValues('IsRising', $event)" />
                                  </td>
                                </tr>
                                <tr *ngSwitchCase="'ExitSegment'">
                                  <td>Exit</td>
                                  <td>
                                    <input [value]="areValuesEqual('ExitSegment')" class="input three-quarters-width" type="number" min="0" (input)="updateControlValues('ExitSegment', $event)" />
                                  </td>
                                </tr>
                              </ng-container>
                              <ng-container *ngIf="SelectedItems.length === 1 && SelectedItems[0].ClassName == 'Space'">
                                <tr *ngSwitchCase="'ChipId'">
                                  <td>Sensor</td>
                                  <td>
                                    <app-sensor-selector [formControl]="SelectedItems[0].formItem.get('ChipId')" [Sensor]="BuildInitialChipId()"></app-sensor-selector>
                                  </td>
                                </tr>
                              </ng-container>
                              <ng-container *ngIf="SelectedItems.length === 1 && SelectedItems[0].ClassName == 'GatewayConfiguration'">
                                <tr *ngSwitchCase="'ChipId'">
                                  <td>Gateway</td>
                                  <td>
                                    <app-gateway-selector [formControl]="SelectedItems[0].formItem.get('ChipId')" [Gateway]="BuildInitialChipId()"></app-gateway-selector>
                                  </td>
                                </tr>
                              </ng-container>
                              <ng-container *ngIf="SelectedItems.length === 1 && SelectedItems[0].ClassName == 'SignConfiguration'">
                                <tr *ngSwitchCase="'ChipId'">
                                  <td>Sign</td>
                                  <td>
                                    <app-sign-selector [formControl]="SelectedItems[0].formItem.get('ChipId')" [Sign]="BuildInitialChipId()"></app-sign-selector>
                                  </td>
                                </tr>
                              </ng-container>
                              <ng-container *ngIf="SelectedItems.length === 1 && SelectedItems[0].ClassName == 'Level'">
                                <tr *ngSwitchCase="'AvailabilityCountMode'">
                                  <td>Count Mode</td>
                                  <td>
                                    <div class="select">
                                      <select class="input" [value]="SelectedItems[0].formItem.get('AvailabilityCountMode')?.value" (change)="updateControlValues('AvailabilityCountMode', $event)">
                                        <option value="Default">Default</option>
                                        <option value="VehiclesPresent">Count in/out</option>
                                      </select>
                                    </div>
                                  </td>
                                </tr>
                                <tr *ngSwitchCase="'ParkingThresholdSetId'">
                                  <td>Threshold Set</td>
                                  <td>
                                    <div class="select" *ngIf="ThresholdSets != null">
                                      <select [value]="SelectedItems[0].formItem.get('ParkingThresholdSetId')?.value" (change)="updateControlValues('ParkingThresholdSetId', $event)" class="input">
                                          <option [ngValue]="null">Inherit From Parking Lot</option>
                                          <option *ngFor="let a of ThresholdSets" [ngValue]="a.Id">{{a.Name}}</option>
                                      </select>
                                  </div>
                                  </td>
                                </tr>
                              </ng-container>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="areAllItemsPolygonMapComponents()">
                            <tr>
                              <td>Show Measurements</td>
                              <td>
                                <input [checked]="showMeasurementLinesEqual()" type="checkbox" (input)="ShowMeasurementLines($event)" />
                              </td>
                            </tr>
                            <tr>
                              <td>Width (m)</td>
                              <td>
                                <input [value]="areWidthValuesEqual()" class="input three-quarters-width" type="number" step="0.1" (input)="updateWidthDebounced($event)" />
                              </td>
                            </tr>
                            <tr>
                              <td>Height (m)</td>
                              <td>
                                <input [value]="areHeightValuesEqual()" class="input three-quarters-width" type="number" step="0.1" (input)="updateHeightDebounced($event)" />
                              </td>
                            </tr>
                      </ng-container>
                      <ng-container *ngIf="SelectedItems.length == 1">
                        <tr>
                          <td>Top (m)</td>
                          <td>
                            <input [value]="getTop()" class="input three-quarters-width" type="number" step="0.1" (input)="updateTopDebounced($event)" />
                          </td>
                        </tr>    
                        <tr>
                          <td>Left (m)</td>
                          <td>
                            <input [value]="getLeft()" class="input three-quarters-width" type="number" step="0.1" (input)="updateLeftDebounced($event)" />
                          </td>
                        </tr>
                      </ng-container>
                      <tr>
                        <td>Display Angle (deg)</td>
                        <input [value]="getAngle()" class="input three-quarters-width" type="number" step="1" (input)="updateAngleDebounced($event)" />
                      </tr>
                      <ng-container *ngIf="ItemsAreBarriers()">
                        <tr>
                        <button type="button" class="button is-small" (click)="OpenBarriers()">Open</button>
                        <button type="button" class="button is-small" (click)="CloseBarriers()">Close</button>
                        <button type="button" class="button is-small" (click)="KeepBarriersOpen()">Keep Open</button>
                        </tr>
                      </ng-container>
                      </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>
        <p *ngIf="EditingPolygon" class="EditingPolygon" style="z-index: 1;" (click)="ToggleEditPolygon()">You are editing a polygons points. Click on the button again, or click here to stop.</p>
        <button (click)="toggleLeftPanel()" class="toggle-button-left"
         [ngClass]="{'open': !isLeftPanelCollapsed, 'closed': isLeftPanelCollapsed}">
          <i *ngIf="isLeftPanelCollapsed" class="fa-solid fa-screwdriver-wrench" style="margin-right:5px;"></i>
          <i [ngClass]="isLeftPanelCollapsed ? 'fa fa-chevron-right' : 'fa fa-chevron-left'"></i>
        </button>
        <button *ngIf="SelectedItems != null && SelectedItems.length > 0" (click)="toggleRightPanel()" class="toggle-button-right" [ngClass]="{'open': !isRightPanelCollapsed, 'closed': isRightPanelCollapsed}">
          <i [ngClass]="isRightPanelCollapsed ? 'fa fa-chevron-left' : 'fa fa-chevron-right'" style="margin-right:5px;"></i>
          <i *ngIf="isRightPanelCollapsed" class="fa-solid fa-sliders"></i>
        </button>
    </app-tab>
    <app-tab Title="Location" >
      <app-field Label="Rotation">
          <div class="control">
              <ngx-slider [(value)]="GoogleMapRotation" [options]="{ floor: -180, ceil: 180 }"
                  (userChange)="UpdateGoogleMapPath(null,null)"></ngx-slider>
          </div>
      </app-field>
      <button (click)="CenterLevelOnMap()">Center</button>
      {{GoogleMapSpaces.length}} space polys
      <google-map width="100%" height="600" [center]="GoogleMapCenter" [zoom]="18">
          <map-polygon *ngFor="let s of GoogleMapSpaces" [paths]="s"
              [options]="{ draggable: false, strokeColor: '#ffffff', strokeWeight: 1 }"></map-polygon>
          <map-polygon [paths]="GoogleMapLevelPath" [options]="{ draggable: true, editable: false }"
              (polygonDragend)="GoogleMapLevelDragged($event)"></map-polygon>
      </google-map>

  </app-tab>
</app-tabs>
