import { Component } from '@angular/core';

@Component({
  selector: 'app-leftnavlayout',
  templateUrl: './leftnavlayout.component.html',
  styleUrls: ['./leftnavlayout.component.scss']
})
export class LeftnavlayoutComponent {

}
