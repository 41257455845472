<app-loader [Parent]="this"></app-loader>
<div *ngIf="Model != null">
  <app-pageheader [Parent]="this">
    <form-edit-button>Edit</form-edit-button>
  </app-pageheader>

  <div class="columns">
    <div class="column is-6">
      <article class="panel">
        <p class="panel-heading">
          Validation Profile Details
        </p>
        <div class="panel-block" style="display: block;">
          <app-field Label="Id">
            <div class="control">
              <p class="input">{{Model.Id}}</p>
            </div>
          </app-field>
          <app-field Label="Name">
            <div class="control">
              <p class="input">{{Model.Name}}</p>
            </div>
          </app-field>
          <app-field Label="Code Generation">
            <div class="control">
              <p class="input">{{Model.CodeGenerationMode}}</p>
            </div>
          </app-field>
          <app-field Label="Validation Action">
            <div class="control">
              <p class="input">{{Model.ValidationAction}}</p>
            </div>
          </app-field>
          <app-field [Visible]="Model.ParkingRateSetId != null" Label="Rate Set">
            <div class="control">
              <p class="input">{{Model.ParkingRateSetName}}</p>
            </div>
          </app-field>
          <app-field [Visible]="Model.DiscountTypeId != null" Label="Discount Type">
            <div class="control">
              <p class="input">{{Model.DiscountTypeName}}</p>
            </div>
          </app-field>
          <app-field Label="Code Template">
            <div class="control">
              <p class="input">{{Model.CodeTemplate}}</p>
            </div>
          </app-field>
          <app-field Label="Overlap Hours">
            <div class="control">
              <p class="input">{{Model.CodeGenerationOverlapHours}}</p>
            </div>
          </app-field>
          <app-field Label="Controlled Area">
            <div class="control">
              <p class="input">{{Model.ControlledAreaName}}</p>
            </div>
          </app-field>
        </div>
      </article>
    </div>
    <div class="column is-6 image-container">
      <img src="../../../../../assets/images/validation.svg" alt="Validation" />
    </div>
  </div>
  <div class="columns">
    <div class="column is-full">
      <article class="panel">
        <p class="panel-heading">
          Validation Profile Users
        </p>
        <div class="panel-block" style="display: block;">
          <app-datalistview *ngIf="Model != null && Model.Id != null" [Columns]="Columns" [PageSize]="10"
              [ShowMap]="false" [ApiUrl]="'parking/validation/profiles/' + Model?.Id + '/users/searches'" [AllowEdit]="true"
              [EditUrl]="'parking/validation/profiles/' + Model?.Id + '/users/:id'">
              <app-filter-set>
                  <div app-search-filter></div>
                  <div app-search-button></div>
              </app-filter-set>
          </app-datalistview>
      </div>
      </article>
    </div>
  </div>
  <div class="columns">
    <div class="column is-12">
      <div class="card">
        <app-validationprofileusageserieschart Title="Validation Profile Usage" ChartType="line" Period="thisweek" [ProfileId]="this.Model.Id"
        DataType="inflows" [Height]="50"></app-validationprofileusageserieschart>
      </div>
    </div>
  </div>
</div>