<div class="modal is-active">
    <app-loader [Parent]="this"></app-loader>
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Upload Statement <span *ngIf="fileName != null">({{fileName}})</span></p>
      </header>
      <section class="modal-card-body"style="min-width: 900px;">
        
        <div class="field-body" *ngIf="selectedFile == null">
            <div class="field awaitingInput">
                <div class="control" style="text-align: center;">
                    <div>
                        <i style="font-size: 4em;" class="fa fa-download"></i><br />
                        <p>Click here to choose or drag a file to upload.</p>
                    </div>
                    <input type="file" class="file-input" accept=".csv" (change)="onFileSelected($event)"
                        #fileUpload>
                </div>
            </div>
        </div>
        
        <div *ngIf="selectedFile != null">
            <div class="field">
                <p> Please select the row that aligns with each of the following details: </p>
            </div>
            <div class="columns">
                <div class="column is-half">
                    <div class="field columns">
                        <div class="column is-one-third">
                            <p class="label">
                                Provider
                            </p>
                        </div>
                        <div class="column is-two-thirds control">
                            <div class="control select">
                                <select (change)="mappingChanged($event)" id="Provider">
                                    <option selected disabled hidden>Select a provider...</option>
                                    <option *ngFor="let provider of providers" [ngValue]="provider">{{provider}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    
                    <div *ngFor="let field of mappedFields" >
                        <span *ngIf="field.sideIndex == 0">
                            <div class="field columns">
                                <div class="column is-one-third">
                                    <p class="label">
                                        {{field.displayName}}
                                    </p>
                                </div>
                                <div class="column is-two-thirds control">
                                    <div class="control select">
                                        <select (change)="mappingChanged($event)" id="{{field.mappedField}}" [ngModel]="fieldMapping.get(field.mappedField) || ''">
                                            <option value="" disabled hidden>Select a header...</option>
                                            <option *ngFor="let header of fileHeaders" value="{{header}}">{{header}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>

                <div class="column is-half">
                    <div class="field columns">
                        <div class="column is-half" tippy="Are the times in UTC or Local? The switch should be green for UTC.">
                            <p class="label">
                                Convert from UTC times
                            </p>
                        </div>
                        <div class="column is-half control">
                            <app-toggleswitch [(ngModel)]="timesAreUtc"></app-toggleswitch>
                        </div>
                    </div>

                    <div *ngFor="let field of mappedFields" >
                        <span *ngIf="field.sideIndex == 1">
                            <div class="field columns">
                                <div class="column is-one-third">
                                    <p class="label">
                                        {{field.displayName}}
                                    </p>
                                </div>
                                <div class="column is-two-thirds control">
                                    <div class="control select">
                                        <select (change)="mappingChanged($event)" id="{{field.mappedField}}" [ngModel]="fieldMapping.get(field.mappedField) || ''">
                                            <option value="" disabled hidden>Select a header...</option>
                                            <option *ngFor="let header of fileHeaders" value="{{header}}">{{header}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>

      </section>
      <footer class="modal-card-foot">
        <button class="button is-danger" (click)="cancel()">Cancel</button>
        <button class="button is-success" (click)="upload()" [disabled]="canUpload() ? null : 'disabled'"><i class="fa-light fa-upload"></i>&nbsp;&nbsp;Upload</button>
      </footer>
    </div>
  </div>