import { Component, OnInit } from '@angular/core';
import { ColorService } from 'src/app/Services/color.service';
import { Datalistviewcolumn, PercentagesDisplaycolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-thresholdsets',
  templateUrl: './thresholdsets.component.html',
  styleUrls: ['./thresholdsets.component.scss']
})
export class ThresholdsetsComponent implements OnInit {

  constructor(private colorService: ColorService) { }

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "Name"),
    new PercentagesDisplaycolumn("Description", "ParkingThresholdColors", this.colorService),
  ];

  ngOnInit(): void {
  }
}
