import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Day } from "src/app/components/Day";
import { CalendarService } from "src/app/Services/calendar.service";
import { ColorService } from "src/app/Services/color.service";
import { OrganizationsService } from "src/app/Services/organizations.service";

@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
})
export class CalendarComponent implements OnInit {
  public monthDays: Day[] | undefined;
  public monthNumber: number = 0;
  public year: number = 0;
  @ViewChild('calendar') calendarRef!: ElementRef;

  public weekDaysName: string[] = [];

  public dayTypes: any[] = [];
  public dateSelectValue: string = "";

  constructor(public calendarCreator: CalendarService, public calendarService: CalendarService, public colorService: ColorService) {}

  ngOnInit(): void {
    this.setMonthDays(this.calendarCreator.getCurrentMonth());
    this.dateSelectValue = this.calendarCreator.currentYear.toString() + "-" + (this.calendarCreator.currentMonthIndex > 9 ? this.calendarCreator.currentMonthIndex : "0" + this.calendarCreator.currentMonthIndex).toString();

    this.weekDaysName.push("Monday");
    this.weekDaysName.push("Tuesday");
    this.weekDaysName.push("Wednesday");
    this.weekDaysName.push("Thursday");
    this.weekDaysName.push("Friday");
    this.weekDaysName.push("Saturday");
    this.weekDaysName.push("Sunday");

    this.getDayTypes();
  }

  getDayTypes(){
    this.calendarCreator.GetDayTypes().then(result => {
      this.dayTypes = result;
    })
  }

  onNextMonth(): void {
    this.monthNumber++;
    if (this.monthNumber == 12) {
      this.monthNumber = 0;
      this.year++;
    }
    this.setMonthDays(this.calendarCreator.getMonth(this.monthNumber, this.year));
  }

  setCurrentMonth(): void {
    this.setMonthDays(this.calendarCreator.getCurrentMonth());
  }

  onPreviousMonth() : void{
    this.monthNumber--;

    if (this.monthNumber < 0) {
      this.monthNumber = 11;
      this.year--;
    }
    this.setMonthDays(this.calendarCreator.getMonth(this.monthNumber, this.year));
  }

  addCalendarDayType(day :any){
    day.ShowAddType = true; 
  }

  deleteDayTypeDay(day:any, daytypedayId :any, daytypedayName: string){
    this.calendarService.DeleteDayTypeDay(daytypedayId);
    day.DayTypes = day.DayTypes.filter((x:any)=> x.Id != daytypedayId);
  }

  updateCalendarDayType(event :any, day: any){
    this.calendarService.AddDayTypeDay({'Month': day.monthIndex + 1, 'Year': day.year, 'Day': day.number, 'DayTypeId': event.target.value }).then(result => {
      day.ShowAddType = false;

      var dayType = this.dayTypes.filter(x => x.Id == event.target.value)[0] 
      day.DayTypes.push({'Id': result.Id, 'CalendarDayTypeColor':dayType.Color , 'CalendarDayTypeName': dayType.Name})
    });
  }

  refresh(){
    this.monthDays = [];
    this.setMonthDays(this.calendarCreator.getMonth(this.monthNumber + 1, this.year));
  }

  private setMonthDays(days: Day[]): void {
    this.monthDays = days;

    this.monthNumber = this.monthDays[0].monthIndex;
    this.year = this.monthDays[0].year;
    
    this.calendarService.GetOrganizationDayTypeDays(this.monthNumber + 1, this.year).then(dayTypeDaysForRange => {
      this.monthDays?.forEach((monthday:any) => {
        monthday.DayTypes = dayTypeDaysForRange.filter((x:any) => x.CalendarDayMonth == monthday.monthIndex+1 && x.CalendarDayYear == monthday.year && x.CalendarDayDay == monthday.number);
      })
    });
  }

  getTextClass(itemColor : string) {
    return this.colorService?.GetVisibleTextClassFor(itemColor);
  }

}