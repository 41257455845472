import { Component, OnInit } from '@angular/core';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { CurrencyViewColumn, DataListFilterColumn, DatalastviewTextAndConditionalSuffixColumn, Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {
  public ShowPermitFilter = false;
  public ShowRatesFilter = false;
  constructor(private permissionsService : PermissionsService) { }

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Id", "Id"),
    new CurrencyViewColumn("Amount", "Amount"),
    new Datalistviewcolumn("Transaction Reference", "TransactionReference"),
    new Datalistviewcolumn("Transaction Id", "TransactionId"),
    new Datalistviewcolumn("Transaction Date", "TransactionDateLocalFriendlyString"),
    new Datalistviewcolumn("Settlement Date", "SettlementDateLocalFriendlyString"),
    new Datalistviewcolumn("Masked Card Number", "MaskedAccountNumber"),
  ];

  async ngOnInit(): Promise<void> {
  }

}
