<div class="modal is-active">

  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Upload Media</p>
    </header>
    <section class="modal-card-body" style="min-width: 600px;">
      <div class="field is-horizontal">

        <div class="field-body">
          <div class="field awaitingInput">
            <div class="control" style="text-align: center;">
              <img *ngIf="!fileInvalid && previewFile && isImageFile" [src]="this.previewFile" alt="File Preview" width="100">
              <div *ngIf="!fileInvalid && previewFile && !isImageFile"><i class="fa fa-film" style="font-size:4em;"></i></div>
              <div *ngIf="!previewFile || fileInvalid">
                <i style="font-size: 4em;" class="fa fa-download"></i><br />
                <p>Click here to choose or drag a file to upload.</p>
              </div>
              
              <br>

              <div class="file-upload">
                {{selectedFile?.name || 'No File Selected'}}
              </div>
              <input type="file" class="file-input" [accept]="RequiredFileType" (change)="onFileSelected($event)"
                #fileUpload>

            </div>
          </div>
        </div>
      </div>


    </section>
    <footer class="modal-card-foot">
      <button [disabled]="previewFile == null || fileInvalid" class="button is-primary" (click)="uploadMedia(this.ImageCategory)">
        <span class="icon-text">
          <span class="icon">
            <i class="fa fa-upload"></i>
          </span>
          <span>Upload</span>
        </span>
      </button>
      <button class="button is-danger" (click)="close()">Cancel</button>

    </footer>
  </div>


</div>