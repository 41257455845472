import { Component, Input, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-editterminaljson',
  templateUrl: './editterminaljson.component.html',
  styleUrls: ['./editterminaljson.component.scss']
})
export class EditterminaljsonComponent extends SimpleModalComponent<any, any> implements OnInit {

  @Input()
  public ModelJson : any = { };
  
  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  confirm() {
    this.result = this.ModelJson;
    this.close();
  }

}
