<app-loader [Parent]="this"></app-loader>
<form [formGroup]="Form" *ngIf="FormLoaded == true">
    <app-pageheader [Parent]="this" [Title]="Form.get('Name')?.value" [ModelId]="Form.get('Id')?.value" [Form]="Form">
        <app-form-save-button [Parent]="this" [Form]="Form" (OnSave)="Save()">
        </app-form-save-button>
    </app-pageheader>
    <app-tabs>
        <app-tab Title="Terminal Details">
            <div class="columns">
                <div class="column is-6">
                    <app-field Label="Name">
                        <div class="control">
                            <input class="input" formControlName="Name" />
                            <app-validator [For]="Form.get('Name')"></app-validator>
                        </div>
                    </app-field>
                    <app-field Label="Serial Number">
                        <div class="control">
                            <input class="input" formControlName="SerialNumber" />
                            <app-validator [For]="Form.get('SerialNumber')"></app-validator>
                        </div>
                    </app-field>
                    <app-field Label="Relay Ip Address">
                        <div class="control">
                            <input type="text" class="input" formControlName="RelayIpAddress">
                            <app-validator [For]="Form.get('RelayIpAddress')"></app-validator>
                        </div>
                    </app-field>
                    <app-field Label="Relay Port">
                        <div class="control">
                            <input type="Number" min="0" class="input" formControlName="RelayPort">
                            <app-validator [For]="Form.get('RelayPort')"></app-validator>
                        </div>
                    </app-field>
                    <app-field Label="Log Token">
                        <div class="control">
                            <input type="Number" min="0" class="input" formControlName="LogToken">
                            <app-validator [For]="Form.get('LogToken')"></app-validator>
                        </div>
                    </app-field>
                </div>
                <div class="column is-6">
                    <app-map-input MapWidth="100%" formControlName="GeoLocationPoints" Mode="marker"></app-map-input>
                </div>
            </div>
        </app-tab>
        <app-tab Title="Settings">
            <div class="columns">
                <div class="column is-6">
                    <app-field Label="Offline After" [HasAddons]="true">
                        <div class="control flex-grow">
                            <input class="input" formControlName="OfflineThresholdMinutes" />
                            <app-validator [For]="Form.get('OfflineThresholdMinutes')"></app-validator>
                        </div>
                        <div class="control">
                            <a class="button is-light">minutes</a>
                        </div>
                    </app-field>
                    <app-field Label="Checkin Interval" [HasAddons]="true">
                        <div class="control flex-grow">
                            <input class="input" formControlName="CheckinIntervalMinutes" />
                            <app-validator [For]="Form.get('CheckinIntervalMinutes')"></app-validator>
                        </div>
                        <div class="control">
                            <a class="button is-light">minutes</a>
                        </div>
                    </app-field>
                    <app-field Label="Software Version">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select class="input" formControlName="SoftwareVersionId">
                                    <option *ngFor="let s of SoftwareVersions" [ngValue]="s.Id">{{s.Version}}</option>
                                </select>
                            </div>
                        </div>
                    </app-field>
                    <app-unvalidated-input LabelText="Power Relay">
                        <div class="select is-fullwidth">
                            <select formControlName="RelayType">
                                <option value=""></option>
                                <option value="tcp-kp-1202">1202 Relay</option>
                            </select>
                        </div>
                    </app-unvalidated-input>
                </div>
            </div>
        </app-tab>
    </app-tabs>
</form>