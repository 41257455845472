import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IconService } from 'src/app/Services/icon.service';

@Component({
  selector: 'app-valettype',
  templateUrl: './valettype.component.html',
  styleUrls: ['./valettype.component.scss']
})
export class ValetTypeComponent extends Busyable implements OnInit {
  public ModelId: any;
  public Filters: any;
  public Model: any;
  public orgService: OrganizationsService;
  public imageHeight: number = 0;
  public router: Router;
  public san !: DomSanitizer;
  public iconService !: IconService;

  constructor(private apiService: ApiServiceBase, private parentRoute: ActivatedRoute, organizationService: OrganizationsService, router: Router, private sanitizer: DomSanitizer, iconService: IconService) {
    super();
    this.orgService = organizationService;
    this.router = router;
    this.san = sanitizer;
    this.iconService = iconService;
  }

  ngOnInit(): void {
    this.Loading();
    this.parentRoute.params.subscribe(params => { this.ModelId = params['id']; });
    this.apiService.Get<any>("valet/types/" + this.ModelId).then(result => {
      this.Model = result;
      this.StopLoading();
    });
  }
}
