import { Component, Input, OnInit, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Color } from 'src/app/util/color';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-daterange',
  templateUrl: './daterange.component.html',
  styleUrls: ['./daterange.component.scss']
})
export class DateRangeComponent extends SimpleModalComponent<any, any> implements OnInit {

  public startDate: any;
  public endDate: any;
  public display: any;

  ngOnInit(): void {

  }

  public changeStartDate(evt: any) {
    this.startDate = evt.target.value;
  }

  public changeEndDate(evt: any) {
    this.endDate = evt.target.value;
  }

  public Confirm() {

    if (this.startDate != null && this.endDate != null) {
      var sd = new Date(this.startDate);

      var ed = new Date(this.endDate);

      this.display = DisplayDate(sd) + " - " + DisplayDate(ed);
      this.result = { StartDate: this.startDate, EndDate: this.endDate, Display: this.display };
    }
    this.close();
  }
}

export function DisplayDate(date: Date) {
  return date.toLocaleString(undefined, { day: 'numeric' }) + " " + date.toLocaleString(undefined, { month: 'short' }) + " " + date.toLocaleString(undefined, { year: 'numeric' });
}