import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { EditlegacysigndisplayruleComponent } from '../editlegacysigndisplayrule/editlegacysigndisplayrule.component';
import { trigger, state, style, transition, group, animate, stagger, query, animateChild } from '@angular/animations';
import { findIndex } from 'rxjs';
import { AnimationEvent } from "@angular/animations";

@Component({
  selector: 'app-editsigndisplayruleset',
  templateUrl: './editsigndisplayruleset.component.html',
  styleUrls: ['./editsigndisplayruleset.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ height:'0', opacity: '0'}),
        query('@*', animateChild(), {optional: true}),
        animate('.3s ease-out', style({ height:'3em' })),
        animate('.1s ease-out', style({ opacity: '1' }))
      ]),
      transition(':leave', [
        style({ height:'3em', opacity: '1' }),
        query('@*', animateChild(), {optional: true}),
        animate('.1s ease-out', style({ opacity: '0'})),
        animate('.3s ease-out', style({ height:'0' }))
      ])
    ]),
    trigger('move', [
      transition(':increment', [
        style({transform: '*'}),
        group([
          query('@*', animateChild(), {optional: true}),
          animate('.3s ease-out', style({transform: 'translateY(100%)'}))
        ])
        
      ]),
      transition(':decrement', [
        style({transform: '*'}),
        group([
          query('@*', animateChild(), {optional: true}),
          animate('.3s ease-out', style({transform: 'translateY(-100%)'}))
        ])
      ])
    ])
  ]
})
export class EditsigndisplayrulesetComponent extends ModelEditor implements OnInit {

  public ModelTypes: any = [];
  public dialogVisible = true;
  DataTypeMap: Map<string, string> = new Map<string, string>();

  constructor(private apiService: ApiService, public organizationsService: OrganizationsService, private route: ActivatedRoute, private toastService: ToastrService, private modalService: SimpleModalService, private injector: Injector) {
    super("infrastructure/displayrulesets", injector);
    apiService.Get("common/modeltypes/parkingstructure").then(result => {
      this.ModelTypes = result;
    });
    this.DataTypeMap.set('88', 'fa fa-times')
    this.DataTypeMap.set('160', 'fa fa-arrow-up');
    this.DataTypeMap.set('161', 'fa fa-arrow-up-right');
    this.DataTypeMap.set('162', 'fa fa-arrow-right');
    this.DataTypeMap.set('163', 'fa fa-arrow-down-right');
    this.DataTypeMap.set('164', 'fa fa-arrow-down');
    this.DataTypeMap.set('165', 'fa fa-arrow-down-left');
    this.DataTypeMap.set('166', 'fa fa-arrow-left');
    this.DataTypeMap.set('167', 'fa fa-arrow-up-left');
  }

  public override DefaultModel() {
    return {Name: 'Sign Ruleset', TargetType: '', DisplayRules: []};
  }
  public override AfterModelLoaded(): void {
  }
  public override BeforeSave(): boolean | void {
  }
  public override Validators() {
  }

  public AddDisplayRule() : void {
    var newline = {Id: 0, ClassName: 'SignConfigurationLegacyTextDisplayRule', Threshold: '', Brightness: 100, Mode: 1, SplitMode: 0, TextColor: 0, TextAlignment: 0, 
    DataType: 0, ShowStyle: 0, SecondDisplayTextColor: 0, SecondDisplayTextAlignment: 0, SecondDisplayDataType: 0, SecondDisplayShowStyle: 0, Content: '', 
    SecondDisplayContent: '', StayTimeInSeconds: 0, Speed: 0, SecondDisplayStayTimeInSeconds: 0, SecondDisplaySpeed: 0, DisplayIndex: 0, TargetThresholdOperator: '=',
    Index: (this.Form.get("DisplayRules") as UntypedFormArray).length + 1, TargetThresholdProperty: null, SecondsBetweenToggling: 0 };
    this.AddToFormArray(this.Form.get("DisplayRules") as UntypedFormArray, newline, "DisplayRules");
  }

  public RemoveDisplayRule(index: any) : void {
    this.FormArray(this.Form, "DisplayRules").removeAt(index);
    this.RecalculateIndexes();
    this.Form.markAsDirty();
  }

  public moveUp(index: number) : void {
    if (index > 0){
      var rules = this.Form.get("DisplayRules") as UntypedFormArray;
      var rule = rules.at(index);
      rule.get('DisplayIndex')?.setValue(rule.get('DisplayIndex')?.value - 1);
      var rule2 = rules.at(index - 1);
      rule2.get('DisplayIndex')?.setValue(rule2.get('DisplayIndex')?.value + 1);
    }
  }

  public TriggerSwap(index: number, event: AnimationEvent): void {
    if (event.fromState == 'void'){
      return;
    }

    //each swap will trigger this event twice, one for the row shifted up and one for the row shifted down.
    //only processes the shift down event - we only want to process once to prevent messing with the indexes.
    if (event.fromState > event.toState){
      var rules = this.Form.get("DisplayRules") as UntypedFormArray;
      var array = rules.value;
      var sortedArray = this.SwapDisplayRules(array, index-1, index);
      var dis1 = sortedArray[index].DisplayIndex;
      var dis2 = sortedArray[index-1].DisplayIndex;
      sortedArray[index].DisplayIndex = dis2;
      sortedArray[index-1].DisplayIndex = dis1;
      rules.setValue(sortedArray);
      this.RecalculateIndexes();
    }
  }

  SwapDisplayRules(arr: any[], index1: number, index2: number): any[] {
    arr = [...arr];
    const temp = arr[index1];
    arr[index1] = arr[index2];
    arr[index2] = temp;
    return arr;
  }

  public moveDown(index: number) : void {
    var rules = (this.Form.get("DisplayRules") as UntypedFormArray);
    if (index < rules.value.length-1) {
      var rule = rules.at(index);
      rule.get('DisplayIndex')?.setValue(rule.get('DisplayIndex')?.value + 1);
      var rule2 = rules.at(index + 1);
      rule2.get('DisplayIndex')?.setValue(rule2.get('DisplayIndex')?.value - 1);
    }
  }

  public RecalculateIndexes(): void {
    for (let i = 0; i <(this.Form.get("DisplayRules") as UntypedFormArray).length; i++){
      var rule = (this.Form.get("DisplayRules") as UntypedFormArray).at(i);
      rule.get('Index')?.setValue(i+1);
    }
  }

  public EditDisplayRule(index: any) : void {
    let displayRule = (this.Form.get('DisplayRules') as UntypedFormArray).at(index);
    switch(displayRule.get('ClassName')?.value)
    {
      case 'SignConfigurationLegacyTextDisplayRule':
      {
        this.modalService.addModal(EditlegacysigndisplayruleComponent, { Form: (this.Form.get('DisplayRules') as UntypedFormArray).at(index), TargetType: this.Form.get('TargetType')?.value })
        .subscribe((result) => {
        });
      }
    }
  }

  public GetContentIcon(datatype : string) : string|undefined {
    if (datatype == '0'|| datatype == '2')
      return '';
    return this.DataTypeMap.get(datatype);
  }
}
