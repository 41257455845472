<div class="modal is-active" [formGroup]="Form">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Camera</p>
        </header>
        <section class="modal-card-body" style="min-width: 800px;">
            <app-tabs>
                <app-tab Title="Camera Details">
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Camera Type</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <div class="control">
                                    <div class="select">
                                        <select formControlName="Type">
                                            <option value=""></option>
                                            <option value="dahua">Frog LPR Camera</option>
                                            <option value="AR-E152">EINAR AR-E152 LPR Camera</option>
                                            <option [hidden]="isProduction()" value="dahuadummy">Frog LRP Camera
                                                Simulator
                                            </option>

                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Serial Number</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <p class="control">
                                    <input formControlName="SerialNumber" class="input" type="text"
                                        placeholder="Serial" />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">IP Address</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <p class="control">
                                    <input formControlName="LocalAddress" class="input" type="text"
                                        placeholder="Name" />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Username</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <p class="control">
                                    <input formControlName="Username" class="input" type="text"
                                        placeholder="username" />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Password</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <p class="control">
                                    <input formControlName="Password" class="input" type="text"
                                        placeholder="password" />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Port</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <p class="control">
                                    <input formControlName="LocalPort" class="input" type="text" />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Enabled for</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <div class="control">
                                    <div class="select">
                                        <select formControlName="ActiveDirection">
                                            <option value="Entry">Entry</option>
                                            <option value="Exit">Exit</option>
                                            <option value="Both">Both</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </app-tab>
            </app-tabs>

        </section>
        <footer class="modal-card-foot">
            <button class="button is-success" (click)="confirm()">OK</button>
        </footer>
    </div>
</div>