import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Busyable } from '../../editors/busyable';
import { OccupancyService } from 'src/app/Services/occupancy.service';

@Component({
  selector: 'app-occupancygauge',
  templateUrl: './occupancygauge.component.html',
  styleUrls: ['./occupancygauge.component.scss']
})
export class OccupancygaugeComponent extends Busyable implements OnInit, OnDestroy {

  
  @Input()
  public Height : number = 400;
  public TotalSpaces: number = 0;
  public OccupiedSpaces?: number;
  public Percent: number = 0;
  public GaugePercentPerTick = 5;
  public GaugeBoxes : string[] = [];
  private OccupancySubscription : Subscription;

  public ColorThresholds :any[] = [
    {Threshold: 75, Color1: "#cf1504", Color2: "#ab1103"},
    {Threshold: 50, Color1: "#e69705", Color2: "#ab7105"},
    {Threshold: 25, Color1: "#cbd904", Color2: "#a4b004"},
    {Threshold: 0, Color1: "#73d404", Color2: "#4e9101"},
  ];
  
  constructor(private occupancyService : OccupancyService) {
    super();
    this.OccupancySubscription = occupancyService.OccupancyChanged.subscribe((val: any) => {
      this.RefreshOccupancy();
    });
  }

  private RefreshOccupancy(){
    this.occupancyService.GetOccupancy(null).then((occupancy: any) => {
      if(occupancy != null){
        this.TotalSpaces = occupancy.TotalSpaces;
        this.OccupiedSpaces = (occupancy.TotalSpaces as number) - (occupancy.AvailableSpaces as number);
        this.Percent = (this.OccupiedSpaces*100 / this.TotalSpaces*100)/100;
        this.UpdateGauge();
        this.StopLoading();
      }
    });
  }

  ngOnInit(): void {
    this.Loading();
    this.RefreshOccupancy();
  }
  ngOnDestroy(): void {
    if(this.OccupancySubscription != null){
      this.OccupancySubscription.unsubscribe();
    }
  }
  public UpdateGauge(){
    if(this.OccupiedSpaces == null){
      this.Percent = 0;
      return;
    }
    this.Percent = Math.round((this.OccupiedSpaces/this.TotalSpaces)*100);
    this.GaugeBoxes = [];
    for(let i = 100; i >= 0; i -= this.GaugePercentPerTick){
      let color = "#ffffff";
      let color2 = "#eeeeee";
      if(i <= this.Percent){
        for(let t of this.ColorThresholds){
          if(i >= t.Threshold){
            color = t.Color1;
            color2 = t.Color2;
            break;
          }
        }
      }
      this.GaugeBoxes.push("linear-gradient(" + color + "," + color2 + ")");
    }
  }

}
