import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import * as THREE from "three";
import * as TWEEN from '@tweenjs/tween.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader";
import { ApiService } from 'src/app/Services/api.service';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Bounds, Geo } from 'src/app/util/geo';
import { Mesh, Object3D, Vector2, Vector3 } from 'three';
import { LevelMesh } from '../levelmapviewer/geometry/level-mesh';
import { Maptiles } from 'src/app/util/maptiles';
import { ObjectDevice } from '../levelmapviewer/geometry/3d-object-device';
import { LevelitemMesh } from '../levelmapviewer/geometry/levelitem-mesh';
import { SpaceMesh } from '../levelmapviewer/geometry/space-mesh';
import { SelectionIndicator } from '../levelmapviewer/geometry/selection-indicator';
import { animate } from '@angular/animations';
import { meshanimationresult } from '../levelmapviewer/geometry/meshanimationresult';

@Component({
  selector: 'app-levelviewer',
  templateUrl: './levelviewer.component.html',
  styleUrls: ['./levelviewer.component.scss']
})
export class LevelviewerComponent implements AfterViewInit, OnChanges {

  @ViewChild("canvas")
  public Canvas!: ElementRef;

  private Camera!: THREE.Camera;
  private Renderer!: THREE.WebGLRenderer;
  private Scene = new THREE.Scene();
  private Orbit !: OrbitControls;
  private raycaster = new THREE.Raycaster();
  private pointer = new THREE.Vector2();
  public levelMesh !: LevelMesh;
  public mixer !: THREE.AnimationMixer;
  public composer !:EffectComposer;
  public renderPass !: RenderPass;
  public outlinePass !: OutlinePass;
  public effectFXAA !: ShaderPass;
  public mixers : meshanimationresult[] = [];

  @Output()
  public DeviceClicked = new EventEmitter<any>();

  @Input()
  public parkingLotId: string = "";

  @Input()
  public parkingLevelId: string = "";
  @Input()
  public AllowSelection : boolean = false;

  @Input()
  public Width: number = 600;
  @Input()
  public Height: number = 300;

  @Input()
  public fullScreen: boolean = false;

  public level: any;
  public SelectionIndicator : SelectionIndicator | null = null;

  public light1: any | undefined;
  public light2: any | undefined;

  constructor(private apiService: ApiService, private element:ElementRef) {
  }

  ngOnChanges() {
    console.log("changes");
    console.log("Full screen value is " + this.fullScreen);

    // if (this.fullScreen) {
    //   this.createView(window.innerWidth, window.innerWidth);
    // } else {
       this.createView(this.Width, this.Height);
    // }
  }

  ngAfterViewInit() {
    this.Height = this.element.nativeElement.offsetHeight;
    this.Width =  this.element.nativeElement.offsetWidth;
    if(this.Height == 0) this.Height = 200;
    if(this.Width == 0) this.Width = 500;
    this.createView(this.Width, this.Height);
  }
  createView(width: number, height: number): void {
    console.log("Create width:" + width);
    console.log("Create height:" + height);
    this.Scene = new THREE.Scene();
    THREE.Object3D.DefaultUp.set(0, 0, 1);
    this.Scene.background = new THREE.Color(0xd4d4d4);
    
    this.Renderer = new THREE.WebGLRenderer({ canvas: this.Canvas.nativeElement, antialias: true });
    //this.Renderer.setPixelRatio(this.Width/this.Height);
    console.log("Set size: " + this.Width);

    this.Renderer.setSize(width, height);
    this.Renderer.shadowMap.enabled = true;
    this.Renderer.shadowMap.type = THREE.PCFSoftShadowMap

    this.apiService.Get<any>("infrastructure/parkinglots/" + this.parkingLotId + "/levels/" + this.parkingLevelId).then(result => {
      this.level = result;
      this.Scene.remove(this.light1);
      this.Scene.remove(this.light2);
      console.log("Got level details");

      let levelBounds = Geo.GetPolygonPointBounds(this.level);
      
      // Maptiles.AddMapTilesToScene(this.Scene, 19, result.GeoLocationPoints, levelBounds, -120);

      this.Camera = new THREE.PerspectiveCamera(50, this.Width/this.Height, 0.0001, 1000);
      this.Camera.up = new THREE.Vector3( 0, 0, 1 );
      this.mixer = new THREE.AnimationMixer(this.Scene);


      let Ambient = new THREE.AmbientLight(0xFFFFFF, 0.5);
      this.Scene.add(Ambient);

      const controls = new OrbitControls(this.Camera, this.Renderer.domElement);
      controls.target.set(levelBounds.Right/2 , -1 * levelBounds.Bottom / 2, 0);
      controls.maxPolarAngle = Math.PI * 0.5;
      this.Orbit = controls;
      this.Camera.position.set(levelBounds.Right/3,-1 * levelBounds.Bottom*2,0.05);
      this.Camera.lookAt(levelBounds.Right/2,-1 * levelBounds.Bottom/2,0);
      
      this.Scene.add(this.Camera);

      this.light1 = new THREE.PointLight( 0xffffff, 1, 3 );
      this.light1.position.set( -1, 1, 1 );
      this.Scene.add( this.light1 );
      this.light2 = new THREE.PointLight( 0xffffff, 3, 3 );
      this.light2.position.set( levelBounds.Right + 1, -1*levelBounds.Bottom - 1, 1 );
      this.Scene.add( this.light2 );


        // this.light1 = new THREE.DirectionalLight(0xffffff, 0.2);
        // this.light1.position.set(levelBounds.Right , -1 * levelBounds.Bottom, 0.65);
        // this.Scene.add(this.light1);

        // this.light2 = new THREE.DirectionalLight(0xffffff, 0.01);
        // this.light2.position.set(levelBounds.Left , -1 * levelBounds.Top, 0.05);
        // this.Scene.add(this.light2);

      if (this.level.PolygonPoints != null && this.level.PolygonPoints.length > 1) {
        this.levelMesh = new LevelMesh(this.Scene, this.level, new Vector3(0, 0, 0), 0.001, new Vector2(1, 1), this.Camera, this.mixers, {ShowAccessControl: true, ShowSpaces: true, ShowShapes: true, ShowWalls:true, ShowGardens: true });
      }
      this.ConfigurePostProcessing();

      const clock = new THREE.Clock();
      this.Renderer.setAnimationLoop(_ => {
        TWEEN.update();
        if(this.mixers){
          this.mixers.forEach((item: any) => {
            item.Mixer.update(item.Clock.getDelta());
          })
        }
        this.Renderer.render(this.Scene, this.Camera);
        this.composer.render();
      });

    });
  }

  // public saveSceneAsImage() {
  //   this.Renderer.render(this.Scene, this.Camera);
  //   // Convert the current frame to a data URL
  //   const dataURL = this.Renderer.domElement.toDataURL();
  
  //   // Create a link element
  //   const link = document.createElement('a');
  //   link.href = dataURL;
  //   link.download = 'scene.png'; // Set the desired file name here
  
  //   // Simulate a click on the link element to trigger the download
  //   link.click();
  // }

  public FindDevice(deviceId : any): any | null{
    if(this.levelMesh?.Meshes != null){
      for( let x of this.levelMesh.Meshes) {
        if(x instanceof LevelitemMesh){
          if(x.Item.Id == deviceId){
            return x;
          }
        }
      };
    }
    return null;
  }

  public CanvasClick(evt : any){
    if(this.AllowSelection == false)
      return;
    var list = [];
    this.pointer.x = ( evt.layerX / this.Width ) * 2 - 1;
	  this.pointer.y = - ( evt.layerY / this.Height ) * 2 + 1;
    this.raycaster.setFromCamera( this.pointer, this.Camera );
    const intersects = this.raycaster.intersectObjects( this.Scene.children, true);

    for ( let i = 0; i < intersects.length; i ++ ) {
      if(intersects[i].face != null){
        list.push(intersects[i]);
      }
    }
    if(list.length > 0 && list[0].object.parent != null){
      var x = list[0].object.parent as ObjectDevice;
      if(x != null && x.Device != null && x.Device.Id != null){
        console.log("DeviceType:" + x.Device.Name + ", DeviceId: " +  x.Device.Id);
        this.DeviceClicked.emit(x.Device);
        this.SelectDevice(x);
      }
    }
    // this.saveSceneAsImage();
  }

  public SelectDevice(obj : Object3D){
    let device = this.FindDevice((obj as any).Device?.Id);
    let prevDevice = null;
    
    if((this.outlinePass != null && this.outlinePass.selectedObjects.length> 0)){
      prevDevice = this.FindDevice(((this.outlinePass.selectedObjects[0]) as any).Device.Id)
      prevDevice.Selected = false;
    }

    if(prevDevice!= null && device.Item.Id == prevDevice.Item.Id){
      this.outlinePass.selectedObjects = [];
      device.Selected = false;
      this.DeviceClicked.emit(null);
    }
    else{
      this.outlinePass.selectedObjects = [obj];
      device.Selected = true;
    }
  }

  public SelectDeviceById(id : string){
    let device = this.FindDevice(id);
    if(device != null){
      this.SelectDevice(device);
    }
  }


  private ConfigurePostProcessing(){
    this.composer = new EffectComposer(this.Renderer);
    this.composer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    // - render pass
    this.renderPass = new RenderPass(this.Scene, this.Camera);
    this.composer.addPass(this.renderPass);
    // - outline pass
    this.outlinePass = new OutlinePass(
      new THREE.Vector2(window.innerWidth, window.innerHeight),
      this.Scene,
      this.Camera
    );
    // -- parameter config
    this.outlinePass.edgeStrength = 3.0;
    this.outlinePass.edgeGlow = 1.0;
    this.outlinePass.edgeThickness = 3.0;
    this.outlinePass.pulsePeriod = 0;
    this.outlinePass.usePatternTexture = false; // patter texture for an object mesh
    this.outlinePass.visibleEdgeColor.set("#ff0000"); // set basic edge color
    this.outlinePass.hiddenEdgeColor.set("#ff0000"); // set edge color when it hidden by other objects
    this.composer.addPass(this.outlinePass);
    
    //shader
    this.effectFXAA = new ShaderPass(FXAAShader);
    this.effectFXAA.uniforms["resolution"].value.set(
      1 / window.innerWidth,
      1 / window.innerHeight
    );
    this.effectFXAA.renderToScreen = true;
    this.composer.addPass(this.effectFXAA);
  }
}
