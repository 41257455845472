import { ParkingLevelMapItem } from "../../../edit/designer/levelmapeditor/mapitems";
import { LevelEditorComponent } from "../../leveleditor.component";
import { MapComponentBase } from "../MapComponentBase";
import { fabric } from 'fabric';
import { Level } from "./Level";
import { EventEmitter, Output } from "@angular/core";
import { FabricHelpers } from "../../fabric.js/helpers";
import { Geo } from "src/app/util/geo";
import { FormArray } from "@angular/forms";
import { v4 as uuidv4 } from 'uuid';
import { point } from "@turf/turf";
import { UndoAction } from "../../UndoActions/undoaction";


export class PolygonMapComponent extends MapComponentBase {
    public UpdatedPoints: any;
    public FillColor : string | null = null;
    public StrokeColor : string | null = null;
    public StrokeWidth : number | null = null;
    public Polygon !: fabric.Polygon;
    public Label !: fabric.Text;
    public PolygonTop !: number;
    public PolygonLeft !: number;
    public override FabricItem !: fabric.Group;
    public TransformedPoints !: any;
    public PatternFill : boolean = false;
    public PatternFillColor: string = "black";
    public HatchSize = 10;
    public ShowLabel : boolean = false;
    public TitleMargin : number = 10;
    public MeasurementLines !: fabric.Group | null;
    public ShowMeasurementLines: boolean = false;
    public EditDots: any = [];
    public AddEditDots: any = [];
    public Editing: boolean = false;
    public EntrySegment !: number;
    public SegmentLines: fabric.Line[] = [];
    public ExitSegment !: number;

    constructor(leveleditorcomponent: LevelEditorComponent, canvas: any, formItem: any, parentFormItem: any, forceClone: boolean = false) {
        super(leveleditorcomponent, canvas, formItem, parentFormItem, forceClone);
        this.TransformedPoints = this.formItem.get('PolygonPoints')?.value;
        this.SetFormValues();
    }

    public override SetFormValues(){
        this.DisplayAngle = this.formItem.get('DisplayAngle')?.value;
        this.EntrySegment = this.formItem.get('EntrySegment')?.value;
        this.ExitSegment = this.formItem.get('ExitSegment')?.value;
    }

    public override Rotate(value: number){
        this.FabricItem.set({'angle': value});
        this.Polygon.fire('modified');
        this.canvas.renderAll();
    }

    public override SetSelectedObject(): void {
        this.leveleditorcomponent.SelectedItems = [];
        this.leveleditorcomponent.SelectedItems.push(this);
        this.canvas.setActiveObject(this.FabricItem);
    }

    public PolygonToFabricPoints(){
        return this.TransformedPoints.map((item : any)=> ({ x: (item[0]*this.ScaleFactor * (this.ForceClone ? 1 : this.Zoom)), y: (item[1]*this.ScaleFactor * (this.ForceClone ? 1: this.Zoom)) }))
    }

    public FabricToPolygonPoints(points: any){
        var matrix = this.Polygon.calcTransformMatrix();
        var transformedPoints = points.map((p: any) => {
            return new fabric.Point(
            p.x - this.Polygon.pathOffset.x,
            p.y - this.Polygon.pathOffset.y
            );
        })
        .map(function (p: any) {
            return fabric.util.transformPoint(p, matrix);
        })
        .map((p: any) => { return [p.x / this.ScaleFactor / this.Zoom, p.y / this.ScaleFactor / this.Zoom] });
        return transformedPoints;
    }

    public DescalePoints(points: any){
        var transformedPoints = points.map((p: any) => {
            return new fabric.Point(
            p.x / this.ScaleFactor / this.Zoom,
            p.y / this.ScaleFactor / this.Zoom
            );
        })
        .map((p: any) => { return [p.x, p.y] });
        return transformedPoints;
    }

    public Clone(formitem: any){
        return new PolygonMapComponent(this.leveleditorcomponent, this.canvas, formitem, this.parentFormItem, true);
    }


    public ButtonsForAddingPolygonPoints(){
        if(this.AddEditDots != null){
            this.AddEditDots.forEach((item: any) => {
                if (item instanceof fabric.Object) {     
                    this.canvas.remove(item);
                }
            });
        }

        const points = this.PolygonToFabricPoints();
        console.log('polygon points:' + points);
    
        if (!points) {
            return;
        }

        for (let i = 0; i < points.length; i++) {
            const point1 = points[i];
            const point2 = points[(i + 1) % points.length]; // Wrap around to the first point

            if (point1.x === point2.x && point1.y === point2.y) {
                continue; // Skip adding the midpoint if the points are the same
            }

            // Calculate midpoint
            const midPoint = {
                x: (point1.x + point2.x) / 2,
                y: (point1.y + point2.y) / 2
            };

            const plusCircle = new fabric.Circle({
                radius: 3,
                fill: 'blue',
                left: midPoint.x,
                top: midPoint.y,
                originX: 'center',
                originY: 'center',
                hasControls: false,
                hasBorders: false,
                selectable: false, // Make it not selectable to prevent dragging
                name: `midpoint_${i}`
            });

            // Add the midpoint circle to the canvas
            this.AddEditDots.push(plusCircle);
            this.canvas.add(plusCircle);

            plusCircle.on('mousedown', () => {
                const circleName = plusCircle.name as string | undefined;
                if (circleName) {

                    var newx = (plusCircle.left ?? 0) - (this.Polygon.left ?? 0);
                    var newy = (plusCircle.top ?? 0) - (this.Polygon.top ?? 0);


                    if (i === points.length - 1) {
                        // Add point to the end of the array
                        points.push({ x: newx, y: newy });
                    } else {
                        // Insert the new point into the points array at the correct index
                        points.splice(i + 1, 0, { x: newx, y: newy });
                    }
            
                    var undoAction = new UndoAction(this.leveleditorcomponent.getCanvasState(), this.leveleditorcomponent, this.formItem, "PolygonPoints", this.formItem.get("PolygonPoints")?.value, undefined);
                    this.TransformedPoints = this.DescalePoints(points);
                    this.formItem.get("PolygonPoints").setValue(this.DescalePoints(points));
                    this.leveleditorcomponent.Form.markAsDirty();
                    undoAction.NewValue = this.formItem.get("PolygonPoints")?.value;

                    this.UndoActions.push(undoAction);
                    this.AddEditPoints();
                    this.ButtonsForAddingPolygonPoints();
                }
            });
        }
    }

    public AddEditPoints(){
        if(this.EditDots != null){
            this.EditDots.forEach((item: any) => {
                if (item instanceof fabric.Object) {     
                    this.canvas.remove(item);
                }
            });
        }

        const points = this.PolygonToFabricPoints();
        this.Polygon.set({
            lockMovementX: true,
            lockMovementY: true,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            hasControls: false,
            hasBorders: false,
            selectable: false // If you don't want it to be selectable by the user
        }); 

        // For each point, create a circle and make it draggable
        points?.forEach((point: any, index: any) => {
        const circle = new fabric.Circle({
            radius: 4,
            fill: 'white', // Set fill color to white
            stroke: 'black', // Set stroke color to black
            strokeWidth: 2,
            left: point.x, // Offset by the polygon's position
            top: point.y,
            originX: 'center',
            originY: 'center',
            hasControls: false, // No control handles
            hasBorders: false,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            lockMovementX: false,
            lockMovementY: false,
            selectable: true,
            name: `point_${index}` // Optional: useful for identification
        });

        this.EditDots.push(circle);
        // Add circle to canvas
        this.canvas.add(circle);

        circle.on('mousedown', (event) => {
            if(this.leveleditorcomponent.DeletePressed){
                var undoAction = new UndoAction(this.leveleditorcomponent.getCanvasState(), this.leveleditorcomponent, this.formItem, "PolygonPoints", this.formItem.get("PolygonPoints")?.value, undefined);
                // Remove the corresponding point from the polygon's points array
                points.splice(index, 1);
                // Remove the circle from the editDots array                
                const circleIndex = this.EditDots.indexOf(circle);
                if (circleIndex !== -1) {
                    this.EditDots.splice(circleIndex, 1);
                }
                // Remove the circle from the canvas
                this.canvas.remove(circle);

                this.TransformedPoints = this.FabricToPolygonPoints(points);
                this.formItem.get("PolygonPoints").setValue(this.FabricToPolygonPoints(points));
                undoAction.NewValue = this.formItem.get("PolygonPoints")?.value;
                this.UndoActions.push(undoAction);
                this.leveleditorcomponent.Form.markAsDirty();
                this.AddFabricItem();
                this.ButtonsForAddingPolygonPoints();
            }
        });

        circle.on('moving', (event) => {
            // Update the corresponding point in the polygon's points array
            points[index].x = (circle.left ?? 0) - (this.Polygon.left ?? 0);
            points[index].y = (circle.top ?? 0) - (this.Polygon.top ?? 0);

            this.TransformedPoints = this.FabricToPolygonPoints(points);
            this.formItem.get("PolygonPoints").setValue(this.FabricToPolygonPoints(points));
            this.leveleditorcomponent.Form.markAsDirty();
            this.AddFabricItem();
            this.ButtonsForAddingPolygonPoints();
        });
        });
        this.ButtonsForAddingPolygonPoints();
    }

    public RedrawWithEditPoints(){
        this.AddEditPoints();
        this.ButtonsForAddingPolygonPoints();
        this.canvas.renderAll();
    }

    public override AddFabricItem(){
        this.SetFormValues();
        if(this.FabricItem != null){
            this.canvas.remove(this.FabricItem);
        }

        this.ClearSegmentLines();
        this.ClearMeasurementLines();
        // this.Zoom = this.canvas.getZoom();
        this.Polygon = new fabric.Polygon(this.PolygonToFabricPoints(), { 
            fill: 'transparent',
            stroke: this.StrokeColor ? this.StrokeColor : '#000000',
            strokeWidth: this.StrokeWidth ? this.StrokeWidth: 2,
            opacity: this.Opacity ? this.Opacity : 1,
            angle: this.DisplayAngle,
            originX: 'center', // Set origin to center
            originY: 'center', // Set origin to center
            centeredRotation: true,
            selectable: !this.Editing,
            lockMovementX: this.Locked,
            lockMovementY: this.Locked,
            hasControls: (this.Locked ? false : !this.Editing),
            id: this.FabricItemId,
            strokeUniform: true,
            absolutePositionedScaling: true,
        } as fabric.IObjectOptions); 

        console.log('poly, ' + (this.Locked ? false : !this.Editing));

        this.PolygonTop = this.Polygon.top ?? 0;
        this.PolygonLeft = this.Polygon.left ?? 0;

        this.FabricItem = new fabric.Group([this.Polygon], {
            selectable: (this.Locked ? false : !this.Editing),
            hasControls: (this.Locked ? false : !this.Editing),
            lockMovementX: this.Locked,
            lockMovementY: this.Locked,
        });

        this.FabricItem.on('moving', () => {
            this.Polygon.fire("moving");
        });

        this.FabricItem.on('scaling', () => {
            this.Polygon.fire("scaling");
        });

        this.FabricItem.on('rotating', () => {
            this.Polygon.fire("rotating");
        });

        this.FabricItem.on('mousedown', () =>  {
            // Check if the group is locked (optional)
            if (this.Locked) {
                this.leveleditorcomponent.toastService.info('This ' + this.ClassName + ' is Locked. Unlock it to make changes or to include it in marquee selections.');
            }
            this.leveleditorcomponent.ItemSelected.emit(this);
        });

        this.SetFillColor();
        this.AddLabel();
        this.DrawMeasurementLines();
        this.SetIndexSegments();
        this.canvas.add(this.FabricItem);
        this.FabricItem.moveTo(this.zIndex);

        this.FabricItem.on("deselected", () => {
            this.leveleditorcomponent.ItemDeselected.emit(this);
        });

        this.Polygon.on('scaling', () => {
            console.log('im scaling');
            this.TransformedPoints = this.FabricToPolygonPoints(this.Polygon.points)
            this.leveleditorcomponent.Form.markAsDirty();
            this.SetIndexSegments();
            this.DrawMeasurementLines();
            this.canvas.renderAll();
        });

        this.Polygon.on('moving', () => {
            console.log('im scaling');
            this.TransformedPoints = this.FabricToPolygonPoints(this.Polygon.points)
            this.leveleditorcomponent.Form.markAsDirty();
            this.SetIndexSegments();
            this.DrawMeasurementLines();
            this.canvas.renderAll();
        });

        this.Polygon.on('rotating', () => {
            console.log('im rotating');
            this.TransformedPoints = this.FabricToPolygonPoints(this.Polygon.points)
            this.leveleditorcomponent.Form.markAsDirty();
            this.SetIndexSegments();
        });

        this.Polygon.on('modified', () => {
            //push to actions array
            var transformedPoints = this.FabricToPolygonPoints(this.Polygon.points);

            if(this.RequiresParent && this.parentFormItem){
                //check item still in parent
                var parentPolygon = this.parentFormItem.get('PolygonPoints')?.value;
                if(!FabricHelpers.isPolygonInsidePolygon(transformedPoints, parentPolygon)){
                    //find parent.
                    var foundParentItem = false;
                    //find parent item its moved to.
                    const ParentItems = this.leveleditorcomponent.MapItemsByType.get(this.ParentClassName);
                    if(ParentItems){
                        for (let ParentItem of ParentItems) {
                            parentPolygon = ParentItem.formItem.get('PolygonPoints')?.value;
                            if (FabricHelpers.isPolygonInsidePolygon(transformedPoints, parentPolygon)) { 
                                //add to new Parent Form Control
                                let newparentItems = this.leveleditorcomponent.ModelEditor.FormArray(ParentItem.formItem, this.ClassName + "s");

                                var newId = uuidv4();
                                var mapItem = this.leveleditorcomponent.MapItems.find(x => x.Id == this.formItem.get("Id")?.value);
                                if(mapItem){
                                    mapItem.Id = newId;
                                }
                                this.formItem.get("Id").setValue(newId);


                                (newparentItems as FormArray).push(this.formItem);
                            
                                //remove from old parent FormControl
                                let oldparentItems = this.leveleditorcomponent.ModelEditor.FormArray(this.parentFormItem, this.ClassName + "s");
                                
                                let oldparentItemsArray: any[] = [];
                                for (let i = 0; i < oldparentItems.length; i++) {
                                    oldparentItemsArray.push(oldparentItems.at(i));
                                }
                                // Find the index of this.formItem within oldparentItems
                                var index = oldparentItemsArray.indexOf(this.formItem);
                                (oldparentItems as FormArray).removeAt(index);

                                this.parentFormItem = ParentItem.formItem;
                                this.formItem.markAsDirty();
                                this.leveleditorcomponent.toastService.success(this.ClassName + " moved to " + ParentItem.ClassName + ": " + ParentItem.formItem.get("Name")?.value);
                                var foundParentItem = true;
                            }
                        }  
                        if(!foundParentItem){
                            this.leveleditorcomponent.toastService.error("Please place your " + this.ClassName + " inside a " + ParentItems[0].ClassName);                            
                            return;
                        }
                    }
                }
            }

            this.formItem.get("PolygonPoints").setValue(transformedPoints);
            this.TransformedPoints = transformedPoints;
            console.log('points updated for ' + this.formItem.get("Name")?.value + ": "+ transformedPoints);

            if(transformedPoints){
                const minX = Math.min(...transformedPoints.map((point : any) => point[0]));
                const maxX = Math.max(...transformedPoints.map((point : any) => point[0]));
                const minY = Math.min(...transformedPoints.map((point : any) => point[1]));
                const maxY = Math.max(...transformedPoints.map((point : any) => point[1]));
            
                const newWidth = maxX - minX;
                const newHeight = maxY - minY;
                
                // Set new width and height on the polygon
                this.Polygon.set({
                    width: newWidth * this.ScaleFactor,
                    height: newHeight * this.ScaleFactor
                });
            }

            this.AddLabel();
            this.DrawMeasurementLines();
            this.SetIndexSegments();
            this.leveleditorcomponent.Form.markAsDirty();
        });
    }

    public AddLabel(){
        if(this.ShowLabel){
            if(this.Label){
                this.FabricItem?.remove(this.Label);
            };
            const polygonBoundingBox = this.Polygon.getBoundingRect();
            const zoom = this.canvas.getZoom();
            const lineShift = this.TitleMargin;
            const adjustedLineShift = lineShift * zoom;
            const leftPosition = polygonBoundingBox.left + polygonBoundingBox.width - adjustedLineShift / zoom; // Already has zoom applied
            const topPosition = polygonBoundingBox.top + polygonBoundingBox.height - adjustedLineShift / zoom; // Already has zoom applied

            this.Label = new fabric.Text(this.formItem.get("Name")?.value.toString(), {
                left: leftPosition,
                top: topPosition,
                fontSize: 8,
                fill: 'white',
                fontWeight: 400,
                originX: 'right',
                originY: 'bottom',
                selectable: false
            });

            this.Label.bringToFront();    
            this.FabricItem?.add(this.Label);
        }
    }

    getExtremePoints(points: number[][]) {
        const extremes = {
            leftmost: points[0][0],
            rightmost: points[0][0],
            topmost: points[0][1],
            bottommost: points[0][1]
        };
    
        points.forEach(point => {
            const [x, y] = point;
            if (x < extremes.leftmost) {
                extremes.leftmost = x;
            }
            if (x > extremes.rightmost) {
                extremes.rightmost = x;
            }
            if (y < extremes.topmost) {
                extremes.topmost = y;
            }
            if (y > extremes.bottommost) {
                extremes.bottommost = y;
            }
        });
    
        return extremes;
    }

    public DrawMeasurementLines(){
        this.ClearMeasurementLines();
        if(this.ShowMeasurementLines){

            var points = this.TransformedPoints;

            const { leftmost, rightmost, topmost, bottommost } = this.getExtremePoints(points);

            const offset = 20 / this.ScaleFactor;
            const lineOffset = 10 / this.ScaleFactor;
            const midHor = rightmost / 2;
            const midVer = (bottommost - topmost) / 2;  
            const width = ((rightmost - leftmost) * this.ScaleFactor) / 10;
            const height = ((bottommost - topmost) * this.ScaleFactor) / 10;

            const vertpointmidLeft = ((leftmost - offset) * this.ScaleFactor);
            const vertpointmidTop = ((((bottommost) * this.ScaleFactor) + (topmost * this.ScaleFactor)) / 2);

            const horpointmidLeft = (((leftmost * this.ScaleFactor) + (rightmost * this.ScaleFactor)) / 2);
            const horpointmidTop = (bottommost + offset) * this.ScaleFactor;

            // Create the vertical line
            const verticalLine = new fabric.Line([(leftmost - offset) * this.ScaleFactor, topmost * this.ScaleFactor, (leftmost - offset) * this.ScaleFactor, (bottommost) * this.ScaleFactor], {
                stroke: 'white',
                strokeWidth: 2,
                selectable: false
            });

            const horizontalLine = new fabric.Line([(leftmost) * this.ScaleFactor, (bottommost + offset) * this.ScaleFactor, rightmost * this.ScaleFactor, (bottommost + offset) * this.ScaleFactor], {
                stroke: 'white',
                strokeWidth: 2,
                selectable: false
            });


            const topVerticalLine = new fabric.Line([((leftmost - offset) - lineOffset) * this.ScaleFactor, topmost * this.ScaleFactor, ((leftmost - offset) + lineOffset) * this.ScaleFactor, topmost * this.ScaleFactor], {
                stroke: 'white',
                strokeWidth: 2,
                selectable: false
            });

            const bottomVerticalLine = new fabric.Line([((leftmost - offset) - lineOffset) * this.ScaleFactor, bottommost * this.ScaleFactor, ((leftmost - offset) + lineOffset) * this.ScaleFactor, bottommost * this.ScaleFactor], {
                stroke: 'white',
                strokeWidth: 2,
                selectable: false
            });

            const leftHorizontalLine = new fabric.Line([(leftmost) * this.ScaleFactor, ((bottommost + offset)- lineOffset) * this.ScaleFactor, leftmost * this.ScaleFactor, ((bottommost + offset)+lineOffset) * this.ScaleFactor], {
                stroke: 'white',
                strokeWidth: 2,
                selectable: false
            });

            const rightHorizontalLine = new fabric.Line([(rightmost) * this.ScaleFactor, ((bottommost + offset)-lineOffset) * this.ScaleFactor, rightmost * this.ScaleFactor, ((bottommost + offset)+lineOffset) * this.ScaleFactor], {
                stroke: 'white',
                strokeWidth: 2,
                selectable: false
            });

            const verticalLabel = new fabric.Text(height.toFixed(2) + ' m', {
                left: vertpointmidLeft - (offset * this.ScaleFactor), // Adjust the horizontal position as needed
                top: vertpointmidTop,
                fill: 'white',
                fontSize: 10, // Adjust font size based on zoom level
                selectable: false,
                originX: 'center',
                originY: 'center',
                angle: -90 // Rotate the text
            });

            const horizontalLabel = new fabric.Text(width.toFixed(2) + ' m', {
                left: horpointmidLeft,
                top: horpointmidTop + (offset * this.ScaleFactor), // Adjust the vertical position as needed
                fill: 'white',
                fontSize: 10, // Adjust font size based on zoom level
                selectable: false,
                originX: 'center',
                originY: 'bottom'
            });

            this.MeasurementLines = new fabric.Group([verticalLine, horizontalLine, topVerticalLine, bottomVerticalLine, leftHorizontalLine, rightHorizontalLine, verticalLabel, horizontalLabel
            ], {
                 selectable: false
            });

            this.canvas.add(this.MeasurementLines);
            this.MeasurementLines.moveTo(1);
        }
    }

    public CreatePattern(hatchSize: number): string | fabric.Pattern {
        const patternCanvas = document.createElement('canvas');
        patternCanvas.width = hatchSize; // Set canvas width based on hatch size
        patternCanvas.height = hatchSize; // Set canvas height based on hatch size
        const ctx = patternCanvas.getContext('2d');
    
        if (ctx) {
            // Draw the angled lines on the pattern canvas
            ctx.strokeStyle = this.PatternFillColor;
            ctx.lineWidth = 1; // Adjust line width as needed
            ctx.beginPath();
            ctx.moveTo(0, hatchSize); // Start point at bottom-left corner
            ctx.lineTo(hatchSize, 0); // End point at top-right corner
            ctx.stroke();
    
            const image = new Image();
            image.src = patternCanvas.toDataURL();
    
            // Return a function that fabric.js will call to get the pattern source
            return new fabric.Pattern({
                source: image,
                repeat: 'repeat'
            });
        } else {
            console.error('Failed to get 2D context');
            // Return a fallback value, such as a string color, if creating the pattern fails
            return '#FFFFFF';
        }
    }

    public ToggleEditPolygon(editing: boolean){
        this.Editing = editing;
    
       if (this.Editing) {
           this.RedrawWithEditPoints(); 
           this.leveleditorcomponent.BlockAllComponents(this.FabricItem);
           this.leveleditorcomponent.ItemSelected.emit(this);
       }
       else {
           this.Polygon.set({
               lockMovementX: false,
               lockMovementY: false,
               lockRotation: false,
               lockScalingX: false,
               lockScalingY: false,
               hasControls: !this.Editing,
               hasBorders: !this.Editing,
               selectable: !this.Editing // Make it selectable again if needed
           });
   
           if(this.EditDots != null){
               this.EditDots.forEach((item: any) => {
                   this.canvas.remove(item);
               });
           }

           if(this.AddEditDots != null){
            this.AddEditDots.forEach((item: any) => {
                this.canvas.remove(item);
            });
        }
   
           this.leveleditorcomponent.ItemSelected.emit(this);
           this.canvas.setActiveObject(this.FabricItem);
       }
   }

   public SetFillColor(){
        if(this.PatternFill){
            this.Polygon.set('fill', this.CreatePattern(this.HatchSize));
        }
        else{
            this.Polygon.set('fill', 'transparent');
        }
    }

    public SetIndexSegments(){
        this.ClearSegmentLines();
        if (this.EntrySegment != null) {
            this.DrawEntryAndExitSegments(this.EntrySegment);
        }

        if (this.ExitSegment != null) {
            this.DrawEntryAndExitSegments(this.ExitSegment);
        }
        this.canvas.requestRenderAll();
    }

    public ClearSegmentLines(){
        if(this.SegmentLines){
            this.SegmentLines.forEach((line) => {
                this.canvas.remove(line);
            });
            this.SegmentLines = [];
        }
    }

    public ClearMeasurementLines(){
        if(this.MeasurementLines){
            this.canvas.remove(this.MeasurementLines);
            this.MeasurementLines = null;
        }
    }

    public DrawEntryAndExitSegments(index: number){
        const points = this.PolygonToFabricPoints();  
            // Validate index against the points length
            if (index < 0 || index >= points.length) {
                console.warn(`Index ${index} is out of bounds for the polygon's points.`);
                return;
            }
        
            // Get the start point and the next point (loop back to the start if it's the last point)
            const startPoint = points[index];
            const nextPointIndex = (index + 1 < points.length) ? index + 1 : 0;
            const endPoint = points[nextPointIndex];
        
            var segmentLine = new fabric.Line(
                    [
                        startPoint.x,
                        startPoint.y,
                        endPoint.x,
                        endPoint.y
                    ],
                    {
                        stroke: 'blue',
                        strokeWidth: 5,
                        selectable: false,
                        hasControls: false // Adjust line thickness based on zoom
                    }
            );
        // Add the line to the canvas
        this.canvas.add(segmentLine);
        this.SegmentLines.push(segmentLine)
    }


    public override SelectChildren() : MapComponentBase[] {
        var childrenComponents : MapComponentBase[] = [];

        this.ChildrenArrays?.forEach((childArray: any) => {
            var children = this.formItem.get(childArray)?.value as [];
            if(children != null){
                children.forEach((child: any) => {
                    var childComponent = this.leveleditorcomponent.MapItems.find(x => x.Id == child.Id);
                    if(childComponent){
                        childrenComponents.push(childComponent);

                        if(childComponent.ChildrenArrays != null){
                            var childrenchildrencomponents = childComponent.SelectChildren()
                            if(childrenchildrencomponents != null && childrenchildrencomponents.length > 0){
                                childrenchildrencomponents.forEach((childrenchildrencomponent : any) => {
                                    childrenComponents.push(childrenchildrencomponent);
                                });
                            }
                        }
                    }
                });
            }
        });

        var mapItemsWithParentLink = this.leveleditorcomponent.MapItems.find(x => x.ParentClassName == this.ClassName && (x.ParentLinkId != null
            && this.formItem.get(x.ParentLinkId)?.value == x.Id 
        )); 
        if(mapItemsWithParentLink){
            childrenComponents.push(mapItemsWithParentLink);
        };

        return childrenComponents;
    }
}