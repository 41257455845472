import { Component } from '@angular/core';

@Component({
  selector: 'app-fullpagelayout',
  templateUrl: './fullpagelayout.component.html',
  styleUrls: ['./fullpagelayout.component.scss']
})
export class FullpagelayoutComponent {

}
