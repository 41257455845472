import { Component, Input, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { Busyable } from 'src/app/shared/editors/busyable';
import { DeleteCheckComponent } from 'src/app/shared/modals/delete-check/delete-check.component';

@Component({
  selector: 'app-form-delete-button',
  templateUrl: './form-delete-button.component.html',
  styleUrls: ['./form-delete-button.component.scss']
})
export class FormDeleteButtonComponent implements OnInit {

  @Input()
  public Parent: Busyable | undefined;
  @Input()
  public Form: any | null = null;
  @Input()
  public ModelId: any | undefined;
  @Input()
  public DeleteUrl: String = "";
  @Input()
  public DeleteCheck: boolean = false;
  @Input()
  public DeleteNavLink: String = "";
  @Input()
  public Title: String = "";
  @Input()
  public Model: any = null;
  @Input()
  public Api: String = "";
  @Input()
  public TypeName: String = "";

  public Animate: boolean = false;

  @Input()
  public Name: String | null = null;

  constructor(private modal: SimpleModalService) { }

  ngOnInit(): void {
    this.Parent?.BusyStarted.subscribe(e => {
      this.Animate = true;
    })
    this.Parent?.BusyEnded.subscribe(e => {
      this.Animate = false;
    })
  }
  public Clicked(evt: any) {
    //check if we are already busy or if the form isn't dirty
    if (this.Animate == true) {
      evt.stopPropagation();
    }
    else {
      this.Delete();
    }
  }

  public Delete() {
    if (this.Model != null){
      if (this.Model.Name != undefined){
        this.Name = this.Model.Name;
      }
      else if (this.Model.Description != undefined){
        this.Name = this.Model.Description;
      }
    }
    if (this.Name == null) {
      this.Name = this.Title;
    }

    if (this.ModelId == null && this.Model != null){
      this.ModelId = this.Model.Id;
    }

    if (this.Form != null) {
      this.Form.markAsPristine();
    }

    this.modal.addModal(DeleteCheckComponent, { 
        'name': this.Name, 
        'url': this.DeleteUrl,
        'modelId': this.ModelId, 
        'SkipCheck': !this.DeleteCheck, 
        'deleteNavLink': this.DeleteNavLink, 
        'api': this.Api, 
        'typeName': this.TypeName 
      }).subscribe((result) => {
        console.log(result);
    });
  }
}
