import { Component, OnInit } from '@angular/core';
import { BooleanCheckOrCrossViewColumn, Datalistviewcolumn, DateRangeViewColumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-discounttypes',
  templateUrl: './discounttypes.component.html',
  styleUrls: ['./discounttypes.component.scss']
})
export class DiscountTypesComponent implements OnInit {
  constructor() { }

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Codes", "CodesCount"),
    new Datalistviewcolumn("Discount Description", "Description"),
    new DateRangeViewColumn("Active dates", "ActiveStartDateLocalFriendlyString", "ActiveEndDateLocalFriendlyString"),
    new BooleanCheckOrCrossViewColumn("Auto Generating Codes", "AutoGenerateCodes")
  ];

  ngOnInit(): void {
  }
}