import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Busyable } from 'src/app/shared/editors/busyable';

@Component({
  selector: 'app-form-save-button',
  templateUrl: './form-save-button.component.html',
  styleUrls: ['./form-save-button.component.scss']
})
export class FormSaveButtonComponent implements OnInit {

  @Output()
  public OnSave = new EventEmitter<any>();
  @Input()
  public Parent: Busyable | null = null;
  @Input()
  public ButtonType: string = "button";
  @Input()
  public ButtonContext: string = "Save";

  @Input()
  public Icon: string = "fa-check";

  @Input()
  public Form: any | null = null;

  public Animate: boolean = false;

  constructor() { }

  GetIcon() : string {
    if (this.Icon.includes("fa-")) return this.Icon;
    return "fa-" + this.Icon;
  }

  ngOnInit(): void {
    this.Parent?.BusyStarted.subscribe(e => {
      this.Animate = true;
    })
    this.Parent?.BusyEnded.subscribe(e => {
      this.Animate = false;
    })
  }
  public Clicked(evt: any) {
    //check if we are already busy or if the form isn't dirty
    if (this.Animate == true || !this.IsFormDirty()) {
      evt.stopPropagation();
    }
    else {
      this.OnSave.emit();
    }
  }


  public IsFormDirty(): boolean {
    if (this.Form == null) return false;

    if (this.Form instanceof UntypedFormGroup) {
      if (this.Form.status) {
        //reactive forms
        //return this.RecursiveFindDirty(this.Form);
        return (this.Form.valid && this.Form.dirty);
      }
    } else {
      return true;
    }
    return false;

    //return this.Form.classList.contains('ng-dirty');
  }
  private RecursiveFindDirty(group: UntypedFormGroup): boolean {
    if (group.dirty && group.valid)
      return true;
    for (let prop in group.controls) {
      if (group.get(prop) instanceof UntypedFormArray) {
        //property is a formarray
        let arr = group.get(prop) as UntypedFormArray;
        for (let c of arr.controls) {
          if (c.dirty && c.valid)
            return true;
          if (this.RecursiveFindDirty(c as UntypedFormGroup))
            return true;
        }
      }
    }
    return false;
  }
}
