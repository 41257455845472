import { LoginService } from "src/app/auth/login.service";
import { ColorService } from "src/app/Services/color.service";
import { MediaService } from "src/app/Services/media.service";
import { OrganizationsService } from "src/app/Services/organizations.service";
import { ValetService } from "src/app/Services/valet.service";
import { Deviceicons } from "src/app/util/deviceicons";
import { environment } from "src/environments/environment";

export class Datalistviewcolumn {
    public Header: string = "";
    public Property: string = "";
    public MediaId: string = "";
    public Width: string = "";
    public MaxLength: number = Number.MAX_VALUE;
    public EnableFilter: boolean = false;
    public FilterColumn: string = "";
    public FilterValue: string = "Descending";
    public IsIcon: boolean = false;

    constructor(header?: string, property?: string, maxLength?: number) {
        if (header != null)
            this.Header = header;
        if (property != null)
            this.Property = property;
        if (maxLength != null)
            this.MaxLength = maxLength;
    }

    public Render(item: any): string {
        if (item[this.Property] == null || item[this.Property] == undefined){
            return '';
        }

        if (this.MaxLength != Number.MAX_VALUE) {
            return item[this.Property].substring(0, this.MaxLength) + "...";

        } else {
            return item[this.Property];
        }
    }
}

export class ValetTagListColumn extends Datalistviewcolumn {
    public HasModal : boolean = false;
    public ModalName : string | null = null;
    public valetService !: ValetService;

    constructor(header?: string, property?: string, valetService ?: ValetService) {
        super(header);
        if (header != null)
            this.Header = header;
        if (property != null)
            this.Property = property;
        if (valetService != null){
            this.valetService = valetService;
        }
    }

    public override Render(item: any): string {
        var str = '';
        if (item[this.Property] == null || item[this.Property] == undefined){
            return '';
        }

        var string = '';

        var addons = item[this.Property];
        addons.forEach((addon : any) => {
            if(addon.Status != "Rejected" && addon.Status != "Cancelled"){
                string = string + '<div class="tag">' + this.valetService.valetSessionDescription(addon.ValetAddonId, addon.ValetAddonOptionId) + " - " + addon.Status + '</div><br>'  
            }
        });

        return string;
    }
}


export class CustomTextViewColumn extends Datalistviewcolumn {

    public customText: string | undefined;
    public ShowProperty: string | undefined;
    public excludeZeroValues : boolean = false;

    constructor(header?: string, property?: string, customText?: string, ShowProperty?: string, excludeZeroValues : boolean = false) {
        super(header);
        if (header != null)
            this.Header = header;
        if (property != null)
            this.Property = property;
        if (customText != null)
            this.customText = customText;
        if (ShowProperty != null)
            this.ShowProperty = ShowProperty;
        if (excludeZeroValues != null)
            this.excludeZeroValues = excludeZeroValues;
    }

    public override Render(item: any): string {
        var string = ""
        if(this.ShowProperty == undefined || item[this.ShowProperty]){
            //show the item

            if(this.excludeZeroValues && (item[this.Property] == null || item[this.Property] == 0)){
                return string;
            }

            var string = "<p>" + this.customText?.replace("{0}", item[this.Property]) + "</p>";
        }
        return string;
    }
}

export class ClassedViewColumn extends Datalistviewcolumn {
    public HasModal : boolean = false;
    public ModalName : string | null = null;

    constructor(header?: string, property?: string, maxLength?: number, HasModal : boolean = false, ModalName: string | null = null) {
        super(header);
        if (header != null)
            this.Header = header;
        if (property != null)
            this.Property = property;
        if (maxLength != null)
            this.MaxLength = maxLength;
        if (HasModal != null)
            this.HasModal = HasModal;
        if (ModalName != null)
            this.ModalName = ModalName;
    }

    public OpenModal(item: any): void {
        console.log('Modal opened!');
    }

    public override Render(item: any): string {
        var str = '';
        if (item[this.Property] == null || item[this.Property] == undefined){
            return '';
        }

        if (this.MaxLength != Number.MAX_VALUE) {
            str = item[this.Property].substring(0, this.MaxLength) + "...";

        } else {
            str = item[this.Property];
        }

        var itemClass = (str.split(" ").join("-"));
        return '<button class="button ' + itemClass + '" style="height:2em; width: 10em; text-align: center; pointer-events:none;"  ><p style="font-weight: bold;">' + str + "</p></button>";
    }
}

export class StateViewColumn extends Datalistviewcolumn {
    constructor(header?: string, property?: string) {
        super(header);
        if (header != null)
            this.Header = header;
        if (property != null)
            this.Property = property;
    }

    public override Render(item: any): string {
        var str = '';
        if (item[this.Property] == null || item[this.Property] == undefined){
            return '';
        }

        var state = item[this.Property];

        var severity = (state.Severity === 2 ? "High" : (state.Severity === 1 ? "Medium" : "Low"));
        return '<p style="text-align:end; margin-right:2em; width:6em; font-weight:600; color:#' + state.ForegroundColor + '" class="Text Flash ' + severity +' ">' + state.DisplayName + '<i class="Icon fa fa-' + state.IconName + '" style="font-size: x-large; margin-top: 0px !important; position:absolute;"></i><p>';
    }
}


export class SelectedViewColumn extends Datalistviewcolumn {
    public SelectedItems: any[] = [];
    constructor(header?: string, SelectedItems? : any[]) {
        super(header);

        if (header != null)
            this.Header = header;
        if (SelectedItems != null)
            this.SelectedItems = SelectedItems;
    }

    public override Render(item: any): string {
        let result = this.SelectedItems.filter((x: any) => x.Name == item.Name).length > 0 ? true : false;
        return(result == true ? '<p style="color: green;"><b>&#x2713;</b></p>' : '<p style="color: red;"><b>&#x2715;</b></p>' );

    }
}

export class DateRangeViewColumn extends Datalistviewcolumn {
    public StartDateProperty: string="";
    public EndDateProperty: string="";
    constructor(header?: string, StartDateProperty?: string, EndDateProperty?: string) {
        super(header);

        if (header != null)
            this.Header = header;
        if (StartDateProperty != null)
            this.StartDateProperty = StartDateProperty;
        if (EndDateProperty != null)
            this.EndDateProperty = EndDateProperty;
    }

    public override Render(item: any): string {
        let start = item[this.StartDateProperty];
        let end = item[this.EndDateProperty];

        let startExists = start != null && start != "" && start != undefined;
        let endExists = end != null && end != "" && end != undefined;

        if(startExists && endExists) {
            return '<p>' + item[this.StartDateProperty] + ' <b>&#x2192</b> ' + item[this.EndDateProperty] +'</p>';
        }
        else if (startExists) {
            return '<p> From ' + start + '</p>';
        }
        else if (endExists) {
            return '<p> Until ' + end + '</p>'
        }
        else {
            return '<p> Unlimited </p>';
        } 
    }
}


export class BooleanCheckOrCrossViewColumn extends Datalistviewcolumn {
    public ShowOnlyTrueValues: boolean = false;
    constructor(header?: string, property?: string, ShowOnlyTrueValues? : boolean) {
        super(header);

        if (header != null)
            this.Header = header;
        if (property != null)
            this.Property = property;
        if (ShowOnlyTrueValues != null)
            this.ShowOnlyTrueValues = ShowOnlyTrueValues;
    }

    public override Render(item: any): string {
        if (item[this.Property] == null || item[this.Property] == undefined){
            return '';
        }

        if (this.MaxLength != Number.MAX_VALUE) {
            return item[this.Property].substring(0, this.MaxLength) + "...";

        } 
        else {
            let result = item[this.Property];
            if(this.ShowOnlyTrueValues){
                if(result == true){
                    return '<p style="color: green;"><b>&#x2713;</b></p>';
                }
            }
            else{
                return(result == true ? '<p class="select-item"><b>&#x2713;</b></p>' : '<p style="color: red;"><b>&#x2715;</b></p>' );
            }
        }
        return "";
    }
}

export class BooleanValuesViewColumn extends Datalistviewcolumn {
    public TrueValue: string = "true";
    public FalseValue: string = "false";
    constructor(header?: string, property?: string, trueValue?: string, falseValue?: string) {
        super(header);

        if (header != null)
            this.Header = header;
        if (property != null)
            this.Property = property;
        if (trueValue != null)
            this.TrueValue = trueValue;
        if (falseValue != null)
            this.FalseValue = falseValue;
    }

    public override Render(item: any): string {
        if (item[this.Property] == null || item[this.Property] == undefined){
            return '';
        }

        if (this.MaxLength != Number.MAX_VALUE) {
            return item[this.Property].substring(0, this.MaxLength) + "...";

        } 
        else {
            let result = item[this.Property];
            if(result == true){
                return '<p>' + this.TrueValue + '</p>';
            }
            else{
                return '<p>' + this.FalseValue + '</p>';
            }
        }
        return "";
    }
}

export class IconViewColumn extends Datalistviewcolumn {
    public color: string = '#000000';
    public override: boolean = false;
    constructor(header?: string, property?: string, color?: string, override: boolean = false) {
        super(header);

        if (header != null)
            this.Header = header;
        if (property != null)
            this.Property = property;
        if (color != null)
            this.color = color;
        if (override != null)
            this.override = override;

        this.IsIcon = true;
    }

    public override Render(item: any): string {
        return '<i style="color:#' + (this.override ? this.color :item[this.color]) + '; font-size: 1.5em; margin-top:5px; width:100%; text-align:center" class="Icon fa fa-' + (this.override ? this.Property : item[this.Property]) + '"></i>';
    }
}


export class CurrencyViewColumn extends Datalistviewcolumn {
    public orgService?: OrganizationsService;
    constructor(header?: string, property?: string, organizationService?: OrganizationsService) {
        super(header);

        if (header != null)
            this.Header = header;
        if (property != null)
            this.Property = property;
        if (organizationService != null)
            this.orgService = organizationService;
    }

    public override Render(item: any): string {
        if (item[this.Property] == null || item[this.Property] == undefined){
            return '';
        }
        
        if (item[this.Property] != 0 && !item[this.Property].toString().includes(this.orgService?.OrgCurrencySymbol) && this.orgService?.OrgCurrencySymbol != undefined)
        {
            return this.orgService?.OrgCurrencySymbol + parseFloat(item[this.Property]).toFixed(2);
        }
        else
        {
            return parseFloat(item[this.Property]).toFixed(2);
        }

    }
}

export class DeviceIconcolumn extends Datalistviewcolumn {
    constructor(header?: string) {
        super(header);
    }

    public override Render(item: any): string {
        return '<img style="vertical-align: middle; height: 1.6em;" src="' + Deviceicons.GenerateIconName(item, false) + '"/>';
    }
}

export class DataListFilterColumn extends Datalistviewcolumn {
    constructor(header?: string, property?: string, enableFilter: boolean = false, filterColumn?:string) {
        super();

        if (header != null)
            this.Header = header;
        if (property != null)
            this.Property = property;
        if (enableFilter != false)
            this.EnableFilter = enableFilter;
        if (filterColumn != null)
            this.FilterColumn = filterColumn;
    }
}

export class DatalistRawImageColumn extends Datalistviewcolumn {
    constructor(header?: string, property?: string) {
        super(header, property);
    }

    public override Render(item: any): string {
        if([".mp4", ".m4v", '.webm'].includes(item.Extension)) return "<i class=\"fa fa-film\" style=\"font-size:2.5em;\">";
        return '<img style="vertical-align: middle; height:40px; width:40px" src="data:image/' + item.Extension + ';base64,' + item[this.Property] + '"/>'
    }

    private getImageTag(imageBase64: any): string {
        let extension: string = '';
        const decodedString = window.atob(imageBase64);
        const lowerCase = decodedString.toLocaleLowerCase();
        if (lowerCase.indexOf("png") !== -1) extension = "png";
        else if (lowerCase.indexOf("svg") !== -1) extension = "svg+xml";
        else if (lowerCase.indexOf("jpg") !== -1 || lowerCase.indexOf("jpeg") !== -1) extension = "jpg";
        return extension;

    }

}

export class ColorDisplaycolumn extends Datalistviewcolumn {
    public mediaService : MediaService | undefined;
    constructor(header?: string, property?: string, width?: string, media?: string, mediaService?: MediaService) {
        super();

        this.mediaService = mediaService;

        if (header != null)
            this.Header = header;
        if (property != null)
            this.Property = property;
        if (width != null)
            this.Width = width;
        if (media != null){
            this.MediaId = media;
        }
    }

    public override Render(item: any): string {
        var colorDiv = '<div style="background-color:#' + item[this.Property].replace("#","") + '; width:5em; vertical-align: middle; color:white; text-align:center; height: 1.6em;">'
        if(this.MediaId != "" && item[this.MediaId] != null)
            colorDiv = colorDiv + '<img style="height:1.6em; width:1.6em;" src="' + (this.mediaService != null ? this.mediaService!.GetMediaUrl(item[this.MediaId]) : "") + '"/>'
        colorDiv = colorDiv + '</div>'
        return colorDiv;
    }
}

export class Linkcolumn extends Datalistviewcolumn {
    public LinkText: string | undefined;
    public LinkIcon: string | undefined;
    public Link: string | undefined;
    public ConditionalProperty: string | undefined;
    public ConditionalValue: string | undefined;
    constructor(link?: string, property?: string, linkText: string | null = null, linkIcon: string | null = "far fa-external-link", conditionalProperty?: string, conditionalValue?: string) {
        super();

        if (property != null)
            this.Property = property;
        if(linkText != null)
            this.LinkText = linkText;
        if (link != null)
            this.Link = link;
        if (linkIcon != null)
            this.LinkIcon = linkIcon;
        if (conditionalProperty != null)
            this.ConditionalProperty = conditionalProperty;
        if (conditionalValue != null)
            this.ConditionalValue = conditionalValue;
    }

    public override Render(item: any): string {
        if(this.ConditionalProperty != undefined && this.ConditionalValue != undefined 
            && item[this.ConditionalProperty] != this.ConditionalValue) {
                return '';
            }
            
        var renderedLink = this.Link?.replace('{0}', item[this.Property]);

        var linkContent = "";
        if (this.LinkIcon != undefined)
            linkContent = '<i class="'+this.LinkIcon+'"></i>'
        if (this.LinkText != undefined)
            linkContent += ' ' + this.LinkText;
        return '<a target="_blank" href="'+renderedLink+'">'+linkContent+'</a>';
    }
}

export class PercentagesDisplaycolumn extends Datalistviewcolumn {
    public colorDivs: string  = "";
    public cService;
    constructor(header?: string, property?: string, colorService?: ColorService) {
        super();
        if (header != null)
            this.Header = header;
        if (property != null)
            this.Property = property;
        if(colorService != null){
            this.cService = colorService;
        }
    }

    public override Render(item: any): string {
        this.colorDivs = '<div style="width: 100%;">'
        var items = item[this.Property];
        items.forEach((element: any, index: any) => {
            if(index != (items.length-1))
                element.displayText = element.ThresholdPercent + '% - ' + (items[index+1].ThresholdPercent-1) + '%';
            if(index == (items.length-1))
                element.displayText = element.ThresholdPercent + '% - 100%';
                element.TextClass = this.cService?.GetVisibleTextClassFor(element.Color)

            this.colorDivs = this.colorDivs + '<div class="' + element.TextClass+ '" style="float:left; background-color:#' + element.Color.replace("#","") + '; width:7em; margin-right: 10px; vertical-align: middle; border: 1px solid black; color:white; text-align:center; height: 1.6em; border-radius:5px;">' + element.displayText + '</div>'
        });
        this.colorDivs = this.colorDivs + '</div>'
        return this.colorDivs;
    }
}

export class DatalistviewPercentColumn extends Datalistviewcolumn {
    public DividendProperty: string = ""; //the total number
    public DivisorProperty: string = ""; //the smaller number to calculat % of Dividend
    constructor(header?: string, dividendProperty?: string, divisorProperty?: string) {
        super(header);
        if (dividendProperty != null)
            this.DividendProperty = dividendProperty;
        if (divisorProperty != null)
            this.DivisorProperty = divisorProperty;
    }

    public override Render(item: any): string {
        let dividend = item[this.DividendProperty];
        let divisor = item[this.DivisorProperty];
        if (dividend == 0) {
            return "";
        }
        try {
            let percent = Math.round((divisor / dividend) * 100);
            if (percent > 100) {percent = 100;}
            if (percent < 0) {percent = 0;}
            return percent + "%";
        }
        catch (ex) {
            return "-";
        }

    }
}

export class DatalastviewTextAndConditionalSuffixColumn extends Datalistviewcolumn {
    public Suffix: string = "";
    public ConditionalProperty: string = "";
    public ConditionalValue: any = "";
    constructor(header?: string, property?: string, suffix?: string, conditionalProperty?: string, conditionalValue?: any){
        super(header, property);
        this.Suffix = suffix ?? "";
        this.ConditionalProperty = conditionalProperty ?? "";
        this.ConditionalValue = conditionalValue;
    }

    public override Render(item: any): string {
        var result = super.Render(item);
        if(this.Suffix != "") {
            if(this.ConditionalProperty == "" || item[this.ConditionalProperty] == this.ConditionalValue) {
                result += " " + this.Suffix;
            }
        }

        return result;
    }
}

