import { Component, Injector, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { UntypedFormArray, } from '@angular/forms';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ModelSelectorComponent } from '../../../../shared/modals/ModelSelector/modelselector.component';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { AddVehicleComponent } from 'src/app/features/users/users/modals/add-vehicle/add-vehicle.component';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { IconService } from 'src/app/Services/icon.service';

@Component({
  selector: 'app-editvalettype',
  templateUrl: './editvalettype.component.html',
  styleUrls: ['./editvalettype.component.scss']
})
export class EditValetTypeComponent extends ModelEditor implements OnInit {
  
  public orgService !: OrganizationsService;
  public IconDisplayName: string | null = null;
  public RateSets: any[] | null = null;

  constructor(private modalService: SimpleModalService, private apiService: ApiServiceBase, private injector: Injector, public orgservice: OrganizationsService, public iconService: IconService) {
    super("valet/types", injector);
    this.orgService = orgservice;

    this.apiService.Post<any>("parking/ratesets/searches", { PageNumber: 1, PageSize: 9999, Page: 1 })
    .then(result => {
      this.RateSets = result.Results;
    });
  }

  public override DefaultModel(): any {
    return {
      Name: "",
      IconName: "",
      IconColor: "",
      IsMultiSession: false,
      RequiresBillingKey: false,
      BillingKeyName: "",
      DefaultParkingRateSetId: null
    }
  }

  public override AfterModelLoaded(): void {
    if(this.Form.get('IconName')?.value){
      this.IconDisplayName = this.iconService.findIconName(this.Form.get('IconName')?.value);
    }

    this.Form.get('IconName')?.valueChanges.subscribe((newValue) => {
      this.IconDisplayName = this.iconService.findIconName(newValue);
    });
  }

  public override BeforeSave(): void {

  }

  public override Validators(): any {
  }
}
