<div class="modal-background" [@modalbackground]="Closing ? 'close' : ''"></div>
<div class="modal-card modal-centered" [@modalopen]="Closing ? 'close' : ''">
  <div class="modal-header">
    <h3>Configure Data Set</h3>
    <button class="close-button" (click)="close()">
      <i class="fas fa-times"></i>
    </button>
  </div>
  
  <div class="modal-body scrollable">
    <div class="form-group">
      <div class="chart-type-buttons">
        <button class="chart-type-btn" [class.active]="DataSetRequest.ChartType === 'bar'" (click)="setChartType('bar')">
          <i class="fas fa-chart-column"></i>
        </button>
        <button class="chart-type-btn" [class.active]="DataSetRequest.ChartType === 'line'" (click)="setChartType('line')">
          <i class="fas fa-chart-line"></i>
        </button>
        <button class="chart-type-btn" 
                [class.active]="DataSetRequest.ChartType === 'pie'" 
                (click)="setChartType('pie')"
                *ngIf="isOnlyDataset">
          <i class="fas fa-chart-pie"></i>
        </button>
      </div>
    </div>

    <div class="form-group" style="display: flex; gap: 10px;">
      <div style="flex: 1;">
        <label>Reportable Entity</label>
        <select [(ngModel)]="DataSetRequest.TargetType">
          <option *ngFor="let entity of reportingService.ReportableEntities" [value]="entity.TargetType">
            {{entity.Label}}
          </option>
        </select>
      </div>
      
      <div style="flex: 1;" *ngIf="reportingService.getReportableEntity(DataSetRequest.TargetType)?.IsPeriodEntity">
        <label>View Mode</label>
        <select [(ngModel)]="DataSetRequest.ReportDateGrouping">
          <option value="Concurrent">Concurrent Sessions</option>
          <option value="StartDate">Sessions Started</option>
          <option value="EndDate">Sessions Ended</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label>Metric</label>
      <select [(ngModel)]="DataSetRequest.Metric">
        <option *ngIf="!groupedProperties.length" value="" disabled selected>No Metrics available for selection</option>
        <optgroup *ngFor="let group of groupedProperties" [label]="group.groupName">
          <option *ngFor="let prop of group.properties" [value]="prop.ColumnName">
            {{prop.Label}}
          </option>
        </optgroup>
      </select>
    </div>

    <div class="form-group">
      <label>Aggregation</label>
      <select [(ngModel)]="DataSetRequest.AggregateType" 
              [disabled]="disableAggregation">
        <option value="" disabled selected>Select aggregation type</option>
        <option value="None" *ngIf="!selectedMetric?.CanSum && !selectedMetric?.CanAverage && !selectedMetric?.CanMin && !selectedMetric?.CanMax && !selectedMetric?.CanCount">None</option>
        <option value="Sum" *ngIf="selectedMetric?.CanSum">Sum</option>
        <option value="Average" *ngIf="selectedMetric?.CanAverage">Average</option>
        <option value="Min" *ngIf="selectedMetric?.CanMin">Min</option>
        <option value="Max" *ngIf="selectedMetric?.CanMax">Max</option>
        <option value="Count" *ngIf="selectedMetric?.CanCount">Count</option>
      </select>
    </div>

    <div class="form-group">
      <label>Dataset Label</label>
      <input type="text" [(ngModel)]="DataSetRequest.Label" placeholder="Enter chart label">
    </div>

    <div class="form-group">
      <label>Display Color</label>
      <input type="color" [(ngModel)]="DataSetRequest.DisplayColor" class="color-picker">
    </div>

    <div class="form-group">
      <label>Split By</label>
      <select [(ngModel)]="DataSetRequest.SplitBy">
        <option [ngValue]="undefined">No Split</option>
        <option *ngFor="let splitEntity of reportingService.getSplitByOptions(DataSetRequest.TargetType)" [value]="splitEntity.ColumnName">
          {{splitEntity.Label}}
        </option>
      </select>
    </div>

    <div class="filters-section">
      <label>Filters</label>
      <div class="filter-row" *ngFor="let filter of DataSetRequest.Filters; let i = index">
        <div class="select">
          <select class="input" [(ngModel)]="filter.Property">
              <option *ngFor="let opt of reportingService.getReportableEntity(DataSetRequest.TargetType)?.Filters" [value]="opt.ColumnName">
                {{opt.Label}}
              </option>
          </select>
        </div>
        <div class="select">
          <select class="input" [(ngModel)]="filter.Operator">
            <option value="" disabled selected>Select operator</option>
            <option value="=">=</option>
            <option value="!=">!=</option>
            <option value=">">></option>
            <option value=">=">>=</option>
            <option value="<"><</option>
            <option value="<="><=</option>
            <option value="contains">Contains</option>
            <option value="startswith">Starts With</option>
            <option value="endswith">Ends With</option>
          </select>
        </div>
        <div style="position: relative; flex: 1;">
          <input class="input" type="text" [(ngModel)]="filter.Value" placeholder="value">
          <span *ngIf="getSelectedProperty(filter.Property)?.GroupByBucketLabel" 
                style="position: absolute; right: 8px; top: 50%; transform: translateY(-50%); 
                       background: #eee; padding: 2px 6px; border-radius: 4px; font-size: 0.8em;">
            {{getSelectedProperty(filter.Property)?.GroupByBucketLabel}}
          </span>
        </div>
        
        <button class="remove-filter" (click)="removeFilter(i)">
          <i class="fas fa-times"></i>
        </button>
      </div>
      
      <button class="add-filter" (click)="addFilter()" style="margin-top:10px;">
        <i class="fas fa-plus"></i>
        Add Filter
      </button>
    </div>

    <div class="info-message flash-attention" *ngIf="DataSetRequest.SplitBy || DataSetRequest.Filters?.length" 
         style="color: #666; font-size: 0.9em; margin-top: 16px; padding: 8px; background: #f5f5f5; border-radius: 4px;">
      <i class="fas fa-info-circle"></i> 
      Using splits or filters will disable live updates. You'll need to refresh the chart manually.
    </div>
</div>

  <div class="modal-footer fixed">
    <button class="confirm-button" (click)="confirm()">Confirm</button>
  </div>
</div>

