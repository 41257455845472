import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import * as THREE from "three";
import { Camera, Vector2, Vector3 } from 'three';
import { LevelMesh } from './geometry/level-mesh';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Geo } from 'src/app/util/geo';
import  * as TWEEN from '@tweenjs/tween.js';
import { meshanimationresult } from './geometry/meshanimationresult';

@Component({
  selector: 'app-levelmapviewer',
  templateUrl: './levelmapviewer.component.html',
  styleUrls: ['./levelmapviewer.component.scss']
})
export class LevelmapviewerComponent implements AfterViewInit {

  @Input()
  public LevelId : string = "";
  @Input()
  public ParkingLotId : string = "";

  @ViewChild("canvas")
  public Canvas!: ElementRef;
  private Camera!: THREE.Camera;
  private TextureLoader!: THREE.TextureLoader;
  private Renderer!: THREE.WebGLRenderer;
  private Scene = new THREE.Scene();
  public mixers !: meshanimationresult[];
  private Orbit !: OrbitControls;
  public Levels: LevelMesh[] = [];
  private CurrentLevelIndex = 0;

  constructor(private apiService : ApiService) { }

  ngAfterViewInit(): void {
    this.Scene.background = new THREE.Color(0x49b6c9);
    this.Scene.rotation.x = -90 * Math.PI/180;
    var grid = new THREE.GridHelper( 30, 30, 0x444444, 0x888888 );
    //grid.rotateX(Math.PI / 2); 
    this.Scene.add(grid);
    //THREE.Object3D.DefaultUp.set(0, 0, 1);
    this.Renderer = new THREE.WebGLRenderer({ canvas : this.Canvas.nativeElement, antialias: true });
    this.Renderer.setPixelRatio(1);
    this.Renderer.setSize(600,400);
    //const axesHelper = new THREE.AxesHelper( 5 );
    //this.Scene.add( axesHelper );

    this.apiService.Get<any>("infrastructure/parkinglots/" + this.ParkingLotId + "/geometry").then(result => {
      let level1 = result.Levels[0];
      let levelBounds = Geo.GetPolygonPointBounds(level1);
      let w = levelBounds.Right-levelBounds.Left;
      let x = levelBounds.Left + (w)/2;
      let y = -1*(levelBounds.Top + (levelBounds.Bottom-levelBounds.Top)/2);

      //this.Camera = new THREE.PerspectiveCamera(50, 1, 0.0001, 2000);
      this.Camera = new THREE.OrthographicCamera(levelBounds.Left, levelBounds.Right, 0.1, -0.1, 0.0001, 2000);
      this.Camera.up.set( 1, 0, 0,  );
      const controls = new OrbitControls(this.Camera, this.Renderer.domElement);
      controls.screenSpacePanning = false;
      this.Camera.position.x = 6*y;
      this.Camera.position.y = 6*y;
      this.Camera.position.z = 0.6;
      //this.Camera.up = new THREE.Vector3( 0, 0, 1 );
      this.Orbit = controls;

      const light = new THREE.PointLight( 0xffffff, 4, 3 );
      light.position.set( -1, 1, 1 );
      this.Scene.add( light );
      const light2 = new THREE.PointLight( 0xffffff, 5, 3 );
      light2.position.set( levelBounds.Right + 1, -1*levelBounds.Bottom - 1, 1 );
      this.Scene.add( light2 );

      for(let i=0; i < result.Levels.length; i++){
        let level = result.Levels[i];
        if(level.PolygonPoints != null && level.PolygonPoints.length > 1){
          let l = new LevelMesh(this.Scene, level, new Vector3(0,0, 0.02 * i), 0.01, new Vector2(1,1), this.Camera, this.mixers, {ShowAccessControl: false, ShowSpaces: true, ShowShapes: true, ShowWalls:true, ShowGardens:false});
          this.Levels.push(l);
        }
      }

      if(this.Levels.length> 1){
        for(let i=1; i < this.Levels.length; i++){
          this.Levels[i].MoveUp(0.5);
        }
      }

      // const levelBoundingBox = new THREE.Box3();
      // levelBoundingBox.setFromObject(l);
      // const levelCenter = new Vector3();
      // levelBoundingBox.getCenter(levelCenter);
      // const levelSize = new Vector3();
      // levelBoundingBox.getSize(levelSize);

      // const maxDim = Math.max( levelSize.x, levelSize.y, levelSize.z );
      controls.target = new Vector3(.04,.04,0);
      const axesHelper = new THREE.AxesHelper( 10 );
      this.Scene.add( axesHelper );
      this.MainRenderLoop();
    });
  }

  


  private MainRenderLoop(){
    let renderer = this.Renderer;
    let scene = this.Scene;
    let camera = this.Camera;
    let controls = this.Orbit;
    (function render() {
      if(controls)
        controls.update();
      TWEEN.update();
      requestAnimationFrame(render);
      renderer.render(scene, camera);
    }());
  }

  public ShowLevel(index : number){
   
    if(this.CurrentLevelIndex == index){
      return;
    }
    else if(this.CurrentLevelIndex < index){
      for(let i=this.CurrentLevelIndex+1; i <= index; i++){
        this.Levels[i].MoveDown(0.5);
      }
    }
    else{
      for(let i=index+1; i <= this.CurrentLevelIndex; i++){
        this.Levels[i].MoveUp(0.5);
      }
    }
    this.CurrentLevelIndex = index;
  }

}
