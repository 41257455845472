import { LevelEditorComponent } from "../../../leveleditor.component";
import { MapItemTool } from "./MapItemTool";

export class PedestrianZoneTool extends MapItemTool{
    public override ItemWidth: number = 110;
    public override ItemHeight: number = 70;
    public override Type = "PedestrianZone";
    public override OutlineColor = "b5a304";
    public override Opacity =  0.8;
    public override FillColor =  "fce303";
    public override OutlineThickness = 2;
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }
}
