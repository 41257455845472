
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { LevelEditorComponent } from "../../../leveleditor.component";
import { v4 as uuidv4 } from 'uuid';
import { PolygonTool } from "../PolygonTool";
import { MapItem } from "../../../components/PolygonComponents/MapItem";

export class MapItemTool extends PolygonTool {
    public Type =  "MapItem";
    public Opacity = 1;
    public OutlineThickness = 1;
    public OutlineColor = "FFFFFF";
    public FillColor = "FFFFFF";
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }

    public override CreateNew(canvasX : number, canvasY: number){
        let items = this.editor.ModelEditor.FormArray(this.editor.Form, "MapItems");
        let newMapItemFormGroup = new FormGroup({
            Id: new FormControl(uuidv4()),
            Name: new FormControl(this.Type),
            BusinessDirectoryId: new FormControl(null),
            BusinessDirectoryName: new FormControl(null),
            DisplayAngle: new FormControl(0),
            EntrySegment: new FormControl(null),
            ExitSegment: new FormControl(null),
            FillColor: new FormControl(this.FillColor),
            FillPattern: new FormControl(null),
            GeoPolygonPoints: new FormControl(null),
            IsRising: new FormControl(null),
            Opacity: new FormControl(this.Opacity),
            OutlineColor: new FormControl(this.OutlineColor),
            OutlineThickness: new FormControl(this.OutlineThickness),
            ParkingLotLevelNumber: new FormControl(null),
            Type: new FormControl(this.Type),
            PolygonPoints: new FormControl(this.CreatePolygonFromXY(canvasX, canvasY))
        });

        (items as FormArray).push(newMapItemFormGroup);

        var component = new MapItem(this.editor, this.canvas, newMapItemFormGroup, null);
        this.editor.Form.markAsDirty();
        return component;
    }
}