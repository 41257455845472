import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-treenode',
  templateUrl: './treenode.component.html',
  styleUrls: ['./treenode.component.scss']
})
export class TreenodeComponent implements OnInit {

  @Input()
  public Text : string = "";
  @Input()
  public Image : string | null = null;
  @Input()
  public Expanded : boolean = false;
  @Input()
  public CanExpand : boolean = true;
  @Input() Selected : boolean = false;

  @Input()
  public CanDelete : boolean = false;

  @Output() OnClicked = new EventEmitter<any>();
  @Output() OnDoubleClicked = new EventEmitter<any>();
  @Output() OnDelete = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public Toggle(){
    this.Expanded = !this.Expanded;
  }

  public HandleClick(evt : any){
    this.OnClicked.emit(evt);
  }
  public HandleDoubleClick(evt : any){
    this.OnDoubleClicked.emit(evt);
  }
  public HandleDelete(evt : any){
    this.OnDelete.emit();
  }

}
