<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">

      <header class="modal-card-head">
        <p class="modal-card-title">Select {{ModelType}}</p>
      </header>
      <section class="modal-card-body" style="min-width: 800px;">
          <div class="taglist" *ngIf="MultiSelect">
            <app-field Label="Selected Items" [HasAddons]="true" tippy="">
              <div class="control">
                  <div class="field is-grouped is-grouped-multiline">
                      <div class="tags has-addons" *ngFor="let x of SelectedItems; let i = index">
                        <a class="tag">{{x[DisplayColumn]}}</a>
                        <a class="tag" (click)="RemoveSelectedItem(i)"><i class="fa fa-trash"></i></a>
                      </div>
                  </div>
              </div>
            </app-field>
          </div>

            <app-datalistview #DataList [Columns]="Columns" 
            [ApiUrl]="ApiUrl"
            [PageSize]="10"
            [ShowPaging]="true"
            [AllowSelectBoxes]="MultiSelect"
            [AllowSelect]="!MultiSelect"
            (SelectBoxChanged)="SelectBoxChanged($event)"
            [SelectedItems]="SelectedItems"
            (ItemSelected)="ItemSelected($event)">
            <app-filter-set>
              <div app-search-filter></div>
              <div app-search-button></div>
            </app-filter-set>
          </app-datalistview>
        
      </section>
      <footer class="modal-card-foot">
        <button *ngIf="MultiSelect" class="button is-primary" (click)="confirm()">Confirm</button>
        <button class="button is-danger" (click)="close()">Cancel</button>
      </footer>
    </div>
  </div>