<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
<app-pageheader [Parent]="this">
    <app-form-save-button [Parent]="this" [Form]="Form" class="button is-primary" (OnSave)="Save()">
    </app-form-save-button>
</app-pageheader>

<article class="panel">
  <p class="panel-heading">
    Role Details
  </p>
  <div class="panel-block"  style="display: block; padding-bottom:3em;">
    <app-field Label="Role Name">
        <div class="control">
            <input formControlName="Name" class="input" type="text" />
            <app-validator [For]="this.Form.get('Name')">
            </app-validator>
        </div>
    </app-field>
    <app-field Label="Role Type">
      <div class="control">
        <input value="{{Form.get('IsAdminRole')?.value ? 'Admin Role' : 'End User Role'}}" class="input" type="text" disabled />
      </div>
    </app-field>
    <app-field *ngIf="Form.get('IsAdminRole')?.value == true" Label="Permissions">
        <app-tabs>
          <app-tab Title="Permissions">
            <div class="columns">
              <div class="column is-5">
                <div style="margin-bottom:10px;">
                  <span><a (click)="ExpandAll()">Expand All</a><a> | </a>
                  <a (click)="CollapseAll()" >Collapse All</a><a> | </a>
                  <a (click)="ExpandSelected()" >Expand Selected</a></span>
                </div>
                  <app-tree formArrayName="Features">
                      <app-treenoderecursive [recursiveList]="features" (UpdateItem)="UpdateAdminSelectedItem($event)" (ItemClicked)="ClickItem($event)" (UpdateItemSelectedValue)="UpdateAdminItemSelectedValue($event)">
                      </app-treenoderecursive>
                  </app-tree>
              </div>
              <div class="column is-7">
                <div class="box content" style="height:100%">
                  <h3 class="has-text-weight-bold">{{SelectedFeatureName}}</h3>
                  <p class="has-text-weight-light- is-italic">{{SelectedFeatureDefinition}}</p>
                  <h6 *ngIf="SelectedFeatureRoutes != null && SelectedFeatureRoutes.length > 0">Enables Menu Items</h6>
                  <ul *ngIf="SelectedFeatureRoutes != null && SelectedFeatureRoutes.length > 0">
                    <li *ngFor="let i of SelectedFeatureRoutes">
                      {{i.MenuPath}}
                    </li>
                  </ul>
                  <h6 *ngIf="SelectedFeaturePrerequisites != null && SelectedFeaturePrerequisites.length > 0">Feature Prerequisites</h6>
                  <ul *ngIf="SelectedFeaturePrerequisites != null && SelectedFeaturePrerequisites.length > 0"> 
                    <li *ngFor="let i of SelectedFeaturePrerequisites">
                      {{i.Name}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </app-tab>
        </app-tabs>
      </app-field>
  </div>
</article>
