export class Geometry2d {

    public static DirectionFromPoint(pointTo : number[], pointFrom : number[]){
        let x = pointTo[0] - pointFrom[0];
        let y = pointFrom[1] - pointTo[1];
        let angle = Math.atan2(y, x) + Math.PI;

        let directions = ["w", "sw", "s", "se", "e", "ne", "n", "nw", "w"];
        let index = Math.round((angle) / (Math.PI / 4));
        return directions[index];
    }
    public static AngleFromPoint(pointTo : number[], pointFrom : number[]){
        return (Math.atan2(pointTo[1] - pointFrom[1], pointTo[0] - pointFrom[0]) * 180 / Math.PI)+90;
    }
    public static DistanceBetweenPoints(pointTo : number[], pointFrom: number[]) : number{
        return Math.sqrt( Math.pow((pointFrom[0]-pointTo[0]), 2) + Math.pow((pointFrom[1]-pointTo[1]), 2) );
    }
    public static RotatePointsAroundOrigin(points: number[][], origin : number[], angleDegrees : number) : number[][]{
        let result = [];
        for(let point of points){
            result.push(this.RotatePointAroundOrigin(point, origin, angleDegrees));
        }
        return result;
    }
    public static RotatePointAroundOrigin(point: number[], origin: number[], angleDegrees : number) : number[]{
        if(angleDegrees == 0)
            return point;
        var radians = (Math.PI / -180) * (angleDegrees-90); //offset 90 degrees to make 0 degrees = north
        var cos = Math.cos(radians);
        var sin = Math.sin(radians);
        var nx = (cos * (point[0] - origin[0])) + (sin * (point[1] - origin[1])) + origin[0];
        var ny = (cos * (point[1] - origin[1])) - (sin * (point[0] - origin[0])) + origin[1];
        return [nx, ny];
    }

    public static FindRectangleRotation(points: number[][]) : number{
        let sideLength = this.DistanceBetweenPoints(points[1], points[0]);
        let topLength = this.DistanceBetweenPoints(points[1], points[points.length-1]);
        if(sideLength > topLength){
            //vertical orientation
            let angle = this.AngleFromPoint(points[1], points[0]);
            return angle;
        }
        else{
            //horizontalorientation
            let angle = this.AngleFromPoint(points[1], points[points.length-1]);
            return angle;
        }
    }
}
