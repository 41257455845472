import { FormControl } from "@angular/forms";
import { PolygonMapComponent } from "../../components/PolygonComponents/PolygonMapComponent";
import { LevelEditorComponent } from "../../leveleditor.component";
import { Tool } from "../tool";
import { FabricHelpers } from "../../fabric.js/helpers";
import { MapComponentBase } from "../../components/MapComponentBase";

export class PolygonTool extends Tool {
    public polygon !: fabric.Polygon; 
    public Locked : boolean = false;
    public ItemWidth: number = 0;
    public ItemHeight: number = 0;

    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas) {
        super(editor, canvas);
    }

    public override CreateNew(canvasX : number, canvasY: number): MapComponentBase | null {
        return new PolygonMapComponent(this.editor, this.canvas, new FormControl(), null);
    }

    public CreatePolygonFromXY(x: any, y: any){
        // Get the current zoom level of the canvas
        var points = FabricHelpers.GetAdjustedXY(this.canvas, x, y);
        var zoom = this.canvas.getZoom();
    
        var point1 = [(points[0] - (this.ItemWidth / 2)), (points[1] - (this.ItemHeight / 2))];

        var point2 = [point1[0], (point1[1] + this.ItemHeight)];
        point2[0] = point2[0]/this.ScaleFactor / zoom;
        point2[1] = point2[1]/this.ScaleFactor / zoom;

        var point3 = [(point1[0] + this.ItemWidth), (point1[1] + this.ItemHeight)];
        point3[0] = point3[0]/this.ScaleFactor / zoom;
        point3[1] = point3[1]/this.ScaleFactor / zoom;
        
        var point4 = [(point1[0] + this.ItemWidth), point1[1]];
        point4[0] = point4[0]/this.ScaleFactor / zoom;
        point4[1] = point4[1]/this.ScaleFactor / zoom;

        point1[0] = point1[0]/this.ScaleFactor / zoom;
        point1[1] = point1[1]/this.ScaleFactor / zoom;

        return [point1, point2, point3, point4];
    }
}