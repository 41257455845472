import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';

@Component({
  selector: 'app-editgateway',
  templateUrl: './editgateway.component.html',
  styleUrls: ['./editgateway.component.scss']
})
export class EditGatewayComponent extends ModelEditor implements OnInit {

  public DisplayRules: any = [];
  private searchResults: any = { PageNumber: 1, PageSize: 0, Results: null };
  public FirmwareVersions: any = [];
  public InitialGateway: any | null = null;

  constructor(private apiService: ApiService, public organizationsService: OrganizationsService, private route: ActivatedRoute, private toastService: ToastrService, private modalService: SimpleModalService, private injector: Injector) {
    super("infrastructure/gatewayconfigurations", injector);
  }
  
  public override DefaultModel() {
    return {};
  }
  
  public override AfterModelLoaded(): void {
    if(this.Model.Device != null){
      this.apiService.Get("hardware/versions/"+this.Model.Device.HardwareVersionId+"/firmware").then(result => {
        if (result != null) {
          this.FirmwareVersions = result;
        }
      });
    }
  }
  
  public override BeforeSave(): boolean | void {
  }
  public override Validators() {
  }
  
}
