<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-delete-button [Title]="'Validation User'" *ngIf="ModelIsNew==false" [Parent]="this" [Form]="Form" [Model]="Model" [DeleteCheck]="false"
        [DeleteUrl]="DeleteUrl" [DeleteNavLink]="DeleteNavLink" Api="Parking" TypeName="ValidationUser">
        </app-form-delete-button>
        <app-form-save-button [Parent]="this" [Form]="Form" (OnSave)="Save()"></app-form-save-button>
    </app-pageheader>
    <div class="columns">
        <div class="column is-6">
            <article class="panel">
                <p class="panel-heading">
                    Validation User Details
                </p>
                <div class="panel-block" style="display:block;">
                    <app-field Label="Validation Profile" *ngIf="Model.ValidationProfileName != ''">
                        <div class="control mb-3">
                            <input value="{{Model.ValidationProfileName}}" class="input is-fullwidth is-static" readonly />
                        </div>
                    </app-field>
                    <app-field Label="Name">
                        <div class="control mb-3">
                            <input formControlName="Name" class="input is-fullwidth"/>
                            <app-validator [For]="this.Form.get('Name')"></app-validator>
                        </div>
                    </app-field>
                    <app-field Label="Owner">
                        <app-singleuserselector formControlName="UserId" [User]="InitialUser" [AllowCreateUser]="false">
                        </app-singleuserselector>
                    </app-field>
                    <app-field Label="Send to User" [Visible]="Form.get('UserId')?.value != null" class="control mb-3">
                        <span class="select is-fullwidth">
                            <select formControlName="SendToUser">
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </span>
                    </app-field>
                    <app-field Label="Send to Specified Email" class="control mb-3">
                        <span class="select is-fullwidth">
                            <select formControlName="SendToEmail">
                                <option [ngValue]="true">Yes</option>
                                <option [ngValue]="false">No</option>
                            </select>
                        </span>
                    </app-field>
                    <app-field Label="Email Address" [Visible]="Form.get('SendToEmail')?.value != false">
                        <div class="control mb-3">
                            <input formControlName="EmailAddress" class="input is-fullwidth" />
                        </div>
                    </app-field>
                    <app-field Label="Document" class="control mb-3">
                        <span class="select is-fullwidth">
                            <select formControlName="DocumentTemplateId">
                                <option *ngFor="let item of DocumentTemplates" value="{{item.Id}}">{{item.Name}}</option>
                            </select>
                        </span>
                    </app-field>
                </div>
            </article>
            
        </div>
    </div>
</div>