<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Form]="Form" [Parent]="this" (OnSave)="Save()"></app-form-save-button>
    </app-pageheader>

    <div class="columns">
        <div class="column is-6">
            <app-field Label="Event Name:">
                <div class="control">
                    <input formControlName="EventName" class="input" type="text"/>
                    <app-validator [For]="this.Form.get('EventName')" ></app-validator>
                </div>
            </app-field>
            <app-field Label="Auto Resolve Event:">
                <div class="control">
                    <input formControlName="AutoResolveEvent" class="input" type="text"/>
                    <app-validator [For]="this.Form.get('AutoResolveEvent')" ></app-validator>
                </div>
            </app-field>
            <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Recipients</label>
                </div>
                <div class="field-body">
                    <div class="field is-grouped is-grouped-multiline">
                        <div class="control" *ngFor="let p of FormArray(Form, 'RoleRecipients').controls; let i = index;">
                            <div class="tags has-addons">
                                <a class="tag" (click)="EditRecipientRole(i)">Role: {{p.get('Name')?.value}}</a>
                                <a class="tag is-delete"(click)="RemoveAlertTriggerRecipientRole(i)"></a>
                            </div>
                        </div>
                        <div class="control" *ngFor="let p of FormArray(Form, 'UserRecipients').controls; let i = index;">
                            <div class="tags has-addons">
                                <a class="tag" (click)="EditRecipientUser(i)">User: {{p.get('Name')?.value}}</a>
                                <a class="tag is-delete"(click)="RemoveAlertTriggerRecipientUser(i)"></a>
                            </div>
                        </div>
                        <div class="control">
                            <div class="tags">
                                <a class="tag is-success" (click)="AddRecipientUser()">Add User</a>
                                <a class="tag is-success" (click)="AddRecipientRole()">Add Role</a>
                            </div>
                        </div>
                    </div>
                  </div>
                </div> 
        </div>
    </div>
</div>