import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-editcontrolledareabarrier',
  templateUrl: './editcontrolledareabarrier.component.html',
  styleUrls: ['./editcontrolledareabarrier.component.scss']
})
export class EditcontrolledareabarrierComponent extends SimpleModalComponent<any, null> {
  Form !: UntypedFormGroup;
  constructor() {
    super();
  }

  confirm() {
    this.close();
  }
}