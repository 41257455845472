import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { Busyable } from 'src/app/shared/editors/busyable';


@Component({
  selector: 'app-lanediagnosticshome',
  templateUrl: './lanediagnosticshome.component.html',
  styleUrls: ['./lanediagnosticshome.component.scss']
})
export class LanediagnosticshomeComponent extends Busyable implements OnInit {
  public parkingLots: any[] = [];
  public expandedLots: { [key: string]: boolean } = {};

  constructor(private apiService: ApiServiceBase, private toastService: ToastrService) {
    super();
  }

  ngOnInit(): void {
    this.Loading();
    this.loadLanes();
  }

  private async loadLanes() {
    try {
      const response = await this.apiService.Get<any>("infrastructure/diagnostics/lanes");
      this.parkingLots = response.ParkingLots;
      this.parkingLots.forEach(lot => this.expandedLots[lot.Id] = false);
      this.StopLoading();
    } catch (error) {
      this.toastService.error('Error loading parking lots');
      this.StopLoading();
    }
  }

  toggleLot(lotId: string): void {
    this.expandedLots[lotId] = !this.expandedLots[lotId];
  }
}
