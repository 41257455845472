import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { MediaService } from 'src/app/Services/media.service';

export interface NewMediaRequest {
  RequiredFileType: string;
  ImageCategory?: string;
  OverrideUploadUrl ?: string | null;
}

@Component({
  selector: 'app-medianew',
  templateUrl: './medianew.component.html',
  styleUrls: ['./medianew.component.scss']
})
export class MedianewComponent extends SimpleModalComponent<NewMediaRequest, any> implements NewMediaRequest {

  public previewFile?: SafeUrl
  public selectedFile?: any;
  public fileInvalid: boolean = false;
  public isImageFile: boolean = true;
  public RequiredFileType: string = 'image/*';
  public OverrideUploadUrl ?: string | null;
  public ImageCategory?: string;

  mediaUploadRequest: any = {}
  constructor(private apiService: ApiServiceBase, private mediaService: MediaService, private domSanitizer: DomSanitizer, private toastr: ToastrService) {
    super();
  }


  MediaUploaded(obj: any, mediaId: any) {
    obj.result = mediaId;
    obj.close();
  }

  uploadMedia(category?: string) {
    var fileByteArray: number[] = [];
    var reader = new FileReader();
    var mediaService: MediaService = this.mediaService;
    var toastrService: ToastrService = this.toastr;
    var file: any = this.selectedFile;
    var mediaUploaded = this.MediaUploaded;
    var self = this;
    var invalid = false;

    reader.onload = function() {
      var arrayBuffer = this.result as ArrayBuffer,
        array = new Uint8Array(arrayBuffer)
        if(array.length > 10485760) {
          toastrService.error('File exceeds max size (10MB).\r\nPlease try a different file.');
          invalid = true;
        } else {
          invalid = false;
          for (var i = 0; i < array.length; i++) {
            fileByteArray.push(array[i]);
          }
          mediaService.UploadMedia(fileByteArray, file.name, category, undefined, self.OverrideUploadUrl).subscribe(result => {
            var extn = file.name.split('.').at(-1);
            mediaUploaded(self, result + '.' + extn);
          });
        }
    }
    var x = reader.readAsArrayBuffer(this.selectedFile);
    this.fileInvalid = invalid;
  }

  onFileSelected(event: any): void {
    var file = event.target.files[0] as File;
    this.selectedFile = file;
    if(!file.type.includes("image") && !['video/mp4', 'video/webm'].includes(file.type)) {
      this.fileInvalid = true;
      this.toastr.error("The file you have selected is not supported.");
      return;
    }
    if(!file.type.includes("image")) this.isImageFile = false;
    if(this.selectedFile.size > 10485760){
      this.fileInvalid = true;
      this.toastr.error("The selected file is too big.\r\nPlease choose a file less than 10MB in size.");
      return;
    } else {
      this.fileInvalid = false;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      if (reader.result != null) {
        this.previewFile = this.domSanitizer.bypassSecurityTrustUrl(reader.result as string);
      }
    }
  }
}

