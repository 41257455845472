<app-loader [Parent]="this"></app-loader>
<form>
    <div [formGroup]="Form" *ngIf="FormLoaded">
        <app-pageheader [Title]="Form.get('Name')?.value" [ModelId]="Form.get('Id')?.value" [Form]="Form">
            <app-form-save-button [Form]="Form" [Parent]="this" (OnSave)="Save()"></app-form-save-button>
        </app-pageheader>

        <div class="columns">
            <div class="column is-6">
                <div class="panel">
                    <p class="panel-heading">
                        Plate Score Settings
                    </p>
                    <div class="panel-block" style="display: block;">
                        <app-field Label="Valid Plate Length" [HasAddons]="true" tippy="The minimum and maximum length plates that will be accepted as a plate read from a camera">
                            <div class="control">
                                <input class="input" type="number" min="0" max="20" step="1"
                                    formControlName="MinPlateLength" >
                            </div>
                            <div class="control">
                                <button class="button is-static"> to </button>
                            </div>
                            <div class="control">
                                <input class="input" type="number" min="0" max="20" step="1"
                                    formControlName="MaxPlateLength">
                            </div>
                            <div class="control">
                                <button class="button is-static">characters</button>
                            </div>
                        </app-field>
                        <app-field Label="New Plate Read Score" [HasAddons]="true" tippy="The score to help determine if a plate read is for the same vehicle again, or for another vehicle. If the first read's 'score' against the new read is higher than this setting then it will be treated as another read for the same vehicle">
                            <div class="control">
                                <input class="input" type="number" min="0" max="100" step="1"
                                    formControlName="SequentialReadSamePlateScoreThreshold">
                            </div>
                            <div class="control">
                                <button class="button is-static">%</button>
                            </div>
                        </app-field>
                        <app-field Label="Plate Search Score" [HasAddons]="true" tippy="The score requirement to find a match for a given plate against a list of potential matches. Used to determine if plates belong to Access Lists and to find existing sessions for a newly read plate">
                            <div class="control">
                                <input class="input" type="number" min="0" max="100" step="1"
                                    formControlName="PlateSearchScoreThreshold">
                            </div>
                            <div class="control">
                                <button class="button is-static">%</button>
                            </div>
                        </app-field>
                        <app-field Label="Missing Character Penalty" tippy="The penalties to apply to match scoring when one plate is shorter than the other. If a comma separated list of values is given it will be treated as an array, e.g. if plate A is 2 characters shorter than plate B then the 2nd array value will be used as the penalty.">
                            <div class="control">
                                <input class="input" type="text" formControlName="MissingCharacterPenalty">
                            </div>
                        </app-field>
                        <app-field Label="Camera Min Confidence" [HasAddons]="true" tippy="The confidence percentage from the camera required to treat a read as a potential valid plate. If a camera gives a read with confidence below this value then it will be treated as a vehicle detection but not a plate read.">
                            <div class="control">
                                <input class="input" type="number" min="0" max="100" step="1"
                                    formControlName="CameraPlateConfidenceThreshold">
                            </div>
                            <div class="control">
                                <button class="button is-static">%</button>
                            </div>
                        </app-field>
                    </div>
                </div>
                <div class="panel">
                    <p class="panel-heading">
                        Vehicle Camera Tracking
                    </p>
                    <div class="panel-block" style="display: block;">
                        <app-field Label="Snapshot Interval" [HasAddons]="true" tippy="For cameras that require snapshots to continue tracking a vehicle after initial detection. This value is the interval, in milliseconds, between snapshots requested from the camera to track the vehicles transition through the gate and get improved plate reads.">
                            <div class="control">
                                <input class="input" type="number" min="100" max="3600000" step="1"
                                    formControlName="SnapshotInterval">
                            </div>
                            <div class="control">
                                <button class="button is-static">ms</button>
                            </div>
                        </app-field>
                        <app-field Label="Tracking Timeout" [HasAddons]="true" tippy="Timeout to set a tracked vehicle as gone if no update snapshots have been received from a camera for the timeout period.">
                            <div class="control">
                                <input class="input" type="number" min="100" max="3600000" step="1"
                                    formControlName="TrackingTimeout">
                            </div>
                            <div class="control">
                                <button class="button is-static">ms</button>
                            </div>

                        </app-field>
                        <app-field Label="Min Vehicle Duration" [HasAddons]="true" tippy="The minimum period that a vehicle will be tracked for. Any conflicting reads from cameras for this period after a new vehicle is detected will be considered reads for the same vehicle and the highest confidence plate read will be set as the vehicles plate.">
                            <div class="control">
                                <input class="input" type="number" min="0" max="3600000" step="1"
                                    formControlName="AcceptNonMatchedReadsTimeout">
                            </div>
                            <div class="control">
                                <button class="button is-static">ms (High Confidence)</button>
                            </div>
                        </app-field>
                        <app-field Label="" [HasAddons]="true" tippy="The minimum period that a vehicle will be tracked for if the first plate read was Low Confidence (&lt; {{Form.get('CameraPlateConfidenceThreshold')?.value}}%) to give the tracker more time to get an accurate plate read.">
                            <div class="control">
                                <input class="input" type="number" min="0" max="3600000" step="1"
                                    formControlName="AcceptNonMatchedReadsLowConfidenceTimeout">
                            </div>
                            <div class="control">
                                <button class="button is-static">ms (Low Confidence)</button>
                            </div>
                        </app-field>
                        <app-field Label="Max Vehicle Duration" [HasAddons]="true" tippy="The maximum duration in milliseconds to track a vehicle for before looking for the next vehicle.">
                            <div class="control">
                                <input class="input" type="number" min="0" max="3600000" step="1"
                                    formControlName="ExpectedMaxTrackingDuration">
                            </div>
                            <div class="control">
                                <button class="button is-static">ms</button>
                            </div>
                        </app-field>
                        <app-field Label="Stationary IOU Threshold" [HasAddons]="true" tippy="The IOU (Intersection Over Union) value to determine if a vehicle is moving or stationary.">
                            <div class="control">
                                <input class="input" type="number" min="0" max="100" step="1"
                                    formControlName="VehicleStationaryIOUThreshold">
                            </div>
                            <div class="control">
                                <button class="button is-static">%</button>
                            </div>
                        </app-field>
                    </div>
                </div>
            </div>
            <div class="column is-6">
                <div class="panel">
                    <p class="panel-heading">
                        Snapshot Storage
                    </p>
                    <div class="panel-block" style="display: block">
                        <app-field Label="Store on Device" tippy="If enabled then terminals and other devices using cameras will attempt to store the snapshot images of vehicles in their local storage. The duration that images are stored for will vary by device depending on storage size.">
                            <div class="control">
                                <div class="select">
                                    <select formControlName="StoreCameraSnapshotsLocal">
                                        <option [ngValue]="true">Yes</option>
                                        <option [ngValue]="false">No</option>
                                    </select>
                                </div>
                            </div>
                        </app-field>
                        <app-field Label="Store in Cloud" tippy="If enabled then terminals and other devices using cameras will attempt to upload compressed snapshot images of vehicles to cloud storage.">
                            <div class="control">
                                <div class="select">
                                    <select formControlName="StoreCameraSnapshotsCloud">
                                        <option [ngValue]="true">Yes</option>
                                        <option [ngValue]="false">No</option>
                                    </select>
                                </div>
                            </div>
                        </app-field>
                    </div>
                </div>
            </div>
        </div>

    </div>
</form>