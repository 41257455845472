import { MeshConstants } from "src/app/util/mesh-constants";
import * as THREE from "three";
import { MeshBasicMaterial, MeshLambertMaterial, Object3D, Scene, Vector2, Vector3 } from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
import { LevelTextMesh } from "./level-text-mesh";
import { DeviceBox } from "./devicebox";
import  * as TWEEN from '@tweenjs/tween.js';
import { LabelMesh } from "./label-mesh";
import { LevelitemMesh } from "./levelitem-mesh";
import { meshanimationresult } from "./meshanimationresult";

export class LevelObjectMesh extends LevelitemMesh {
    public raycaster = new THREE.Raycaster();
    public mouse = new THREE.Vector2();
    private Animations : any = { };
    public ResultingMesh : Object3D|null = null;
    public scene = new THREE.Scene();
    
    public gltf: any;
    public mixer !: THREE.AnimationMixer;
    public lastAction !: THREE.AnimationAction
    public clock = new THREE.Clock();
    private SpecifiedColors:any = {};

    private RandomColors : any[] = [0xc41704, 0x017509, 0x032a9e, 0x383838, 0xadacac];

    constructor(scene: Scene, obj: any, offset: Vector3, scale: Vector2, objectType: string, addLabel: boolean, device : any, mixers: meshanimationresult[]) {
        super(device);
        this.type = objectType;
        let self = this;
        // load the object
        let loader = new GLTFLoader();
        if(objectType == null || objectType == ''){
            return;
        }

        let file = objectType;

        if(this.type == 'froggatev1'){
            if(device.OpenDirection == "Right"){
                file = file + '_right';
            }
            else{
                file = file + '_left';
            }
        }

        loader.load('/assets/3d-models/' + file + '.glb',  (gltf :any) => {
            // adjust the size of the object to add into the scene
            let factor = MeshConstants.scaleFactor;
            this.gltf = gltf;
            this.scene = scene;


            if(this.gltf.animations.length > 0){
                this.mixer = new THREE.AnimationMixer(this.gltf.scene);
                mixers.push(new meshanimationresult(this.mixer, this.clock));

                if(this.Item.ClassName == "Barrier"){
                    if(this.Item.BarrierStatus){
                        this.HandleEvent(this, "BarrierOpened", false);
                    } else {
                        this.HandleEvent(this, "BarrierClosed", false);
                    }
                }
            }

            const box = new DeviceBox().setFromObject(gltf.scene);
            box.Device = device;

            const size = box.getSize(new THREE.Vector3())

            gltf.scene.position.x = obj.LocationPoints[0] * scale.x + offset.x;

            gltf.scene.position.y = -1 * (obj.LocationPoints[1] * scale.y + offset.y);
            gltf.scene.position.z = MeshConstants.floorThickness + MeshConstants.bevelThickness;


            // const light = new THREE.PointLight( 0xFFFFFF, 0.1, 100 );
            // light.position.set( gltf.scene.position.x, gltf.scene.position.y, gltf.scene.position.z );
            // scene.add( light );

            gltf.scene.rotation.x = Math.PI / 2;
            // as we using differenrt coordinate system, apply the rotation to different direction

            if (obj.DisplayAngle == 0) {
                //gltf.scene.rotation.y = 0;
            } else {

                gltf.scene.rotation.y = ((-1*obj.DisplayAngle) * (Math.PI / 180))
            }
            gltf.scene.Device = device;
            gltf.scene.scale.set(factor, factor, factor)
            scene.add(gltf.scene);
            self.ResultingMesh = gltf.scene;
            self.InitObjectAnimation(gltf.scene);

            if(addLabel)
            {
                new LabelMesh(scene, gltf.scene, objectType);
            }

            for(const i in this.SpecifiedColors){
                for(let child of this.gltf.scene.children){
                    if(child.name == i){
                        if(child.material == undefined && child.children != null){
                            child.children[0].material = new MeshLambertMaterial({ color: this.SpecifiedColors[i] as number});
                        }else{
                            child.material = new MeshLambertMaterial({ color: this.SpecifiedColors[i] as number});  
                        }
                    }
                }
            }
        }, undefined, function (err : any) {
            console.log(err);

        }); 
    }

    public SetColor(name:string, color: number){
        this.SpecifiedColors[name] = color;
        if(this.gltf != null && this.gltf.scene != null){
            for(let child of this.gltf.scene.children){
                if(child.name == name){
                    if(child.material == undefined && child.children != null){
                        for(let c of child.children){
                                c.material = new MeshLambertMaterial({ color: color});
                        }
                    }else{
                        child.material = new MeshLambertMaterial({ color: color});  
                    }
                }
            }
        }
    }

    private InitObjectAnimation(scene : any){
        for(let child of scene.children){
            if(child.name.indexOf('rotate') > 0){
                this.AddRotationAnimation(child);
            }
        }
    }

    private AddRotationAnimation(object : any){
        let regex = new RegExp('rotate\\(([^\\)]+)');
        let match = regex.exec(object.name);
        if(match != null){
            let expression = match[1];
            regex = new RegExp('([A-z]+)=([^,]+)', 'g');    
            while((match = regex.exec(expression)) !== null){
                let event : string = match[1];
                if(this.Animations[event] == null)
                this.Animations[event] = [];
                let values = match[2];
                let valuesRegex = new RegExp("([x|y|z])([-|0-9]+)", "g");
                let valueMatch : any;
                let target : any = {};
                while((valueMatch = valuesRegex.exec(values)) !== null){
                    let axis = valueMatch[1];
                    let angle = parseFloat(valueMatch[2])*(Math.PI/180);
                    target[axis] = angle;
                }
                this.Animations[event].push(['rotate', object, target]);
            }
        }
    }

    public async HandleEvent(item: LevelObjectMesh, eventName : string, SoundEnabled: boolean){
        //had to hard code until I can find a way in ts to check if a file exists before loading.
        if(eventName == "PaymentReceived" && SoundEnabled){
            var path = "../../../assets/audio/" + eventName + ".mp3";
            var audio = new Audio(path);
            audio.load(); 
            audio.volume = 0.1;
            audio.play();
        }
        else if(eventName == "Problem"){
            item.ProblemWithItem(item.scene);
        }
        else if(eventName == "ProblemResolved"){
            item.RemoveItemProblem(item.scene);
        }
        
        if(item.gltf?.animations == null){
            return;
        }

        var clip = THREE.AnimationClip.findByName(item.gltf.animations, eventName);
        var action = item.mixer.clipAction( clip );
        action.reset();
        action.clampWhenFinished = true;
        action.setLoop(THREE.LoopOnce, 1);

        if (item.lastAction) {
            item.lastAction.fadeOut(0.5);
            action.fadeIn(0.5);
        }
        action.play();
        item.lastAction = action;
    }
}
