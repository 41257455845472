import { Component, Injector } from '@angular/core';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-editterminal',
  templateUrl: './editterminal.component.html',
  styleUrls: ['./editterminal.component.scss']
})
export class EditterminalComponent extends ModelEditor {

  public SoftwareVersions: any[] = [];

  constructor(private apiService: ApiServiceBase, private injector: Injector) {
    super("infrastructure/localcontrollers", injector);

    apiService.Get<any>("hardware/versions/ABB3D560-0381-4990-9BDE-6B3D5C39528C/software").then(result => {
      this.SoftwareVersions = result;
    });
  }
  public override DefaultModel() {
    return { Name: "New Terminal", AccessMethods: [], ParkingLots: [], Gates: [] }
  }
  public override AfterModelLoaded(): void {

  }
  public override BeforeSave(): boolean | void {

  }
  public override Validators(): any {
    return {
      "Name": [Validators.required, Validators.minLength(1)],
      "RelayIpAddress": [],
      "RelayPort": [],
      "CheckinIntervalMinutes": [Validators.required, Validators.min(0)],
      "OfflineThresholdMinutes": [Validators.required, Validators.min(0)],
      "RelayType": [Validators.required],
    }
  }

  public override FormLabels() {
    return {
      RelayIpAddress: "Ip Address",
      RelayPort: "Port",
      CheckinIntervalMinutes: "Checkin Interval",
      OfflineThresholdMinutes: "Offline After",
      RelayType: "Power Relay"
    }
  }
}
