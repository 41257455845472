<app-pageheader Title="Organization Settings">
    <app-form-save-button [Parent]="Busyable" (OnSave)="OnSave()" [Form]="this"></app-form-save-button>
</app-pageheader>
<div class="container content" *ngIf="this.Model == null || this.Model.length == 0">
    <app-loader [Parent]="Busyable"></app-loader>
    <ul>
        <div class="title" *ngIf="!HasSettings">No Settings Found</div>
    </ul>
</div>
<div class="" *ngIf="this.Model != null && this.Model.length > 0">
    <app-tree>
        <app-treenodesettings [recursiveList]="this.Model" (updateSettingValue)="updateSettingValue($event)"></app-treenodesettings>
    </app-tree>
</div>