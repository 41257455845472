import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrIconClasses, ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { FeaturesService } from 'src/app/Services/features.service';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { Busyable } from 'src/app/shared/editors/busyable';
import { Deviceicons } from 'src/app/util/deviceicons';

@Component({
  selector: 'app-frogissues',
  templateUrl: './frogissues.component.html',
  styleUrls: ['./frogissues.component.scss']
})
export class FrogIssuesComponent extends Busyable implements OnInit {

  TotalToReconcile: number | null = null;
  DuplicateCount: number | null = null;
  ActionItems: any[] = [];

  public StartDate: string | undefined;
  public EndDate: string | undefined;
  public UseLastStatementRange: boolean = false;

  constructor(private apiService : ApiService, public orgService: OrganizationsService, private toast : ToastrService, private parentRoute: ActivatedRoute, private router: Router, private modalService: SimpleModalService) { super(); }

  ngOnInit(): void {
  }

  getIssues() {
    this.Loading();
    this.apiService.Post<any>("accounting/reconciliation/reconcile/frog", 
    {StartDate: this.StartDate, EndDate: this.EndDate, UseLastStatementRange: this.UseLastStatementRange})
    .then(result => {
      this.ActionItems = result.Issues;
      this.DuplicateCount = result.DuplicateCount;
      this.TotalToReconcile = result.TotalIssues;
      this.StopLoading();
    }).catch(error => {
      this.toast.error(error.error.Error.Message, "Couldn't find issues");
      this.StopLoading();
    });
  }

  doAction(action: any) {
    this.Loading();
    this.apiService.Post<any>("accounting/reconciliation/reconcile/frog/resolve",
    {
      StartDate: this.StartDate,
      EndDate: this.EndDate, 
      UseLastStatementRange: this.UseLastStatementRange,
      ActionValues: action.ActionValues,
      Name: action.Name,
    },
    ).then(result => {
      this.ActionItems = result.Issues;
      this.DuplicateCount = result.DuplicateCount;
      this.TotalToReconcile = result.TotalIssues;
      this.toast.success("Action performed successfully");
      this.StopLoading();
    }).catch(result => {
      this.toast.error(result.error.Error.Message, "Failed to do the requested action");
      this.StopLoading();
    }).finally(() => this.StopLoading());

  }

}
