import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { formatCurrency } from '@angular/common';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ToastrService } from 'ngx-toastr';
import { ModalSaveButtonComponent } from 'src/app/shared/forms/buttons/modal-save-button/modal-save-button.component';

@Component({
  selector: 'app-sessionpaymentrefund',
  templateUrl: './sessionpaymentrefund.component.html',
  styleUrls: ['./sessionpaymentrefund.component.scss']
})
export class SessionpaymentrefundComponent extends SimpleModalComponent<any, any> implements OnInit {
  public Payment: any;
  public RefundedAmount: any;
  public RefundDate : any;
  @ViewChild(ModalSaveButtonComponent) modalSaveButton!: ModalSaveButtonComponent;

  constructor(private organizationsService : OrganizationsService, private apiService: ApiServiceBase, private toast: ToastrService) {
    super();
  }

  ngOnInit(): void {
    this.RefundedAmount = this.Payment.Amount;
  }

  UpdateValue(value: any){
    if(value > this.Payment.Amount){
      this.RefundedAmount = this.Payment.Amount;
    }
    this.RefundedAmount = value;
  }

  UpdateDateValue(value: any){
    this.RefundDate = value.target.value;
  }

  confirm() {
    if(this.RefundDate == null || this.RefundDate == undefined)
    {
      this.toast.error("Refund date is required", "Submission error");
      this.modalSaveButton.StopAnimations();
      return;
    }

    this.apiService.Post("parking/sessions/" + this.Payment.ParkingSessionId + "/payments/" + this.Payment.AccountTransactionId +"/refunds", { Amount: this.RefundedAmount, RefundDate: this.RefundDate })
    .then(x => {
      this.result = x;
      this.close();
    }).catch((error) => {
        this.modalSaveButton.StopAnimations();
    });
  }
}