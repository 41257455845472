import { MeshConstants } from "src/app/util/mesh-constants";
import * as THREE from "three";
import { Mesh, Object3D, Scene, Vector2, Vector3 } from "three";
import { LevelitemMesh } from "./levelitem-mesh";
import * as TWEEN from '@tweenjs/tween.js';

export class SelectionIndicator extends THREE.Mesh  {
    private t1 : any;
    private t2 : any;

    constructor(meshToIndicate: Object3D) {
        super();
        
        var camera = new THREE.PerspectiveCamera();
        const box = new THREE.Box3().setFromObject(meshToIndicate);

        const geometry = new THREE.ConeGeometry( 0.0005, 0.002, 4 );
        const material = new THREE.MeshLambertMaterial( {color: 0xff0000} );
        this.material = material;
        this.geometry = geometry;

        const box3 = new THREE.Box3().setFromObject(meshToIndicate);
        const vector = new Vector3();
        let size = box3.getSize(vector);
       
        this.translateZ(0.02);
        this.rotateX(-Math.PI/2);

        if(meshToIndicate instanceof LevelitemMesh){
            if(meshToIndicate.position.x != 0){
                this.position.set(meshToIndicate.position.x, meshToIndicate.position.y, meshToIndicate.position.z + 0.008);
            }
            else if(meshToIndicate.Item != null){
                this.position.set(meshToIndicate.Item.LocationPoints[0], -meshToIndicate.Item.LocationPoints[1], 0.008);
            }
        }
        else
            this.position.set(meshToIndicate.position.x, meshToIndicate.position.y, meshToIndicate.position.z + 0.008);

        let height1 = this.position.z;
        let height2 = this.position.z + 0.001;
        this.t1 = new TWEEN.Tween(this.position).to({ z : height2}, 500).easing(TWEEN.Easing.Circular.InOut);
        this.t2 = new TWEEN.Tween(this.position).to({ z : height1}, 500).easing(TWEEN.Easing.Circular.InOut);
        this.t1.chain(this.t2);
        this.t2.chain(this.t1);
        this.t1.start();
        
    }
}
