import { ThisReceiver } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

interface CategoryValue {
  Value: string;
  Description: string;
  Name: string;
  AllowArguments: boolean;
}

@Component({
  selector: 'app-editterminaltransition',
  templateUrl: './editterminaltransition.component.html',
  styleUrls: ['./editterminaltransition.component.scss']
})

export class EditterminaltransitionComponent extends SimpleModalComponent<any, any> implements OnInit {

  @Input()
  public Model: any = {};
  public DelaySeconds: number = 0;

  @Input()
  public Transitions: { [key: string]: CategoryValue[] } = {};

  public SelectedEvents: Array<any> = [];
  public SelectedEvent : any = {};

  removeSection(index: number) {
    this.SelectedEvents.forEach((_, eventIndex) => {
      if (eventIndex == index) this.SelectedEvents.splice(index, 1);
    });
  }

  constructor() {
    super();
  }

  public ngOnInit() {

    let events: string = this.Model.Event;
    let eventArray : string[] = events.split("|");

    for(let evt of eventArray){
      if (evt.indexOf('After') == 0) {
        //this is a delay with configurable seconds, strip off the seconds for editing
        let delaySeconds = parseInt(evt.substring(5, evt.length - 1));
        this.SelectedEvents.push({Event: 'After', IsCustom: false, DelaySeconds: delaySeconds});
      } else if (evt.indexOf('Presence') == 0){
        let delaySeconds = parseInt(evt.substring(8, evt.length - 1));
        this.SelectedEvents.push({Event: 'Presence', IsCustom: false, DelaySeconds: delaySeconds});
      } else{
        //check if it's a custom one
        let exists = false;
        for(let t in this.Transitions) {
          if (this.Transitions[t].filter((x: any) => x.Value == evt).length > 0) {
            exists = true;
            break;
          }
        }
        this.SelectedEvents.push({Event: !exists ? 'Custom' : evt, IsCustom: !exists, CustomEvent: !exists ? evt : '' });
      }
    }
  }

  public AddSelectedEvent(){
    this.SelectedEvents.push({Event: this.SelectedEvent.Value, IsCustom: this.SelectedEvent.Value == "Custom", CustomEvent: "" })
  }
  public DeleteEvent(index : number){
    this.SelectedEvents.splice(index, 1);
  }

  public Confirm() {
    var eventsList: string[] = [];

    for(let e of this.SelectedEvents){
      if (e.Event == "After" || e.Event == "Presence") {
        eventsList.push(e.Event + e.DelaySeconds + "s");
      }
      else if (e.Event == "Custom") {
        eventsList.push(e.CustomEvent);
      }
      else{
        eventsList.push(e.Event);
      }
    }
    this.Model.Event = eventsList.join("|");

    this.result = this.Model;
    this.close();
  }
}