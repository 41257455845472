<div class="modal is-active" [formGroup]="Form">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Cell Router</p>
      </header>
      <section class="modal-card-body"style="min-width: 800px;">
        <app-tabs>
            <app-tab Title="Cekk Router Details">

                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Name</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                            <p class="control">
                                <input formControlName="Name" class="input" type="text" placeholder="Name" />
                            </p>
                            </div>
                        </div>  
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Serial Number</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                            <p class="control">
                                <input formControlName="SerialNumber" class="input" type="text" placeholder="Serial Number" />
                            </p>
                            </div>
                        </div>  
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">IMei</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                            <p class="control">
                                <input formControlName="Imei" class="input" type="text" placeholder="IMei"/>
                            </p>
                            </div>
                        </div>  
                    </div>

                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Sim 1</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                            <p class="control">
                                <input formControlName="Sim1Id" class="input" type="text" placeholder="Sim 1"/>
                            </p>
                            </div>
                        </div>  
                    </div>

                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Sim 2</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                            <p class="control">
                                <input formControlName="Sim2Id" class="input" type="text" placeholder="Sim 2"/>
                            </p>
                            </div>
                        </div>  
                    </div>
            </app-tab>
        </app-tabs>
        
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" (click)="confirm()">OK</button>
      </footer>
    </div>
  </div>