import { Component, Input, OnInit } from '@angular/core';
import { Datalistrequestfilter } from '../../request/datalistrequestfilter';
import { FilterFiltersBase, IFilterFilters } from '../ifilter';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: '[app-admin-filter]',
  templateUrl: './admin-filter.component.html',
  styleUrls: ['./admin-filter.component.scss'],
  host: { 'class': 'control select' }
})
export class AdminFilterComponent extends FilterFiltersBase implements IFilterFilters, OnInit {
  @Input()
  public SelectedIsAdmin: any = false;
  constructor(route: ActivatedRoute) { super(route); }
  ngOnInit(): void {
    var values = super.GetParamFields(this.QueryParamPrefix + "isAdmin");
    if(values != null) {
      this.SelectedIsAdmin = values[2] == "true";
    }

    this.Filters.push({
      Property: "IsAdmin",
      Comparator: "==",
      Value: this.SelectedIsAdmin,
      FilterName: this.FilterName
    });
  }
  public Filters: Datalistrequestfilter[] = [];
  public FilterName: string = "isAdmin";
  QueryParamPrefix: string= '';
  
  public addIsAdminFilter(event: any) {
    var val = event.target.value;

    if (val != "") {
      this.Filters = [];
      this.Filters.push({
        Property: "IsAdmin",
        Comparator: "==",
        Value: val,
        FilterName: this.FilterName
      });
    }
  }
}
