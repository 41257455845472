import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Busyable } from 'src/app/shared/editors/busyable';
import { environment } from 'src/environments/environment';
import { LoginServiceBase } from '../login.service.base';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public user = { username: '', password: '' };
  public ErrorMessage: string = "";

  constructor(private loginService: LoginServiceBase, private router: Router) { }

  ngOnInit(): void {
    if (this.loginService.IsLoggedIn() && environment.production) {
      this.router.navigate([""]);
    }
  }
}
