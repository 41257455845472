<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Record Refund</p>
      </header>
      <section class="modal-card-body"style="min-width: 800px;">

          <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="label">Paid Amount</label>
            </div>
            <div class="field-body">
                <div class="field">
                <p class="control">
                    ${{this.Payment.Amount}}
                </p>
                </div>
            </div>  
        </div>
            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="label">Refunded Amount</label>
                </div>
                <div class="field-body">
                    <div class="field">
                    <p class="control">
                        <app-currency-input [InternalValue]="this.Payment.Amount" [MaxValue]="this.Payment.Amount" (ValueChanged)="UpdateValue($event)"></app-currency-input>
                    </p>
                    </div>
                </div>  
            </div>
            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="label">Refund Date</label>
                </div>
                <div class="field-body">
                    <div class="field">
                    <p class="control">
                        <input class="input" type="datetime-local" (change)="UpdateDateValue($event)" />
                    </p>
                    </div>
                </div>  
            </div>
            
      </section>
      <footer class="modal-card-foot">
        <!-- <button class="button is-success" (click)="confirm()">OK</button> -->
        <app-modal-save-button [Disabled]="this.RefundedAmount <= 0 || this.RefundedAmount == null || this.RefundDate == null || this.RefundDate == undefined" (OnSave)="confirm()"></app-modal-save-button>
      </footer>
    </div>
  </div>