import { FabricHelpers } from "../../../fabric.js/helpers";
import { LevelEditorComponent } from "../../../leveleditor.component";
import { MapItemTool } from "./MapItemTool";

export class ArrowTool extends MapItemTool{
    public override Type = "Arrow";
    public override OutlineColor = "FFFFFF";
    public override Opacity =  0.8;
    public override FillColor =  "FFFFFF";
    public override OutlineThickness = 2;
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }

    public override CreatePolygonFromXY(x: number, y: number) {
        var zoom = this.canvas.getZoom(); 
        var points = FabricHelpers.GetAdjustedXY(this.canvas, x, y);
        x = points[0];
        y = points[1];
        let size = 10;
        let poly: any[] = [];
        poly.push([x - size, y + (2 * size)]);
        poly.push([x - size, y + (2 * size)]);
        poly.push([x - size, y - (2 * size)]);
        poly.push([x - size - size, y - (2 * size)]);
        poly.push([x, y - (6 * size)]);
        poly.push([x + size + size, y - (2 * size)]);
        poly.push([x + size, y - (2 * size)]);
        poly.push([x + size, y + (2 * size)]);
        var results= poly.map((item: any) => [item[0]/this.ScaleFactor/zoom, item[1]/this.ScaleFactor/zoom]);
        return results;
    }
}