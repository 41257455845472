<aside class="menu mt-2 ml-5 is-hidden-mobile">
    <div *ngFor="let item of MenuItems">
        <p class="menu-label mb-1 mt-4">
        {{item.Text}}
        </p>
        <ul class="menu-list">
            <li *ngFor="let subitem of item.Children" class="m-0 p-0" [ngStyle]="{'opacity' : subitem.Enabled ? 1 : 0.4, 'pointer-events': subitem.Enabled ? 'auto' : 'none' }">
                <a [routerLink]="'/' + subitem.Route">{{subitem.Text}}</a>
            </li>
        </ul>
    </div>
</aside>