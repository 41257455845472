import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceBase } from './api.service.base';
import { ServiceBusClient} from '@azure/service-bus'
import { OrganizationsService } from './organizations.service';
import { PermissionsService } from './permissions.service';
import { LoginServiceBase } from '../auth/login.service.base';

@Injectable({
  providedIn: 'root'
})
export class GuidanceFeedService {

  private LatestData? : {};
  private Lookup : any = {};
  public GuidanceFeedServiceUpdated = new EventEmitter<any>();
  public CurrentConnectionString : string = "";
  private Client? : ServiceBusClient;

  constructor(private apiService : ApiServiceBase, private organizationsService : OrganizationsService, private loginService : LoginServiceBase, private permissionService : PermissionsService) { 
    organizationsService.AvailableOrganizationsChanged.subscribe(result =>{
      this.ConnectServiceBus(); //connect to the bus if we aren't already connected
    });
    loginService.OrganizationChanged.subscribe((orgid) => {
      this.ConnectServiceBus(); //disconnect from previous bus and connect to new org
    });
    this.ConnectServiceBus();
  }

  private Update(space : any, messagetype: string, enqueuedTimeUtc: any){
    console.log("guidance feed received update");
    if(space.Id != null){
      this.LatestData = {SpaceId: space.Id, MessageType: messagetype, EnqueuedTimeUtc: enqueuedTimeUtc}
      this.GuidanceFeedServiceUpdated.emit({SpaceId: space.Id, MessageType: messagetype, EnqueuedTimeUtc: enqueuedTimeUtc});
    }
  }

  private ConnectServiceBus(){
    this.organizationsService.GetServiceBusListenerConnectionString().then((x: any) => {
      if(this.CurrentConnectionString == x){
        //same connection as before
        return;
      }
      if(this.Client != null){
        //if we were connected before then close
        this.Client.close();
      }
      if(x == null || x == ''){
        return; //can't connect with no connecton string
      }

    var subscriptionKey = this.permissionService.GetSessionIdentifier();
     this.apiService.Post<any>("infrastructure/servicebus/topics/Guidance/" + this.loginService.UserId() + subscriptionKey, {}).then(result => {
      this.Client = new ServiceBusClient(x);
      const receiver = this.Client.createReceiver("Guidance", this.loginService.UserId() + subscriptionKey);
      const SBMessageHandler = async (messageReceived:any) => {
        this.Update(messageReceived.body, messageReceived.applicationProperties.MessageType, messageReceived.enqueuedTimeUtc);
      };
      const SBErrorHandler = async (error:any) => {
        console.log(error);
      };
      receiver.subscribe({
        processMessage: SBMessageHandler,
        processError: SBErrorHandler
      });
     });
    });
  }
}
  

export class GuidanceFeedsResponse implements IFeedServiceValue{
  
  public Id! : string;
}

export interface IFeedServiceValue{
}
