<div class="modal is-active">
    <div class="modal-background" [@modalbackground]="Closing ? 'close' : ''"></div>
    <div class="modal-card" [@modalopen]="Closing ? 'close' : ''">
        <header class="modal-card-head">
            <p class="modal-card-title">Manage {{Model.PlateAccessListName}} Plate</p>
        </header>
        <form [formGroup]="Form">
            <section class="modal-card-body" style="min-width: 800px; min-height: 350px;">
                <app-field Label="Plate Number">
                    <div class="control">
                        <input type="text" id="plateNumber" class="input" formControlName="plateNumber"
                            placeholder="Plate Number">
                    </div>
                </app-field>

                <app-field Label="Owner">
                    <app-singleuserselector formControlName="UserId" [User]="InitialUser" [AllowCreateUser]="true">
                    </app-singleuserselector>
                </app-field>
            </section>

            <footer class="modal-card-foot">
                <app-form-save-button [Form]="Form" (OnSave)="AddPlate()"></app-form-save-button>
                <button class="button ml-3" (click)="cancel()">Cancel</button>
            </footer>

        </form>
    </div>

</div>