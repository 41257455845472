import { JsonpClientBackend } from "@angular/common/http";
import { AfterContentInit, AfterViewInit, Component, EventEmitter, Inject, inject, Injector, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, CanDeactivate } from "@angular/router";
import { catchError, Observable, ObservableInput, of, Subject } from "rxjs";
import { Busyable } from "./busyable";
import { AbstractControl, UntypedFormArray, FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from "ngx-toastr";
import { SimpleModalService } from "ngx-simple-modal";
import { Router } from '@angular/router';
import { stringify } from "querystring";
import { ComponentCanDeactivate } from "../guards/pendingchangesguard";
import { ApiServiceBase } from "src/app/Services/api.service.base";
import { Deviceicons } from "src/app/util/deviceicons";

@Component({
    template: ''
})


export abstract class ModelEditor extends Busyable implements OnInit, ComponentCanDeactivate {

    public ModelId: string = "";
    public Model: any | null = null;
    public QueryString: string = "";
    public ModelIsNew: boolean = false;
    public OriginalModelJson: string = "";
    public Form: UntypedFormGroup = new UntypedFormGroup({});
    public FormLoaded: boolean = false;
    public ValidatorLookup: any = {};
    public ReactiveFormsEnabled: boolean = true;
    public idFieldName = "id";
    public ModelLoaded = new EventEmitter<any>();
    public BlockLoading : boolean = false; //flag to be able to force editor to always act as 'new' instead of edit existing
    public UseAnimation : boolean = false;

    public abstract DefaultModel(): any;
    public abstract AfterModelLoaded(): void;
    public abstract BeforeSave(): boolean | void;
    public abstract Validators(): any;
    public FormLabels(): any { }


    protected api: ApiServiceBase;
    protected parentRoute: ActivatedRoute;
    protected toast: ToastrService;
    protected modal: SimpleModalService;
    protected router: Router;

    constructor(@Inject('ModelUrl') private ModelUrl: string, injector: Injector, @Inject('idName') private idName?: string) {
        super();
        this.api = injector.get(ApiServiceBase);
        this.parentRoute = injector.get(ActivatedRoute);
        this.toast = injector.get(ToastrService);
        this.modal = injector.get(SimpleModalService);
        this.router = injector.get(Router);

        if(idName != null && idName != '') {
            this.idFieldName = idName; 
        }
                
        ModelUrl = ModelUrl.trim();
        if (ModelUrl.endsWith("/")) {
            ModelUrl = ModelUrl.substring(0, ModelUrl.length - 1);
        }
        this.ValidatorLookup = this.Validators();
    }

    public ngOnInit(): void {

        this.api.loginService.LoginStatusChanged.subscribe(isLoggedIn => {
            if (this.Model == null) {
                this.InitializeModel();
            }
        });

        if (this.api.loginService.IsLoggedIn()) { //user is currently logged in
            this.InitializeModel();
        }
    }

    public InitializeModel() {
        this.ModelId = this.parentRoute.snapshot.params[this.idFieldName];
        if (this.ModelId == 'new' || this.BlockLoading) {

            let defaultModel = this.DefaultModel();
            if(defaultModel instanceof Promise){
                defaultModel.then((value) =>{
                    this.Model = value;
                    this.ModelIsNew = true;
                    this.OriginalModelJson = JSON.stringify(this.Model);
                    this.AfterModelLoaded_Internal();
                });
            }
            else{
                this.Model = defaultModel;
                this.ModelIsNew = true;
                this.OriginalModelJson = JSON.stringify(this.Model);
                this.AfterModelLoaded_Internal();
            }
        }
        else {
            this.FetchModel(this.ModelId);
        }
    }

    public FetchModel(id: string) {
        this.ModelId = id;
        this.Loading();
        let url = this.ModelUrl + (id != null ? '/' + id : "") + this.QueryString;
        this.api.Get(url).then(result => {
            this.Model = result;
            this.OriginalModelJson = JSON.stringify(this.Model);
            this.StopLoading();
            this.AfterModelLoaded_Internal();
            this.ModelLoaded.emit(this.Model);
        }, error => {
            this.toast.error(error.message, "Error Loading Resource");
        });
    }
    public AfterModelLoaded_Internal() {
        if (this.ReactiveFormsEnabled) {
            this.PopulateFormGroupFromModel(this.Model, this.Form, "");
            this.AddUILabelIntoForm();
        }
        setTimeout(() => {
            this.UseAnimation = true;
          }, 500);
        this.AfterModelLoaded();
        this.OriginalModelJson = JSON.stringify(this.Model);
        if (this.ReactiveFormsEnabled) {
            this.FormLoaded = true;
        }
    }

    private AddUILabelIntoForm() {
        this.Form.addControl('FormLabels', new UntypedFormControl(this.FormLabels()))
    }

    public PopulateFormGroupFromModel(model: any, formGroup: UntypedFormGroup, path: string) {
        if (Array.isArray(model)) {
            for (let i of model) {
                this.PopulateFormGroupFromModel(i, formGroup, path);

            }
        }
        else {
            for (let prop in model) {
                let val = model[prop];
                let isArray = Array.isArray(val);
                let arrayTypeIsObject = isArray && val.length > 0 && (typeof val === 'object');
                if (prop == 'PolygonPoints' || prop == 'PolygonCenter' || prop == 'LocationPoints' || prop == 'Map' || prop == 'GeoLocationPoints' || prop == 'GeoPolygonPoints') {
                    //special handling for double[][] arrays of polygon points
                    let fc = new UntypedFormControl(val);
                    formGroup.addControl(prop, fc);
                    this.CheckForValidators(fc, path + prop);
                }
                else if (isArray && arrayTypeIsObject) {
                    //property type is array of objects
                    let fa = new UntypedFormArray([]);
                    formGroup.addControl(prop, fa);
                    (fa as any)._parent = formGroup;
                    for (let i = 0; i < val.length; i++) {
                        this.AddToFormArray(fa, val[i], path + prop);
                    }
                    fa.updateValueAndValidity();
                }
                else if (isArray) {
                    //it's an array of values
                    let fa = new UntypedFormArray([]);
                    formGroup.addControl(prop, fa);
                    for (let i = 0; i < val.length; i++) {
                        let fc = new UntypedFormControl(val[i]);
                        fa.controls.push(fc);
                        fc.valueChanges.subscribe((val) => {
                            fa.updateValueAndValidity();
                        });
                    }
                    fa.updateValueAndValidity();
                }
                else if (typeof val === 'object' && val != null && val != undefined && Object.keys(val).length > 0){
                    //is a child object
                    let fg = new UntypedFormGroup({});
                    this.PopulateFormGroupFromModel(val, fg, path)
                    formGroup.addControl(prop, fg);
                }
                else {
                    let fc = new UntypedFormControl(model[prop]);
                    formGroup.addControl(prop, fc);
                    this.CheckForValidators(fc, path + prop);
                    fc.valueChanges.subscribe((val) => {
                        if (this.FormLoaded)
                            this.Form.markAsDirty();
                    });
                }
            }
        }
    }
    public AddToFormArray(fa: UntypedFormArray, model: any, path: string): UntypedFormGroup {
        let fg = new UntypedFormGroup({});
        (fg as any)._parent = fa;
        if (!path.endsWith('.')) path = path + "."; //populate function expects trailing dot to just add 
        fa.controls.push(fg);
        this.PopulateFormGroupFromModel(model, fg, path);
        fg.valueChanges.subscribe((val) => {
            fa.updateValueAndValidity();
        });
        if (this.FormLoaded) {
            fa.updateValueAndValidity();
            this.Form.markAsDirty();
        }
        return fg;
    }

    public CheckFormArrayContains(fa: UntypedFormArray, comparitor: string, value: string): boolean {
        var existing = fa.controls.map(x => x.get(comparitor)?.value);
        let exists = existing.some(x => x == value);
        return exists;
    }

    public RemoveFromFormArray(fa: UntypedFormArray, index: number) {
        fa.removeAt(index);
        if (this.FormLoaded) {
            this.Form.markAsDirty();
        }
    }

    private CheckForValidators(fc: UntypedFormControl, path: string) {
        if (this.ValidatorLookup != null && this.ValidatorLookup[path]) {
            let validators = this.ValidatorLookup[path];
            fc.addValidators(validators);
        }
    }

    public FormArray(parent: any, name: string): UntypedFormArray {
        let target = parent.controls[name];
        if (target instanceof UntypedFormArray) {
            return target as UntypedFormArray;
        }
        else {
            //property is not a formarray, probably was NULL instead of empty array when we got it initially so a formcontrol was created instead
            //make a new formarray and inject it into parent over the top of the old formcontrol
            let fa = new UntypedFormArray([]);
            parent.controls[name] = fa;
            (fa as any)._parent = parent;
            return fa;
        }
    }

    public Save() {
        if (!this.Form?.valid) {
            //form is not valid
            this.toast.warning("Form has missing or invalid data", "Error");

            return;
        }
        this.Busy();
        var c = this.BeforeSave();
        this.UseAnimation = false;
        if(c != null && c == false){
            this.toast.warning("Form has missing or invalid data", "Error");
            this.StopBusy();
            return;
        }

        let submitModel = this.ReactiveFormsEnabled ? this.Form.getRawValue() : this.Model;


        if (this.ModelIsNew) {
            this.Busy();
            this.api.Post<any>(this.ModelUrl, submitModel).then(result => {
                this.StopBusy();
                if (result != null) {
                    this.Model = result;
                    this.OriginalModelJson = JSON.stringify(this.Model);
                    this.toast.success('Add successful', 'Saved');
                    this.Form = new UntypedFormGroup({});
                    this.ModelIsNew = false;
                    this.ModelId = result.Id;
                    this.AfterModelLoaded_Internal();
                    this.Form.markAsPristine();
                    this.AfterSave_Internal(this.ModelId);
                }

            }, (err) => {
                this.StopBusy();
                this.HandleApiError(err);
            });
        }
        else {
            this.Busy();
            let url = this.ModelUrl + (this.ModelId != null ? '/' + this.ModelId : "");
            this.api.Put(url, submitModel).then(result => {
                this.StopBusy();
                if (result != null) {
                    this.toast.success('Update successful', 'Saved');
                    // update the model
                    this.Model = result;
                    this.OriginalModelJson = JSON.stringify(this.Model);
                    this.Form = new UntypedFormGroup({});
                    this.AfterModelLoaded_Internal();
                    this.AfterSave();
                    this.Form.markAsPristine();
                }
            }, (err) => {
                this.StopBusy();
                this.HandleApiError(err);
            });
        }

    }

    private HandleApiError(err: any): ObservableInput<any> {
        if (err.error != null && err.error.Error != null) {
            this.toast.error(err.error.Error.Message, "Error");
        }
        else if (err.error && err.error.errors && err.error.errors.id) {
            this.toast.error(err.error.errors.id[0], err.error.title);
        }
        else {
            this.toast.error('There was a problem', 'Error');
        }
        return of(null);
    }

    public CanDeactivate() {
        if (this.ReactiveFormsEnabled) {
            return !this.Form.dirty;
        }
        return JSON.stringify(this.Model) == this.OriginalModelJson;
    }

    public SetDirty(form: any, control: any) {
        if (form != null) {

        }
    }

    public ShowDebugMessages() {
        //this.modal.addModal(HardwaremessagesviewerComponent, { DeviceId: this.ModelId });
    }

    public GenerateIconName(fg: UntypedFormGroup | AbstractControl, marker: boolean = false, markerselected: boolean = false): string {
        return Deviceicons.GenerateIconName(fg, marker, markerselected);
    }

    
    public AfterSave(): void{
        setTimeout(() => {
            this.UseAnimation = true;
        }, 500);
    }

    public AfterSave_Internal(ModelId : string): void{
        var routerlink = this.router.url;
        routerlink = routerlink.replace('new', ModelId);
        this.router.navigate([routerlink]);
    }
}
