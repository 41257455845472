<app-loader [Parent]="this"></app-loader>
<div *ngIf="ShowPage">
  <app-pageheader [Parent]="this">
    <form-edit-button routerLink="edit">Edit</form-edit-button>
    <app-form-delete-button [Parent]="this" [Model]="Model" [DeleteCheck]="false"
        DeleteUrl="infrastructure/controlledareas/" DeleteNavLink="parking/accesscontrol/controlledareas">
        </app-form-delete-button>
  </app-pageheader>
  <div class="columns">
    <div class="column is-6">
      <article class="panel">
        <p class="panel-heading">
          Controlled Area {{Model.Name}}
        </p>
        <a class="panel-block">
          <span class="label">Id</span>
          <span class="value">{{Model.Id}}</span>
        </a>
        <a class="panel-block">
          <span class="label">Controlled Area</span>
          <span class="value">{{Model.LocationString}}</span>
        </a>
        <a class="panel-block">
          <span class="label">Spaces</span>
          <span class="value">Vacant: {{Model.VacantSpaces}} Occupied: {{Model.OccupiedSpaces}}
            ({{Model.TotalSpaces}} total)</span>
        </a>
        <a class="panel-block">
          <span class="label">Parking Lots</span>
          <span class="value">
            <div class="field is-grouped is-grouped-multiline">
              <div class="control" *ngFor="let p of Model.ParkingLots; let i = index;">
                <div class="tags has-addons">
                  <a class="tag" routerLink="/parking/parking/lots/{{p.Id}}" routerLinkActive="active">{{p.Name}}</a>
                </div>
              </div>
            </div>
          </span>
        </a>
        <a class="panel-block">
          <span class="label">Access Methods</span>
          <span class="value">
            <div class="field is-grouped is-grouped-multiline">
              <div class="control" *ngFor="let p of Model.AccessMethods; let i = index;">
                <div class="tags has-addons">
                  <a class="tag">{{p.RateSetName}}</a>
                </div>
              </div>
            </div>
          </span>
        </a>
        <a class="panel-block">
          <span class="label">Controllers</span>
          <span class="value">
            <div class="field is-grouped is-grouped-multiline">
              <div class="control" *ngFor="let p of Model.Controllers; let i = index;">
                <div class="tags has-addons">
                  <a class="tag tag-image"><img class="node-image" style="height: 2em; max-width: 3em;"
                      [src]="GenerateIconName(p)" /></a>
                  <a class="tag" routerLink="/parking/accesscontrol/terminals/{{p.Id}}"
                    routerLinkActive="active">{{p.Name}}</a>
                </div>
              </div>
            </div>
          </span>
        </a>
        <a class="panel-block">
        </a>
        <a class="panel-block">
        </a>
        <a class="panel-block">
        </a>
        <a class="panel-block empty">
        </a>
        <a class="panel-block">
        </a>

      </article>
    </div>
    <div class="column is-6">
      <app-structureviewermaps [StructureId]="Model.Id" [Layers]="StructureViewerLayers" [ShowLotSelect]="true" [ShowLevelSelect]="true"></app-structureviewermaps>
    </div>
  </div>
  <div class="columns">
    <div class="column is-6">
      <app-dashboardbox>
        <app-occupancyserieschart Title="Traffic - Today" [StructureId]="Model.Id" StructureType="controlledarea"
          ChartType="line" Period="today" DataType="inflows"></app-occupancyserieschart>
      </app-dashboardbox>
    </div>
    <div class="column is-6">
      <app-dashboardbox>
        <app-occupancyserieschart Title="Traffic - Last 7 Days" [StructureId]="Model.Id"
          StructureType="controlledarea" ChartType="bar" Period="last7days" DataType="inflows">
        </app-occupancyserieschart>
      </app-dashboardbox>
    </div>
  </div>