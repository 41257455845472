<div class="modal is-active">

  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Media List</p>
      <button style="float: right;" class="button is-primary" (click)="addNew()">  
        <span class="icon-text">
          <span class="icon">
            <i class="fa fa-upload"></i>
          </span>
          <span>Upload</span>
        </span>
      </button>
    </header>
    <section class="modal-card-body" style="min-width: 800px;">
      <div class="field is-horizontal">

        <div class="field-body">
          <div class="field">
            <div class="control">
              <app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="media/searches"
                [AllowEdit]="false" [PageSize]=10 (RowSelected)="ItemSelected($event)" [AllowSelect]="false">
              </app-datalistview>

            </div>
          </div>
        </div>
      </div>


    </section>
    <footer class="modal-card-foot">
      <button [disabled]="selectedItem == null" class="button is-primary" (click)="confirm()">Insert</button>
      <button class="button is-danger" (click)="close()">Cancel</button>
    </footer>
  </div>


</div>