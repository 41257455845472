import { Component, Input, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Busyable } from 'src/app/shared/editors/busyable';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-editparkinglevelcounts',
  templateUrl: './editparkinglevelcounts.component.html',
  styleUrls: ['./editparkinglevelcounts.component.scss']
})
export class EditParkinglevelCountsComponent extends SimpleModalComponent<any, any> implements OnInit {

  constructor(public apiService: ApiService) {
      super();
  }

  public ParkingLevels: any = [];
  public ModelId : string = "";
  public Busyable: Busyable = new Busyable();

  ngOnInit(): void {
    this.Busyable.Loading();
    this.apiService.Get("/infrastructure/parkinglots/" + this.ModelId + "/levels").then(result => {
        this.ParkingLevels = result;
        this.Busyable.StopLoading();
    });
  }

  public UpdateCount(evt: any, index: number){
    var parkingLevel = this.ParkingLevels[index];
    parkingLevel.VehiclesPresent = evt.target.value;
  };


  public UpdateCounts(){
    var request = [];
    for (let i = 0; i < this.ParkingLevels.length; i++){
      request.push({Id: this.ParkingLevels[i].Id, VehiclesPresent: this.ParkingLevels[i].VehiclesPresent });
    }
    this.apiService.Put("/infrastructure/parkinglots/" + this.ModelId + "/levels/UpdateVehiclesPresent", request).then(result => {
        this.result = result;
        this.close()
    });
  };
}
