import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Color } from 'src/app/util/color';

@Component({
  selector: 'app-heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.scss']
})
export class HeatmapComponent implements AfterViewInit  {
 
  @ViewChild('canvasElement', { static: false }) 
  canvasElement !: ElementRef<HTMLCanvasElement>;

  @Input()
  public TopLabel : string = "";
  @Input()
  public BottomLabel : string = "";

  private context: CanvasRenderingContext2D | null = null;
  @Input()
  public width = 300;
  @Input()
  public height = 8

  ngAfterViewInit(): void {
    const canvas = this.canvasElement.nativeElement;
    this.context = canvas.getContext('2d');
    this.render();
  }
  private render(): void {
    const step = this.width/255;
    for (let i = 0.0; i < 255; i++) {
      const color = Color.CalculateHeatmapColor(i);
      if(this.context != null){
        this.context.fillStyle = "#" + color.toString(16);
        this.context.fillRect(i*step,0, step+1, this.height);
      }
    }
  }
}
