import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ServiceBusService } from 'src/app/Services/servicebus.service';
import { Busyable } from 'src/app/shared/editors/busyable';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/auth/login.service';
import { LoginServiceBase } from 'src/app/auth/login.service.base';

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss']
})
export class TerminalComponent extends Busyable implements OnInit {

  public ModelId: any;
  public Model: any;
  public deviceCheckin: any;
  public ShowAlertsDropdown: boolean = false;
  public ShowPage: Boolean = false;
  constructor(private apiService: ApiServiceBase, private parentRoute: ActivatedRoute, private servicebusService: ServiceBusService, public loginService: LoginServiceBase) {
    super();
  }

  public terminalConfig: any;

  ngOnInit(): void {
    this.Loading();
    this.parentRoute.params.subscribe(params => { this.ModelId = params['id']; });

    this.apiService.Get<any>("infrastructure/localcontrollers/" + this.ModelId).then(result => {
      this.Model = result;
      this.GenerateConfigInJson();
    });
    this.apiService.Get<any>("infrastructure/checkins/" + this.ModelId + '/latest').then(result => {
      this.deviceCheckin = result;
      this.ShowPage = true;
      this.StopLoading();
    });

  }

  PowerCycle(voltage: string) {
    this.servicebusService.PowerCycle(this.ModelId, voltage);
  }

  DatabaseReset() {
    this.servicebusService.ResetDatabase(this.ModelId);
  }

  GetServerUrl() {
    return environment.serverBaseUrl;
  }


  GenerateConfigInJson() {
    fetch('./assets/template/terminalConfig.json').then(result => result.json()).then(data => {
      let terminalConfigModel = data;

      terminalConfigModel.ServerBaseUrl = this.GetServerUrl();
      terminalConfigModel.OrganizationId = this.loginService.CurrentOrganizationId();
      terminalConfigModel.DeviceId = this.Model.Id;
      terminalConfigModel.NLog.targets.rapid7.token = this.Model.LogToken;
      terminalConfigModel.EncryptionKey = this.Model.PrivateKey;

      this.terminalConfig = JSON.stringify(terminalConfigModel, null, 2)
    })
  }
}
