<div class="designer" [formGroup]="Form">
    <div class="designtools">
        <div style="display: inline-block">
            <div class="field has-addons">
                <div class="control">
                    <a class="button is-static">
                        Use for up to
                    </a>  
                </div>
                <div class="control">
                    <input type="number" class="input is-short" formControlName="MaxTotalSpaces">
                </div>
                <div class="control">
                    <div class="button is-static">
                        Spaces
                    </div>  
                </div>
            </div>
        </div>
        &nbsp;
        <a class="tool button" *ngFor="let t of Types" (click)="SetTool(t[1])">
            {{t[0]}}
        </a>
    </div>
    <div class="columns">
        <div class="designsurface" (mousemove)="DesignSurfaceMouseMove($event)" (mousedown)="DesignSurfaceMouseDown($event)">
            <div class="column is-7">
                <div style="align-items: center; justify-content: center; display: flex;">
                    <svg viewBox="0 0 500 500" width="500" height="500">
                        <ng-container *ngFor="let element of GetElementsArray(); let i = index;">
                        <ng-container [ngSwitch]="element.get('Type').value">
                            <g (mousedown)="ElementMouseDown(element,$event)" (mouseup)="DesignSurfaceMouseUp($event)" (click)="SelectElement(element, i)">
                                <rect *ngSwitchCase="'rect'" [attr.x]="element.get('X')?.value"
                                [attr.y]="element.get('Y')?.value" 
                                [attr.width]="element.get('Width')?.value" 
                                [attr.height]="element.get('Height')?.value" 
                                [attr.fill]="'#'+element.get('Fill')?.value" 
                                [attr.stroke]="'#'+element.get('StrokeColor')?.value"  
                                [attr.stroke-width]="element.get('StrokeWidth')?.value" 
                                [attr.rx]="element.get('CornerRadius')?.value" 
                                [attr.opacity]="element.get('Opacity')?.value"></rect>
                                <ellipse *ngSwitchCase="'ellipse'" 
                                [attr.cx]="GetElementCenterX(element)" 
                                [attr.cy]="GetElementCenterY(element)" 
                                [attr.rx]="GetElementWidth(element)/2"  
                                [attr.ry]="GetElementHeight(element)/2" 
                                [attr.fill]="'#'+element.get('Fill')?.value" 
                                [attr.stroke]="'#'+element.get('StrokeColor')?.value"  
                                [attr.stroke-width]="element.get('StrokeWidth')?.value" 
                                [attr.opacity]="element.get('Opacity')?.value"></ellipse>
                                <text *ngSwitchCase="'text'" 
                                [attr.x]="element.get('X')?.value" 
                                [attr.y]="element.get('Y')?.value"  
                                [attr.font-size]="element.get('FontSize')?.value" 
                                [attr.fill]="'#'+element.get('Fill')?.value" 
                                [attr.opacity]="element.get('Opacity')?.value">{{element.get('Text').value.indexOf('{') == 0 ? '{}' : element.get('Text').value }}</text> 
                                <ellipse *ngSwitchCase="'spaceTypeIcon'" 
                                [attr.cx]="GetElementCenterX(element)" 
                                [attr.cy]="GetElementCenterY(element)" 
                                [attr.rx]="GetElementWidth(element)/2"  
                                [attr.ry]="GetElementHeight(element)/2" 
                                [attr.fill]="'#'+element.get('Fill')?.value" 
                                [attr.stroke]="'#'+element.get('StrokeColor')?.value"  
                                [attr.stroke-width]="element.get('StrokeWidth')?.value" 
                                [attr.opacity]="element.get('Opacity')?.value"></ellipse>
                            </g>
                        </ng-container>
                        </ng-container>
                    </svg>
                </div>
            </div>
        </div>
        <div class="column is-2">
            <div class="designproperties">
                <div class="title is-size-7 has-text-centered">PROPERTIES</div>

                <table class="table is-striped properties-table" *ngIf="SelectedElement != null" [formGroup]="SelectedElement">
                    <tr>
                        <td>X</td>
                        <td><input type="number" class="input" formControlName="X"></td>
                    </tr>
                    <tr>
                        <td>Y</td>
                        <td><input type="number" class="input" formControlName="Y"></td>
                    </tr>
                    <tr  *ngIf="SelectedElement.get('Type')?.value != 'text'">
                        <td>Width</td>
                        <td> <input type="number" class="input" formControlName="Width"></td>
                    </tr>
                    <tr  *ngIf="SelectedElement.get('Type')?.value != 'text'">
                        <td>Height</td>
                        <td><input type="number" class="input" formControlName="Height"></td>
                    </tr>
                    <tr>
                        <td>Fill</td>
                        <td><app-colorpicker formControlName="Fill" (ColorChanged)="FillColorChanged($event)"></app-colorpicker></td>
                    </tr>
                    <tr>
                        <td>Fill#</td>
                        <td><input type="text" class="input" formControlName="Fill"></td>
                    </tr>
                    <tr>
                        <td>Stroke</td>
                        <td>
                            <div class="field has-addons">
                                <div class="control is-expanded">
                                    <app-colorpicker formControlName="StrokeColor"></app-colorpicker>
                                </div>
                                <div class="control">
                                    <input type="number" class="input" formControlName="StrokeWidth">
                                </div>
                                <div class="control">
                                    <button class="button is-static">px</button>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr  *ngIf="SelectedElement.get('Type')?.value != 'text'">
                        <td>Corners</td>
                        <td> <input type="number" class="input" formControlName="CornerRadius"></td>
                    </tr>
                    <tr *ngIf="SelectedElement.get('Type')?.value == 'text'">
                        <td>Text</td>
                        <td><input type="text" class="input" formControlName="Text"></td>
                    </tr>
                    <tr *ngIf="SelectedElement.get('Type')?.value == 'text'">
                        <td>Font Size</td>
                        <td><input type="number" class="input" formControlName="FontSize"></td>
                    </tr>
                    <tr>
                        <td>Opacity</td>
                        <td><input type="number" step="0.01" min="0" max="1" class="input" formControlName="Opacity"></td>
                    </tr>
                    <tr>
                        <td>Space Type Index</td>
                        <td><input type="number" step="1" min="0" max="6" class="input" formControlName="SpaceTypeCountIndex"></td>
                    </tr>
                    <tr>
                        <td colspan="2" class="has-text-centered">
                            <button type="button" class="button is-danger" (click)="RemoveSelectedElement()">Remove</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="column is-2">
            <div class="designelements">
                <div class="title is-size-7 has-text-centered">LAYERS</div>
                <div *ngFor="let element of GetElementsArray(); let i = index;" class="element" [ngClass]="{ 'selected' : element == SelectedElement}" (click)="SelectElement(element, i)">
                    <i class="fa fa-chevron-up" (click)="MoveElementUp(i)"></i>
                    <i class="fa fa-chevron-down" (click)="MoveElementDown(i)"></i>
                    {{element.get('Type').value}}
                </div>
            </div>
        </div>
    </div>
</div>