<app-loader [Parent]="this"></app-loader>

<div [formGroup]="Form" *ngIf="FormLoaded">
  <app-pageheader [Parent]="this">
    <app-form-save-button [Form]="Form" [Parent]="this" (click)="Save()"></app-form-save-button>
  </app-pageheader>

  <div class="columns">
    <div class="column is-6">
      <article class="panel">
        <p class="panel-heading">Valet Session Details</p>
        <div class="panel-block" style="display: block;">
          <app-field Label="Ticket Number" [HasAddons]="true">
            <div class="Icon"><i class="fa fa-barcode"></i></div>
            <p class="control">
              <input formControlName="TicketNumber" class="input" type="text" [ngClass]="ModelId !== 'new' ? 'disabled' : ''"/>
            </p>
          </app-field>

          <app-field Label="Valet Type" *ngIf="ValetTypes">
            <div class="select">
              <select formControlName="ValetTypeId" (change)="selectValetMethod($event)">
                <option *ngFor="let x of ValetTypes" [value]="x.Id">{{x.Name}}</option>
              </select>
            </div>
          </app-field>

          <app-field Label="First Name">
            <p class="control">
              <input formControlName="FirstName" class="input is-fullwidth" type="text" />
              <app-validator [For]="Form.get('FirstName')"></app-validator>
            </p>
          </app-field>

          <app-field Label="Last Name">
            <p class="control">
              <input formControlName="LastName" class="input is-fullwidth" type="text" />
              <app-validator [For]="Form.get('LastName')"></app-validator>
            </p>
          </app-field>

          <app-field Label="Phone Number">
            <p class="control">
              <input formControlName="PhoneNumber" class="input is-fullwidth" type="text" />
              <app-validator [For]="Form.get('PhoneNumber')"></app-validator>
            </p>
          </app-field>

          <app-field Label="Vehicle Plate Number">
            <p class="control">
              <input formControlName="VehiclePlateNumber" class="input" type="text"/>
            </p>
          </app-field>

          <app-field Label="Vehicle Details" [HasAddons]="true">
            <div class="control">
              <app-search-selector [Items]="Colors" formControlName="VehicleColorName"></app-search-selector>
            </div>
            <p class="control">
              <app-search-selector [Items]="Makes" formControlName="VehicleMakeName"></app-search-selector>
            </p>
          </app-field>

          <app-field Label="Key Location">
            <p class="control">
              <input formControlName="KeysLocation" class="input" type="text"/>
            </p>
          </app-field>

          <app-field Label="Key Tag">
            <p class="control">
              <input formControlName="KeyTagId" class="input" type="text"/>
            </p>
          </app-field>

          <app-field *ngIf="Form.get('ValetTypeBillingKeyName')?.value !== ''" [Label]="Form.get('ValetTypeBillingKeyName')?.value">
            <p class="control">
              <input formControlName="BillingKey" class="input" type="text">
            </p>
          </app-field>

          <app-field Label="Rate Set" *ngIf="RateSets">
            <div class="control select">
              <select formControlName="RateSetId">
                <option *ngFor="let a of RateSets" [ngValue]="a.Id">{{a.Name}}</option>
              </select>
            </div>
          </app-field>

          <app-field Label="Status">
            <p class="control">
              <input formControlName="Status" class="input disabled" type="text"/>
            </p>
          </app-field>
        </div>
      </article>
    </div>
  </div>
</div>
