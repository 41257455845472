import { Component, Injector, OnInit } from '@angular/core';
import { MinLengthValidator, RequiredValidator, UntypedFormControl, Validators } from '@angular/forms';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { sameValueValidator } from 'src/app/shared/forms/validators/samevaluevalidator/samevaluevalidator';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangePasswordComponent extends ModelEditor implements OnInit {

  public ErrorMessage = "";
  public override BlockLoading: boolean = true;

  constructor(private route: ActivatedRoute, private apiService: ApiServiceBase, private injector: Injector, private toastManager: ToastrService) {
    super("user/password", injector);
  }

  public override DefaultModel(): any {
    return {
      ConfirmPassword: "",
      UpdatedPassword: "",
      CurrentPassword: ""
    };
  }
  public override Validators(): any {
    return {
      "ConfirmPassword": [Validators.required, Validators.minLength(5), Validators.maxLength(32), sameValueValidator("UpdatedPassword")],
      "UpdatedPassword": [Validators.required, Validators.minLength(5), Validators.maxLength(32)],
      "CurrentPassword": [Validators.required, Validators.minLength(5), Validators.maxLength(32)],
    }
  }
 
  public override BeforeSave(): boolean | void {
  }

  public trySave() {
    this.Loading();
    if (this.Form.get('UpdatedPassword')?.value != this.Form.get('ConfirmPassword')?.value) {
      this.ErrorMessage = "New password does not match";
    } else {
      this.ErrorMessage = "";
      var newPassword = this.Form.get('UpdatedPassword')?.value;
      var oldPassword = this.Form.get('CurrentPassword')?.value;
      this.apiService.Put<any>("user/password", {UpdatedPassword : newPassword, CurrentPassword: oldPassword}).then(res =>{
        this.toastManager.success("Password Changed Successfully", "Success");
        this.router.navigate(["account"]);
      }).catch(rejected => {
        this.ErrorMessage = rejected.error.Error.Message;
        this.toastManager.error(rejected.error.Error.Message, "Error");
      });
    }
    this.StopLoading();
  }

  public override AfterModelLoaded(): void {
  }

  

 
}