import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { ModalService } from 'src/app/Services/modal.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { LoginService } from 'src/app/auth/login.service';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl, FormArray, FormControl, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ModelSelectorComponent } from '../../../../shared/modals/ModelSelector/modelselector.component';
import { StructureSelectorComponent } from 'src/app/shared/modals/StructureSelector/structureselector.component';


@Component({
  selector: 'app-editpermittype',
  templateUrl: './editpermittype.component.html',
  styleUrls: ['./editpermittype.component.scss']
})
export class EditpermittypeComponent extends ModelEditor implements OnInit {
  public ParkingLots: any[] = [];
  public EndUserRoles: any[] = [];
  public PermitTypes: any[] = [];
  public SpaceTypes: any[] = [];
  public orgService : OrganizationsService;
  public modalService : SimpleModalService;
  public toastService : ToastrService;

  public selectedUserType: string = "";

  constructor(private apiService: ApiServiceBase, private injector: Injector, organizationService: OrganizationsService, modalService: SimpleModalService, toastService: ToastrService, private cdRef: ChangeDetectorRef) {
    super("parking/permittypes", injector);
    this.orgService = organizationService;
    this.modalService = modalService;
    this.toastService = toastService;
  }

  public override DefaultModel(): any {
    return {
      Name: 'New Permit Type',
      ClassName: "ParkingPermitType",
      LimitDailyUses: null,
      MaxUsersLimit: null,
      ActivePermitLimit: null,
      ActiveStartDateLocal: null,
      ActiveEndDateLocal: null,
      PurchaseStartDateLocal: null,
      PurchaseEndDateLocal: null,
      Price: 0,
      ParkingSpaceTypeId: null,
      VehicleModificationTimeout: null,
      MaxVehicles: null,
      RestrictToUserRoles: false,
      MultiUser: false,
      MinUsers:null,
      MaxUsers:null,
      LimitToSpaceTypes: false,
      CanMultiUsersLeave: false,
      RequireConfirmations: false,
      MaxNonConfirmingUsers: null,
      MaxConfirmationFails: null,
      MaxConfirmationDistance: null,
      SplitPayments: false,
      InviteTimeout: null,
      TimeoutAction: 0,
      MaxInviteAttempts: null,
      MaxActivePermits: null,
      EnableWaitlist: false,
      MaxUsesPerPeriod: null,
      MaxUsePeriod: null,
      LimitToParkingSpace: false,
      ChargePeriod: null,
      UserCanCancelRenew: false,
      MutuallyExclusivePermitTypes: [],
      ParkingSpaceTypeRestrictions: [],
      ParkingStructures: [],
      ParkingPermitTypeRoleRestrictions: [],
      RequireRegisteredVehicles: false,
      RestrictTimesOfDay: false,
      SinglePayment: false,
      ParkingPermitTypeDailyUseHours: [],
      DaysUntilCancellation: null,
      ChargePerUser: false,
      PurchasingOption: 0
    }
  }
  public override AfterModelLoaded(): void {
    this.Form.get('MutuallyExclusivePermitTypes')?.valueChanges.subscribe((value) => {
      console.log('Updated form control value:', value);
    });

    var multi = this.Form.get('MultiUser')?.value;
    var split = this.Form.get('SplitPayments')?.value

    if(multi && split){
      this.selectedUserType = "MultiUser";
    }
    else if(multi && !split){
      this.selectedUserType = "PrimaryUser";
    }
    else{
      this.selectedUserType = "SingleUser";
    }

  }

  public updateChargePerUser(event : any){
    this.Form.get('ChargePerUser')?.setValue(event.target.value);
  }

  public UpdateUserSetting(evt : any){
    var value = evt.target.value
    switch(value){
      case "SingleUser":
        this.Form.get('MultiUser')?.setValue(false);
        this.Form.get('SplitPayments')?.setValue(false);
        this.Form.get('CanMultiUsersLeave')?.setValue(true);
        break;
      case "PrimaryUser":
        this.Form.get('MultiUser')?.setValue(true);
        this.Form.get('SplitPayments')?.setValue(false);
        this.Form.get('CanMultiUsersLeave')?.setValue(true);
        break;
      case "MultiUser":
        this.Form.get('MultiUser')?.setValue(true);
        this.Form.get('SplitPayments')?.setValue(true);
        this.Form.get('CanMultiUsersLeave')?.setValue(false);
        break;
    }
  }

  public checkTimeoutAction(evt :any){
    var value = evt.target.value
    switch(value){
      case "2":
        this.Form.get('MaxInviteAttempts')?.setValue(1);
        break;
    }
  }

  public override BeforeSave(): void {
    var x = 1;
  }

  public override Validators(): any {
    return{
      'MaxActivePermits': [Validators.min(0)],
      'MaxUsesPerPeriod': [Validators.min(0)],
      'VehicleModificationTimeout': [Validators.min(0)],
      'MinUsers': [Validators.min(0)],
      'MaxUsers': [Validators.min(0)],
      'MaxNonConfirmingUsers': [Validators.min(0)],
      'MaxConfirmationFails': [Validators.min(0)],
      'MaxConfirmationDistance': [Validators.min(0)],
      'InviteTimeout': [Validators.min(0)],
      'MaxInviteAttempts': [Validators.min(0)],
      'DaysUntilCancellation': [Validators.min(0)],
    };
  }

  public override CanDeactivate(): boolean {
    return true;
  }

  public TimesOfDay(event: any){
    if(!event.target.checked){
      const emptyFormArray = new FormArray([]);
      this.Form.setControl('ParkingPermitTypeDailyUseHours', emptyFormArray);
    }
    else{
      this.AddToFormArray(this.FormArray(this.Form, "ParkingPermitTypeDailyUseHours"), {StartTime: "00:00", EndTime: "23:59", DayOfWeek:0}, "ParkingPermitTypeDailyUseHours");
    }
  }

  public AddParkingStructureRestriction(){
    this.modalService.addModal(StructureSelectorComponent,
      { Structures : this.Form.get('ParkingStructures')?.value})
      .subscribe((result: any) => {
        if (result != null) {
          var fa = this.FormArray(this.Form, "ParkingStructures");
          fa.clear();
          result.forEach((value : any) => {
            var control = new FormControl(value);
            fa.push(control);
          })
          fa.markAsTouched();
          this.Form.markAsDirty();
        }
      });
  }

  public AddPrice(){
    let u = { Id: null, Price: 0, EffectiveStartDateLocal: null};
    this.AddToFormArray(this.Form.get('Prices') as UntypedFormArray, u, "Prices");
  }

  public AddTimeSpan(){
    this.AddToFormArray(this.FormArray(this.Form, "ParkingPermitTypeDailyUseHours"), {StartTime: "00:00", EndTime: "23:59", DayOfWeek:0}, "ParkingPermitTypeDailyUseHours");
  }

  public RemovePrice(index: any){
    this.FormArray(this.Form, "Prices").removeAt(index);
    this.Form.markAsDirty();
  }

  public RemoveTimeSpan(index: any){
    this.FormArray(this.Form, "ParkingPermitTypeDailyUseHours").removeAt(index);
    this.Form.markAsDirty();
  }
}
