import { trigger, transition, style, group, animate, query, animateChild } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { transformScale } from '@turf/turf';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-modal-base',
  template: '<div></div>',
  animations: [
    trigger('modalopen', [
      transition(':enter', [
        style({height:'0'}),
        group([
          animate('.3s ease-in-out', style({height:'*'})),
          query('@*', animateChild(), {optional: true})
        ])
      ]),
      transition('* => close', [
        style({height:'*', opacity:'1'}),
        group([
          query('@*', animateChild(), {optional: true}),
          animate('.3s ease-in-out', style({height:'0'}))
        ])
      ])
    ]),
    trigger('modalbackground', [
      transition(':enter', [
        style({opacity:'0'}),
        group([
          query('@*', animateChild(), {optional: true}),
          animate('.3s ease-in-out', style({opacity: '1'}))
        ])
      ]),
      transition('* => close', [
        style({opacity: '1'}),
        group([
          query('@*', animateChild(), {optional: true}),
          animate('.3s ease-in-out', style({opacity: '0'}))
        ])
      ])
    ])
  ]
})

export class ModalBaseComponent<T, T1> extends SimpleModalComponent<T, T1>{

  Closing: boolean = false;

  confirm() {
    this.Closing = true;
    setTimeout(() => {
      (document.querySelector('.modal-card') as HTMLElement).style.height = '0';
      (document.querySelector('.modal-background') as HTMLElement).style.opacity = '0';
    }, 1);
    setTimeout(() => {
      this.close();
    }, 300);
  }
}
