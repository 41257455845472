import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';

@Component({
  selector: 'app-editspace',
  templateUrl: './editspace.component.html',
  styleUrls: ['./editspace.component.scss']
})
export class EditspaceComponent extends ModelEditor implements OnInit {

  public FirmwareVersions: any = [];

  constructor(
    private apiService: ApiService,
    public organizationsService: OrganizationsService,
    private route: ActivatedRoute,
    private toastService: ToastrService,
    private injector: Injector
  ) {
    super("infrastructure/spaceconfigurations", injector);
  }

  public override DefaultModel() {
    return {
    };
  }

  public override AfterModelLoaded(): void {
    // Any additional logic after the model is loaded
    if(this.Model.Device != null){
      this.apiService.Get("hardware/versions/"+this.Model.Device.HardwareVersionId+"/firmware").then(result => {
        if (result != null) {
          this.FirmwareVersions = result;
        }
      });
    }
  }

  public override BeforeSave(): boolean | void {
    // Any logic to run before saving
  }

  public override Validators() {
    this.Form.get('Name')?.setValidators([Validators.required]);
    this.Form.get('LocationString')?.setValidators([Validators.required]);
    this.Form.get('GuidanceLightPortNumber')?.setValidators([Validators.min(0)]);
  }

  override ngOnInit() {
    super.ngOnInit();
    // Any additional initialization logic
  }
}