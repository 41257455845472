import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { ApiService } from 'src/app/Services/api.service';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { RevenueService } from 'src/app/Services/revenue.service';

@Component({
  selector: 'app-parkingsessionrevenuethisweekbar',
  templateUrl: './parkingsessionrevenuethisweekbar.component.html',
  styleUrls: ['./parkingsessionrevenuethisweekbar.component.scss']
})
export class ParkingsessionrevenuethisweekbarComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | any = null;

  public ChartOptions : ChartOptions = {
    elements: {
      line: {
        tension: 0.2
      }
    },
    plugins: {
      legend : { display: false }
    },
    scales : {
      x: { }, 
      y: { beginAtZero : true}
    }
  };
  public ChartData : ChartData = {
   datasets: []
  };
  public ChartColors: string[] = [
    'green'
  ];

  public TotalTransactions: number = 0;
  public TotalAmount: number = 0;

  constructor(private apiService : ApiService, public organizationService : OrganizationsService, private revenueService : RevenueService) { }

  ngOnInit(): void {
    this.Refresh();
    this.revenueService.TransactionsChanged.subscribe(() => {
      this.revenueService.GetThisWeeksTransactions().then(result => {
        if(result == null)
          return;
        this.TotalTransactions = result.TotalTransactions;
        this.TotalAmount = result.TotalAmount;
        if(this.chart?.chart?.data?.datasets?.length == 1){
          this.chart.chart.data.datasets[0].data = result.Values;
          this.chart.chart.update();
        }
        else{
          this.ChartData =  {
            datasets: [{
                    data: result.Values,
                    label: 'Amount($)',
                    pointBackgroundColor: ['green'],
                    pointBorderColor: ['green'],
                    backgroundColor: ['green'],
                    borderColor: ['green']
            }],
            labels: result.Labels,
          };
        }
      });
    });
  }
  private Refresh(){
    this.revenueService.GetThisWeeksTransactions().then(result =>  {
      if(result == null)
        return;
      this.ChartData =  {
        datasets: [{
                data: result.Values,
                label: 'Amount($)',
                pointBackgroundColor: ['green'],
                pointBorderColor: ['green'],
                backgroundColor: ['green'],
                borderColor: ['green']
        }],
        labels: result.Labels,
      };
      this.TotalTransactions = result.TotalTransactions;
      this.TotalAmount = result.TotalAmount;
    });
  }

}
