<app-pageheader [Parent]="this">
</app-pageheader>
<app-loader [Parent]="Busyable"></app-loader>
<div class="columns">
  <div class="column is-9">
    <div class="columns is-multiline">
      <div class="column is-4">
        <div class="card" style="height: 100%">
          <div class="card-content">
            <div class="field">
              <div class="field-label is-normal">
                <label class="label">Parking Lot</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <div class="select is-fullwidth">
                      <select aria-placeholder="Select a parking lot..." (change)="onParkingLotChange($event)" [value]="SelectedParkingLot?.Id">
                        <option *ngFor="let pl of ParkingLots" [value]="pl.Id">{{pl.Name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-8">
        <div class="card" style="height: 100%">
          <div class="card-content stat-grouping">
            <div class="is-flex">
              <canvas id="parkingStats" 
                      width="120" 
                      height="120" 
                      style="width: 120px !important; height: 120px !important; margin-right: 2rem;">
              </canvas>
              <div class="is-flex-grow-1">
                <div class="field is-horizontal">
                  <div class="field-label is-normal" style="max-width: 120px; margin-right: 0;">
                    <label class="label stat-number" style="font-weight: normal !important;">Total Spaces</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <span class="is-size-5 stat-number" style="font-weight: bolder !important;">{{SelectedParkingLot?.TotalSpaces ?? 0}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field is-horizontal">
                  <div class="field-label is-normal" style="max-width: 120px; margin-right: 0;">
                    <label class="label stat-number" style="font-weight: normal !important; color: #2E8637;">Occupied</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <span class="is-size-5 stat-number" style="font-weight: bolder !important;">{{SelectedParkingLot?.TotalSpaces - SelectedParkingLot?.AvailableSpaces ?? 0}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field is-horizontal">
                  <div class="field-label is-normal" style="max-width: 120px; margin-right: 0;">
                    <label class="label stat-number" style="font-weight: normal !important; color: #505050;">Available</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <span class="is-size-5 stat-number" style="font-weight: bolder !important;">{{SelectedParkingLot?.AvailableSpaces ?? 0}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field is-horizontal">
                  <div class="field-label is-normal" style="max-width: 120px; margin-right: 0;">
                    <label class="label stat-number" style="font-weight: normal !important; color: #d32f2f;">Violations</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <span class="is-size-5 stat-number" style="font-weight: bolder !important;">{{enforcementService.Violations.length}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-12">
        <div class="card">
          <div class="card-content" style="height: 100%">
            <google-map *ngIf="SelectedParkingLot == null || (SelectedParkingLot != null && !SelectedParkingLot.IsMultiLevel)"
              height="600px"
              width="100%"
              [center]="center"
              [zoom]="zoom">
            </google-map>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="column is-3">
    <div class="field">
      <label class="label is-size-4">Violations</label>
    </div>
    <div #scrollContainer class="scrollable-container" style="max-height: 700px; overflow-y: auto;">
      <ng-container *ngIf="enforcementService.Violations != null && enforcementService.Violations.length > 0; else noViolations">
        <ng-container *ngFor="let i of enforcementService.Violations">
          <div class="card mb-2 violation-card" 
               [ngClass]="{'violation-selected': i.Selected}"
               (click)="panToViolation(i)">
            <div class="card-content p-3">
              <div class="violation-header">
                <span class="space-id">Space <strong>{{i.ParkingSpaceName}}</strong> <span class="violation-status" style="margin-left: 1rem; font-weight: 900;" [style.color]="enforcementService.getViolationStatusColor(i.ViolationStatus)">{{i.ViolationStatus}} <i *ngIf="i.ActionedDateLocalFriendlyString" class="fas fa-check"></i></span></span>
                <div class="action-button" *ngIf="i.ViolationStatus === 'Pending' || i.ViolationStatus === 'Vacated without Action'">
                  <button class="button is-primary is-rounded" (click)="openActionViolationModal(i); $event.stopPropagation()">Record Action</button>
                </div>
              </div>
              <div class="violation-times">
                <span class="time-label is-size-7">Start Time: </span>
                <span class="time-value is-size-7"><strong>{{i.ParkingStartDateLocalFriendlyString}}</strong></span>
                <span class="time-label is-size-7" style="margin-left: 1rem;">Violation Began: </span>
                <span class="time-value is-size-7"><strong>{{i.StartDateLocalFriendlyString}}</strong></span>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #noViolations>
        <div class="card mb-2">
          <div class="card-content p-3">
            <p class="has-text-centered">No active violations</p>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
