import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { Datalistviewcolumn, SelectedViewColumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { DatalistviewComponent } from 'src/app/shared/datalistview/datalistview.component';
import { Busyable } from '../../editors/busyable';
import { Datalistrequestfilter } from '../../datalistview/request/datalistrequestfilter';

@Component({
  selector: 'app-actioneventinputhandler',
  templateUrl: './actioneventinputhandler.component.html',
  styleUrls: ['./actioneventinputhandler.component.scss']
})
export class ActionEventInputHandlerComponent extends SimpleModalComponent<any, any>  implements AfterViewInit  {
  
  public EventName !: string;
  public Inputs: any[] = [];
  public KeyValues: any[] = [];

  public apiService !: ApiService;
  public toastService !: ToastrService;
  public Busyable = new Busyable();

  ngAfterViewInit(): void {
   
  }
  
  constructor(apiService: ApiService, toastService: ToastrService) {
    super();
    this.apiService = apiService;
    this.toastService = toastService;
  }

  AddOrChangeValue(key: string, event: any, IsBoolean : boolean = false){ 
    var value;
    if(IsBoolean){
      value = event.target.checked;
    }
    else{
      value = event.target.value;
    }

    var existingItem = this.KeyValues.filter((x : any) => x.Name == key);
    if(existingItem && existingItem.length > 0){
      existingItem[0].Value = value;
    }
    else{
      //doesnt exist so add it.
      this.KeyValues.push({Key: key, Value: value})
    }
  }

  confirm() {
    this.result = { Success: true, KeyValues: this.KeyValues};
    this.close();
  }
}