<div class="modal is-active" [formGroup]="Form">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Payment Terminal</p>
      </header>
      <section class="modal-card-body"style="min-width: 800px;">
        <app-tabs>
            <app-tab Title="Terminal Details">
                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label">Terminal Type</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                        <div class="control">
                            <div class="select">
                                <select formControlName="ProviderType">
                                    <option value=""></option>
                                    <option value="windcave">Windcave SCR</option>
                                </select>
                            </div>
                        </div>
                        </div>
                    </div>  
                </div>
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Serial Number</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                            <p class="control">
                                <input class="input" type="text" placeholder="Serial"  formControlName="SerialNumber" />
                            </p>
                            </div>
                        </div>  
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">IP Address</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                            <p class="control">
                                <input class="input" type="text" formControlName="LocalAddress" />
                            </p>
                            </div>
                        </div>  
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Port</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                            <p class="control">
                                <input class="input" type="text" formControlName="LocalPort" />
                            </p>
                            </div>
                        </div>  
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Enabled for</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <div class="control">
                                    <div class="select">
                                        <select formControlName="ActiveDirection">
                                            <option value="Entry">Entry</option>
                                            <option value="Exit">Exit</option>
                                            <option value="Both">Both</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </app-tab>
            <app-tab Title="Messages">

            </app-tab>
        </app-tabs>
        
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" (click)="confirm()">OK</button>
      </footer>
    </div>
  </div>