<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Form]="Form" [Parent]="this" (click)="Save()"></app-form-save-button>
    </app-pageheader>

    <div class="columns">
        <div class="column is-6">
            <article class="panel">
                <p class="panel-heading">
                  Valet Addon Details
                </p>
                <div class="panel-block" style="display: block;">
                  <app-field Label="Name">
                      <p class="control">
                          <input formControlName="Name" class="input is-fullwidth" type="text" />
                          <app-validator [For]="this.Form.get('Name')"></app-validator>
                      </p>
                  </app-field>
                  <app-field Label="Description">
                    <p class="control">
                      <input formControlName="Description" class="input is-fullwidth" type="text" />
                      <app-validator [For]="this.Form.get('Description')"></app-validator>
                    </p>
                  </app-field>
                  <app-field Label="Icon" [HasAddons]="true">
                    <div class="Icon"><i [ngClass]="'fa fa-' + this.Form.get('IconName')?.value" [ngStyle]="{color: '#' + this.Form.get('IconColor')?.value}"></i></div>
                    <p class="control">
                        <input [value]="IconDisplayName" class="input disabled" type="text"/>
                    </p>
                    <div class="control">
                        <app-colorpicker formControlName="Color"></app-colorpicker>
                    </div>
                    <div class="control is-fullwidth">
                        <app-fa-iconselector formControlName="IconName"></app-fa-iconselector>
                    </div>
                  </app-field>
                  <app-field Label="Amount" *ngIf="!HasOptions">
                    <p class="control">
                      <app-currency-input formControlName="Amount"></app-currency-input>
                    </p>
                  </app-field> 
                  <app-field Label="Requires Extra Time" [HasAddons]="Form.get('RequiresExtraTime')?.value" tippy="Will this require any time to achieve. Set a default time ">
                    <p class="control">
                      <app-toggleswitch formControlName="RequiresExtraTime"></app-toggleswitch>
                    </p>
                    <p class="control" *ngIf="Form.get('RequiresExtraTime')?.value" style="margin-left:10px;">
                      <input formControlName="DefaultExtraTime" class="input" type="number" min="0"/>
                      <app-validator [For]="this.Form.get('DefaultExtraTime')"></app-validator>
                    </p>
                    <div class="control" *ngIf="Form.get('RequiresExtraTime')?.value">
                      <button class="button is-static"> Minutes </button>
                    </div>
                  </app-field>
                  

                <app-field Label="Has options">
                    <p class="control">
                      <app-toggleswitch [value]="HasOptions" (change)="ToggleType($event)" [ngClass]="{ 'disabled': Form.get('Id')?.value !== null }"></app-toggleswitch>
                    </p>
                </app-field>
                <app-field [HasAddons]="true" *ngIf="HasOptions">
                  <div class="control flex-grow" formArrayName="Options">
                      <table class="table is-striped is-bordered input-table">
                          <thead>
                              <tr>
                                  <th>Option</th>
                                  <th>Amount</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr [formGroupName]="i"
                                  *ngFor="let line of FormArray(Form, 'Options').controls; let i = index">
                                  <td>
                                    <p class="control">
                                      <input formControlName="Description" class="input is-fullwidth" type="text" />
                                      <app-validator [For]="line.get('Description')"></app-validator>
                                    </p>
                                  </td>
                                  <td>
                                    <p class="control">
                                      <app-currency-input formControlName="Amount"></app-currency-input>
                                    </p>
                                  </td>
                                  <td>
                                      <div class="control">
                                          <button class="button is-light" (click)="RemoveOption(i)">
                                              <i class="fa fa-trash"></i>
                                          </button>
                                      </div>
                                  </td>
                              </tr>
                          </tbody>
                          <tr>
                              <td colspan="4">
                                  <div class="button is-primary" (click)="AddOption()">
                                      Add Option
                                  </div>
                              </td>
                          </tr>
                      </table>
                  </div>
              </app-field>
                </div>
              </article>
        </div>
    </div>