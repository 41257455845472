  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Terminal</p>
      </header>
      <section class="modal-card-body" style="min-width: 800px;">
        <app-tabs>
          <app-tab Title="Create New" [formGroup]="Form">

            <app-field Label="Controller Name">
              <div class="control">
                <input type="text" class="input" formControlName="Name">
                <app-validator [For]="Form.get('Name')"></app-validator>
              </div>
            </app-field>
            <app-field Label="Serial Number">
              <div class="control">
                <input type="text" class="input" formControlName="SerialNumber">
                <app-validator [For]="Form.get('SerialNumber')"></app-validator>
              </div>
            </app-field>
            <app-field Label="Relay Ip Address">
              <div class="control">
                <input type="text" class="input" formControlName="RelayIpAddress">
                <app-validator [For]="Form.get('RelayIpAddress')"></app-validator>
              </div>
            </app-field>
            <app-field Label="Relay Port">
              <div class="control">
                <input type="Number" min="0" class="input" formControlName="RelayPort">
                <app-validator [For]="Form.get('RelayPort')"></app-validator>
              </div>
            </app-field>
            <app-field Label="Power Relay Type">
              <div class="control">
                <div class="select">
                  <select name="RelayType" formControlName="RelayType">
                    <option value=""></option>
                    <option value="tcp-kp-1202">1202 Relay</option>
                  </select>
                </div>
                <app-validator [For]="Form.get('RelayType')"></app-validator>
              </div>
            </app-field>
            <app-field Label="Location">
              <div class="control">
                <app-map-input MapWidth="500" formControlName="LocationPoints" DefaultMode="marker"></app-map-input>
              </div>
            </app-field>

            <button class="button is-success" (click)="CreateNew()">Add New Terminal</button>

          </app-tab>
          <app-tab Title="Select Existing">
            <div class="columns">
              <div class="column is-6">
                <table class="table is-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Used By</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let control of ExistingControllers.controls">
                      <td>
                        {{control.get('Name')?.value}}
                      </td>
                      <td>
                        <span *ngFor="let gate of Gates.controls">
                          <span *ngFor="let lane of $any(gate.get('Lanes')).controls">
                            <span *ngIf="lane.get('LocalControllerId')?.value == control.get('Id')?.value">
                              {{lane.get('Name')?.value}}
                            </span>
                          </span>
                        </span>
                      </td>
                      <td>
                        <button type="button" class="button is-primary" (click)="ExistingSelected(control)">Select</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="column is-6">
                
              </div>
            </div>
            
          </app-tab>
        </app-tabs>

      </section>
      <footer class="modal-card-foot">
      </footer>
    </div>
  </div>