import { ChangeDetectorRef, Component, Host, Input, OnInit, Optional, Self, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormGroupDirective, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

@Component({
  selector: 'app-emailtaglist',
  templateUrl: './emailtaglist.component.html',
  styleUrls: ['./emailtaglist.component.scss'],
})

export class EmailTagListComponent implements OnInit, ControlValueAccessor {

  @Input() 
  Property: string = "Email";

  @Input()
  public Items: any[] = []

  @Input()
  public AllowCreateUser: boolean = false;

  @Input()
  public MaxUsers: number = 0;

  public EmailValid: boolean = false;

  public SetEmail: string = "";

  @Input() public formArrayName !: string; // Name of the FormArray in the parent FormGroup

  public formArray: FormArray | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    @Optional() @Host() private formGroupDirective: FormGroupDirective,
  ) {}

  ngOnInit() {
    if (this.formGroupDirective && this.formArrayName) {
      this.formArray = this.formGroupDirective.form.get(this.formArrayName) as FormArray;

      console.log(this.MaxUsers +  " Max Users");
    }
  }

  public removeUser(index: number){
    this.formArray?.removeAt(index);
    this.formArray?.markAsDirty();
  }

  addUser() {
    if (this.formArray) {
      const newUser = this.formBuilder.group({
        Email: [null]
      });

      this.formArray.push(newUser);
      this.changeDetector.detectChanges();
    }
    this.SetEmail = "";
  }

   // Function to call when the value changes.
   private onChange(value: any) {
      
   };
 
   // Function to call when the control is touched (optional).
   private onTouched() {
     
   };
 
   // Write value from external form control to the component.
   writeValue(value: any): void {
     if (this.formArray) {
       this.formArray.patchValue(value);
     }
   }
 
   // Save the function to call when the value changes.
   registerOnChange(fn: (value: any) => void): void {
     this.onChange = fn;
   }
 
   // Save the function to call when the control is touched (optional).
   registerOnTouched(fn: () => void): void {
     this.onTouched = fn;
   }
 
   // Set the disabled state of the component (optional).
   setDisabledState?(isDisabled: boolean): void {
     if (this.formArray) {
       isDisabled ? this.formArray.disable() : this.formArray.enable();
     }
   }

   public SetNewEmail(event: any) {
    this.SetEmail = event.target.value;
    this.EmailValid = /^\w+([-.\w]*)@\w+([-.]\w+)*\.\w{2,4}$/.test(this.SetEmail);

    // Manually set the validity of the email input field
    const itemControl = this.formArray?.at(this.formArray?.controls.length - 1);
    if (itemControl) {
      if (this.EmailValid) {
        itemControl.get('Email')?.setErrors(null); // Valid email, set errors to null
      } else {
        itemControl.get('Email')?.setErrors({ 'invalidEmail': true }); // Invalid email, set custom error
      }
    }
  }

  public onEnterKeyPressed(event: any) {
    if (this.EmailValid) {
      const itemControl = this.formArray?.at(this.formArray?.controls.length - 1);
      if (itemControl) {
        this.AddEmailToList(itemControl);
      }
    }
  }

   public AddEmailToList(itemcontrol: any){
    itemcontrol.get('Email').setValue(this.SetEmail);
    this.formArray?.markAsDirty();
   }

   public RemoveItem(index: any){
    this.formArray?.removeAt(index);
    this.formArray?.markAsDirty();
   }
}
