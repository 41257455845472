import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-editcontrolledareagate',
  templateUrl: './editcontrolledareagate.component.html',
  styleUrls: ['./editcontrolledareagate.component.scss']
})
export class EditcontrolledareagateComponent  extends SimpleModalComponent<any, null> {
  Form !: UntypedFormGroup;
  constructor() {
    super();
  }

  confirm() {
    this.close();
  }

}
