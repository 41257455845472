import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, Self, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-dropdown-multiselector',
  templateUrl: './multiselect-dropdown.component.html',
  styleUrls: ['./multiselect-dropdown.component.scss']
})
export class MultiSelectDropdownComponent implements OnInit, ControlValueAccessor {

  @Output()
  public ValueChanged = new EventEmitter<any>();

  @Input()
  public Options: any[] = [];

  @Input()
  public LabelProperty: string = "Name";

  @Input()
  public ValueProperty: string = "Value";

  public DropdownActive: boolean = false;
  SelectedOptions: any[] = [];
  public DisplayString: string = "None Selected";

  public SelectAllText = "Select All";
  public AllSelected: boolean = false;

  constructor(private elementRef: ElementRef, @Self() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  toggleDropdown() {
    this.DropdownActive = !this.DropdownActive;
  }

  public writeValue(value: any): void { 
    this.SelectedOptions = value || [];
  }

  public onChange = (item: any) => {
    this.ValueChanged.emit(item);
   };
   
  public registerOnChange(fn: any): void { this.onChange = fn; }
  public onTouched = () => { };
  public registerOnTouched(fn: any): void { this.onTouched = fn; }
  ngOnInit(): void {
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.DropdownActive = false;
    }
  }

  getValuesArray(): any[] {
    return this.SelectedOptions.flatMap((item: any) => {
      return item[this.ValueProperty];
    });
  }

  toggleSelection(option: any) {
    var selection = option[this.ValueProperty];
    if (this.isSelected(option)) {
      this.SelectedOptions = this.SelectedOptions.filter(item => item[this.ValueProperty] !== selection);
    } else {
      this.SelectedOptions.push(option);
    }
    this.updateName();
    this.onChange(this.getValuesArray());
  }

  isSelected(option: any): boolean {
    return this.SelectedOptions.some(item => item[this.ValueProperty] === option[this.ValueProperty]);
  }

  updateName() {
    if(this.SelectedOptions.length == 0) {
      this.DisplayString = "None Selected";
      this.SelectAllText = "Select All";
      return;
    } else if (this.SelectedOptions.length == this.Options.length) {
      this.DisplayString = "All Selected";
      this.SelectAllText = "Deselect All";
      this.AllSelected = true;
      return;
    }

    this.AllSelected = false;
    this.SelectAllText = "Deselect All";

    var label = this.SelectedOptions.flatMap((item: any) => item[this.LabelProperty]).join(", ");
    if (label.length > 25) {
      this.DisplayString = this.SelectedOptions.length + " selected";
    } else {
      this.DisplayString = label;
    }
  }

  toggleSelectAll() {
    if(!this.AllSelected && this.SelectedOptions.length == 0) {
      this.SelectedOptions = [...this.Options];
      this.AllSelected = true;
    } else {
      this.AllSelected = false;
      this.SelectedOptions= [];
    }

    this.updateName();
    this.onChange(this.getValuesArray());
  }
}
