import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { LoginService } from 'src/app/auth/login.service';
import { Datalistviewcolumn, Linkcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { DatalistviewComponent } from 'src/app/shared/datalistview/datalistview.component';
import { Busyable } from 'src/app/shared/editors/busyable';
import { environment } from 'src/environments/environment';
import { EditValidationCodeComponent } from './editcodes/editvalidationcode.component';
@Component({
  selector: 'app-validationprofileuser',
  templateUrl: './validationprofileuser.component.html',
  styleUrls: ['./validationprofileuser.component.scss']
})
export class ValidationProfileUserComponent extends Busyable implements OnInit{
  
  public ListId : any;
  public ModelId : any;
  public Model: any;
  public CodesApiUrl: any;
  public ApiUrl: any;
  public modalService : SimpleModalService;  
  public toastService : ToastrService;
  public loginService : LoginService;
  @ViewChild("DataList")
  public DataList!: DatalistviewComponent;

  public Columns: Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Code", "Code"),
    new Datalistviewcolumn("Uses", "Uses"),
    new Datalistviewcolumn("Max Uses", "MaxUses"),
    new Datalistviewcolumn("Issued", "ActiveStartDateLocalFriendlyString"),
    new Datalistviewcolumn("Expires", "ActiveEndDateLocalFriendlyString"),
    new Datalistviewcolumn("Status", "Status"),
  ];

  public Actions: any[] = [
    {text: "Revoke", action:(code: any) => this.RevokeCode(code)},
  ];

  constructor(private apiService: ApiService, private parentRoute: ActivatedRoute, modalService: SimpleModalService, toastService: ToastrService, loginService: LoginService) {
    super();
    this.modalService = modalService;
    this.toastService = toastService;
    this.loginService = loginService;
    this.Columns.push(new Linkcolumn(environment.serverBaseUrl+"/parking/validation/codes/{0}/document?token="+encodeURIComponent(this.loginService.CurrentToken()), "Id", null, 'fa-regular fa-print', "Status", "Active"));
}

  ngOnInit(): void {
    this.Loading();
    this.parentRoute.params.subscribe(params => { this.ListId = params['listid']; this.ModelId = params['id'] });
    this.ApiUrl = "parking/validation/profiles/" + this.ListId + '/users/' + this.ModelId;
    this.CodesApiUrl = "parking/validation/profiles/" + this.ListId + '/users/' + this.ModelId + '/codes/searches';
    this.apiService.Get<any>(this.ApiUrl).then(result => {
        this.Model = result;
        this.StopLoading();
    });
  }

  
  public CreateCode() {
    this.modalService.addModal(EditValidationCodeComponent, { "ValidationProfileId": this.Model.ValidationProfileId , "ValidationUserId": this.Model.Id })
      .subscribe((result: any) => {
        if (result != null) {
          if (result == true) {
            this.toastService.success('Code created', 'Saved');
            this.DataList.Search();
          }
        }
      });
  }

  public RevokeCode(x: any) {
    this.apiService.Delete("parking/validation/profiles/" + this.Model.ValidationProfileId + '/users/' + this.Model.Id + "/codes/" + x.Id, {}).then(result => {
      this.toastService.success('Validation code revoked successfully', 'Saved');
      this.DataList.Search();
    });
  }
}
