import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ModalBaseComponent } from 'src/app/shared/modals/modal-base/modal-base.component';

@Component({
  selector: 'app-editcontrolledareauserinput',
  templateUrl: './editcontrolledareauserinput.component.html',
  styleUrls: ['./editcontrolledareauserinput.component.scss']
})
export class EditcontrolledareauserinputComponent extends ModalBaseComponent<any, null> {
  Form !: UntypedFormGroup;
  constructor() {
    super();
  }
}