<app-pageheader [Parent]="this">
  <add-button AddRoute="settings/alerttriggers/new/edit"></add-button>
</app-pageheader>

<app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="alerts/triggers/searches"
  [AllowEdit]="true" EditUrl="settings/alerttriggers/:id/edit">
  <app-filter-set>
    <div app-search-filter></div>
    <div app-search-button></div>
  </app-filter-set>
</app-datalistview>