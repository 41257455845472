import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';

@Component({
  selector: 'app-platematchcharacters',
  templateUrl: './platematchcharacters.component.html',
  styleUrls: ['./platematchcharacters.component.scss']
})
export class PlatematchcharactersComponent extends ModelEditor {

  public Chars : string[] = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','1','2','3','4','5','6','7','8','9','0'];
  public Shades: string[] = ['#ffffff', '#e6f1eb','#cce3d7','#b3d5c3','#99c7af','#99c7af','#80b99b','#66aa87','#4d9c73','#338e5f', '#1a804b'];
  public CharArray : any|null = null;
  public highlightX : string = '';
  public highlightY : string = '';

  constructor(private injector: Injector) { 
    super("parking/platematching/characters", injector);
   
  }
  public override DefaultModel() : any{
    return {};
  }
  public override AfterModelLoaded(): void {
    this.CharArray = {};
    for(let c1 of this.Chars){
      let arr : any = {};
      this.CharArray[c1] = arr;
      for(let c2 of this.Chars){
        arr[c2] = '';
        for(let x of this.Model.Characters){
          if(x.Char == c1 && x.ToChar == c2){
            arr[c2] = x.Probability;
          }
        }
      }
    }
  }
  public override BeforeSave(): void {
    let values : any[] = [];
    for(let c1 of this.Chars){
      for(let c2 of this.Chars){
        let val = this.CharArray[c1][c2];
        if(val != '' && val > 0){
          values.push({ Char: c1, ToChar: c2, Probability: val});
        }
      }
    }
    this.Form = new UntypedFormGroup({});
    this.PopulateFormGroupFromModel( { Characters: values }, this.Form, "");
  }
  public override Validators() : any {
      return { };
  }


  public ValChanged(char1 : string, char2 : string){
    let inverse = this.CharArray[char2][char1];
    if(inverse == null || inverse == ''){
      this.CharArray[char2][char1] = this.CharArray[char1][char2];
      this.Form.markAsDirty();
    }
  }

  public GetColor(char1 : string, char2 : string) : string{
    if(char1 == char2) return "#dddddd";
    let value = this.CharArray[char1][char2];
    if(value == null) return "#ffffff";

    return this.Shades[Math.round(value/10)];
  }
}
