import { FormControl } from "@angular/forms";
import { LevelEditorComponent } from "../../leveleditor.component";
import { Tool } from "../tool";
import { PointMapComponent } from "../../components/PointComponents/PointMapComponent";
import { MapComponentBase } from "../../components/MapComponentBase";

export class PointTool extends Tool {
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }

    public override CreateNew(canvasX : number, canvasY: number): MapComponentBase | null {
        return new PointMapComponent(this.editor, this.canvas, new FormControl(), null);
    }

    public CreatePointFromXY(x: number, y: number) {
        var zoom = this.canvas.getZoom();
        // Get the current viewport transformation
        var viewportTransform = this.canvas.viewportTransform;
        if (viewportTransform) {
            var translateX = viewportTransform[4];
            var translateY = viewportTransform[5];
            x = (x - translateX) / zoom;
            y = (y - translateY) / zoom;
            return [x / this.ScaleFactor / zoom, y / this.ScaleFactor / zoom]
        }
        return null;
    }
}
