import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';

@Component({
  selector: 'app-editguidancelight',
  templateUrl: './editguidancelight.component.html',
  styleUrls: ['./editguidancelight.component.scss']
})
export class EditGuidanceLightComponent extends ModelEditor implements OnInit {

  public DisplayRules: any = [];
  private searchResults: any = { PageNumber: 1, PageSize: 0, Results: null };
  public FirmwareVersions: any = [];
  public InitialGuidanceLight: any | null = null;

  constructor(private apiService: ApiService, public organizationsService: OrganizationsService, private route: ActivatedRoute, private toastService: ToastrService, private modalService: SimpleModalService, private injector: Injector) {
    super("infrastructure/guidancelights", injector);
    // this.apiService.Post("infrastructure/displayrulesets/searches",  {PageNumber: 1, PageSize: 999}).then(result => {
    //   if (result != null){
    //     this.searchResults = result;
    //     this.DisplayRules = this.searchResults.Results;
    //   }
    // });
  }

  public AddPortAssignment() {
    ///if(this.Model.RuleLines == null) this.Model.RuleLines = [];
    let newline = { PortNumber: 0, ParkingSpaceId: null}
    this.AddToFormArray(this.Form.get('PortAssignments') as UntypedFormArray, newline, "PortAssignments");
  }

  public RemovePortAssignment(index: number) {
    this.FormArray(this.Form, "PortAssignments").removeAt(index);
    this.Form.markAsDirty();
  }

  
  public override DefaultModel() {
    return {};
  }
  
  public override AfterModelLoaded(): void {
    if(this.Model.Device != null){
      this.apiService.Get("hardware/versions/"+this.Model.Device.HardwareVersionId+"/firmware").then(result => {
        if (result != null) {
          this.FirmwareVersions = result;
        }
      });
    }
  }
  
  public override BeforeSave(): boolean | void {
  }
  public override Validators() {
  }
  
}
