import { AnimationMetadataType, animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ApiService } from 'src/app/Services/api.service';
import { ModalBaseComponent } from 'src/app/shared/modals/modal-base/modal-base.component';

@Component({
  selector: 'app-editlegacysigndisplayrule',
  templateUrl: './editlegacysigndisplayrule.component.html',
  styleUrls: ['./editlegacysigndisplayrule.component.scss'],
  animations: [
    trigger('leftpanelslide', [ 
      state('full', style({ width: '100%' })),
      state('half', style({ width: '50%' })),
      state('smallinit', style({width: '50%'})),
      state('biginit', style({width: '100%'})),
      transition('* => half', [
        query('@*', animateChild(), {optional: true}),
        animate('.3s ease-in-out')
      ]),
      transition('* => full', [
        query('@*', animateChild(), {optional: true}),
        animate('.3s ease-in-out')
      ]),
      transition('* => smallinit', [
        style({width: '50%'}),
        query('@*', animateChild(), {optional: true})
      ]),
      transition('* => biginit', [
        style({width: '100%'}),
        query('@*', animateChild(), {optional: true})
      ])
    ]),
    trigger('rightpanelslide', [ 
      transition(':enter', [
        style({width:'0', opacity: '0'}),
        query('@*', animateChild(), {optional: true}),
        animate('.3s ease-in-out', style({width:'50%'})),
        animate('.1s ease-in-out', style({opacity:'1'}))
      ]),
      transition(':leave', [
          style({width:'50%', opacity: '1'}),
          group([
            query('@*', animateChild(), {optional: true}),
            animate('.1s ease-in-out', style({opacity:'0'})),
            animate('.3s ease-in-out', style({width:'0'}))
          ])
      ])
    ]),
    trigger('rightpaneldisplay', [ 
      transition(':enter', [
        style({width:'0', opacity: '0'}),
        query('@*', animateChild(), {optional: true}),
        animate('.3s ease-in-out', style({width:'50%'})),
        animate('.1s ease-in-out', style({opacity:'1'}))
      ]),
      transition(':leave', [
          style({width:'50%', opacity: '1'}),
          group([
            query('@*', animateChild(), {optional: true}),
            animate('.1s ease-in-out', style({opacity:'0'})),
            animate('.3s ease-in-out', style({width:'0'}))
          ])
      ])
    ]),
    trigger('leftpaneldisplay', [ 
      state('full', style({ width: '100%' })),
      state('half', style({ width: '50%' })),
      state('smallinit', style({width: '50%'})),
      state('biginit', style({width: '100%'})),
      transition('* => half', [
        group([
          query('@*', animateChild(), {optional: true}),
          animate('.3s ease-in-out')
        ])
      ]),
      transition('* => full', [
        group([
          query('@*', animateChild(), {optional: true}),
          animate('.3s ease-in')
        ])
      ]),
      transition('* => smallinit', [
        style({width: '50%'}),
        query('@*', animateChild(), {optional: true})
      ]),
      transition('* => biginit', [
        style({width: '100%'}),
        query('@*', animateChild(), {optional: true})
      ])
    ])
  ]
})

export class EditlegacysigndisplayruleComponent extends ModalBaseComponent<any, null> implements OnInit, AfterViewInit {
  Form !: UntypedFormGroup;
  TargetType !: string;

  public ModelProperties: any = [];
  DataTypeClass: any = '';
  SecondDataTypeClass: any = '';
  DataTypeMap: Map<string, string> = new Map<string, string>();

  HalfWidth: boolean = false;
  FullPanel: boolean = false;
  DisablePanelAnim: boolean = true;

  constructor(private apiService: ApiService) {
    super();
    this.DataTypeMap.set('88', 'fa-times')
    this.DataTypeMap.set('160', 'fa-arrow-up');
    this.DataTypeMap.set('161', 'fa-arrow-up-right');
    this.DataTypeMap.set('162', 'fa-arrow-right');
    this.DataTypeMap.set('163', 'fa-arrow-down-right');
    this.DataTypeMap.set('164', 'fa-arrow-down');
    this.DataTypeMap.set('165', 'fa-arrow-down-left');
    this.DataTypeMap.set('166', 'fa-arrow-left');
    this.DataTypeMap.set('167', 'fa-arrow-up-left');
    setTimeout(() => {
      this.DisablePanelAnim = false;
    }, 500);
  }
  ngOnInit(): void {
    var type = this.Form.get('DataType')?.value;
    this.DataTypeClass = this.DataTypeMap.get(type);
    var secondType = this.Form.get('SecondDisplayDataType')?.value;
    this.SecondDataTypeClass = this.DataTypeMap.get(secondType);
    this.HalfWidth = this.Form.get('Mode')?.value == 2;
    this.FullPanel = this.Form.get('Mode')?.value == 2;
    this.apiService.Get("common/modeltypes/"+this.TargetType+"/properties").then(result => {
      this.ModelProperties = result;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      (document.querySelector('.modal-background') as HTMLElement).style.opacity = '1';
    }, 1);
  }

  DataTypeChanged(): void {
    var type = this.Form.get('DataType')?.value;
    this.DataTypeClass = this.DataTypeMap.get(type);
  }

  SecondDataTypeChanged(): void {
    var secondType = this.Form.get('SecondDisplayDataType')?.value;
    this.SecondDataTypeClass = this.DataTypeMap.get(secondType);
  }

  ModeChanged() : void {
    if (this.Form.get('Mode')?.value == 1) {
      this.HalfWidth = false;
      this.FullPanel = false;
    } else {
      this.HalfWidth = true;
      this.FullPanel = true;
    }
  }
}
