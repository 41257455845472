import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MapInfoWindow, MapMarker, MapPolygon } from '@angular/google-maps';
import { LotmultiviewerComponent } from 'src/app/components/lotmultiviewer/lotmultiviewer.component';
import { ApiService } from 'src/app/Services/api.service';
import { FeaturesService } from 'src/app/Services/features.service';
import { Busyable } from 'src/app/shared/editors/busyable';

@Component({
  selector: 'app-permitshome',
  templateUrl: './permitshome.component.html',
  styleUrls: ['./permitshome.component.scss']
})
export class PermitshomeComponent extends Busyable implements OnInit, AfterViewInit{

  public apiService : ApiService;
  public response: any = [];
  public alerts: any = [];
  public rightColumnheight: number = 0;
  public cdr: ChangeDetectorRef;
  public showAlerts: boolean = false;

  @ViewChild("leftColumn")
  public leftColumn!: ElementRef;

  constructor(private featuresService: FeaturesService, apiservice: ApiService, cdr: ChangeDetectorRef) { 
    super();
    console.log("Permits Home Constructor");
    this.apiService = apiservice;
    this.cdr = cdr;
  }

  public UpdateColumnHeight(){
    if (this.leftColumn) {
      this.rightColumnheight = this.leftColumn.nativeElement.offsetHeight;
      console.log(this.rightColumnheight)
    }
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    this.UpdateColumnHeight();
  }


  ngOnInit(): void {
    this.Loading();
    this.apiService.Get('/parking/permits/summary').then((response: any) => {
      this.response = response
      this.StopLoading();
      this.showAlerts = true;
    })

    // this.alerts.push({icon: "car", iconcolor: "red", text: "You are in trouble"});
    // this.alerts.push({icon: "thumbs-down", iconcolor: "red", text: "Oh no, something broke"});
    // this.alerts.push({icon: "gear", iconcolor: "orange", text: "We are having problems"});
    // this.alerts.push({icon: "wifi", iconcolor: "red", text: "Connection issues"});
    // this.alerts.push({icon: "users", iconcolor: "dark-yellow", text: "Users have broken something"});


  }
}
