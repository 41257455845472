import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Busyable } from 'src/app/shared/editors/busyable';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { ApiService } from 'src/app/Services/api.service';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ModalService } from 'src/app/Services/modal.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { StructureSelectorComponent } from 'src/app/shared/modals/StructureSelector/structureselector.component';
import { FormControl } from '@angular/forms';
import { EditDiscountCodeComponent } from '../../edit/editdiscountcode.component';

@Component({
  selector: 'app-editdiscounttype',
  templateUrl: './editdiscounttype.component.html',
  styleUrls: ['./editdiscounttype.component.scss']
})

export class EditDiscountTypeComponent extends ModelEditor implements OnInit {
  public modalService : SimpleModalService;
  constructor(private injector: Injector, modalService: SimpleModalService) {
    super("parking/discounts", injector);
    this.modalService = modalService;
  }
  
  public override DefaultModel() {
    return {
      Name: "New Discount Type",
      ClassName: "FreeParkingDiscountType",
      DiscountAmount: 0,
      ActiveEndDateLocal: null,
      ActiveStartDateLocal: null,
      AutoGenerateCodes: false,
      GenerationTime: null,
      GenerationFrequency: null,
      GenerationFrequencyUnits: 2,
      DefaultCodeValidTime: null,
      DefaultCodeValidTimeUnits: 2,
      DefaultMaxUsesPerCode: null,
      CodeTemplate: "XXX-XXX",
      RateSetRestrictions: [],
      ParkingStructures: [],
      UseHoursRestrictions: [],
    }
  }

  public AddTimeSpan(){
    this.AddToFormArray(this.FormArray(this.Form, "UseHoursRestrictions"), {StartTime: "00:00", EndTime: "23:59", DayOfWeek:0}, "UseHoursRestrictions");
  }

  public RemoveTimeSpan(index: any){
    this.FormArray(this.Form, "UseHoursRestrictions").removeAt(index);
    this.Form.markAsDirty();
  }

  public AddParkingStructureRestriction(){
    this.modalService.addModal(StructureSelectorComponent,
      { Structures : this.Form.get('ParkingStructures')?.value})
      .subscribe((result: any) => {
        if (result != null) {
          var fa = this.FormArray(this.Form, "ParkingStructures");
          fa.clear();
          result.forEach((value : any) => {
            var control = new FormControl(value);
            fa.push(control);
          })
          fa.markAsTouched();
          this.Form.markAsDirty();
        }
      });
  }

  public override AfterModelLoaded(): void {
    if(this.Form.get('Id') != null)
      this.Form.get('ClassName')?.disable();
  }
  public override BeforeSave(): boolean | void {
  }
  public override Validators() {
  }
}