import { Component, Input, OnInit, Self, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { IconService } from 'src/app/Services/icon.service';

@Component({
  selector: 'app-fa-iconselector',
  templateUrl: './fa-iconselector.component.html',
  styleUrls: ['./fa-iconselector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FaIconSelector),
      multi: true,
    },
  ],
})

export class FaIconSelector implements OnInit, ControlValueAccessor {

  constructor(private iconService: IconService) { }
  
  @Input() 
  value: any;

  searchQuery: string = '';
  ShowIconDropdown: boolean = false;

  filteredIcons: { class: string, name: string }[] = [];
  faIconSet: { class: string, name: string }[] = []

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  handleItemClick(item: { class: string, name: string }): void {
    this.onChange(item.class);
  }

  filterIcons(): void {
    this.filteredIcons = this.faIconSet.filter(icon => icon.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
  }

  ngOnInit(): void {
    this.faIconSet = this.iconService.getIcons();
    this.filteredIcons = this.iconService.getIcons();
  }

  preventDropdownClose(event: MouseEvent): void {
    event.stopPropagation();
  }

}
