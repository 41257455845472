<app-loader [Parent]="this"></app-loader>
<app-pageheader [Parent]="this">
    <button type="button" class="button is-primary " routerLink="/parking/accesscontrol/terminalconfigurations/new/edit" routerLinkActive="active">
        <span class="icon-text">
            <span class="icon">
                <i class="fa fa-plus"></i>
            </span>
            <span>Add New</span>
        </span>
    </button>
</app-pageheader>


<div *ngFor="let workflow of Workflows" class="card" style="  border-style: outset;
border-color: lightgray; height: 16em;"
  routerLink="/parking/accesscontrol/terminalconfigurations/{{workflow.Id}}/edit">
  <div class="card-image">
    <figure class="image is-4by"
      [innerHTML]="sanitizer.bypassSecurityTrustHtml(DisplayWorkFlowThumbnail(workflow.MediaId))">
    </figure>
  </div>
  <div class="card-content">
    <div class="media">
      <div class="media-content">
        <p class="title is-5">{{workflow.Name}}</p>
      </div>
    </div>
  </div>
</div>