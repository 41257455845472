import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ClassedViewColumn, Datalistviewcolumn, DateRangeViewColumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { ApiService } from 'src/app/Services/api.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { EditDiscountCodeComponent } from '../../edit/editdiscountcode.component';
import { ToastrService } from 'ngx-toastr';
import { DatalistviewComponent } from 'src/app/shared/datalistview/datalistview.component';

@Component({
  selector: 'app-discounttype',
  templateUrl: './discounttype.component.html',
  styleUrls: ['./discounttype.component.scss']
})

export class DiscountTypeComponent extends Busyable implements OnInit {
  public ModelId : any;
  public Model: any;
  public ApiUrl: any;
  public modalService : SimpleModalService;  
  public toastService : ToastrService;
  @ViewChild("DataList")
  public DataList!: DatalistviewComponent;

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Code", "Code"),
    new Datalistviewcolumn("Times Used", "Uses"),
    new Datalistviewcolumn("Maximum uses", "MaxUses"),
    new DateRangeViewColumn("ActiveDates", "ActiveStartDateLocalFriendlyString", "ActiveEndDateLocalFriendlyString"),
    new ClassedViewColumn("Status", "Status")
  ];

  public Actions: any[] = [
    {text: "Edit", action:(code: any) => this.EditDiscountCode(code)},
    {text: "Revoke", action:(code: any) => this.RevokeCode(code)},
  ];

  constructor(private apiService: ApiService, private parentRoute: ActivatedRoute, modalService: SimpleModalService, toastService: ToastrService) {
      super();
      this.modalService = modalService;
      this.toastService = toastService;
  }

  ngOnInit(): void {
    this.Loading();
    this.parentRoute.params.subscribe(params => { this.ModelId = params['id']; });
    this.ApiUrl = "parking/discounts/" + this.ModelId + "/codes/searches";
    this.apiService.Get<any>("parking/discounts/" + this.ModelId).then(result => {
        this.Model = result;
        this.StopLoading();
    });
  }

  public RevokeCode(x: any) {
    this.apiService.Delete("parking/discounts/" + this.Model.Id + "/codes/" + x.Id, {}).then(result => {
      this.toastService.success('Discount code revoked successfully', 'Saved');
      this.DataList.Search();
    });
  }

  public EditDiscountCode(code: any) {
    this.modalService.addModal(EditDiscountCodeComponent, { "CodeTypeId": this.Model.Id, "ModelId": code.Id })
      .subscribe((result: any) => {
        if (result != null) {
          if (result == true) {
            this.toastService.success('Discount code updated successfully', 'Saved');
            this.DataList.Search();
          }
        }
      });
  }

  public CreateDiscountCode() {
    this.modalService.addModal(EditDiscountCodeComponent, { "CodeTypeId": this.Model.Id })
      .subscribe((result: any) => {
        if (result != null) {
          if (result == true) {
            this.toastService.success('Discount Code created', 'Saved');
            this.DataList.Search();
          }
        }
      });
  }
}