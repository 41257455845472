<div class="modal is-active">
    <div class="modal-background" [@modalbackground]="Closing ? 'close' : ''"></div>
    <div class="modal-card" [@modalopen]="Closing ? 'close' : ''">
        <header class="modal-card-head">
            <p class="modal-card-title">Hardware Message</p>
        </header>
        <div class="modal-card-body modal-slide-transition" style="height: 70em; width: 70em;overflow: auto;">
            <article *ngIf="Model != null" class="panel">
                <p class="panel-heading">Request</p>
                <div class="panel-block">
                    <table class="table is-striped" style="width:100% !important">
                        <tr *ngFor="let pair of Model.Request">
                            <th>{{pair.Key}}</th>
                            <td>{{pair.Value}}</td>
                        </tr>
                    </table>
                </div>
            </article>
            <article *ngIf="Model != null" class="panel">
                <p class="panel-heading">Response</p>
                <div class="panel-block">
                    <table class="table is-striped" style="width:100% !important">
                        <tr *ngFor="let pair of Model.Response">
                            <th>{{pair.Key}}</th>
                            <td>{{pair.Value}}</td>
                        </tr>
                    </table>
                </div>
            </article>
        </div>
        <footer class="modal-card-foot">
            <button class="button is-success" (click)="confirm()">OK</button>
        </footer>
    </div>
    
</div>