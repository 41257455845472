import { MeshConstants } from "src/app/util/mesh-constants";
import * as THREE from "three";
import { Mesh, Scene, Vector2, Vector3 } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DeviceBox } from "./devicebox";
import { Geo } from "src/app/util/geo";

export class GatewayMesh extends THREE.Mesh {
    constructor(scene: Scene, obj: any, offset: Vector3, scale: Vector2) {
      super();
      let self = this;
      // load the object
        this.type = obj;
      // load the object
      let loader = new GLTFLoader();
      let factor = MeshConstants.scaleFactor + 0.001;

      

                    loader.load('/assets/3d-models/gateway.glb', (gltf: any) => {
                        // adjust the size of the object to add into the scene
                                gltf.scene.position.x = obj.LocationPoints[0] * scale.x + offset.x;
                                gltf.scene.position.y = -1 * (obj.LocationPoints[1] * scale.y + offset.y);
                                gltf.scene.position.z = MeshConstants.floorThickness;
                                gltf.scene.rotation.x = Math.PI / 2;
                                
                                if (obj.DisplayAngle == 0) {
                                  //gltf.scene.rotation.y = 0;
                                } else {
                    
                                    gltf.scene.rotation.y = ((-1*obj.DisplayAngle) * (Math.PI / 180))
                                }

                                gltf.scene.scale.set(factor, factor, factor)
                                scene.add(gltf.scene);

                }, undefined, function (err: any) {
                    console.log(err);
                });
          }

}

