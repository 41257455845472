<div class="container">
  <div class="input-container">
    <input class="input" type="text" [value]="SearchTerm" (input)="SetSearchTermEvent($event)" (keydown)="HandleKeyDown($event)"/>
  </div>
  <div class="dropdown" [ngClass]="IsDropdownOpen ? 'is-active' : ''">
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <a *ngFor="let item of FilteredItems; let i = index" (click)="SelectItem(item.Name)" [ngClass]="'dropdown-item' + (currentIndex === i ? ' is-active' : '')">{{item.Name}}</a>
      </div>
    </div>
  </div>
</div>