import { Component, OnInit } from '@angular/core';
import { Datalistviewcolumn, DatalistviewPercentColumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';


@Component({
  selector: 'app-controlledareas',
  templateUrl: './controlledareas.component.html',
  styleUrls: ['./controlledareas.component.scss']
})
export class ControlledareasComponent implements OnInit {

  constructor() { }

  public Columns: Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Description", "Description"),
    new Datalistviewcolumn("Spaces", "TotalSpaces"),
    new DatalistviewPercentColumn("Occupancy", "TotalSpaces", "OccupiedSpaces")
  ];

  ngOnInit(): void {
  }

}
