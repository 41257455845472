  <div class="modal is-active" [formGroup]="Form">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Terminal</p>
      </header>
      <section class="modal-card-body" style="min-width: 800px;">
        <app-field Label="Name">
          <div class="control">
            <input formControlName="Name" class="input">
            <app-validator [For]="Form.get('Name')"></app-validator>
          </div>
        </app-field>
        <app-field Label="Serial Number">
          <div class="control">
            <input formControlName="SerialNumber" class="input" placeholder="Serial">
            <app-validator [For]="Form.get('SerialNumber')"></app-validator>
          </div>
        </app-field>
        <app-field Label="Relay Ip Address">
          <div class="control">
            <input type="text" class="input" formControlName="RelayIpAddress">
            <app-validator [For]="Form.get('RelayIpAddress')"></app-validator>
          </div>
        </app-field>
        <app-field Label="Relay Port">
          <div class="control">
            <input type="Number" min="0" class="input" formControlName="RelayPort">
            <app-validator [For]="Form.get('RelayPort')"></app-validator>
          </div>
        </app-field>
        <app-field Label="Power Relay Type">
          <div class="control">
            <div class="select">
              <select formControlName="RelayType">
                <option value=""></option>
                <option value="tcp-kp-1202">1202 Relay</option>
              </select>
            </div>
            <app-validator [For]="Form.get('RelayType')"></app-validator>
          </div>
        </app-field>
        <app-field Label="Location">
          <div class="control">
            <app-map-input formControlName="LocationPoints" MapWidth="500" MapHeight="400"></app-map-input>
            <app-validator [For]="Form.get('LocationPoints')"></app-validator>
          </div>
        </app-field>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" (click)="confirm()">OK</button>
      </footer>
    </div>
  </div>