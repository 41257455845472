import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';
import { LoginServiceBase } from 'src/app/auth/login.service.base';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { BusyableModalComponent } from 'src/app/shared/editors/busyableModalComponent';

@Component({
  selector: 'app-add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['./add-vehicle.component.scss']
})
export class AddVehicleComponent extends BusyableModalComponent<null, any> implements OnInit {

  public Colors: any[] = [];
  public Makes: any[] = [];

  public Form !: UntypedFormGroup;
  public DefaultUserModel(): any {
    return {
      Make: "",
      Color: "",
      PlateNumber: ""
    }
  }
  
  private addUrl = '/parking/vehicles';

  constructor(private apiService: ApiServiceBase, private loginService: LoginServiceBase, private toastService: ToastrService) { super(); }

  ngOnInit(): void {
    this.Form = new UntypedFormGroup({
      MakeId: new UntypedFormControl(''),
      ColorId: new UntypedFormControl(''),
      PlateId: new UntypedFormControl(''),
      PlateNumber: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
      VehicleMake:  new UntypedFormControl(''),
      VehicleColor: new UntypedFormControl('')
    });

    this.Loading()
    this.apiService.Get('/parking/vehicles/makesandcolors').then((result: any) => {
      this.Colors = result.Colors.filter((x: any) => x.Name != "");
      this.Makes = result.Makes.filter((x: any) => x.Name != "");
      this.StopLoading();
    });
  }

  addVehicle() {
    this.Busy();
    let model = this.Form.getRawValue();

    this.apiService.Get('/parking/plates/' + this.Form.get('PlateNumber')?.value).then(result => {
      model.PlateId = result;

      this.apiService.Post(this.addUrl, model).then(result => {
        console.log(result);
        this.result = result;
        this.StopBusy();
        this.close();
      });
    })
  }

  cancel() {
    this.result = null;
    this.close();
  }

  isFormValid() {
    return this.Form.valid;
  }

  isFormDirty() {
    return this.Form.dirty;
  }

}
