import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ApiService } from 'src/app/Services/api.service';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { BusyableModalComponent } from '../../editors/busyableModalComponent';

@Component({
  selector: 'app-structureselector',
  templateUrl: './structureselector.component.html',
  styleUrls: ['./structureselector.component.scss']
})
export class StructureSelectorComponent extends BusyableModalComponent<null, any> {
  
  @Input()
  public Structures: any[] = [];
  public ParkingLots: any[] = [];
  
  public apiService: ApiService;

  public boolExpandAll: boolean = false;
  public expandSelected: boolean = false;

  constructor(apiService: ApiService) {
    super();
    this.apiService = apiService;

    this.Loading();
    this.apiService.Get<any>("infrastructure/parkinglots/withlevels").then(result => {
      this.ParkingLots = result;
      this.ProcessStructures();
      this.StopLoading();
    });  
  }

  confirm() { 
    this.result = this.Structures;
    this.close();
  }

  ngOnInit(): void {
    
  }

  public ProcessStructures(){
    this.ParkingLots.forEach(lot => {
      lot.Children = lot.Levels;
      lot.HasCheckbox = true;
      lot.IsSelected = this.IsParkingStructureSelected(lot);
      lot.HasParentSelected = false;
      lot.Expanded = this.boolExpandAll || (this.expandSelected && (lot.HasChildItemsSelected));

      lot.Children.forEach((level: any) => {
        level.Children = level.Rows;
        level.HasCheckbox = true;
        level.IsSelected = this.IsParkingStructureSelected(level);
        level.Parent = lot;
        level.Expanded = this.boolExpandAll || (this.expandSelected && (level.HasChildItemsSelected));

        level.Children.forEach((row: any) => {
          row.HasCheckbox = true;
          row.IsSelected = this.IsParkingStructureSelected(row);
          row.Parent = level;  
          row.Children = row.Spaces;
          row.Expanded = this.boolExpandAll || (this.expandSelected && (row.HasChildItemsSelected));

          if(row.Children != null){
            row.Children.forEach((space: any) => {
              space.HasCheckbox = true;
              space.IsSelected = this.IsParkingStructureSelected(space);
              space.Parent = row;  
            });
            row.HasChildItemsSelected = row.Children.filter((x: any) => x.IsSelected).length > 0;
          }
        });
        level.HasChildItemsSelected = (level.Children.filter((x: any) => x.IsSelected).length > 0) || level.Children.filter((x: any) => x.HasChildItemsSelected).length > 0;
      });
      lot.HasChildItemsSelected = lot.Children.filter((x: any) => x.IsSelected || x.Children.filter((y: any) => y.IsSelected).length > 0).length > 0 || lot.Children.filter((x: any) => x.HasChildItemsSelected).length > 0;
    })
  }

  public IsParkingStructureSelected(item: any): boolean {
    var parkingStructures = this.Structures;
    if (parkingStructures == null) {
      return false;
    }
    var t = parkingStructures.filter((x: any) => x.Id == item.Id);
    if (t.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  public UpdateParkingStructureSelectedItem(item: any) {
    if (this.Structures == null) this.Structures = [];
    var x = this.IsParkingStructureSelected(item);
    if (x) {
      //item exists in list, so remove.
      item.IsSelected = false;
      var g = this.Structures;
      this.Structures = this.Structures.filter(x => x.Id != item.Id);
    }
    else {
      //item does not exist in list, so add.
      var ParkingStructure = {
        Id: item.Id,
        Name: item.Name,
        StructureType: item.ClassName,
        LocationString: item.LocationString,
        GeoPolygonPoints: item.GeoPolygonPoints
      }
      item.IsSelected = true;
      this.Structures.push(ParkingStructure);
    }
    this.ProcessStructures();
  };


  public ExpandAll() {
    this.boolExpandAll = true;
    this.ProcessStructures();
  }

  public CollapseAll() {
    this.boolExpandAll = false;
    this.expandSelected = false;
    this.ProcessStructures();
  }

  public ExpandSelected() {
    this.boolExpandAll = false;
    this.expandSelected = true;
    this.ProcessStructures();
  }
}