
<app-pageheader [Parent]="this">
</app-pageheader>

<app-datalistview [Columns]="Columns" [ShowMap]="true" ApiUrl="infrastructure/spaceconfigurations/searches"
[AllowEdit]="true" EditUrl="/hardware/devices/spaces/:id" [StructureViewerLayers]="StructureViewerLayers" StructureViewerForType="Spaces">
    <app-filter-set>
        <div app-search-filter></div>
        <div app-search-button></div>
    </app-filter-set>
</app-datalistview>
