<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Parent]="this" [Form]="Form" class="button is-primary" (OnSave)="Save()">
        </app-form-save-button>
    </app-pageheader>

    <div class="columns">
        <div class="column is-6">
            <article class="panel">
                <p class="panel-heading">Guidance Light Configuration</p>
                <div class="panel-block">
                    <div class="three-quarters-width">
                        <app-field Label="Chip Id" class="control">
                            <input formControlName="ChipId" class="input mb-3" type="number" readonly/>
                            <app-validator [For]="this.Form.get('ChipId')"></app-validator>
                        </app-field>
                        <app-field Label="Name" class="control">
                            <input formControlName="Name" class="input mb-3" type="text" />
                            <app-validator [For]="this.Form.get('Name')"></app-validator>
                        </app-field>
                        <app-field Label="Mode" class="control mb-3">
                            <span class="select is-fullwidth">
                                <select formControlName="Mode">
                                    <option value="0">Polling</option>
                                    <option value="1">Device</option>
                                    <option value="2">Polling Master</option>
                                    <option value="3">Device Master</option>
                                    <option value="4">Slave</option>
                                </select>
                            </span>
                            <app-validator [For]="this.Form.get('Mode')"></app-validator>
                        </app-field>
                        <app-field Label="Sleep Time" class="control">
                            <input formControlName="SleepTime" class="input mb-3" type="time"/>
                            <app-validator [For]="this.Form.get('SleepTime')"></app-validator>
                        </app-field>
                        <app-field Label="Wake Up Time" class="control">
                            <input formControlName="WakeUpTime" class="input mb-3" type="time"/>
                            <app-validator [For]="this.Form.get('WakeUpTime')"></app-validator>
                        </app-field>
                        <app-field Label="Start of Day Time" class="control">
                            <input formControlName="StartOfDayTime" class="input mb-3" type="time"/>
                            <app-validator [For]="this.Form.get('StartOfDayTime')"></app-validator>
                        </app-field>
                        <app-field Label="End of Day Time" class="control">
                            <input formControlName="EndOfDayTime" class="input mb-3" type="time"/>
                            <app-validator [For]="this.Form.get('EndOfDayTime')"></app-validator>
                        </app-field>
                    </div>
                </div>
            </article>
            <article class="panel">
                <p class="panel-heading">Colour Configuration</p>
                <div class="panel-block">
                    <div class="three-quarters-width">
                        <app-field Label="Vacant Colour" class="control mb-3">
                            <app-colorpicker formControlName="VacantColour">
                            </app-colorpicker>
                            <app-validator [For]="this.Form.get('VacantColour')"></app-validator>
                        </app-field>
                        <app-field Label="Brightness" class="control mb-3">
                            <span class="select is-fullwidth">
                                <select formControlName="Brightness">
                                    <option value="0">Off</option>
                                    <option value="1">Low</option>
                                    <option value="2">Med</option>
                                    <option value="3">High</option>
                                    <option value="4">Max</option>
                                </select>
                            </span>
                        </app-field>
                        <app-field Label="Port 1 Affects Colour">
                            <app-toggleswitch formControlName="Port1AffectsColour"></app-toggleswitch>
                        </app-field>
                        <app-field Label="Port 2 Affects Colour">
                            <app-toggleswitch formControlName="Port2AffectsColour"></app-toggleswitch>
                        </app-field>
                        <app-field Label="Port 3 Affects Colour">
                            <app-toggleswitch formControlName="Port3AffectsColour"></app-toggleswitch>
                        </app-field>
                        <app-field Label="Port 4 Affects Colour">
                            <app-toggleswitch formControlName="Port4AffectsColour"></app-toggleswitch>
                        </app-field>
                        <app-field Label="Port 5 Affects Colour">
                            <app-toggleswitch formControlName="Port5AffectsColour"></app-toggleswitch>
                        </app-field>
                        <app-field Label="Port 6 Affects Colour">
                            <app-toggleswitch formControlName="Port6AffectsColour"></app-toggleswitch>
                        </app-field>
                    </div>
                </div>
            </article>
        </div>
        <div class="column is-6">
            <article class="panel" *ngIf="Model.Device != null">
                <p class="panel-heading">Device Settings</p>
                <div class="panel-block">
                    <div formGroupName="Device" class="three-quarters-width">
                        <app-field Label="Hardware Version" class="control">
                            <input class="input mb-3" formControlName="HardwareVersionVersion" type="number" readonly/>
                        </app-field>
                        <app-field Label="Firmware Version" class="control mb-3">
                            <span class="select is-fullwidth">
                                <select formControlName="NewFirmwareVersion">
                                    <option *ngFor="let item of FirmwareVersions" [ngValue]="item.Version">{{item.Version}}</option>
                                </select>
                            </span>
                        </app-field>
                        <app-field Label="Extended Configuration" class="control">
                            <input formControlName="ExtendedConfiguration" class="input mb-3" type="text"/>
                            <app-validator [For]="this.Form.get('ExtendedConfiguration')"></app-validator>
                        </app-field>
                        <app-field Label="TxPower" class="control">
                            <input formControlName="TxPower" class="input mb-3" min="0" max="20" type="number"/>
                            <app-validator [For]="this.Form.get('TxPower')"></app-validator>
                        </app-field>
                    </div>
                </div>
            </article>
            <article class="panel">
                <p class="panel-heading">Port Settings</p>
                <div class="panel-block">
                    <div class="three-quarters-width">
                        <app-field class="control mb-3" Label="Port 2 Camera">
                            <span class="select is-fullwidth">
                                <select formControlName="Port2Camera">
                                    <option value="0">None</option>
                                    <option value="1">Port A</option>
                                    <option value="2">Port B</option>
                                    <option value="3">Port C</option>
                                    <option value="4">Port D</option>
                                </select>
                            </span>
                        </app-field>
                        <app-field class="control mb-3" Label="Port 3 Camera">
                            <span class="select is-fullwidth">
                                <select formControlName="Port3Camera">
                                    <option value="0">None</option>
                                    <option value="1">Port A</option>
                                    <option value="2">Port B</option>
                                    <option value="3">Port C</option>
                                    <option value="4">Port D</option>
                                </select>
                            </span>
                        </app-field>
                        <app-field class="control mb-3" Label="Port 4 Camera">
                            <span class="select is-fullwidth">
                                <select formControlName="Port4Camera">
                                    <option value="0">None</option>
                                    <option value="1">Port A</option>
                                    <option value="2">Port B</option>
                                    <option value="3">Port C</option>
                                    <option value="4">Port D</option>
                                </select>
                            </span>
                        </app-field>
                        <app-field class="control mb-3" Label="Port 5 Camera">
                            <span class="select is-fullwidth">
                                <select formControlName="Port5Camera">
                                    <option value="0">None</option>
                                    <option value="1">Port A</option>
                                    <option value="2">Port B</option>
                                    <option value="3">Port C</option>
                                    <option value="4">Port D</option>
                                </select>
                            </span>
                        </app-field>
                        <app-field class="control mb-3" Label="Port 6 Camera">
                            <span class="select is-fullwidth">
                                <select formControlName="Port6Camera">
                                    <option value="0">None</option>
                                    <option value="1">Port A</option>
                                    <option value="2">Port B</option>
                                    <option value="3">Port C</option>
                                    <option value="4">Port D</option>
                                </select>
                            </span>
                        </app-field>
                    </div>
                </div>
            </article>
        </div>
    </div>
</div>