<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Vehicle Parked Details</p>
      </header>
      <section class="modal-card-body" style="min-width: 800px;">
        <app-loader [Parent]="Busyable"></app-loader>
        <app-field Label="Vehicle Location" tippy="Where did you park this vehicle" *ngIf="ParkingLots != null">
          <div class="control select">
              <select (change)="ParkingLotSelected($event)">
                <option *ngFor="let p of ParkingLots" [value]="p.Id">{{p.Name}}</option>
              </select>
          </div>
        </app-field>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary" (click)="confirm()">Confirm</button>
        <button class="button is-danger" (click)="close()">Cancel</button>
      </footer>
    </div>
  </div>