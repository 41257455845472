import { EditGatewayConfigurationComponent } from "../../../modals/editgatewayconfiguration/editgatewayconfiguration.component";
import { LevelEditorComponent } from "../../leveleditor.component";
import { PointMapComponent } from "./PointMapComponent";

export class Gateway extends PointMapComponent{
    public override ClassName: string = "GatewayConfiguration";
    public override ImageUrl: string = "/assets/leveleditor/gateway.png";
    public override ImageScale = 0.04;
    public override CanDuplicate: boolean = false;
    public override IsGuidId: boolean = false;
    
    constructor(leveleditorcomponent: LevelEditorComponent, canvas: any, formItem: any, parentFormItem: any, forceClone: boolean = false) {
        super(leveleditorcomponent, canvas, formItem, parentFormItem, forceClone);
        this.AddFabricItem();
    }

    public override Clone(formitem: any){
        return new Gateway(this.leveleditorcomponent, this.canvas, formitem, this.parentFormItem, true);
    }

    public override ShowModal(): void {
        this.leveleditorcomponent.modalService.addModal(EditGatewayConfigurationComponent, { Form: this.formItem })
        .subscribe((result) => {
        });
    }
}