import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { ChartDataResponse, ChartResponse } from '../dashboardchart/ChartResponse';
import { GenericChartComponent } from '../generic-chart/generic-chart.component';
import { ChartConfiguration, ChartType } from '../dashboardchart/ChartConfiguration';
import { ChartOptions } from 'chart.js';
interface DateRange {
  start: string;
  end: string;
}

@Component({
  selector: 'app-generic-bar-chart',
  templateUrl: './generic-bar-chart.component.html',
  styleUrls: ['./generic-bar-chart.component.scss']
})

export class GenericBarChartComponent extends GenericChartComponent {
  @Input() height: number = 400;
  @Input() chartData !: ChartDataResponse;
  @Input() options?: ChartOptions;
  @Input() title: string = '';
  @Input() interactive: boolean = true;
  @Input() tooltipOptions: any = {};
  @Input() stacked: boolean = false;
  @Input() showLegend: boolean = false;
  public chartType : ChartType = 'bar';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['chartData']) {
      this.setupChartOptions();
      this.processChartData();
      this.updateChart();
    }
  }

  public override processChartData() {
    if(this.chartData && this.chartData.datasets && this.chartData.datasets.length !== 0){
    let datasets = this.chartData.datasets.map((dataset: ChartResponse, index: number) => ({
      ...dataset,
      type: dataset.type,
      data: dataset.data.map((value: any) => Number(value)),
      backgroundColor: dataset.displayColor,
      borderColor: dataset.displayColor ?? undefined,
      stack: dataset.type === 'bar' && this.stacked ? 'stack1' : undefined,
      ...(dataset.type === 'line' ? {
        tension: 0.4,
        fill: false,
        borderWidth: 2
      } : {})
    }));

    this.processedChartData = {
      labels: this.chartData.labels,
      datasets: datasets
      };
    }
  }

  protected override setupChartOptions() {
    this.chartOptions = this.createChartOptions();
    this.chartOptions = { ...this.chartOptions, ...this.options };
  }

  protected override createChartOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: this.showLegend,
          position: 'top',
          align: 'center',
          onClick: this.interactive ? undefined : () => {}
        },
        tooltip: {
          ...this.tooltipOptions,
          usePointStyle: true,
          boxWidth: 10,
          boxHeight: 10,
          callbacks: {
            labelColor: function(context) {
              return {
                borderColor: context.dataset.borderColor,
                backgroundColor: context.dataset.backgroundColor
              };
            }
          }
        }
      },
      scales: {
        x: {
          display: true,
          stacked: this.stacked,
        },
        y: {
          display: true,
          stacked: this.stacked,
          beginAtZero: true,
          ticks: {
            precision: 0
          }
        }
      },
      datasets: {
        bar: {
          categoryPercentage: 0.85,
          barPercentage: 0.85  
        }
      }
    };
  }

  /**
   * Updates a single data point in the chart
   * @param datasetIndex The index of the dataset to update
   * @param valueIndex The index of the value within the dataset
   * @param newValue The new value to set
   */
  public override updateSingleValue(datasetIndex: number, valueIndex: number, newValue: number) {
    if (this.chart && this.chart.data && this.processedChartData?.datasets[datasetIndex]) {
      // Update the processed data
      this.processedChartData.datasets[datasetIndex].data[valueIndex] = newValue;
      
      // Only update the specific data point in the chart
      this.chart.data.datasets[datasetIndex].data[valueIndex] = newValue;
      // Preserve the colors
      const currentBackgroundColors = this.chart.data.datasets[datasetIndex].backgroundColor || [];
      const currentBorderColors = this.chart.data.datasets[datasetIndex].borderColor || [];

      // Ensure the colors array exists and is correctly indexed
      this.chart.data.datasets[datasetIndex].backgroundColor = currentBackgroundColors;
      this.chart.data.datasets[datasetIndex].borderColor = currentBorderColors;

      // Update the chart without recalculating everything
      this.chart.update('none'); // Use 'none' for minimal redraw
    }
  }
}