<app-pageheader [Parent]="this">
  <add-button AddRoute="/parking/accesscontrol/accesslists/new"></add-button>
</app-pageheader>

<app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="parking/plateaccess/searches"
[AllowEdit]="true" EditUrl="/parking/accesscontrol/accesslists/:listid">
  <app-filter-set>
    <div app-search-filter></div>
    <div app-search-button></div>
  </app-filter-set>
</app-datalistview>