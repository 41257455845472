import * as THREE from "three";
import { Mesh, Scene, Vector2, Vector3 } from "three";

export class ShapeMesh extends THREE.Line {
    constructor(scene : Scene, shape: any, offset: Vector3, scale: Vector2){
        super();
        let p = shape.PolygonPoints;
        let points = [];
        let line = new THREE.Shape()
        line.moveTo(p[0][0] * scale.x + offset.x, -1 * p[0][1] * scale.y + offset.y);
        for (let i = 1; i < p.length; i++) {
            line.lineTo(p[i][0] * scale.x + offset.x, -1 * p[i][1] * scale.y + offset.y);
        }
        line.lineTo(p[0][0] * scale.x + offset.x, -1 * p[0][1] * scale.y + offset.y);
            // for(let i=0; i < p.length; i++){
        //     points.push(new THREE.Vector3(p[i][0]*scale.x + offset.x, -1*p[i][1]*scale.y + offset.y, offset.z + 0.0001));
        // }
        const extrudeSettings = {
            steps: 1,
            depth: 0.00001,
            bevelEnabled: false
        };
        let shapeBounds = new THREE.Box3;

        let geometry = new THREE.ExtrudeGeometry(line, extrudeSettings);


        //let geometry = new THREE.BufferGeometry().setFromPoints(points);
        let material = new THREE.MeshLambertMaterial({ color:  new THREE.Color("#" + shape.FillColor)  });
        let mesh = new Mesh(geometry, material);
        this.geometry = geometry;
        this.material = material;
        mesh.translateZ(offset.z+0.0001);
        this.type = "arrow";
        scene.add(mesh);
    }
}
