import { Component, Injector, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/Services/modal.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute } from '@angular/router';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { LoginService } from 'src/app/auth/login.service';
import { ToastrService } from 'ngx-toastr';
import { FormArray, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { FeaturesService } from 'src/app/Services/features.service';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { SessionpaymentrefundComponent } from '../modals/sessionpaymentrefund/sessionpaymentrefund.component';
import { AddsessionmanualpaymentmodalComponent } from '../modals/addsessionmanualpayment/addsessionmanualpaymentmodal.component';
import { EditsessionratesetmodalComponent } from '../modals/editsessionrateset/editsessionratesetmodal.component';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { StructureViewerLayer } from 'src/app/shared/structureviewermaps/structureviewerlayers';
import { CloseSessionComponent } from '../modals/close-session/close-session.component';

@Component({
  selector: 'app-editsession',
  templateUrl: './editsession.component.html',
  styleUrls: ['./editsession.component.scss']
})
export class EditsessionComponent extends ModelEditor implements OnInit {
  public userInfo: any;
  public parkingSessionInfo: any;
  public editPage: any;
  public ClosedReasons: any[] = [];
  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("AccountTransactionId", "AccountTransactionId"),
    new Datalistviewcolumn("Amount", "Amount"),
    new Datalistviewcolumn("PaidFrom", "PaidFrom"),
    new Datalistviewcolumn("PaidTo", "PaidTo"),
    new Datalistviewcolumn("PaymentType", "PaymentType"),
    new Datalistviewcolumn("TransactionId", "TransactionId"),
    new Datalistviewcolumn("TransactionReference", "TransactionReference")
  ];

  public StructureViewerLayers: StructureViewerLayer[] = [];
  public StructureSearchTerm!: string;
  public StructureId !: string;
  public StructureViewerForType !: string;

  constructor(private modalService: SimpleModalService, private apiService: ApiServiceBase, private injector: Injector) {
    super("parking/sessions", injector);
    this.QueryString = "?quote=false";
  }
  
  public DefaultModel() {
    return {
      Id: null,
      ParkingSpaceId: null
    }
  }

  public Validators() {
  }

  public override AfterModelLoaded(): void {
    this.StructureViewerLayers = [];
    if(this.Model.ParkingSpaceId != null){
      this.StructureViewerLayers.push(new StructureViewerLayer("ParkingLot"));
      this.StructureViewerLayers.push(new StructureViewerLayer("ParkingLevel"));
      this.StructureViewerLayers.push(new StructureViewerLayer("ParkingSpace"));
      this.StructureSearchTerm = this.Model.ParkingSpaceId;
      this.StructureId = this.Model.ParkingSpaceId;
      this.StructureViewerForType = "ParkingSpace";
    }
    else if(this.Model.ControlledAreaId != null){
      this.StructureViewerLayers.push(new StructureViewerLayer("ControlledArea"))
      this.StructureViewerLayers.push(new StructureViewerLayer("ParkingLot"));
      this.StructureId = this.Model.ControlledAreaId;
      this.StructureViewerForType = "ControlledArea";
    }
  }

  public override BeforeSave(): void {

  }

  public Receipt(p : any){
    window.open(p.PublicPortalReceiptUrl, '_blank')?.focus();
  }

  public async RecordRefund(p: any) {
    this.modalService.addModal(SessionpaymentrefundComponent, { Payment: p})
    .subscribe((result) => {
      if (result != null) {
        this.FetchModel(this.Model.Id);
      }
      });
  }

  public MakeManualPayment(){
    this.modalService.addModal(AddsessionmanualpaymentmodalComponent, {Session: this.Model})
      .subscribe((result) => {
        this.FetchModel(this.ModelId);
    });
  }

  public ChangeRateSet(){
    this.modalService.addModal(EditsessionratesetmodalComponent, {Session: this.Model})
    .subscribe((result) => {
      this.FetchModel(this.ModelId);
    });
  }
  public CloseSession() {
    this.modalService.addModal(CloseSessionComponent, { session: this.Model })
      .subscribe((result) => {
        if (result != null) {
          this.FetchModel(this.ModelId);
        }
    });
  }
}
