<div class="modal is-active">
  <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Select Structures</p>
      </header>
      <section class="modal-card-body" style="min-width: 800px;">
        <app-loader [Parent]="this"></app-loader>
          <div class="column is-12">
              <div style="margin-bottom:10px;">
                  <span><a (click)="ExpandAll()">Expand All</a><a> | </a>
                  <a (click)="CollapseAll()" >Collapse All</a><a> | </a>
                  <a (click)="ExpandSelected()" >Expand Selected</a></span>
              </div>
              <app-tree>
                  <app-treenoderecursive [recursiveList]="ParkingLots" (UpdateItem)="UpdateParkingStructureSelectedItem($event)">
                  </app-treenoderecursive>
              </app-tree>
          </div>

      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary" (click)="confirm()">Confirm</button>
        <button class="button is-danger" (click)="close()">Cancel</button>
      </footer>
    </div>
  </div>