<app-loader [Parent]="this"></app-loader>
<div *ngIf="Model != null">
  <app-pageheader [Parent]="this">
    <form-edit-button>Edit</form-edit-button>
  </app-pageheader>
  <div class="columns">
    <div class="column is-6">
      <article class="panel">
        <p class="panel-heading">
          Validation Profile User
        </p>
        <div class="panel-block" style="display: block;">
          <app-field Label="Id">
            <div class="control">
              <p class="input">{{Model.Id}}</p>
            </div>
          </app-field>
          <app-field Label="Name">
            <div class="control">
              <p class="input">{{Model.Name}}</p>
            </div>
          </app-field>
          <app-field Label="Validation Profile">
            <div class="control">
              <p class="input">{{Model.ValidationProfileId}} ({{Model.ValidationProfileName}})</p>
            </div>
          </app-field>
          <app-field Label="User Name">
            <div class="control">
              <p class="input">{{Model.UserFullName}}</p>
            </div>
          </app-field>
          <app-field Label="Code Last Generated at">
            <div class="control">
              <p class="input">{{Model.CodeLastGeneratedAtLocalString}}</p>
            </div>
          </app-field>
          <app-field Label="Send To User">
            <div class="control">
                <app-toggleswitch [(ngModel)]="Model.SendToUser" disabled></app-toggleswitch>
            </div>
          </app-field>
          <app-field Label="Send To Specified Email">
            <div class="control">
                <app-toggleswitch [(ngModel)]="Model.SendToEmail" disabled></app-toggleswitch>
            </div>
          </app-field>
          <app-field Label="Email Address" *ngIf="Model.SendToEmail">
            <div class="control">
              <p class="input">{{Model.EmailAddress}}</p>
            </div>
          </app-field>
        </div>
      </article>
    </div>
    <div class="column is-6 image-container">
      <!-- <img src="../../../../../assets/images/frogcoupon.svg" alt="FrogOne Coupons" /> -->
    </div>
  </div>
  <div class="columns">
    <div class="column is-full">
      <article class="panel">
        <p class="panel-heading">
          Validation Codes
          <actionbutton Icon="plus" ButtonType="primary" (PerformAction)="CreateCode()" >Add Code</actionbutton>
        </p>
        <div class="panel-block" style="display: block;">
          <app-datalistview #DataList [Columns]="Columns" [ShowMap]="false" [ApiUrl]="CodesApiUrl" [AllowEdit]="false" [AllowDropdownActions]="true" [DropdownActions]="Actions">
            <app-filter-set>
              <div app-search-filter></div>
              <div app-search-button></div>
            </app-filter-set>
          </app-datalistview>
        </div>
      </article>
    </div>
  </div>
  <div class="columns">
    <div class="column is-12">
      <div class="card">
        <app-validationuserusageserieschart Title="Validation Code Usage" ChartType="line" Period="thisweek" [ValidationUserId]="this.Model.Id" [ProfileId]="this.Model.ValidationProfileId"
        DataType="inflows" [Height]="50"></app-validationuserusageserieschart>
      </div>
    </div>
  </div>
</div>
