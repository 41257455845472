import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { ModalService } from 'src/app/Services/modal.service';
import { Modal } from 'src/app/shared/modals/modal';
import { ModelSelectorComponent } from '../../../../shared/modals/ModelSelector/modelselector.component';
import { DatalistviewComponent } from 'src/app/shared/datalistview/datalistview.component';

@Component({
  selector: 'app-permittype',
  templateUrl: './permittype.component.html',
  styleUrls: ['./permittype.component.scss']
})

export class PermittypeComponent extends Busyable implements OnInit {
  public ModelId: any;
  public MapPolygons: any[] = [];
  public Filters: any;
  public Model: any;
  public orgService: OrganizationsService;
  public modalService : SimpleModalService;

  @ViewChild("WhiteList")
  public WhiteList!: DatalistviewComponent;

  public MapOptions: google.maps.MapOptions = {
    center: { lat: 40, lng: -20 },
    zoom: 16,
  };

  constructor(private apiService: ApiServiceBase, private parentRoute: ActivatedRoute, organizationService: OrganizationsService, modalService: SimpleModalService) {
    super();
    this.orgService = organizationService;
    this.modalService = modalService;
  }

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Waitlist Index", "Index"),
    new Datalistviewcolumn("User", "UserString"),
    new Datalistviewcolumn("Invite Attempts", "SaleAttempts"),
    new Datalistviewcolumn("Invited On", "InvitedOnLocalFriendlyString"), 
  ];

  public WaitListUserSelectColumns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("User", "UserString")
  ];

  ngOnInit(): void {
    this.Loading();
    this.parentRoute.params.subscribe(params => { this.ModelId = params['id']; });
    this.apiService.Get<any>("parking/permittypes/" + this.ModelId).then(result => {
      this.Model = result;

      this.Model.Restriction = this.BuildRestrictionString();


      if (this.Model["ParkingStructures"] != null) {
        this.Model.ParkingStructures.forEach((ps:any) => {
          let loc = ps["GeoPolygonPoints"];
          if(loc != null){
            if (loc.length > 0) {
              let poly: google.maps.LatLngLiteral[] = [];
              for (let j = 0; j < loc.length; j++) {
                poly.push({ lat: loc[j][1], lng: loc[j][0] });
              }

              this.MapOptions.center = { lat: poly[0].lat, lng: poly[0].lng };

              this.MapPolygons.push(poly);
            }
          }
        });
      }



      this.StopLoading();
    });
  }

  public AddWaitlistedUser(){
    this.modalService.addModal(ModelSelectorComponent,
      { ApiUrl: "organization/users/searches", ModelType: "User", Columns: this.WaitListUserSelectColumns })
      .subscribe((modalresult) => {
        if (modalresult != null) {
          this.apiService.Post("parking/permittypes/waitlists/" + this.Model.Id, { UserId: modalresult.UserId }).then(result => {
            this.WhiteList.Search();
          })
        }
      });
  }

  public DeleteItem = (item: any) => {
    this.apiService.Delete("parking/permittypes/waitlists/" + this.Model.Id + "/" + item.Id, {}).then(result => {
      this.WhiteList.Search();
    })
  }

  public BuildRestrictionString(){
    var string = [];
    if(this.Model.RestrictToUserRoles && this.Model.ParkingPermitTypeRoleRestrictions.length > 0){
    string.push("This permit is restricted to Users who have the User Roles: ");
      for (let i = 0; i < this.Model.ParkingPermitTypeRoleRestrictions.length ; i++) {
        string.push((i == 0 ? '' : (i == this.Model.ParkingPermitTypeRoleRestrictions.length-1 ? ', and ': ',')) + this.Model.ParkingPermitTypeRoleRestrictions[i].RoleName);
      }
      string.push(". ");
    }
    var limitedToPushed = false;
    if(this.Model.MaxUsesPerPeriod != null && this.Model.MaxUsesPerPeriod != 0 && this.Model.MaxUsePeriodString != null){
      string.push(string.length > 0 ? "There is a maximum of " : "This permit has a maximum of ");
      limitedToPushed = true;
      string.push(this.Model.MaxUsesPerPeriod + " uses " + this.Model.MaxUsePeriodString);
    }

    if(this.Model.LimitToSpaceTypes && this.Model.ParkingSpaceTypeRestrictions.length > 0){
      if(limitedToPushed) string.push(" in ");
      else {
        string.push(string.length > 0 ? "It is limited to use in " : "This Permit is limited to use in ");
        limitedToPushed = true;
      }
      for (let i = 0; i < this.Model.ParkingSpaceTypeRestrictions.length ; i++) {
        string.push((i == 0 ? '' : (i == this.Model.ParkingSpaceTypeRestrictions.length-1 ? ', and ': ',')) + "'" + this.Model.ParkingSpaceTypeRestrictions[i].SpaceTypeName + "'");
      }
      string.push(" Parking Spaces");
    }

    if(this.Model.ParkingStructures?.length > 0){
      if(limitedToPushed) string.push(" in the following parking structures: ");
      else {
        string.push("This Permit is limited to use the following parking structures: ");
      }
      for (let i = 0; i < this.Model.ParkingStructures.length ; i++) {
        string.push((i == 0 ? '' : (i == this.Model.ParkingStructures.length-1 ? ', and ': ',')) + "'" + this.Model.ParkingStructures[i].LocationString + "'");
      }
    }
    string.push(". ")
    var str = string.join("");
    if(str == ". ") str = "There are no restrictions on this permit.";
    return str;
  }

  public BuildUsersString(){
    if(!this.Model.MultiUser) return 'Single User Permit';
    var str = 'Multi User Permit';
    if(this.Model.MinUsers == null && this.Model.MaxUsers == null) return str + ' (Any number of users)';
    if(this.Model.MinUsers == null) return str + ' (At most ' + this.Model.MaxUsers + ' users)';
    if(this.Model.MaxUsers == null) return str + ' (At least ' + this.Model.MinUsers + ' users)';
    return str + ' (' + this.Model.MinUsers + ' to ' + this.Model.MaxUsers + ' users)'
  }
}
