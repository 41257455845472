<div class="container">
  <div class="input-container">
    <input #input class="input" type="text" [value]="SearchTerm" (input)="SetSearchTermEvent($event)" (click)="onInputClick()" (keydown)="HandleKeyDown($event)"/>
  </div>
  <div class="dropdown" [ngClass]="IsDropdownOpen ? 'is-active' : ''">
    <div #dropdownMenu class="dropdown-menu" id="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <ng-container *ngFor="let category of FilteredCategories">
          <div class="dropdown-header">
            {{category.category}}
          </div>
          <hr class="dropdown-divider">
          <a *ngFor="let item of category.items;" 
             (click)="SelectItem(item)"
             class="dropdown-item"
             [title]="item.Description">
            {{item.Name}}
          </a>
          <hr class="dropdown-divider">
        </ng-container>
      </div>
    </div>
  </div>
</div>