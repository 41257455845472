import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Datalistviewcolumn, SelectedViewColumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { DatalistviewComponent } from 'src/app/shared/datalistview/datalistview.component';

@Component({
  selector: 'app-modelselector',
  templateUrl: './modelselector.component.html',
  styleUrls: ['./modelselector.component.scss']
})
export class ModelSelectorComponent extends SimpleModalComponent<any, any> {
  
  @Input()
  public ApiUrl: string = "";

  @Input()
  public ModelType: string = "";

  @Input()
  public MultiSelect: boolean = false;

  @Input()
  public DisplayColumn: string = "Name";

  @Input()
  public SelectedItems: any[] = [];

  @ViewChild("DataList")
  public DataList!: DatalistviewComponent;
  
  @Input()
  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "Name")
  ];

  constructor() {
    super();
  }

  public RemoveSelectedItem(index: number){
    this.SelectedItems.splice(index, 1);
    this.result = this.SelectedItems;
  }

  public SelectBoxChanged(item: any){
    if(this.SelectedItems == undefined){
      this.SelectedItems = [];
    }
    if(item.Selected){
      this.SelectedItems.push(item);
    }
    else{
      this.SelectedItems = this.SelectedItems.filter(x => x.Id != item.Id);
    }
    this.result = this.SelectedItems;
  }

  public ItemSelected(item : any){
      this.result = item;
      this.close();
  }
  
  confirm() {
    this.close();
  }
}