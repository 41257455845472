import { Component, DoCheck, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-validated-if-input',
  templateUrl: './validated-if-input.component.html',
  styleUrls: ['./validated-if-input.component.scss']
})
export class ValidatedIfInputComponent implements DoCheck {

  @Input()
  Validate : boolean = false;
  @Input()
  LabelText : string = '';
  @Input()
  public Input : any;
  @Input()
  ErrorDefs : any;
  
  public ErrorMessage : string = '';
  
  ngDoCheck(): void {
    this.ErrorMessage = '';
    if(this.Validate == false || this.ErrorDefs == null) return;

    Object.keys(this.ErrorDefs).some((key : any) => {
      if (this.Input.validity[key] == true) {
        this.ErrorMessage = this.ErrorDefs[key];
        return true;
      }
      return false;
    });
      
    
  }

}
