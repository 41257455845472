import { Component, OnInit } from '@angular/core';
import { EnforcementService } from 'src/app/Services/enforcement.service';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { DataListFilterColumn, DatalastviewTextAndConditionalSuffixColumn, Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-violations',
  templateUrl: './violations.component.html',
  styleUrls: ['./violations.component.scss']
})
export class ViolationsComponent implements OnInit {
  public ShowPermitFilter = false;
  public ShowRatesFilter = false;
  constructor(private permissionsService : PermissionsService, public enforcementService : EnforcementService) { }

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("ID", "Id"),
    new Datalistviewcolumn("Started", "StartDateAsString"),
    new Datalistviewcolumn("Ended", "EndDateAsString"),
    new Datalistviewcolumn("Location", "LocationString"),
    new Datalistviewcolumn("Plate", "PlateNumber"),
    new Datalistviewcolumn("Total Parked Duration", "ParkingDurationAsString"),
    new Datalistviewcolumn("Violation Duration", "ViolationDurationAsString"),
    new Datalistviewcolumn("Status", "ViolationStatus")
  ];

  async ngOnInit(): Promise<void> {
  }

}
