import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-editcontrolledareapaymentterminal',
  templateUrl: './editcontrolledareapaymentterminal.component.html',
  styleUrls: ['./editcontrolledareapaymentterminal.component.scss']
})
export class EditcontrolledareapaymentterminalComponent  extends SimpleModalComponent<any, null> {
  Form !: UntypedFormGroup;
  constructor() {
    super();
  }

  confirm() {
    this.close();
  }

}