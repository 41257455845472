import { EditcontrolledarealaneComponent } from "src/app/features/parking/accesscontrol/controlledareas/edit/editcontrolledarealane/editcontrolledarealane.component";
import { LevelEditorComponent } from "../../leveleditor.component";
import { PolygonMapComponent } from "./PolygonMapComponent";
import { FormArray } from "@angular/forms";
import { PointMapComponent } from "../PointComponents/PointMapComponent";
import { MapComponentBase } from "../MapComponentBase";
import { LevelEditorHelper } from "../../helper";

export class Lane extends PolygonMapComponent{
    public override ClassName: string = "Lane"
    public override FillColor: string = "#a64ca6";
    public override StrokeColor: string = "#FFFFFF";
    public override StrokeWidth: number = 3;
    public override PatternFill = true;
    public override PatternFillColor = "#a64ca6";
    public override ShowLabel: boolean = true;
    public override RequiresParent: boolean = true;
    public override ParentClassName: string = "Gate";
    public override zIndex: number = 100;
    public override ChildrenArrays = ["Cameras", "Barriers", "TrafficLights"];

    constructor(leveleditorcomponent: LevelEditorComponent, canvas: any, formItem: any, parentFormItem: any, forceClone: boolean = false) {
        super(leveleditorcomponent, canvas, formItem, parentFormItem, forceClone);
        this.AddFabricItem();
    }

    public override Clone(formitem: any){
        return new Lane(this.leveleditorcomponent, this.canvas, formitem, this.parentFormItem, true);
    }

    public override ShowModal(): void {
        this.leveleditorcomponent.modalService.addModal(EditcontrolledarealaneComponent, { Form: this.formItem, Level: this.leveleditorcomponent.Form })
            .subscribe((result) => {
        });
    }

    public override DeleteChildren(): void {
        this.ChildrenArrays.forEach((childArray: any) => {
            (this.formItem.get(childArray).value as any[]).forEach((child: any) => {
                var mapItem = this.leveleditorcomponent.MapItems.find(x => x.Id === child.Id);
                //remove
                this.canvas.remove((mapItem as MapComponentBase).FabricItem);
                const items = this.formItem.get(childArray) as FormArray;
                const index = LevelEditorHelper.findFormItemIndex(items, child);
                if (index !== -1) {
                      items.removeAt(index);
                      console.log(`Item ${index} found and removed from FormArray`);
                      this.formItem.markAsDirty();
                } else {
                      console.log("Item not found in FormArray");
                }
            });
        });
        //find assigned controller and remove it from controllers list;
        var controllerId = this.formItem.get('LocalControllerId')?.value;
        if(controllerId){
            var controller = this.leveleditorcomponent.MapItems.find(x => x.Id == controllerId);

            var items = this.leveleditorcomponent.Form.get('Controllers') as FormArray;
            const index = LevelEditorHelper.findFormItemIndex(items, controller?.formItem);
            if (index !== -1) {
                  items.removeAt(index);
                  console.log(`Item ${index} found and removed from FormArray`);
                  this.formItem.markAsDirty();
            } else {
                  console.log("Item not found in FormArray");
            }

            this.canvas.remove((controller as MapComponentBase).FabricItem);
        }
    }
}