import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function sameValueValidator(controlName: string): ValidatorFn {
    
    return (control:AbstractControl) : ValidationErrors | null => {

        const value = control.value;
        const compareValue = control.parent?.get(controlName)?.value;

        if(value == compareValue)
            return null;
        return { samepassword : true };
    }
}