import { Component, OnInit } from '@angular/core';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-plateaccesslists',
  templateUrl: './plateaccesslists.component.html',
  styleUrls: ['./plateaccesslists.component.scss']
})
export class PlateaccesslistsComponent implements OnInit {

  
  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Description", "Description"),
    new Datalistviewcolumn("Info", "Info")
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
