import { Component, OnInit } from '@angular/core';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-lprsearch',
  templateUrl: './lprsearch.component.html',
  styleUrls: ['./lprsearch.component.scss']
})
export class LprsearchComponent implements OnInit {

  constructor() { }

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Plate", "PlateNumber"),
    new Datalistviewcolumn("Location", "GuidanceName"),
    new Datalistviewcolumn("Bay Entry", "BayEntry")
  ];

  ngOnInit(): void {
  }

 

}
