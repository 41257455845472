<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Select Level</p>
        </header>
        <section class="modal-card-body" style="min-width: 800px;">
            <app-tree >
                <app-treenode *ngFor="let lot of ParkingLots" [Text]="lot.Name" 
                    [Expanded]="true">
                    <app-treenode *ngFor="let level of lot.Levels" [Text]="level.Name" [Selected]="level == SelectedLevel" (click)="SelectedLevel = level"
                        [Expanded]="true">
                    </app-treenode>
                </app-treenode>
            </app-tree>
            {{SelectedLevel?.Id}}
        </section>
        <footer class="modal-card-foot">
            <button class="button is-success" (click)="confirm()">OK</button>
            <button class="button is-success" (click)="close()">Cancel</button>
        </footer>
    </div>
</div>