import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { LoginServiceBase } from 'src/app/auth/login.service.base';

@Component({
  selector: 'app-myaccount',
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.scss']
})
export class MyAccountComponent extends ModelEditor implements OnInit {
  constructor(private route: ActivatedRoute, private apiService: ApiServiceBase, private injector: Injector, private toastManager: ToastrService, public loginService: LoginServiceBase) {
    super("users", injector);
  }
  public override DefaultModel(): any {
  }

  public override ngOnInit(): void {
    this.Loading();
    this.Form.addControl("FirstName", new UntypedFormControl('', Validators.required));
    this.Form.addControl("LastName", new UntypedFormControl('', Validators.required));
    this.Form.addControl("Email", new UntypedFormControl('', [Validators.required, Validators.email]));
    this.apiService.Get<any>("user").then(user => {
      this.Form.get("FirstName")?.setValue(user.FirstName);
      this.Form.get("LastName")?.setValue(user.LastName);
      this.Form.get("Email")?.setValue(user.Email);
      this.StopLoading();
    });

  }

  public override AfterModelLoaded(): void {
  }

  public override Save(): void {
    this.Loading();
    this.apiService.Put<any>('user', {
      FirstName: this.Form.get('FirstName')?.value,
      LastName: this.Form.get('LastName')?.value,
      Email: this.Form.get('Email')?.value,
      OrganizationId: this.loginService.CurrentOrganizationId(),
    }).then(x => {
      this.StopLoading();
      this.toastManager.success("Details Updated Successfully");
      this.loginService.LoggedInNameChanged(x.FirstName, x.LastName);
    });
  }

  public override BeforeSave(): boolean | void {
  }

  public override Validators(): any {
  }
}