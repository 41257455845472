<div class="modal is-active" [formGroup]="Form">
    <div class="modal-background" [@modalbackground]="Closing ? 'close' : ''"></div>
    <div class="modal-card" [@modalopen]="Closing ? 'close' : ''">
        <header class="modal-card-head">
            <p class="modal-card-title">User Input</p>
        </header>
        <section class="modal-card-body"style="min-width: 800px;overflow: hidden;">
            <app-field Label="Scanner Type" class="control mb-3">
                <span class="select three-quarters-width">
                    <select formControlName="Type">
                        <option value="MultiInputScanner">MultiScanner</option>
                    </select>
                </span>
            </app-field>
            <app-field Label="Name" class="control mb-3">
                <input formControlName="Name" type="text" class="input three-quarters-width"/>
            </app-field>
            <app-field Label="Serial Number" class="control mb-3">
                <input formControlName="SerialNumber" type="text" class="input three-quarters-width"/>
            </app-field>
            <app-field Label="Com Port" class="control mb-3">
                <input formControlName="ComPort" type="text" class="input three-quarters-width"/>
            </app-field>
            <app-field Label="Enabled for" class="control mb-3">
                <span class="select three-quarters-width">
                    <select formControlName="ActiveDirection">
                        <option value="Entry">Entry</option>
                        <option value="Exit">Exit</option>
                        <option value="Both">Both</option>
                    </select>
                </span>
            </app-field>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" (click)="confirm()">OK</button>
        </footer>
    </div>
</div>