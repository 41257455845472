export class Day {
    public number: number | undefined;
    public year: number = 0;
  
    public month: string| undefined;
    public monthIndex: number = 0;
  
    public weekDayName: string| undefined;
    public weekDayNumber: number = 0;
    public ShowAddType: boolean = false
    public DayTypes: any[] = [];
}