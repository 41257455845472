import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ServiceBusService } from 'src/app/Services/servicebus.service';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ColorService } from 'src/app/Services/color.service';
import { OccupancyService } from 'src/app/Services/occupancy.service';
import { Subscription } from 'rxjs';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { EditParkinglevelCountsComponent } from '../../parkinglevels/modals/parkinglevelcounts/editparkinglevelcounts.component';
import moment from 'moment';
import { StructureViewerLayer } from 'src/app/shared/structureviewermaps/structureviewerlayers';

@Component({
  selector: 'app-parkinglot',
  templateUrl: './parkinglot.component.html',
  styleUrls: ['./parkinglot.component.scss']
})
export class ParkingLotComponent extends Busyable implements OnInit, OnDestroy {
  public ModelId: any;
  public Model: any;
  public MapPolygons: any = [];
  public ResetModes: any = [];
  public levels: any = [];
  private OccupancySubscription: Subscription;


  public MapOptions: google.maps.MapOptions = {
    center: { lat: 40, lng: -20 },
    zoom: 4,
  };

  public StructureViewerLayers: StructureViewerLayer[] = [
    new StructureViewerLayer("ParkingLot", null, false, true), 
    new StructureViewerLayer("ParkingLevel"), 
    new StructureViewerLayer("ParkingSpace"), 
    new StructureViewerLayer("ParkingLevelMapItem", "Floor Map Items", false, false, "FloorItems"), 
    new StructureViewerLayer("ParkingLevelMapItem", "Raised Map Items", false, false, "RaisedItems"), 
    new StructureViewerLayer("SignConfiguration", "Signs")
  ]

  public Columns: Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Device Type", "ClassName"),
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Location", "LocationString")
  ];

  constructor(private apiService: ApiServiceBase, private parentRoute: ActivatedRoute, private servicebusService: ServiceBusService, private cService: ColorService, private occupancyService: OccupancyService, private modalService: SimpleModalService, private toast: ToastrService) {
    super();

    this.ResetModes.push({ value: 0, Name: "None" });
    this.ResetModes.push({ value: 1, Name: "Daily" });
    this.ResetModes.push({ value: 2, Name: "Weekly" });
    this.ResetModes.push({ value: 3, Name: "Monthly" });

    this.OccupancySubscription = occupancyService.OccupancyChanged.subscribe((val: any) => {
      this.RefreshOccupancy();
    });
  }

  private RefreshOccupancy() {
    this.occupancyService.GetOccupancy(this.Model.Id).then((occupancy: any) => {
      if (occupancy != null) {
        console.log("Parking Lot Occupancy " + JSON.stringify(occupancy));
        this.Model.TotalSpaces = occupancy.TotalSpaces;
        this.Model.OccupiedSpaces = occupancy.OccupiedSpaces;
        this.Model.AvailableSpaces = occupancy.AvailableSpaces;
      }
    });
  }

  public EditParkingLevelCounts() {
    this.modalService.addModal(EditParkinglevelCountsComponent, { "ModelId": this.ModelId })
      .subscribe((result: any) => {
        if (result != null) {
          if (result == true) {
            this.toast.success('Vehicle Present counts updated successfully', 'Saved');
            this.GetParkingLot();
          }
        }
      });
  }

  public GetResetMode(val: any): string {
    var x = this.ResetModes.filter((x: any) => x.value == val);
    return x[0].Name;
  }

  ngOnInit(): void {
    this.Loading();
    this.GetParkingLot();
  }

  public GetParkingLot() {
    this.parentRoute.params.subscribe(params => { this.ModelId = params['lotid']; });

    this.apiService.Get<any>("infrastructure/parkinglots/" + this.ModelId).then(result => {

      this.Model = result;
      this.levels = result.Levels;
      this.Model.Api = "/parking/" + this.Model.Id + "/Devices";

      this.Model.ResetHour = moment(this.Model.OccupancyResetTime, 'HH').format('h A').toString(); // 09:00 PM 
      this.StopLoading();

      if (this.Model["GeoPolygonPoints"] != null) {
        let loc = this.Model["GeoPolygonPoints"];
        if (loc.length > 0) {
          let poly: google.maps.LatLngLiteral[] = [];
          for (let j = 0; j < loc.length; j++) {
            poly.push({ lat: loc[j][1], lng: loc[j][0] });
          }

          this.MapPolygons.push(poly);
        }
      }

      this.MapOptions.center = { lat: this.MapPolygons[0][0].lat, lng: this.MapPolygons[0][0].lng };
      this.MapOptions.zoom = 20;
      this.Model.TextClass = this.cService?.GetVisibleTextClassFor(this.Model.ThresholdColor);
    });
  }
  ngOnDestroy(): void {
    if (this.OccupancySubscription != null) {
      this.OccupancySubscription.unsubscribe();
    }
  }
}
