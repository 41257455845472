import { Component, Injector, OnInit } from '@angular/core';
import { TippyDirective, TippyService } from '@ngneat/helipopper';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';

@Component({
  selector: 'app-vehicledetectionsettings',
  templateUrl: './vehicledetectionsettings.component.html',
  styleUrls: ['./vehicledetectionsettings.component.scss']
})
export class VehicledetectionsettingsComponent extends ModelEditor implements OnInit {
  public DefaultModel() {
    return {};
  }
  public AfterModelLoaded(): void {
    
  }
  public BeforeSave(): boolean | void {
    
  }
  public Validators() {
    return [];
  }

  constructor(private apiService: ApiServiceBase, private organizationsService: OrganizationsService, private injector: Injector, private tippy : TippyService) {
    super("parking/vehicledetectionsettings", injector);
  }
  
}
