import { UntypedFormArray, UntypedFormGroup } from "@angular/forms";
import { Geo } from "src/app/util/geo";
import { LevelmapeditorComponent } from "./levelmapeditor.component";
import { v4 as uuidv4 } from 'uuid';
import { BarrierMapItem, CameraMapItem, ControllerMapItem, GateMapItem, LaneMapItem, MapItemBase, ParkingRowMapItem, ParkingSpaceMapItem, ShapeMapItem, GatewayMapItem, SignMapItem, TrafficLightMapItem, GuidanceLightMapItem, CarCounterMapItem } from "./mapitems";

export abstract class MapEditorToolBase {
    public abstract Execute(editor: LevelmapeditorComponent, location: any): MapItemBase | null | undefined;
}
export class MapEditorRowTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let poly = Geo.NewPolygonAtCenter(location, 0.01);
        let rows = editor.ModelEditor.FormArray(editor.Form, "Rows");
        let newItem: any = { ClassName: "ParkingRow", PolygonPoints: poly, Name: "Row", Spaces: [] };
        let fg = editor.ModelEditor.AddToFormArray(rows, newItem, "Rows");
        editor.AddMapItem(new ParkingRowMapItem(fg, poly));
        return newItem;
    }
}
export class MapEditorParkingSpaceTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let poly = Geo.NewPolygonAtCenter(location, 0.0025, 0.0045);
        let rows = editor.MapItemsByType.get("ParkingRow");
        if (rows == null) return;
        for (let row of rows) {
            let bounds = Geo.GetPolygonPointBounds(row);
            if (bounds.Contains(location)) {
                let rowFg = row.FormControl as UntypedFormGroup;
                let spaces = editor.ModelEditor.FormArray(rowFg, "Spaces");
                let newSpace = { Id: uuidv4(), ClassName: "Space", Name: "1", PolygonPoints: poly, ParkingSpaceTypeId: null };
                let fg = editor.ModelEditor.AddToFormArray(spaces, newSpace, "Rows/Spaces");
                let space = new ParkingSpaceMapItem(fg, row as ParkingRowMapItem, poly);
                editor.AddMapItem(space);
                return space;
            }
        }
        editor.toastr.warning("Spaces must be placed within Rows", "Placement Error");
        return null;
    }
}
export class MapEditorGateTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let poly = Geo.NewPolygonAtCenter(location, 0.01);
        let gates = editor.ModelEditor.FormArray(editor.Form, "Gates");
        let newGate = { Id: uuidv4(), ClassName: "Gate", Name: "Gate", PolygonPoints: poly, Lanes: [], EditorLocked : false };
        let fg = editor.ModelEditor.AddToFormArray(gates, newGate, "Gates");
        let g = new GateMapItem(fg, poly);
        editor.AddMapItem(g);
        return g;
    }
}
export class MapEditorLaneTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let poly = Geo.NewPolygonAtCenter(location, 0.004);
        let gates = editor.ModelEditor.FormArray(editor.Form, "Gates");
        let newLane = { Id: uuidv4(), ClassName: "Lane", Name: "Lane", PolygonPoints: poly, LocalControllerId: null, WorkflowId: null, Barriers: [], Cameras: [], PaymentTerminals: [], Displays: [], CellRouters: [], TrafficLights: [], EditorLocked : false };
        let fg = editor.ModelEditor.AddToFormArray(gates.controls[0].get("Lanes") as UntypedFormArray, newLane, "Gates/Lanes");
        let g = new LaneMapItem(fg, poly);
        editor.AddMapItem(g);
        return g;
    }
}
export class MapEditorControllerTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let poly = Geo.NewPolygonAtCenter(location, 0.01);
        let lanes = editor.MapItemsByType.get("Lane");
        if (lanes == null || lanes.length == 0) return;
        for (let lane of lanes) {
            if (Geo.GetPolygonPointBounds(lane.PolygonPoints).Contains(location)) {
                let newItem = { Id: uuidv4(), ClassName: "LocalController", Name: "Terminal", LaneId: lane.FormControl.get("Id")?.value, DisplayAngle: 0, LocationPoints: location, RelayType: "", SoftwareVersionId: null, OfflineTimeoutMinutes: 15, EditorLocked : false, SerialNumber: null, RelayIpAddress: null, RelayPort: null };
                let fg = editor.ModelEditor.AddToFormArray(editor.Form.get("Controllers") as UntypedFormArray, newItem, "Controllers");
                let g = new ControllerMapItem(fg, location);
                editor.AddMapItem(g);
                return g;
            }
        }
        editor.toastr.warning("Controllers may only be placed within Lanes", "Not added");
        return null;
    }
}
export class MapEditorCameraTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let poly = Geo.NewPolygonAtCenter(location, 0.01);
        let lanes = editor.MapItemsByType.get("Lane");
        if (lanes == null || lanes.length == 0) return;
        for (let lane of lanes) {
            if (Geo.GetPolygonPointBounds(lane.PolygonPoints).Contains(location)) {
                let newItem = { Id: uuidv4(), ClassName: "Camera", Name: "Camera", LocationPoints: location, Type: "", LocalAddress: "", LocalPort: 0, SerialNumber: "", Username: "", Password: "", DisplayAngle: 0, ActiveDirection: null, EditorLocked : false };
                let fg = editor.ModelEditor.AddToFormArray(lane.FormControl.get("Cameras") as UntypedFormArray, newItem, "Gates/Lanes/Cameras");
                let m = new CameraMapItem(fg, location);
                editor.AddMapItem(m);
                return m;
            }
        }
        editor.toastr.warning("Cameras may only be placed within Lanes", "Camera not added");
        return null;
    }
}
export class MapEditorBarrierTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let poly = Geo.NewPolygonAtCenter(location, 0.01);
        let lanes = editor.MapItemsByType.get("Lane");
        if (lanes == null || lanes.length == 0) return;
        for (let lane of lanes) {
            if (Geo.GetPolygonPointBounds(lane.PolygonPoints).Contains(location)) {
                let newItem = { ClassName: "Barrier", Name: "Barrier", LocationPoints: location, Id: uuidv4(), DisplayAngle: 0, Type: "mo24", LocalAddress: "192.168.1.110", LocalPort: 0, SerialNumber: null, ActiveDirection: null, OpenDirection: null, EditorLocked : false };
                let fg = editor.ModelEditor.AddToFormArray(lane.FormControl.get("Barriers") as UntypedFormArray, newItem, "Gates/Lanes/Barriers");
                let m = new BarrierMapItem(fg, location);
                editor.AddMapItem(m);
                return m;
            }
        }
        editor.toastr.warning("Barriers may only be placed within Lanes", "Barrier not added");
        return null;
    }
}
export class MapEditorTrafficLightTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let poly = Geo.NewPolygonAtCenter(location, 0.01);
        let lanes = editor.MapItemsByType.get("Lane");
        if (lanes == null || lanes.length == 0) return;
        for (let lane of lanes) {
            if (Geo.GetPolygonPointBounds(lane.PolygonPoints).Contains(location)) {
                let newItem = { ClassName: "TrafficLight", Name: "Traffic Light", LocationPoints: location, Id: uuidv4(), DisplayAngle: 0, Type: "k3sign", LocalAddress: "192.168.1.222", LocalPort: 0, SerialNumber: null, ActiveDirection: null, IsInverted: false, Brightness: 100, WorkflowDriven: false, EditorLocked : false, ShowGreenFor: null };
                let fg = editor.ModelEditor.AddToFormArray(lane.FormControl.get("TrafficLights") as UntypedFormArray, newItem, "Gates/Lanes/TrafficLights");
                let m = new TrafficLightMapItem(fg, location);
                editor.AddMapItem(m);
                return m;
            }
        }
        editor.toastr.warning("Traffic Lights may only be placed within Lanes", "Traffic Light not added");
        return null;
    }
}
export class MapEditorArrowTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let size = 0.001;
        let x = location[0]; let y = location[1];
        let poly: any[] = [];
        poly.push([x - size, y + (2 * size)]);
        poly.push([x - size, y + (2 * size)]);
        poly.push([x - size, y - (2 * size)]);
        poly.push([x - size - size, y - (2 * size)]);
        poly.push([x, y - (6 * size)]);
        poly.push([x + size + size, y - (2 * size)]);
        poly.push([x + size, y - (2 * size)]);
        poly.push([x + size, y + (2 * size)]);

        let newItem = { Id: uuidv4(), ClassName: "Shape", Name: "Arrow", Type: "Arrow", PolygonPoints: poly, OutlineColor: "000000", OutlineThickness: 2, DisplayAngle: 0, FillColor: "ffffff", Opacity: 0.5, EditorLocked : false };
        let fg = editor.ModelEditor.AddToFormArray(editor.Form.get("MapItems") as UntypedFormArray, newItem, "MapItems");
        let m = new ShapeMapItem(fg, poly);
        editor.AddMapItem(m);
        return m;
    }
}
export class MapEditorBoxTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let size = 0.004; let x = location[0]; let y = location[1];
        let poly: any[] = [[x, y], [x + size, y], [x + size, y + size], [x, y + size], [x, y]];
        let newItem = { ClassName: "Shape", Name: "Box", Type: "Box", PolygonPoints: poly, Id: uuidv4(), OutlineColor: "000000", OutlineThickness: 2, DisplayAngle: 0,  FillColor: "ffffff", Opacity: 0.5, EditorLocked : false };
        let m = new ShapeMapItem(editor.ModelEditor.AddToFormArray(editor.Form.get("MapItems") as UntypedFormArray, newItem, "MapItems"), poly);
        editor.AddMapItem(m);
        return m;
    }
}


export class MapEditorIslandTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let size = 0.004; let x = location[0]; let y = location[1];
        let poly: any[] = [[x, y], [x + size, y], [x + size, y + size], [x, y + size], [x, y]];
        let newItem = { ClassName: "Shape", Name: "Island", Type: "Island", PolygonPoints: poly, Id: uuidv4(), OutlineColor: "999999", OutlineThickness: 2, DisplayAngle: 0, FillColor: "bbbbbb", Opacity: 0.8, EditorLocked : false };
        let m = new ShapeMapItem(editor.ModelEditor.AddToFormArray(editor.Form.get("MapItems") as UntypedFormArray, newItem, "MapItems"), poly);
        editor.AddMapItem(m);
        return m;
    }
}

export class MapEditorGrassIslandTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let size = 0.004; let x = location[0]; let y = location[1];
        let poly: any[] = [[x, y], [x + size, y], [x + size, y + size], [x, y + size], [x, y]];
        let newItem = { ClassName: "Shape", Name: "Grass Island", Type: "GrassIsland", PolygonPoints: poly, Id: uuidv4(), OutlineColor: "999999", OutlineThickness: 2, DisplayAngle: 0, FillColor: "bbbbbb", Opacity: 0.8, EditorLocked : false };
        let m = new ShapeMapItem(editor.ModelEditor.AddToFormArray(editor.Form.get("MapItems") as UntypedFormArray, newItem, "MapItems"), poly);
        editor.AddMapItem(m);
        return m;
    }
}

export class MapEditorGardenBedTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let size = 0.004; let x = location[0]; let y = location[1];
        let poly: any[] = [[x, y], [x + size, y], [x + size, y + size], [x, y + size], [x, y]];
        let newItem = { ClassName: "Shape", Name: "Garden Bed", Type: "GardenBed", PolygonPoints: poly, Id: uuidv4(), OutlineColor: "999999", OutlineThickness: 2, DisplayAngle: 0, FillColor: "bbbbbb", Opacity: 0.8, EditorLocked : false };
        let m = new ShapeMapItem(editor.ModelEditor.AddToFormArray(editor.Form.get("MapItems") as UntypedFormArray, newItem, "MapItems"), poly);
        editor.AddMapItem(m);
        return m;
    }
}

export class MapEditorPedestrianZoneTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let size = 0.004; let x = location[0]; let y = location[1];
        let poly: any[] = [[x, y], [x + size, y], [x + size, y + size], [x, y + size], [x, y]];
        let newItem = { ClassName: "Shape", Name: "Pedestrian Zone", Type: "PedestrianZone", PolygonPoints: poly, Id: uuidv4(), OutlineColor: "b5a304", OutlineThickness: 2, DisplayAngle: 0,  FillColor: "fce303", Opacity: 0.8, EditorLocked : false };
        let m = new ShapeMapItem(editor.ModelEditor.AddToFormArray(editor.Form.get("MapItems") as UntypedFormArray, newItem, "MapItems"), poly);
        editor.AddMapItem(m);
        return m;
    }
}

export class MapEditorPillarTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let size = 0.001; let x = location[0]; let y = location[1];
        let poly: any[] = [[x, y], [x + size, y], [x + size, y + size], [x, y + size], [x, y]];
        let newItem = { ClassName: "Shape", Name: "Pillar", Type: "Pillar", PolygonPoints: poly, Id: uuidv4(), OutlineColor: "555555", OutlineThickness: 2,  DisplayAngle: 0, FillColor: "e8e8e8", Opacity: 0.8, EditorLocked : false };
        let m = new ShapeMapItem(editor.ModelEditor.AddToFormArray(editor.Form.get("MapItems") as UntypedFormArray, newItem, "MapItems"), poly);
        editor.AddMapItem(m);
        return m;
    }
}

export class MapEditorRampTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let size = 0.004; let x = location[0]; let y = location[1];
        let poly: any[] = [[x, y], [x + size, y], [x + size, y + size], [x, y + size], [x, y]];
        let newItem = { ClassName: "Shape", Name: "Ramp", Type: "Ramp", PolygonPoints: poly, Id: uuidv4(), OutlineColor: "999999", OutlineThickness: 2, DisplayAngle: 0,  FillColor: "bbbbbb", Opacity: 0.8, EditorLocked : false };
        let m = new ShapeMapItem(editor.ModelEditor.AddToFormArray(editor.Form.get("MapItems") as UntypedFormArray, newItem, "MapItems"), poly);
        editor.AddMapItem(m);
        return m;
    }
}

export class MapEditorBushTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let size = 0.001; let x = location[0]; let y = location[1];
        let poly: any[] = [[x, y], [x + size, y], [x + size, y + size], [x, y + size], [x, y]];
        let newItem = { ClassName: "Shape", Name: "Bush", Type: "Bush", PolygonPoints: poly, Id: uuidv4(), OutlineColor: "999999", OutlineThickness: 2, DisplayAngle: 0,  FillColor: "bbbbbb", Opacity: 0.8, EditorLocked : false };
        let m = new ShapeMapItem(editor.ModelEditor.AddToFormArray(editor.Form.get("MapItems") as UntypedFormArray, newItem, "MapItems"), poly);
        editor.AddMapItem(m);
        return m;
    }
}

export class MapEditorCustomSvgTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let size = 0.001; let x = location[0]; let y = location[1];
        let poly: any[] = [[x, y], [x + size, y], [x + size, y + size], [x, y + size], [x, y]];
        let newItem = { ClassName: "Shape", Name: "Custom Svg", Type: "CustomSvg", PolygonPoints: poly, Id: uuidv4(), OutlineColor: "000000", OutlineThickness: 2, DisplayAngle: 0,  FillColor: "000000", Opacity: 0.8, EditorLocked : false, IsPublicFacing: true, MediaId: null };
        let m = new ShapeMapItem(editor.ModelEditor.AddToFormArray(editor.Form.get("MapItems") as UntypedFormArray, newItem, "MapItems"), poly);
        m.RequireParent = null;
        editor.AddMapItem(m);
        return m;
    }
}

export class MapEditorTallTreeTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let size = 0.001; let x = location[0]; let y = location[1];
        let poly: any[] = [[x, y], [x + size, y], [x + size, y + size], [x, y + size], [x, y]];
        let newItem = { ClassName: "Shape", Name: "TallTree", Type: "TallTree", PolygonPoints: poly, Id: uuidv4(), OutlineColor: "999999", OutlineThickness: 2, DisplayAngle: 0,  FillColor: "bbbbbb", Opacity: 0.8, EditorLocked : false };
        let m = new ShapeMapItem(editor.ModelEditor.AddToFormArray(editor.Form.get("MapItems") as UntypedFormArray, newItem, "MapItems"), poly);
        editor.AddMapItem(m);
        return m;
    }
}

export class MapEditorSmallTreeTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let size = 0.001; let x = location[0]; let y = location[1];
        let poly: any[] = [[x, y], [x + size, y], [x + size, y + size], [x, y + size], [x, y]];
        let newItem = { ClassName: "Shape", Name: "SmallTree", Type: "SmallTree", PolygonPoints: poly, Id: uuidv4(), OutlineColor: "999999", OutlineThickness: 2, DisplayAngle: 0,  FillColor: "bbbbbb", Opacity: 0.8, EditorLocked : false };
        let m = new ShapeMapItem(editor.ModelEditor.AddToFormArray(editor.Form.get("MapItems") as UntypedFormArray, newItem, "MapItems"), poly);
        editor.AddMapItem(m);
        return m;
    }
}

export class MapEditorStairwellTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let size = 0.001; let x = location[0]; let y = location[1];
        let poly: any[] = [[x, y], [x + size, y], [x + size, y + size], [x, y + size], [x, y]];
        let newItem = { ClassName: "Shape", Name: "Stairwell", Type: "Stairwell", PolygonPoints: poly, Id: uuidv4(), OutlineColor: "999999", OutlineThickness: 2, DisplayAngle: 0,  FillColor: "bbbbbb", Opacity: 0.8, EditorLocked : false };
        let m = new ShapeMapItem(editor.ModelEditor.AddToFormArray(editor.Form.get("MapItems") as UntypedFormArray, newItem, "MapItems"), poly);
        editor.AddMapItem(m);
        return m;
    }
}

export class MapEditorBusinessTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let size = 0.004; let x = location[0]; let y = location[1];
        let poly: any[] = [[x, y], [x + size, y], [x + size, y + size], [x, y + size], [x, y]];
        let newItem = { ClassName: "Shape", Name: "Business", Type: "BusinessDirectory", PolygonPoints: poly, Id: uuidv4(), OutlineColor: "555555", OutlineThickness: 2, DisplayAngle: 0,  FillColor: "e8e8e8", Opacity: 0.8, BusinessDirectoryId: null, EditorLocked : false };
        let m = new ShapeMapItem(editor.ModelEditor.AddToFormArray(editor.Form.get("MapItems") as UntypedFormArray, newItem, "MapItems"), poly);
        editor.AddMapItem(m);
        return m;
    }
}
export class MapEditorSolidWallTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let sizeX = 0.01, sizeY = 0.0005; 
        let x = location[0]; let y = location[1];
        let poly: any[] = [[x, y], [x + sizeX, y], [x + sizeX, y + sizeY], [x, y + sizeY], [x, y]];
        let newItem = { ClassName: "Shape", Name: "Solid Wall", Type: "SolidWall", PolygonPoints: poly, Id: uuidv4(), OutlineColor: "000000", OutlineThickness: 2, DisplayAngle: 0,  FillColor: "555555", Opacity: 0.8, EditorLocked : false };
        let m = new ShapeMapItem(editor.ModelEditor.AddToFormArray(editor.Form.get("MapItems") as UntypedFormArray, newItem, "MapItems"), poly);
        editor.AddMapItem(m);
        return m;
    }
}
export class MapEditorGuardRailTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let sizeX = 0.01, sizeY = 0.0005; 
        let x = location[0]; let y = location[1];
        let poly: any[] = [[x, y], [x + sizeX, y], [x + sizeX, y + sizeY], [x, y + sizeY], [x, y]];
        let newItem = { ClassName: "Shape", Name: "Guard Rail", Type: "GuardRail", PolygonPoints: poly, Id: uuidv4(), OutlineColor: "b5a304", OutlineThickness: 2, DisplayAngle: 0,  FillColor: "fce303", Opacity: 0.8, EditorLocked : false };
        let m = new ShapeMapItem(editor.ModelEditor.AddToFormArray(editor.Form.get("MapItems") as UntypedFormArray, newItem, "MapItems"), poly);
        editor.AddMapItem(m);
        return m;
    }
}
export class MapEditorGatewayTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let newItem = { ClassName: "GatewayConfiguration", Name:"Gateway", WakeUpTime: null, DisplayAngle: 0, SleepTime: null, LocationPoints: location, ChipId: null, EditorLocked : false };
        let m = new GatewayMapItem(editor.ModelEditor.AddToFormArray(editor.Form.get("GatewayConfigurations") as UntypedFormArray, newItem, "GatewayConfigurations"), location);
        editor.AddMapItem(m);
        return m;
    }
}
export class MapEditorSignTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let newItem = { ClassName: "SignConfiguration", Name:"Sign", WakeUpTime: null, DisplayAngle: 0, SleepTime: null, StartOfDayTime: '08:00:00', EndOfDayTime: '20:00:00', DayBrightness: 100, NightBrightness: 100, Mode: 0, DisplayRuleSetId: null, LocationPoints: location, ChipId: null, EditorLocked : false };
        let m = new SignMapItem(editor.ModelEditor.AddToFormArray(editor.Form.get("SignConfigurations") as UntypedFormArray, newItem, "SignConfigurations"), location);
        editor.AddMapItem(m);
        return m;
    }
}

export class MapEditorGuidanceLightTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        
        let poly = Geo.NewPolygonAtCenter(location, 0.0025, 0.0045);
        let rows = editor.MapItemsByType.get("ParkingRow");
        if (rows == null) return;
        for (let row of rows) {
            let bounds = Geo.GetPolygonPointBounds(row);
            if (bounds.Contains(location)) {
                let rowFg = row.FormControl as UntypedFormGroup;
                let guidanceLights = editor.ModelEditor.FormArray(rowFg, "GuidanceLightConfigurations");
                //let newLight = { Id: uuidv4(), ClassName: "Space", Name: "1", PolygonPoints: poly, ParkingSpaceTypeId: null };
                let newItem = { ClassName: "GuidanceLightConfiguration", Name:"Guidance Light", DisplayAngle: 0, LocationPoints: location, ChipId: null, EditorLocked : false, PortAssignments : [ {ParkingSpaceId: null, PortId : null}] };
                let fg = editor.ModelEditor.AddToFormArray(guidanceLights, newItem, "GuidanceLightConfigurations");
                let m = new GuidanceLightMapItem(fg, row as ParkingRowMapItem, location);
                editor.AddMapItem(m);
                return m;
            }
        }
        editor.toastr.warning("Guidance Lights must be placed within Rows", "Placement Error");
        return null;
    }
}

export class MapEditorCarCounterTool extends MapEditorToolBase {
    public override Execute(editor: LevelmapeditorComponent, location: number[]) {
        let newItem = { ClassName: "CarCounterConfiguration", Name:"Car Counter", DisplayAngle: 0, LocationPoints: location, ChipId: null, EditorLocked : false };
        let m = new CarCounterMapItem(editor.ModelEditor.AddToFormArray(editor.Form.get("CarCounterConfigurations") as UntypedFormArray, newItem, "CarCounterConfigurations"), location);
        editor.AddMapItem(m);
        return m;
    }
}