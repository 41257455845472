<app-loader [Parent]="this"></app-loader>

<div class="lane-diagnostics-container" *ngIf="Lane">
  <div class="columns is-mobile mb-0">
    <div class="column pb-0">
      <div class="lane-header section-title">
        <h1>{{Lane.ParkingLotName}} - {{Lane.Name}}</h1>
      </div>
    </div>
  </div>

  <div class="columns pb-5">
    <div class="column is-one-quarter">
        <div class="section-header">
            <h1 class="section-title">Sessions</h1>
            <div class="header-controls">
                <div class="live-switcher">
                    <label class="switch">
                        <input type="checkbox" 
                               [ngModel]="autoFollowSessions"
                               (ngModelChange)="setAutoFollow($event)">
                        <span class="slider">
                            <ng-container *ngIf="autoFollowSessions">
                                <span class="live-indicator"></span>
                                Auto-follow
                            </ng-container>
                            <ng-container *ngIf="!autoFollowSessions">
                                Manual
                            </ng-container>
                        </span>
                    </label>
                </div>
            </div>
        </div>
        <div class="card scrollable-container frog-scrollbar">
            <div *ngIf="isLoadingSessions" class="loading-container">
                <div class="spinner froggreen"></div>
            </div>
            <div *ngIf="!isLoadingSessions">
                <div *ngFor="let session of sessions" 
                     class="list-item" 
                     [ngClass]="{ 'selected' : selectedSession == session.SessionKey}" 
                     (click)="selectSession(session.SessionKey)">
                    <div class="item-content">
                        <div class="secondary-text">
                            {{session.ArrivalAtGateTime | date:'mediumTime'}}
                            <span *ngIf="session.IsImported" 
                                  class="imported-indicator" 
                                  title="Imported Session">
                                <i class="fas fa-file-import"></i>
                            </span>
                        </div>
                        <div class="info-row">
                            <span class="info-row-title" *ngIf="session.PlateNumber">{{session.PlateNumber}}</span>
                            <span class="info-row-title-small" *ngIf="!session.PlateNumber">
                                <ng-container *ngIf="session === sessions[0]">Pending plate...</ng-container>
                                <ng-container *ngIf="session !== sessions[0]">Click to load</ng-container>
                            </span>
                            <span class="info-row-activity">{{session.SessionKey}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
    <div class="column is-half">
        <div class="columns pb-5">
            <div class="column is-half">
                <div class="section-header">
                    <h1 class="section-title">Session Details</h1>
                    <div class="header-controls">
                        <input type="file" 
                               #fileInput 
                               style="display: none;" 
                               accept=".json"
                               (change)="importSession($event)">
                        <button class="button is-small mr-2" 
                                (click)="fileInput.click()"
                                title="Import session from file">
                            <span class="icon">
                                <i class="fas fa-upload"></i>
                            </span>
                        </button>
                        <button class="button is-small" 
                                [disabled]="!currentLoadedSession"
                                (click)="downloadSession()"
                                [title]="currentLoadedSession?.IsLive ? 
                                        'Download will start when session completes' : 
                                        'Download session data'">
                            <span class="icon">
                                <i class="fas fa-download"></i>
                            </span>
                        </button>
                    </div>
                </div>
                <div class="card">
                    <div *ngIf="(isLoadingSessionDetails && selectedSession != undefined) || isLoadingSessions" class="loading-container">
                        <div class="spinner froggreen"></div>
                    </div>
                    <div *ngIf="!isLoadingSessions && selectedSession == undefined" class="no-image-placeholder">
                      <p>Please select a session to view details</p>
                    </div>
                    <div *ngIf="!isLoadingSessionDetails && selectedSession != undefined">
                      <div class="panel-block detail-row">
                        <span class="label" *ngIf="currentLoadedSession.ProcessingStarted || !currentLoadedSession.IsLive">Time at Gate</span>
                        <span class="label" *ngIf="!currentLoadedSession.ProcessingStarted && currentLoadedSession.IsLive">Idle time</span>
                        <span class="value">{{SecondsToUsefulTime(currentLoadedSession.TimeAtGate)}} <span class="is-size-7">(Since {{currentLoadedSession.ArrivalAtGateTime | date:'mediumTime'}})</span></span> 
                      </div>
                      <div class="panel-block detail-row">
                          <span class="label">Plate Number</span>
                          <span class="value">{{currentLoadedSession.PlateNumber}}</span>
                      </div>
                      <div class="panel-block detail-row">
                          <span class="label">Rate Set</span>
                          <span class="value">{{currentLoadedSession.RateSetName}}</span>
                      </div>
                      <div class="panel-block detail-row">
                          <span class="label">
                            <ng-container *ngIf="!currentLoadedSession.EntryDate || isEntryBeforeArrival(currentLoadedSession.EntryDate, currentLoadedSession.ArrivalAtGateTime)">
                              Session Duration
                            </ng-container>
                            <ng-container *ngIf="currentLoadedSession.EntryDate && !isEntryBeforeArrival(currentLoadedSession.EntryDate, currentLoadedSession.ArrivalAtGateTime)">
                              Entry Date
                            </ng-container>
                          </span>
                          <span class="value">
                            <ng-container *ngIf="currentLoadedSession.EntryDate">
                              <ng-container *ngIf="isEntryBeforeArrival(currentLoadedSession.EntryDate, currentLoadedSession.ArrivalAtGateTime)">
                                {{getDurationFromEntry(currentLoadedSession.EntryDate, currentLoadedSession.ArrivalAtGateTime)}}
                                <span class="is-size-7">(Entry: {{currentLoadedSession.EntryDate | date:'mediumTime'}})</span>
                              </ng-container>
                              <ng-container *ngIf="!isEntryBeforeArrival(currentLoadedSession.EntryDate, currentLoadedSession.ArrivalAtGateTime)">
                                {{currentLoadedSession.EntryDate | date:'medium'}}
                              </ng-container>
                            </ng-container>
                          </span>
                      </div>
                      <div class="panel-block detail-row">
                        <span class="label">Session Key</span>
                        <span class="value is-size-7">{{currentLoadedSession.SessionKey}}</span>
                      </div>
                    </div>
                </div>
            </div>
            <div class="column is-half">
                <div class="section-header">
                    <h1 class="section-title">Camera & Screens</h1>
                </div>
                <div class="card">
                    <div *ngIf="(isLoadingSessionDetails && selectedSession != undefined) || isLoadingSessions" class="loading-container">
                        <div class="spinner froggreen"></div>
                    </div>
                    <div *ngIf="!selectedSession && !isLoadingSessions" class="no-image-placeholder">
                        <p>Please select a session to view images</p>
                    </div>
                    <div *ngIf="selectedSession && currentLoadedSession">
                        <div *ngIf="currentLoadedSession.images?.length > 0" class="image-container">
                            <img [src]="'data:image/jpeg;base64,' + getSelectedImage()" alt="Selected Image">
                            <button class="expand-button" (click)="fetchAndShowImageModal()">
                                <i class="fas fa-expand"></i>
                            </button>
                        </div>
                        <div *ngIf="currentLoadedSession.images?.length > 0" class="thumbnails-container">
                            <div *ngFor="let image of currentLoadedSession.images; let i = index" 
                                 class="thumbnail" 
                                 [ngClass]="{'selected': selectedImageId === image.ImageId}"
                                 (click)="selectImage(image.ImageId)">
                                <div class="image-type-indicator" [ngClass]="image.ImageType">
                                    <i [class]="image.ImageType === 'CameraSnapshot' ? 'fas fa-car' : 'fas fa-desktop'"></i>
                                </div>
                                <img [src]="'data:image/jpeg;base64,' + image.Bytes" [alt]="image.ImageType + ' image'">
                            </div>
                        </div>
                        <div *ngIf="!currentLoadedSession.images?.length" class="no-image-placeholder">
                            <ng-container *ngIf="currentLoadedSession.IsLive">
                                <div class="bouncing-dots">
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                </div>
                                <p class="mt-3">Waiting for images...</p>
                            </ng-container>
                            <ng-container *ngIf="!currentLoadedSession.IsLive">
                                <i class="fas fa-image-slash"></i>
                                <p>No images found</p>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="workflows">
            <div class="section-header">
                <h1 class="section-title">Workflow</h1>
                <div class="header-controls">
                    <button class="button is-small" 
                            (click)="confirmForceHome()"
                            [disabled]="!currentLoadedSession?.IsLive"
                            title="Force workflow back to home state">
                        <span class="icon">
                            <i class="fas fa-house"></i>
                        </span>
                    </button>
                </div>
            </div>
            <div class="card frog-scrollbar">
                <app-workflowviewer [workflow]="getCurrentWorkflow()" [useCasePath]="currentLoadedSession?.StateEvents" [changeCounter]="stateEventCounter"></app-workflowviewer>
            </div>
        </div>
        <div class="events">
            <div class="section-header">
                <h1 class="section-title">Events</h1>
            </div>
            <div class="card">
                <div *ngIf="(isLoadingSessionDetails && selectedSession != undefined) || isLoadingSessions" class="loading-container">
                    <div class="spinner froggreen"></div>
                </div>
                <div *ngIf="!isLoadingSessions && selectedSession == undefined" class="no-image-placeholder">
                  <p>Please select a session to view events</p>
                </div>
                <div *ngIf="!isLoadingSessionDetails">
                  <div *ngIf="currentLoadedSession.DebugEvents.length == 0">
                    <div class="p-4 m-4"><i>No recorded events.</i></div>
                  </div>
                  <div *ngIf="currentLoadedSession.DebugEvents.length > 0" class="event-list frog-scrollbar">
                    <div class="event-row" *ngFor="let event of currentLoadedSession.DebugEvents; let even = even"
                         [ngClass]="{'even': even}">
                      <div class="event-timestamp">
                        {{event.TimeStamp | date:'mediumTime'}}
                      </div>
                      <div class="event-device">
                        <div class="event-device-type">{{event.DeviceType}}</div>
                        <div class="event-device-identifier" *ngIf="event.DeviceIdentifier">{{event.DeviceIdentifier}}</div>
                      </div>
                      <div class="event-details">
                        <div class="event-name">{{event.EventName}}</div>
                        <div class="event-description" *ngIf="event.EventDescription">{{event.EventDescription}}</div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
  
    <div class="column is-one-quarter">
        <div class="section-header">
            <h1 class="section-title">
                Devices
                <ng-container *ngIf="currentLoadedSession && !currentLoadedSession.IsLive && currentLoadedSession.DevicesSnapshotTime">
                    <span class="snapshot-time">(as at {{currentLoadedSession.DevicesSnapshotTime | date:'medium'}})</span>
                </ng-container>
            </h1>
            <div class="header-controls">
                <div class="live-switcher" *ngIf="currentLoadedSession?.IsLive">
                    <label class="switch">
                        <input type="checkbox" 
                               [(ngModel)]="isLiveMode"
                               [disabled]="!selectedSession || currentLoadedSession?.IsLive">
                        <span class="slider">
                            <ng-container *ngIf="isLiveMode">
                                <span class="live-indicator"></span>
                                Live
                            </ng-container>
                            <ng-container *ngIf="!isLiveMode">
                                <i class="fas fa-camera"></i>
                                Snapshot
                            </ng-container>
                        </span>
                    </label>
                </div>
            </div>
        </div>
        <div class="card scrollable-container frog-scrollbar">
            <div *ngFor="let device of getDevicesToShow()" 
                 class="list-item device-item"
                 [class.expanded]="expandedDevices.has(device.DeviceId)"
                 (click)="toggleDevice(device.DeviceId)">
                <div class="item-content">
                    <div class="status-indicator">
                        <span class="status-dot" 
                              [ngClass]="{
                                'good': device.Status === 'Connected',
                                'degraded': device.Status === 'Degraded',
                                'offline': device.Status === 'Offline',
                                'syncing': device.Status === 'Syncing',
                                'unknown': !['Connected', 'Degraded', 'Offline', 'Syncing'].includes(device.Status)
                              }">
                        </span>
                        <span class="connection-status">{{device.Status ?? 'Unknown'}}</span>
                    </div>
                    <div class="info-row">
                        <span class="info-row-title">{{device.DeviceType}}</span>
                        <span class="info-row-activity" *ngIf="device.CurrentActivity">
                            {{device.CurrentActivity.charAt(0).toUpperCase() + device.CurrentActivity.slice(1)}}
                        </span>
                    </div>
                    <div class="device-details">
                        <div class="detail-item" *ngIf="device.Name">
                            <span class="detail-label">Name:</span>
                            <span class="detail-value">{{device.Name}}</span>
                        </div>
                        <div class="detail-item" *ngIf="device.ConnectionStatus">
                            <span class="detail-label">Connection:</span>
                            <span class="detail-value">{{device.ConnectionStatus}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div class="modal" [class.is-active]="showOfflineModal">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box">
        <i class="fas fa-wifi-slash fa-2x has-text-danger mb-3"></i>
        <h1 class="title is-4 has-text-danger">Controller failed to respond</h1>
        <p>The lane controller has not responded within the expected timeframe. There may be an issue with communications or power.</p>
        <p class="has-text-grey mt-2">You may need to restart the controller or contact support if the issue persists.</p>
      </div>
    </div>
  </div>
  <div class="modal" [class.is-active]="showImageModal">
    <div class="modal-background" (click)="showImageModal = false"></div>
    <div class="modal-content">
      <div class="image-modal-container">
        <img [src]="'data:image/jpeg;base64,' + getSelectedImage()" alt="Full size image">
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" (click)="showImageModal = false"></button>
  </div>
  <div class="modal" [class.is-active]="showInactivityModal">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box">
        <i class="fas fa-clock fa-2x has-text-warning mb-3"></i>
        <h1 class="title is-4">Are you still there?</h1>
        <p>This session will expire in {{countdownSeconds}} seconds due to inactivity.</p>
        <p class="has-text-grey mt-2">Click anywhere or move your mouse to continue.</p>
      </div>
    </div>
  </div>
  <div class="modal" [class.is-active]="showExpiredModal">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box">
        <i class="fas fa-triangle-exclamation fa-2x has-text-danger mb-3"></i>
        <h1 class="title is-4">Session Expired</h1>
        <p>Your session has expired due to inactivity.</p>
        <p class="has-text-grey mt-2">Please refresh the page to continue.</p>
        <button class="button is-primary mt-4" onclick="window.location.reload()">
          <span class="icon">
            <i class="fas fa-arrows-rotate"></i>
          </span>
          <span>Refresh Page</span>
        </button>
      </div>
    </div>
  </div>
  <div class="modal" [class.is-active]="showForceHomeModal">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box">
        <i class="fas fa-triangle-exclamation fa-2x has-text-warning mb-3"></i>
        <h1 class="title is-4">Confirm Force Home</h1>
        <p>Are you sure you want to force the workflow back to its home state? This should only be used when the workflow is stuck.</p>
        <div class="buttons mt-4 is-justify-content-flex-end">
          <button class="button" (click)="showForceHomeModal = false">
            Cancel
          </button>
          <button class="button is-warning countdown-button" [ngClass]="{'animating': forceHomeCountdown > 0}"
                  [disabled]="forceHomeCountdown > 0"
                  (click)="executeForceHome()">
            <span>Force Home</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>