import * as turf from '@turf/turf'
import { Polygon, Position } from '@turf/turf';
import { Bounds, Geo } from './geo';
import { Geometry2d } from './geometry2d';

export class Geography {
    
    public static DoublesToTurfPoint(doubles : number[]) : Position {
        return turf.point([doubles[0], doubles[1]]).geometry.coordinates;
    }

    public static ConvertLocalCoordinatesToTurfPolygon(localCoords : number[][], localScaleToMeters : number, geoCenter : number[], localCenter : number[], rotationDegrees : number = 0) : Polygon {
        let localOrigin = localCenter;// || Geo.PolygonCenterAsNumbers(localCoords);
        let geoPoints = [];
       
        for(let i in localCoords){
          let point = localCoords[i];
          let a = Geometry2d.AngleFromPoint(point, localOrigin);
          let d = Geometry2d.DistanceBetweenPoints(point, localOrigin);
          let tpoint = turf.point([geoCenter[0], geoCenter[1]]);
          const geoPoint = turf.destination(tpoint, d, a > 180 ? -360+a : a);
          geoPoints.push([geoPoint.geometry.coordinates[0], geoPoint.geometry.coordinates[1]]);
        }
        let poly = turf.polygon([geoPoints]);
        let rotatedPoly = turf.transformRotate(poly, rotationDegrees, { pivot : geoCenter});
        return rotatedPoly.geometry;
    }

    public static PolygonToGoogleMaps(polygon : Polygon) : google.maps.LatLngLiteral[]{
        let coords : google.maps.LatLngLiteral[] = [];
        for(let i in polygon.coordinates[0]){
            coords.push( { lng : polygon.coordinates[0][i][0], lat: polygon.coordinates[0][i][1]});
        }
        return coords;
    }

    public static ReverseTransformPoint(geoPoint: turf.helpers.Feature<turf.helpers.Point, turf.helpers.Properties>, geoCenter: number[], localCenter: number[], rotationDegrees: number = 0, localScaleToMeters: number = 1000): number[] {
        // Step 1: Undo the rotation
        const unrotatedPoint = turf.transformRotate(geoPoint, -rotationDegrees, { pivot: geoCenter });
      
        // Step 2: Reverse the translation (conversion to local coordinates)
        const geoCoords = turf.getCoords(unrotatedPoint);
        const distance = turf.distance(turf.point(geoCenter), unrotatedPoint, { units: 'meters' });
        const bearing = turf.bearing(turf.point(geoCenter), unrotatedPoint);
      
        const localPoint = turf.destination(turf.point(localCenter), -distance / localScaleToMeters, bearing);
      
        // Extract and return the coordinates as an array
        const localCoordinates: number[] = [localPoint.geometry.coordinates[0], localPoint.geometry.coordinates[1]];
        return localCoordinates;
      }
}
