<app-pageheader [Parent]="this">
  <add-button AddRoute="/usermanagement/roles/new/edit" [RouteData]="userRoles ? 'true' : 'false'"></add-button>
</app-pageheader>

<app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="organization/roles/search"
  [AllowEdit]="true"
  EditUrl="/usermanagement/roles/:id">
  <app-filter-set>
    <div app-search-filter></div>
    <div app-admin-role-filter [SelectedIsAdmin]="userRoles" (ValueOutput)="filterChanged($event)"></div>
    <div app-search-button></div>
  </app-filter-set>
</app-datalistview>