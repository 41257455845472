import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { LevelEditorComponent } from "../../leveleditor.component";
import { v4 as uuidv4 } from 'uuid';
import { PointTool } from "./PointTool";
import { Controller } from "../../components/PointComponents/Controller";
import { FabricHelpers } from "../../fabric.js/helpers";
import { Geo } from "src/app/util/geo";
import { PolygonMapComponent } from "../../components/PolygonComponents/PolygonMapComponent";

export class ControllerTool extends PointTool{
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }

    public override CreateNew(canvasX : number, canvasY: number){

        let newControllerFormGroup = new FormGroup({
            Id: new FormControl(uuidv4()),
            Name: new FormControl("Controller"),
            DisplayAngle: new FormControl(0),
            OfflineThresholdMinutes: new FormControl(null),
            RelayIpAddress: new FormControl(null),
            RelayPort: new FormControl(null),
            RelayType: new FormControl(null),
            SerialNumber: new FormControl(null),
            SoftwareVersionId: new FormControl(null),
            LocationPoints: new FormControl(this.CreatePointFromXY(canvasX, canvasY)),
            EditorLocked: new FormControl(false)
        });

        var parentFormItem = null;
        const Lanes = this.editor.MapItemsByType.get('Lane');
        if(Lanes){
            for (let lane of Lanes) {
                var points = FabricHelpers.GetAdjustedXY(this.canvas, canvasX, canvasY);
                if (Geo.GetPolygonPointBounds((lane as PolygonMapComponent).formItem.get('PolygonPoints')?.value).Contains([points[0] / this.ScaleFactor, points[1] / this.ScaleFactor])) {     
                    parentFormItem = lane.formItem;
                    lane.formItem.get('LocalControllerId').setValue(newControllerFormGroup.get('Id')?.value);

                    let controllers = this.editor.ModelEditor.FormArray(this.editor.Form, "Controllers");
                    (controllers as FormArray).push(newControllerFormGroup);
                    var component = new Controller(this.editor, this.canvas, newControllerFormGroup, parentFormItem);
                    this.editor.Form.markAsDirty();
                    return component;
                }
            }
        }
        this.editor.toastService.error('You must place your Local Controller within a Lane.');
        return null;
    }
}