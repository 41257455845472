<div class="chart-container" [style.height.px]="height">
  <div class="chart-header">
    <div class="chart-title">{{title}}</div>
  </div>
  <div class="canvas-container" style="height: calc(100% - 40px);">
    <canvas baseChart 
      [data]="processedChartData"
      [options]="chartOptions"
      [type]="chartType">
    </canvas>
    <app-loader [Parent]="this"></app-loader>
  </div>
</div>