<div class="modal is-active" [formGroup]="Form">
    <div class="modal-background" [@modalbackground]="Closing ? 'close' : ''"></div>
    <div class="modal-card" [@modalopen]="Closing ? 'close' : ''">
        <header class="modal-card-head">
            <p class="modal-card-title">Traffic Light</p>
        </header>
        <section class="modal-card-body"style="min-width: 800px;overflow: hidden;">
            <app-field Label="Barrier Type" class="control mb-3">
                <span class="select three-quarters-width">
                    <select formControlName="Type">
                        <option value=""></option>
                        <option value="k1sign">K1 Sign</option>
                        <option value="k3sign">K3 Sign</option>
                        <option value="relaysign">Relay Sign</option>
                    </select>
                </span>
            </app-field>
            <app-field Label="IP Address" class="control mb-3">
                <input formControlName="LocalAddress" type="text" class="input three-quarters-width"/>
            </app-field>
            <app-field Label="Port" class="control mb-3">
                <input formControlName="LocalPort" type="number" class="input three-quarters-width"/>
            </app-field>
            <app-field Label="Enabled for" class="control mb-3">
                <span class="select three-quarters-width">
                    <select formControlName="ActiveDirection">
                        <option value="Entry">Entry</option>
                        <option value="Exit">Exit</option>
                        <option value="Both">Both</option>
                    </select>
                </span>
            </app-field>
            <app-field Label="Is Inverted" class="control mb-3">
                <span class="select three-quarters-width">
                    <select formControlName="IsInverted">
                        <option [ngValue]="true">True</option>
                        <option [ngValue]="false">False</option>
                    </select>
                </span>
            </app-field>
            <app-field Label="Brightness" class="control mb-3">
                    <input type="number" formControlName="Brightness" class="input three-quarters-width"/>
            </app-field>
            <app-field Label="Drive Traffic Light by" class="control mb-3">
                <span class="select three-quarters-width">
                    <select formControlName="WorkflowDriven">
                        <option [ngValue]="true">Workflow</option>
                        <option [ngValue]="false">Lane Direction</option>
                    </select>
                </span>
            </app-field>
            <app-field [Visible]="Form.get('WorkflowDriven')?.value == false" Label="Show Green for" class="control mb-3">
                <span class="select three-quarters-width">
                    <select formControlName="ShowGreenFor">
                        <option>Entry</option>
                        <option>Exit</option>
                    </select>
                </span>
            </app-field>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" (click)="confirm()">OK</button>
        </footer>
    </div>
</div>