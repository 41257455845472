<app-pageheader Title="Price Rules">
  <add-button AddRoute="/settings/rates/pricerules/new/edit"></add-button>
</app-pageheader>

<app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="parking/pricerules/searches"
  [AllowEdit]="true" EditUrl="/settings/rates/pricerules/:id/edit">
  <app-filter-set>
    <div app-search-filter></div>
    <div app-search-button></div>
  </app-filter-set>
</app-datalistview>