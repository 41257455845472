import { FormArray } from "@angular/forms";
import { LevelEditorComponent } from "../../leveleditor.component";
import { PolygonMapComponent } from "./PolygonMapComponent";

export class Row extends PolygonMapComponent{
    public override ClassName: string = "Row"
    public override StrokeColor: string = "#FFFFFF";
    public override StrokeWidth: number = 3;
    public override PatternFill = true;
    public override PatternFillColor = "#cccccc";
    public override ShowLabel: boolean = true;
    public override zIndex: number = 90;
    public override ChildrenArrays = ["Spaces"];

    constructor(leveleditorcomponent: LevelEditorComponent, canvas: any, formItem: any, parentFormItem: any, forceClone: boolean = false) {
        super(leveleditorcomponent, canvas, formItem, parentFormItem, forceClone);
        this.AddFabricItem();
    }

    public override Clone(formitem: any){
        return new Row(this.leveleditorcomponent, this.canvas, formitem, this.parentFormItem, true);
    }
}