import { Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiService } from 'src/app/Services/api.service';
import { CalendarService } from 'src/app/Services/calendar.service';
import { ColorService } from 'src/app/Services/color.service';
import { Color } from 'src/app/util/color';
import { MediaService } from 'src/app/Services/media.service';
import { ApiServiceBase } from 'src/app/Services/api.service.base';

@Component({
  selector: 'app-editspacetype',
  templateUrl: './editspacetype.component.html',
  styleUrls: ['./editspacetype.component.scss']
})
export class EditspacetypeComponent extends ModelEditor {

  public TextColor = Color.GetVisibleTextClassFor;
  public fileByteArray = [];

  constructor(public calendarService: CalendarService, private injector: Injector) {
    super("infrastructure/spacetypes", injector);
  }

  public override DefaultModel(): any {
    return {
      ClassName: "ParkingSpaceType",
      Name: "New SpaceType",
      Color: "#ffffff",
      Description: "Add a Description",
      IncludeInCounts: false,
      MediaId: null
    };
  }

  public override AfterModelLoaded(): void {
  }

  public override BeforeSave(): void {

  }
  public override Validators(): any {
    return {
      "Name": [Validators.required],
      "Color": [Validators.required]
    };
  }

  public ClassForColor() {
    let c = this.Form.get("Color")?.value;
    return Color.GetVisibleTextClassFor(c);
  }

  MediaIdChange(mediaId: any) {
    this.Form.get('MediaId')?.setValue(mediaId);
  }
}

