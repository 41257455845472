import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { LocalStorageService } from 'src/app/Services/local-storage.service';
import { FilterFiltersBase, IFilter, IFilterFilters } from '../ifilter';

@Component({
  selector: '[app-discount-filter]',
  templateUrl: './discount-filter.component.html',
  styleUrls: ['./discount-filter.component.scss'],
  host: {'class':'control select'}
})
export class DiscountFilterComponent extends FilterFiltersBase implements OnInit, IFilterFilters {
  constructor(route: ActivatedRoute) {
    super(route);
  }

  public Filters: any[] = [];
  public SelectedDiscountFilter: any = "";
  public FilterName: string = "discount";
  QueryParamPrefix: string= '';

  ngOnInit(): void {

    this.SelectedDiscountFilter = "Active";

    var vals = super.GetParamFields(this.QueryParamPrefix + this.FilterName);
    if(vals != null) {
      this.SelectedDiscountFilter = vals[2];
    }

    if (this.SelectedDiscountFilter != "") {
      this.Filters.push({
        Property: "DiscountCodeStatus",
        Comparator: "==",
        Value: this.SelectedDiscountFilter,
        FilterName: this.FilterName
      })
    }
  }

  public addDiscountCodeFilter(event: any) {
    var val = event.target.value;
    this.Filters = [];

    if (val != "") {
      this.Filters.push({
        Property: "DiscountCodeStatus",
        Comparator: "==",
        Value: val,
        FilterName: this.FilterName
      });

      this.SelectedDiscountFilter = val;
    }
  }
}
