export class Datalistrequestfilter {

  public Property: string = "";
  public Comparator: string = "";
  public Value: string = "";
  public FilterName: string = "";
  constructor(property?: string, comparator?: string, value?: string, filterName?: string) {
    if (property != null) {
      this.Property = property;
    }
    if (comparator != null) {
      this.Comparator = comparator;
    }
    if (value != null) {
      this.Value = value;
    }
    if(filterName != null){
      this.FilterName = filterName;
    }
  }
}

