import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ActivatedRoute } from '@angular/router';
import { WorkflowviewerComponent } from './workflowviewer/workflowviewer.component';
import { ServiceBusClient, ServiceBusReceiver } from '@azure/service-bus';
import { LoginService } from 'src/app/auth/login.service';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { Subscription, timer, interval, firstValueFrom, fromEvent, merge, map, timeout } from 'rxjs';
import { timeout as rxjsTimeout } from 'rxjs/operators';

@Component({
  selector: 'app-lanediagnostics',
  templateUrl: './lanediagnostics.component.html',
  styleUrls: ['./lanediagnostics.component.scss']
})
export class LanediagnosticsComponent extends Busyable implements OnInit, OnDestroy {
  public Lane: any;
  public sessions: any[] = [];
  private loadedSessions: any[] = [];
  public currentLoadedSession: any = null;
  public isLoadingSessions = true;
  public isLoadingSessionDetails = true;
  public stateEventCounter = 0;
  public selectedSession?: string;
  public isLiveMode: boolean = true;
  private laneId!: string;

  public selectedImageId: number = 0;
  public autoFollowSessions = false;

  private initMessageReceived = false;
  public showImageModal = false;
  public showOfflineModal = false;
  public showInactivityModal = false;
  public showExpiredModal = false;
  public showForceHomeModal = false;
  
  private serviceBus = {
    client: undefined as ServiceBusClient | undefined,
    listener: undefined as ServiceBusReceiver | undefined
  };

  private subscriptions = {
    timer: undefined as Subscription | undefined,
    timeAtGate: undefined as Subscription | undefined,
    initMessageTimeout: undefined as Subscription | undefined,
    keepAliveTimeout: undefined as Subscription | undefined,
  };

  public expandedDevices: Set<string> = new Set();

  private inactivityTimeout?: any;
  private inactivityModalTimeout?: any;
  public countdownSeconds = 60;
  private readonly INACTIVITY_TIMEOUT_MINUTES = 5;
  private countdownInterval?: any;
  
  private pendingDownload = false;
  
  public forceHomeCountdown: number = 0;
  private countdownTimer?: any;

  constructor(
    private apiService: ApiServiceBase,
    private toastService: ToastrService,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private permissionService: PermissionsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.Loading();
    this.loadLane();
    
    // Setup activity monitoring
    const activity$ = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'keydown'),
      fromEvent(document, 'click'),
      fromEvent(document, 'scroll')
    );
    
    activity$.subscribe(() => {
      if (this.showInactivityModal && !this.showExpiredModal && this.inactivityTimeout) {
        // Allow the modal to be dismissed by activity
        this.resetInactivityTimer();
      }
    });
  }

  ngOnDestroy(): void {
    this.serviceBus.listener?.close();
    this.serviceBus.client?.close();
    Object.values(this.subscriptions).forEach(sub => sub?.unsubscribe());
    this.subscriptions.initMessageTimeout?.unsubscribe();
    if (this.inactivityTimeout) {
      clearTimeout(this.inactivityTimeout);
    }
    if (this.inactivityModalTimeout) {
      clearTimeout(this.inactivityModalTimeout);
    }
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
    if (this.countdownTimer) {
      clearInterval(this.countdownTimer);
    }
  }

  loadLane() {
    this.laneId = this.route.snapshot.params['id'];
    const sbSubName = this.loginService.UserId() + this.permissionService.GetSessionIdentifier();
    this.apiService.Get('infrastructure/diagnostics/lanes/' + this.laneId + '?sbSubscriptionName=' + sbSubName).then((value: any) => {
      this.Lane = value;
      this.serviceBus.client = new ServiceBusClient(value.ServiceBusConnectionString);
      this.serviceBus.listener = this.serviceBus.client.createReceiver(value.ServiceBusTopicName, sbSubName);
      
      this.subscriptions.initMessageTimeout = timer(30000, 45000).subscribe((count) => {
        if (!this.initMessageReceived) {
          if (count === 0) {
            this.toastService.warning('The controller has not responded yet, there may be an issue with the device or network.', 'Connection Warning');
          } else {
            this.showOfflineModal = true;
          }
        }
      });

      const messageHandler = async (messageReceived: any) => {
        await this.handleMessage(messageReceived);
      };
      const errorHandler = async (error:any) => {
        console.log(error);
      };
      this.serviceBus.listener.subscribe({
        processMessage: messageHandler,
        processError: errorHandler
      });

      this.apiService.Get('infrastructure/diagnostics/lanes/' + this.laneId + '/live');

      // Start keepalive timer only after init
      if (!this.subscriptions.timer) {
        this.subscriptions.timer = interval(60 * 1000).subscribe(() => {
          this.apiService.Put('infrastructure/diagnostics/lanes/' + this.laneId + '/live', null);
        });
      }
    }).catch((reason: any) => {
      this.toastService.error(reason.error.Error.Message, "Error loading lane");
    }).finally(() => {
      this.StopLoading();
    })
  }

  public getCurrentWorkflow() {
    var direction = this.currentLoadedSession?.Direction ?? this.Lane.CurrentDirection;

    if (direction == "Entry") {
      return this.Lane.EntryWorkflow;
    }
    return this.Lane.ExitWorkflow;
  }

  public selectSession(sessionKey: string) {
    if (this.autoFollowSessions && this.selectedSession !== sessionKey) {
        this.setAutoFollow(false);
    }

    this.subscriptions.timeAtGate?.unsubscribe();
    
    this.selectedSession = sessionKey;
    if (this.currentLoadedSession?.IsLive) {
      this.apiService.Delete('infrastructure/diagnostics/lanes/' + this.laneId + '/live/sessions/' + this.currentLoadedSession.SessionKey, null);
    }

    this.currentLoadedSession = null;
    this.isLoadingSessionDetails = true;
    var preload = this.loadedSessions.filter(x => x.SessionKey == sessionKey);
    if (preload.length == 1) {
      this.currentLoadedSession = preload[0];
      this.isLoadingSessionDetails = false;
    } else {
      this.apiService.Get('infrastructure/diagnostics/lanes/' + this.laneId + '/live/sessions/' + sessionKey)
    }
  }

  public setAutoFollow(value: boolean) {
    this.autoFollowSessions = value;
    
    if (value) {
        if (this.sessions.indexOf(this.sessions.find(x => x.SessionKey == this.selectedSession)) != 0) {
          this.selectSession(this.sessions[0].SessionKey);
        }
    }
  }

  private async handleMessage(messageReceived: any): Promise<void> {
    if(messageReceived.body.LaneId !== this.laneId && messageReceived.applicationProperties['MessageType'] != 'SessionsRemoved') {
      console.log(`Message received for ${messageReceived.body.LaneId}, which is not this lane. Ignoring.`);
      return;
    }

    // Reset keep-alive monitoring since we received a message
    this.handleKeepAliveAck(null);

    const handlers: Record<string, (body: any) => void> = {
      'LaneDebugInit': this.handleLaneDebugInit.bind(this),
      'SessionDebugInit': this.handleSessionDebugInit.bind(this),
      'SessionsRemoved': this.handleSessionsRemoved.bind(this),
      'SessionRegistered': this.handleSessionRegistered.bind(this),
      'SessionDebugUpdate': this.handleSessionDebugUpdate.bind(this),
      'DeviceDiagnosticUpdate': this.handleDeviceDebugUpdate.bind(this),
      'StateChange': this.handleStateChange.bind(this),
      'SessionFieldDelta': this.handleSessionFieldDelta.bind(this),
      'LaneDirectionChange': this.handleLaneDirectionChange.bind(this),
      'SessionDebugNotFound': this.handleSessionDebugNotFound.bind(this),
      'KeepAliveAck': this.handleKeepAliveAck.bind(this),
    };

    this.initMessageReceived = true;
    this.subscriptions.initMessageTimeout?.unsubscribe();

    const messageType = messageReceived.applicationProperties['MessageType'];
    const handler = handlers[messageType];
    
    if (handler) {
      try {
        handler(messageReceived.body);
        this.handleKeepAliveAck(null);
      } catch (e) {
        console.log("handler " + handler + " failed " + e);
      }
    }
    else {
      console.log("No handler found for " + messageType);
    }
  }

  private handleKeepAliveAck(msg: any) {
    // Clear existing timeout if any
    if (this.subscriptions.keepAliveTimeout) {
      this.subscriptions.keepAliveTimeout.unsubscribe();
    }

    // Set new timeouts for both 90s and 5m checks
    this.subscriptions.keepAliveTimeout = merge(
      timer(90000).pipe(
        timeout(90000),
        map(() => {
          this.toastService.warning('No response received from controller recently', 'Connection Warning');
        })
      ),
      timer(300000).pipe(
        timeout(300000),
        map(() => {
          this.showOfflineModal = true;
        })
      )
    ).subscribe();
  }

  private handleLaneDebugInit(msg: any) {
    this.showOfflineModal = false;
    this.initMessageReceived = true;
    this.subscriptions.initMessageTimeout?.unsubscribe();
    
    // Start keepalive timer only after init
    if (!this.subscriptions.timer) {
      this.subscriptions.timer = interval(60 * 1000).subscribe(() => {
        this.apiService.Put('infrastructure/diagnostics/lanes/' + this.laneId + '/live', null);
      });
    }
    
    this.sessions = msg.Sessions;
    
    const receivedDevices = msg.Devices || [];
    if (this.Lane.Devices) {
      this.Lane.Devices.forEach((device: any) => {
        const updatedDevice = receivedDevices.find((d: any) => d.DeviceId === device.DeviceId);
        if (updatedDevice) {
          this.handleDeviceDebugUpdate(updatedDevice);
        } else {
          device.Status = 'Unknown';
          device.CurrentActivity = 'Unknown';
        }
      });
    }
    
    this.isLoadingSessions = false;

    if(this.sessions[0]) {
      this.selectSession(this.sessions[0].SessionKey);
    }

    // Start inactivity monitoring only after init
    this.resetInactivityTimer();
  }

  private handleSessionDebugInit(msg: any) {
    if (msg.SessionKey == this.selectedSession) {
      msg.images = [];    
      this.currentLoadedSession = msg;
      this.selectedImageId = 0;
      this.isLoadingSessionDetails = false;

      if (msg.IsLive) {
        this.isLiveMode = true;
        this.setAutoFollow(true);
        this.subscriptions.timeAtGate?.unsubscribe();
        this.subscriptions.timeAtGate = interval(1000).subscribe(() => {
          if (this.currentLoadedSession.IsLive) {
            this.currentLoadedSession.TimeAtGate++;
          } else {
            this.currentLoadedSession.DevicesSnapshotTime = new Date();
            this.subscriptions.timeAtGate?.unsubscribe();
          }
        });
      }
    }
    if (!msg.IsLive) {
      this.loadedSessions.push(msg);
    }

    var s = this.sessions.find(x => x.SessionKey == msg.SessionKey);
    if (s) {
      if(msg.PlateNumber) {
        s.PlateNumber = msg.PlateNumber; 
      } else if (!s.PlateNumber && !msg.IsLive) {
        s.PlateNumber = "None";
      }
      if(msg.ArrivalAtGateTime) {
        s.ArrivalAtGateTime = msg.ArrivalAtGateTime; 
      }
    }
  }

  private handleDeviceDebugUpdate(msg: any) {
    var device = this.Lane.Devices.find((x: any) => x.DeviceId == msg.DeviceId);
    if (device) {
      device.Status = msg.Status;
      device.CurrentActivity = msg.CurrentActivity;
      device.ConnectionStatus = msg.ConnectionStatus;
    }
  }

  private handleSessionsRemoved(msg: any) {
    var removed: string[] = msg;

    var toRemove = removed.filter((r => !this.loadedSessions.some(x => x.SessionKey == r)));
    this.sessions = this.sessions.filter(session => !toRemove.includes(session.SessionKey));
  }

  private handleSessionRegistered(msg: any) {
    this.sessions.unshift(msg);
    if (this.autoFollowSessions) {
        this.currentLoadedSession.DevicesSnapshotTime = new Date();
        this.currentLoadedSession.DevicesSnapshot = this.Lane.Devices.map((device: any) => ({
            DeviceId: device.DeviceId,
            Status: device.Status,
            CurrentActivity: device.CurrentActivity,
            ConnectionStatus: device.ConnectionStatus,
            DeviceType: device.DeviceType,
            Name: device.Name
        }));
        if (this.pendingDownload)
          this.downloadSession();
        this.selectSession(msg.SessionKey);
        this.autoFollowSessions = true;
    }
  }

  private handleSessionDebugUpdate(msg: any) {
    if (this.selectedSession == msg.SessionKey) {
      this.currentLoadedSession.DebugEvents.unshift(msg.Body);
      if(msg.Body.UpdateProperties)
      {
        msg.Body.UpdateProperties.forEach((x: any) => {
          this.currentLoadedSession[x.Key] = x.Value;
          if (x.Key == "SessionKey"){
            this.selectedSession = x.Value;
          }
          var s = this.sessions.find(y => y.SessionKey == msg.SessionKey);
          if(s){
            s[x.Key] = x.Value;
          }
          
          if(x.Key === 'IsLive' && x.Value === false) {
            this.loadedSessions = this.loadedSessions.filter(s => s.SessionKey !== msg.SessionKey);
            this.loadedSessions.push(this.currentLoadedSession);
          }
        })
      }
    }
  }

  private handleStateChange(msg: any) {
    if (this.selectedSession == msg.SessionKey) {
      this.currentLoadedSession.StateEvents.push(msg.Body);
      this.stateEventCounter++;
    }
  }

  private handleSessionFieldDelta(msg: any) {
    if(this.currentLoadedSession?.SessionKey == msg.SessionKey) {
      if (msg.Key === 'Images' && msg.Value) {
        if (!this.currentLoadedSession.images) {
          this.currentLoadedSession.images = [];
        }

        var image = JSON.parse(msg.Value);
        var existing = this.currentLoadedSession.images.find((x: any) => x.ImageId == image.ImageId);
        if (existing) {
          existing.Bytes = image.Bytes;
          existing.MaxSize = image.MaxSize;
        } else {
          this.currentLoadedSession.images.push(image);
          if(this.selectedImageId == this.currentLoadedSession.images[this.currentLoadedSession.images.length - 2].ImageId){
            this.selectedImageId++;
          }
        }
      } else {
        this.currentLoadedSession[msg.Key] = msg.Value;
        
        if (msg.Key === 'IsLive' && msg.Value === false) {
          this.currentLoadedSession.DevicesSnapshotTime = new Date();
          this.currentLoadedSession.DevicesSnapshot = this.Lane.Devices.map((device: any) => ({
              DeviceId: device.DeviceId,
              Status: device.Status,
              CurrentActivity: device.CurrentActivity,
              ConnectionStatus: device.ConnectionStatus,
              DeviceType: device.DeviceType,
              Name: device.Name
          }));

          if (this.pendingDownload)
            this.downloadSession(s);
        }
      }
    }

    var s = this.loadedSessions.find((x: any) => x.SessionKey == msg.SessionKey);
    if (s) {
      s[msg.Key] = msg.Value;
      
      if (msg.Key === 'IsLive' && msg.Value === false) {
        s.DevicesSnapshotTime = new Date();
        s.DevicesSnapshot = this.Lane.Devices.map((device: any) => ({
            DeviceId: device.DeviceId,
            Status: device.Status,
            CurrentActivity: device.CurrentActivity,
            ConnectionStatus: device.ConnectionStatus,
            DeviceType: device.DeviceType,
            Name: device.Name
        }));

        if (this.pendingDownload)
          this.downloadSession(s);
      }
    }

    var s2 = this.sessions.find((x: any) => x.SessionKey == msg.SessionKey);
    if(s2) {
      s2[msg.Key] = msg.Value;
    }
  }

  private handleLaneDirectionChange(msg: any) {
    this.Lane.CurrentDirection = msg.Direction;
  }

  private handleSessionDebugNotFound(msg: any) {
    this.sessions = this.sessions.filter((x: any) => x.SessionKey != msg.SessionKey);
    this.toastService.warning("The session " + msg.SessionKey + " was not found in the controller. You may need to refresh to get the latest sessions.", "Session not found.")
    this.isLoadingSessionDetails = false;
  }

  public SecondsToUsefulTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    
    return [
      hours > 0 ? `${hours}h` : '',
      (minutes > 0 || hours > 0) ? `${minutes}m` : '',
      `${remainingSeconds}s`
    ].filter(Boolean).join(' ');
  }

  public getDevicesToShow(): any[] {
    const shouldUseSnapshot = this.selectedSession && 
        this.currentLoadedSession && 
        !this.currentLoadedSession.IsLive;

    return this.Lane.Devices.map((device: any) => {
        if (shouldUseSnapshot) {
            const snapshotDevice = this.currentLoadedSession.DevicesSnapshot
                ?.find((d: any) => d.DeviceId === device.DeviceId);
            return snapshotDevice ? { ...snapshotDevice, DeviceType: device.DeviceType } : device;
        }
        return device;
    });
  }

  public selectImage(index: number) {
    this.selectedImageId = index;
  }

  public getSelectedImage(): any {
    const image = this.currentLoadedSession?.images?.find((x: any) => x.ImageId == this.selectedImageId);
    return image ? image.Bytes : null;
  }

  public fetchAndShowImageModal() {
    this.showImageModal = true;
    if (!this.currentLoadedSession.images.find((x: any) => x.ImageId == this.selectedImageId).MaxSize) {
      this.apiService.Post('infrastructure/diagnostics/lanes/' + this.laneId + '/sessions/' + this.selectedSession + '/images/' + this.selectedImageId, null);
    }
  }

  public toggleDevice(deviceId: string) {
    if (this.expandedDevices.has(deviceId)) {
        this.expandedDevices.delete(deviceId);
    } else {
        this.expandedDevices.add(deviceId);
    }
  }

  private resetInactivityTimer() {
    if (this.inactivityTimeout) {
      clearTimeout(this.inactivityTimeout);
    }
    if (this.inactivityModalTimeout) {
      clearTimeout(this.inactivityModalTimeout);
    }
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }

    this.countdownSeconds = 60;
    this.showInactivityModal = false;
    
    this.inactivityTimeout = setTimeout(() => {
      this.showInactivityModal = true;
      this.countdownSeconds = 60;
      
      this.countdownInterval = setInterval(() => {
        this.countdownSeconds--;
        if (this.countdownSeconds <= 0) {
          clearInterval(this.countdownInterval);
          this.subscriptions.timer?.unsubscribe();
          this.showInactivityModal = false;
          this.showExpiredModal = true;
          this.ngOnDestroy();
        }
      }, 1000);
      
      this.inactivityModalTimeout = setTimeout(() => {
        if (this.countdownInterval) {
          clearInterval(this.countdownInterval);
        }
        this.subscriptions.timer?.unsubscribe();
        this.showInactivityModal = false;
        this.showExpiredModal = true;
        this.ngOnDestroy();
      }, 60000);
    }, this.INACTIVITY_TIMEOUT_MINUTES * 60 * 1000);
  }

  public downloadSession(session: any = undefined) {
    if (!this.currentLoadedSession && !session) return;

    if (!session)
      session = this.currentLoadedSession;

    if (session.IsLive) {
      this.pendingDownload = true;
      this.toastService.info('Download will start when session completes');
      return;
    }

    try {
      // Create a formatted session export object
      const sessionExport = {
        sessionKey: session.SessionKey,
        plateNumber: session.PlateNumber,
        arrivalTime: session.ArrivalAtGateTime,
        timeAtGate: session.TimeAtGate,
        rateSet: session.RateSetName,
        sessionDuration: session.SessionDuration,
        events: session.DebugEvents,
        stateEvents: session.StateEvents,
        images: session.images?.map((img: any) => ({
          id: img.ImageId,
          type: img.ImageType,
          timestamp: img.Timestamp,
          base64Data: img.Bytes
        })) || [],
        devices: session.DevicesSnapshot || []
      };

      this.pendingDownload = false;
      const blob = new Blob([JSON.stringify(sessionExport, null, 2)], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `session-${session.SessionKey}.json`;
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      this.toastService.error('Failed to download session data');
    }
  }

  public importSession(event: any) {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const importedData = JSON.parse(e.target?.result as string);
        
        // Create a session object matching our expected format
        const session = {
          SessionKey: importedData.sessionKey,
          PlateNumber: importedData.plateNumber,
          ArrivalAtGateTime: importedData.arrivalTime,
          TimeAtGate: importedData.timeAtGate,
          RateSetName: importedData.rateSet,
          SessionDuration: importedData.sessionDuration,
          DebugEvents: importedData.events,
          StateEvents: importedData.stateEvents,
          images: importedData.images?.map((img: any) => ({
            ImageId: img.id,
            ImageType: img.type,
            Timestamp: img.timestamp,
            Bytes: img.base64Data
          })) || [],
          DevicesSnapshot: importedData.devices,
          IsLive: false,
          IsImported: true
        };

        // Add to sessions list if not already present
        if (!this.sessions.some(s => s.SessionKey === session.SessionKey)) {
          const sessionListItem = {
            SessionKey: session.SessionKey,
            PlateNumber: session.PlateNumber,
            ArrivalAtGateTime: session.ArrivalAtGateTime,
            IsImported: true
          };
          
          // Insert into sessions array in chronological order
          const insertIndex = this.sessions.findIndex(s => 
            new Date(s.ArrivalAtGateTime) < new Date(session.ArrivalAtGateTime)
          );
          
          if (insertIndex === -1) {
            this.sessions.push(sessionListItem);
          } else {
            this.sessions.splice(insertIndex, 0, sessionListItem);
          }
        }

        // Add to loaded sessions
        this.loadedSessions = this.loadedSessions.filter(s => s.SessionKey !== session.SessionKey);
        this.loadedSessions.push(session);

        // Select the imported session
        this.selectSession(session.SessionKey);
        
        this.toastService.success('Session imported successfully');
      } catch (error) {
        this.toastService.error('Failed to import session. Invalid file format.');
      }
    };
    reader.readAsText(file);

    event.target.value = '';
  }

  confirmForceHome() {
    this.showForceHomeModal = true;
    this.forceHomeCountdown = 5;
    
    if (this.countdownTimer) {
        clearInterval(this.countdownTimer);
    }
    
    setTimeout(() => {
        this.forceHomeCountdown = 0;
    }, 5000);
  }

  executeForceHome() {
    this.showForceHomeModal = false;
    this.forceHomeCountdown = 0;
    if (this.countdownTimer) {
        clearInterval(this.countdownTimer);
    }
    this.Loading();
    this.apiService.Post('infrastructure/diagnostics/lanes/' + this.laneId + "/workflow/home", null).then((value: any) => {
      this.StopLoading();
    }).catch((reason: any) => {
      this.toastService.error("Failed to force workflow home", "Command Failed");
      this.StopLoading();
    });
  }

  isEntryBeforeArrival(entryDate: string, arrivalTime: string): boolean {
    return new Date(entryDate) < new Date(arrivalTime);
  }

  getDurationFromEntry(entryDate: string, arrivalTime: string): string {
    const entry = new Date(entryDate);
    const arrival = new Date(arrivalTime);
    const durationMs = arrival.getTime() - entry.getTime();
    
    // Convert to hours, minutes, seconds
    const hours = Math.floor(durationMs / (1000 * 60 * 60));
    const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((durationMs % (1000 * 60)) / 1000);

    if (hours > 0) {
      return `${hours}h ${minutes}m ${seconds}s`;
    } else if (minutes > 0) {
      return `${minutes}m ${seconds}s`;
    }
    return `${seconds}s`;
  }
}

