import { DocumentEditorComponent } from "./documenteditor.component";
import { DocumentComponentBase } from "./Components/DocumentComponentBase";
import { fabric } from 'fabric';
import { TextComponent } from "./Components/TextComponent";
import { RectangleComponent } from "./Components/RectangleComponent";
import { ImageComponent } from "./Components/ImageComponent";
import { Helpers } from "./Helpers/Helpers";

export interface Style {
    fontSize: string | null;
    fontFamily: string | null;
    color: string | null;
    left: number | null;
    top: number  | null;
    width: number | null;
    height: number  | null;
    fontWeight: string | null;
    fontStyle: string | null;
    textAlign: string | null
    borderStyle: string;
    borderRadius: number | null;
    boxShadow: string | null;
    backgroundColor: string | null;
    IsLink: boolean;
    textDecoration: string | null;
    transform: string | null;
}

export class HtmlRenderComponents extends DocumentComponentBase {
    public Parent : HtmlRenderComponents | null;
    public Element : HTMLElement;
    public Styles !: Style;
    public Text !: string;
    public Link !: string | null;

    constructor(documentEditorComponent: DocumentEditorComponent, htmlDivElement: HTMLElement, parentElement: HtmlRenderComponents | null = null) {
        super(documentEditorComponent);

        this.Parent = parentElement;
        this.Element = htmlDivElement;
        this.processElement();
    }

    public processElement(): void {
        Array.from(this.Element.children).forEach(child => {
          
        const childElement = child as HTMLElement;
        const parentFontSize = this.Parent?.Styles.fontSize || '16px';
        const parentFontFamily = this.Parent?.Styles.fontFamily || 'Arial';
        const parentColor = this.Parent?.Styles.color || 'black';
        const parentLeft = this.Parent?.Styles.left || 0;
        const parentTop = this.Parent?.Styles.top || 0;
        const parentWidth = this.Parent?.Styles.width || 0;
        const parentHeight = this.Parent?.Styles.height || 0;
        const parentFontWeight = this.Parent?.Styles.fontWeight || 'normal';
        const parentTextAlign = this.Parent?.Styles.textAlign || 'left';
        
        const fontSize = childElement.style.fontSize || parentFontSize;
        const fontFamily = childElement.style.fontFamily || parentFontFamily;

        const color = this.rgbToHex(childElement.style.color) || parentColor;

        const fontweight = childElement.style.fontWeight || parentFontWeight;
        const textAlign = childElement.style.textAlign || parentTextAlign;
        const backgroundColor = this.rgbToHex(childElement.style.backgroundColor) || "white";

        const textDecoration = childElement.style.textDecoration || "none";
        const fontStyle = childElement.style.fontStyle || "normal";
        const transform = childElement.style.transform;

        const borderStyle = childElement.style.border;
        const borderRadius = parseFloat(childElement.style.borderRadius.replace('px', '')) ?? null;
        const boxShadow = childElement.style.boxShadow;

        var left = 0;
        var top = 0;
        var width = 0;
        var height = 0;

        if(childElement.style.left.endsWith("in")){
        left = Helpers.convertInchesToPixels(childElement.style.left)
        }
        else{
        left = parseFloat(childElement.style.left) || parentLeft;
        }
        if(childElement.style.top.endsWith("in")){
        top = Helpers.convertInchesToPixels(childElement.style.top)
        }
        else{
        top = parseFloat(childElement.style.top) || parentTop;
        }
        if(childElement.style.height.endsWith("in")){
        height = Helpers.convertInchesToPixels(childElement.style.height)
        }
        else{
        height = parseFloat(childElement.style.height) || parentHeight;
        }
        if(childElement.style.width.endsWith("in")){
        width = Helpers.convertInchesToPixels(childElement.style.width)
        }
        else{
        width = parseFloat(childElement.style.width) || parentWidth;
        }

        this.Styles = {
            fontSize: fontSize,
            fontFamily: fontFamily,
            color: color,
            left: left,
            top: top,
            width : width,
            height: height,
            fontWeight: fontweight,
            textAlign: textAlign,
            boxShadow: boxShadow,
            borderRadius: borderRadius,
            borderStyle: borderStyle,
            IsLink: false,
            backgroundColor: backgroundColor,
            textDecoration: textDecoration,
            fontStyle: fontStyle,
            transform: transform
        };
    
            if(borderRadius && borderStyle){ 
              this.documentEditorComponent.DocumentComponents.push(new RectangleComponent(this.documentEditorComponent, this.Styles))
            }

            if (childElement.tagName.toLowerCase() === 'img') {
              const imgElement = childElement as HTMLImageElement;
              const src = imgElement.src;

              let href = null;

              const parentElement = imgElement.parentElement;
              if (parentElement && parentElement.tagName.toLowerCase() === 'a') {
                const anchorElement = parentElement as HTMLAnchorElement;
                href = anchorElement.href;
              }

              if (src.startsWith('data:image/')) { // Check if the src is a base64 string
                  this.documentEditorComponent.DocumentComponents.push(new ImageComponent(this.documentEditorComponent, imgElement.id, src, this.Styles, href));
              } else {
                  console.warn('Image src is not a base64 string:', src);
              }
            }
            else if (childElement.children.length > 0) {
              const childText = childElement.textContent?.trim();
              if (childText && !this.containsTextInChildren(childElement)) {
                  this.documentEditorComponent.toastService.error('Text could not be loaded because the div has child elements but contains text');
              }
              new HtmlRenderComponents(this.documentEditorComponent, childElement, this);
            }
            else{
                if(childElement.textContent?.toString()){
                    this.Text = childElement.textContent?.trim() || '';
                    this.Link = this.containsHrefLink(childElement)
                    if(this.Link){
                        this.Styles.IsLink = true;
                    }
                    
                    const dataType = childElement.getAttribute('data-type') || null;
                    const boundValue = childElement.getAttribute('bound-value') || null;
                    const dataExpression = childElement.getAttribute('data-expression') || null;

                    this.documentEditorComponent.DocumentComponents.push(new TextComponent(this.documentEditorComponent, this.Styles, this.Text, this.Link, dataType, boundValue, dataExpression))
                }
            }
        });
      }

      containsTextInChildren(element: HTMLElement): boolean {
        // Check if any child elements contain text content recursively
        for (let i = 0; i < element.children.length; i++) {
            const child = element.children[i];
            if (child.textContent?.trim()) {
                return true;
            }
            if (child.children.length > 0 && this.containsTextInChildren(child as HTMLElement)) {
                return true;
            }
        }
        return false;
    }

    rgbToHex(rgb: string): string | null {
        // Extract the numerical values from the rgb string
        const result = rgb.match(/\d+/g);
        if (result && result.length >= 3) {
            // Convert to hex and pad with zero if necessary
            const [r, g, b] = result.map(x => parseInt(x).toString(16).padStart(2, '0'));
            return `#${r}${g}${b}`.toUpperCase();
        }
        // Fallback in case the input is not valid
        return null;
    }


       containsHrefLink(element : HTMLElement) {
        // Check if the element itself is an anchor tag with an href
        if (element.tagName.toLowerCase() === 'a' && element.hasAttribute('href')) {
          return element.getAttribute('href');
        }
        
        // Check if any child element has an href attribute
        var anchors = element.querySelectorAll('a[href]');
        if (anchors.length > 0) {
          return anchors[0].getAttribute('href'); // Return the href of the first found link
        }
        
        return null; // No href found
      }
}
