import { Component, Injector, OnInit } from '@angular/core';
import { ModalService } from 'src/app/Services/modal.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute } from '@angular/router';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { LoginService } from 'src/app/auth/login.service';
import { ToastrService } from 'ngx-toastr';
import { Validators } from '@angular/forms';
import { EditalerttriggerrecipientroleComponent } from '../modals/editalerttriggerrecipientrole/editalerttriggerrecipientrole.component';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { EditalerttriggerrecipientuserComponent } from '../modals/editalerttriggerrecipientuser/editalerttriggerrecipientuser.component';


@Component({
  selector: 'app-editalerttrigger',
  templateUrl: './editalerttrigger.component.html',
  styleUrls: ['./editalerttrigger.component.scss']
})
export class EditalerttriggerComponent extends ModelEditor implements OnInit {

  constructor(private modalService: SimpleModalService, private injector: Injector) {
    super("alerts/triggers", injector);
  }

  public override DefaultModel() {
    return { EventName: "", AutoResolveEvent: "", UserRecipients: [], RoleRecipients: [] }
  }

  public override AfterModelLoaded(): void {

  }
  public override BeforeSave(): void {

  }

  public override Validators(): any {
    return {
      'EventName': [Validators.required]
    };
  }

  public RemoveAlertTriggerRecipientUser(index: number) {
    this.FormArray(this.Form, "UserRecipients").removeAt(index);
  }

  public RemoveAlertTriggerRecipientRole(index: number) {
    this.FormArray(this.Form, "RoleRecipients").removeAt(index);
  }

  public AddRecipientUser() {
    this.modalService.addModal(EditalerttriggerrecipientuserComponent, {})
      .subscribe((result) => {
        if (result != null) {
          this.AddToFormArray(this.FormArray(this.Form, "UserRecipients"), result, "UserRecipients")
        }
      });
  }

  public AddRecipientRole() {
    this.modalService.addModal(EditalerttriggerrecipientroleComponent, {})
      .subscribe((result) => {
        if (result != null) {
          this.AddToFormArray(this.FormArray(this.Form, "RoleRecipients"), result, "RoleRecipients")
        }
      });
  }

  public EditRecipientRole(index: number) {

    let method = this.FormArray(this.Form, "RoleRecipients").controls[index];
    this.modalService.addModal(EditalerttriggerrecipientroleComponent, { Model: method.value })
      .subscribe((result) => {
        if (result != null) {
          method.setValue(result);
        }
      });
  }

  public EditRecipientUser(index: number) {

    let method = this.FormArray(this.Form, "UserRecipients").controls[index];
    this.modalService.addModal(EditalerttriggerrecipientuserComponent, { Model: method.value })
      .subscribe((result) => {
        if (result != null) {
          method.setValue(result);
        }
      });
  }
}
