import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError, EMPTY, empty, lastValueFrom, ObjectUnsubscribedError, Observable, ObservableInput, of, retry, share, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginServiceBase } from '../auth/login.service.base';
import { ApiServiceBase } from './api.service.base';

@Injectable({
  providedIn: 'root'
})
export class ApiService implements ApiServiceBase {

  private Cache = new Map<string, any>();

  constructor(public loginService: LoginServiceBase, private httpClient: HttpClient, private router: Router, private toastr: ToastrService) {
    loginService.OrganizationChanged.subscribe(() => {
      //clear any cached data when the org changes
      this.Cache = new Map<string, any>();
    });

  }

  private GenerateHeaders(): any {
    return new HttpHeaders().set("authorization", "Bearer " + this.loginService.CurrentToken())
      .set('Cache-Control', 'no-cache, no-store, must-revalidate')
      .set('Pragma', 'no-cache')
      .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
      .set('If-Modified-Since', '0');
  }

  private ShouldCache(url: string): boolean {
    if (url.indexOf('-') > 0) {
      //has a dash in it from a guid
      return false;
    }
    if (url.match(/\/[0-9]/)) {
      //has /number in it which will be an id in a url
      return false;
    }
    return true;
  }
  private ClearCacheFor(url: string) {
    if (this.Cache.has(url)) this.Cache.delete(url);
    let index = url.lastIndexOf('/');
    if (index > 0) {
      url = url.substring(0, index - 1);
      if (this.Cache.has(url)) this.Cache.delete(url);
    }
  }
  private CleanUrl(url: string): string {
    if (url.startsWith('/')) url = url.substring(1);
    if (url.endsWith('/')) url = url.substring(0, url.length - 1);
    return url;
  }

  public Get<T>(url: string): Promise<T> {
    let promise: Promise<T> = new Promise((resolve, reject) => {
      url = this.CleanUrl(url);
      console.log("ApiService get to " + url);
      let headers = new HttpHeaders().set("authorization", "Bearer " + this.loginService.CurrentToken());

      let obs = this.httpClient.get<T>(environment.serverBaseUrl + "/" + url, { headers });

      obs.subscribe({
        next: (result) => {
          resolve(result as T)
        },
        error: (err) => { 
          this.HandleHttpError(err, resolve, reject, () =>{
              this.Get<T>(url).then((resolve2) => {
                resolve(resolve2)
              },(reject2) => {
                reject(reject2)
              });
          });
        }
      });
    });
    return promise;
  }

  public Post<T>(url: string, body: any): Promise<T> {
    let promise: Promise<T> = new Promise((resolve, reject) => {
      url = this.CleanUrl(url);
      this.ClearCacheFor(url);
      console.log("ApiService postasync to " + url + ", body " + body);
      let headers = new HttpHeaders().set("authorization", "Bearer " + this.loginService.CurrentToken());
      
      let obs = this.httpClient.post<T>(environment.serverBaseUrl + "/" + url, body, { headers });
      
      // obs.pipe(
      //   catchError(err => {
      //     this.HandleHttpError<T>(err, resolve, reject, this.httpClient.delete, url, body);
      //     return EMPTY;
      //   })
      // )
      obs.subscribe({
        next: (result) => {
          resolve(result as T)
        },
        error: (err) => { 
          this.HandleHttpError(err, resolve, reject, () =>{
              this.Post<T>(url, body).then((resolve2) => {
                resolve(resolve2)
              },(reject2) => {
                reject(reject2)
              });
          });
        }
      });
    });
    return promise;
  }

  public Put<T>(url: string, body: any): Promise<T> {
    let promise: Promise<T> = new Promise((resolve, reject) => {
      url = this.CleanUrl(url);
      this.ClearCacheFor(url);
      console.log("ApiService put to " + url + ", body " + body);
      let headers = new HttpHeaders().set("authorization", "Bearer " + this.loginService.CurrentToken());

      let obs = this.httpClient.put<T>(environment.serverBaseUrl + "/" + url, body, { headers });

      obs.subscribe({
        next: (result) => {
          resolve(result as T)
        },
        error: (err) => { 
          this.HandleHttpError(err, resolve, reject, () =>{
              this.Put<T>(url, body).then((resolve2) => {
                resolve(resolve2)
              },(reject2) => {
                reject(reject2)
              });
          });
        }
      });

    });
    return promise;
  }

  public Delete<T>(url: string, body: any): Promise<T> {
    let promise: Promise<T> = new Promise((resolve, reject) => {
      url = this.CleanUrl(url);
      this.ClearCacheFor(url);
      console.log("ApiService del " + url + ", body " + body);
      let headers = new HttpHeaders().set("authorization", "Bearer " + this.loginService.CurrentToken());

      let obs = this.httpClient.delete(environment.serverBaseUrl + "/" + url, { headers, body });
     
      obs.subscribe({
        next: (result) => {
          resolve(result as T)
        },
        error: (err) => { 
          this.HandleHttpError(err, resolve, reject, () =>{
              this.Delete<T>(url, body).then((resolve2) => {
                resolve(resolve2)
              },(reject2) => {
                reject(reject2)
              });
          });
        }
      });
    });
    return promise;
  }


  private HandleHttpError<T>(error: any, resolve: any, reject:any, retryCallback : Function) : void {
    if (error != null) {
      if (this.router.url.indexOf("/login") >= 0) {
        //we are on login page so don't need to do anything
        reject(error);
        return;
      }

      switch (error.status) {
        case 400:
          if (error.error?.Error) {
            this.toastr.error(error.error.Error.Message, error.error.Error.Code);
          } else {
            this.toastr.error("You do not have permission to access this resource", "Error");
          }
          reject(error);
          break;
        case 401:
          this.loginService.NotifyAuthExpired();
          this.loginService.LoginStatusChanged.subscribe((loggedIn) =>{
            if(loggedIn){
              retryCallback();
            }
          });
          break;
        case 403:
          if (error.error?.Error) {
            this.toastr.error(error.error.Error.Message, error.error.Error.Code);
          } else {
            this.toastr.error("You do not have permission to access this resource", "Error");
          }
          reject(error);
          break;
        case 404:
          if(error.error?.Error){
            this.toastr.error(error.error.Error.Message, error.error.Error.Code);
          }
          else{
            this.toastr.error("The requested resource could not be found", "Error");
          }
          reject(error);
          break;
        default:
          reject(error);
          break;
      }
    }
  }
}
