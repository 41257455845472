<div *ngIf="Visible" @addRemove [@.disabled]="!UseAnimation" class="field is-horizontal" ngClass="{{InputPadding == true ? 'mb-2' : ''}}">
    <div class="field-label is-normal">
        <label class="label">{{Label}}</label>
    </div>
    <div class="field-body">
        <div class="field {{class}}" [ngClass]="{ 'has-addons' : HasAddons == true }">
            <ng-content></ng-content>
        </div>
    </div>  
</div>
