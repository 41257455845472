<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{EventName}}</p>
      </header>
      <section class="modal-card-body" style="min-width: 800px;">
        <app-field *ngFor="let input of Inputs" [Label]="input.Label">
          <div class="control">
            <input *ngIf="input.InputType == 'Number' || input.InputType == 'Time'" class="input" type="number" (change)="AddOrChangeValue(input.Key, $event)">
            <input *ngIf="input.InputType == 'Text'" class="input" type="text" (change)="AddOrChangeValue(input.Key, $event)">
            <app-toggleswitch *ngIf="input.InputType == 'Boolean'" (change)="AddOrChangeValue(input.Key, $event)"></app-toggleswitch>
            <div class="select" *ngIf="input.InputType == 'Select'">
              <select (change)="AddOrChangeValue(input.Key, $event)">
                <option value="" disabled hidden selected> None Selected </option>
                <option *ngFor="let inputOption of input.Options" [value]="inputOption.Value">{{inputOption.Name}}</option>
              </select>
            </div>
          </div>
        </app-field>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary" (click)="confirm()">Confirm</button>
        <button class="button is-danger" (click)="close()">Cancel</button>
      </footer>
    </div>
  </div>