import { Component, Input, OnInit } from '@angular/core';
import { Datalistrequestfilter } from '../../request/datalistrequestfilter';
import { IFilterFilters } from '../ifilter';

@Component({
  selector: 'app-property-filter',
  templateUrl: './property-filter.component.html',
  styleUrls: ['./property-filter.component.scss'],
})
export class PropertyFilterComponent implements OnInit, IFilterFilters {

  @Input()
  public Property: string = "";
  @Input()
  public Comparitor: string = "";
  @Input()
  public Value: string = "";

  public FilterName: string = "prop";
  public Filters: Datalistrequestfilter[] = [];
  QueryParamPrefix: string= '';

  constructor() { 
  }

  ngOnInit(): void {
    if (this.Property != "") {
      this.Filters.push({
        Property: this.Property,
        Comparator: this.Comparitor,
        Value: this.Value,
        FilterName: this.FilterName
      })
    }


    // this.Filters = [new Datalistrequestfilter(this.Property, this.Comparitor, this.Value)]
  }
}
