import { MeshConstants } from "src/app/util/mesh-constants";
import * as THREE from "three";
import { Scene, Vector2, Vector3 } from "three";
import { Geo } from "src/app/util/geo";

export class GardenBedMesh  extends THREE.Mesh {
    constructor(scene : Scene, island: any, offset: Vector3, scale: Vector2){
        super();
        let p = island.PolygonPoints.map((point:any) => ({ x: (point[0] * scale.x + offset.x), y: -1 * point[1] * scale.y + offset.y }));
        var newpoints = Geo.RoundEdges(p, 1);
 
        //Outer Shape
        let shape = new THREE.Shape();
            shape.moveTo(newpoints[0].x, newpoints[0].y);
        for (let i = 0; i < newpoints.length; i++) {
            shape.lineTo(newpoints[i].x, newpoints[i].y);
        }

        let extrudeSettings = {
            steps: 2,
            depth: MeshConstants.curbThickness,
            bevelEnabled: true,
            bevelThickness: 0.0001,
            bevelSize: 0.0001,
            bevelOffset: 0,
            bevelSegments: 1
        };

        let geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

        let texture = new THREE.TextureLoader().load('/assets/textures/concrete.jpg');
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.offset.set( 0, 0.5 );
        texture.repeat.set( 100,100 );
        texture.needsUpdate = true;

        let material = new THREE.MeshLambertMaterial({ color: 0x555555, map : texture });
        this.geometry = geometry;
        this.material = material;
        this.type = "grassisland";
        scene.add(this);

        var scaledDownPoly = Geo.DescalePolygon(newpoints);

        let grassshape = new THREE.Shape();
        grassshape.moveTo(scaledDownPoly[0].x, scaledDownPoly[0].y);
        for (let i = 0; i < scaledDownPoly.length; i++) {
            grassshape.lineTo(scaledDownPoly[i].x, scaledDownPoly[i].y);
        }

        let grassgeometry = new THREE.ExtrudeGeometry(grassshape, extrudeSettings);
        texture = new THREE.TextureLoader().load('/assets/textures/dirt.jpg');
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.offset.set( 0, 0.5 );
        texture.repeat.set( 100,100 );
        texture.needsUpdate = true;

        material = new THREE.MeshLambertMaterial({ color: 0x555555, map : texture });
        var grassmesh = new THREE.Mesh(grassgeometry, material);
        grassmesh.position.z = grassmesh.position.z + 0.0001; 
        scene.add(grassmesh);
      }
}
