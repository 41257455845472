<app-pageheader Title="Parking Lots">
  <add-button AddRoute="/parking/parking/lots/new/edit"></add-button>
</app-pageheader>

<app-datalistview [Columns]="Columns" [ShowMap]="true" ApiUrl="infrastructure/parkinglots/searches" 
  [AllowEdit]="true" EditUrl="/parking/parking/lots/:id" [StructureViewerLayers]="StructureViewerLayers" StructureViewerForType="ParkingLot">
  <app-filter-set>
    <div app-search-filter></div>
    <div app-search-button></div>
  </app-filter-set>
</app-datalistview>