import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorService {

  constructor() { }

  private HexToRGB(color : string) : number[]{
    if(color == null || color == ''){
      return [0,0,0];
    }
    if(color.indexOf('#') == 0){
      color = color.substring(1);
    }
    let r = parseInt(color.substring(0,2), 16);
    let g = parseInt(color.substring(2,4), 16);
    let b = parseInt(color.substring(4,6), 16);
    return [r,g,b];
  }

  public GetVisibleTextClassFor(color : string){
    let rgb = this.HexToRGB(color);
    let brightness = Math.round(((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000); 
    if(brightness > 125){
      return 'has-text-dark';
    }
    return 'has-text-light';
  }

}
