import { ChangeDetectorRef, Component, EventEmitter, Host, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { FormArray, FormGroup, FormControl, NgControl, ControlValueAccessor, FormGroupDirective, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-multiuserselector',
  templateUrl: './multiuserselector.component.html',
  styleUrls: ['./multiuserselector.component.scss']
})
export class MultiUserSelectorComponent implements OnInit, ControlValueAccessor {

    @Input()
    public AllowCreateUser: boolean = false;
    
    @Input()
    public MaxUsers: number = 0;
  
    @Input() public formArrayName !: string; // Name of the FormArray in the parent FormGroup

    public formArray: FormArray | undefined;

    // Function to call when the value changes.
    private onChange(value: any) {
      
    };
  
    // Function to call when the control is touched (optional).
    private onTouched() {
      
    };
  
    // Write value from external form control to the component.
    writeValue(value: any): void {
      if (this.formArray) {
        this.formArray.patchValue(value);
      }
    }
  
    // Save the function to call when the value changes.
    registerOnChange(fn: (value: any) => void): void {
      this.onChange = fn;
    }
  
    // Save the function to call when the control is touched (optional).
    registerOnTouched(fn: () => void): void {
      this.onTouched = fn;
    }
  
    // Set the disabled state of the component (optional).
    setDisabledState?(isDisabled: boolean): void {
      if (this.formArray) {
        isDisabled ? this.formArray.disable() : this.formArray.enable();
      }
    }

    constructor(
      private formBuilder: FormBuilder,
      private changeDetector: ChangeDetectorRef,
      @Optional() @Host() private formGroupDirective: FormGroupDirective,
    ) {}
  
    ngOnInit() {
      if (this.formGroupDirective && this.formArrayName) {
        this.formArray = this.formGroupDirective.form.get(this.formArrayName) as FormArray;
      }
    }

    public removeUser(index: number){
      this.formArray?.removeAt(index);
      this.formArray?.markAsDirty();
    }

    addUser() {
      if (this.formArray) {
        const newUser = this.formBuilder.group({
          FirstName: [''], // You can set default values if needed
          LastName: [''],
          Email: [''],
          UserId : [null]
        });
  
        this.formArray.push(newUser);
        this.changeDetector.detectChanges();
      }
    }

    public UserChanged(event : any, userControl : any){
      userControl.get('Email').setValue(event.Email);
      userControl.get('FirstName').setValue(event.FirstName);
      userControl.get('LastName').setValue(event.LastName);
      userControl.get('UserId').setValue(event.Id);
      this.formArray?.markAsDirty();
    }
  }