import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Busyable } from 'src/app/shared/editors/busyable';
/**
 * @deprecated: Using Form-saev-button instead, and pass the Form parameter as the form acutally used. 
 */
@Component({
  selector: 'actionbutton',
  templateUrl: './actionbutton.component.html',
  styleUrls: ['./actionbutton.component.scss']
})
export class ActionbuttonComponent implements OnInit {

  @Input()
  public Parent: Busyable | null = null;
  @Input()
  public ButtonType: string = "button";
  @Input()
  public Icon: string = "check";
  @Input()
  public Enabled: any = true;
  @Output()
  public PerformAction = new EventEmitter;
  @Input()
  public Color: string | null = null;

  public Animate: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.Parent?.BusyStarted.subscribe(e => {
      this.Animate = true;
    })
    this.Parent?.BusyEnded.subscribe(e => {
      this.Animate = false;
    })
  }

  public DoAction(){
    this.PerformAction.emit();
  }
}
