import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import { LevelEditorComponent } from '../leveleditor.component';
import { PointMapComponent } from './PointComponents/PointMapComponent';
import { PolygonMapComponent } from './PolygonComponents/PolygonMapComponent';
import { FormArray } from '@angular/forms';
import { LevelEditorHelper } from '../helper';
import { IObjectOptions } from 'fabric/fabric-impl';
import { UndoAction } from '../UndoActions/undoaction';
import { last } from 'rxjs';

export abstract class MapComponentBase {
    canvas: any;
    formItem: any;
    parentFormItem: any;
    public leveleditorcomponent !: LevelEditorComponent;
    
    public ClassName !: string;
    public ScaleFactor = 10000;
    public Zoom = 1;
    public Opacity = 1;
    public Id !: string;
    public FabricItemId !: string;
    public FabricItem !: fabric.Object;
    public DisplayAngle : number | undefined = 0;
    public RequiresParent: boolean = false;
    public ParentClassName: string = "";
    public ParentLinkId: string | null = null;
    public zIndex: number = 0;
    public ForceClone: boolean = false;
    public Locked: boolean = false;
    public ChildrenArrays: string[] | null = null;
    public CanDuplicate: boolean = true;
    public UndoActions: UndoAction[] = [];

    public IsClone: boolean = false;
    public IsToolItem: boolean = false;
    public InitialProcessPoints: boolean = false;

    constructor(leveleditorcomponent: LevelEditorComponent, canvas: any, formItem: any, parentFormItem: any, forceClone: boolean = false) {
        this.leveleditorcomponent = leveleditorcomponent;
        this.canvas = canvas;
        this.parentFormItem = parentFormItem;
        this.Zoom = this.canvas.getZoom();
        this.formItem = formItem;
        this.Id = formItem.get("Id")?.value;
        this.Locked = formItem.get("EditorLocked")?.value;
        this.FabricItemId = uuidv4();
        this.ForceClone = forceClone;
    }

    public UndoLastAction(){
        var lastAction = this.UndoActions.pop();
        if(lastAction){
            lastAction.FormItem = this.formItem;
        };
        lastAction?.Undo();
    }

    public SetFormValues(){
    }

    public AddFabricItem(){
    }

    public Rotate(value: number){
    }

    public ItemModified(){
    }

    public ShowModal(){
        this.leveleditorcomponent.toastService.info("There is no edit modal available for this " + this.ClassName + ".");
    }

    public SelectChildren() : MapComponentBase[] | null {
        return null;
    }

    public DeleteChildren(){
        if(this.ChildrenArrays){
            this.ChildrenArrays.forEach((childArray: any) => {
                (this.formItem.get(childArray).value as any[]).forEach((child: any) => {
                    var mapItem = this.leveleditorcomponent.MapItems.find(x => x.Id === child.Id);
                    mapItem?.DeleteChildren();

                    if(mapItem instanceof MapComponentBase){
                        this.canvas.remove(mapItem.FabricItem);
                    }
                    
                    const items = this.formItem.get(childArray) as FormArray;
                    const index = LevelEditorHelper.findFormItemIndex(items, child);
                    if (index !== -1) {
                            items.removeAt(index);
                            console.log(`Item ${index} found and removed from FormArray`);
                            this.formItem.markAsDirty();
                    } else {
                            console.log("Item not found in FormArray");
                    }
                });
            });
        }
    }

    public SetSelectedObject(){
        return;
    }
}