import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { Busyable } from 'src/app/shared/editors/busyable';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent extends Busyable implements OnInit {
  
  public ModelId: any;
  public Filters: any;
  public Model: any;
  
  constructor(private apiService: ApiServiceBase, private parentRoute: ActivatedRoute, public orgService: OrganizationsService) {
    super();
  }

  ngOnInit(): void {
    this.Loading();
    this.parentRoute.params.subscribe(params => { this.ModelId = params['id']; });
    this.apiService.Get<any>("accounting/transactions/" + this.ModelId).then(result => {
      this.Model = result;
      this.StopLoading();
    });
  }
}
