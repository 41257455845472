import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-editcontrolledareacellrouter',
  templateUrl: './editcontrolledareacellrouter.component.html',
  styleUrls: ['./editcontrolledareacellrouter.component.scss']
})
export class EditcontrolledareacellrouterComponent extends SimpleModalComponent<any, null>{
  Form!: UntypedFormGroup;
  constructor() { super();}

  confirm(){
    this.close();
  }

}
