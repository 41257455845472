export class Color {

    public static HexToRGB(color : string) : number[]{
        if(color == null || color == ''){
          return [0,0,0];
        }
        if(color.indexOf('#') == 0){
          color = color.substring(1);
        }
        let r = parseInt(color.substring(0,2), 16);
        let g = parseInt(color.substring(2,4), 16);
        let b = parseInt(color.substring(4,6), 16);
        return [r,g,b];
      }
    
      public static GetVisibleTextClassFor(color : string){
        if(color == null || color == '') return 'has-text-dark';
        if(color.startsWith("#")) color = color.substring(1);
        if(color.length != 6) return 'has-text-dark';
        let rgb = Color.HexToRGB(color);
        let brightness = Math.round(((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000); 
        if(brightness > 125){
          return 'has-text-dark';
        }
        return 'has-text-light';
      }

      ///Calculates heat map color. Value is normalized to be out of 255
    public static CalculateHeatmapColor(value: number): number {
      // Calculate the normalized value between 0 and 1
      const normalizedValue = value / 255;
  
      // Define the color stops
      const colorStops = [
          { value: 0.05, color: [224, 41, 20] },      // Red
          { value: 0.3, color: [208, 212, 21] },  // Yellow
          { value: 0.6, color: [50, 138, 21] },    // Green
          { value: 0.8, color: [21, 56, 138] },    // Blue
          { value: 1, color: [6, 24, 64] },      // Dark blue
      ];
  
      // Find the color stop before and after the normalized value
      let i;
      for (i = 0; i < colorStops.length - 1; i++) {
          if (normalizedValue <= colorStops[i + 1].value) {
          break;
          }
      }
  
      // Calculate the interpolation factor between the two color stops
      const factor = (normalizedValue - colorStops[i].value) / (colorStops[i + 1].value - colorStops[i].value);
  
      // Interpolate the color between the two color stops
      const color = [
          Math.round(colorStops[i].color[0] + factor * (colorStops[i + 1].color[0] - colorStops[i].color[0])),
          Math.round(colorStops[i].color[1] + factor * (colorStops[i + 1].color[1] - colorStops[i].color[1])),
          Math.round(colorStops[i].color[2] + factor * (colorStops[i + 1].color[2] - colorStops[i].color[2])),
      ];
  
      // Convert the color to a number in the format 0xRRGGBB
      const hexColor = (color[0] << 16) + (color[1] << 8) + color[2];
  
      // Return the color as a number
      return hexColor;
      }

}
