import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { LevelEditorComponent } from "../../leveleditor.component";
import { PointTool } from "./PointTool";
import { GuidanceLight } from "../../components/PointComponents/GuidanceLight";
import { FabricHelpers } from "../../fabric.js/helpers";
import { Geo } from "src/app/util/geo";
import { PolygonMapComponent } from "../../components/PolygonComponents/PolygonMapComponent";


export class GuidanceLightTool extends PointTool{
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }

    public override CreateNew(canvasX : number, canvasY: number){
        const Rows = this.editor.MapItemsByType.get('Row');
        if(Rows){
            for (let row of Rows) {
                var points = FabricHelpers.GetAdjustedXY(this.canvas, canvasX, canvasY);
                if (Geo.GetPolygonPointBounds((row as PolygonMapComponent).formItem.get('PolygonPoints')?.value).Contains([points[0] / this.ScaleFactor, points[1] / this.ScaleFactor])) {     
                    let GuidanceLightConfigurations = this.editor.ModelEditor.FormArray(row.formItem, "GuidanceLightConfigurations");
                    let newglFormGroup = new FormGroup({
                        Id: new FormControl(null),
                        Name: new FormControl("Guidance Light"),
                        PortAssignments: new FormControl([]),
                        ChipId: new FormControl(null),
                        DisplayAngle: new FormControl(0),
                        EditorLocked: new FormControl(false),
                        LocationPoints: new FormControl(this.CreatePointFromXY(canvasX, canvasY))
                    });

                    (GuidanceLightConfigurations as FormArray).push(newglFormGroup);

                    var component = new GuidanceLight(this.editor, this.canvas, newglFormGroup, null);
                    this.editor.Form.markAsDirty();
                    return component;
                }
            }
        }
        this.editor.toastService.error("Please place your Camera within a Lane");
        return null;
    }
}
