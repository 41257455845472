import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { LevelEditorComponent } from "../../leveleditor.component";
import { v4 as uuidv4 } from 'uuid';
import { PointTool } from "./PointTool";
import { Geo } from "src/app/util/geo";
import { TrafficLight } from "../../components/PointComponents/TrafficLight";
import { PolygonMapComponent } from "../../components/PolygonComponents/PolygonMapComponent";
import { FabricHelpers } from "../../fabric.js/helpers";


export class TrafficLightTool extends PointTool{
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }

    public override CreateNew(canvasX : number, canvasY: number){
        const Lanes = this.editor.MapItemsByType.get('Lane');
        if(Lanes){
            for (let lane of Lanes) {
                var points = FabricHelpers.GetAdjustedXY(this.canvas, canvasX, canvasY);
                if (Geo.GetPolygonPointBounds((lane as PolygonMapComponent).formItem.get('PolygonPoints')?.value).Contains([points[0] / this.ScaleFactor, points[1] / this.ScaleFactor])) {     
                    let lights = this.editor.ModelEditor.FormArray(lane.formItem, "TrafficLights");
                    let newlightFormGroup = new FormGroup({
                        Id: new FormControl(uuidv4()),
                        Type: new FormControl(""),
                        LocalAddress: new FormControl(""),
                        LocalPort: new FormControl(0),
                        Name: new FormControl("TrafficLight"),
                        ActiveDirection: new FormControl("0"),
                        DisplayAngle: new FormControl(0),
                        SerialNumber: new FormControl(null),
                        IsInverted: new FormControl(false),
                        Brightness: new FormControl(100),
                        WorkflowDriven: new FormControl(false),
                        EditorLocked: new FormControl(false),
                        ShowGreenFor: new FormControl(null),
                        LocationPoints: new FormControl(this.CreatePointFromXY(canvasX, canvasY))
                    });

                    (lights as FormArray).push(newlightFormGroup);

                    var component = new TrafficLight(this.editor, this.canvas, newlightFormGroup, lane.formItem);
                    this.editor.Form.markAsDirty();
                    return component;
                }
            }
        }
        this.editor.toastService.error("Please place your Traffic Light within a Lane");
        return null;
    }
}