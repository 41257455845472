import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FrogRoute } from '../FrogRoute';
import { ApiServiceBase } from './api.service.base';
import { LoginServiceBase } from '../auth/login.service.base';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  @Output()
  public UserAlertsUpdated = new EventEmitter<any>();
  private OrganizationUserAlerts: any[] = [];
  private UnresolvedUserAlerts: any = 0;

  constructor(private apiService: ApiServiceBase, private loginService: LoginServiceBase, private frogRouter: Router) {
    loginService.LoginStatusChanged.subscribe(loggedIn => {
      if (loggedIn == true) {
        this.RefreshUserAlerts();
        this.apiService.Get<any>("user/alerts").then(result => {
          this.OrganizationUserAlerts = result;
        });
      }
    });
    if (loginService.IsLoggedIn()) {
      this.RefreshUserAlerts();
    }
  }

  public GetUserAlerts() {
    return this.OrganizationUserAlerts;
  }

  public GetUnresolvedUserAlerts() {
    return this.UnresolvedUserAlerts;
  }

  public RefreshUserAlerts() {

    this.apiService.Get<any>("user/alerts", true).then(result => {
      this.OrganizationUserAlerts = result;
      this.OrganizationUserAlerts.forEach(async (oua) => {
        //cycle through each alert and map a route for the trigger type
        oua["EntityRoute"] = this.MapEntityRoute(oua.TriggerType);
      });
      this.UserAlertsUpdated.emit();
    });

    this.apiService.Get<any>("user/alerts/count", true).then(result => {
      this.UnresolvedUserAlerts = result;
      this.UserAlertsUpdated.emit();
    });
  }

  public AlertResolved(alert: any) {
    var al = this.OrganizationUserAlerts.filter(x => x.Id == alert.Id);
    al[0].ResolvedAt = new Date();
    this.UnresolvedUserAlerts = this.UnresolvedUserAlerts - 1;
    this.UserAlertsUpdated.emit();
  }

  public ResolveAlert(alert: any) {
    var t = "user/alerts/" + alert.Id + "/resolve"
    this.apiService.Post(t, {})
      .then(result => {
        this.AlertResolved(alert);
      });
  }

  public MapEntityRoute(type: string) {
    let entityRoute: string | undefined = "";
    //foreach route in app-routing module, look for an entityType that matches the current entity.
    this.frogRouter.config.forEach((pathsection) => {
      var route = this.CheckChildRoutes(pathsection, type);
      if(route != null) entityRoute = route;
    })
    return entityRoute;
  }

  public CheckChildRoutes(parent: FrogRoute, type: string) {  
    if(parent.entityType != null && parent.entityType == type) {
      return parent.path;
    }

    if(parent.children != null){
      for(let child of parent.children) {
        let cpath: any = this.CheckChildRoutes(child, type);
        if(cpath != null) {
          return parent.path + "/" + cpath;
        }
      }
    }

    return null;
  }
}
