import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-actionviolationmodal',
  templateUrl: './actionviolationmodal.component.html',
  styleUrls: ['./actionviolationmodal.component.scss']
})
export class ActionviolationmodalComponent extends SimpleModalComponent<any, null> {
  public violation: any = null;

  constructor() {
    super();
  }
} 