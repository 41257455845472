import { Component, OnInit, ViewChild } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ToastrService } from 'ngx-toastr';
import { ModalSaveButtonComponent } from 'src/app/shared/forms/buttons/modal-save-button/modal-save-button.component';
import { BusyableModalComponent } from 'src/app/shared/editors/busyableModalComponent';

@Component({
  selector: 'app-uploadstatementmodal',
  templateUrl: './uploadstatementmodal.component.html',
  styleUrls: ['./uploadstatementmodal.component.scss']
})
export class UploadStatementModal extends BusyableModalComponent<any, any> implements OnInit {
  public fileName: string | null = null;
  public selectedFile: File | null = null;
  public fileData: string | undefined = undefined;
  public fileHeaders: string[] = [];
  public providers: string[] = ["Windcave", "Touchnet"];
  public timesAreUtc: boolean = false;
  selectedProvider: string | undefined;

  public mappedFields: { displayName: string, mappedField: string, sideIndex: number }[] = [
    { displayName: "Transaction Id", mappedField: "TransactionId", sideIndex: 0 },
    { displayName: "Amount", mappedField: "Amount", sideIndex: 1 },
    { displayName: "Transaction Date", mappedField: "TransactionDate", sideIndex: 0 },
    { displayName: "Settlement Date", mappedField: "SettlementDate", sideIndex: 1 },
    { displayName: "Card Number", mappedField: "MaskedAccountNumber", sideIndex: 0 },
    { displayName: "Card Type", mappedField: "CardType", sideIndex: 1 },
    { displayName: "Transaction Reference", mappedField: "TransactionReference", sideIndex: 0 },
    { displayName: "Voided", mappedField: "Voided", sideIndex: 1 },
  ]

  public fieldMapping: Map<string, string> = new Map<string, string>();

  constructor(private organizationsService : OrganizationsService, private apiService: ApiServiceBase, private toast: ToastrService) {
    super();
  }
  ngOnInit(): void {
  }

  canUpload(): boolean {
    return this.selectedFile != null && this.fieldMapping.size == this.mappedFields.length && this.selectedProvider != undefined;
  }

  onFileSelected(event: any) {
    this.Busy();
    var file = event.target.files[0] as File;
    this.selectedFile = file;
    this.fileName = this.selectedFile.name;

    this.readFile(this.selectedFile)
      .then(headers => {
          this.fileHeaders = headers;
          this.preMapHeaders();
          this.StopBusy();
        }
      )
      .catch(error => {
          this.toast.error(error, "Error loading File");
          this.selectedFile = null;
          this.fileName = null;
          this.fileHeaders = [];
        }
      );
  }

  preMapHeaders() {
    this.fileHeaders.forEach((header) => {
      let fullmatch = this.mappedFields.find(x => x.displayName == header.trim() || x.mappedField == header.trim())?.mappedField;
      if(fullmatch){
        this.fieldMapping.set(fullmatch, header);
      } else {
        let partMatch = this.mappedFields.find(x => x.displayName.includes(header) || x.displayName.includes(header))?.mappedField;
        if(partMatch) {
          this.fieldMapping.set(partMatch, header);
        }
      }
    });
  }

  readFile(file: File): Promise<string[]> {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = () => {
        var csvData = reader.result as string;
        this.fileData = btoa(csvData);
        var headers = csvData.split('\n')[0].split(',');
        resolve(headers);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    });
  }

  mappingChanged(event: any) {
    if(event.target.id == "Provider") {
      this.selectedProvider = event.target.value;
    }
    else {
      this.fieldMapping.set(event.target.id, event.target.value);
      console.log(this.fieldMapping);
    }
  }

  upload() {
    this.Loading();

    this.apiService.Post("accounting/reconciliation/statements", {
      FileName: this.fileName,
      HeaderMappings: Object.fromEntries(this.fieldMapping),
      Base64CSVData: this.fileData,
      ProviderType: this.selectedProvider,
      TimesAreUTC: this.timesAreUtc,
    }
    ).then((val) => {
      this.StopLoading();
      this.result = true;
      this.toast.success(undefined, "Upload successful!")
      this.close();
    }).catch((val) => {
      this.toast.error(val.error.Error.Message, "There was an error uploading your file");
      this.StopLoading();
    })
  }

  cancel() {
    this.result = false;
    this.close();
  }
}