export class MeshConstants {
    public static floorThickness: number = 0.0005;
    public static bevelThickness: number = 0.0001;
    public static curbThickness: number = 0.0003;
    public static scaleFactor: number = 0.001;
    public static levelHeight: number = 0.005;

    public static talltreesdistance: number = 0.0025;
    public static smalltreesdistance: number = 0.0018;
    public static bushesdistance: number =  0.002;
}


