<app-pageheader [Parent]="this">
  <add-button AddRoute="/settings/calendar/daytypes/new/edit"></add-button>
</app-pageheader>

<app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="calendar/daytypes/searches"
  [AllowEdit]="true" EditUrl="/settings/calendar/daytypes/:id">
  <app-filter-set>
    <div app-search-filter></div>
    <div app-search-button></div>
  </app-filter-set>
</app-datalistview>