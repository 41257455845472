import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';
import { MediaService } from 'src/app/Services/media.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { LoginServiceBase } from 'src/app/auth/login.service.base';

@Component({
  selector: 'app-reportslist',
  templateUrl: './reportslist.component.html',
  styleUrls: ['./reportslist.component.scss']
})
export class ReportslistComponent extends Busyable implements OnInit {

  public Reports: any = {};
  public Categories: string[] = [];

  constructor(private apiService: ApiServiceBase, private loginService : LoginServiceBase, private router: Router, private mediaService: MediaService, private sanitizer: DomSanitizer) {
    super();
  }



  ngOnInit(): void {
    this.Refresh();

  }

  public Refresh() {
    this.Loading();
    this.apiService.Get<any>("reporting/reports").then(results => {
      if (results == null)
        return;

      this.Reports = {};
      for (let result of results) {
        result.ImageUrl = this.mediaService.GetMediaUrl(result.MediaId);
        if (!this.Reports[result.Category]) {
          this.Reports[result.Category] = [];
          this.Categories.push(result.Category);
        }
        this.Reports[result.Category].push(result);
      }
      this.StopLoading();
    });
  }

  public OpenReport(report: any) {
    this.router.navigate(['/reports/run', report.Id]);
  }
}
