<div *ngIf="formArray && formArray.controls.length > 0">
  <div class="control">
    <div class="field is-grouped is-grouped-multiline">
      <div class="tags has-addons" *ngFor="let userControl of formArray.controls; let i = index">
        <span *ngIf="userControl.get('UserId')?.value != null" class="user-text">
          <a class="tag">{{ userControl?.get('FirstName')?.value }} {{ userControl?.get('LastName')?.value }}</a>
          <a class="tag" (click)="removeUser(i)"><i class="fa fa-trash"></i></a>
        </span>
      </div>
      <div class="tags has-addons">
        <a class="tag button is-primary" *ngIf="AllowCreateUser" (click)="addUser()">Add</a>
      </div>
    </div>
  </div>

  <div *ngFor="let userControl of formArray.controls; let i = index">
    <span *ngIf="userControl.get('UserId')?.value == null" class="user-text">
      <div class="addComponent">
        <app-userselector (UserIdChange)="UserChanged($event, userControl)" [AllowCreateUser]="AllowCreateUser"></app-userselector>
      </div>
    </span>
  </div>
</div>
