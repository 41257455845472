import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginServiceBase } from '../login.service.base';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-orgswitch',
  templateUrl: './orgswitch.component.html',
  styleUrls: ['./orgswitch.component.scss']
})
export class OrgswitchComponent implements OnInit {

  constructor(private router: Router, private loginService: LoginServiceBase, private parentRoute: ActivatedRoute) { }

  ngOnInit(): void {
    let orgId = this.parentRoute.snapshot.params['id'];
    this.loginService.DoOrganizationSwitch(orgId);
  }

}
