<div *ngIf="ShowFilters" class="filters-container" [class.fullpage-chart]="isFullPage">
  <div class="properties-pane">
    <div class="datetime-inputs">
      <div class="datetime-wrapper" [tippy]="'Select a date range to filter the data displayed in the chart'">
        <div class="control">
          <div class="select is-small" style="align-items: center;">
            <select [(ngModel)]="selectedDateRange" 
                    style="border: none; outline: none; box-shadow: none !important;" 
                    (change)="chartDataHandler.HandleDateRangePreset()">
              <option value="today">Today (Live)</option>
              <option value="thisWeek">This Week</option>
              <option value="lastWeek">Last Week</option>
              <option value="thisMonth">This Month</option>
              <option value="lastMonth">Last Month</option>
              <option value="last6Months">Last 6 Months</option>
              <option value="thisYear">This Year</option>
              <option value="lastYear">Last Year</option>
              <option value="custom">Custom Range</option>
            </select>
          </div>
        </div>
        <div class="datetime-inputs" *ngIf="selectedDateRange === 'custom'">
          <div class="datetime-input-group">
            <div class="control">
              <input 
                class="form-control input is-small"
                type="datetime-local" 
                [(ngModel)]="dateRange.start"
                [min]="userInterfaceHandler.getMinDate()"
                (change)="chartDataHandler.DateRangeChange()">
            </div>
          </div>
          <div class="arrow-separator">
            <i class="fas fa-arrow-right"></i>
          </div>
          <div class="datetime-input-group">
            <div class="control">
              <input 
                class="form-control input is-small"
                type="datetime-local" 
                [(ngModel)]="dateRange.end"
                [min]="userInterfaceHandler.getMinDate()"
                (change)="chartDataHandler.DateRangeChange()">
            </div>
          </div>
        </div>
      </div>
      
      <div class="time-grouping">
        <div class="buttons has-addons">
          <button class="button is-small time-button" style="border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;" 
                  [class.is-primary]="GroupBy === 'h'"
                  (click)="chartDataHandler.SetGroupBy('h')" [tippy]="'Group data by hour'">
            <span [style.font-weight]="GroupBy === 'h' ? 'bold' : 'normal'">Hour</span>
          </button> 
          <button class="button is-small time-button" 
                  [class.is-primary]="GroupBy === 'd'"
                  (click)="chartDataHandler.SetGroupBy('d')" [tippy]="'Group data by day'">
            <span [style.font-weight]="GroupBy === 'd' ? 'bold' : 'normal'">Day</span>
          </button>
          <button class="button is-small time-button" 
                  [class.is-primary]="GroupBy === 'w'"
                  (click)="chartDataHandler.SetGroupBy('w')" [tippy]="'Group data by week'">
            <span [style.font-weight]="GroupBy === 'w' ? 'bold' : 'normal'">Week</span>
          </button>
          <button class="button is-small time-button" 
                  [class.is-primary]="GroupBy === 'm'"
                  (click)="chartDataHandler.SetGroupBy('m')" [tippy]="'Group data by month'">
            <span [style.font-weight]="GroupBy === 'm' ? 'bold' : 'normal'">Month</span>
          </button>
          <button class="button is-small time-button" 
                  style="height: 38px;" 
                  [class.is-primary]="GroupBy === 'y'"
                  (click)="chartDataHandler.SetGroupBy('y')" [tippy]="'Group data by year'"
                  [ngStyle]="{'border-bottom-right-radius': !(dataSetRequests.length === 1 && dataSetRequests[0].Metric && reportingService.getReportableProperty(dataSetRequests[0].TargetType, dataSetRequests[0].Metric)?.CanGroupBy) ? '5px' : '0',
                             'border-top-right-radius': !(dataSetRequests.length === 1 && dataSetRequests[0].Metric && reportingService.getReportableProperty(dataSetRequests[0].TargetType, dataSetRequests[0].Metric)?.CanGroupBy) ? '5px' : '0'}"
                  >
            <span [style.font-weight]="GroupBy === 'y' ? 'bold' : 'normal'">Year</span>
          </button>
          <div *ngIf="userInterfaceHandler.canShowMetricGrouping()" >
            <button class="button is-small time-button"
                    [class.is-primary]="GroupBy === 'metric'"
                    style="width: auto; padding: 10px;  border-bottom-right-radius: 5px; border-top-right-radius: 5px;"
                    (click)="chartDataHandler.SetGroupBy('metric')" 
                    [tippy]="'Group data by selected metric'">
                    <span [style.font-weight]="GroupBy === 'metric' ? 'bold' : 'normal'">{{reportingService.getReportableProperty(dataSetRequests[0].TargetType, dataSetRequests[0].Metric)?.Label || dataSetRequests[0].Metric}}</span>
            </button>
            <div class="button" 
                 style="border: none; border-radius:5px; padding: 5px 4px; margin-left:10px;" 
                 *ngIf="GroupBy === 'metric' && reportingService.getReportableProperty(dataSetRequests[0].TargetType, dataSetRequests[0].Metric)?.GroupByBucketRequired">
              <span style="margin: 10px; font-size:small">Group size:</span>
              <input class="input" 
                     type="number" 
                     style="width: 80px; height: 30px;"
                     placeholder="Size"
                     [(ngModel)]="bucketSize"
                     (change)="chartDataHandler.SetBucketSize($event)">
              <span style="margin: 10px; font-size:small;">{{reportingService.getReportableProperty(dataSetRequests[0].TargetType, dataSetRequests[0].Metric)?.GroupByBucketLabel}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="chart-controls">
          <button class="button is-small" *ngIf="userInterfaceHandler.HasMultipleBarCharts()"
                  [class.is-primary]="stacked"
                  (click)="chartDataHandler.UpdateStackedOption(!stacked)"
                  [tippy]="'Toggle stacked/unstacked view for bar charts'"
                  style="padding: 0 8px; height:38px; border-radius: 5px;">
            <span class="icon-text" style="gap: 4px;">
              <span class="icon" style="margin: 2px 5px 0 0;">
                <i [class]="stacked ? 'fa-kit fa-stacked-chart fa-xl' : 'fas fa-chart-column fa-xl'"></i>
              </span>
              <span style="font-size: 0.9em;">Stacked: <strong>{{ stacked ? 'On' : 'Off' }}</strong></span>
            </span>
          </button>
          <span class="is-divider-vertical" style="height: 28px; margin: 5px 10px; border-left: 2px solid #dbdbdb;"></span>

          <button class="button is-small is-primary add-dataset-button" style="border-radius: 5px;" [tippy]="userInterfaceHandler.HasPieChart() ? 'Pie charts cannot be combined with other charts. Please select a different chart type to add another Data Set.' : 'Click here to add a new Data Set'"
          (click)="chartDataHandler.AddNewChartDataSet()"
          [disabled]="userInterfaceHandler.HasPieChart()">
    <span>Add Data Set</span>
    <span class="icon is-small">
      <i class="fas fa-plus"></i>
    </span>
  </button>
      </div>
    </div>
  </div>

  <div class="tags-container">
    <div class="tags" style="display: flex; justify-content: space-between; align-items: center; width: 98%;">
      <div style="display: flex; flex-wrap: wrap; gap: 8px; align-items: flex-start;">
        <span class="tag is-clickable custom-tag" *ngFor="let chart of dataSetRequests; let i = index" 
              (click)="chartDataHandler.OpenChartEditModal(chart, i)"
              style="color: black; border: 1px solid #dbdbdb; background-color: hsl(0, 0%, 99%); min-width: 120px; padding: 6px 12px; outline: none;">
          <div style="display: flex; flex-direction: column; width: 100%;">
            <div class="tag-main-content" style="display: flex; align-items: center; margin-bottom: 4px;">
              <span class="color-swatch" *ngIf="chart.ChartType !== 'pie'"
                    [style.background-color]="chart.DisplayColor"
                    style="display: inline-block; width: 12px; height: 12px; margin-right: 6px; border: 1px solid rgba(0,0,0,0.1);">
              </span>
              <i class="fas" 
                 [class.fa-chart-line]="chart.ChartType === 'line'"
                 [class.fa-chart-column]="chart.ChartType === 'bar'"
                 [class.fa-chart-pie]="chart.ChartType === 'pie'"
                 style="margin-right: 6px;">
              </i>
              {{ chart.Label }}
              <ng-container *ngIf="chart.AggregateType && chart.AggregateType !== 'None'">
                [{{chart.AggregateType }}{{' of ' + reportingService.getReportableProperty(chart.TargetType, chart.Metric)?.Label}}{{ chart.SplitBy ? ' by ' + reportingService.GetSplitByLabel(chart.TargetType, chart.SplitBy) : '' }}] 
              </ng-container>
              <ng-container *ngIf="chart.AggregateType === 'None' && chart.Metric">
                [{{ reportingService.getReportableProperty(chart.TargetType, chart.Metric)?.Label }}]
              </ng-container>
              <ng-container *ngIf="chart.Filters != undefined && chart.Filters.length > 0">
                ({{ chart.Filters?.length }} {{ chart.Filters.length === 1 ? 'filter' : 'filters' }})
              </ng-container>
            </div>
            <div *ngIf="chartDataHandler.datasetStatistics != null && chartDataHandler.datasetStatistics.length > 0" 
              style="font-size: 0.85em; color: #777; display: flex; gap: 12px; margin-left:3.5em;">
           <span>
             Total: <strong>{{ chartDataHandler.getStatisticsForChart(chart.Label, chart.TargetType)?.totalResults | number:'1.2-2' }}{{ asPercentage ? '%' : '' }}</strong>
           </span>
           <ng-container *ngIf="GroupBy !== 'metric'">
             <span>
               Avg: <strong>{{ chartDataHandler.getStatisticsForChart(chart.Label, chart.TargetType)?.averageResultsPerTimeUnit | number:'1.2-2' }}{{ asPercentage ? '%' : '' }}</strong>
             </span>
           </ng-container>
         </div>
          </div>
          
          <button class="delete is-small" style="margin-left: 1rem;"
                  (click)="$event.stopPropagation(); chartDataHandler.RemoveDataSet(i); chartDataHandler.chartUpdated.emit(dataSetRequests)">
          </button>
        </span>
      </div>
    </div>
  </div>
</div>
<div [class.fullpage]="isFullPage" style="position: relative;">
  <button *ngIf="!isFullPage" 
          class="button is-small" 
          style="position: absolute; top: 10px; right: 10px; z-index: 10; border: none; background: transparent;">
    <span class="icon is-small has-text-grey-light">
      <i class="fas fa-expand"></i>
    </span>
  </button>

  <div *ngIf="isLive && chartDataResponse?.datasets?.length" class="live-indicator" style="position:absolute; top: 10px; z-index: 10;" 
       [style.right]="isFullPage ? '1em' : '3em'" 
       [tippy]="'Data is updating in real-time'">
    <span class="tag is-danger is-small">
      <span class="icon-text">
        <span class="icon">
          <i class="fas fa-circle-dot fa-beat-fade" style="--fa-animation-duration: 5s;"></i>
        </span>
        <span>Live</span>
      </span>
    </span>
  </div>

  <div *ngIf="!isLive" style="position:absolute; top: 10px; z-index: 10;" 
       [tippy]="'Refresh the chart to get the latest data, as it has filters applied.'"
       [style.right]="isFullPage ? '1em' : '3em'">
    <span class="tag is-light is-small" style="display: flex; align-items: center; gap: 4px;">
      <span class="icon">
        <i class="fas fa-clock"></i>
      </span>
      <span>Updated {{ lastUpdated | timeAgo | async }}</span>
      <button class="button is-small is-ghost"
              style="height: 20px; padding: 0 4px; text-decoration: none; box-shadow: none !important;"
              (click)="$event.stopPropagation(); chartDataHandler.FetchChartData()">
        <span class="icon is-small">
          <i class="fas fa-sync-alt"></i>
        </span>
      </button>
    </span>
  </div>
  
  <app-generic-pie-chart #chart *ngIf="chartType === 'pie'"
    [chartData]="chartDataResponse"
    [title]="title"
    [height]="isFullPage ? 600 : 400"
    [interactive]="isFullPage ? true : false"
    [tooltipOptions]="{ usePointStyle: true }">
  </app-generic-pie-chart>
  
  <app-generic-bar-chart #chart *ngIf="chartType !== 'pie'"
    [chartData]="chartDataResponse"
    [stacked]="stacked"
    [title]="title"
    [height]="isFullPage ? 600 : 400"
    [interactive]="isFullPage ? true : false"
    [showLegend]="isFullPage ? true : false"
    [tooltipOptions]="{ usePointStyle: true }">
  </app-generic-bar-chart>