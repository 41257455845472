<nav class="navbar is-primary is-fixed-top" role="navigation" aria-label="main navigation"
    *ngIf="loginService.IsLoggedIn()">
    <div *ngIf="ShowBanner" class="environment-banner">
        {{BannerText}}
    </div>
    <div class="navbar-brand">
        <a class="navbar-item logo-container pt-0 pb-0 pl-6 pr-6" href="/">
            <img class="logo mr-5 ml-5" src="/assets/images/Frogparking-Logo-White.png" style="max-height: 50px" />
        </a>
    </div>
    <div id="menuToggle" class="is-hidden-desktop">
        <input id="chkToggle" type="checkbox" class="is-hidden-desktop" />
        <span aria-hidden="true" class="burger is-hidden-desktop"></span>
        <span aria-hidden="true" class="burger is-hidden-desktop"></span>
        <span aria-hidden="true" class="burger is-hidden-desktop"></span>

        <ul id="menu" class="is-hidden-desktop  is-scroll">
            <div *ngFor="let i of TopMenuItems" [ngStyle]="{'opacity' : i.Enabled ? 1 : 0.4, 'pointer-events': i.Enabled ? 'auto' : 'none' }">
                <div class="main-menu-item">{{i.Path}}</div>
                <div *ngFor="let j of GetSubMenu(i.Path)">
                    <p class="sub-menu-item">{{j.Text}}</p>
                    <ul class="menu-list">
                        <li *ngFor="let z of j.Children">
                            <a onclick='document.getElementById("chkToggle").checked = false;'
                                [routerLink]="z.Route">{{z.Text}}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div>
                <ul class="menu-list">
                    <hr>
                    <li>
                        <a routerLink="settings" class="nav-icon" onclick='document.getElementById("chkToggle").checked = false;'>
                            <span class="fa fa-cog"></span> Settings
                        </a>
                    </li>
                    <li>
                        <a routerLink="operatorconsole" class="nav-icon" onclick='document.getElementById("chkToggle").checked = false;'>
                            <span class="fa fa-terminal"></span> Operator Console
                        </a>                    
                    </li>
                    <hr>
                    <li>
                        <a style="pointer-events: none;" *ngIf="loginService.IsLoggedIn()" [ngStyle]="{'color': 'lightgray'}" routerLink="account"><i class="fa fa-user"></i> &nbsp;{{loginService.LoggedInName()}} signed into {{CurrentOrganizationName}}</a>
                    </li>
                    <li>
                        <div class="navbar-menu navbar-item has-dropdown" (mouseleave)="ShowOrganizationsDropdown=false"
                        [ngClass]="{ 'is-active' : ShowOrganizationsDropdown }" *ngIf="loginService.IsLoggedIn()">
                        <a class="navbar-link" (click)="ShowOrganizationsDropdown = !ShowOrganizationsDropdown">
                            <div style="min-width: 10em; color: green;"><span class="fa fa-shuffle"></span> Switch Organization</div>
                        </a>
                        <div *ngIf="ShowOrganizationsDropdown" class="navbar-dropdown" (click)="ShowOrganizationsDropdown = false">
                            <div style="max-height:20em; overflow: auto;">
                                <a class="navbar-item" style="white-space: normal; color: black;" *ngFor="let o of Organizations" [routerLink]="'switchorganizations/' + o.Id">
                                    {{o.Name}}
                                </a>
                            </div>
                            <hr class="navbar-divider">
                        </div>
                        </div>
                    </li>
                    <li>
                        <a (click)="LogOut()" class="nav-icon">
                            <span class="fa fa-right-from-bracket"></span> Log Out
                        </a>
                    </li>
                    <hr>
                </ul>
            </div>
        </ul>
    </div>

    <div id="navbarTop" class="navbar-menu">
        <div class="navbar-start" *ngIf="TopMenuItems.length > 0">
            <a class="navbar-item" *ngFor="let i of TopMenuItems" [routerLink]="i.Route" [ngStyle]="{'opacity' : i.Enabled ? 1 : 0.4, 'pointer-events': i.Enabled ? 'auto' : 'none' }">{{i.Text}}</a>
            <a class="navbar-item" [routerLink]="'settings'" [ngStyle]="{'opacity' : 1, 'pointer-events': 'auto'}"><i class="fa fa-cog" style="margin-right:10px; "></i>Settings</a>
        </div>
    </div>
    <div class="navbar-menu">
        <div class="navbar-end">
            <div class="navbar-item"  style="padding-left:0px !important; padding-right:0px !important;" [ngClass]="{ 'is-active' : ShowAlertsDropdown }" *ngIf="loginService.IsLoggedIn()"
                (click)="ShowAlertsDropdown = !ShowAlertsDropdown">
                <div class="fa fa-bell"></div><span class="tag is-rounded is-small"
                    *ngIf="UnresolvedAlertCount != null && UnresolvedAlertCount > 0"
                    [ngClass]="{'alert-count' : UnresolvedAlertCount != null && UnresolvedAlertCount > 0}">{{UnresolvedAlertCount}}</span>
                <div class="navbar-dropdown" style="max-width:20em;" (click)="ShowAlertsDropdown = false">
                    <div class="navbar-item card" style="display: block; cursor: pointer;" *ngFor="let o of Alerts"
                        [ngClass]="{'resolved' : o.ResolvedAt != null }" >
                        <div class="card-content">
                            <div class="content">
                                <span (click)="ViewEntity(o)">
                                <h6 style="display: inline-block;">{{o.Title}}: </h6>
                                <p class="card-timestamp" style="display: inline-block; padding-left:5px;">
                                    {{o.TimeTriggered}}</p>
                                <br>
                                <p class="card-description" style="max-width: 12em; white-space:normal; display:inline-block; word-wrap: break-word;" >
                                    {{o.Description}}</p> 
                                </span>
                                <button
                                    *ngIf="o.ResolvedAt == null" type="button" style="display: inline-block; left:10px;
                                border-radius: 17px;" class="button is-primary is-small" (click)="ResolveAlert(o)">Mark
                                    Resolved</button>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="navbar-item" *ngIf="ShowOperatorConsole">
                <a routerLink="operatorconsole" class="nav-icon">
                    <span class="fa fa-terminal" style="padding-left:0px !important; padding-right:10px !important;"></span>
                </a>
            </div>
            <div class="navbar-item" style="color:lightgray;">
                |
            </div>
            <div class="navbar-item myaccount">
                <a *ngIf="loginService.IsLoggedIn()" [ngStyle]="{'color': 'inherit'}" routerLink="account"><i class="fa fa-user" style="margin-right:10px;"></i> &nbsp;{{loginService.LoggedInName()}}</a>
                <div *ngIf="!loginService.IsLoggedIn()">
                    (not logged in)
                </div>
            </div>
            <div class="navbar-item has-dropdown mr-3" (mouseleave)="ShowOrganizationsDropdown=false"
                [ngClass]="{ 'is-active' : ShowOrganizationsDropdown }" *ngIf="loginService.IsLoggedIn()">
                <a class="navbar-link input select orgselector" [ngClass]="{ 'is-active' : ShowOrganizationsDropdown }" style="margin-top: 10px; height: 2em;" (click)="ShowOrganizationsDropdown = !ShowOrganizationsDropdown">
                    <div style="min-width: 10em;">{{CurrentOrganizationName}}</div>
                </a>
                <div class="navbar-dropdown" (click)="ShowOrganizationsDropdown = false">
                    <div style="max-height:20em; overflow: auto;">
                        <a class="navbar-item" style="white-space: normal;" *ngFor="let o of Organizations" [routerLink]="'switchorganizations/' + o.Id">
                            {{o.Name}}
                        </a>
                    </div>
                    <hr class="navbar-divider">
                    <div class="navbar-item" >
                        <button type="button" class="button is-primary is-small is-outlined is-fullwidth"
                            (click)="LogOut()">Log Out</button>
                    </div>
                    <!-- <div class="navbar-item" >
                        <button type="button" class="button is-primary is-small is-outlined is-fullwidth" (click)="loginService.DebugBreakAuthToken()">*break session*</button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

</nav>