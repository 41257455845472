import { Component, Injector, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { FirmwareselectorComponent } from 'src/app/components/dialogs/firmwareselector/firmwareselector.component';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiService } from 'src/app/Services/api.service';
import { OrganizationsService } from 'src/app/Services/organizations.service';

@Component({
  selector: 'app-editlegacysign',
  templateUrl: './editlegacysign.component.html',
  styleUrls: ['./editlegacysign.component.scss']
})
export class EditlegacysignComponent extends ModelEditor implements OnInit {

  public ParkingLots : any[] | null = null;
  public TimeZoneName : any;

  constructor(private apiService: ApiService, public organizationsService: OrganizationsService, private route: ActivatedRoute, private toastService: ToastrService, private modalService: SimpleModalService, private injector: Injector) {
    super("infrastructure/legacysigns", injector);
    apiService.Get<any>("/infrastructure/parkinglots").then(result =>{
      this.ParkingLots = result;
    })
    organizationsService.GetTimeZoneName().then((x : string) => this.TimeZoneName = x);
  }
  public override DefaultModel(): any {
    return {

    };
  }
  public override AfterModelLoaded(): void {
  }
  public override BeforeSave(): void {

  }
  public override Validators(): any {
    return {
      "ChipId": [Validators.required],
    };
  }

  public LevelsForLot(lotId: string): any[] {
    if (this.ParkingLots == null) return [];

    let filter = this.ParkingLots.filter((x: any) => x.Id == lotId);
    if (filter.length > 0) {
      return filter[0].Levels;
    }
    return [];
  }

  public RowsForLevel(rowId: string, levelId: string): any[] {
    if (this.ParkingLots == null) return [];
    let levels = this.LevelsForLot(rowId);

    if (levels != undefined) {
      let filter = levels.filter((x: any) => x.Id == levelId);
      if (filter.length > 0) {
        return filter[0].Rows;
      }
    }
    return [];
  }

  public SelectFirmwareVersion() {
    this.modalService.addModal(FirmwareselectorComponent, { HardwareVersionId: this.Form.get("HardwareVersionId")?.value })
      .subscribe((result) => {
        if (result != null) {
          this.Form.get("FirmwareVersionId")?.setValue(result.Id);
          this.Form.get("FirmwareVersionFileName")?.setValue(result.FileName);
        }
      });
  }

}
