import { EventEmitter, Injectable, Output } from '@angular/core';
import { ApiServiceBase } from './api.service.base';
import { PermissionsService } from './permissions.service';
import { resolve } from 'dns';

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {

  @Output()
  public MenuChanged = new EventEmitter<any>();

  constructor(private apiService: ApiServiceBase, private permissionsService: PermissionsService) {
    permissionsService.PermissionSetChanged.subscribe(x => {
      console.log("Feature Service received Permissions Changed event, rebuilding menu");
      this.RebuildMenu();
    });
  }

  private Features: any = [];
  private Settings: any = [];

  private MenuItems: any[] = [];
  private MenuTree: any = { Path: "", Route: "", Children: [] };

  private FeaturePromise: Promise<any> | null = null;

  public RebuildMenu() {
    if (this.FeaturePromise != null) {
      console.log("Installed Features request aborted - already requested");
      return this.FeaturePromise;
    }
    this.FeaturePromise = new Promise<string>(async (resolve, reject) => {
      await this.apiService.Get("features/installed").then(async (result: any) =>{
        console.log("Feature Service refreshed " + result.length + " installed features from server");
        let menuArray: any = { Path: "", Route: "", Children: [] };
        for (let i = 0; i < result.length; i++) {
          await this.ProcessFeature(menuArray, result[i]);
        }
        this.Features = result;
        menuArray.Children = menuArray.Children.sort((n1: any,n2: any)=> n1.DisplayIndex - n2.DisplayIndex);
        this.MenuTree.Children = menuArray.Children;

        this.MenuTree.Children.forEach((child : any) => {
          child.Children = child.Children.sort((a: any, b: any)=> a.DisplayIndex - b.DisplayIndex);
        });

        resolve('Feature service finished building menu tree');
        this.MenuChanged.emit(this.Features);
        this.FeaturePromise = null;
        console.log("Feature service finished building menu tree, " + menuArray.Children.length + " top level items");
      });
    });
    return this.FeaturePromise;
  }


  public GetFeatures() {
    return this.Features;
  }

  public GetSettings() {
    return this.Settings;
  }

  public GetFeatureName(type: string) {
    let x = this.Features.filter((x: any) => x.Type == type);
    if (x.length == 0) {
      return "";
    }
    return x[0].Name;
  }

  public GetTopMenu() {
    return this.MenuTree.Children.filter((x: any) => x.Text != "Settings" && x.Text != "Account");
  }

  public GetMenuChildren(path: string) {
    path = path.toLowerCase();
    for (let i = 0; i < this.MenuTree.Children.length; i++) {
      let child = this.MenuTree.Children[i];
      if (child != null) {
        if (child.Path != null && child.Path.toLowerCase() == path) {
          return child.Children;
        }
      }
    }
  }

  private async ProcessFeature(parentArray: any[], feature: any) {
    //console.log("Feature Service processing feature " + feature.Type);
    if (feature.AdminMenuItems != null) {
      for (let i = 0; i < feature.AdminMenuItems.length; i++) {
        await this.ProcessMenuItem(parentArray, feature, feature.AdminMenuItems[i]);
      }
    }
  }

  private async ProcessMenuItem(parentArray: any[], feature: any, item: any) {
    //check if user has access to the menu item

    let enabled = await this.permissionsService.CheckAdminPermission(feature.Type, 0);
      item.Enabled = enabled;
      let parts = item.MenuPath.split('/');
      let builder: string = parts[0];
      console.log("Adding " + feature.Type + " enable is" + item.Enabled);
      let parent = this.AddMenuItem(parentArray, builder.trim().replace(/\s/g, ''), builder, item, feature);
      for (let i = 1; i < parts.length; i++) {
        builder = builder.trim().replace(/\s/g, '') + "/" + parts[i].trim().replace(/\s/g, '');
        parent = this.AddMenuItem(parent, builder, parts[i], item, feature);
      }
      parent.Route = item.Route.toLowerCase();
      if(parent.Route.startsWith('/')) parent.Route = parent.Route.substring(1);
    
    //item.Enabled = this.permissionsService.CheckAdminPermission(feature.Type, 0);
    // let parts = item.MenuPath.split('/');
    // let builder: string = parts[0];
    // console.log("Adding " + feature.Type + " enable is" + item.Enabled);
    // let parent = this.AddMenuItem(parentArray, builder.trim().replace(/\s/g, ''), builder, item);
    // for (let i = 1; i < parts.length; i++) {
    //   builder = builder.trim().replace(/\s/g, '') + "/" + parts[i].trim().replace(/\s/g, '');
    //   parent = this.AddMenuItem(parent, builder, parts[i], item);
    // }
    // parent.Route = item.Route.toLowerCase();
  }

  private AddMenuItem(parent: any, path: string, text: string, item: any, feature: any): any {
    for (let i = 0; i < parent.Children.length; i++) {
      if (parent.Children[i].Path == path) {
        if (item.Enabled == true) {
          parent.Children[i].Enabled = true;
        }
        if (feature.Path == parent.Children[i].Path){
          parent.Children[i].DisplayIndex = feature.DisplayIndex;
        }
        return parent.Children[i];
      }
    }
    let route = path.toLowerCase().trim();
    if(route.startsWith('/')) 
      route = route.substring(1);
    let displayIndex = item.DisplayIndex;
    if (feature.Path == path)
      displayIndex = feature.DisplayIndex;
    let newItem = { Text: text, Path: path, Route: route, Children: [], Enabled: item.Enabled, DisplayIndex: displayIndex };
    parent.Children.push(newItem);
    this.MenuItems.push(newItem);
    return newItem;
  }

  public async FindMenuItemByRoute(route: string) {
    if(route.startsWith('/')) route = route.substring(1);
    for (let i = 0; i < this.MenuItems.length; i++) {
      if (this.MenuItems[i].Route == route) {
        return this.MenuItems[i];
      }
    }
  }

  public FeatureEnabled(name: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.Features == null) {
        this.RebuildMenu().then(() => {
          resolve(this.Features.filter((x: any) => x.Type == name).length > 0);
        });
      }
      else {
        resolve(this.Features.filter((x: any) => x.Type == name).length > 0);
      }
    });
  }
}
