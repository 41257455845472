import { Renderer2 } from '@angular/core';
import * as TWEEN from '@tweenjs/tween.js';
import { MeshConstants } from 'src/app/util/mesh-constants';
import * as THREE from "three";
import { Camera, Scene, Vector2, Vector3 } from "three";
import { GuardRailMesh } from './guard-rail-mesh';
import { IslandMesh } from './island-mesh';
import { LevelObjectMesh } from './level-object-mesh';
import { meshanimationresult } from './meshanimationresult';
import { SelectionIndicator } from './selection-indicator';
import { ShapeMesh } from "./shape-mesh";
import { SolidWallMesh } from './solid-wall-mesh';
import { SpaceMesh } from "./space-mesh";
import { PillarMesh } from './pillar-mesh';
import { RampMesh } from './ramp-mesh';
import { point } from '@turf/turf';
import { GrassIslandMesh } from './grassisland-mesh';
import { BushMesh } from './bush-mesh';
import { TreeMesh } from './tree-mesh';
import { PedestrianZoneMesh } from './pedestrian-zone-mesh';
import { BusinessMesh } from './business-mesh';
import { GardenBedMesh } from './gardenbed-mesh';
import { GatewayMesh } from './gateway-mesh';
import { SignMesh } from './sign-mesh';
import { TrafficLightMesh } from './trafficlight-mesh';

export class LevelMesh extends THREE.Mesh {
    public Meshes: any[] = [];
    public MeshesById : any = {};
    public Camera = new THREE.Camera;

    constructor(scene: Scene, level: any, offset: Vector3, levelHeight: number, scale: Vector2, camera: THREE.Camera, mixers: meshanimationresult[], displayOptions : LevelMeshDisplayOptions) {
        super();
        this.Camera = camera;
        console.log("Adding Level at height " + offset.z);
        let p = level.PolygonPoints;
        let shape = new THREE.Shape()
        shape.moveTo(p[0][0] * scale.x + offset.x, -1 * p[0][1] * scale.y + offset.y);

        for (let i = 1; i < p.length; i++) {
            shape.lineTo(p[i][0] * scale.x + offset.x, -1 * p[i][1] * scale.y + offset.y);
        }
        const extrudeSettings = {
            steps: 2,
            depth: MeshConstants.floorThickness,
            bevelEnabled: true,
            bevelThickness: MeshConstants.bevelThickness,
            bevelSize: 0.0001,
            bevelOffset: 0,
            bevelSegments: 1
        };

        let geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
        geometry.translate(0, 0, offset.z);
        const texture = new THREE.TextureLoader().load('/assets/textures/asphalt.jpg');
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.offset.set( 0, 0.5 );
        texture.repeat.set( 200,200 );

        let material = new THREE.MeshStandardMaterial({ color: 0x999999, map : texture });
        let mesh = new THREE.Mesh(geometry, material);
        
        scene.add(mesh);
        this.Meshes.push(mesh);

        extrudeSettings.depth = 0.0001;

        if (displayOptions.ShowSpaces && level.Rows != null) {
            for (let r of level.Rows) {
                
                for (let s of r.Spaces) {
                    let sp = new SpaceMesh(scene, s, new Vector3(offset.x, offset.y, offset.z + MeshConstants.floorThickness), scale, mixers)
                    this.Meshes.push(sp);
                    this.MeshesById[s.Id] = sp;
                }
            }
        
        }

        if(level.GatewayConfigurations != null && level.GatewayConfigurations.length > 0){
            for(let gateway of level.GatewayConfigurations){
                let s = new GatewayMesh(scene, gateway, new Vector3(offset.x, offset.y, offset.z + MeshConstants.floorThickness), scale);
                    this.Meshes.push(s);
                    scene.add(s);
                    this.MeshesById[gateway.Id] = s;
            }
        }

        if(level.SignConfigurations != null && level.SignConfigurations.length > 0){
            for(let sign of level.SignConfigurations){
                let s = new SignMesh(scene, sign, new Vector3(offset.x, offset.y, offset.z + MeshConstants.floorThickness), scale);
                    this.Meshes.push(s);
                    scene.add(s);
                    this.MeshesById[sign.Id] = s;
            }
        }

        if (level.MapItems != null) {
            for (let m of level.MapItems) {
                switch (m.Type) {
                    case "SolidWall":
                        if(displayOptions.ShowWalls){
                            let x = new SolidWallMesh(scene, m, offset, levelHeight, scale);
                            this.Meshes.push(x);
                            this.MeshesById[m.Id] = x;
                        }
                        break;
                    case "GuardRail":
                        if(displayOptions.ShowWalls){
                            let g = new GuardRailMesh(scene, m, new Vector3(offset.x, offset.y, offset.z + MeshConstants.floorThickness), levelHeight - MeshConstants.floorThickness, scale);
                            this.Meshes.push(g);
                            this.MeshesById[m.Id] = g;
                        }
                        break;
                    case "Island":
                        if(displayOptions.ShowAccessControl){
                            let i = new IslandMesh(scene, m, new Vector3(offset.x, offset.y, offset.z + MeshConstants.floorThickness), scale);
                            this.Meshes.push(i); 
                            this.MeshesById[m.Id] = i;
                        }
                        break;
                    case "GrassIsland":
                        if(displayOptions.ShowGardens){
                            let i = new GrassIslandMesh(scene, m, new Vector3(offset.x, offset.y, offset.z + MeshConstants.floorThickness), scale);
                            this.Meshes.push(i); 
                            this.MeshesById[m.Id] = i;
                        }
                        break;
                    case "GardenBed":
                        if(displayOptions.ShowGardens){
                            let i = new GardenBedMesh(scene, m, new Vector3(offset.x, offset.y, offset.z + MeshConstants.floorThickness), scale);
                            this.Meshes.push(i); 
                            this.MeshesById[m.Id] = i;
                        }
                        break;
                    case "Box":
                    case "Arrow":
                        if(displayOptions.ShowShapes){
                            let s = new ShapeMesh(scene, m, new Vector3(offset.x, offset.y, offset.z + MeshConstants.floorThickness), scale);
                            this.Meshes.push(s);
                            this.MeshesById[m.Id] = s;
                        }
                        break;
                    case "Pillar":
                        if (displayOptions.ShowShapes){
                            let s = new PillarMesh(scene, m, new Vector3(offset.x, offset.y, offset.z + MeshConstants.floorThickness), scale);
                            this.Meshes.push(s);
                            this.MeshesById[m.Id] = s;
                        }
                        break;
                    case "Ramp":
                        if(displayOptions.ShowShapes){
                            let s = new RampMesh(scene, m, new Vector3(offset.x, offset.y, offset.z + MeshConstants.floorThickness), scale, false);
                            this.Meshes.push(s);
                            this.MeshesById[m.Id] = s;
                            }
                        break;
                    case "Bush":
                        if(displayOptions.ShowGardens){
                            let s = new BushMesh(scene, m, new Vector3(offset.x, offset.y, offset.z + MeshConstants.floorThickness), scale, 'bush');
                            this.Meshes.push(s);
                            this.MeshesById[m.Id] = s;
                            }
                        break;
                    case "SmallTree":
                        if(displayOptions.ShowGardens){
                            let s = new TreeMesh(scene, m, new Vector3(offset.x, offset.y, offset.z + MeshConstants.floorThickness), scale, 'smalltree');
                            this.Meshes.push(s);
                            this.MeshesById[m.Id] = s;
                            }
                        break;
                    case "TallTree":
                        if(displayOptions.ShowGardens){
                            let s = new TreeMesh(scene, m, new Vector3(offset.x, offset.y, offset.z + MeshConstants.floorThickness), scale, 'talltree');
                            this.Meshes.push(s);
                            this.MeshesById[m.Id] = s;
                            }
                        break;
                    case "PedestrianZone":
                        if(displayOptions.ShowShapes){
                            let s = new PedestrianZoneMesh(scene, m, new Vector3(offset.x, offset.y, offset.z + MeshConstants.floorThickness), scale);
                            this.Meshes.push(s);
                            this.MeshesById[m.Id] = s;
                            }
                        break;
                    case "BusinessDirectory":
                        if(displayOptions.ShowShapes){
                            let s = new BusinessMesh(scene, m, new Vector3(offset.x, offset.y, offset.z + MeshConstants.floorThickness), scale);
                            this.Meshes.push(s);
                            this.MeshesById[m.Id] = s;
                            }
                        break;
                    case "Business":
                        if(displayOptions.ShowShapes){
                            let s = new BusinessMesh(scene, m, new Vector3(offset.x, offset.y, offset.z + MeshConstants.floorThickness), scale);
                            this.Meshes.push(s);
                            this.MeshesById[m.Id] = s;
                            }
                        break;
                }
            }
        }
        if (level.AdjoiningObjects != null) {
            for (let m of level.AdjoiningObjects) {
                switch (m.Type) {
                    case "Pillar":
                        let s = new PillarMesh(scene, m, new Vector3(offset.x, offset.y, offset.z - MeshConstants.levelHeight), scale);
                        this.Meshes.push(s);
                        this.MeshesById[m.Id] = s;
                        break;
                }
                switch (m.Type) {
                    case "Ramp":
                        let s = new RampMesh(scene, m, new Vector3(offset.x, offset.y, offset.z - MeshConstants.levelHeight), scale, true);
                        this.Meshes.push(s);
                        this.MeshesById[m.Id] = s;
                        break;
                }                
            }
        }
        
        if (displayOptions.ShowAccessControl && level.Gates != null) {
            for (let g of level.Gates) {
                if (g.Lanes != null) {
                    for (let l of g.Lanes) {

                        let lanepoly = l.PolygonPoints;
                        let shape = new THREE.Shape()
                        shape.moveTo(lanepoly[0][0]*scale.x + offset.x, -1*lanepoly[0][1]*scale.y + offset.y);
                        for(let i=1; i < lanepoly.length; i++){
                            shape.lineTo(lanepoly[i][0]*scale.x + offset.x, -1*lanepoly[i][1]*scale.y + offset.y);
                        }

                        const extrudeSettings = {
                            steps: 2,
                            depth: MeshConstants.curbThickness,
                            bevelEnabled: true,
                            bevelThickness: 0.0001,
                            bevelSize: 0.0001,
                            bevelOffset: 0,
                            bevelSegments: 1
                        };
                        
                        let geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
                        geometry.translate(0,0,offset.z + 0.0001);           
                        geometry.computeBoundingBox();
                        var center = new THREE.Vector3();
                        geometry.boundingBox?.getCenter( center );
                        mesh.localToWorld( center );
                    
                        const pointLight = new THREE.PointLight( 0xFFFFFF, 1.5, 0.1 );
                        pointLight.position.set( center.x, center.y, 0.008 );
                        pointLight.castShadow = true;
                        scene.add( pointLight );

                        if (l.Barriers != null) {
                            for (let b of l.Barriers) {
                                let c = new LevelObjectMesh(scene, b, new Vector3(offset.x, offset.y, offset.z + MeshConstants.floorThickness), scale, b.Type, false, b, mixers);
                                this.Meshes.push(c);
                                this.MeshesById[b.Id] = c;
                                scene.add(c);
                            }
                        }

                        if (l.Cameras != null) {
                            for (let camera of l.Cameras) {
                                let c = new LevelObjectMesh(scene, camera, new Vector3(offset.x, offset.y, offset.z + MeshConstants.floorThickness), scale, camera.Type, false, camera, mixers);
                                this.Meshes.push(c);
                                scene.add(c);
                                this.MeshesById[camera.Id] = c;
                            }
                        }

                        if (l.TrafficLights != null) {
                            for (let trafficlight of l.TrafficLights) {
                                let c = new TrafficLightMesh(scene, trafficlight, new Vector3(offset.x, offset.y, offset.z + MeshConstants.floorThickness), scale);
                                this.Meshes.push(c);
                                scene.add(c);
                                this.MeshesById[trafficlight.Id] = c;
                            }
                        }
                    }
                }
            }
        }

        if (displayOptions.ShowAccessControl && level.Controllers != null) {
            for (let controller of level.Controllers) {
                let c = new LevelObjectMesh(scene, controller, new Vector3(offset.x, offset.y, offset.z + MeshConstants.floorThickness), scale, 'FrogTerminal', false, controller, mixers);         
                this.Meshes.push(c);
                scene.add(c);
                this.MeshesById[controller.Id] = c;
            }

        }

        const intervalInMinutes = 1; // Change this to your desired interval in minutes
        const intervalInMilliseconds = intervalInMinutes * 60 * 1000;

        setInterval(() => {
            let spaceMeshes = this.Meshes.filter((x: any) => x.type == "space");
            for(let mesh of spaceMeshes){
                (mesh as SpaceMesh).UpdateVehicleColor();
            };
        }, intervalInMilliseconds);

    }

    public MoveUp(distance: number) {
        console.log("Moving level up by " + distance);
        for (let m of this.Meshes) {
            let end = m.position.z + distance;
            new TWEEN.Tween(m.position)
                .to({ z: end }, 500)
                .easing(TWEEN.Easing.Cubic.Out)
                .start()
                .onUpdate((val) => {
                    console.log("Level Move Down animation OnUpdate");
                })
                .onComplete(() => { console.log("Level Move Down animation complete"); });
        }
    }
    public MoveDown(distance: number) {
        console.log("Movind level down by " + distance);
        for (let m of this.Meshes) {
            let end = m.position.z - distance;
            new TWEEN.Tween(m.position)
                .to({ z: end }, 500)
                .easing(TWEEN.Easing.Cubic.Out)
                .start()
                .onUpdate((val) => {
                    console.log("Level Move Down animation OnUpdate");
                })
                .onComplete(() => { console.log("Level Move Down animation complete"); });
        }
    }
}

export class LevelMeshDisplayOptions{
    public ShowSpaces : boolean = false;
    public ShowShapes : boolean = false;
    public ShowAccessControl : boolean = false;
    public ShowWalls : boolean = false;
    public ShowGardens : boolean = false;
}