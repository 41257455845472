import { Component, OnInit, ViewChild } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ToastrService } from 'ngx-toastr';
import { ModalSaveButtonComponent } from 'src/app/shared/forms/buttons/modal-save-button/modal-save-button.component';
import { BusyableModalComponent } from 'src/app/shared/editors/busyableModalComponent';

@Component({
  selector: 'app-reconciliationdatamodal',
  templateUrl: './datamodal.component.html',
  styleUrls: ['./datamodal.component.scss']
})
export class ReconciliationDataModalComponent extends BusyableModalComponent<any, any> implements OnInit {
  public fieldMapping: Map<string, string> = new Map<string, string>();

  Row: any;

  parkingLots: any[] | null = null;
  ratesets: any[] | null = null;

  constructor(private organizationsService : OrganizationsService, private apiService: ApiServiceBase, private toast: ToastrService) {
    super();
  }
  ngOnInit(): void {
    this.fieldMapping = new Map<string, string>();
    this.Loading();

    for(let field of this.Row.RequestedFields) {
      if(field == 'ParkingLot' && this.parkingLots == null) {
        this.apiService.Get<any>("infrastructure/parkinglots").then(result => {
            this.parkingLots = result;
            var lotname = this.Row.ActionValues["ParkingLotName"];
            if(lotname) {
              var lot = result.find((x: any) => x.Name == lotname);
              if(lot) {
                this.fieldMapping.set("ParkingLot", lot.Id);
              }
            }
          }
        );
      } 
      else if(field == 'RateSet' && this.parkingLots == null) {
        this.apiService.Get<any>("parking/ratesets").then(result => this.ratesets = result);
      } 
      else {
        var def = this.Row.ActionValues[field];
        if(def) {
          this.fieldMapping.set(field, def);
        }
      }
    }    

    this.StopLoading();
  }

  submit() {
    this.Row.RequestedValues = Object.fromEntries(this.fieldMapping);
    this.result = this.Row;
    this.close();
  }

  cancel() {
    this.result = false;
    this.close();
  }

  getFieldType(fieldType: string) {
    if(fieldType == 'ParkingLot' || fieldType == 'RateSet') {
      return fieldType;
    }

    if(fieldType.endsWith('Date')) {
      return 'Date';
    }

    return 'Text';
  }

  getFieldDisplayName(field: string): string {
    return field.replace(/([a-z])([A-Z])/g, '$1 $2');
}

  mappingChanged(event: any) {
    this.fieldMapping.set(event.target.id, event.target.value);
  }
}