import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { OrganizationsService } from 'src/app/Services/organizations.service';

@Component({
  selector: 'app-addsessionmanualpaymentmodal',
  templateUrl: './addsessionmanualpaymentmodal.component.html',
  styleUrls: ['./addsessionmanualpaymentmodal.component.scss']
})
export class AddsessionmanualpaymentmodalComponent extends SimpleModalComponent<any, null> implements OnInit {

  public Session: any = null;

    constructor(private modalService: SimpleModalService, private route: ActivatedRoute, private apiService: ApiServiceBase, private organizationsService: OrganizationsService, private toastService: ToastrService) {
      super();
    }

    ngOnInit(): void {

    }

}
