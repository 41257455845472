<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Events for {{Model.Name}}</p>
      </header>
      <section class="modal-card-body" style="min-width: 30em;">
       
      

      <nav class="panel">
        <a class="panel-block is-active"*ngFor="let s of Model.EntryEvents; let i = index">
            <span class="panel-icon">
              <i class="fa fa-bolt" aria-hidden="true"></i>
            </span>
            <span class="panel-icon" style="height: auto;">
              <a *ngIf="i!=0" class="up-button" (click)="MoveUp(i)" title="Move Up">
                <i class="fa fa-chevron-up" aria-hidden="true"></i>
              </a>
              <a *ngIf="i!=Model.EntryEvents.length - 1" class="down-button"  (click)="MoveDown(i)" title="Move Down"> 
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
              </a>
            </span>
            {{s.Event}}
            <span *ngIf="s.Event == 'InitiatePayment'">
              (Max Retries: <input class="input" style="width: 4em;" type="number" step="1" min="1" [(ngModel)]="s.Arguments"/>)
            </span>
            <span *ngIf="s.Event == 'SetExitType'">
              <span class="select ml-2">
                <select [(ngModel)]="s.Arguments">
                  <option *ngFor="let i of ExitTypes" [value]="i.Value">{{i.Name}}</option>
                </select>
              </span>
            </span> 
            <span *ngIf="s.AllowArguments == true || (s.Arguments != null && s.Event != 'InitiatePayment' && s.Event != 'SetExitType')">
              (With value: <input class="input" style="width: 6em;" [(ngModel)]="s.Arguments">)
            </span>
            <i class="fa fa-trash" style="position: absolute; right: 2em;" (click)="DeleteEvent(i)"></i> 
        </a>
        <div class="panel-block">
          <div class="field has-addons">
            <div class="control is-expanded">
              <app-categorized-selector
                [transitions]="Events"
                (selectionChanged)="onEventSelected($event)">
              </app-categorized-selector>
            </div>
            <div class="control">
              <a class="button is-primary" (click)="AddSelectedEvent()">
                Add
              </a>
            </div>
          </div>
        </div>
        <div class="panel-block" >
            <div style="width: 30em;">
              <div *ngIf="SelectedEvent != null">
                {{SelectedEvent.Description}}
            </div>
          </div>
        </div>
      </nav>

    </section>
      <footer class="modal-card-foot">
        <button class="button is-success" (click)="Confirm()">OK</button>
      </footer>
    </div>
  </div>