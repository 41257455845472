import { Component, Input, OnInit } from '@angular/core';
import { MediaService } from 'src/app/Services/media.service';

@Component({
  selector: 'app-mediaviewer',
  templateUrl: './mediaviewer.component.html',
  styleUrls: ['./mediaviewer.component.scss']
})
export class MediaViewerComponent implements OnInit {

  @Input() MediaId: any = "";
  @Input() Height: any = "100%";
  @Input() Width: any = "100%";
  @Input() Thumbnail: boolean = false;
  public src : string | undefined = "";

  constructor(public mediaService: MediaService) {
  }

  ngOnInit(): void {
    this.src = this.mediaService.GetMediaUrl(this.MediaId, this.Thumbnail);
  }
}