import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { LevelEditorComponent } from '../../leveleditor/leveleditor.component';

@Component({
  selector: 'app-editparkingleveldesign',
  templateUrl: './editparkingleveldesign.component.html',
  styleUrls: ['./editparkingleveldesign.component.scss']
})
export class EditparkinglevelDesignComponent extends ModelEditor {
  // @ViewChild("levelMapEditor")
  // public levelMapEditor !: LevelmapeditorComponent;

  @ViewChild("levelEditor")
  public levelEditor !: LevelEditorComponent;

  constructor(private apiService: ApiService, public route: ActivatedRoute, private injector: Injector) {
    super("infrastructure/parkinglots/" + route.snapshot.params["lotid"] + "/leveldesigns", injector);
  }

  public override DefaultModel(): any {
    return { Name: "New Parking Level", Rows: [], Gates: [], Lanes: []};
  }

  public override AfterSave(): void {
    if(this.levelEditor != null){
      this.levelEditor.Form = this.Form;
      this.levelEditor.LoadCanvas();
      this.levelEditor.ToolInUse.Locked = false;
      this.levelEditor.ToolInUse = null;
    }
  }

  public override AfterModelLoaded(): void {
    
  }

  public override BeforeSave(): boolean | void {
    // this.levelMapEditor.ClearSelection();
    var ErrorList: any[] = [];
    var spaceIds: any[] = [];
    var spaces: any[] = [];
    //check no spaces have same Id

    if(this.Form.get('Rows')?.value != null){
      this.Form.get('Rows')?.value.forEach((row:any) => {
        if(row.Spaces != null){
          row.Spaces.forEach((space: any)=>{
            if(space.ChipId != undefined){
              spaceIds.push(space.ChipId);
              spaces.push({'Space': space, 'Row': row });
            }
          })
        }
      });
    }


    let findDuplicates = (arr : any) => arr.filter(((item : any, index:any) => arr.indexOf(item) != index));
    var dup = findDuplicates(spaceIds);

    dup.forEach((duplicate : any) => {
      var dupspaces = spaces.filter(x => x.Space.ChipId == duplicate).map(a => "(Space " + a.Space.Name + " " + a.Row.Name + ')');
      ErrorList.push('The Sensor Id: ' + duplicate + ' is applied to more than one space. ' + dupspaces.join(","));
    })

    if(ErrorList.length >0){
      this.toast.warning(ErrorList.join(","), "Error")
      return false;
    }
  }

  public override Validators(): any {
    return {
      'Name': [Validators.required],
      'PolygonPoints': [Validators.required]
    };
  }

}
