<div class="modal is-active">
    <app-loader [Parent]="this"></app-loader>
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Please provide the following...</p>
      </header>
      <section class="modal-card-body"style="min-width: 800px;">
        <div>
            <div *ngFor="let field of Row.RequestedFields">
                <app-field [Label]="getFieldDisplayName(field)">
                    <div class="control select" *ngIf="getFieldType(field) == 'ParkingLot'">
                        <select class="select input" (change)="mappingChanged($event)" id="{{field}}" [ngModel]="fieldMapping.get(field) || ''">
                            <option value="" disabled hidden>Select a value...</option>
                            <option *ngFor="let lot of parkingLots" value="{{lot.Id}}">{{lot.Name}}</option>
                        </select>
                    </div>

                    <div class="control select" *ngIf="getFieldType(field) == 'RateSet'">
                        <select class="select input" (change)="mappingChanged($event)" id="{{field}}">
                            <option selected disabled hidden>Select a value...</option>
                            <option *ngFor="let set of ratesets" value="{{set.Id}}">{{set.Name}}</option>
                        </select>
                    </div>

                    <div class="control" *ngIf="getFieldType(field) == 'Text'">
                        <input class="input" (change)="mappingChanged($event)" id="{{field}}" [ngModel]="fieldMapping.get(field) || ''" type="text" placeholder="Enter a value..." />
                    </div>

                    <div class="control" *ngIf="getFieldType(field) == 'Date'">
                        <input class="input" (change)="mappingChanged($event)" id="{{field}}" [ngModel]="fieldMapping.get(field) || ''" type="datetime-local" placeholder="Select a value..." />
                    </div>
                </app-field>  
                <br/>
            </div>
        </div>

      </section>
      <footer class="modal-card-foot">
        <button class="button is-danger" (click)="cancel()">Cancel</button>
        <button class="button is-success" (click)="submit()">Submit</button>
      </footer>
    </div>
  </div>