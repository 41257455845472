<app-loader [Parent]="this"></app-loader>
<app-pageheader Title="Plate Character Mapping Matrix ">
    <app-form-save-button [Parent]="this" [Form]="Form" class="button is-primary" (OnSave)="Save()"></app-form-save-button>
</app-pageheader>

<table class="table table-striped mb-6" *ngIf="CharArray != null" style="margin-left: auto; margin-right: auto;">
    <tr>
        <th rowspan="38" style="text-align: center; font-size: 0.9em; writing-mode:vertical-rl">
            CAMERA PLATE CHARACTER
        </th>
        <th colspan="37" style="text-align: center; font-size: 0.9em;;">
            STORED PLATE CHARACTER
        </th>
    </tr>
    <tr>
        <th>
        </th>
        <th *ngFor="let char of Chars"  [ngClass]="{ 'highlight' :highlightX==char }">
            {{char}}
        </th>
    </tr>
    <tr *ngFor="let char of Chars">
        <td  [ngClass]="{ 'highlight' : highlightY==char }">{{char}}</td>
        <td *ngFor="let char2 of Chars" (mouseover)="highlightX=char2; highlightY=char;" [ngClass]="{ 'highlight' : highlightX==char2 || highlightY==char || (highlightX == char && highlightY==char2) }">
            <input type="text" style="width: 2em;" [disabled]="char == char2" [(ngModel)]="CharArray[char][char2]" (change)="ValChanged(char, char2)" [ngStyle]="{ 'background-color': GetColor(char,char2) }">
        </td>
    </tr>
</table>