import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { FeaturesService } from 'src/app/Services/features.service';

@Component({
  selector: 'app-edituserpermission',
  templateUrl: './edituserpermission.component.html',
  styleUrls: ['./edituserpermission.component.scss']
})
export class EdituserpermissionComponent extends SimpleModalComponent<any, any> implements OnInit {
  Form !: UntypedFormGroup;
  public Permissions : any[] | null = null;
  public PermissionLevels : any[] | null = null;
  constructor(private featuresService : FeaturesService) {
    super();
  }

  ngOnInit(): void {
    this.Permissions = this.featuresService.GetFeatures().filter((x : any) => x.GrantableEndUserPermissionLevels != null);
    if (this.Form == null){
      this.Form = new UntypedFormGroup({Permission: new UntypedFormControl(), PermissionLevel: new UntypedFormControl()});
    }
    else{
      this.PermissionChanged();
    }
  }

  public PermissionChanged(){
    if(this.Permissions == null){
      return;
    }
    let p = this.Form.get("Permission")?.value;
    let set = this.Permissions.filter(x => x.Type == p)[0];
    this.PermissionLevels = set.GrantableEndUserPermissionLevels;
  }

  public PermissionLevelChanged(){
    if(this.PermissionLevels == null){
      return;
    }
    let set = this.PermissionLevels.filter(x => x.Level == this.PermissionLevels)[0];
  }

  confirm() {
    this.result = this.Form;
    this.close();
  }
}