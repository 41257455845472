import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceBase } from './api.service.base';
import { ServiceBusClient} from '@azure/service-bus'
import { OrganizationsService } from './organizations.service';
import { PermissionsService } from './permissions.service';
import { LoginServiceBase } from '../auth/login.service.base';

@Injectable({
  providedIn: 'root'
})

export class SignsService {

  private Lookup : any = {};
  public SignValueChanged = new EventEmitter<any>();
  public CurrentConnectionString : string = "";
  private Client? : ServiceBusClient;

  constructor(private apiService : ApiServiceBase, private organizationsService : OrganizationsService, private loginService : LoginServiceBase, private permissionService : PermissionsService) { 
    loginService.OrganizationChanged.subscribe(result => {
      this.ConnectServiceBus(); //disconnect from previous bus and connect to new org
    });
    this.ConnectServiceBus();
  }

  private Update(data : SignResponse){
    console.log("Sign Service received update, " + data.Name + ", " + data.SignValue);
    if(data != null){
      this.SignValueChanged.emit(data);
    }
  }

  private ConnectServiceBus(){
    this.organizationsService.GetServiceBusListenerConnectionString().then((x: any) => {
      if(this.CurrentConnectionString == x){
        //same connection as before
        return;
      }
      if(this.Client != null){
        //if we were connected before then close
        this.Client.close();
      }
      if(x == null || x == ''){
        return; //can't connect with no connecton string
      }
  
     this.apiService.Post<any>("infrastructure/servicebus/topics/Signs/" + this.loginService.UserId() + this.permissionService.GetSessionIdentifier(), {}).then(result => {
      this.Client = new ServiceBusClient(x);
      const receiver = this.Client.createReceiver("Signs", this.loginService.UserId() + this.permissionService.GetSessionIdentifier());
      const SBMessageHandler = async (messageReceived:any) => {
        this.Update(<SignResponse>(messageReceived.body));
      };
      const SBErrorHandler = async (error:any) => {
        console.log(error);
      };
      receiver.subscribe({
        processMessage: SBMessageHandler,
        processError: SBErrorHandler
      });
     });
    });
  }

//   public GetOccupancy(structureId : string | null) : any | null{
//     var newpromise = new Promise<any>((resolve, reject) => {
//       if(structureId == '' || structureId == null){
//         if(this.LatestData != null)
//           resolve(this.LatestData);
//       }
//       if(structureId != null && this.Lookup[structureId] != null){
//         resolve(this.Lookup[structureId]);
//       }
//     });
//     return newpromise;
//   }
}

export class SignResponse implements IOccupancyValue{
  
  public Id! : string;
  public Name! : string;
  public SignValue! : number;
}

export interface IOccupancyValue{
  SignValue : number;
}
