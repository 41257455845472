import * as THREE from "three";
import { Mesh, Scene, Vector2, Vector3 } from "three";

export class PedestrianZoneMesh extends THREE.Line {
    constructor(scene : Scene, shape: any, offset: Vector3, scale: Vector2){
        super();
        let p = shape.PolygonPoints;
        
        let line = new THREE.Shape();
        line.moveTo(p[0][0] * scale.x + offset.x, -1 * p[0][1] * scale.y + offset.y);
        for (let i = 1; i < p.length; i++) {
            line.lineTo(p[i][0] * scale.x + offset.x, -1 * p[i][1] * scale.y + offset.y);
        }
        line.lineTo(p[0][0] * scale.x + offset.x, -1 * p[0][1] * scale.y + offset.y);
        
        
        const extrudeSettings = {
            steps: 1,
            depth: 0.00001,
            bevelEnabled: false
        };
        
        let geometry = new THREE.ExtrudeGeometry(line, extrudeSettings);
          
        const texture = new THREE.TextureLoader().load('/assets/textures/stripes.jpg');
        texture.format = THREE.RGBAFormat;
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.offset.set(0, 0.5);
        texture.repeat.set(100, 100);
        
        let material = new THREE.MeshLambertMaterial({ map: texture });
        material.transparent = true;
        material.color.set('#' + shape.FillColor);
        
        texture.needsUpdate = true;
        material.map = texture; // Update the material's map
        
        let mesh = new THREE.Mesh(geometry, material);
        this.geometry = geometry;
        this.material = material;
        mesh.translateZ(offset.z + 0.0001);
        
        this.type = "pedestrian-zone";
        
        scene.add(mesh);
    }
}