<div style="margin: 1em;">
    <div class="columns">
        <div class="column is-5">
            <div class="vertical-center totals">
                <h1>THIS WEEK</h1>
                <p>{{organizationService.OrgCurrencySymbol}}{{TotalAmount.toFixed(2)}}</p>
                <h2>TOTAL TRANSACTIONS</h2>
                <p>{{TotalTransactions}}</p>
            </div>
        </div>
        <div class="column is-6">
            <div class="has-text-centered">REVENUE THIS WEEK</div>
            <canvas baseChart 
                height="100px"
                [data]="ChartData"
                [options]="ChartOptions"
                [type]="'bar'">
            </canvas>
        </div>
    </div>
</div>