import { AfterViewInit, ChangeDetectorRef, Component, ContentChild, EventEmitter, Host, Input, OnChanges, OnInit, Optional, Output, Self, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { ControlContainer, FormArray, FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, FormBuilder, FormGroupDirective } from '@angular/forms';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { AddUserComponent } from 'src/app/features/users/users/modals/add-user/add-user.component';
import { ModelSelectorComponent } from 'src/app/shared/modals/ModelSelector/modelselector.component';
import { ModalService } from 'src/app/Services/modal.service';
import { write } from 'fs';
import { ToggleSwitchComponent } from '../toggleswitch/toggleswitch.component';

@Component({
  selector: 'app-selectortaglist',
  templateUrl: './selectortaglist.component.html',
  styleUrls: ['./selectortaglist.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectorTaglistComponent),
      multi: true,
    },
  ],
})
export class SelectorTaglistComponent implements OnInit, ControlValueAccessor {
  public modalService: SimpleModalService;

  @Input()
  public IsMultiSelect: boolean = false;

  @ContentChild(ToggleSwitchComponent) toggleSwitchComponent!: ToggleSwitchComponent;

  @Input()
  public AddItemApi: string = '';

  @Input()
  public ModelType: string = '';

  @Input()
  public FollowsCheckbox: boolean = false;

  @Input() MapProperties : [string[], string[]] = [['Id','Id'], ['Name', 'Name']];

  @Input() itemsDisplayProperty: string = 'Name';

  @Input() public formArrayName !: string; // Name of the FormArray in the parent FormGroup

  public formArray: FormArray | undefined;

  constructor(private formBuilder: FormBuilder, private changeDetector: ChangeDetectorRef, @Optional() @Host() private formGroupDirective: FormGroupDirective, modalService: SimpleModalService) 
  {
    this.modalService = modalService;
  }

  writeValue(value: any): void {
    if (this.formArray) {
      this.formArray.patchValue(value);
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnInit() {
    if (this.formGroupDirective && this.formArrayName) {
      this.formArray = this.formGroupDirective.form.get(this.formArrayName) as FormArray;
    }
  }

  public AddItem() {

    var results = this.formArray?.value;

    //map items to results view
    if(results != undefined){
      results.forEach((result: any) => {
        this.MapProperties.forEach((mapProperty) => {
          result[mapProperty[1]] = result[mapProperty[0]];
        });
      });
    }
  
    this.modalService
      .addModal(ModelSelectorComponent, {
        ApiUrl: this.AddItemApi,
        ModelType: this.ModelType,
        MultiSelect: this.IsMultiSelect,
        SelectedItems: this.formArray?.value,
      })
      .subscribe((results) => {
        if (results != null) {
          this.formArray?.clear();
          results.forEach((result: any) => {
            const value: { [x: string]: any } = {};
            this.MapProperties.forEach((mapProperty) => {
              value[mapProperty[0]] = result[mapProperty[1]];
            });
            const newFormControl = new FormControl(value);
            this.formArray?.push(newFormControl);
          });
          this.formArray?.markAsDirty();
          this.changeDetector.detectChanges();
        }
      });
  }

  onChange: any = () => {};
  onTouched: any = () => {};
}