<app-pageheader [Parent]="this">
</app-pageheader>
<app-loader [Parent]="this"></app-loader>

<div class="columns is-fullwidth">
  <div class="column is-half">
    <article class="panel">
      <p class="panel-heading">Search for issues</p>
      <div class="panel-block">
        <p class="label">
          Start Date
        </p>
        <p class="value">
          <input class="input" [(ngModel)]="StartDate" type="date" [disabled]="UseLastStatementRange"/>
        </p>
      </div>
      <div class="panel-block">
        <p class="label">
          End Date
        </p>
        <p class="value">
          <input class="input" [(ngModel)]="EndDate" type="date" [disabled]="UseLastStatementRange"/>
        </p>
      </div>
      <div class="panel-block" tippy="You can check this to use the dates from the last uploaded statement, automatically">
        <p class="label">
          Use last statement's dates
        </p>
        <p class="value">
          <app-toggleswitch [(ngModel)]="UseLastStatementRange"></app-toggleswitch>
        </p>
      </div>
      <div class="panel-block" style="width: 100%">
        <span class="has-text-centered" style="width: 100%">
          <button class="button is-primary" (click)="getIssues()"><i class="fa-solid fa-magnifying-glass"></i>&nbsp;&nbsp;Find Issues</button>
        </span>
      </div>
    </article>
  </div>
  <div class="column is-half">
    <article class="panel">
      <p class="panel-heading">
        Search Summary
      </p>
      <div class="panel-block">
        <p class="label">Total to Resolve</p>
        <p class="value">{{TotalToReconcile ?? "--"}}</p>
      </div>
      <div class="panel-block">
        <p class="label">Duplicates</p>
        <p class="value">{{DuplicateCount ?? "--"}}</p>
      </div>
      <div class="panel-block">
        <p class="label">&nbsp;</p>
        <p class="value">&nbsp;</p>
      </div>
      <div class="panel-block">
        <p class="label">&nbsp;</p>
        <p class="value">&nbsp;</p>
      </div>
      <div class="panel-block">
        <p class="label">&nbsp;</p>
        <p class="value">&nbsp;</p>
      </div>
      <div class="panel-block">
        <button class="button is-primary is-hidden" hidden>&nbsp;</button>
      </div>
    </article>
  </div>
</div>

<table class="table is-fullwidth" *ngIf="ActionItems.length > 0">
  <thead>
      <tr>
          <th class="column-half">Issue</th>
          <th class="column-half">Resolutions</th>
      </tr>
  </thead>
  <tbody>
      <tr *ngFor="let item of ActionItems">
        <td colspan="2">
          <span *ngFor="let card of item.Cards" class="columns is-fullwidth">
            <span class="column is-half">
              <article class="panel">
                <p class="panel-heading">{{card.CardTitle}} (<a href="parking/parking/sessions/{{card.ParkingSessionId}}" target="_blank">Session {{card.ParkingSessionId}}</a>)</p>
                <p class="panel-block">
                  <span class="label">Account Transaction</span>
                  <span class="value">{{card.AccountTransactionId}}</span>
                </p>
                <p class="panel-block">
                  <span class="label">External ID</span>
                  <span class="value">{{card.TransactionId}}</span>
                </p>
                <p class="panel-block">
                  <span class="label">Reference</span>
                  <span class="value">{{card.TransactionReference}}</span>
                </p>
                <p class="panel-block">
                  <span class="label">PaidFrom</span>
                  <span class="value">{{card.PaidFromLocalString}}</span>
                </p>
                <p class="panel-block">
                  <span class="label">Paid To</span>
                  <span class="value">{{card.PaidToLocalString}}</span>
                </p>
              </article>
            </span>
            <span class="column is-half">
              <button *ngFor="let action of card.Actions" (click)="doAction(action)" class="button is-{{action.ButtonColor}} is-fullwidth">
                <i class="fa-regular fa-{{action.IconName}}" style="padding-right: 0.75rem;"></i>
                {{action.DisplayName}}
              </button>
            </span>
          </span>
        </td>
      </tr>
  </tbody>
</table>
