<app-loader [Parent]="this"></app-loader>
<form [formGroup]="Form">
    <app-pageheader [Parent]="this">
    </app-pageheader>

    <article class="panel">
        <div class="columns">
            <div class="column is-6">
                <app-field Label="Current Password">
                    <div class="control">
                        <input formControlName="CurrentPassword" class="input" type="password" />
                        <app-validator [For]="Form.get('CurrentPassword')"></app-validator>
                    </div>
                </app-field>
                <app-field Label="New Password">
                    <div class="control">
                        <input formControlName="UpdatedPassword" class="input" type="password" />
                        <app-validator [For]="Form.get('UpdatedPassword')"></app-validator>
                    </div>
                </app-field>
                <app-field Label="Confirm Password">
                    <div class="control">
                        <input formControlName="ConfirmPassword" class="input" type="password" />
                        <app-validator [For]="Form.get('ConfirmPassword')"></app-validator>
                    </div>
                </app-field>
                <app-field>
                    <app-form-save-button [Parent]="this" [Form]="Form" (click)="trySave()"></app-form-save-button>
                </app-field>

                <div class="has-text-centered is-danger" *ngIf="ErrorMessage != ''">
                    <p>{{ErrorMessage}}</p>
                </div>
            </div>
        </div>
    </article>
</form>