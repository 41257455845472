import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-editcontrolledareaterminal',
  templateUrl: './editcontrolledareaterminal.component.html',
  styleUrls: ['./editcontrolledareaterminal.component.scss']
})
export class EditcontrolledareaterminalComponent extends SimpleModalComponent<any, null> {
  Form !: UntypedFormGroup;
  constructor() {
    super();
  }

  confirm() {
    this.close();
  }
}
