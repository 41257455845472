<app-loader [Parent]="this"></app-loader>
<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Add Vehicle</p>
        </header>
        <form [formGroup]="Form">
            <section class="modal-card-body" style="min-width: 800px;">
                
                <app-field Label="Vehicle Make">
                    <div class="control">
                        <select class="input" formControlName="MakeId">
                            <option value="" disabled selected>Select a Make</option>
                            <option *ngFor="let x of Makes" [value]="x.Id">{{x.Name}}</option>
                        </select>
                    </div>
                </app-field>

                <app-field Label="Color">
                    <div class="control">
                        <select class="input" formControlName="ColorId">
                            <option value="" disabled selected>Select a Color</option>
                            <option *ngFor="let x of Colors" [value]="x.Id">{{x.Name}}</option>
                        </select>
                    </div>
                </app-field>

                <app-field Label="Plate Number">
                    <div class="control">
                        <input type="text" class="input" formControlName="PlateNumber"
                            placeholder="Plate Number">
                        <app-validator [For]="Form.get('PlateNumber')"></app-validator>
                    </div>
                </app-field>
            </section>


            <footer class="modal-card-foot">
                <app-form-save-button [Parent]="this" [Form]="Form" (OnSave)="addVehicle()" [ButtonContext]="'Add Vehicle'" [Icon]="'plus'"></app-form-save-button>
                <button class="button ml-3" (click)="cancel()">Cancel</button>
            </footer>

        </form>
    </div>

</div>