<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Addons</p>
      </header>
      <section class="modal-card-body" style="max-width: 128em;">
      <div class="flex-container">
        <div class="card addon" *ngFor="let addon of addons; let i = index;">
          <div class="columns" style="height:6em;">
            <div class="column is-2">
              <div class="logo" style="text-align:center; font-size: xx-large; "><i [ngClass]="'Icon fa fa-' + addon.IconName" style="width:100%em !important; padding:15px;" [ngStyle]="{'color': '#' + addon.Color}"></i></div>
            </div>
            <div class="column is-10" style="padding:1.5em;">
              <div class="header" style="text-align:left; font-size: xx-large;"><h1>{{addon.Name}}</h1></div>
              <div class="header" style="text-align:left; font-size: medium;"><h1>{{addon.Description}}</h1></div> 
            </div>
          </div>
          <div class="flex-container" *ngIf="addon.ClassName === 'ConfigurableValetAddon'">
            <div class="card addon option" *ngFor="let option of addon.Options; let i = index;" [ngStyle]="{ 'box-shadow': checkIfValetAddonIdExists(addon.Id, option.Id) ? '4px 4px 6px 6px rgba(0, 128, 0, 0.5)' : ''}">
              <div style="display: inline-flex; width:100%;">
                <div class="optionbutton">
                  <input type="checkbox" [id]="'custom-checkbox-' + option.Description" class="hidden-checkbox" [checked]="checkIfValetAddonIdExists(addon.Id, option.Id)" (change)="AddAddon(addon.Id, option.Id, $event)">
                  <label [for]="'custom-checkbox-' + option.Description" class="custom-checkbox-label"></label>
                </div>
                <div class="optiontext" style="width:80%;">
                  <div style="text-align:center; font-size: xx-large; font-weight: 500;"><h1 [ngStyle]="{'color': checkIfValetAddonIdExists(addon.Id, option.Id) ? 'green': 'black'}">{{orgService.OrgCurrencySymbol}}{{option.Amount.toFixed(2)}}</h1></div>
                  <div style="text-align:center;"><h1 [ngStyle]="{'color': checkIfValetAddonIdExists(addon.Id, option.Id) ? 'green': 'black'}">{{option.Description}}</h1></div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-container" *ngIf="addon.ClassName === 'BasicValetAddon'">
            <div class="card addon option" [ngStyle]="{ 'box-shadow': checkIfValetAddonIdExists(addon.Id, null) ? '4px 4px 6px 6px rgba(0, 128, 0, 0.5)' : '' }">
              <div style="display: inline-flex; width:100%;">
                <div class="optionbutton">
                  <input type="checkbox" [id]="'custom-checkbox-' + addon.Name" class="hidden-checkbox" [checked]="checkIfValetAddonIdExists(addon.Id, null)" (change)="AddAddon(addon.Id, null, $event)">
                  <label [for]="'custom-checkbox-' + addon.Name" class="custom-checkbox-label"></label>
                </div>
                <div class="optiontext" style="width:80%;">
                  <div style="text-align:center; font-size: xx-large; font-weight: 500;"><h1 [ngStyle]="{'color': checkIfValetAddonIdExists(addon.Id, null) ? 'green': 'black'}">{{orgService.OrgCurrencySymbol}}{{addon.Amount.toFixed(2)}}</h1></div>
                  <div style="text-align:center;"><h1 [ngStyle]="{'color': checkIfValetAddonIdExists(addon.Id, null) ? 'green': 'black'}">Fixed Price</h1></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary" (click)="confirm()">Confirm</button>
      </footer>
    </div>
  </div>