import { Component, OnInit } from '@angular/core';
import { LoginServiceBase } from '../login.service.base';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-login-status',
  templateUrl: './login-status.component.html',
  styleUrls: ['./login-status.component.scss']
})
export class LoginStatusComponent implements OnInit {

  constructor(public loginService: LoginServiceBase) { }

  ngOnInit(): void {
  }

  public Logout() {
    this.loginService.DoLogout();
  }
}
