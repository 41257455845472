import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginServiceBase } from 'src/app/auth/login.service.base';
import { RevenueService } from '../Services/revenue.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private loginService: LoginServiceBase, private router: Router) { }

  ngOnInit(): void {
    if(this.loginService.RequiresPasswordReset)
      this.router.navigate(['myaccount/changepassword']);
  }

}
