import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { OrganizationsService } from 'src/app/Services/organizations.service';

@Component({
  selector: 'app-editemail',
  templateUrl: './editemail.component.html',
  styleUrls: ['./editemail.component.scss']
})
export class EditEmailComponent extends ModelEditor implements OnInit {

  public Body = "";
  public MobileNotificationBody = "";
  public AllowDelete = false;
  public EmailTypes: any[] = [];
  public CommunicationCategories: any[] = [];

  constructor(private route: ActivatedRoute, private apiService: ApiServiceBase, private injector: Injector) {
    super("communications/emailtemplates", injector);

    this.apiService.Get<any>("communications/emailtypes").then(result => {
      this.EmailTypes = result;
    });

    this.apiService.Get<any>("communications/communicationcategories").then(result => {
      this.CommunicationCategories = result;
    });
  };

  public override DefaultModel(): any {
    return {
      EmailName: "",
      Subject: "",
      Body: "",
      TemplateType: "",
      Enabled: true,
      FromAddress: "",
      MobileNotificationEventName: null,
      MobileNotificationBody: "",
    };
  }

  public override AfterModelLoaded(): void {
    this.Body = this.Form.get('Body')?.value;
    this.MobileNotificationBody = this.Form.get('MobileNotificationBody')?.value;

    if (this.route.snapshot.params['id'].charAt(0) != 'm') {
      this.AllowDelete = true;
    }
  }

  public override BeforeSave(): boolean | void {
  }

  public override Validators(): any {
  }

  public override AfterSave(): boolean | void {
    this.ModelId = this.Model.Id;
    this.router.navigate(["/settings/notificationtemplates", this.Model.Id, "edit"])
  }

  public BodyChanged(event: any) {
    // Update form and mark as such
    this.Form.get('Body')?.setValue(event);
    this.Form.get('Body')?.markAsDirty();
  }

  public MobileBodyChanged(event: any) {
    // Update form and mark as such
    this.Form.get('MobileNotificationBody')?.setValue(event);
    this.Form.get('MobileNotificationBody')?.markAsDirty();
  }

  public TypeChanged(event: any) {
    this.Form.get('TemplateType')?.setValue(event.target.value);

    if(event.target.value == "Layout") {
      this.Form.get('Layout')?.setValue(1)
    }
  }

  public CategoryChanged(event: any) {
    this.Form.get('CommunicationCategory')?.setValue(event.target.value);
  }


  public LayoutChanged(event: any) {
    this.Form.get('Layout')?.setValue(event.target.value);
  }
}
