import { Component, Injector, OnInit } from '@angular/core';
import { ModalService } from 'src/app/Services/modal.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { LoginService } from 'src/app/auth/login.service';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiServiceBase } from 'src/app/Services/api.service.base';

@Component({
  selector: 'app-editparkinglot',
  templateUrl: './editparkinglot.component.html',
  styleUrls: ['./editparkinglot.component.scss']
})
export class EditparkinglotComponent extends ModelEditor implements OnInit {

  public MapItems: any[] = [];
  public ThresholdSets: any[] = [];
  public RateSets: any[] = [];
  public resetTimeSlots: any[] = [{ v: 0, k: '12 AM' }, { v: 1, k: '1 AM' }, { v: 2, k: '2 AM' }, { v: 3, k: '3 AM' }, { v: 4, k: '4 AM' }, { v: 5, k: '5 AM' }, { v: 6, k: '6 AM' }, { v: 7, k: '7 AM' },
  { v: 8, k: '8 AM' }, { v: 9, k: '9 AM' }, { v: 10, k: '10 AM' }, { v: 11, k: '11 AM' }, { v: 12, k: '12 PM' }, { v: 13, k: '1 PM' }, { v: 14, k: '2 PM' }, { v: 15, k: '3 PM' }, { v: 16, k: '4 PM' }, { v: 17, k: '5 PM' },
  { v: 18, k: '6 PM' }, { v: 19, k: '7 PM' }, { v: 20, k: '8 PM' }, { v: 21, k: '9 PM' }, { v: 22, k: '10 PM' }, { v: 23, k: '11 PM' }];

  constructor(private apiService: ApiServiceBase, private organizationsService: OrganizationsService, private injector: Injector) {
    super("infrastructure/parkinglots", injector, 'lotid');
    this.Loading()
    this.apiService.Get<any>("infrastructure/thresholdsets").then(result => {
        this.ThresholdSets = result;
      });

    this.apiService.Get<any>("parking/ratesets").then(result => {
      this.RateSets = result;
        this.StopLoading()
      });
  }

  public override DefaultModel(): any {
    return new Promise((resolve, reject) => {
      this.organizationsService.GetOrganizationDefaultPolygon().then(x => {
        resolve({ Name: "New Parking Lot", TotalSpaces: 100, Levels: [{ LevelNumber: 0, Name: "Level 1", TotalSpaces: 100 }], GeoPolygonPoints : x, 
        CountMode: 0, Closed: false, ResetMode: 0, ParkingThresholdSetId: null, Type: 0, EnableGlobalDirectory: false,});
      });
    })
  }
  public override AfterModelLoaded(): void {
    for (let l of this.Model.Levels) {
      this.MapItems.push(l.GeoPolygonPoints);
      if (l.Rows != null) {
        for (let r of l.Rows) {
          this.MapItems.push(r.GeoPolygonPoints);
          if (r.Spaces != null) {
            for (let s of r.Spaces) {
              this.MapItems.push(s.GeoPolygonPoints);
            }
          }
        }
      }
    }
  }
  public override BeforeSave(): void {

  }
  public override Validators(): any {
    return {
      'Name': [Validators.required],
      'PolygonPoints': [Validators.required]
    };

  }

  public TabChanged(index: number) {

  }

  public AddLevel() {
    let newLevel = { LevelNumber: 0, Name: "Level " + (this.FormArray(this.Form, "Levels").controls.length + 1), TotalSpaces: 0 };
    this.AddToFormArray(this.FormArray(this.Form, "Levels"), newLevel, "Levels");
  }

  public EditLevel(level: UntypedFormGroup | AbstractControl) {
    let levelId = level.get("Id")?.value;
    this.router.navigate(["parking/parking/lots/", this.ModelId, "levels", levelId]);
  }

  public EditLevelDesign(level: UntypedFormGroup | AbstractControl) {
    let levelId = level.get("Id")?.value;
    this.router.navigate(["parking/parking/lots/", this.ModelId, "levels", levelId, "design", "edit"]);
  }

  private externalCountProviders: any[] | null = null;
  private fetchingProviders = false;
  private fetchProvidersFailed = false;

  GetExternalCountProviders() {
    if (this.externalCountProviders == null && !this.fetchingProviders && !this.fetchProvidersFailed) {
      this.fetchingProviders = true;
      this.Loading();
      this.apiService.Get<any>("integrations/countproviders/options").then(result => {
        this.externalCountProviders = result.ProviderOptions;
      }).catch(onreject => 
        { 
          this.fetchProvidersFailed = true;
          this.toast.error("Failed to fetch provider options");
        }
      )
      .finally(() => { this.StopLoading(); this.fetchingProviders = false; });
    }

    return this.externalCountProviders;
  }

  GetExternalCountSources(providerName: string) {
    var provider = this.externalCountProviders?.filter(x => x.Name == providerName)[0];
    return provider?.Options;
  }
}
