import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/auth/login.service';
import { Busyable } from 'src/app/shared/editors/busyable';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { ApiService } from 'src/app/Services/api.service';
import { MediaService } from 'src/app/Services/media.service';

@Component({
  selector: 'app-spacetype',
  templateUrl: './spacetype.component.html',
  styleUrls: ['./spacetype.component.scss']
})

export class SpacetypeComponent extends Busyable implements OnInit {
  public ModelId : any;
  public Model: any;
  public Filters: any;

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Location", "LocationString")
  ];


  constructor(mediaService: MediaService, private loginService: LoginService, private apiService: ApiService, private parentRoute: ActivatedRoute) {
      super();
  }

  ngOnInit(): void {
    this.Loading();
    this.parentRoute.params.subscribe(params => { this.ModelId = params['id']; });
    this.apiService.Get<any>("infrastructure/spacetypes/" + this.ModelId).then(result => {
        this.Model = result;
        this.StopLoading();
    });
  }
}