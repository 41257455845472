import { AfterContentInit, AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { ClassedViewColumn, CurrencyViewColumn, CustomTextViewColumn, DataListFilterColumn, DatalastviewTextAndConditionalSuffixColumn, Datalistviewcolumn, IconViewColumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { DatalistviewComponent } from 'src/app/shared/datalistview/datalistview.component';

@Component({
  selector: 'app-valetaddons',
  templateUrl: './valetaddons.component.html',
  styleUrls: ['./valetaddons.component.scss']
})
export class ValetAddonsComponent{

  public OrgService !: OrganizationsService;
  public Columns !: Datalistviewcolumn[];

  constructor(organizationService: OrganizationsService, private route: ActivatedRoute) { 
    this.OrgService = organizationService;

    this.Columns = [
      new IconViewColumn(undefined, "IconName", "Color"),
      new Datalistviewcolumn("Name", "Name"),
      new CurrencyViewColumn("Amount", "Amount", this.OrgService),
      new CustomTextViewColumn(undefined, "OptionsCount", "Has {0} pricing options", undefined, true),
      new CustomTextViewColumn(undefined, "DefaultExtraTime", "Requires {0} minutes - Default", "RequiresExtraTime")
    ];
  }
}
