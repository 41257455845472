import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ApiService } from 'src/app/Services/api.service';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { catchError, ObservableInput, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { formatDate } from '@angular/common';
import { LoginService } from 'src/app/auth/login.service';

@Component({
  selector: 'app-runreport',
  templateUrl: './runreport.component.html',
  styleUrls: ['./runreport.component.scss']
})
export class RunreportComponent extends Busyable implements OnInit {

  public Report: any = {};
  public ReportRequest: any = {
    ReportTarget: "BrowserReportTarget",
    Parameters: [],
    TargetParameters: []
  };
  public ResultMode: string = "BrowserReportTarget";
  public Output: SafeHtml | null = null;
  public ReportLoading: Busyable = new Busyable();

  constructor(private apiService: ApiServiceBase, private route: ActivatedRoute, private sanitizer: DomSanitizer, private toastr: ToastrService, public loginService: LoginService) {
    super();
  }

  ngOnInit(): void {
    this.Refresh();

  }

  public Refresh() {
    this.Loading();
    let reportId = this.route.snapshot.params['id'];

    this.apiService.Get<any>("reporting/reports/" + reportId).then(result => {
      this.Report = result;
      this.StopLoading();
    });
  }

  public RunReport() {
    this.ReportRequest.Parameters = [];
    for (let p of this.Report.Parameters) {

      if(p.Name == 'orgId'){
        p.Value = this.loginService.CurrentOrganizationId(); 
      }

      var val = p.Value;
      if(p.Type == 'MultiSelect') {
        val = val.join(",");
      }

      this.ReportRequest.Parameters.push({ Name: p.Name, Value: val, Type: p.Type });
    }

    this.ReportLoading.Loading();
    this.Output = null;
    let reportId = this.route.snapshot.params['id'];
    this.ResultMode = this.ReportRequest.ReportTarget;
    this.apiService.Post<any>("reporting/reports/" + reportId + "/generate", this.ReportRequest).then(result => {
      if (result.Html != null) {
        this.Output = this.sanitizer.bypassSecurityTrustHtml(result.Html);
      }
      else if (this.ReportRequest.ReportTarget == "CsvReportTarget"){
        const file = new Blob([result.Csv], {type: 'text/csv'});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(file);
        link.download = this.Report?.Name + "_" + formatDate(new Date(), 'dd-MM-yyyy_hh-mm-ss', 'en-us');
        link.click();
      }
      else if (result.PdfBytes != null) {
        this.Output = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64, " + result.PdfBytes);;

      }
      this.ReportLoading.StopLoading();
    }).catch(rejected => {
      this.HandleApiError(rejected);
    });
  }

  public HandleApiError(err: any): ObservableInput<any> {
    this.ReportLoading.StopLoading();
    this.toastr.error(err.error.Error.Message, "Error");
    return throwError(() => err);
  }
}
