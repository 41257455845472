import { Component } from '@angular/core';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { StructureViewerLayer } from 'src/app/shared/structureviewermaps/structureviewerlayers';

@Component({
  selector: 'app-guidancelights',
  templateUrl: './guidancelights.component.html',
  styleUrls: ['./guidancelights.component.scss']
})
export class GuidanceLightsComponent {
  constructor() { }

  ngOnInit(): void {
  }
  public Subsets: string[] = [];

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Id", "Id"),
    new Datalistviewcolumn("HID", "ChipId"),
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Location", "LocationString"),
    
  ];

  public StructureViewerLayers: StructureViewerLayer[] = [new StructureViewerLayer("ParkingLot", null, false, true), new StructureViewerLayer("ParkingLevel"), new StructureViewerLayer("ParkingSpace"), new StructureViewerLayer("GuidanceLightConfiguration", "Guidance Lights") ]
}
