import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ModalBaseComponent } from 'src/app/shared/modals/modal-base/modal-base.component';

@Component({
  selector: 'app-hardwaremessagemodal',
  templateUrl: './hardwaremessagemodal.component.html',
  styleUrls: ['./hardwaremessagemodal.component.scss']
})
export class hardwaremessagemodalComponent extends ModalBaseComponent<any, null> implements OnInit, AfterViewInit {
  
  public Model: any;
  public ModelId: any;

  constructor(private apiService: ApiServiceBase) {
    super();
  }
  
  ngAfterViewInit(): void {
    setTimeout(() => {
      (document.querySelector('.modal-background') as HTMLElement).style.opacity = '1';
    }, 1);
  }

  ngOnInit(): void {
    this.apiService.Get<any>("messaging/hardwaremessages/" + this.ModelId).then(result => {
      this.Model = result;
    });
  }

  }
