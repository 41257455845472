import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';
import { Busyable } from 'src/app/shared/editors/busyable';
@Component({
  selector: 'app-firmwareselector',
  templateUrl: './firmwareselector.component.html',
  styleUrls: ['./firmwareselector.component.scss']
})
export class FirmwareselectorComponent extends SimpleModalComponent<any, any> implements OnInit {

  public HardwareVersionId: string = "";
  public FirmwareVersions: any[] = [];
  public Busyable: Busyable = new Busyable();

  constructor(private apiService: ApiServiceBase) {
    super();
  }

  ngOnInit(): void {
    this.Busyable.Loading();
    this.apiService.Get<any>("/hardware/versions/" + this.HardwareVersionId + "/firmware").then(result => {
      this.Busyable.StopLoading();
      this.FirmwareVersions = result;
    });
  }

  public SelectVersion(version: any) {
    this.result = version;
    this.close();
  }

}
