import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ApiServiceBase } from 'src/app/Services/api.service.base';

@Component({
  selector: 'app-usermagicmoney',
  templateUrl: './usermagicmoney.component.html',
  styleUrls: ['./usermagicmoney.component.scss']
})
export class UsermagicmoneyComponent extends SimpleModalComponent<any, any> implements OnInit {
  User: any = {
    OrgUserId: '',
    MagicMoney: 0,
    Description: ''
  };

  startRequesting: boolean = false;

  constructor(private apiService: ApiServiceBase) {
    super();
  }

  ngOnInit(): void {

  }
  confirm() {
    this.startRequesting = true;
    this.apiService.Post<any>("organization/users/magicmoney", this.User)
      .then(result => {
        this.startRequesting = false;
        this.result = result;
        this.close();
      });
  }
}
