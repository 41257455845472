import { Component, EventEmitter, Input, OnChanges, OnInit, Output, Self, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ApiServiceBase } from 'src/app/Services/api.service.base';

@Component({
  selector: 'app-guidancelight-selector',
  templateUrl: './guidancelight-selector.component.html',
  styleUrls: ['./guidancelight-selector.component.scss']
})
export class GuidanceLightSelectorComponent implements OnInit, ControlValueAccessor, OnChanges {

  public GuidanceLightChipId: string | null = null;
  @Output()
  public GuidanceLightIdChange = new EventEmitter<string | null>();

  public InitChipId = "";
  @Input()
  public GuidanceLight: any = {};

  public searchResults: any[] = [];
  public searchTerm: string = "ChipId";
  public isLoading = false;

  constructor(private apiService: ApiServiceBase, @Self() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.GuidanceLightChipId = this.GuidanceLight.ChipId;
    this.onChange(this.GuidanceLight.ChipId);
    this.GuidanceLightIdChange.emit(this.GuidanceLight);
  }

  public writeValue(id: string | null): void {
    console.log("Write value");

    this.GuidanceLightChipId = id;
    this.GuidanceLight.ChipId = id;
    if (this.GuidanceLightChipId == "") this.GuidanceLight = null;
  }
  public onChange = (id: string | null) => {
    this.GuidanceLightIdChange.emit(id);
   };
   
  public registerOnChange(fn: any): void {
    console.log("Reg on change");

    this.onChange = fn;
  }
  public onTouched = () => { };
  public registerOnTouched(fn: any): void {
    console.log("On Touched");

    this.onTouched = fn;
  }
  ngOnInit(): void {
  }


  public ItemSelected(item: any) {
    if (item && item != null && item.Id) {
      this.GuidanceLight = { Id: item.Id, ChipId: item.ChipId, OrganizationId: item.OrganizationId };
      this.GuidanceLightChipId = item.ChipId;
      this.onChange(item.ChipId);
      this.GuidanceLightIdChange.emit(this.GuidanceLight);
    }
  }
  public SearchChanged(val: string) {
    console.log("Car Counter Selector search event: '" + val + "'");
    if (val == null || val == "") return;
    this.GuidanceLight = null;
    this.isLoading = true;
    //"infrastructure/sensor/searches"
    this.apiService.Post<any>("hardware/guidancelights/searches", { PageNumber: 1, PageSize: 8, SearchTerm: val }).then(result => {
      this.searchResults = [];
      result.Results.forEach((element: any) => {
        this.searchResults.push({
          ChipId: element.ChipId.toString(),
          Id: element.Id,
          OrganizationId: element.OrganizationId
        });
      });

      console.log("Search results: ");

      console.log(this.searchResults);

      this.isLoading = false;
    });
  }
  public ClearGuidanceLight() {
    this.GuidanceLightChipId = null;
    this.onChange(null);
  }

  public Cleared() {
    this.GuidanceLightChipId = null;
    this.onChange(null);
  }
  public KeyPress(evt: any) {
    if (evt.keyCode == 13) {
      evt.stopPropagation();
    }
  }
  public InputKeyPress(evt: any) {

  }

}
