export class FabricHelpers{
    public static GetAdjustedXY(canvas: any, x: any, y: any) {
        // Get the current zoom level of the canvas
        const zoom = canvas.getZoom();
        
        // Get the current viewport transformation
        const viewportTransform = canvas.viewportTransform;

        if (viewportTransform) {
            const translateX = viewportTransform[4];
            const translateY = viewportTransform[5];

            // Adjust the coordinates according to the zoom level and viewport transformation
            const adjustedX = (x - translateX) / zoom;
            const adjustedY = (y - translateY) / zoom;

            return [adjustedX, adjustedY];
        }
        
        return [x, y]; // If no transformation, return the original coordinates
    }

    public static isPolygonInsidePolygon(innerPolygon: [number, number][], outerPolygon: [number, number][]): boolean {
        return innerPolygon.every(point => this.isPointInPolygon(point, outerPolygon));
    }

    public static isPointInPolygon(point: [number, number], polygon: [number, number][]): boolean {
        const [x, y] = point;
        let inside = false;
    
        for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
            const [xi, yi] = polygon[i];
            const [xj, yj] = polygon[j];
    
            const intersect = ((yi > y) !== (yj > y)) &&
                              (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }
    
        return inside;
    }


    public static CreatePointFromXY(canvas: any, x: number, y: number) {
        var zoom = canvas.getZoom();
        // Get the current viewport transformation
        var viewportTransform = canvas.viewportTransform;
        if (viewportTransform) {
            var translateX = viewportTransform[4];
            var translateY = viewportTransform[5];
            x = (x - translateX) / zoom;
            y = (y - translateY) / zoom;
            return [x / zoom, y / zoom]
        }
        return null;
    }
    
}