import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-treenodeselect',
  templateUrl: './treenodeselect.component.html',
  styleUrls: ['./treenodeselect.component.scss']
})
export class TreenodeselectComponent implements OnInit {


  @Input()
  public Text : string = "";
  @Input()
  public Image : string | null = null;
  @Input()
  public Expanded : boolean = false;
  @Input()
  public CanExpand : boolean = true;

  @Input() Selected : boolean = false;

  @Input() HasChildItemsSelected : boolean = false;

  @Input() Checked : boolean = false;
  @Input() HasCheckbox : boolean = true;

  @Input() NodeDisabled : boolean = false;

  @Input() SelectItems: any;

  @Input() SelectItemValue: any;
  @Input() TrailingInfo: any = "";

  @Input()
  public CanDelete : boolean = false;


  @Output() OnClicked = new EventEmitter<any>();
  @Output() OnSelectChange = new EventEmitter<any>();
  @Output() OnDoubleClicked = new EventEmitter<any>();
  @Output() OnDelete = new EventEmitter<any>();
  @Output() OnSelectedChange = new EventEmitter<any>();
  @Output() ChildItemsSelected = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  public Toggle(){
    this.Expanded = !this.Expanded;
  }

  public HandleClick(evt : any){
    this.OnClicked.emit(evt);
  }

  public CheckItem(){
    this.ChildItemsSelected.emit();
  }

  public HandleSelectedClick(evt : any){
    this.OnSelectChange.emit(evt);
  }

  public HandleSelectedChange(evt: any){
    this.OnSelectedChange.emit(evt);
  }

  public HandleDoubleClick(evt : any){
    this.OnDoubleClicked.emit(evt);
  }
  public HandleDelete(evt : any){
    this.OnDelete.emit();
  }
}