<app-pageheader Title="Email Templates">
  <add-button AddRoute="settings/notificationtemplates/new/edit"></add-button>
</app-pageheader>

<app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="communications/emailtemplates/searches" 
[AllowEdit]="true" EditUrl="settings/notificationtemplates/:id/edit">
  <app-filter-set>
    <div app-search-filter></div>
    <div app-enabled-filter></div>
    <div app-search-button></div>
  </app-filter-set>
</app-datalistview>