import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { BooleanCheckOrCrossViewColumn, CurrencyViewColumn, Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { Busyable } from 'src/app/shared/editors/busyable';

@Component({
  selector: 'app-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.scss']
})
export class StatementComponent extends Busyable implements OnInit {
  
  public ModelId: any;
  public Filters: any;
  public Model: any;
  
  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Id", "Id"),
    new CurrencyViewColumn("Amount", "Amount"),
    new Datalistviewcolumn("Transaction Id", "TransactionId"),
    new Datalistviewcolumn("Transaction Date", "TransactionDateLocalFriendlyString"),
    new Datalistviewcolumn("Settlement Date", "SettlementDateLocalFriendlyString"),
    new BooleanCheckOrCrossViewColumn("Reconciled", "Reconciled"),
    new BooleanCheckOrCrossViewColumn("Ignored", "Ignored"),
  ];

  public Subsets : Array<string> = [
    "Reconciled", "Unreconciled", "Ignored"
  ];
  
  constructor(private apiService: ApiServiceBase, private parentRoute: ActivatedRoute, public orgService: OrganizationsService) {
    super();
  }

  ngOnInit(): void {
    this.Loading();
    this.parentRoute.params.subscribe(params => { this.ModelId = params['id']; });
    this.apiService.Get<any>("accounting/reconciliation/statements/" + this.ModelId).then(result => {
      this.Model = result;
      this.StopLoading();
    });
  }
}
