import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { BusyableModalComponent } from '../../editors/busyableModalComponent';

@Component({
  selector: 'app-delete-check',
  templateUrl: './delete-check.component.html',
  styleUrls: ['./delete-check.component.scss']
})


export class DeleteCheckComponent extends BusyableModalComponent<any, any> implements OnInit {

  deleteCheckResult: any | null = null;

  public modelId!: string;
  public url: string = "";
  public name!: string;
  public SkipCheck: boolean = false;
  public deleteNavLink: string | null = null;
  public api!: string;
  public typeName!: string | null;
  public Error : string|null = null;

  constructor(private router: Router, private apiService: ApiService, private toastService: ToastrService) {
    super();
  }

  ngOnInit(): void {
    if(!this.SkipCheck) {
      this.apiService.Get('/' + this.api + '/deletecheck/' + this.typeName + '/' + this.modelId).then((result: any) => {
        this.deleteCheckResult = result;
      }, (error) => {
        this.Error = error.message;
        this.toastService.error(error.message, "Error");
      });
    }
    else {
      this.deleteCheckResult = { "CanDelete" : true };
    }

  }

  cancel() {
    this.result = false;
    this.close();
  }

  delete() {
    this.Loading();
    if(this.deleteCheckResult['CanDelete']) {
      this.apiService.Delete(this.url + this.modelId, {}).then((result: any) => {
        if(this.deleteNavLink != null && this.deleteNavLink != "") {
          this.router.navigate([this.deleteNavLink]);
        }
        this.toastService.success(result.ClassName + " deleted successfully");
        this.StopLoading();
        this.close();
      });
    }
  }
}
