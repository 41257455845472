import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }

  public isAdmin = false;
  public Columns: Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("First Name", "FirstName"),
    new Datalistviewcolumn("Last Name", "LastName"),
    new Datalistviewcolumn("Email", "Email")
  ];

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
        this.isAdmin = params["IsAdmin"] ? params["IsAdmin"] : false;
      }
    );
  }
}