import { EditcontrolledareabarrierComponent } from "src/app/features/parking/accesscontrol/controlledareas/edit/editcontrolledareabarrier/editcontrolledareabarrier.component";
import { LevelEditorComponent } from "../../leveleditor.component";
import { PointMapComponent } from "./PointMapComponent";

export class Barrier extends PointMapComponent{
    public override ClassName: string = "Barrier";
    public override ImageUrl: string = "/assets/leveleditor/barrier.png";
    public override ImageScale = 0.1;
    public override RequiresParent: boolean = true;
    public override ParentClassName: string = "Lane";

    constructor(leveleditorcomponent: LevelEditorComponent, canvas: any, formItem: any, parentFormItem: any, forceClone: boolean = false) {
        super(leveleditorcomponent, canvas, formItem, parentFormItem, forceClone);
        this.AddFabricItem();
    }

    public override Clone(formitem: any){
        return new Barrier(this.leveleditorcomponent, this.canvas, formitem, this.parentFormItem, true);
    }

    public override ShowModal(): void {
        this.leveleditorcomponent.modalService.addModal(EditcontrolledareabarrierComponent, { Form: this.formItem })
        .subscribe((result) => {
        });
    }
}
