import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  public Save(key: string, value: any) {
    let jsonValue = JSON.stringify(value);
    localStorage.setItem(key, jsonValue);
  }

  public Get(key: string) {
    let storedData = localStorage.getItem(key);
    if (storedData != null && storedData != '') {
      return JSON.parse(storedData);
    }
    return null;
  }

  public Clear(key: string) {
    localStorage.removeItem(key);
  }

  public ClearAll() {
    localStorage.clear();
  }
}
