<app-loader [Parent]="Busyable"></app-loader>
<div class="modal is-active" *ngIf="ParkingLevels.length !> 0">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Parking Level Vehicles Present</p>
      </header>
      <section class="modal-card-body" style="min-width: 800px; max-height: 80vh; overflow-y: auto;">
        <table class="table is-striped is-bordered is-fullwidth">
            <thead>
              <tr>
                <th>Name</th>
                <th>Vehicles Present</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let l of ParkingLevels; let i = index;">
                <td>{{l.Name}}</td>
                <td><input class="input" type="number" [value]="l.VehiclesPresent" (change)="UpdateCount($event, i)"></td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="99">
                  <button class="button is-primary is-outlined is-fullwidth" (click)="UpdateCounts()">Save</button>
                </td>
              </tr>
            </tfoot>
          </table>
      </section>
      <footer class="modal-card-foot">
      </footer>
    </div>
  </div>