import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';


@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }

  public userRoles = false;
  public Columns: Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("User Count", "UsersCount"),
    new Datalistviewcolumn("Role Permissions Count", "RolePermissionsCount"),
  ];

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
        this.userRoles = params["UserRoles"] != null ? params["UserRoles"] : false;
      }
    );
  }

  filterChanged(evt: any): void {
    this.userRoles = evt == 'true' ? true : false;
  }

}
