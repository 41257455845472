
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { LevelEditorComponent } from "../../leveleditor.component";
import { PolygonTool } from "./PolygonTool";
import { v4 as uuidv4 } from 'uuid';
import { Geo } from "src/app/util/geo";
import { PolygonMapComponent } from "../../components/PolygonComponents/PolygonMapComponent";
import { Lane } from "../../components/PolygonComponents/Lane";
import { FabricHelpers } from "../../fabric.js/helpers";

export class LaneTool extends PolygonTool{
    public override ItemWidth: number = 200;
    public override ItemHeight: number = 100;
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }

    public override CreateNew(canvasX : number, canvasY: number){
        const Gates = this.editor.MapItemsByType.get('Gate');
        if(Gates){
            for (let gate of Gates) {
                var points = FabricHelpers.GetAdjustedXY(this.canvas, canvasX, canvasY);
                if (Geo.GetPolygonPointBounds((gate as PolygonMapComponent).formItem.get('PolygonPoints')?.value).Contains([points[0] / this.ScaleFactor, points[1] / this.ScaleFactor])) {     
                    let lanes = this.editor.ModelEditor.FormArray(gate.formItem, "Lanes");
                    let newLaneFormGroup = new FormGroup({
                        Id: new FormControl(uuidv4()),
                        Name: new FormControl("Lane"),
                        LocalControllerId: new FormControl(null),
                        WorkflowId: new FormControl(),
                        Barriers: new FormControl([]),
                        Cameras: new FormControl([]),
                        PaymentTerminals: new FormControl([]),
                        Displays: new FormControl([]),
                        CellRouters: new FormControl([]),
                        TrafficLights: new FormControl([]),
                        PolygonPoints: new FormControl(this.CreatePolygonFromXY(canvasX, canvasY)),
                        EditorLocked: new FormControl(false)
                    });

                    (lanes as FormArray).push(newLaneFormGroup);

                    var component = new Lane(this.editor, this.canvas, newLaneFormGroup, gate.formItem);
                    this.editor.Form.markAsDirty();
                    return component;
                }
            }
        }
        this.editor.toastService.error("Please place your Lane within a Gate");
        return null;
    }
}