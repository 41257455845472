import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-editgatewayconfiguration',
  templateUrl: './editgatewayconfiguration.component.html',
  styleUrls: ['./editgatewayconfiguration.component.scss']
})

export class EditGatewayConfigurationComponent extends SimpleModalComponent<any, null>{
  Form !: UntypedFormGroup;

  public InitialGateway: any = {};

  constructor() {
    super();
  }

  confirm() {
    let formthing = this.Form.controls["ChipId"];
    this.close();
  }
}