<app-loader [Parent]="this"></app-loader>
<form #form *ngIf="Model != null">
    <app-pageheader [Parent]="this">
        <button class="button is-dark mr-3" (click)="ViewJson()">View Json</button>
        <app-form-delete-button *ngIf="ModelIsNew==false" [Parent]="this" [Form]="Form" [Model]="Model" [DeleteCheck]="false"
        DeleteUrl="infrastructure/localcontrollers/workflows/" DeleteNavLink="parking/accesscontrol/terminalconfigurations" Api="Infrastructure" TypeName="LocalControllerWorkflow"></app-form-delete-button>
        <app-form-save-button [Parent]="this" [Form]="this.form" (OnSave)="Save()">Save</app-form-save-button>
    </app-pageheader>

    <div class="columns">
        <div class="column is-6">
            <app-field Label="Name">
                <div class="control">
                    <input name="Name" class="input" type="text" [(ngModel)]="Model.Name" required />
                    <app-validator [For]="this.form['Name']" [ErrorDefs]="{ 'valueMissing' : 'Name is required' }">
                    </app-validator>
                </div>
            </app-field>
        </div>
        <div class="column is-4">
            <app-field Label="Default State">
                <div class="control">
                    <div class="select">
                        <select name="DefaultStateId" [(ngModel)]="Model.DefaultStateId">
                            <option *ngFor="let s of Model.States" [ngValue]="s.Id">{{s.Description}}</option>
                        </select>
                    </div>
                    <app-validator [For]="this.form['DefaultStateId']"
                        [ErrorDefs]="{ 'valueMissing' : 'Default State is required' }"></app-validator>
                </div>
            </app-field>
        </div>
    </div>

    <app-tabs>
        <app-tab Title="States">
            <div class="designer no-select">
                <div class="designer-inner" (mousemove)="DesignerMouseMove($event)" (mouseup)="DesignerMouseUp($event)"
                    (click)="DesignerClick($event)">
                    <button class="button is-primary add-state-button"
                        [ngClass]="{ 'is-warning' : IsAddingState == true }"
                        (click)="IsAddingState = true; $event.stopPropagation()" title="Add new state"><i
                            class="fa fa-plus"></i></button>
                    <svg class="transition-line">
                        <path *ngFor="let t of AllTransitions"
                            [ngClass]="{ 'is-active' : t.StateTransition == CurrentHighlightTransition }"
                            [attr.d]="t.PathValues[0].SVGPath" stroke="#007237" stroke-width="8" fill="none"
                            (mouseenter)="LineMouseEnter($event, t.StateTransition)"
                            (mouseleave)="LineMouseLeave($event, t.StateTransition)"
                            (click)="EditTransition(t.StateTransition)" />
                        <path *ngIf="CurrentDragExit != null" class="dragline" [attr.d]="CurrentDragExitLine"
                            stroke="black" stroke-width="1"></path>
                    </svg>
                    <div class="transition-label" *ngFor="let t of AllTransitions"
                        [ngClass]="{ 'is-active' : t.StateTransition == CurrentHighlightTransition }"
                        [ngStyle]="{ 'left' : t.PathValues[0].LabelPoint[0] + 'px', 'top' : t.PathValues[0].LabelPoint[1] + 'px'}"
                        (mouseenter)="LineMouseEnter($event, t.StateTransition)"
                        (mouseleave)="LineMouseLeave($event, t.StateTransition)">
                        <div (click)="EditTransition(t.StateTransition)" style="display: inline; ">
                            {{t.StateTransition.Event}}</div>
                        <div style="cursor: pointer; display: inline; padding-left: 10px;"><i class="fa fa-trash"
                                (click)="DeleteTransition(t.StateTransition)"></i></div>
                    </div>
                    <div class="state" *ngFor="let s of Model.States; let i = index;"
                        [ngStyle]="{ 'left' : s.LocationLeft + 'px', 'top' : s.LocationTop + 'px', 'width' : StateWidth + 'px', 'height' : StateHeight + 'px' }"
                        [ngClass]="{ 'is-default' : s.Id == Model.DefaultStateId}">
                        <div class="grab" (mousedown)="StateGrabMouseDown($event, s)"></div>
                        <div class="state-delete" (click)="DeleteState(i)" style="cursor: pointer;"><i
                                class="fa fa-trash"></i></div>
                        <div class="header m-2">
                            <input [name]="'StateName'+i" type="text" class="input is-small"
                                [(ngModel)]="s.Description" />
                        </div>


                        <div class="columns is-gapless mb-0">
                            <div class="column is-4 has-text-right">
                                <label class="label is-small mr-3" style="display: inline-block;">Display</label>
                            </div>
                            <div class="column is-8">
                                <span class="select is-small">
                                    <select [name]="'StateScreenId'+i" [(ngModel)]="s.ScreenId">
                                        <option *ngFor="let sc of Model.Screens" [value]="sc.Id">{{sc.Name}}</option>
                                    </select>
                                </span>
                            </div>
                        </div>
                        <div class="columns is-gapless">
                            <div class="column is-4 has-text-right">
                                <label class="label is-small mr-3" style="display: inline-block;">Events</label>
                            </div>
                            <div class="column is-8">
                                <a class="entryevents is link is-size-7" (click)="EditEvents(s)">&nbsp;
                                    <i class="fa fa-bolt mr-1" aria-hidden="true"></i>
                                    <span *ngIf="s.EntryEvents.length > 0">
                                        <span>{{s.EntryEvents.length}} Events </span>
                                        (<span>{{s.EntryEvents[0].Event}}</span><span *ngIf="s.EntryEvents.length > 1">
                                            + {{s.EntryEvents.length-1}} more</span>)
                                    </span>
                                    <a class="is-link" *ngIf="s.EntryEvents.length == 0">no events</a>
                                </a>
                            </div>
                        </div>
                        <div class="entrypoint" (mouseup)="EntryPointMouseUp($event, s)"
                            [ngClass]="{ 'is-active' : CurrentDragExit != null && CurrentDragExit != s }"></div>
                        <div class="exitpoint" (mousedown)="ExitPointMouseDown($event, s)"></div>
                    </div>
                </div>
            </div>
        </app-tab>
        <app-tab Title="Screens">
            <div class="columns">
                <div class="column is-3">

                    <nav class="panel is-primary">
                        <a class="panel-block" style="position: relative;"
                            *ngFor="let s of Model.Screens; let i = index;"
                            [ngClass]="{ 'is-active' : s == SelectedScreen }" (click)="ScreenSelected(s)">
                            <span class="panel-icon">
                                <i class="fa fa-book" aria-hidden="true"></i>
                            </span>
                            {{s.Name}}
                            <i class="fa fa-trash" style="position: absolute; right: 1em;"
                                (click)="DeleteScreen(i)"></i>
                        </a>
                        <div class="panel-block">
                            <button class="button is-primary is-fullwidth" (click)="AddScreen()">
                                Add New Screen
                            </button>
                        </div>
                    </nav>
                </div>
                <div class="column is-9">

                    <div class="field is-horizontal" *ngIf="SelectedScreen != null">
                        <div class="button" (click)="InsertMedia()"> Insert Media </div>
                        <div class="field-label is-normal">
                            <label class="label">Name</label>
                        </div>
                        <div class="field-body">
                            <div class="field has-addons">
                                <div class="control">
                                    <input name="SelectedScreenName" type="text" class="input"
                                        [(ngModel)]="SelectedScreen.Name" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-codeeditor Mode="html" *ngIf="SelectedScreen != null" [(Model)]="SelectedScreen.Html" #editor>
                    </app-codeeditor>
                </div>
            </div>
        </app-tab>
        <app-tab Title="Style">
            <app-codeeditor Mode="css" [(Model)]="Model.ScreenStyles"></app-codeeditor>
        </app-tab>
    </app-tabs>
</form>