import { Injectable } from '@angular/core';
import { Day } from '../components/Day';
import { ApiServiceBase } from './api.service.base';

@Injectable({
  providedIn: 'root'
})

export class CalendarService {

  public currentYear: number;
  public currentMonthIndex: number;

  constructor(private apiService: ApiServiceBase) {
    let date = new Date();
    this.currentYear = date.getFullYear();
    this.currentMonthIndex = date.getMonth(); 
  }
  
  private MonthShortNames : string[] = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  private DaysInMonths : number[] =    [ 31,   29,   31,   30,   31,   30,   31,   31,   30,   31,   30,   31];

  public GetMonth(index : number){
    return { ShortName: this.MonthShortNames[index-1]};
  }

  public getCurrentMonth(): Day[] {
    return this.getMonth(this.currentMonthIndex, this.currentYear);
  }


  public getMonthName(monthIndex: number): string {
    switch (monthIndex) {
      case 0:
        return "January";      
      case 1:
        return "February";
      case 2:
        return "March";
      case 3:
        return "April";
      case 4:
        return "May";
      case 5:
        return "June";
      case 6:
        return "July";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "October";
      case 10:
        return "November";
      case 11:
        return "December";

      default:
        return "|" + monthIndex;
    }
  }

  public getWeekDayName(weekDay: number): string {
    switch (weekDay) {
      case 0:
        return "Mo"; // Monday
      case 1:
        return "Tu"; // Tuesday
      case 2:
        return "We"; // Wednesday
      case 3:
        return "Th"; // Thursday
      case 4:
        return "Fr"; // Friday
      case 5:
        return "Sa"; // Saturday
      case 6:
        return "Su"; // Sunday
      default:
        return "";
    }
  }

  public getMonth(monthIndex: number, year: number): Day[] {
    let days = [];

    let firstday = this.createDay(1, monthIndex, year);

    //create empty days
    for (let i = 1; i < firstday.weekDayNumber; i++) {
      days.push({
        weekDayNumber: i,
        monthIndex: monthIndex,
        year: year,
      } as Day);
    }
    days.push(firstday);
    //

    let countDaysInMonth = new Date(year, monthIndex +1, 0).getDate();
    for (let i = 2; i < countDaysInMonth +1; i++) {
      days.push(this.createDay(i, monthIndex, year));
    }

    return days;
  }

  public GetMonthArray(){
    let result = [];
    for(let i=0; i < this.MonthShortNames.length; i++){
      result.push({ Index : i+1, Name : this.MonthShortNames[i]});
    }
    return result;
  }

  public GetIntArrayForMonth(month : number){
    let result : number[] = [];
    for(let i=1; i <= this.DaysInMonths[month-1]; i++){
      result.push(i);
    }
    return result;
  }

  public GetNextDay(day : number, month : number) : Date{
    let d = new Date(2000, month-1, day);
    d.setDate(d.getDate() + 1);//add 1 day
    return d;
  }
  public GetPrevDay(day : number, month : number) : Date{
    let d = new Date(2000, month-1, day);
    d.setDate(d.getDate() - 1);//add 1 day
    return d;
  }
  public MaxDaysInMonth(month : number){
    return this.DaysInMonths[month-1];
  }

  private createDay(dayNumber: number, monthIndex: number, year: number) {
    let day = new Day();

    day.monthIndex = monthIndex;
    day.month = this.getMonthName(monthIndex);

    day.number = dayNumber;
    day.year = year;

    day.weekDayNumber = new Date(year, monthIndex, dayNumber).getDay() || 7;
    day.weekDayName = this.getWeekDayName(day.weekDayNumber);

    return day;
  }


  public GetOrganizationDayTypeDays(month:number, year: number) : Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.Get<any>("calendar/days/" + year + "/" + month).then(result => {
      resolve(result);
      });
    });
  }

  public GetDayTypes() : Promise<any> {
    return new Promise<string>((resolve, reject) => {
      this.GetOrganizationDayTypes().then((x: any) => {
        resolve(x);
      });
    });
  }

  private GetOrganizationDayTypes() : Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.Get<any>("calendar/daytypes/").then(result => {
      resolve(result);
      });
    });
  }

  public AddDayTypeDay(dtd: any) : Promise<any> {
    return new Promise<string>((resolve, reject) => {
      this.apiService.Put("calendar/daytypeday/", dtd).then((result : any) => {
        resolve(result);
      })
    });
  }

  public DeleteDayTypeDay(dtd: any) : Promise<any> {
    return new Promise<string>((resolve, reject) => {
      this.apiService.Delete("calendar/daytypeday/" + dtd, null).then((result : any) => {
        resolve(result);
      })
    });
  }
}
