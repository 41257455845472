import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { Geo } from 'src/app/util/geo';
import { v4 as uuidv4 } from 'uuid';


@Component({
  selector: 'app-addcontrolledareaterminal',
  templateUrl: './addcontrolledareaterminal.component.html',
  styleUrls: ['./addcontrolledareaterminal.component.scss']
})
export class AddcontrolledareaterminalComponent  extends SimpleModalComponent<any, any> implements OnInit {
  
  public Model : any =  { Id : uuidv4(), Name : "New Controller", Location: [] };
  public Form : UntypedFormGroup = new UntypedFormGroup({
    Id : new UntypedFormControl(uuidv4()),
    Name : new UntypedFormControl('New Controller', Validators.required),
    RelayType : new UntypedFormControl('tcp-kp-1202', Validators.required),
    SerialNumber : new UntypedFormControl(''),
    LocationPoints: new UntypedFormControl(null, Validators.required)
  });
  public ControlledAreaId : string = "";
  @Input()
  public ExistingControllers !: UntypedFormArray;
  @Input()
  public Gates !: UntypedFormArray;
  @Input()
  public Lane !: UntypedFormGroup;

  constructor() {
    super();
  }

  ngOnInit(){
    if(this.Lane != null){
      let point = Geo.PolygonCenter(this.Lane.get('PolygonPoints')?.value);
      if(point != null){
        this.Form.get('LocationPoints')?.setValue([point.lng, point.lat]);
      }
    }
  }

  public ExistingSelected(c : any){
    c.IsNew = false;
    this.result = c;
    this.close();
  }
  public CreateNew(){
    this.result = this.Form.value;
    this.close();
  }

}