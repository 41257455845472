import * as THREE from "three";
import { Mesh, Scene, Vector2, Vector3 } from "three";

export class GuardRailMesh extends THREE.Mesh{
    constructor(scene : Scene, rail: any, offset: Vector3, height: number, scale: Vector2){
        super();
        let p = rail.PolygonPoints;
        let shape = new THREE.Shape()
        shape.moveTo(p[0][0]*scale.x + offset.x, -1*p[0][1]*scale.y + offset.y);
        for(let i=1; i < p.length; i++){
            shape.lineTo(p[i][0]*scale.x + offset.x, -1*p[i][1]*scale.y + offset.y);
        }
        const extrudeSettings = {
            steps: 2,
            depth: height/24,
            bevelEnabled: false,
        };
        let geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
        geometry.translate(0,0,offset.z + 0.0001);
        let material = new THREE.MeshBasicMaterial({ color: new THREE.Color("#" + rail.FillColor) });
        this.geometry = geometry;
        this.material = material;
        scene.add(this);

        geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
        geometry.translate(0,0,offset.z + 0.0001 + height/6);
        let m1 = new Mesh(geometry, material);
        scene.add(m1);

        geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
        geometry.translate(0,0,offset.z + 0.0001 + height/3);
        let m2 = new Mesh(geometry, material);
        this.type = "guardrail";
        scene.add(m2);
    }
}
