<div class="modal is-active">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Close Session</p>
    </header>
    
    <section class="modal-card-body" style="min-width: 800px;">
      <app-loader *ngIf="loading"></app-loader>
      
      <form [formGroup]="form">
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Exit Date</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input class="input" type="datetime-local" formControlName="ControlledAreaExitDate">
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>

    <footer class="modal-card-foot">
      <app-modal-save-button (OnSave)="confirm()"></app-modal-save-button>
    </footer>
  </div>
</div>
