<div>
    <div class="columns" style="width: 15em; height: 2em; margin-top: -12px;">
        <div class="column is-6">
            <label style="min-height:0.5em; width:100%; display: contents; background-color: green;">
                <div class="img centered" style="background-color: green;">
                    <div class="white datemonth">{{StartDate}}</div>
                    
                </div>
            </label>
        </div>
        <div class="column is-6">
            <div class="img centered">
                <div class="datemonth">{{EndDate}}</div> 
            </div>
        </div>
    </div>
</div>