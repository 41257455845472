import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { LevelEditorComponent } from "../../leveleditor.component";
import { PolygonTool } from "./PolygonTool";
import { v4 as uuidv4 } from 'uuid';
import { Row } from "../../components/PolygonComponents/Row";

export class RowTool extends PolygonTool{
    public override ItemWidth: number = 300;
    public override ItemHeight: number = 70;
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }

    public override CreateNew(canvasX : number, canvasY: number){
        let rows = this.editor.ModelEditor.FormArray(this.editor.Form, "Rows");
        let newRowFormGroup = new FormGroup({
            Id: new FormControl(uuidv4()),
            Name: new FormControl("Row"),
            Spaces: new FormControl([]),
            PolygonPoints: new FormControl(this.CreatePolygonFromXY(canvasX, canvasY)),
            EditorLocked: new FormControl(false)
        });
        (rows as FormArray).push(newRowFormGroup);

        var component = new Row(this.editor, this.canvas, newRowFormGroup, null);
        this.editor.Form.markAsDirty();
        return component;
    }
}