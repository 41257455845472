import { FormArray, FormGroup } from "@angular/forms";

export class LevelEditorHelper{
    public static findFormItemIndex = (formArray: FormArray, formItem: FormGroup): number => {
        return formArray.controls.findIndex(control => {
            if (!control || !formItem || !control.value || !formItem.value) {
                return false; // Skip if either control or formItem is undefined or null
            }
            
            let match = true;
            for (const key in formItem.value) {
                if (formItem.value.hasOwnProperty(key) && control.value.hasOwnProperty(key)) {
                    if (control.value[key] !== formItem.value[key]) {
                        match = false;
                        break;
                    }
                }
            }
            return match;
        });
      };
}