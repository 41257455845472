<div class="modal is-active" [formGroup]="Form">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Access Control</p>
      </header>
      <section class="modal-card-body"style="min-width: 800px;">
            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="label">Access Type</label>
                </div>
                <div class="field-body">
                    <div class="field">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select formControlName="ClassName" name="ClassName" (change)="ClassChanged()">
                                    <option value="ControlledAreaAccessMethodPublic">Public Access</option>
                                    <option value="ControlledAreaAccessMethodWhiteList">Access List Only</option>
                                </select>
                            </div>
                            <app-validator [For]="this.Form.get('ClassName')"></app-validator>
                        </div>
                    </div>
                </div>  
            </div>
            <div class="field is-horizontal" *ngIf="GetClassName() == 'ControlledAreaAccessMethodWhiteList'">
                <div class="field-label is-normal">
                    <label class="label">Access List</label>
                </div>
                <div class="field-body">
                    <div class="field">
                        <div class="control">
                            <div class="select is-fullwidth" *ngIf="AccessLists != null">
                                <select formControlName="PlateAccessListId" name="PlateAccessListId" (change)="AccessListChanged()">
                                    <option *ngFor="let a of AccessLists" [ngValue]="a.Id">{{a.Name}}</option>
                                </select>
                            </div>
                            <span *ngIf="AccessLists == null">
                                Loading...
                            </span>
                        </div>
                    </div>
                </div>  
            </div>
                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label">Rate Set</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <div class="select is-fullwidth" *ngIf="RateSets != null">
                                    <select formControlName="RateSetId" name="RateSetId" (change)="RateSetChanged()">
                                        <option *ngFor="let a of RateSets" [ngValue]="a.Id">{{a.Name}}</option>
                                    </select>
                                </div>
                                <app-validator [For]="this.Form.get('RateSetId')"></app-validator>
                                <span *ngIf="RateSets == null">
                                    Loading...
                                </span>
                            </div>
                        </div>
                    </div>  
                </div>
        
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" (click)="confirm()">OK</button>
      </footer>
    </div>
  </div>