import { Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';
import { CalendarService } from 'src/app/Services/calendar.service';
import { ColorService } from 'src/app/Services/color.service';
import { Color } from 'src/app/util/color';

@Component({
  selector: 'app-editpricerule',
  templateUrl: './editpricerule.component.html',
  styleUrls: ['./editpricerule.component.scss'],
})
export class EditpriceruleComponent extends ModelEditor {

  public TextColor = Color.GetVisibleTextClassFor;

  constructor(public calendarService: CalendarService, private injector: Injector) {
    super("parking/pricerules", injector);

  }
  public override DefaultModel(): any {
    return {
      ClassName: "LinearPriceRule",
      Name: "New Price Rule",
      Color: "#ffffff",
      FeePerBlock: 1,
      MinutesPerBlock: 30,
      RuleLines: [{ FeePerBlock: 1, MinutesPerBlock: 30, MaxMinutes: 60 }],
      MaxParkingMinutes: 0,
      Fee: 1,
      MaxFee: null,
      PeriodQuantity: null,
      PeriodUnit: 3,
    };
  }

  public override AfterModelLoaded(): void {
    this.ClassNameChanged(); //set up the validators based on the class name
  }
  public override BeforeSave(): void {

  }


  public override FormLabels(): any {
    return {
      Name: 'Rule Name',
      Color: 'Color',
      FeePerBlock: "Price",
      MinutesPerBlock: "Minutes",
      MaxParkingMinutes: "Max Parking Time"
    }
  }

  public override Validators(): any {
    return {
      "Name": [Validators.required],
      "Color": [Validators.required]
    };
  }

  public check(form: any) {

  }
  public ClassNameChanged() {
    let className = this.Form.get('ClassName')?.value;

    let fa = this.FormArray(this.Form, "RuleLines");
    if (fa != null) {
      for (let i in fa.controls) {
        let price = fa.controls[i].get('FeePerBlock');
        let mins = fa.controls[i].get('MinutesPerBlock');
        let max = fa.controls[i].get('MaxMinutes');
        if (className == 'TieredPriceRule') {
          price?.setValidators(Validators.required);
          mins?.setValidators([Validators.required, Validators.min(1)]);
          max?.setValidators(Validators.required);
        }
        else {
          price?.clearValidators();
          mins?.clearValidators();
          max?.clearValidators();
        }
        price?.updateValueAndValidity();
        mins?.updateValueAndValidity();
        max?.updateValueAndValidity();
      }
    }
    let feePerBlock = this.Form.get("FeePerBlock");
    let minutesPerBlock = this.Form.get("MinutesPerBlock");
    let fee = this.Form.get("Fee");
    if (className == 'LinearPriceRule') {
      feePerBlock?.setValidators(Validators.required);
      minutesPerBlock?.setValidators(Validators.required);
    }
    else {
      feePerBlock?.clearValidators();
      minutesPerBlock?.clearValidators();
    }
    feePerBlock?.updateValueAndValidity();
    minutesPerBlock?.updateValueAndValidity();

    if (className == 'FlatRatePriceRule') {
      fee?.setValidators(Validators.required);
    }
    else {
      fee?.clearValidators();
    }
    this.Form.updateValueAndValidity();
  }
  public AddTieredLine() {
    ///if(this.Model.RuleLines == null) this.Model.RuleLines = [];
    let newline = { Index: 0, FeePerBlock: 1, MinutesPerBlock: 1, MaxMinutes: 1 }
    this.AddToFormArray(this.Form.get('RuleLines') as UntypedFormArray, newline, "RuleLines");
    this.ReindexRuleLines();
  }
  public RemoveTieredLine(index: number) {
    this.FormArray(this.Form, "RuleLines").removeAt(index);
    this.ReindexRuleLines();
  }
  private ReindexRuleLines() {
    let lines = this.FormArray(this.Form, "RuleLines").controls;
    for (let i = 0; i < lines.length; i++) {
      lines[i].get('Index')?.setValue(i);
    }
  }

  public ValidateTieredPriceRuleRequired(control: AbstractControl) {
    let className = control.parent?.parent?.parent?.get("ClassName")?.value;
    if (className == "TieredPriceRule") {
      if (control.value == null)
        return { rewuired: true };
    }
    return null;
  }

  public ClassForColor() {
    let c = this.Form.get("Color")?.value;
    return Color.GetVisibleTextClassFor(c);
  }
}
