import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { LocalStorageService } from 'src/app/Services/local-storage.service';
import { FilterFiltersBase, IFilterFilters } from '../ifilter';

@Component({
  selector: '[app-valettypes-filter]',
  templateUrl: './valettypes-filter.component.html',
  styleUrls: ['./valettypes-filter.component.scss'],
  host: {'class':'control select'}
})
export class ValetTypesFilterComponent extends FilterFiltersBase implements OnInit, IFilterFilters {

  constructor(private apiService: ApiServiceBase, route: ActivatedRoute) {
    super(route);
  }

  public FilterName: string = "valettype";
  public Filters: any[] = [];
  public SelectedValetType: any = "";
  public ValetTypes: any;
  @Input()
  public QueryParamPrefix: string= '';

  ngOnInit(): void {
    var vals = super.GetParamFields(this.QueryParamPrefix + this.FilterName);
    if(vals != null) {
      this.SelectedValetType = vals[2];
    }

    if (this.SelectedValetType != "") {
      this.Filters.push({
        Property: "ValetTypeId",
        Comparator: "==",
        Value: this.SelectedValetType,
        FilterName: this.FilterName
      })
    }

    this.apiService.Get<any>("/valet/types").then(result => {
      this.ValetTypes = result;
    });
  }

  public addTypeFilter(event: any) {
    var val = event.target.value;
    this.Filters = this.Filters.filter(x => x.Property != "ValetTypeId");

    if (val != "") {
      this.Filters.push({
        Property: "ValetTypeId",
        Comparator: "==",
        Value: val,
        FilterName: this.FilterName
      });

      this.SelectedValetType = val;
    }
  }

  public ValetTypeSelected(valetType: any) {
    if (valetType.Id == this.SelectedValetType) {
      return true;
    }
    return false;
  }

}
