<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Form]="Form" [Parent]="this" (OnSave)="Save()"></app-form-save-button>
    </app-pageheader>

    <div class="columns">
        <div class="column is-6">
            <article class="panel">
                <p class="panel-heading">
                    {{Model.ParkingLotName}} - Level {{Model.Level}}
                </p>
                <div class="panel-block" style="display: block;">
                    <app-field Label="Name">
                        <div class="control">
                            <input formControlName="Name" class="input" type="text" />
                            <app-validator [For]="this.Form.get('Name')"></app-validator>
                        </div>
                    </app-field>
                    <app-field Label="Space Count">
                        <div class="control">
                            <input formControlName="TotalSpaces" class="input is-static" type="text" readonly />
                        </div>
                    </app-field>
                    <app-field Label="Occupancy Count Mode" tippy="The method used to determine occupancy count. 'Default' mode adds up occupancy reported for each level. 'Count In/Out' mode uses car counters/access control to track vehicles entering/leaving and does not use Level occupancy figures.">
                        <div class="control">
                            <div class="select">
                                <select formControlName="AvailabilityCountMode" class="input">
                                    <option value="Default">Default</option>
                                    <option value="VehiclesPresent">Count in/out</option>
                                    <option value="External">External</option>
                                </select>
                            </div>
                        </div>
                    </app-field>
                    <app-field Label="External Count Provider" tippy="The external provider used to set occupancy count." *ngIf="this.Form.get('AvailabilityCountMode')?.value == 'External'">
                        <div class="control">
                            <div class="select">
                                <select formControlName="ExternalCountProvider" class="input">
                                    <option value="" hidden>None Selected</option>
                                    <option *ngFor="let opt of GetExternalCountProviders()" value="{{opt.Name}}">{{opt.Name}}</option>
                                </select>
                            </div>
                        </div>
                    </app-field>
                    <app-field Label="External Count Source" tippy="The external source used to set occupancy count." *ngIf="this.Form.get('AvailabilityCountMode')?.value == 'External' && this.Form.get('ExternalCountProvider')?.value != null">
                        <div class="control">
                            <div class="select">
                                <select formControlName="ExternalCountProviderSourceId" class="input">
                                    <option value="" hidden>None Selected</option>
                                    <option *ngFor="let opt of GetExternalCountSources(this.Form.get('ExternalCountProvider')?.value)" value="{{opt.Id}}">{{opt.Name}}</option>
                                </select>
                            </div>
                        </div>
                    </app-field>
                    <app-field Label="Open/Closed Status" [HasAddons]="true" tippy="Whether or not the lot is open for parking. Can be triggered automatically using Threshold Sets.">
                        <div class="control">
                            <div class="select">
                                <select formControlName="ClosedByUser" class="input">
                                    <option [value]="false">Open</option>
                                    <option [value]="true">Closed</option>
                                </select>
                            </div>
                        </div>
                        <div class="control" *ngIf="Model.ClosedBySystem == true">
                            <span class="button is-danger" >Currently closed due to {{Model.PercentOccupied}} Occupancy</span>
                        </div>
                    </app-field>
                    <app-field Label="Threshold Set" tippy="Governs the color of the parking lot based on occupancy. Threshold sets can also close a parking lot at certain thresholds and reopen them when occpancy falls.">
                        <div class="control">
                            <div class="select" *ngIf="ThresholdSets != null">
                                <select formControlName="ParkingThresholdSetId" class="input" type="text">
                                    <option value="">Use Parent</option>
                                    <option value="null" hidden>Use Parent</option>
                                    <option *ngFor="let a of ThresholdSets" [ngValue]="a.Id">{{a.Name}}</option>
                                </select>
                            </div>
                            <span *ngIf="ThresholdSets == null">
                                Loading...
                            </span>
                        </div>
                    </app-field>

                    <app-field Label="Default Rate Set" tippy="Change the default rate set applied to this parking lot">
                        <div class="control">
                            <div class="select" *ngIf="RateSets != null">
                                <select formControlName="ParkingRateSetId" class="input" type="text">
                                    <option value="">Use Parent</option>
                                    <option value="null" hidden>Use Parent</option>
                                    <option *ngFor="let a of RateSets" [ngValue]="a.Id">{{a.Name}}</option>
                                </select>
                            </div>
                            <span *ngIf="RateSets == null">
                                Loading...
                            </span>
                        </div>
                    </app-field>
                </div>
            </article>

        </div>
        <div class="column is-6">
            <!-- <app-lotviewer *ngIf="Model != null && Model.Levels.length > 0" [ParkingLotId]="Model.Id" ></app-lotviewer> -->
            <app-map-input formControlName="GeoPolygonPoints" MapWidth="100%" MapHeight="500" [Editable]="false"
                [BackgroundPolygonPoints]="MapItems">
            </app-map-input>

        </div>
    </div>
</div>