import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { v4 as uuidv4 } from 'uuid';
import * as ace from 'ace-builds';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { EditterminaltransitionComponent } from './transitions/editterminaltransition.component';
import { EditterminaleventsComponent } from './events/editterminalevents.component';
import { EditterminaljsonComponent } from './json/editterminaljson.component';
import { MediaSelectorComponent } from 'src/app/shared/forms/inputs/mediaselector/mediaselector.component';
ace.config.set("basePath", "/scripts/ace");

@Component({
  selector: 'app-editterminalconfiguration',
  templateUrl: './editterminalconfiguration.component.html',
  styleUrls: ['./editterminalconfiguration.component.scss']
})
export class EditterminalconfigurationComponent extends ModelEditor implements OnInit {

  constructor(private modalService: SimpleModalService, private injector: Injector, private apiService: ApiService) {
    super("infrastructure/localcontrollers/workflows", injector);
    this.Loading();
    this.apiService.Get("infrastructure/localcontrollers/workflows/events").then((x: any) => {
      this.ControllerEvents = x.Events;
      this.ControllerTransitions = x.Transitions;
      this.StopLoading();
    });
  }

  public ControllerEvents = null;
  public ControllerTransitions = null;

  public override ReactiveFormsEnabled: boolean = false;

  public override DefaultModel(): any {
    let stateId = uuidv4();
    let screenId = uuidv4();
    return {
      Name: 'New Configuration',
      DefaultStateId: stateId,
      States: [
        { Id: stateId, Name: 'Default State', ScreenId: screenId, LocationTop: 50, LocationLeft: 150, Transitions: [], EntryEvents: [] },
      ],
      Screens: [{ Id: screenId, Name: "Screen 1", Html: "<div class='screen1'></div>" }],
      ScreenStyles: "html {}\r\n.modal { }\r\n.modalBg { }\r\n.modalTitle { }\r\n.modalDescription { }\r\n.modalContainer { }\r\n.modalButtons { }"
    }
  };
  
  public override AfterModelLoaded(): void {
    this.RefreshTransitionLines();
  }

  public ViewJson() {
    var modelJson = JSON.stringify(this.Model, null, 2)
    this.modalService.addModal(EditterminaljsonComponent, { ModelJson: modelJson })
      .subscribe((result) => {
        if (result != null) {
          if(modelJson != result){
            //json has been changed so remap the model

            let newModel = JSON.parse(result);
            this.Model = newModel;
            this.RefreshTransitionLines();
          }
        }
      });
  }

  public override BeforeSave(): boolean | void {
  }


  public override AfterSave(): void {
    this.SelectedScreen = this.Model.Screens.filter((x: any) => x.Id == this.SelectedScreen.Id)[0];
  }
  


  public override Validators(): any {
  }

  public AllTransitions: any[] = [];
  public StateLocations: any[] = [];
  public TransitionPaths: any[] = [];
  public EntryAndExits: any[] = [];
  public StateWidth: number = 225;
  public StateHeight: number = 150;
  public StyleEditorOptions: any = { maxLines: 1000, printMargin: false, basePath: "/scripts" };
  public SelectedScreen: any = null;
  public CurrentDragState: any = null;
  public CurrentDragStartEvent: any = null;
  public IsAddingState: boolean = false;
  public CurrentDragExit: any = null; //the state whose exit point is being dragged
  public CurrentDragExitEvent: any = null; //the mouse event that started a drag from a state exit
  public CurrentDragExitLine: string = "";
  private BottomOfStateBoxes: number = 0;
  private LastLineOuterRouteY: number = 0; //to keep track of the lines routed around the outside of the states so they dont overlap
  public CurrentHighlightTransition: any = null;

  public ScreenSelected(screen: any) {
    this.SelectedScreen = screen;
  }

  public DeleteState(index: number) {
    var state = this.Model.States[index];
    this.UpdateTransitions(state)
    this.Model.States.splice(index, 1);
  }

  public StateGrabMouseDown(evt: any, state: any) {
    this.CurrentDragState = state;
    this.CurrentDragStartEvent = evt;
    var location = { x: state.LocationLeft, y: state.LocationTop };

    this.StateLocations = this.StateLocations.filter(x => x.StateId != state.Id);
    this.StateLocations.push({StateId: state.Id, PreDragLocation: location});
  }

  public UpdateTransitions(state: any) {
    var stateId = state.Id;
    this.Model.States.forEach((state: any) => {
      state.Transitions = state.Transitions.filter((x: any) => x.ToStateId != stateId && x.WorkflowStateId != stateId);
    });
    this.RefreshTransitionLines();
  }

  public DeleteTransition(transition: any) {
    var state = this.Model.States.filter((x: any) => x.Id == transition.WorkflowStateId);
    state[0].Transitions = state[0].Transitions.filter((x: any) => x.Id != transition.Id);
    this.RefreshTransitionLines();
  }

  public DesignerMouseMove(evt: any) {
    if (this.CurrentDragState != null) { //user is dragging a state around

      var PreDragLocation = this.StateLocations.filter(x => x.StateId == this.CurrentDragState.Id)[0].PreDragLocation;

      let deltaX = evt.pageX - this.CurrentDragStartEvent.pageX;
      let deltaY = evt.pageY - this.CurrentDragStartEvent.pageY;
      this.CurrentDragState.LocationLeft = PreDragLocation.x + deltaX;
      this.CurrentDragState.LocationTop = PreDragLocation.y + deltaY;
      if (this.CurrentDragState.LocationLeft < 0) {
        this.CurrentDragState.LocationLeft = 0;
      }
      if (this.CurrentDragState.LocationTop < 0) {
        this.CurrentDragState.LocationTop = 0;
      }
    }
    if (this.CurrentDragExit != null) { //user is dragging a state exit point
      let deltaX = evt.pageX - this.CurrentDragExitEvent.pageX;
      let deltaY = evt.pageY - this.CurrentDragExitEvent.pageY;
      this.CurrentDragExitLine = "M " + this.CurrentDragExit.ExitPoint[0] + " " + this.CurrentDragExit.ExitPoint[1] + " "
        + (this.CurrentDragExit.ExitPoint[0] + deltaX) + " " + (this.CurrentDragExit.ExitPoint[1] + deltaY);
    }
  }

  public DesignerClick(evt: any) {
    if (this.IsAddingState == true) {
      let newState = { Id: uuidv4(), Name: 'New State', Transitions: [], EntryEvents: [], LocationTop: evt.layerY, LocationLeft: evt.layerX };
      this.Model.States.push(newState);
      this.IsAddingState = false;
    }
  }

  public DesignerMouseUp(evt: any) {
    if (this.CurrentDragState != null) {
      this.RefreshTransitionLines();
    }
    this.CurrentDragState = null;
    this.CurrentDragStartEvent = null;
    this.CurrentDragExit = null;
  }

  public ExitPointMouseDown(evt: any, state: any) {
    this.CurrentDragExit = state;
    this.CurrentDragExitEvent = evt;
  }
  public EntryPointMouseUp(evt: any, state: any) {
    if (this.CurrentDragExit != null) {
      if (this.CurrentDragExit.Transitions.filter((x: any) => x.ToStateId == state.Id).length > 0) {
        return; //transition to that state already exists
      }
      let newTransition = { Id: uuidv4(), ToStateId: state.Id, Event: "New Transition", WorkflowStateId: this.CurrentDragExit.Id };

      this.CurrentDragExit.Transitions.push(newTransition);
      this.RefreshTransitionLines();
    }
  }

  public RefreshTransitionLines() {
    this.BottomOfStateBoxes = 0;
    this.AllTransitions = [];
    for (let i = 0; i < this.Model.States.length; i++) {
      if (this.Model.States[i].LocationTop + this.StateHeight > this.BottomOfStateBoxes) {
        this.BottomOfStateBoxes = this.Model.States[i].LocationTop + this.StateHeight;
      }
    }
    this.LastLineOuterRouteY = 20; //set the minimum Y of lines routed around the outside
    for (let i = 0; i < this.Model.States.length; i++) {
      this.RefreshTransitionLinesOnState(this.Model.States[i]);
    }
  }
  public RefreshTransitionLinesOnState(state: any) {
    //set the entry/exit points
    this.EntryAndExits.push({
      StateId: state.Id, 
      EntryPoint: [state.LocationLeft, state.LocationTop + (this.StateHeight / 2)],
      ExitPoint: [state.LocationLeft + this.StateWidth, state.LocationTop + (this.StateHeight / 2)]
  })

    for (let i = 0; i < state.Transitions.length; i++) {
      let toStateId = state.Transitions[i].ToStateId;
      let toState = this.Model.States.filter((x: any) => x.Id == toStateId)[0];
      this.AllTransitions.push({StateTransition: state.Transitions[i], PathValues: this.GenerateTransitionPath(state.Transitions[i], state, toState)});
    }
  }

  public GenerateTransitionPath(transition: any, stateFrom: any, stateTo: any) {
    let pathvalues = this.FindPathForTransition(transition, stateFrom, stateTo);
    this.PathToSvgPath(pathvalues);
    return pathvalues;
  }

  public FindPathForTransition(transition: any, stateFrom: any, stateTo: any): number[][] {
    let pathvalues: any = [];
    let path: number[][] = [];
    let labelpoint: any;
    path.push([stateFrom.LocationLeft + this.StateWidth, stateFrom.LocationTop + (0.5 * this.StateHeight)]); //start point
    if (stateTo.LocationLeft < stateFrom.LocationLeft) {
      //line is going backward to the left
      path.push([stateFrom.LocationLeft + this.StateWidth + 50, stateFrom.LocationTop + (0.5 * this.StateHeight)]); //go out to the right a bit
      path.push([stateFrom.LocationLeft + this.StateWidth + 50, this.BottomOfStateBoxes + this.LastLineOuterRouteY]); //do down to the outer route Y
      path.push([stateTo.LocationLeft - this.LastLineOuterRouteY, this.BottomOfStateBoxes + this.LastLineOuterRouteY]);
      path.push([stateTo.LocationLeft - this.LastLineOuterRouteY, stateTo.LocationTop + this.StateHeight + 50]);
      path.push([stateTo.LocationLeft - this.LastLineOuterRouteY, stateTo.LocationTop + (0.5 * this.StateHeight)]);
      labelpoint = [stateFrom.LocationLeft + ((stateTo.LocationLeft - stateFrom.LocationLeft + this.StateWidth) / 2), this.BottomOfStateBoxes + this.LastLineOuterRouteY - 13];
      this.LastLineOuterRouteY += 20;
    }
    else {
      labelpoint = [stateFrom.LocationLeft + ((stateTo.LocationLeft - stateFrom.LocationLeft + this.StateWidth) / 2), stateFrom.LocationTop + ((stateTo.LocationTop - stateFrom.LocationTop + this.StateHeight) / 2) - 13];
    }
    path.push([stateTo.LocationLeft, stateTo.LocationTop + (0.5 * this.StateHeight)]);
    pathvalues.push({Path: path, LabelPoint: labelpoint})
    return pathvalues;
  }

  public PathToSvgPath(pathvalues: any) {
    var path = pathvalues[0].Path;

    if (path.length == 0) {
      return "";
    }

    let result = "M" + path[0][0] + " " + path[0][1];

    for (let i = 1; i < path.length; i++) {
      result += " ";
      let startX = path[i - 1][0];
      let startY = path[i - 1][1];
      let deltaX = path[i][0] - path[i - 1][0];
      let deltaY = path[i][1] - path[i - 1][1];
      if (Math.abs(deltaX) < 10 || Math.abs(deltaY) < 10) {
        //not much deviation in the line so draw straight
        result += " L" + path[i][0] + " " + path[i][1]; //simple line
      }
      else {
        result += "Q "; //quadratic draw
        result += (startX + (deltaX / 4)) + " " + (startY) + " ";
        result += (startX + (deltaX / 2)) + " " + (startY + (deltaY / 2));
        result += "Q " + (startX + (deltaX * 0.75)) + " " + (startY + deltaY) + " ";
        result += (startX + deltaX) + " " + (startY + deltaY);
      }
    }
    pathvalues[0].SVGPath = result
    return;
  }

  public LineMouseEnter(evt: any, transition: any) {
    this.CurrentHighlightTransition = transition;
  }
  public LineMouseLeave(evt: any, transition: any) {
    if (this.CurrentHighlightTransition == transition) {
      this.CurrentHighlightTransition = null;
    }
  }
  public EditTransition(transition: any) {
    let editingTransition = transition;
    this.modalService.addModal(EditterminaltransitionComponent, { Model: transition, Transitions: this.ControllerTransitions })
      .subscribe((result) => {
        if (result != null) {
          Object.assign(editingTransition, result);
        }
      });
  }
  public EditEvents(state: any) {
    if (state.EntryEvents == null) state.EntryEvents = [];
    let editingState = state;
    this.modalService.addModal(EditterminaleventsComponent, { Model: state, Events: this.ControllerEvents })
      .subscribe((result) => {
        if (result != null) {
          editingState.EntryEvents = result.EntryEvents;
        }
      });
  }

  public DeleteScreen(index: number) {
    let id = this.Model.Screens[index].Id;
    this.Model.Screens.splice(index, 1);
    for(let s of this.Model.States){
      if(s.ScreenId == id){
        s.ScreenId = null;
      }
    }
  }
  public AddScreen() {
    this.Model.Screens.push({
      Id: uuidv4(),
      Name: "New Screen",
      Html: "<div>New Screen</div>"
    });
  }

  public MediaTools = {
    Name: "Insert Media",
    Tools: [
      { Name: "Insert Image" },
    ],
    Icon: ""
  }

  @ViewChild("editor") codeEditor: any;
  mediaSelected(event: number) {
    this.codeEditor.mediaSelected(event);
  }

  public InsertMedia() {
    this.modalService.addModal(MediaSelectorComponent, { ImageCategory: "WorkflowMedia" }).subscribe((result) => {
      this.mediaSelected(result);
    });
  }
}
