<app-pageheader [Parent]="this">
    <add-button AddRoute="/parking/validation/profiles/new/edit"></add-button>
  </app-pageheader>
  
  <app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="parking/validation/profiles/searches"
    [AllowEdit]="true" EditUrl="/parking/validation/profiles/:id">
    <app-filter-set>
      <div app-search-filter></div>
      <div app-search-button></div>
    </app-filter-set>
  </app-datalistview>