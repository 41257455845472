<div class="dropdown is-fullwidth" [class.is-active]="DropdownActive">
    <div class="dropdown-trigger" (click)="toggleDropdown()">
        <div class="input select" aria-haspopup="true" aria-controls="dropdown-selector">
            {{ DisplayString }}
        </div>
    </div>
    <div class="dropdown-menu" id="dropdown-selector" role="menu" [class.is-active]="DropdownActive">
        <div class="dropdown-content">
            <div class="dropdown-item" *ngFor="let option of Options">
                <div class="field" (click)="toggleSelection(option)">
                    <input type="checkbox" [id]="option[ValueProperty]" class="frogbox" [checked]="isSelected(option)">
                    <label (click)="toggleSelection(option)" class="is-unselectable" [for]="option[ValueProperty]">{{ option[LabelProperty] }}</label>
                </div>
            </div>
            <hr class="is-marginless" />
            <div class="dropdown-item">
                <div class="field has-text has-text-centered" (click)="toggleSelectAll()">
                    <p class="is-unselectable">{{ SelectAllText }}</p>
                </div>
            </div>
        </div>
    </div>
</div>