import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { Datalistviewcolumn, SelectedViewColumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { DatalistviewComponent } from 'src/app/shared/datalistview/datalistview.component';
import { Busyable } from '../../editors/busyable';

@Component({
  selector: 'app-actionlog',
  templateUrl: './actionlog.component.html',
  styleUrls: ['./actionlog.component.scss']
})
export class ActionLogComponent extends SimpleModalComponent<any, any> implements AfterViewInit  {
  public apiService !: ApiService;
  public toastService !: ToastrService;
  public Busyable = new Busyable();
  public Columns !: Datalistviewcolumn[];

  @ViewChild("DataList")
  public DataList!: DatalistviewComponent;

  public Title : string = "Action History";
  public EntityType : string = "";
  public EntityId : string = "";
  
  public Api !: string; 

  constructor(apiService: ApiService, toastService: ToastrService) {
    super();
    this.apiService = apiService;
    this.toastService = toastService;
    this.Columns = [
      new Datalistviewcolumn("Action", "Action"),
      new Datalistviewcolumn("Timestamp", "TimestampLocalFriendlyString"),
      new Datalistviewcolumn("User", "UserDisplayName"),
    ];
  }

  ngAfterViewInit(): void {
    this.Api = "common/actionlogs/" + this.EntityType + "/" + this.EntityId + "/searches"; 
    this.DataList.ApiUrl = this.Api;
    this.DataList.Search();
  }
  
}