<app-pageheader [Parent]="this">
</app-pageheader>
<app-loader [Parent]="this"></app-loader>

<div class="columns">
  <div class="column is-half">
    <p>Total to Reconcile: {{TotalToReconcile ?? "--"}}</p>
  </div>
</div>

<table class="table is-fullwidth" *ngIf="ActionItems.length > 0">
  <thead>
      <tr>
          <th class="column-half">Imported</th>
          <th class="icon-cell"></th>
          <th class="column-half">FrogOne</th>
      </tr>
  </thead>
  <tbody>
      <tr *ngFor="let item of ActionItems">
        <td class="column-half">
          <article class="panel">
            <p class="panel-heading">Transaction {{item.ImportedCard.TransactionId}} 
              (<a href="/revenue/reconciliation/statements/{{item.ImportedCard.ProviderStatementId}}" target="_blank">Statement {{item.ImportedCard.ProviderStatementId}}</a>)
            </p>
            <p class="panel-block" *ngFor="let field of item.ImportedCard.DisplayFields | keyvalue">
              <span class="label">{{field.key}}</span>
              <span class="value">{{field.value}}</span>
            </p>
            <p class="panel-block">
              <span class="has-text-centered" style="width:100%">
                <button class="button is-small is-warning is-right" (click)="ignoreIssue(item.ImportedCard.ProviderStatementRowId, item.ImportedCard.ProviderStatementId)">
                  <i class="fa-regular fa-xmark-to-slot" style="padding-right: 0.5rem;"></i>
                  Ignore
                </button>
              </span>
            </p>
          </article>
        </td>
        <td class="icon-cell">
          <i class="fa fa-chevron-right"></i>
        </td>
        <td class="column-half" *ngIf="item.FrogOneCards.length > 0">
          <div *ngFor="let card of item.FrogOneCards; let i = index" [ngClass]="{ 'mb-4': item.FrogOneCards.length > 1  && i != item.FrogOneCards.length-1}">
            <article *ngIf="card.CardType == 'nodetails'" class="panel">
              <p class="panel-heading">Possible Resolutions</p>
              <p class="panel-block">
                <span class="label">&nbsp;</span>
                <span class="value">&nbsp;</span>
              </p>
              <p class="panel-block">
                <span class="label">&nbsp;</span>
                <span class="value">&nbsp;</span>
              </p>
              <p class="panel-block">
                <span class="has-text-centered" style="width:100%">
                  <button class="button is-small mr-2 is-{{a.ButtonColor}}" *ngFor="let a of card.Actions" (click)="doAction(a)" >
                    <i *ngIf="a.IconName != null" class="fa-regular fa-{{a.IconName}}" style="padding-right: 0.75rem;"></i>
                    {{a.DisplayName}}
                  </button>
                </span>
              </p>
              <p class="panel-block">
                <span class="label">&nbsp;</span>
                <span class="value">&nbsp;</span>
              </p>
              <p class="panel-block">
                <span class="label">&nbsp;</span>
                <span class="value">&nbsp;</span>
              </p>
            </article>
            <article *ngIf="card.CardType == 'build'" class="panel">
              <p *ngIf="card.HeaderUrl == null" class="panel-heading">{{card.CardName ?? "Resolution details"}}</p>
              <p *ngIf="card.HeaderUrl != null" class="panel-heading"><a [href]="card.HeaderUrl" target="_blank">{{card.CardName ?? "Resolution details"}}</a></p>
              <p class="panel-block" *ngFor="let field of card.DisplayFields | keyvalue">
                <span class="label">{{field.key}}</span>
                <span class="value">{{field.value}}</span>
              </p>
              <span *ngIf="card.Table != null" class="panel-block">
                <table class="cardTable">
                  <thead>
                    <tr>
                      <th *ngFor="let h of card.Table.Headers">{{h?.Value ?? ""}}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tr *ngFor="let row of card.Table.Rows">
                    <td *ngFor="let col of row.Columns">{{col?.Value ?? ""}}</td>
                    <td>
                      <span *ngIf="row.Actions != null">
                        <button *ngFor="let act of row.Actions" class="button mr-1 is-small is-{{act.ButtonColor}}" (click)="doAction(act)">
                          <i *ngIf="act.IconName != null" class="fa-regular fa-{{act.IconName}}" style="padding-right: 0.75rem;"></i>
                          {{act.DisplayName}}
                        </button>
                      </span>
                    </td>
                  </tr>
                </table>
              </span>
              <p class="panel-block">
                <span class="has-text-centered" style="width:100%">
                  <button class="button mr-2 mb-2 is-small is-{{a.ButtonColor}}" *ngFor="let a of card.Actions" (click)="doAction(a)" >
                    <i *ngIf="a.IconName != null" class="fa-regular fa-{{a.IconName}}" style="padding-right: 0.75rem;"></i>
                    {{a.DisplayName}}
                  </button>
                </span>
              </p>
            </article>
            <article class="panel" style="border-style: dashed; border-color: lightgrey;" *ngIf="card.CreateAction != null">
              <p class="panel-block">                
                <span class="has-text-centered" style="width:100%; vertical-align: middle;">
                  <button class="button is-small is-{{card.CreateAction.ButtonColor}}" (click)="doAction(card.CreateAction)" >
                  <i *ngIf="card.CreateAction.IconName != null" class="fa-regular fa-{{card.CreateAction.IconName}}" style="padding-right: 0.75rem;"></i>
                  {{card.CreateAction.DisplayName}}
                  </button>
                </span>
              </p>
            </article>
          </div>
        </td>
      </tr>
  </tbody>
</table>
