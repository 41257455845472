import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-treenodepanelblock',
  templateUrl: './treenodepanelblock.component.html',
  styleUrls: ['./treenodepanelblock.component.scss']
})
export class TreeNodePanelBlock implements OnInit {

  @Input() recursiveList: any;
  @Output() UpdateItem = new EventEmitter<any>();
  @Output() ItemClicked = new EventEmitter<any>();

  @Output() updateSettingValue = new EventEmitter<any>();
  @Output() getItemValue = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {

    var x = this.recursiveList;
  }

  public GetItemValue(item : any){
    this.getItemValue.emit(item);
  }

  public UpdateSettingItemValue(item: any, evt: any) {
    let value;

    if (item.Type === 'boolean') {
        value = typeof evt === 'boolean' ? evt : evt.target.checked;
    } else {
        value = typeof evt === 'object' && evt.target ? evt.target.value : evt;
    }

    item.Value = value;
    this.updateSettingValue.emit(item);
}

  public UpdateSettingItem(item : any){
    this.updateSettingValue.emit(item);
  }
}