import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ServiceBusService } from 'src/app/Services/servicebus.service';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ColorService } from 'src/app/Services/color.service';
import { Deviceicons } from 'src/app/util/deviceicons';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { StructureViewerLayer } from 'src/app/shared/structureviewermaps/structureviewerlayers';

@Component({
  selector: 'app-controlledarea',
  templateUrl: './controlledarea.component.html',
  styleUrls: ['./controlledarea.component.scss']
})
export class ControlledAreaComponent extends Busyable implements OnInit {
  public ModelId : any;
  public Model: any;
  public ShowPage : Boolean = false;
  public MapPolygons: any = [];
  public ResetModes: any = [];

  public MapOptions: google.maps.MapOptions = {
    center: { lat: 40, lng: -20 },
    zoom: 4,
  };

  public StructureViewerLayers: StructureViewerLayer[] = [new StructureViewerLayer("ParkingLot", null, false, true), new StructureViewerLayer("ParkingLevel"), new StructureViewerLayer("ParkingSpace")]


  constructor(private apiService: ApiServiceBase, private parentRoute: ActivatedRoute, private servicebusService: ServiceBusService, private cService : ColorService ) {
    super();
  }

  public GenerateIconName(fg: UntypedFormGroup | AbstractControl, marker: boolean = false, markerselected: boolean = false): string {
    return Deviceicons.GenerateIconName(fg, marker, markerselected);
  }

  ngOnInit(): void {
    this.Loading();
    this.parentRoute.params.subscribe(params => { this.ModelId = params['id']; });

    this.apiService.Get<any>("infrastructure/controlledareas/" + this.ModelId).then(result => {
      this.Model = result;
      this.ShowPage = true;
      this.StopLoading();

      if (this.Model["GeoPolygonPoints"] != null) {
        let loc = this.Model["GeoPolygonPoints"];
        if (loc.length > 0) {
          let poly: google.maps.LatLngLiteral[] = [];
          for (let j = 0; j < loc.length; j++) {
            poly.push({ lat: loc[j][1], lng: loc[j][0] });
          }

          this.MapPolygons.push(poly);
        }
      }

      this.MapOptions.center = { lat: this.Model["GeoPolygonCenter"][1], lng: this.Model["GeoPolygonCenter"][0] };
      this.MapOptions.zoom = 20;
      this.Model.TextClass = this.cService?.GetVisibleTextClassFor(this.Model.ThresholdColor);
    });
  }
}
