import { Component, ContentChildren, Input, OnInit, Injector, forwardRef, Inject, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, UrlSegment } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { SimpleModalService } from 'ngx-simple-modal';
import { Observable } from 'rxjs';
import { FeaturesService } from 'src/app/Services/features.service';
import { DeleteCheckComponent } from '../modals/delete-check/delete-check.component';
import { FrogRoute } from '../../FrogRoute';
import { ModelEditor } from '../editors/modeleditor';

@Component({
  selector: 'app-pageheader',
  templateUrl: './pageheader.component.html',
  styleUrls: ['./pageheader.component.scss']
})
export class PageheaderComponent implements OnInit {

  public Breadcrumbs: string[][] = [];
  
  @Input()
  public Parent : any|null = null;
  public Model : any|null = null;

  @Input()
  public Title: string = "";

  @Input()
  public ModelId?: any;

  // Introduce this for page that has form which marks as dirty during init, such as Parking levels
  @Input()
  public Form: UntypedFormGroup | null = null;

  @Input()
  public EnableEdit = false;

  @Input()
  public EnableDelete = false;

  @Input()
  public DeleteUrl?: string;

  @Input()
  public DeleteCheck = true;

  @Input()
  public FormSaveEvent?: Observable<void>;

  // this is a special parameter to handle something like parking level which embedded inside a parking lot
  // Once we have a parking level deteled, we need to nav back to the parking level
  @Input()
  public DeleteNavLink?: string | null;

  public modelHomeLink: string = "DISABLED";
  public editRouterLink?: string;
  public displayTitle!: string;
  public Editor: boolean = false;

  constructor(private router: Router, private featureService: FeaturesService, private modalService: SimpleModalService, private activatedRoute: ActivatedRoute,private viewContainerRef: ViewContainerRef) { }


  ngOnInit(): void {
    if(this.Parent == null){
      this.Model = null;
    }
    else if(this.Parent instanceof ModelEditor){
      let editor:ModelEditor = this.Parent as ModelEditor;
      this.Model = editor.Model;
      editor.ModelLoaded.subscribe((model) => {
        this.Model = model;
        this.BuildBreadcrumbs();
      });
    } else{ 
      this.Model = this.Parent["Model"];
    }

    this.displayTitle = this.Title;
    this.featureService.MenuChanged.subscribe(() => {
      this.BuildBreadcrumbs();
    });
    this.BuildBreadcrumbs();

    if (this.EnableEdit) {
      this.editRouterLink = this.router.url + "/edit"
    }

    if (this.ModelId == null) {
      this.EnableDelete = false;
    }
  }


  public Delete() {
    if (this.Form != null) {
      this.Form.markAsPristine();
    }
    this.modalService.addModal(DeleteCheckComponent, { name: this.Title, url: this.DeleteUrl, modelId: this.ModelId, deleteCheck: this.DeleteCheck, deleteNavLink: this.DeleteNavLink })
      .subscribe((result) => {
        console.log(result);
      });
  }

  private async BuildBreadcrumbs() {
    let currentRoute = "";
    this.Breadcrumbs = [];
    let routeByParents : ActivatedRouteSnapshot[] = [];
    let lastUsedNameCrumb = "";

    let proute: ActivatedRoute|null = this.activatedRoute;
    if(proute.routeConfig?.path != '') routeByParents.push(proute.snapshot);
    proute = proute.parent;
    while (proute != null) { // build route by parents of current
      if (proute.routeConfig?.path != '')
        routeByParents.unshift(proute.snapshot);
      proute = proute.parent;
    }

    routeByParents[0]?.url.forEach(segment => currentRoute == "" ? currentRoute += segment : currentRoute += "/" + segment);

    for (let i = 1; i < routeByParents.length; i++) {
      let latestItem = "";
      let segments = routeByParents[i]?.url;
      segments?.forEach(segment => currentRoute == "" ? currentRoute += segment : currentRoute += "/" + segment);

      let disabled = (routeByParents[i]?.routeConfig as FrogRoute)?.breadcrumbDisabled == true || i == (routeByParents.length-1);

      if (segments != null && segments.length > 1)
        latestItem = segments[segments.length - 1]?.toString(); // save the last segment added to the routw

      let displayText = (routeByParents[i]?.routeConfig as FrogRoute)?.breadcrumbText;
      if (displayText) { //the route has specific text assigned
        if (displayText.indexOf("{") > -1) {
          //there is dynamic values in the breadcrumb
          let template = displayText;
          let matches = template.match("\{(.*?)\}");
          if (matches != null) {
            for (let j = 1; j < matches.length; j++) {
              if (this.Model == null) displayText = template?.replace("{"+matches[j]+"}", "");
              else {
                if(matches[j].indexOf('|') > 0){
                  //there are multiple properties to check for
                  let props = matches[j].split('|');
                  for(let x=0; x< props.length; x++){
                    if(props[x].startsWith("'") || props[x].startsWith('"')){ //string not property value
                      displayText = props[x].replace(/'/g, '').replace(/"/g, '');
                      if(displayText.startsWith("New")) disabled = true;
                      break;
                    }
                    else {//property name, grab the value from the model
                      if(this.Model[props[x]] && this.Model[props[x]] != ""){
                        let propVal = this.Model[props[x]];
                        displayText = template?.replace("{"+matches[j]+"}", propVal);
                        break;
                      }
                    }
                  }
                } else{
                  let propVal = this.Model[matches[j]];
                  displayText = template?.replace("{"+matches[j]+"}", propVal);
                }
              }
            }
            if(displayText == template){
              displayText = "";
            }
          }
        }
        let routeFullLink = currentRoute.split("/");
        let routeLink = "";
        if(disabled)
          routeLink = "DISABLED";
        //else if (routeFullLink[routeFullLink.length - 1].length != 36) // build ourselves a valid route out of what is there (removes any extra routes after the ID field if needed)
        //      routeLink = "/" + routeFullLink.slice(0, routeFullLink.length - 1).join("/");
        else
          routeLink = "/" + currentRoute;
        this.Breadcrumbs.push([displayText,  routeLink])
      } else { //no text assigned, try to find text from menu items
        let menuItem = await this.featureService.FindMenuItemByRoute(currentRoute); // attempt to find feature name and route
        if (menuItem != null) {
          if (disabled) {
            this.Breadcrumbs.push([menuItem.Text, "DISABLED"]); // unclickable links
          } else {
            this.Breadcrumbs.push([menuItem.Text, "/" + menuItem.Route]);
          }
        }
      }
    }

    // let editPageOnly = false;
    // if(routeByParents[routeByParents.length-1]?.data['EditPageOnly']) editPageOnly = true; // ensure we don't have a details page to go back to (breakcrumb is skipped)

    // if(this.router.url.toLowerCase().includes('/edit') && !editPageOnly){ // If we have a details page
    //   if(currentRoute.split("/").some(x => x == "new")) return;
    //   if(currentRoute.indexOf("%23") > 0){
    //     this.modelHomeLink = "/" + currentRoute.slice(0, currentRoute.indexOf("%23")) // remove any states that ended up part of the route
    //   } else {
    //     this.modelHomeLink = "/" + currentRoute; // Route without the edit
    //   }
    //   this.Editor = true; 
    //   this.Breadcrumbs.splice(this.Breadcrumbs.length, 0, ["MODELDISPLAY", "ENABLED"]); // Flag for handling in the HTML
    // }
  }

}
