<app-loader [Parent]="this"></app-loader>
<div *ngIf="Model != null">
  <app-pageheader [Parent]="this">
    <form-edit-button>Edit</form-edit-button>
    
  </app-pageheader>
  <div class="columns">
    <div class="column is-6">
      <article class="panel">
        <p class="panel-heading">
          User Details
        </p>
        <div class="panel-block">
          <div class="columns" style="margin: 0px;">
            <div class="column is-12">
              <app-field Label="Organization User Id" class="mb-1">
                <div class="control">
                  <p class="input">{{Model.Id}}</p>
                </div>
              </app-field>
              <app-field Label="User Id" class="mb-1">
                <div class="control">
                  <p class="input">{{Model.UserId}}</p>
                </div>
              </app-field>
              <app-field Label="Full Name" class="mb-1">
                <div class="control">
                  <p class="input">{{Model.FirstName}} {{Model.LastName}}</p>
                </div>
              </app-field>
              <app-field Label="Email" [HasAddons]="Model.IsExternalLogin" tippy="{{Model.IsExternalLogin ? 'This account uses an external login provider (E.g. Google or Apple)' : ''}}" class="mb-1">
                <div class="control is-expanded">
                  <p class="input">{{Model.Email}}</p>
                </div>
                <span *ngIf="Model.IsExternalLogin" class="tag is-large">
                  <i class="fa-regular fa-link"></i>
                </span>
              </app-field>
              <app-field Label="Account Balance" class="mb-1">
                <div class="control">
                  <app-currency-input  [InternalValue]="Model.AccountBalance" [Disabled]="true">
                  </app-currency-input>
                </div>
              </app-field>
              <app-field Label="Magic Money Balance" class="mb-1">
                <div class="control">
                  <app-currency-input [InternalValue]="Model.MagicMoneyAccountBalance" [Disabled]="true">
                  </app-currency-input>
                  <button class="button is-primary is-small" (click)="AddMagicMoney()">Add Magic Money</button>
                </div>
              </app-field>
              <app-field Label="Admin Panel Access">
                <div class="control">
                    <app-toggleswitch [(ngModel)]="Model.IsAdmin" disabled></app-toggleswitch>
                </div>
              </app-field>
              <app-field Label="User Is Activated" [HasAddons]="true">
                <div class="control">
                    <app-toggleswitch [(ngModel)]="Model.UserIsActivated" disabled></app-toggleswitch>
                    <button *ngIf="!Model.UserIsActivated" class="button is-primary is-small" style="margin-left:10px;" (click)="SendActivationEmail()">Send Activation Email</button>
                </div>
              </app-field>
            </div>
          </div>
        </div>
      </article>
    </div>
    <div class="column is-6">
      <div style="height: 300px; text-align:center;">
      <svg xmlns="http://www.w3.org/2000/svg" stroke="#327e3a" fill="#327e3a" height="100%" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
      </div>
    </div>
  </div>
  <div class="columns"  *ngIf="ShowSessions">
    <div class="column is-12">
      <article class="panel">
        <p class="panel-heading">
          User Sessions
        </p>
        <div class="panel-block">
          <app-datalistview style="width:100%;" [Columns]="SessionColumns" [ShowMap]="false" ApiUrl="parking/parkingsessions/searches"
                [AllowEdit]="true" EditUrl="/parking/parking/sessions/:id" [PageSize]="5">
            <app-filter-set>
              <div app-search-filter></div>
              <div app-search-button></div>
              <app-property-filter Property="UserId" Comparitor="==" [Value]="Model.UserId"></app-property-filter>
            </app-filter-set>
          </app-datalistview>
        </div>
      </article>
    </div>
  </div>
  <div class="columns" *ngIf="ShowPermits">
    <div class="column is-12">
      <article class="panel">
        <p class="panel-heading">
          User Permits
        </p>
        <div class="panel-block">
          <app-datalistview style="width:100%;" [Columns]="PermitColumns" [ShowMap]="false" ApiUrl="parking/permits/{{Model.UserId}}/searches"
                [AllowEdit]="true" EditUrl="/permits/parkingpermits/:id" [PageSize]="5">
            <app-filter-set>
              <div app-search-filter></div>
              <div app-search-button></div>
            </app-filter-set>
          </app-datalistview>
        </div>
      </article>
    </div>
  </div>
  <div class="columns">
    <div class="column is-12">
      <article class="panel">
        <p class="panel-heading">
          User Vehicles
        </p>
        <div class="panel-block">
          <app-datalistview style="width:100%;" [Columns]="VehicleColumns" [ShowMap]="false" ApiUrl="parking/vehicles/searches"
                [AllowEdit]="true" EditUrl="/parking/parking/sessions/:id/edit" [PageSize]="5">
            <app-filter-set>
              <div app-search-filter></div>
              <div app-search-button></div>
              <app-property-filter Property="UserId" Comparitor="==" [Value]="Model.UserId"></app-property-filter>
            </app-filter-set>
          </app-datalistview>
        </div>
      </article>
    </div>
  </div>
</div>