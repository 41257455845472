import { AfterContentInit, AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ApiService } from 'src/app/Services/api.service';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { ValetService } from 'src/app/Services/valet.service';
import { ClassedViewColumn, CurrencyViewColumn, DataListFilterColumn, DatalastviewTextAndConditionalSuffixColumn, Datalistviewcolumn, IconViewColumn, StateViewColumn, ValetTagListColumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { DatalistviewComponent } from 'src/app/shared/datalistview/datalistview.component';

@Component({
  selector: 'app-valetsessions',
  templateUrl: './valetsessions.component.html',
  styleUrls: ['./valetsessions.component.scss']
})
export class ValetSessionsComponent implements AfterViewInit{

  public OrgService !: OrganizationsService;
  public valetService !: ValetService;
  public Columns !: Datalistviewcolumn[];
  public SummaryItems !: any[];

  public StatusFilter !: string;

  @ViewChild("DataList")
  public DataList!: DatalistviewComponent;

  constructor(organizationService: OrganizationsService, private route: ActivatedRoute, valetService: ValetService, public apiService : ApiService, public modalService: SimpleModalService, public router: Router) { 
    this.OrgService = organizationService;
    this.valetService = valetService;

    this.apiService.Get('/valet/summary').then((result: any) => {
      this.SummaryItems = result.Items;
    })

    this.Columns = [
      new ClassedViewColumn("Ticket", "TicketNumber"),
      new Datalistviewcolumn("Customer Name", "FullName"),
      new Datalistviewcolumn("Vehicle Details", "VehicleDescription"),
      new IconViewColumn(undefined, "ValetTypeIconName", "ValetTypeIconColor"),
      new Datalistviewcolumn("Type", "ValetTypeName"),
      new Datalistviewcolumn("Billing To", "BillingKeyDescription"),
      new CurrencyViewColumn("Total Paid", "TotalPaid", this.OrgService),
      new ValetTagListColumn("Addons", "Addons", this.valetService),
      new StateViewColumn(undefined, "State"),
    ];
  }
  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(
      params => {
        this.StatusFilter = params["filter"] ? params["filter"] : "";
        if(this.DataList && this.DataList.FilterSet && this.DataList.FilterSet.SubSetFilter){
          this.DataList.FilterSet.SubSetFilter.Subset = this.StatusFilter;
          this.DataList.Filter.Subset = this.StatusFilter;
          this.DataList.Refresh();
        }
      }
    );
  }

  public HandleSummaryItem(item: SummaryItem){
    if(item.Action == 'Filter'){
      if(this.DataList && this.DataList.FilterSet && this.DataList.FilterSet.SubSetFilter){
      this.DataList.FilterSet.SubSetFilter.Subset = item.ActionProperty;
      this.DataList.Filter.Subset = item.ActionProperty;
      this.DataList.Refresh();
      }
    }
    if(item.Action == 'Redirect'){
      this.router.navigate([item.ActionProperty]);
    }

  }

  public Subsets: string[] = ["In Inventory", 'Requested', 'New', 'Require Action', 'Pull Now', 'Requiring Billing Info', 'Ready for Customer', 'Parked', 'Out to Return', 'Closed'];
}

interface SummaryItem {
  Description: string;
  IconName: string;
  IconColor: string;
  Count: number;
  Action: string; // Optional property
  ActionProperty: string; // Optional property
}
