import { LevelEditorComponent } from "../../../leveleditor.component";
import { MapItemTool } from "./MapItemTool";

export class IslandTool extends MapItemTool{
    public override ItemWidth: number = 60;
    public override ItemHeight: number = 30;
    public override Type = "Island";
    public override OutlineColor = "999999";
    public override Opacity =  0.8;
    public override FillColor =  "bbbbbb";
    public override OutlineThickness = 2;
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }
}