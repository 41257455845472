<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
  <app-pageheader [Parent]="this">
    <app-form-save-button [Form]="Form" [Parent]="this" (click)="Save()"></app-form-save-button>
  </app-pageheader>
  <div class="columns" #Properties>
    <div class="column is-6">
        <app-field Label="Name">
            <div class="control">
                <input class="input" formControlName="Name"/>
            </div>
        </app-field>
      </div>
  </div>

  <app-documenteditor *ngIf="FormLoaded == true" [ModelEditor]="this" [Document]="Form"></app-documenteditor>
</div>