import { AfterContentInit, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MapInfoWindow, MapMarker, MapPolygon } from '@angular/google-maps';
import { Router } from '@angular/router';
import { LotmultiviewerComponent } from 'src/app/components/lotmultiviewer/lotmultiviewer.component';
import { ApiService } from 'src/app/Services/api.service';
import { FeaturesService } from 'src/app/Services/features.service';
import { OccupancyService } from 'src/app/Services/occupancy.service';
import { Busyable } from 'src/app/shared/editors/busyable';

@Component({
  selector: 'app-valethome',
  templateUrl: './valethome.component.html',
  styleUrls: ['./valethome.component.scss']
})


export class ValetHomeComponent extends Busyable implements OnInit {

  public alerts: any = [];
  public Lots: any = [];

  public Summary : any | null = null;

  public TotalSpaces: number = 0;
  public OccupiedSpaces: number = 0;
  public Percent: number = 0;
  public rightColumnheight: number = 0;

  @ViewChild("leftColumn")
  public leftColumn!: ElementRef;
  public apiService: ApiService;

  OccupancySubscription: any;
  public cdr: ChangeDetectorRef;
  public showAlerts: boolean = false;

  public HighVolume: boolean = false;
  public ShowVolumeButton: boolean = false;

  constructor(private featuresService: FeaturesService, occupancyService: OccupancyService, cdr: ChangeDetectorRef, apiservice: ApiService, private router: Router) { 
    super();
    console.log("ValetHome Constructor");
    this.cdr = cdr;
    this.apiService = apiservice;
  }

  ngOnInit(): void {
    this.Loading();
    this.apiService.Get("/valet/highvolumemode").then((response: any) => {
      this.HighVolume = (response.HighVolumeMode == "True" ? true : false);
      this.ShowVolumeButton = true;
    });

    this.apiService.Get('/valet/summary').then((response: any) => {
      this.Summary = response.Items
      this.showAlerts = true;
      this.StopLoading();
  });
  };
  
  ToggleHighVolume(event: any){
    this.HighVolume = event.target.checked;
    this.apiService.Post("valet/highvolumemode", {HighVolumeMode : event.target.checked});
  }

  navigateToSessions(summaryItem: any) {
    if(summaryItem.Action == 'Redirect'){
      this.router.navigate([summaryItem.ActionProperty]);
    }
    else{
      this.router.navigate(['valet/sessions'], { queryParams: { filter: summaryItem.ActionProperty } });
    }
  }
}
