import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';
import { LoginServiceBase } from 'src/app/auth/login.service.base';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { BusyableModalComponent } from 'src/app/shared/editors/busyableModalComponent';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent extends BusyableModalComponent<null, any> implements OnInit {

  public Form !: UntypedFormGroup;
  public DefaultUserModel(): any {
    return {
      FirstName: "",
      LastName: "",
      Email: "",
      Password: "",
      OrganizationId: "" // this should default to the current user orgid
    }
  }

  private addUrl = '/users/user';

  constructor(private apiService: ApiServiceBase, private loginService: LoginServiceBase, private toastService: ToastrService) { super(); }


  ngOnInit(): void {
    this.Form = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
      lastName: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
      email: new UntypedFormControl('', [Validators.email, Validators.required]),

      organizationId: new UntypedFormControl(this.loginService.CurrentOrganizationId()),
      resetPasswordRequired: new UntypedFormControl(true)
    });
  }

  addUser() {
    this.Busy();
    let model = this.Form.getRawValue();

    this.apiService.Post(this.addUrl, model).then(result => {
      console.log(result);
      this.result = result;
      this.StopBusy();
      this.close();
    });
  }

  cancel() {
    this.result = null;
    this.close();
  }

  isFormValid() {
    return this.Form.valid;
  }

  isFormDirty() {
    return this.Form.dirty;
  }

}
