import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeaturesService } from 'src/app/Services/features.service';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { AlertsService } from 'src/app/Services/alerts.service';
import { LoginServiceBase } from 'src/app/auth/login.service.base';
import { environment } from 'src/environments/environment';
import { RevenueService } from 'src/app/Services/revenue.service';

@Component({
  selector: 'app-mainmenu',
  templateUrl: './mainmenu.component.html',
  styleUrls: ['./mainmenu.component.scss']
})
export class MainmenuComponent implements OnInit {

  public TopMenuItems: Array<any> = [];
  public Organizations: any[] = [];
  public Alerts: any[] = [];
  public CurrentOrganizationName: string = "";
  public ShowOrganizationsDropdown: boolean = false;
  public ShowAlertsDropdown: boolean = false;
  public UnresolvedAlertCount: any = 0;
  public ShowOperatorConsole = false;
  public ShowBanner = environment.bannerText != null;
  public BannerText = environment.bannerText;    

  constructor(public loginService: LoginServiceBase, private router: Router, private featuresService: FeaturesService, private organizationsService: OrganizationsService, private alertService: AlertsService, private revenueService : RevenueService) {
    this.loginService.LoginStatusChanged.subscribe(loggedIn => {
      if (loggedIn == true) {
        this.UpdateMenu();
        organizationsService.GetOrganizationName(this.loginService.CurrentOrganizationId()).then((x: any) => {
          this.CurrentOrganizationName = x
        });
      }
    });
    this.loginService.OrganizationChanged.subscribe(x => {
      organizationsService.GetOrganizationName(this.loginService.CurrentOrganizationId()).then((x: any) => {
        this.CurrentOrganizationName = x
      });
    });
    
    this.organizationsService.AvailableOrganizationsChanged.subscribe(result => {
      this.Organizations = organizationsService.GetAvailableOrganizations();
      organizationsService.GetOrganizationName(this.loginService.CurrentOrganizationId()).then((x: any) => {
        this.CurrentOrganizationName = x
      });
    });

    this.alertService.UserAlertsUpdated.subscribe(result => {
      this.Alerts = alertService.GetUserAlerts();
      this.UnresolvedAlertCount = alertService.GetUnresolvedUserAlerts();
    });
    this.featuresService.MenuChanged.subscribe(() =>{
      console.log("MainMenu component received event: MenuChanged. Updating menu DOM elements");
      this.UpdateMenu();
    });
  }

  ngOnInit(): void {
    if (this.loginService.IsLoggedIn() == false) {
      this.router.navigate(['login']);
    }

    this.UpdateMenu();
  }

  private UpdateMenu() {
    this.TopMenuItems = this.featuresService.GetTopMenu();
    //remove from list, and add icon if Operator Console Exists
    this.ShowOperatorConsole = (this.TopMenuItems.filter(x => x.Text == "Operator Console").length == 1);
    this.TopMenuItems = this.TopMenuItems.filter(x => x.Text != "Operator Console");
    console.log("Main Menu updated with " + this.TopMenuItems.length + " menu items from feature service");
  }

  public LogOut() {
    this.loginService.DoLogout();
  }

  public SwitchToOrganization(id: string) {
    this.ShowOrganizationsDropdown = false;
    this.loginService.DoOrganizationSwitch(id);
  }

  public ResolveAlert(alert: any) {
    this.alertService.ResolveAlert(alert);
  }

  public ViewEntity(alert: any) {
    //use the entityRoute on the alert, and the triggerId to navigate to the entity.
    this.router.navigate([alert.EntityRoute.replace(":id", alert.TriggerId)]).then(() =>{
      window.location.reload();
    });
  }

  public GetSubMenu(feature: string): any {
    var children = this.featuresService.GetMenuChildren(feature);
    return children;
  }
}
