<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Parent]="this" [Form]="Form" class="button is-primary" (OnSave)="Save()">
        </app-form-save-button>
    </app-pageheader>

    <app-tabs>
        <app-tab Title="Details">
            <div class="columns">
                <div class="column is-6">
                    <app-field Label="Chip ID" [HasAddons]="true">
                        <div class="control flex-grow">
                            <input formControlName="ChipId" class="input disabled" readonly>
                        </div>
                        <div class="control">
                            <a class="button is-static">
                                HID
                            </a>
                        </div>
                    </app-field>
                    <app-field Label="Frog ID">
                        <div class="control">
                            <input formControlName="Id" class="input disabled" readonly>
                        </div>
                    </app-field>
                    <app-field Label="PEK">
                        <div class="control">
                            <input formControlName="PrivateEncryptionKey" class="input disabled" readonly>
                        </div>
                    </app-field>
                    <app-field Label="Activated">
                        <div class="control">
                            <input formControlName="IsActivated" class="input disabled" readonly>
                        </div>
                    </app-field>
                    <app-field Label="Last Check In" [HasAddons]="true">
                        <div class="control flex-grow">
                            <input formControlName="LastCheckedInOnString" class="input disabled" readonly>
                        </div>
                        <div class="control">
                            <a class="button is-static">
                                {{TimeZoneName}}
                            </a>
                        </div>
                    </app-field>
                    <app-field Label="Last Non-Car Check In" [HasAddons]="true">
                        <div class="control flex-grow">
                            <input formControlName="LastNonCarCheckInOnString" class="input disabled" readonly>
                        </div>
                        <div class="control">
                            <a class="button is-static">
                                {{TimeZoneName}}
                            </a>
                        </div>
                    </app-field>

                </div>
                <div class="column is-6">
                    <app-map-input formControlName="LocationPoints" Mode="marker" MapWidth="100%"></app-map-input>
                </div>
            </div>
            <app-field Label="Linked Structures" [HasAddons]="true">
                <div class="control flex-grow" formArrayName="Relationships">
                    <table class="table is-striped is-bordered input-table">
                        <thead>
                            <tr>
                                <th>Parking Lot</th>
                                <th>Parking Level</th>
                                <th>Direction</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr [formGroupName]="i"
                                *ngFor="let line of FormArray(Form, 'Relationships').controls; let i = index">
                                <td>
                                    <div class="select">
                                        <select class="input" formControlName="ParkingLotId">
                                            <option *ngFor="let p of ParkingLots" [ngValue]="p.Id">{{p.Name}}</option>
                                        </select>
                                    </div>
                                </td>
                                <td>
                                    <div class="select">
                                        <select class="input" formControlName="ParkingLevelId">
                                            <option [ngValue]="null"></option>
                                            <option *ngFor="let l of LevelsForLot(line.get('ParkingLotId')?.value)"
                                                [ngValue]="l.Id">{{l.Name}}</option>
                                        </select>
                                    </div>
                                </td>
                                <td>
                                    <div class="select">
                                        <select class="input" formControlName="DirectionAIn" style="min-width: 10em;">
                                            <option [ngValue]="true">A is Entry</option>
                                            <option [ngValue]="false">A is Exit</option>
                                        </select>
                                    </div>
                                    <app-validator [For]="line.get('MaxMinutes')"></app-validator>
                                </td>
                                <td>
                                    <div class="control">
                                        <button class="button is-light" (click)="RemoveRelationship(i)">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tr>
                            <td colspan="4">
                                <div class="button is-primary" (click)="AddRelationship()">
                                    Add
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </app-field>
        </app-tab>
        <app-tab Title="Device Settings">
            <div class="columns">
                <div class="column is-6">
                    <app-field Label="Debugging">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select class="input" formControlName="DebugMessagesEnabled">
                                    <option [ngValue]="true">Enabled</option>
                                    <option [ngValue]="false">Disabled</option>
                                </select>
                            </div>
                        </div>
                    </app-field>
                    <app-field Label="Hardware Version" [HasAddons]="true">
                        <div class="control flex-leading-sm">
                            <input formControlName="HardwareVersionVersion" class="button is-static" readonly>
                        </div>
                        <div class="control flex-grow">
                            <input formControlName="HardwareVersionName" class="input disabled" readonly>
                        </div>
                    </app-field>
                    <app-field Label="Firmware Version" [HasAddons]="true">
                        <div class="control flex-leading-sm">
                            <input class="button is-static" formControlName="FirmwareVersionVersion" readonly>
                        </div>
                        <div class="control flex-grow">
                            <input formControlName="FirmwareVersionFileName" class="input disabled" readonly>
                        </div>
                        <div class="control">
                            <button class="button is-primary" (click)="SelectFirmwareVersion()">
                                change
                            </button>
                        </div>
                    </app-field>
                </div>
            </div>
        </app-tab>
        <app-tab Title="Messages">
            <app-debugview [ModelId]="this.ModelId">

            </app-debugview>
        </app-tab>
    </app-tabs>


</div>