import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, ElementRef, ViewChild, HostListener } from '@angular/core';

interface CategoryValue {
  Value: string;
  Description: string;
  Name: string;
  AllowArguments: boolean;
}

@Component({
  selector: 'app-categorized-selector',
  templateUrl: './categorized-selector.component.html',
  styleUrls: ['./categorized-selector.component.scss']
})
export class CategorizedSelectorComponent implements OnInit, OnDestroy {

  @Input()
  set transitions(value: { [key: string]: CategoryValue[] }) {
    if (value) {
      this.Categories = Object.entries(value).map(([stateId, events]) => ({
        category: stateId,
        items: events.map(event => ({
          Name: event.Name || event.Value || event.Description,
          Value: event.Value,
          Description: event.Description || event.Name,
          AllowArguments: event.AllowArguments,
        }))
      }));
      this.FilteredCategories = this.Categories;
    }
  }

  Categories: any[] = [];
  FilteredCategories!: any[];

  public SearchTerm: string = "";
  public IsDropdownOpen: boolean = false;
  public currentIndex = 0;

  @Output()
  selectionChanged = new EventEmitter<{event: CategoryValue}>();

  @ViewChild('input') inputElement!: ElementRef;

  constructor() {
  }

  public writeValue(val: string | null): void {
    this.SearchTerm = val ?? "";
  }

  ngOnInit(): void {
    this.FilteredCategories = this.Categories;
  }

  SetSearchTermEvent(event : any){
    this.currentIndex = 0;
    this.SearchTerm = event.target.value;
    this.FilterItems();
  }

  SelectItem(item: any) {
    this.SearchTerm = item.Name;
    this.IsDropdownOpen = false;
    this.selectionChanged.emit({
      event: item
    });
  }

  toggleDropdown() {
    this.IsDropdownOpen = !this.IsDropdownOpen;
  }

   HandleKeyDown(event: any){
    const allItems = this.FilteredCategories.flatMap(c => c.items);
    
    switch(event.key){
      case 'ArrowUp':
        if(this.currentIndex > 0){
          this.currentIndex = this.currentIndex - 1;
        }
        break; 
      case 'ArrowDown':
        if(this.currentIndex < (allItems.length -1)){
          this.currentIndex = this.currentIndex + 1;
        } 
        break;
      case 'Enter':
        if (allItems.length > 0) {
          this.SelectItem(allItems[this.currentIndex]);
        }
        break;
    }
  }

  FilterItems() {
    if (this.SearchTerm.trim() === '') {
      this.FilteredCategories = this.Categories;
      this.IsDropdownOpen = true;
    } else {
      const searchTerm = this.SearchTerm.trim().toLowerCase();
      this.FilteredCategories = this.Categories.map(category => {
        // If category name matches, include all items
        if (category.category.toLowerCase().includes(searchTerm)) {
          return {
            category: category.category,
            items: category.items
          };
        }
        // Otherwise only include matching items
        return {
          category: category.category,
          items: category.items.filter((item: any) => 
            item.Name.toLowerCase().includes(searchTerm)
          )
        };
      }).filter(category => category.items.length > 0);
    }
  }

  onInputClick() {
    this.IsDropdownOpen = true;
    setTimeout(() => this.positionDropdown(), 0);
  }

  positionDropdown() {
    const inputRect = this.inputElement.nativeElement.getBoundingClientRect();
    const dropdown = document.querySelector('.dropdown-menu') as HTMLElement;
    if (dropdown) {
      dropdown.style.setProperty('--dropdown-width', `${inputRect.width}px`);
      dropdown.style.top = `${inputRect.bottom}px`;
      dropdown.style.left = `${inputRect.left}px`;
    }
  }

  // Add HostListener for window resize
  @HostListener('window:resize')
  onResize() {
    if (this.IsDropdownOpen) {
      this.positionDropdown();
    }
  }

  // Add HostListener for scroll events on any parent
  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    if (this.IsDropdownOpen) {
      this.positionDropdown();
    }
  }

  ngOnDestroy() {
    // Clean up by closing dropdown if component is destroyed
    this.IsDropdownOpen = false;
  }

}
