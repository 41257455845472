<div class="modal is-active">
  <app-loader [Parent]="this"></app-loader>
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Confirm Delete</p>
    </header>
    <section class="modal-card-body" style="min-width: 800px;">
      <div *ngIf="deleteCheckResult == null; else resultAvailable">
        <div *ngIf="Error == null" class="has-text-centered is-normal">
          <p>Checking for references to this item...</p>
        </div>
        <div *ngIf="Error != null" class="has-text-centered is-normal">
          <p>{{Error}}</p>
        </div>
      </div>
      <ng-template #resultAvailable>
        <div class="is-normal" *ngIf="deleteCheckResult?.CanDelete; else cannotDelete">
          <p class="has-text-centered is-normal">
            Are you sure you want to delete "{{name}}"?
          </p>
          
          <div class="content" *ngIf="deleteCheckResult?.Messages?.length > 0">
            <p class="has-text-centered is-normal"> If you delete this object, the following items will be impacted.</p>
            <table>
              <tr *ngFor="let message of deleteCheckResult?.Messages">
                <td class="notification" style="list-style: none;" [ngClass]="{ 'is-warning': message['Level'] == 100, 'is-danger': message['Level'] == 200 }">
                  {{message["Message"]}}
                </td>
              </tr>
          </table>
          </div>

          <div class="has-text-centered mt-3">
            
            
          <footer class="modal-card-foot">
            <button class="button is-danger mr-3 button-width" (click)="delete()">Delete</button>
            <button class="button is-normal button-width" (click)="cancel()">Cancel</button>
          </footer>
            
          </div>

        </div>

        <ng-template #cannotDelete>
          <p class="is-normal">Unable to delete "{{name}}", there are one or more strongly dependent items which can not be removed.</p>
          <p class="is-normal">Any red items below are blocking {{name}} from being deleted.</p>
          <div class="content">
            <table>
              <tr *ngFor="let message of deleteCheckResult?.Messages">
                <td class="notification" style="list-style: none;" [ngClass]="{ 'is-warning': message['Level'] == 100, 'is-danger': message['Level'] == 200 }">
                  {{message["Message"]}}
                </td>
              </tr>
            </table>
          </div>

          <footer class="modal-card-foot">
            <button class="button is-normal button-width" (click)="cancel()">Cancel</button>
          </footer>
          
        </ng-template>
      </ng-template>


    </section>
  </div>
</div>