import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, Self, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { AddUserComponent } from 'src/app/features/users/users/modals/add-user/add-user.component';

@Component({
  selector: 'app-singleuserselector',
  templateUrl: './singleuserselector.component.html',
  styleUrls: ['./singleuserselector.component.scss']
})
export class SingleUserSelectorComponent implements OnInit, ControlValueAccessor, OnChanges {

  public UserId: string | null = null;
  @Output()
  public UserIdChange = new EventEmitter<string | null>();
  @Output()
  public UserCleared = new EventEmitter<string | null>();

  public InitialString: string = "";
  @Input()
  public User: any = { Email: "", FirstName: "", LastName: "" };

  @Input()
  public AllowCreateUser: boolean = false;

  public SearchResults: any[] = [];
  public SearchOn: string = "Name";
  public IsLoading: boolean = false;

  constructor(private apiService: ApiServiceBase, @Self() public ngControl: NgControl, private modalService: SimpleModalService) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['User'].previousValue == null || changes['User'].currentValue.Id == changes['User'].previousValue.Id) return
    this.UserId = this.User.Id;
    this.onChange(this.User.Id);
    this.UserIdChange.emit(this.User);
  }

  ngOnInit(): void {
  }

  public writeValue(id: string | null): void {
    if(id == this.UserId) return;
    this.UserId = id;
    if (this.UserId == "") this.User = null;
  }

  public onChange = (id: string | null) => { };
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public onTouched = () => { };
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public ClearUser() {
    this.UserCleared.emit(this.UserId);
    this.UserId = null;
    this.onChange(null);
  }
  public Cleared() {
    this.UserId = null;
    this.onChange(null);
  }
  
  public KeyPress(evt: any) {
    if (evt.keyCode == 13) {
      evt.stopPropagation();
      console.log("intercepted enter key on user selector");
    }
  }

  public UserChanged(user : any){
    this.onChange(user.Id);
    this.User = { Email: user.Email, FirstName: user.FirstName, LastName: user.LastName};
  }
}
