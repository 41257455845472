import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import * as THREE from "three";
import { Camera, Vector2, Vector3 } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Geo } from 'src/app/util/geo';
import  * as TWEEN from '@tweenjs/tween.js';
import { LevelMesh } from '../levelmapviewer/geometry/level-mesh';
import { OccupancyService } from 'src/app/Services/occupancy.service';
import { Subscription } from 'rxjs';
import { meshanimationresult } from '../levelmapviewer/geometry/meshanimationresult';

@Component({
  selector: 'app-lotmultiviewer',
  templateUrl: './lotmultiviewer.component.html',
  styleUrls: ['./lotmultiviewer.component.scss']
})
export class LotmultiviewerComponent implements AfterViewInit, OnDestroy {
 
  @Input()
  public Width : number = 600;
  @Input()
  public Height : number = 200;
  
  @ViewChild("canvas")
  public Canvas!: ElementRef;
  @ViewChild("lotviewerparent")
  public lotviewerparent !: ElementRef;

  private Camera!: THREE.OrthographicCamera;
  private TextureLoader!: THREE.TextureLoader;
  private Renderer!: THREE.WebGLRenderer;
  private mixers!: meshanimationresult[];
  private Scene = new THREE.Scene();
  private Orbit !: OrbitControls;
  private CurrentLevelIndex = 0;
  private Levels : LevelMesh[] = [];
  public Lots : any[] = [];
  public Index : number = 1;
  private OccupancySubscription : Subscription;
  private LocationIncrement : number = 0.11;


  constructor(private apiService : ApiService, private occupancyService : OccupancyService) { 
    this.OccupancySubscription = occupancyService.OccupancyChanged.subscribe(result =>{
      for(let lot of this.Lots){
        occupancyService.GetOccupancy(lot.Id).then((x: any) => {
          lot.TotalSpaces = x.TotalSpaces;
          lot.OccupiedSpaces = x.OccupiedSpaces;
        });
      }
    });
  }
  ngOnDestroy(): void {
    if(this.OccupancySubscription != null){
      this.OccupancySubscription.unsubscribe();
    }
    this.Renderer.dispose();
  }

  ngAfterViewInit(): void {
    this.Scene.background = new THREE.Color(0xf5f5f5);
    this.Renderer = new THREE.WebGLRenderer({ canvas : this.Canvas.nativeElement, antialias: false });
    this.Width = this.lotviewerparent.nativeElement.offsetWidth;
    this.Renderer.setSize(this.Width, this.Height);
    this.Camera = new THREE.OrthographicCamera(0, 0.48, 0.1, -0.15, 0.00001, 2000);
    const controls = new OrbitControls(this.Camera, this.Renderer.domElement);
    this.Camera.up.set( 0, 0, 1 );
    this.Camera.position.x = -0.3;
    this.Camera.position.y = -0.3;
    this.Camera.position.z = 0.3;
    this.Orbit = controls;
    controls.target = new Vector3(.00,.00,0.06);
    controls.enabled = false;

    const light = new THREE. AmbientLight( 0xffffff, 0.6);
    light.position.set( -1, 1, 1 );
    this.Scene.add( light );
    const light2 = new THREE.DirectionalLight( 0xffffff, 2,);
    light2.position.set( 1.1, -1, 1 );
    this.Scene.add( light2 );

    this.apiService.Get<any>("infrastructure/parkinglots/geometry").then(results => {
      let location = new Vector3(0,0,0);
      let filteredResults = [];
      for(let result of results){
        if(result.Levels.length < 2) console.log("LotMultiViewer skipping lot " + result.Name + " as it has no levels");
        else filteredResults.push(result);
      }
      if(filteredResults.length == 1){
        //only a single entry so skip first position so it appears in the middle
        location.x += this.LocationIncrement;
        location.y -= this.LocationIncrement;
      }
      for(let result of filteredResults){
        console.log("Adding multiviewer lot " + result.Name + " at " + location.x + "," + location.y);
        let level1 = result.Levels[0];
        let levelBounds = Geo.GetPolygonPointBounds(level1);
        let w = levelBounds.Right-levelBounds.Left;
        let x = levelBounds.Left + (w)/2;
        let y = -1*(levelBounds.Top + (levelBounds.Bottom-levelBounds.Top)/2);

        
        if(result.Levels != null && result.Levels.length > 1){
          let bounds = Geo.GetPolygonPointBounds(result.Levels[0].PolygonPoints);
          if(bounds.Width == 0 || bounds.Height == 0){
            console.log(result.Name + " first level is zero size, skipping lot");
            continue;
          }
          this.Lots.push(result);
          let scaleX :number = 1, scaleY :number = 1;
          if(bounds.Width > bounds.Height){
            scaleX = (bounds.Height/bounds.Width);
            scaleY = 0.1/bounds.Height;
            scaleX *= scaleY;
          }
          else{
            scaleY = (bounds.Width/bounds.Height);
          }

          let levels : any[] = result.Levels.sort((n1:any, n2:any) => { return n1.LevelNumber < n2.LevelNumber });
          for(let i=0; i < levels.length; i++){
            let level = levels[i];
            if(level.PolygonPoints != null && level.PolygonPoints.length > 1){
              console.log("Adding level " + i + " to lot " + result.Name);
              let l = new LevelMesh(this.Scene, level, new Vector3(location.x,location.y, 0.02 * i), 0.02, new Vector2(scaleX, scaleY), this.Camera, this.mixers, {ShowAccessControl: false, ShowSpaces: true, ShowShapes: true, ShowWalls:true, ShowGardens: false});
              this.Levels.push(l);
            }
          }
          location.x += this.LocationIncrement;
          location.y -= this.LocationIncrement;
        }
        this.UpdateLabelPositions(); 
      }

    });
    this.MainRenderLoop();
  }

  


  private MainRenderLoop(){
    let renderer = this.Renderer;
    let scene = this.Scene;
    let camera = this.Camera;
    let controls = this.Orbit;
    (function render() {
      if(controls)
        controls.update();
      TWEEN.update();
      requestAnimationFrame(render);
      renderer.render(scene, camera);
    }());
  }

  public SlideRight(){
    if(this.Index == this.Lots.length+1){
      return;
    }
    this.Index += 1;
    new TWEEN.Tween( this.Camera.position).to({ x: this.Camera.position.x + 0.11, y: this.Camera.position.y - 0.11, }, 500).easing(TWEEN.Easing.Cubic.InOut).start();
    new TWEEN.Tween(this.Orbit.target).to({ x: this.Orbit.target.x + 0.11, y: this.Orbit.target.y - 0.11, }, 500).easing(TWEEN.Easing.Cubic.InOut).start();
    for(let l of this.Lots){
      new TWEEN.Tween(l).to({ LabelPosition: l.LabelPosition - (this.Width/3) }, 500).easing(TWEEN.Easing.Cubic.InOut).start();
    }
  }
  public SlideLeft(){
    if(this.Index == 0){
      return;
    }
    this.Index -= 1;
    new TWEEN.Tween( this.Camera.position).to({ x: this.Camera.position.x - 0.11, y: this.Camera.position.y + 0.11, }, 500).easing(TWEEN.Easing.Cubic.InOut).start();
    new TWEEN.Tween(this.Orbit.target).to({ x: this.Orbit.target.x - 0.11, y: this.Orbit.target.y + 0.11, }, 500).easing(TWEEN.Easing.Cubic.InOut).start();
    for(let l of this.Lots){
      new TWEEN.Tween(l).to({ LabelPosition: l.LabelPosition + (this.Width/3) }, 500).easing(TWEEN.Easing.Cubic.InOut).start();
    }
  }
  public UpdateLabelPositions(){
    for(let i=0; i < this.Lots.length; i++){
      let index : number = i;
      if(this.Lots.length == 1){
        //single lot so push label to middle to match the centering of the model
        index += 1;
      }
      this.Lots[i].LabelPosition = (this.Width/3 * (index+1))-(this.Width/6)-(this.Lots[i].Name.length*5);
    }
  }
}
