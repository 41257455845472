import { Component, Injector, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiService } from 'src/app/Services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { UntypedFormArray, Validators } from '@angular/forms';

@Component({
  selector: 'app-editthresholdset',
  templateUrl: './editthresholdset.component.html',
  styleUrls: ['./editthresholdset.component.scss']
})
export class EditthresholdsetComponent extends ModelEditor implements OnInit {

  constructor(private injector: Injector) {
    super("infrastructure/thresholdsets", injector);
  }

  public DefaultModel() {
    return {
      Name: "New Threshold Set",
      ParkingThresholdColors: [
        {
          Color: "ffffff",
          Description: "New",
          ThresholdPercent: 0,
          CloseWhenReached: false
        }
      ],
    };
  }
  public AfterModelLoaded(): void {
  }
  public BeforeSave(): void {
  }

  public override FormLabels(): any {
    return {
      ThresholdPercent: 'Threshold Percent',
    }
  }
  public override Validators(): any {
    return {
      "Name": [Validators.required, Validators.minLength(1)],
      "ParkingThresholdColors.ThresholdPercent": [Validators.required, Validators.max(100), Validators.min(0)]
    };
  }

  public AddParkingThresholdColor() {
    ///if(this.Model.RuleLines == null) this.Model.RuleLines = [];
    let newline = { Color: "ffffff", Description: "New", ThresholdPercent: 0,  CloseWhenReached : false}
    this.AddToFormArray(this.Form.get('ParkingThresholdColors') as UntypedFormArray, newline, "ParkingThresholdColors");
  }

  public RemoveParkingThresholdColor(index: number) {
    this.FormArray(this.Form, "ParkingThresholdColors").removeAt(index);
    this.Form.markAsDirty();
  }
}