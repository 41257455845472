<app-loader [Parent]="this"></app-loader>
<div *ngIf="Model != null">
  <app-pageheader [Parent]="this">
    <div style="display: inline-flex;">
      <valetactionbuttons #ValetButtons [ModelId]="Model.Id" [AllowedActions]="Model.Actions"></valetactionbuttons>
      <form-edit-button>Edit</form-edit-button>
    </div>
  </app-pageheader>
  <div class="columns">
    <div class="column is-6">
      <article class="panel">
        <p class="panel-heading">
          Valet Session Details
          <span><button class="button" (click)="ShowHistory()" style="float:right; height:100%; background:none; border:none; padding-top:0px; font-size: 0.8em;"><i class="fa-solid fa-clock-rotate-left" style="margin-right:10px;"></i> History</button></span>
        </p>
        <div class="panel-block">
          <span class="label">Ticket Number:</span>
          <span class="value"><input class="button" style="height:2em; text-align: center; pointer-events:none; font-weight: bold;" [value]="Model.TicketNumber"/></span>
          <span class="value">
            <button class="button" style="height:2em !important; border:none;">
              <i class="fa fa-copy" style="width:1em !important; color: lightgray" (click)="CopyToClipboard(Model.TicketNumber)"></i>
            </button>          
          </span>
        </div>
        <div class="panel-block">
          <span class="label">Customer Details:</span>
          <span class="value">{{Model.FullName}}</span>
        </div>
        <div class="panel-block" *ngIf="Model.ValetTypeId != null">
          <a [routerLink]="'/valet/types/' + Model.ValetTypeId" style="text-decoration: underline; cursor: pointer;">
            <span class="label">Valet Type:</span>
            <span class="value">
              <i [ngClass]="'Icon fa fa-' + Model.ValetTypeIconName" [ngStyle]="{color: '#' + Model.ValetTypeIconColor}"></i>
            </span>
            <span class="value"> - {{Model.ValetTypeName}}</span>
          </a>
        </div>
        <div class="panel-block">
          <span class="label">Contact Number:</span>
          <span class="value">{{Model.PhoneNumber}}</span>
        </div>
        <div class="panel-block">
          <span class="label">Vehicle Details:</span>
          <span class="value">{{Model.VehicleDescription}}</span>
        </div>
        <div class="panel-block" *ngIf="Model.ParkedAt != null">
          <span class="label">Parked at:</span>
          <span class="value">{{Model.ParkedAt}}</span>
        </div>
        <div class="panel-block" *ngIf="Model.KeysLocation != null">
          <span class="label">Keys Location:</span>
          <span class="value disabled">{{Model.KeysLocation}}</span>
        </div>
        <div class="panel-block" *ngIf="Model.KeyTagId != null">
          <span class="label">Key Tag:</span>
          <span class="value">{{Model.KeyTagId}}</span>
        </div>
        <div class="panel-block" *ngIf="Model.ValetTypeId != null">
          <span class="label">Billing:</span>
          <span class="value" *ngIf="!Model.HasFault">{{Model.BillingKeyDescription}}</span>
          <span class="value" *ngIf="Model.HasFault" style="color:red;">UNKNOWN</span>
        </div>
        <div class="panel-block" *ngIf="Model.RateSetId != null">
          <span class="label">Rate Set</span>
          <span class="value">
            {{Model.ParkingRateSetName}}
          </span>
        </div>
        <div class="panel-block" *ngIf="Model.TotalPaymentRequired > 0">
          <span class="label">Payment Details</span>
          <span class="value">
            {{orgService.GetCurrencySymbol()}}{{Model.TotalPaid}} of {{orgService.GetCurrencySymbol()}}{{Model.TotalPaymentRequired}} paid ({{orgService.GetCurrencySymbol()}}{{Model.TotalOutstanding}} outstanding)
            <a class="fas fa-plus is-primary ml-3" (click)="MakeManualPayment()" tippy="Record a payment against this session"></a>
          </span>
        </div>
        <div class="panel-block">
          <span class="label">Status:</span>
          <span class="value">
            <p style="text-align:end; margin-right:2em; width:6em; font-weight:600;" [ngStyle]="{'color': '#' + Model.State.ForegroundColor}" class="Text">{{Model.State.DisplayName}}<i [ngClass]="'Icon fa fa-' + Model.State.IconName" style="font-size: x-large; margin-top: 0px !important; position:absolute;"></i><p>
          </span>
          <span class="value" style="margin-left:10px;" *ngFor="let issue of Model.Issues">
            <i [tippy]="issue.IssueDescription" [ngClass]="'Icon fa fa-' + issue.IssueIcon + ' Flash ' + issue.Severity + ' tippy-color-' + issue.IssueIconColor" style="width:1.5em !important; font-size:x-large" [ngStyle]="{'color' : '#' +  issue.IssueIconColor}"></i>
          </span>
        </div>
        <div class="panel-block" *ngIf="Model.Status == 'Closed'">
          <span class="label">Closed At:</span>
          <span class="value">{{Model.ClosedAtLocalFriendlyString}}</span>
        </div>
        <div class="panel-block">
          <span class="label">Public Code:</span>
          <span class="value"><a [href]="Model.PublicPortalLink">{{Model.SessionShortCode}}</a></span>
        </div>
      </article>
    </div>
    <div class="column is-6 image-container">
      <img src="../../../../../assets/images/Valet-session.png" alt="FrogOne Valet Sessions" />
    </div>
  </div>
  <div class="columns">
    <div class="column is-12" >
        <article class="panel file uploadpanel" [ngClass]="{'dragging': isDragging}" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">
          <button class="button is-primary upload"><i class="fa fa-upload" style="margin-right:10px;"></i> Upload</button>
          <app-media-thumbnail *ngFor="let image of Model.VehicleImages; let i = index;" [ModelId]="image.Id" [MediaId]="image.MediaFileId" [tippy]="'Taken ' + image.DateTakenLocalFriendlyString" [ShowDelete]="true" (Deleted)="RemoveImage(image)"></app-media-thumbnail>
          <input type="file" class="file-input" [accept]="RequiredFileType" (change)="onFileSelected($event)" #fileUpload>
          <div [ngClass]="isDragging ? ' text dragging' : 'text hidden'">Drop file here to upload</div>
        </article>
    </div>
  </div>
  <div class="columns">
    <div class="column is-12">
        <app-datalistview #DataList [Columns]="Columns" [ShowMap]="false" ApiUrl='valet/sessions/{{Model.Id}}/parkingsessions/searches'
          [AllowEdit]="false" [ShowPaging]="false" [EditUrlPerItem]="false" [HideSearch]="true" [HighlightOnSelect]="false"></app-datalistview>
    </div>
  </div>
  <div class="columns">
    <div class="column is-6">
      <article class="panel">
        <p class="panel-heading">
          Addons
          <span><button class="button is-primary" (click)="AddAddon()" style="
            height: 100%;
            font-size: 0.8em;
            padding: 0.2em;
            width: 9em;
            margin-left: 2em;"><i class="fa-solid fa-plus" style="margin-right:10px;"></i>Add</button></span>
        </p>
        <app-datalistview #AddonsDataList [Columns]="AddonColumns" [ShowColumnHeaders]="false" [ShowMap]="false" ApiUrl='valet/sessions/{{Model.Id}}/addons/searches'
          [AllowEdit]="false" [ShowPaging]="false" [EditUrlPerItem]="false" [HideSearch]="true" [AllowDropdownActions]="true" [UseActionsOnEachItem]="true" (HandleDropdownAction)="performAddonAction($event)" [HighlightOnSelect]="false"
          ></app-datalistview>
      </article>
    </div>
    <div class="column is-6">
      <article class="panel">
        <p class="panel-heading">
          Payments
        </p>
        <app-datalistview #PaymentsDataList [Columns]="PaymentColumns" [ShowMap]="false" ApiUrl='valet/sessions/{{Model.Id}}/payments/searches'
          [AllowEdit]="false" [ShowPaging]="false" [EditUrlPerItem]="false" [HideSearch]="true" [ShowColumnHeaders]="false" [HighlightOnSelect]="false"
          [AllowCustomAction]="true" CustomActionText="Record Refund" CustomActionIcon="rotate-left" (CustomActionEvent)="RecordRefund($event)"
          CustomActionConditionalField="TransactionReference" CustomActionConditionalValue="REFUND" CustomActionConditionalComparator="NOT contains"
          ></app-datalistview>
        </article>
    </div>
  </div>
</div>
