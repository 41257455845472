import { EditGuidanceLightComponent } from "src/app/features/hardware/devices/guidancelights/edit/editguidancelight.component";
import { LevelEditorComponent } from "../../leveleditor.component";
import { PointMapComponent } from "./PointMapComponent";

export class GuidanceLight extends PointMapComponent{
    public override ClassName: string = "GuidanceLightConfiguration";
    public override ImageUrl: string = "/assets/leveleditor/guidancelight.png";
    public override ImageScale = 0.05;
    public override CanDuplicate: boolean = false;
    public override RequiresParent: boolean = true;
    public override ParentClassName: string = "Row";
    public override IsGuidId: boolean = false;
    
    constructor(leveleditorcomponent: LevelEditorComponent, canvas: any, formItem: any, parentFormItem: any, forceClone: boolean = false) {
        super(leveleditorcomponent, canvas, formItem, parentFormItem, forceClone);
        this.AddFabricItem();
    }

    public override Clone(formitem: any){
        return new GuidanceLight(this.leveleditorcomponent, this.canvas, formitem, this.parentFormItem, true);
    }

    public override ShowModal(): void {
        // this.leveleditorcomponent.modalService.addModal(EditGuidanceLightComponent, { Form: this.formItem })
        // .subscribe((result) => {
        // });
    }
}