import { Component, DoCheck, ElementRef, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-validated-input',
  templateUrl: './validated-input.component.html',
  styleUrls: ['./validated-input.component.scss'],
})
export class ValidatedInputComponent implements DoCheck {

  @Input()
  LabelText : string = '';
  @Input()
  public Input : any;
  @Input()
  ErrorDefs : any;
  @Input()
  HasAddonsClass : boolean = false;
  
  public ErrorMessage : string = '';
  
  ngDoCheck(): void {
    this.ErrorMessage = '';
    if(this.ErrorDefs == null)
      return;
    Object.keys(this.ErrorDefs).some((key : any) => {
      if (this.Input.validity[key] == true) {
        this.ErrorMessage = this.ErrorDefs[key];
        return true;
      }
      return false;
    });
      
    
  }

}
