import { Geo } from "src/app/util/geo";
import { StructureViewerMapsComponent } from "./structureviewermaps.component";
import { SafeHtml, SafeUrl } from "@angular/platform-browser";
import { MapPolygon } from "@angular/google-maps";

export abstract class StructureViewerItemBase {
    
    public Id: string | undefined = "";
    public Name: string | undefined = "";
    public Index = 0;
    public ParentId !: string;
    public ClassName !: string;
    public Description !: string;
    public Reroute !: string;
    
    constructor(structure: any) {
        this.Id = structure.Id;
        this.Name = structure.Name;
        this.Index = (structure.ParkingLotLevelNumber) ?? 0;
        this.ParentId = structure.ParentId;
        this.Description = structure.Description;
    }

    public GetImage(top: number, height: number, left: number): SafeUrl | null {
        return null;
    }   
}

export class ParkingStructurePointItem extends StructureViewerItemBase{
    public GeoLocationPoints !: google.maps.LatLngLiteral;
    public MapPoint !: google.maps.Marker;
    public CustomMarker !: google.maps.Marker;
    public Url !: string;
    constructor(structure: any){
        super(structure);
    }

    public AddPoint(structure: any){
        if(structure.GeoLocationPoints != null && structure.GeoLocationPoints != undefined){
            this.GeoLocationPoints = {lat: structure.GeoLocationPoints[1], lng: structure.GeoLocationPoints[0]};
        }

        const point = new google.maps.Marker({
            position: this.GeoLocationPoints,
            zIndex: this.Index
        });
      
        this.MapPoint = point;
    }
}


export class ParkingStructurePolygonItem extends StructureViewerItemBase {
    public GeoPolygonPoints !: google.maps.LatLngLiteral[];
    public GeoPolygonCenter !: google.maps.LatLngLiteral;
    public GeoLocationPoints !: google.maps.LatLngLiteral;
    public MapPolygon !: google.maps.Polygon;
    public StrokeColor !: string;
    public StrokeWeight = 2;
    public FillColor !: string | null;
    public FillOpacity = 0;

    constructor(structure: any){
        super(structure);
    }

    public AddPolygon(structure: any){
        if(structure.GeoLocationPoints != null && structure.GeoLocationPoints != undefined){
            this.GeoLocationPoints = {lat: structure.GeoLocationPoints[1], lng: structure.GeoLocationPoints[0]};
        }

        if(structure.GeoPolygonCenter != null && structure.GeoPolygonCenter != undefined){
            this.GeoPolygonCenter = {lat: structure.GeoPolygonCenter[1], lng: structure.GeoPolygonCenter[0]};
        }
        
        if(structure.GeoPolygonPoints != null && structure.GeoPolygonPoints != undefined){
            let poly: google.maps.LatLngLiteral[] = [];
            for (let j = 0; j < structure.GeoPolygonPoints.length; j++) {
                poly.push({ lat: structure.GeoPolygonPoints[j][1], lng: structure.GeoPolygonPoints[j][0] });
            }

            if(poly.length > 0){
                this.GeoPolygonPoints = poly;   

                const polygon = new google.maps.Polygon({
                        paths: this.GeoPolygonPoints,
                        strokeColor: this.StrokeColor,
                        strokeOpacity: 1,
                        strokeWeight: this.StrokeWeight,
                        fillColor: this.FillColor,
                        fillOpacity: this.FillOpacity,
                        zIndex: this.Index
                });
                  
                this.MapPolygon = polygon;
            }

            return;
        }
    }
}

export class ParkingLotStructureItem extends ParkingStructurePolygonItem {
    public override ClassName = "ParkingLot";
    constructor(structure: any) {
        super(structure);

        this.FillColor = '#' + structure.DisplayColor;
        this.FillOpacity = 0.2;
        this.StrokeColor = '#' + structure.DisplayColor;
        this.Index = -1;
        this.Reroute = "/parking/parking/lots/" + structure.Id;
        this.AddPolygon(structure);
    }
}


export class ParkingLevelStructurePolygonItem extends ParkingStructurePolygonItem{
    public ParkingLevelId !: string;
    constructor(structure: any) {
        super(structure)
        this.ParkingLevelId = structure.ParkingLevelId;
        this.Index = structure.ParkingLotLevelNumber;
    }
}

export class ParkingLevelStructurePointItem extends ParkingStructurePointItem{
    public ParkingLevelId !: string;

    constructor(structure: any) {
        super(structure)
        this.ParkingLevelId = structure.ParkingLevelId;
    }
}

export class ParkingLevelStructure extends ParkingLevelStructurePolygonItem{
    public override ClassName = "ParkingLevel";
    constructor(structure: any) {
        super(structure)
        this.FillColor = "#B3B3B3";
        this.FillOpacity = 1;
        this.StrokeColor = "grey";
        this.StrokeWeight = 3;
        this.Description = structure.Description;
        this.Reroute = "/parking/parking/lots/" + structure.ParentId + "/levels/" + structure.Id;
        this.AddPolygon(structure);
    }
}

export class ParkingSpaceStructureItem extends ParkingLevelStructurePolygonItem {
    public override ClassName = "ParkingSpace";
    public override StrokeColor: string = "white"; 
    public override FillColor: string | null =  null; 
    constructor(structure: any) {
        super(structure);
        this.Index = structure.ParkingLotLevelNumber;
        if(structure.ParkingSpaceTypeColor != (null || undefined)){
            this.FillColor = '#' + structure.ParkingSpaceTypeColor;
            this.FillOpacity = 0.2;
            this.StrokeColor = '#' + structure.ParkingSpaceTypeColor;
        }

        this.AddPolygon(structure);
    }
}

export class StructureParkingLevelMapItem extends ParkingLevelStructurePolygonItem{
    public override ClassName = "ParkingLevelMapItem";
    constructor(structure: any) {
        super(structure);
        this.Index = structure.ParkingLotLevelNumber;
        this.FillColor =  "#" + structure.FillColor;
        this.FillOpacity = structure.Opacity;
        this.StrokeColor = "#" + structure.OutlineColor;
        this.AddPolygon(structure);
    }
}

export class SensorStructureItem extends ParkingLevelStructurePointItem{
    public override ClassName = "IRSensor";
    public Selected: boolean = false;
    public IsOccupied: boolean = false;
    public ParkingSpaceId !: string;
    constructor(structure: any){
        super(structure);
        this.IsOccupied = structure.IsOccupied;
        this.ParkingSpaceId = structure.ParkingSpaceId;
        this.AddPoint(structure);
        this.Reroute = "/parking/guidance/sensors/" + structure.Id + '/edit';
        this.getMarkerIcon();
    }

    getMarkerIcon() {
        this.MapPoint.setIcon({
          url: `assets/deviceicons/${this.IsOccupied ? 'occupied' : 'vacant'}.gif`,
          anchor: new google.maps.Point(13, 13),
          scaledSize: new google.maps.Size(26, 26)
        });
    }
}

export class TerminalStructureItem extends ParkingLevelStructurePointItem{
    public override ClassName = "LocalController";
    public Selected: boolean = false;
    public Status !: string;
    constructor(structure: any){
        super(structure);
        this.Index = structure.ParkingLotLevelNumber;
        this.Status = structure.Status;
        this.AddPoint(structure);
        this.Reroute = "/parking/accesscontrol/terminals/" + structure.Id;
        this.getMarkerIcon();
    }

    getMarkerIcon() {
        this.MapPoint.setIcon({
          url: `assets/deviceicons/LocalController_${(this.Status == ("New" || "0") ? "Ok" : this.Status)}.svg`,
          anchor: new google.maps.Point(13, 13),
          scaledSize: new google.maps.Size(26, 26),
        });
    }
}

export class SignStructureItem extends ParkingLevelStructurePointItem{
    public override ClassName = "SignConfiguration";
    public SignNumber : number = 0;
    public ZoomLevel: number | undefined = 16;
    public Selected: boolean = false;
    constructor(structure: any){
        super(structure);
        this.Index = structure.ParkingLotLevelNumber;
        this.AddPoint(structure);
        this.UpdateMarkerNumber(this.SignNumber);
        this.BuildNewUrlForNumber(this.ZoomLevel);
        this.Reroute = "/hardware/devices/signs/" + structure.Id;
    }
    
    public UpdateMarkerNumber(newNumber: number){
        this.SignNumber = newNumber;
    }

    public BuildNewUrlForNumber(zoomLevel: number | undefined){
        if (zoomLevel && zoomLevel <= 19) {
            this.Url = 'data:image/svg+xml;charset=UTF-8,<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><rect width="16" height="16" rx="4" ry="4" fill="black" stroke="' + (this.Selected ? 'green' : 'black') + '" stroke-width="' + (this.Selected ? 4 : 2) + '"/><text x="50%" y="50%" text-anchor="middle" fill="white" font-size="7" dy=".3em">' + this.SignNumber + '</text></svg>'; 
            this.MapPoint.setIcon({
              url: this.Url,
              anchor: new google.maps.Point(8, 8), // Adjust the anchor point for the smaller icon
            });
        }
        else {
            this.Url = 'data:image/svg+xml;charset=UTF-8,<svg width="48" height="48" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" rx="4" ry="4" fill="black" stroke="' + (this.Selected ? 'green' : 'black') + '" stroke-width="' + (this.Selected ? 4 : 2) + '"/><text x="50%" y="50%" text-anchor="middle" fill="white" font-size="21" dy=".3em">' + this.SignNumber + '</text></svg>'; 
            this.MapPoint.setIcon({
              url: this.Url,
              anchor: new google.maps.Point(16, 16), // Adjust the anchor point for the larger icon
            });
        }
        return this.Url;
    };
}

export class GatewayStructureItem extends ParkingLevelStructurePointItem{
    public override ClassName = "GatewayConfiguration";
    public SignNumber : number = 0;
    public ZoomLevel: number | undefined = 16;
    public Selected: boolean = false;
    constructor(structure: any){
        super(structure);
        this.Index = structure.ParkingLotLevelNumber;
        this.AddPoint(structure);
        this.BuildNewUrlForNumber(this.ZoomLevel);
        this.Reroute = "/hardware/devices/gateways/" + structure.Id;
    }
    
    public BuildNewUrlForNumber(zoomLevel: number | undefined){
        if (zoomLevel && zoomLevel <= 19) {
            this.Url = '/assets/levelmap/gateway.svg'; 
            this.MapPoint.setIcon({
              url: this.Url,
              anchor: new google.maps.Point(8, 8), // Adjust the anchor point for the smaller icon
              scaledSize: new google.maps.Size(16, 16) // Set the desired width and height of the icon
            });
        }
        else {
            this.Url = '/assets/levelmap/gateway.svg'; 
            this.MapPoint.setIcon({
              url: this.Url,
              anchor: new google.maps.Point(16, 16), // Adjust the anchor point for the larger icon
              scaledSize: new google.maps.Size(48, 48) 
            });
        }
        return this.Url;
    };
}

export class GuidanceLightStructureItem extends ParkingLevelStructurePointItem{
    public override ClassName = "GuidanceLightConfiguration";
    public ZoomLevel: number | undefined = 16;
    public Selected: boolean = false;
    constructor(structure: any){
        super(structure);
        this.Index = structure.ParkingLotLevelNumber;
        this.AddPoint(structure);
        this.BuildNewUrlForNumber(this.ZoomLevel);
        this.Reroute = "/hardware/devices/guidancelights/" + structure.Id;
    }
    
    public BuildNewUrlForNumber(zoomLevel: number | undefined){
        if (zoomLevel && zoomLevel <= 19) {
            this.Url = '/assets/levelmap/guidancelight.svg'; 
            this.MapPoint.setIcon({
              url: this.Url,
              anchor: new google.maps.Point(8, 8), // Adjust the anchor point for the smaller icon
              scaledSize: new google.maps.Size(16, 16) // Set the desired width and height of the icon
            });
        }
        else {
            this.Url = '/assets/levelmap/guidancelight.svg'; 
            this.MapPoint.setIcon({
              url: this.Url,
              anchor: new google.maps.Point(16, 16), // Adjust the anchor point for the larger icon
              scaledSize: new google.maps.Size(48, 48) 
            });
        }
        return this.Url;
    };
}

export class CarCounterStructureItem extends ParkingLevelStructurePointItem{
    public override ClassName = "CarCounterConfiguration";
    public SignNumber : number = 0;
    public ZoomLevel: number | undefined = 16;
    public Selected: boolean = false;
    constructor(structure: any){
        super(structure);
        this.Index = structure.ParkingLotLevelNumber;
        this.AddPoint(structure);
        this.BuildNewUrlForNumber(this.ZoomLevel);
        this.Reroute = "/hardware/devices/carcounters/" + structure.Id;
    }
    
    public BuildNewUrlForNumber(zoomLevel: number | undefined){
        if (zoomLevel && zoomLevel <= 19) {
            this.Url = '/assets/levelmap/carcounter.svg'; 
            this.MapPoint.setIcon({
              url: this.Url,
              anchor: new google.maps.Point(8, 8), // Adjust the anchor point for the smaller icon
              scaledSize: new google.maps.Size(16, 16) // Set the desired width and height of the icon
            });
        }
        else {
            this.Url = '/assets/levelmap/carcounter.svg'; 
            this.MapPoint.setIcon({
              url: this.Url,
              anchor: new google.maps.Point(16, 16), // Adjust the anchor point for the larger icon
              scaledSize: new google.maps.Size(48, 48) 
            });
        }
        return this.Url;
    };
}