<div class="field is-horizontal mb-3">
    <div class="field-label is-normal">
        <label class="label">{{LabelText}}</label>
    </div>
    <div class="field-body">
        <div class="field has-addons">
            <ng-content></ng-content>
            <div class="control">
                <span class="help is-danger" *ngIf="ErrorMessage">
                    {{ErrorMessage}}
                </span>
            </div>
        </div>
    </div>
</div>