<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Form]="Form" [Parent]="this" (OnSave)="Save()"></app-form-save-button>
    </app-pageheader>
</div>



<app-levelmapeditor #levelMapEditor *ngIf="FormLoaded == true" [ModelEditor]="this" [Level]="Form"></app-levelmapeditor> 
<!-- 
<app-leveleditor #levelEditor *ngIf="FormLoaded == true" [ModelEditor]="this" [Level]="Form"></app-leveleditor>-->