<app-pageheader [Parent]="this">
</app-pageheader>

<app-loader [Parent]="this"></app-loader>
<div class="columns is-multiline is-centered" style="margin: 10px 0 10px 0;">
  <div class="column is-four-fifths" *ngFor="let lot of parkingLots">
    <div class="box">
      <div class="box-header" (click)="toggleLot(lot.Id)" style="cursor: pointer;">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="lot-title-group">
              <h3 class="title is-4 lot-header">{{lot.Name}}</h3>
              <h6 class="subtitle is-6 lot-subtitle">({{lot.Lanes.length}} lane{{lot.Lanes.length == 1 ? '' : 's'}})</h6>
            </div>
          </div>
          <div class="level-right">
            <span class="icon">
              <i class="fas" [ngClass]="expandedLots[lot.Id] ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
            </span>
          </div>
        </div>
      </div>

      <div class="columns is-multiline expanded-lot-content" 
           [ngClass]="{'is-expanded': expandedLots[lot.Id]}">
        <div class="column is-3" *ngFor="let lane of lot.Lanes">
          <div class="card" [ngClass]="'device-status-' + (lane.Status)">
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">{{lane.Name}}</p>
                  <p class="subtitle is-6">ID: {{lane.Id}}</p>
                </div>
              </div>

              <div class="content">
                <div class="field">
                  <label class="label">Current Direction</label>
                  <p>{{lane.Direction}}</p>
                </div>
                <div class="field">
                  <label class="label">Controlled by</label>
                  <p>{{lane.ControllerId ?? "Uncontrolled"}}</p>
                </div>
              </div>
            </div>
            <footer class="card-footer">
              <a [routerLink]="['/diagnostics/lanes', lane.Id]" class="card-footer-item">
                <span class="icon">
                  <i class="fas fa-wrench"></i>
                </span>
                <span>Enter Diagnostic View</span>
              </a>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
