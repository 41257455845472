import { AfterContentInit, Component, Input } from '@angular/core';
import { ISeachTermFilter } from '../ifilter';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: '[app-search-filter]',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  host: {'class': 'control has-icons-right'}
})
export class SearchFilterComponent implements AfterContentInit, ISeachTermFilter {

  @Input()
  public AllowSearchClear: boolean = true;

  constructor(private route: ActivatedRoute) {}

  public SearchTerm: string = '';
  QueryParamPrefix: string= '';

  public async ClearSearch() {
    this.SearchTerm = '';
  }

  ngAfterContentInit(): void {
    var stateSearchTerm = this.route.snapshot.queryParamMap.get("searchTerm");
    if(stateSearchTerm != null && stateSearchTerm.trim() != "") {
      this.SearchTerm = stateSearchTerm;
    }
  }

}
