import { edit } from "ace-builds";
import { LevelEditorComponent } from "../leveleditor.component";
import { PolygonMapComponent } from "../components/PolygonComponents/PolygonMapComponent";
import { UndoAction } from "./undoaction";
import { FormArray } from "@angular/forms";
import { LevelEditorHelper } from "../helper";
import { MapComponentBase } from "../components/MapComponentBase";

export class FormRefreshUndoAction extends UndoAction {
    public oldFormItem !: any;
    public newFormItem !: any;
    public ParentFormItem !: any;
    public ParentChildArrayName !: string | undefined;
    public MapItem !: MapComponentBase;

    constructor(
        canvasState: any,
        editor?: LevelEditorComponent,
        oldformItem?: any,
        newformItem?: any,
        children?: UndoAction[],
        parentFormItem ?: any,
        parentchildArrayName? : string,
    ) {
        super(canvasState, editor, newformItem, undefined, undefined, undefined, undefined);
        if (children) {
            this.Children = children;
        } else {
            this.Editor = editor!;
            this.oldFormItem = oldformItem!;
            this.newFormItem = newformItem!;
            this.CanvasState = canvasState!;
            this.FormItem = newformItem!;
            this.ParentChildArrayName = parentchildArrayName;
            this.ParentFormItem = parentFormItem;
        }
    }

    public override Undo() {
        this.Editor.Form = this.Editor.copyFormGroup(this.oldFormItem, false);
        this.Editor.ModelEditor.Form = this.Editor.Form;

        //create a copy of the map items. 
        var mapItems = [...this.Editor.MapItems];
        
        this.Editor.LoadCanvas();
        this.Editor.Form.markAsDirty();
        this.Editor.MapItems.forEach((x: MapComponentBase) => {
            var item = mapItems.find((old: MapComponentBase) => old.Id == x.Id);
            if(item){
                x.UndoActions = item.UndoActions;  
            };
        })
    }
}
