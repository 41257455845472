import { fabric } from "fabric";
import { LevelEditorComponent } from "../leveleditor.component";
import { MapComponentBase } from "../components/MapComponentBase";
import { PolygonMapComponent } from "../components/PolygonComponents/PolygonMapComponent";
import { FormControl } from "@angular/forms";

export class Tool{
    public canvas !: fabric.Canvas;
    public editor !: LevelEditorComponent;
    public ScaleFactor = 10000;

    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        this.canvas = canvas;
        this.editor = editor;
    }

    public CreateNew(canvasX : number, canvasY: number): MapComponentBase | null {
        return new PolygonMapComponent(this.editor, this.canvas, new FormControl(), null);
    }
}