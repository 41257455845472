import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';

import { UntypedFormGroup } from '@angular/forms';
import { ApiService } from 'src/app/Services/api.service';
import { catchError, Observable, observable, throwError } from 'rxjs';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
@Component({
  selector: 'app-debugmessage',
  templateUrl: './debugmessage.component.html',
  styleUrls: ['./debugmessage.component.scss']
})
export class DebugmessageComponent extends SimpleModalComponent<any, null> implements OnInit {
  Form !: UntypedFormGroup;
  public IsLoaded: boolean = false;
  public decodeMessageDetails: any;
  //private apiService: ApiService;
  constructor(public apiService: ApiServiceBase) {
    super();
    //  this.apiService = apiService;


  }
  ngOnInit(): void {
    this.apiService.Post<any>("infrastructure/debugmessages/decode",
      { Id: this.Id, PayloadType: this.PayloadType, DebugMessage: this.DebugMessage })
      .then(result => {
        this.decodeMessageDetails = result;
        this.IsLoaded = true;
      });
  }

  @Input()
  public Id: string = "";
  @Input()
  public PayloadType: string = "";
  @Input()
  public DebugMessage: string = "";
  @Input()
  public PostUrl: string = "";
  confirm() {
    this.close();
  }


}
