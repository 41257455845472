<app-loader [Parent]="this"></app-loader>
<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Record Refund</p>
        </header>
            <section class="modal-card-body" style="min-width: 800px;">
                <app-field Label="Amount">
                    <div class="control">
                      <app-currency-input [InternalValue]="this.Payment.Amount" [Disabled]="true"></app-currency-input>
                    </div>
                </app-field>
            </section>


            <footer class="modal-card-foot">
              <button class="button ml-3 is-primary" (click)="record()">
                  <span class="icon-text">
                    <span class="icon"><i class="fa" [ngClass]="this.IsBusy ? 'fa-spinner fa-pulse' : 'fa-check'"></i></span>
                    <span>Record Refund</span>
                  </span>
                </button>
                <button class="button ml-3" (click)="cancel()">Cancel</button>
            </footer>
    </div>

</div>