
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { v4 as uuidv4 } from 'uuid';
import { DocumentToolBaseComponent } from "./DocumentToolBaseComponent";
import { DocumentEditorComponent } from "../documenteditor.component";
import { TextComponent } from "../Components/TextComponent";
import { Style } from "../HtmlRenderComponents";
import { Helpers } from "../Helpers/Helpers";
import { FabricHelpers } from "src/app/features/parking/parking/parkinglevels/leveleditor/fabric.js/helpers";
import { edit } from "ace-builds";

export class DocumentTextTool extends DocumentToolBaseComponent{
    
    public Styles !: Style;

    constructor(editor: DocumentEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }

    public override CreateNew(canvasX : number, canvasY: number){
        const adjustedpoints = FabricHelpers.GetAdjustedXY(this.canvas, canvasX, canvasY);

        this.Styles = {
            fontSize: "12px",
            fontFamily: "Arial",
            color: "black",
            left: adjustedpoints[0] - this.editor.offsets.x,
            top: adjustedpoints[1] - this.editor.offsets.y,
            width : Helpers.convertInchesToPixels("5in"),
            height: null,
            fontWeight: "normal",
            textAlign: "left",
            boxShadow: null,
            borderRadius: null,
            borderStyle: "0px solid black",
            IsLink: false,
            backgroundColor: null,
            textDecoration: null,
            fontStyle: 'normal',
            transform: null
        };

        var component = new TextComponent(this.editor, this.Styles , "Enter Text Here ...", null);

        this.editor.DocumentComponents.push(component);
        component.Draw(this.canvas);

        this.editor.Form.markAsDirty();
        return component;
    }
}
