<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Parent]="this" [Form]="Form" class="button is-primary" (OnSave)="Save()">
        </app-form-save-button>
    </app-pageheader>

    <app-tabs>
        <app-tab Title="Details">
            <div class="columns">
                <div class="column is-6">
                    <app-field Label="Chip ID" [HasAddons]="true">
                        <div class="control flex-grow">
                            <input formControlName="ChipId" class="input disabled" readonly>
                        </div>
                        <div class="control">
                            <a class="button is-static">
                                HID
                            </a>
                        </div>
                    </app-field>
                    <app-field Label="Frog ID">
                        <div class="control">
                            <input formControlName="Id" class="input disabled" readonly>
                        </div>
                    </app-field>
                    <app-field Label="Last Check In" [HasAddons]="true">
                        <div class="control flex-grow">
                            <input formControlName="LastCheckedInOnString" class="input disabled" readonly>
                        </div>
                        <div class="control">
                            <a class="button is-static">
                                {{TimeZoneName}}
                            </a>
                        </div>
                    </app-field>
                </div>
                <div class="column is-6">
                    <app-map-input formControlName="LocationPoints" Mode="marker" MapWidth="100%"></app-map-input>
                </div>
            </div>
        </app-tab>
        <app-tab Title="Messages">
            <app-debugview [ModelId]="this.ModelId">

            </app-debugview>
        </app-tab>
    </app-tabs>


</div>