<app-loader [Parent]="this"></app-loader>
<div *ngIf="Model != null">
  <app-pageheader [Parent]="this">
    <form-edit-button *ngIf="Model.StatusString != 'Cancelled'">Edit</form-edit-button>
    <actionbutton *ngIf="Model.StatusString == 'Suspended'" Icon="check" ButtonType="warning"
    (PerformAction)="ReinstatePermit()">Reinstate Permit</actionbutton>
    <actionbutton *ngIf="Model.StatusString != ('Cancelled' || 'Suspended')" Icon="ban" ButtonType="danger"
      (PerformAction)="CancelPermit()">Cancel Permit</actionbutton>
  </app-pageheader>
  <div class="columns">
    <div class="column is-6">
      <article class="panel">
        <p class="panel-heading">
          Permit Details
        </p>
        <div class="panel-block">
          <span class="label">Id</span>
          <span class="value">{{Model.Id}}</span>
        </div>
        <div class="panel-block">
          <span class="label">Permit Type</span>
          <span class="value"><a
              href="permits/permittypes/{{Model.ParkingPermitTypeId}}">{{Model.ParkingPermitTypeName}}
              ({{orgService.GetCurrencySymbol()}}{{Model.ParkingPermitType.Price.toFixed(2)}}
              {{Model.ParkingPermitType.ChargePeriodString}} {{((Model.ParkingPermitType.MultiUser &&
              Model.ParkingPermitType.SplitPayments) ? ' - Split Payment' : '')}})</a></span>
        </div>
        <div class="panel-block">
          <span class="label">Active Dates</span>
          <span class="value"><app-daterangedisplay FieldName="Active Dates"
              [StartDate]="Model.ActiveStartDateLocalFriendlyString"
              [EndDate]="Model.ActiveEndDateLocalFriendlyString"></app-daterangedisplay></span>
        </div>
        <div class="panel-block">
          <span class="label">Status</span>
          <span class="value">{{Model.StatusString}}</span>
        </div>
        <div class="panel-block">
          <span class="label">Times used</span>
          <span class="value">{{Model.TimesUsed}}</span>
        </div>
        <div class="panel-block" *ngIf="Model.ParkingSpaceId != null">
          <span class="label">Assigned Parking Space</span>
          <span class="value">
            <div class="field is-grouped is-grouped-multiline">
              <div class="tags has-addons">
                <a class="tag">{{Model.ParkingSpaceLocationString}}</a>
              </div>
            </div>
          </span>
        </div>
        <div class="panel-block">
          <span class="label">Assigned Users</span>
          <span class="value">
            <div class="field is-grouped is-grouped-multiline">
              <div class="control" *ngFor="let u of Model.Users; let i = index;">
                <div class="tags has-addons">
                  <a class="tag">{{u.FirstName}} {{u.LastName}} ({{u.Email}})</a>
                  <a class="tag is-dark" *ngIf="Model.PurchasingPermitUserId == u.Id && Model.ParkingPermitType.MultiUser && !Model.ParkingPermitType.SplitPayments ">Owner</a>
                </div>
              </div>
            </div>
          </span>
        </div>
        <div class="panel-block">
          <span class="label">Invited Users</span>
          <span class="value">
            <div class="field is-grouped is-grouped-multiline">
              <div class="control" *ngFor="let u of Model.Invitees; let i = index;">
                <div class="tags has-addons">
                  <a class="tag">{{u.FirstName}} {{u.LastName}} ({{u.Email}})</a>
                </div>
              </div>
            </div>
          </span>
        </div>
        <div class="panel-block">
          <span class="label">Vehicles</span>
          <span class="value" *ngIf="Model.Vehicles?.length > 0">
            <div class="field is-grouped is-grouped-multiline">
              <div class="control" *ngFor="let u of Model.Vehicles; let i = index;">
                <div class="tags has-addons">
                  <a class="tag">{{u.VehicleColor}} {{u.VehicleMake}} {{u.VehicleModel}} ({{u.VehiclePlateNumber}})</a>
                </div>
              </div>
            </div>
          </span>
          <span class="value" *ngIf="Model.Vehicles == null || Model.Vehicles.length == 0">
            No Assigned Vehicles
          </span>
        </div>
      </article>
    </div>
    <div class="column is-6 image-container">
      <img src="../../../../../assets/images/permitimage.svg" alt="FrogOne Parking Permits" />
    </div>
  </div>
  <div class="columns">
    <div class="column is-12">
      <article class="panel">
        <p class="panel-heading">
          Usage Instances
        </p>
        <app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl='parking/permits/{{Model.Id}}/instances/searches'
          [AllowEdit]="false" [ShowPaging]="true" [EditUrlPerItem]="true" [AllowCustomAction]="true"
          [CustomAction]="CustomAction"></app-datalistview>
      </article>
    </div>
  </div>
</div>