<app-pageheader [Parent]="this">
</app-pageheader>
 <div style="height:4em">
   <p class="is-size-3" style="display: flex; align-items: center; justify-content: center; font-weight: bold; color: green; font-family: FrogFont;">Valet</p>
</div>
<div class="columns">
   <div class="column is-10 is-offset-1">
      <div class="columns" style="min-height:50%; margin-top: 0;">
         <div class="column is-6" id="leftColumn" #leftColumn>
            <div class="card">
               <app-loader [Parent]="this"></app-loader>
               <div class="columns" style="height:100%; margin-top: 0;">
                  <div class="column is-8 is-offset-2" *ngIf="Summary != null">
                     <div style="display:flex; align-items: center; justify-content: center;" *ngFor="let summaryItem of Summary" [ngStyle]="{'cursor': summaryItem.ActionProperty ? 'pointer' : 'auto'}" (click)="navigateToSessions(summaryItem)">
                        <div class="card-stat p-1">
                           <div class="green-bg p-1 tag is-large is-rounded" [ngStyle]="{'background-color': '#' + summaryItem.IconColor }">
                              <i [ngClass]="'fa fa-' + summaryItem.IconName"  style="font-size: 1em; color:white; position:relative; left:-10px;"></i>
                              <p class="numbers" style="padding-left:10px;">{{summaryItem.Count}}</p>
                           </div>
                        </div>
                        <div class="white-bg" style="height:100%; margin-left:2em; min-width:12em;">
                              <p class="text is-size-5">{{summaryItem.Description}}</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>   
         </div>
         <div class="column is-6">
            <div class="card" style="height:25%; margin-bottom:10px; text-align: center;" *ngIf="ShowVolumeButton">
               <div style="display: inline-flex;" *ngIf="showAlerts">
                  <app-toggleswitch class="toggle" [value]="HighVolume" (change)="ToggleHighVolume($event)"></app-toggleswitch>
                  <p class="highvolume"  [ngStyle]="{'color' : HighVolume ? 'var(--frog-green2)' : 'gray'}">High Volume Mode {{HighVolume ? 'On' : 'Off'}}</p>
               </div>
               <p style="display: inline-flex; margin:10px;" *ngIf="showAlerts">High volume mode will automatically reject vehicle requests when the valet stand is too busy.</p>
            </div>
            <div class="card" style="height:75%">
               <app-loader [Parent]="this"></app-loader>
               <div style="width:100%;">
                  <p class="pt-4 is-size-4" style="font-weight: 500; text-align: center;">Alerts</p>
               </div>
               <div class="p-4" style="height:100%">
                  <div *ngIf="showAlerts" class="p-4" style="width: 100%; height: 80%; display: flex; align-items: center; justify-content: center;">
                     <div *ngIf="alerts.length > 0">
                        <div class="alertitem" *ngFor="let alert of alerts"><i class="fa fa-{{alert.icon}} alerticon" [ngStyle]="{'color': alert.iconcolor}"></i><p class="alerttext">{{alert.text}}</p></div>
                     </div>
                     <div *ngIf="alerts.length == 0">
                        <div class="alertitem"><i class="fa fa-check alerticon" style="color:green"></i><p class="alerttext" style="color:green; font-weight: 500;">You have no alerts</p></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="columns" style="height:50%">
        <div class="column is-6">
            <app-dashboardbox>
                  <app-valetsessionusageserieschart Title="Valet Sessions Started Today" ChartType="line" Period="today"
                  DataType="inflows" [Height]="100"></app-valetsessionusageserieschart>
            </app-dashboardbox>
        </div>
        <div class="column is-6">
            <app-dashboardbox>
               <app-valetsessionusageserieschart Title="Valet Sessions Started - Last 7 Days" ChartType="line" Period="thisweek"
               DataType="inflows" [Height]="100"></app-valetsessionusageserieschart>
            </app-dashboardbox>
        </div>
    </div>
   </div>
</div>