
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { LevelEditorComponent } from "../../leveleditor.component";
import { v4 as uuidv4 } from 'uuid';
import { Geo } from "src/app/util/geo";
import { PolygonTool } from "./PolygonTool";
import { PolygonMapComponent } from "../../components/PolygonComponents/PolygonMapComponent";
import { Space } from "../../components/PolygonComponents/Space";
import { FabricHelpers } from "../../fabric.js/helpers";


export class SpaceTool extends PolygonTool{
    public override ItemWidth: number = 25;
    public override ItemHeight: number = 50;
    public ParkingSpaceTypes: any[] = [];
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas, parkingSpaceTypes: any){
        super(editor, canvas);
        this.ParkingSpaceTypes = parkingSpaceTypes;
    }

    public override CreateNew(canvasX : number, canvasY: number){
        const Rows = this.editor.MapItemsByType.get('Row');
        if(Rows){
            for (let row of Rows) {
                var points = FabricHelpers.GetAdjustedXY(this.canvas, canvasX, canvasY);
                if (Geo.GetPolygonPointBounds((row as PolygonMapComponent).formItem.get('PolygonPoints')?.value).Contains([points[0] / this.ScaleFactor, points[1] / this.ScaleFactor])) {     
                    let spaces = this.editor.ModelEditor.FormArray(row.formItem, "Spaces");
                    let newspaceFormGroup = new FormGroup({
                        Id: new FormControl(uuidv4()),
                        Name: new FormControl(""),
                        ChipId: new FormControl(null),
                        GuidanceLightConfigurationId: new FormControl(),
                        GuidanceLightPortNumber: new FormControl(),
                        IsOccupied: new FormControl(false),
                        OccupiedSince: new FormControl(null),
                        ParkingLevelId: new FormControl(null),
                        ParkingLotId: new FormControl(null),
                        ParkingSpaceTypeId: new FormControl(null),
                        ParkingRateSetId: new FormControl(null),
                        PolygonPoints: new FormControl(this.CreatePolygonFromXY(canvasX, canvasY)),
                        EditorLocked: new FormControl(false)
                    });

                    (spaces as FormArray).push(newspaceFormGroup);

                    var component = new Space(this.editor, this.canvas, newspaceFormGroup, row.formItem);
                    this.editor.Form.markAsDirty();
                    return component;
                }
            }
        }
        this.editor.toastService.error("Please place your Space within a Row");
        return null;
    }
}