
<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
    <header class="modal-card-head">
        <p class="modal-card-title">User Role</p>
    </header>
    <section class="modal-card-body"style="min-width: 800px;">
    <div class="field is-horizontal">
        <div class="field-label is-normal">
            <label class="label">Roles</label>
        </div>
        <div class="field-body">
            <div class="field">
                <div class="control">
                    <div class="select" *ngIf="Roles != null">
                        <select  [(ngModel)]="Model.Roles" name="ClassName" (change)="RoleChanged()">
                            <option *ngFor="let a of Roles" [ngValue]="a.Id">{{a.Name}}</option>
                        </select>
                    </div>
                    <span *ngIf="Roles == null">
                        Loading...
                    </span>
                </div>
            </div>
        </div>  
    </div>
    </section>
    <footer class="modal-card-foot">
        <button class="button is-success" (click)="confirm()">OK</button>
    </footer>
    </div>
</div>