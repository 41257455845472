import { Component, Injector, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { UntypedFormArray, } from '@angular/forms';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ModelSelectorComponent } from '../../../../shared/modals/ModelSelector/modelselector.component';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { AddVehicleComponent } from 'src/app/features/users/users/modals/add-vehicle/add-vehicle.component';
import { OrganizationsService } from 'src/app/Services/organizations.service';

@Component({
  selector: 'app-editparkingpermit',
  templateUrl: './editparkingpermit.component.html',
  styleUrls: ['./editparkingpermit.component.scss']
})
export class EditParkingPermitComponent extends ModelEditor implements OnInit {

  public PermitTypes: any[] = [];
  public RenewDate: string = "";
  public orgService !: OrganizationsService;

  constructor(private modalService: SimpleModalService, private apiService: ApiServiceBase, private injector: Injector, public orgservice: OrganizationsService) {
    super("parking/permits", injector);

    this.orgService = orgservice;

    this.apiService.Get<any>("parking/permittypes/summary").then(result => {
      this.PermitTypes = result;
    });
  }

  public override DefaultModel(): any {
    return {
      Users: [],
      TimesUsed: 0,
      ActiveStartDateLocal: null,
      ActiveEndDateLocal: null,
      ParkingPermitTypeId: null,
      ParkingPermitType: { Id: null, MultiUser: false},
      ParkingSpaceId: null,
      ParkingSpaceLocationString: null,
      ClassName: "ParkingPermit",
      Invitees: [],
      Vehicles: []
    }
  }
  public override AfterModelLoaded(): void {
    if(this.Model.Users == null || this.Model.Users.length == 0){
      this.Model.Users = [{UserId: null, FirstName: null, LastName: null, Email: null}]
    }

    if(this.Model.ActiveStartDateLocal == null){
      this.api.Get("organization/localdate").then((result: any) => {
        this.Model.ActiveStartDateLocal = result.LocalDate;
        this.Form.get('ActiveStartDateLocal')?.setValue(result.LocalDate)
      });
    }

    if(this.Form.get('ParkingPermitTypeId')?.value != null){
      this.Model.ParkingPermitType.Restriction = this.BuildRestrictionString();
    }
  }

  public UpdateParkingPermitType(){
    var pt = this.PermitTypes.filter((x: any) => x.Id == this.Form.get('ParkingPermitTypeId')?.value)[0];
    this.Loading();
    this.apiService.Get<any>("parking/permittypes/" + pt.Id).then(result => {
      this.Model.ParkingPermitType = result;
      this.Model.ParkingPermitTypeId = pt.Id;
      this.Model.ParkingPermitType.Restriction = this.BuildRestrictionString();
      this.StopLoading();
    });
  }

  public override BeforeSave(): void {

  }
  public override Validators(): any {
  }

  public override CanDeactivate(): boolean {
    return true;
  }


  public BuildRestrictionString(){
    var string = [];
    if(this.Model.ParkingPermitType.RestrictToUserRoles && this.Model.ParkingPermitType.ParkingPermitTypeRoleRestrictions.length > 0){
    string.push("This permit is restricted to Users who have the User Roles: ");
      for (let i = 0; i < this.Model.ParkingPermitType.ParkingPermitTypeRoleRestrictions.length ; i++) {
        string.push((i == 0 ? '' : (i == this.Model.ParkingPermitType.ParkingPermitTypeRoleRestrictions.length-1 ? ', and ': ',')) + this.Model.ParkingPermitType.ParkingPermitTypeRoleRestrictions[i].RoleName);
      }
      string.push(". ");
    }
    var limitedToPushed = false;
    if(this.Model.ParkingPermitType.MaxUsesPerPeriod != null && this.Model.ParkingPermitType.MaxUsesPerPeriod != 0 && this.Model.ParkingPermitType.MaxUsePeriodString != null){
      string.push(string.length > 0 ? "There is a maximum of " : "This permit has a maximum of ");
      limitedToPushed = true;
      string.push(this.Model.ParkingPermitType.MaxUsesPerPeriod + " uses " + this.Model.ParkingPermitType.MaxUsePeriodString);
    }

    if(this.Model.ParkingPermitType.LimitToSpaceTypes && this.Model.ParkingPermitType.ParkingSpaceTypeRestrictions.length > 0){
      if(limitedToPushed) string.push(" in ");
      else {
        string.push(string.length > 0 ? "It is limited to use in " : "This Permit is limited to use in ");
        limitedToPushed = true;
      }
      for (let i = 0; i < this.Model.ParkingPermitType.ParkingSpaceTypeRestrictions.length ; i++) {
        string.push((i == 0 ? '' : (i == this.Model.ParkingPermitType.ParkingSpaceTypeRestrictions.length-1 ? ', and ': ',')) + "'" + this.Model.ParkingPermitType.ParkingSpaceTypeRestrictions[i].SpaceTypeName + "'");
      }
      string.push(" Parking Spaces");
    }

    if(this.Model.ParkingPermitType.ParkingStructures?.length > 0){
      if(limitedToPushed) string.push(" in the following parking structures: ");
      else {
        string.push("This Permit is limited to use the following parking structures: ");
      }
      for (let i = 0; i < this.Model.ParkingPermitType.ParkingStructures.length ; i++) {
        string.push((i == 0 ? '' : (i == this.Model.ParkingPermitType.ParkingStructures.length-1 ? ', and ': ',')) + "'" + this.Model.ParkingPermitType.ParkingStructures[i].LocationString + "'");
      }
    }
    string.push(". ")
    var str = string.join("");
    if(str == ". ") str = "There are no restrictions on this permit.";
    return str;
  }

  public AddUser(){
    let u = { Id: null, UserId: null, FirstName: null, LastName:null, Email:null};
    this.AddToFormArray(this.Form.get('Users') as UntypedFormArray, u, "Users");
  }


  public removeUser(index: number){
    this.FormArray(this.Form, "Users").removeAt(index);
    this.Form?.markAsDirty();
  }

  public BuildUsersString(){
    if(!this.Model.ParkingPermitType.MultiUser) return 'Single User Permit';
    var str = 'Multi User Permit';
    if(this.Model.ParkingPermitType.MinUsers == null && this.Model.ParkingPermitType.MaxUsers == null) return str + ' (Any number of users)';
    if(this.Model.ParkingPermitType.MinUsers == null) return str + ' (At most ' + this.Model.ParkingPermitType.MaxUsers + ' users)';
    if(this.Model.ParkingPermitType.MaxUsers == null) return str + ' (At least ' + this.Model.ParkingPermitType.MinUsers + ' users)';
    return str + ' (' + this.Model.ParkingPermitType.MinUsers + ' to ' + this.Model.ParkingPermitType.MaxUsers + ' users)'
  }

  public UserChanged(MultiUserResponse : any){

    var User = MultiUserResponse.User;
    var formGroup = MultiUserResponse.FormGroup;

    formGroup.get('Email').setValue(User.Email);
    formGroup.get('FirstName').setValue(User.FirstName);
    formGroup.get('LastName').setValue(User.LastName);
    formGroup.get('UserId').setValue(User.Id);
  }

  public DeleteUser(MultiUserResponse : any){
    var index = MultiUserResponse.Index;
    this.FormArray(this.Form, "Users").removeAt(index);
  }

  public DeleteParkingSpaceId(){
    this.Form.get('ParkingSpaceId')?.setValue(null);
  }

  public ParkingSpaceColumns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Location", "LocationString")
  ];


  public VehicleColumns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Description", "Description"),
    new Datalistviewcolumn("Plate Number", "PlateNumber")
  ];

  public AddParkingSpace(){
    this.modalService.addModal(ModelSelectorComponent,
      { ApiUrl: "parking/permittypes/" + this.Form.get('ParkingPermitTypeId')?.value +"/spaces/searches", ModelType: "Parking Space", 
        Columns : this.ParkingSpaceColumns
      })
      .subscribe((result) => {
        if (result != null) {
            this.Form.get('ParkingSpaceId')?.setValue(result.Id);
            this.Form.get('ParkingSpaceLocationString')?.setValue(result.LocationString);
          }
      })
  };

  public RemoveVehicle(index: any){
    this.FormArray(this.Form, "Vehicles").removeAt(index);
    this.Form.markAsDirty();
  }

  public AddVehicle(){
      this.modalService.addModal(AddVehicleComponent).subscribe(result => {
        console.log(result);
        this.AddToFormArray(this.FormArray(this.Form, "Vehicles"), {VehicleId: result.Id, VehiclePlateNumber: result.PlateNumber, VehicleMake: result.VehicleMake, MakeId: result.MakeId, VehicleColor: result.VehicleColor, ColorId: result.ColorId, PlateId: result.PlateId}, "Vehicles")
      })
  };
}
