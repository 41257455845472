<app-pageheader [Parent]="this">
    <add-button AddRoute="/hardware/configuration/signdisplayrulesets/new/edit"></add-button>
</app-pageheader>
  
<app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="infrastructure/displayrulesets/searches"
[AllowEdit]="true" EditUrl="/hardware/configuration/signdisplayrulesets/:id">
    <app-filter-set>
        <div app-search-filter></div>
        <div app-search-button></div>
    </app-filter-set>
</app-datalistview>