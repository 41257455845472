import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function mustContainValidator(values: string[], containsAll: boolean): ValidatorFn {
    
    return (control:AbstractControl) : ValidationErrors | null => {
        const value = control.value;
        
        for(var v of values) {
            if(value.includes(v))
                {
                    if(!containsAll)
                        return null;
                }
                else {
                    if(containsAll)
                        return { containsvalues: false};
                }
        }

        return { containsvalues : false };
    }
}