import { MapBaseLayer } from "@angular/google-maps";
import * as turf from '@turf/turf'


export class Geo {
    public static PolygonCenter(p: number[][]): google.maps.LatLngLiteral {
        if (p == null)
            return { lat: 0, lng: 0 };
        let minX: number = 99999;
        let maxX: number = -99999;
        let minY: number = 99999;
        let maxY: number = -99999;
        for (let i = 0; i < p.length; i++) {
            if (p[i][0] > maxX) maxX = p[i][0];
            if (p[i][1] > maxY) maxY = p[i][1];
            if (p[i][0] < minX) minX = p[i][0];
            if (p[i][1] < minY) minY = p[i][1];
        }

        return { lat: minY + ((maxY - minY) / 2), lng: minX + ((maxX - minX) / 2) };
    }
    public static PolygonCenterAsNumbers(p: number[][]): number[] {
        let center = Geo.PolygonCenter(p);
        return [center.lng, center.lat];
    }

    public static NewInnerPolygon(outerPolygon: number[][], size: number) {
        let center = this.PolygonCenter(outerPolygon);
        return this.NewPolygonAtCenter(center, size);
    }

    public static NewPolygonAtCenter(center: google.maps.LatLng | google.maps.LatLngLiteral | number[], width: number, height ? : number) {
        let w = width / 2;
        let h = height != null ? height/2 : w;
        let x = 0;
        let y = 0;

        if(center == null){
            x = 0;
            y = 0;
        }
        else if (center instanceof google.maps.LatLng) {
            x = center.lng();
            y = center.lat();
        }
        else if (Array.isArray(center)) {
            x = center[0];
            y = center[1];
        }
        else {
            x = center.lng;
            y = center.lat;
        }
        return [[x - w, y - h], [x + w, y - h], [x + w, y + h], [x - w, y + h], [x - w, y - h]];
    }
    public static DoublesToLatLng(doubles: number[][]): google.maps.LatLngLiteral[] {
        if(doubles == null || doubles.length < 2) return [{ lat: 0, lng: 0 }];
        let result = [];
        for (let d of doubles) {
            result.push({ lng: d[0], lat: d[1] });
        }
        return result;
    }

    public static DoublesToSvgPath(doubles: number[][], scale: number = 1, ShiftItem : number = 0): string {
        let result = "M ";
        for (let i = 0; i < doubles.length; i++) {
            result += (doubles[i][0]+ ShiftItem) * scale + " " + doubles[i][1] * scale;
            result += " L";
        }
        //back to origin
        result += (doubles[0][0]+ ShiftItem) * scale + " " + doubles[0][1] * scale;
        return result;
    }
    public static BisectPoints(p1: number[], p2: number[]) {
        let x = p1[0] + (p2[0] - p1[0]) / 2;
        let y = p1[1] + (p2[1] - p1[1]) / 2;
        return [x, y];
    }
    public static BisectPointsArray(array: number[][]): number[][] {
        let result = [];
        for (let i = 0; i < array.length; i++) {
            result.push(this.BisectPoints(array[i], i == array.length - 1 ? array[0] : array[i + 1]));
        }
        return result;
    }

    public static LeftMost(array: number[][]): number {
        let min = array[0][0];
        for (let point of array) {
            if (point[0] < min) {
                min = point[0];
            }
        }
        return min;
    }
    public static RightMost(array: number[][]): number {
        let max = array[0][0];
        for (let point of array) {
            if (point[0] > max) {
                max = point[0];
            }
        }
        return max;
    }
    public static TopMost(array: number[][]): number {
        let min = array[0][1];
        for (let point of array) {
            if (point[1] < min) {
                min = point[1];
            }
        }
        return min;
    }
    public static BottomMost(array: number[][]): number {
        let max = array[0][1];
        for (let point of array) {
            if (point[1] > max) {
                max = point[1];
            }
        }
        return max;
    }
    public static GetPolygonPointBounds(item: any): Bounds {
        if(item == null) return new Bounds(0,0,0,0);
        let left, right, top, bottom: number | null = null;
        if (Array.isArray(item)) {
            for(let i of item){
                if(i.PolygonPoints){
                    for (let point of i.PolygonPoints) {
                        if (top == null || point[1] < top) {
                            top = point[1];
                        }
                        if (bottom == null || point[1] > bottom) {
                            bottom = point[1];
                        }
                        if (left == null || point[0] < left) {
                            left = point[0];
                        }
                        if (right == null || point[0] > right) {
                            right = point[0];
                        }
                    }
                }
                else if(Array.isArray(i)){
                        if (top == null || i[1] < top) {
                            top = i[1];
                        }
                        if (bottom == null || i[1] > bottom) {
                            bottom = i[1];
                        }
                        if (left == null || i[0] < left) {
                            left = i[0];
                        }
                        if (right == null || i[0] > right) {
                            right = i[0];
                        }
                }
            }
        }
        else {
            for (let point of item.PolygonPoints) {
                if (top == null || point[1] < top) {
                    top = point[1];
                }
                if (bottom == null || point[1] > bottom) {
                    bottom = point[1];
                }
                if (left == null || point[0] < left) {
                    left = point[0];
                }
                if (right == null || point[0] > right) {
                    right = point[0];
                }
            }
        }
        return new Bounds(left ?? 0, top ?? 0, right ?? 0, bottom ?? 0);
    }

    public static DirectionFromCenter(point: number[], array: number[][]): string {

        let center = this.PolygonCenterAsNumbers(array);
       return this.DirectionFromPoint(point, center);
    }
    public static DirectionFromPoint(pointTo : number[], pointFrom : number[]){
        let x = pointTo[0] - pointFrom[0];
        let y = pointFrom[1] - pointTo[1];
        let angle = Math.atan2(y, x) + Math.PI;

        let directions = ["w", "sw", "s", "se", "e", "ne", "n", "nw", "w"];
        let index = Math.round((angle) / (Math.PI / 4));
        return directions[index];
    }
    public static AngleFromPoint(pointTo : number[], pointFrom : number[]){
        return (Math.atan2(pointTo[1] - pointFrom[1], pointTo[0] - pointFrom[0]) * 180 / Math.PI)+90;
    }
    public static DistanceBetweenPoints(pointTo : number[], pointFrom: number[]) : number{
        return Math.sqrt( Math.pow((pointFrom[0]-pointTo[0]), 2) + Math.pow((pointFrom[1]-pointTo[1]), 2) );
    }
    public static RotatePointsAroundOrigin(points: number[][], origin : number[], angleDegrees : number) : number[][]{
        let result = [];
        for(let point of points){
            result.push(this.RotatePointAroundOrigin(point, origin, angleDegrees));
        }
        return result;
    }
    public static RotatePointAroundOrigin(point: number[], origin: number[], angleDegrees : number) : number[]{
        if(angleDegrees == 0)
            return point;
        var radians = (Math.PI / -180) * (angleDegrees-90);
        var cos = Math.cos(radians);
        var sin = Math.sin(radians);
        var nx = (cos * (point[0] - origin[0])) + (sin * (point[1] - origin[1])) + origin[0];
        var ny = (cos * (point[1] - origin[1])) - (sin * (point[0] - origin[0])) + origin[1];
        return [nx, ny];
    }

    static RoundEdges(points: any[], iterations: number) {
        for (let i = 0; i < iterations; i++) {
          const newPoints = [points[0]];
          for (let j = 1; j < points.length - 1; j++) {
            // Check if the current point or the next point should be excluded from rounding
            if (
              (points[j].isIndex != (undefined || null) && points[j].isIndex) ||
              (points[j + 1].isIndex != (undefined || null) && points[j + 1].isIndex)
            ) {
              // Include the unrounded points in the newPoints array
              newPoints.push(points[j]);
            } else {
              const angle = Geo.calculateAngle(
                points[j - 1],
                points[j],
                points[j + 1]
              );
              const smoothingFactor = Geo.calculateSmoothingFactor(angle);
              const p1 = {
                x: points[j].x + smoothingFactor * (points[j - 1].x - points[j].x),
                y: points[j].y + smoothingFactor * (points[j - 1].y - points[j].y),
                height:
                  points[j].height != undefined
                    ? points[j].height + smoothingFactor * (points[j - 1].height - points[j].height)
                    : 0,
              };
              const p2 = {
                x: points[j].x + smoothingFactor * (points[j + 1].x - points[j].x),
                y: points[j].y + smoothingFactor * (points[j + 1].y - points[j].y),
                height:
                  points[j].height != undefined
                    ? points[j].height + smoothingFactor * (points[j + 1].height - points[j].height)
                    : 0,
              };
              newPoints.push(p1, p2);
            }
          }
          newPoints.push(points[points.length - 1]);
          points = newPoints;
        }
        return points;
      }
    
      static calculateAngle(p1: any, p2: any, p3: any) {
        const angle1 = Math.atan2(p1.y - p2.y, p1.x - p2.x);
        const angle2 = Math.atan2(p3.y - p2.y, p3.x - p2.x);
        const angleDiff = Math.abs(angle1 - angle2);
        const epsilon = Math.PI / 180; // 1 degree in radians
        const angle = angleDiff < Math.PI ? angleDiff : 2 * Math.PI - angleDiff;
        return angle;
      }
    
      static calculateSmoothingFactor(angle: number) {
        // Adjust these parameters to control the smoothing behavior
        const maxAngle = Math.PI; // Maximum angle
        const minSmoothingFactor = 0.1; // Minimum smoothing factor
        const maxSmoothingFactor = 1.0; // Maximum smoothing factor
    
        if (angle <= Math.PI / 2) {
            return 0.01;
        }
    
        // Calculate the normalized angle within the range of 0 to 1
        const normalizedAngle = Math.min(angle / maxAngle, 1);
    
        // Calculate the inverse of the normalized angle to get the smoothing factor
        const smoothingFactor =
        normalizedAngle === 0 // Angle is exactly 0
          ? maxSmoothingFactor // Use maximum smoothing factor
          : normalizedAngle === 1 // Angle is exactly 180 degrees (or PI radians)
          ? minSmoothingFactor // Use minimum smoothing factor
          : minSmoothingFactor + (1 - normalizedAngle) * (maxSmoothingFactor - minSmoothingFactor);
    
      return smoothingFactor;
      }   

      public static IsPointInsidePolygon(x: any, y: any, polygonCoordinates: any) {
        let isInside = false;
        const polygonLength = polygonCoordinates.length;
    
        for (let i = 0, j = polygonLength - 1; i < polygonLength; j = i++) {
          const [xi, yi] = polygonCoordinates[i];
          const [xj, yj] = polygonCoordinates[j];
    
          const intersect =
            yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    
          if (intersect) {
            isInside = !isInside;
          }
        }
    
        return isInside;
      }

      public static DescalePolygon(polygonPoints: any) {
        // Calculate the center point of the polygon
        const center = polygonPoints.reduce(
          (accumulator: { x: number; y: number; }, point: { x: number; y: number; }) =>
            ({ x: accumulator.x + point.x, y: accumulator.y + point.y }),
          { x: 0, y: 0 }
        );
        center.x /= polygonPoints.length;
        center.y /= polygonPoints.length;
      
        // Calculate the average distance from the center to all points
        const avgDistance = polygonPoints.reduce(
          (sum: number, point: { x: number; y: number; }) =>
            sum + Math.sqrt((point.x - center.x) ** 2 + (point.y - center.y) ** 2),
          0
        ) / polygonPoints.length;
      
        // Define the step size as a fraction of the average distance
        const stepSize = 0.1 * avgDistance; // Adjust the scaling factor as needed
      
        // Iterate over each point and scale it down towards the center
        const descaledPolygon = polygonPoints.map((point: { x: number; y: number; }) => {
          const distanceToCenter = Math.sqrt((point.x - center.x) ** 2 + (point.y - center.y) ** 2);
          const scaledDistance = Math.max(distanceToCenter - stepSize, 0); // Ensure distance does not go below zero
          const scaledX = center.x + (point.x - center.x) * (scaledDistance / distanceToCenter);
          const scaledY = center.y + (point.y - center.y) * (scaledDistance / distanceToCenter);
          return { x: scaledX, y: scaledY };
        });
      
        return descaledPolygon;
      }
}

export class Bounds {
    public constructor(left: number, top: number, right: number, bottom: number) {
        this.Left = left;
        this.Right = right;
        this.Top = top;
        this.Bottom = bottom;
        this.Height = bottom-top;
        this.Width = right-left;
        this.Center = [this.Width/2 + left, this.Height/2 + top];
    }
    public Left: number = 0;
    public Right: number = 0;
    public Top: number = 0;
    public Bottom: number = 0;
    public Width : number = 0;
    public Height: number = 0;
    public Center : number[] = [0,0];

    public SvgPath(scale : number) : string{
        let points= [[this.Left, this.Top], [this.Right, this.Top], [this.Right, this.Bottom], [this.Left, this.Bottom]];
        return Geo.DoublesToSvgPath(points, scale);
    }
    public Offset(left: number, top: number){
        this.Left += left;
        this.Right += left;
        this.Top += top;
        this.Bottom += top;
    }
    public Scale(scale : number){
        this.Left *= scale;
        this.Right *= scale;
        this.Top *= scale;
        this.Bottom *= scale;
    }
    public Contains(point : number[]) : boolean{
        if(point.length == 0) return false;
        return this.Left <= point[0] && this.Right >= point[0] && this.Top <= point[1] && this.Bottom >=  point[1];
    }
    public ContainsPolygon(points : number[][]) : boolean{
        if(points.length == 0) return false;
        for(let point of points){
            if(!(this.Left <= point[0] && this.Right >= point[0] && this.Top <= point[1] && this.Bottom >=  point[1])){
                return false;
            }
        }
        return true;
    }
}
