import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Color } from 'src/app/util/color';

@Component({
  selector: 'app-treenodesettings',
  templateUrl: './treenodesettings.component.html',
  styleUrls: ['./treenodesettings.component.scss']
})
export class TreeNodeSettingsComponent implements OnInit {

  @Input() recursiveList: any;
  @Output() UpdateItem = new EventEmitter<any>();
  @Output() ItemClicked = new EventEmitter<any>();

  @Output() updateSettingValue = new EventEmitter<any>();
  @Output() getItemValue = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {

    var x = this.recursiveList;
  }

  public GetItemValue(item : any){
    this.getItemValue.emit(item);
  }

  public ClassForColor(color: string) {
    return Color.GetVisibleTextClassFor(color);
  }

  public UpdateSettingItemValue(item: any, evt : any){
    if(item.Type == 'boolean'){
      item.Value = evt.target.checked;
    }
    else if (item.Type == 'color' || item.Type == 'colour'){
      item.Value = evt;
    }
    else if (item.Type == 'media'){
      item.Value = evt;
    }
    else{
      item.Value = evt.target.value;
    }
    this.updateSettingValue.emit(item);
  }

  public ManualChange(item: any, evt : any){
    item.Value = evt.target.value;
    this.updateSettingValue.emit(item);
  }

  public UpdateSettingItem(item : any){
    this.updateSettingValue.emit(item);
  }
}