<app-pageheader [Parent]="this">
</app-pageheader>
<app-loader [Parent]="this"></app-loader>

<div class="columns is-fullwidth">
  <div class="column is-half">
    <article class="panel">
      <p class="panel-heading">Search for duplicates</p>
      <div class="panel-block">
        <p class="label">
          Start Date
        </p>
        <p class="value">
          <input class="input" [(ngModel)]="StartDate" type="date" [disabled]="UseLastStatementRange"/>
        </p>
      </div>
      <div class="panel-block">
        <p class="label">
          End Date
        </p>
        <p class="value">
          <input class="input" [(ngModel)]="EndDate" type="date" [disabled]="UseLastStatementRange"/>
        </p>
      </div>
      <div class="panel-block" tippy="You can check this to use the dates from the last uploaded statement, automatically">
        <p class="label">
          Use last statement's dates
        </p>
        <p class="value">
          <app-toggleswitch [(ngModel)]="UseLastStatementRange"></app-toggleswitch>
        </p>
      </div>
      <div class="panel-block" style="width: 100%">
        <span class="has-text-centered" style="width: 100%">
          <button class="button is-primary" (click)="getIssues()"><i class="fa-solid fa-magnifying-glass"></i>&nbsp;&nbsp;Find Duplicates</button>
        </span>
      </div>
    </article>
  </div>
  <div class="column is-half">
    <article class="panel">
      <p class="panel-heading">
        Search Summary
      </p>
      <div class="panel-block">
        <p class="label">Total to Resolve</p>
        <p class="value">{{TotalToReconcile ?? "--"}}</p>
      </div>
      <div class="panel-block">
        <p class="label">Duplicates</p>
        <p class="value">{{DuplicateCount ?? "--"}}</p>
      </div>
      <div class="panel-block">
        <p class="label">&nbsp;</p>
        <p class="value">&nbsp;</p>
      </div>
      <div class="panel-block">
        <p class="label">&nbsp;</p>
        <p class="value">&nbsp;</p>
      </div>
      <div class="panel-block">
        <p class="label">&nbsp;</p>
        <p class="value">&nbsp;</p>
      </div>
      <div class="panel-block">
        <button class="button is-primary is-hidden" hidden>&nbsp;</button>
      </div>
    </article>
  </div>
</div>

<table class="table is-fullwidth" *ngIf="ActionItems.length > 0">
  <thead>
      <tr>
          <th colspan="3">Found Duplicates</th>
      </tr>
  </thead>
  <tbody>
      <tr *ngFor="let item of ActionItems">
        <td class="column-half">
          <article class="panel">
            <p class="panel-heading">{{item.Cards[0].CardTitle}} (<a href="parking/parking/sessions/{{item.Cards[0].ParkingSessionId}}" target="_blank">Session {{item.Cards[0].ParkingSessionId}}</a>)</p>
            <p class="panel-block">
              <span class="label">Account Transaction</span>
              <span class="value">{{item.Cards[0].AccountTransactionId}}</span>
            </p>
            <p class="panel-block">
              <span class="label">External ID</span>
              <span class="value">{{item.Cards[0].TransactionId}}</span>
            </p>
            <p class="panel-block">
              <span class="label">Reference</span>
              <span class="value">{{item.Cards[0].TransactionReference}}</span>
            </p>
            <p class="panel-block">
              <span class="label">PaidFrom</span>
              <span class="value">{{item.Cards[0].PaidFromLocalString}}</span>
            </p>
            <p class="panel-block">
              <span class="label">Paid To</span>
              <span class="value">{{item.Cards[0].PaidToLocalString}}</span>
            </p>
            <p class="panel-block">
              <span class="has-text-centered" style="width:100%; height: 100%">
                <button *ngFor="let action of item.Cards[0].Actions" (click)="doAction(action)" class="button is-{{action.ButtonColor}} is-small">
                  <i class="fa-regular fa-{{action.IconName}}" style="padding-right: 0.75rem;"></i>
                  {{action.DisplayName}}
                </button>
              </span>
            </p>
          </article>
        </td>
        <td class="icon-cell">
          <i class="fa-solid fa-equals"></i>
        </td>
        <td class="column-half">
          <article class="panel">
            <p class="panel-heading">{{item.Cards[1].CardTitle}} (<a href="parking/parking/sessions/{{item.Cards[0].ParkingSessionId}}" target="_blank">Session {{item.Cards[1].ParkingSessionId}}</a>)</p>
            <p class="panel-block">
              <span class="label">Account Transaction</span>
              <span class="value">{{item.Cards[1].AccountTransactionId}}</span>
            </p>
            <p class="panel-block">
              <span class="label">External ID</span>
              <span class="value">{{item.Cards[1].TransactionId}}</span>
            </p>
            <p class="panel-block">
              <span class="label">Reference</span>
              <span class="value">{{item.Cards[1].TransactionReference}}</span>
            </p>
            <p class="panel-block">
              <span class="label">PaidFrom</span>
              <span class="value">{{item.Cards[1].PaidFromLocalString}}</span>
            </p>
            <p class="panel-block">
              <span class="label">Paid To</span>
              <span class="value">{{item.Cards[1].PaidToLocalString}}</span>
            </p>
            <p class="panel-block">
              <span class="has-text-centered" style="width:100%; height: 100%">
                <button *ngFor="let action of item.Cards[1].Actions" (click)="doAction(action)" class="button is-{{action.ButtonColor}} is-small">
                  <i class="fa-regular fa-{{action.IconName}}" style="padding-right: 0.75rem;"></i>
                  {{action.DisplayName}}
                </button>
              </span>
            </p>
          </article>
        </td>
      </tr>
  </tbody>
</table>

<div *ngIf="ActionItems.length == 0" class="has-text-centered">
  <p>No duplicates found for the current search</p>
</div>
