import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { LevelEditorComponent } from "../../leveleditor.component";
import { PointTool } from "./PointTool";
import { Gateway } from "../../components/PointComponents/Gateway";

export class GatewayTool extends PointTool{
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }

    public override CreateNew(canvasX : number, canvasY: number){
        let gateways = this.editor.ModelEditor.FormArray(this.editor.Form, "GatewayConfigurations");
        let newGatewayFormGroup = new FormGroup({
            Id: new FormControl(null),
            Name: new FormControl("Gateway"),
            ChipId: new FormControl(null),
            WakeUpTime: new FormControl(null),
            SleepTime: new FormControl(null),
            DisplayAngle: new FormControl(0),
            EditorLocked: new FormControl(false),
            LocationPoints: new FormControl(this.CreatePointFromXY(canvasX, canvasY))
        });

        (gateways as FormArray).push(newGatewayFormGroup);
        var component = new Gateway(this.editor, this.canvas, newGatewayFormGroup, null);
        this.editor.Form.markAsDirty();
        return component;
    }
}
