<div class="modal is-active" [formGroup]="Form">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit Admin Permission</p>
      </header>
      <section class="modal-card-body"style="min-width: 800px;">
            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="label">Select Admin Permission</label>
                </div>
                <div class="field-body">
                    <div class="field">
						<div class="control">
							<div class="select">
								<select formControlName="Permission" (change)="PermissionChanged()">
									<option *ngFor="let w of Permissions" [value]="w.Type">{{w.Name}}</option>
								</select>
							</div>
						</div>
                    </div>
                </div>  
            </div>
			<div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="label">Select Permission Level</label>
                </div>
                <div class="field-body">
                    <div class="field">
						<div class="control">
							<div class="select">
								<select formControlName="PermissionLevel">
									<option *ngFor="let w of PermissionLevels" [value]="w.Level">{{w.Permission}}</option>
								</select>
							</div>
						</div>
                    </div>
                </div>  
            </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" (click)="confirm()">OK</button>
      </footer>
    </div>
  </div>