import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-editsignconfiguration',
  templateUrl: './editsignconfiguration.component.html',
  styleUrls: ['./editsignconfiguration.component.scss']
})

export class EditSignConfigurationComponent extends SimpleModalComponent<any, null>{
  Form !: UntypedFormGroup;

  public InitialSign: any = {};
  public searchResult: any = { PageNumber: 1, PageSize: 0, Results: null };
  public SignDisplayRuleSets: any;

  constructor(private apiService: ApiService) {
    super();
    this.apiService.Post("infrastructure/displayrulesets/searches",  {PageNumber: 1, PageSize: 999}).then(result => {
      if (result != null){
        this.searchResult = result;
        this.SignDisplayRuleSets = this.searchResult.Results;
      }
    });
  }

  confirm() {
    let formthing = this.Form.controls["ChipId"];
    this.close();
  }
}