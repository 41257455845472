import { Geo } from "src/app/util/geo";
import { LevelEditorComponent } from "../../leveleditor.component";
import { MapComponentBase } from "../MapComponentBase";
import { fabric } from 'fabric';
import { FabricHelpers } from "../../fabric.js/helpers";
import { PolygonMapComponent } from "../PolygonComponents/PolygonMapComponent";
import { FormArray } from "@angular/forms";
import { v4 as uuidv4 } from 'uuid';
import { EventEmitter, Injectable, Output } from '@angular/core';

export class PointMapComponent extends MapComponentBase {
    public LocationPoint: any;
    public ImageUrl : string | null = null;
    public ImageScale = 0.02;
    public Left = 0;
    public Top = 0;
    public override FabricItem !: fabric.Object;
    public override zIndex: number = 200;
    public RotationTimer: NodeJS.Timeout | null = null;
    public ModifiedTimer: NodeJS.Timeout | null = null;
    public ImageLoadedAndUpdated = new EventEmitter<any>();
    public IsGuidId: boolean = true;

    constructor(leveleditorcomponent: LevelEditorComponent, canvas: any, formItem: any, parentFormItem: any, forceClone: boolean = false) {
        super(leveleditorcomponent, canvas, formItem, parentFormItem, forceClone);
        this.SetFormValues();
    }

    public Clone(formitem: any){
        return new PointMapComponent(this.leveleditorcomponent, this.canvas, formitem, this.parentFormItem, true);
    }

    public override SetFormValues(){
        this.LocationPoint =  this.formItem.get("LocationPoints")?.value;
        this.DisplayAngle = this.formItem.get("DisplayAngle")?.value;
    }

    public override Rotate(value: number){
        this.FabricItem.set({'angle': value});
        this.formItem.get('DisplayAngle').setValue(value);
        this.canvas.renderAll();
    }

    public override SetSelectedObject(): void {
        this.leveleditorcomponent.SelectedItems = [];
        this.leveleditorcomponent.SelectedItems.push(this);
        this.canvas.setActiveObject(this.FabricItem);
    }

    getAbsolutePosition(obj: fabric.Object): { left: number, top: number } {
        let left = obj.left ?? 0;
        let top = obj.top ?? 0;
    
        if (obj.group) {
            const matrix = obj.calcTransformMatrix();
            const transformPoint = new fabric.Point(left, top);
            const transformedPoint = fabric.util.transformPoint(transformPoint, matrix);
    
            left = transformedPoint.x;
            top = transformedPoint.y;
        }
    
        return { left, top };
    }

    public override AddFabricItem(){
        this.SetFormValues();
        if(this.FabricItem != null){
            this.canvas.remove(this.FabricItem);
        }

        this.Left = (this.LocationPoint[0] * this.ScaleFactor * (this.ForceClone ? 1 : this.Zoom));
        this.Top = (this.LocationPoint[1] * this.ScaleFactor * (this.ForceClone ? 1 : this.Zoom))

        fabric.Image.fromURL(this.ImageUrl ? this.ImageUrl : "", (img) => {
            img.set({
                left: this.Left,
                top: this.Top,
                scaleX: this.ImageScale, // Adjust scale as needed
                scaleY: this.ImageScale, // Adjust scale as needed
                angle: this.DisplayAngle,
                originX: 'center',
                originY: 'center',
                id: this.FabricItemId,
                lockScalingX: true, // Prevent scaling in the X direction
                lockScalingY: true,
                hasControls: !this.Locked,
                lockMovementX: this.Locked,
                lockMovementY: this.Locked,
            } as fabric.IObjectOptions);

            this.FabricItem = img;

            this.FabricItem.angle = this.formItem.get('DisplayAngle')?.value;
        
            this.FabricItem.on('rotating', () => {
                this.ItemModified();
                // Get the final angle after rotation stops
                const angle = this.FabricItem.get('angle');
                const newAngle = Math.round(angle ?? 0);
                
                // Update form and variables
                this.formItem.get("DisplayAngle").setValue(newAngle);
                this.formItem.get("DisplayAngle").markAsDirty();
                this.DisplayAngle = newAngle;
            });
        
            this.FabricItem.on('modified', () => {
                    const absolutePosition = this.getAbsolutePosition(this.FabricItem);

                    const left = absolutePosition.left;
                    const top = absolutePosition.top;

                    console.log(left, top);

                    if (left !== undefined && top !== undefined) {
                        const newLeft = left / (this.ScaleFactor) / this.Zoom;
                        const newTop = top / (this.ScaleFactor) / this.Zoom;
                        this.formItem.get("LocationPoints").setValue([newLeft, newTop]);
                        this.formItem.get("LocationPoints").markAsDirty();
                        this.LocationPoint = [newLeft, newTop];
                        if(this.RequiresParent){
                            var foundParent = false;
                            if(this.parentFormItem){
                                var parentPolygon = this.parentFormItem.get('PolygonPoints')?.value;
                                if (Geo.GetPolygonPointBounds(parentPolygon).Contains([newLeft, newTop])) 
                                {
                                    this.Left = left;
                                    this.Top = top;
                                    foundParent = true;
                                }
                            }

                            if(!foundParent){
                                const ParentItems = this.leveleditorcomponent.MapItemsByType.get(this.ParentClassName);
                                if(ParentItems){
                                    for (let ParentItem of ParentItems) {
                                        if (Geo.GetPolygonPointBounds((ParentItem as PolygonMapComponent).formItem.get('PolygonPoints')?.value).Contains([newLeft, newTop])) { 
                                            //add to new Parent Form Control
                                            this.parentFormItem = ParentItem.formItem;

                                            if(this.ParentLinkId){
                                                //linked through Id on Parent
                                                this.parentFormItem.get(this.ParentLinkId)?.setValue(null);
                                                ParentItem.formItem.get(this.ParentLinkId)?.setValue(this.formItem.get('Id')?.value);
                                            }
                                            else{
                                                //linked through list on Parent.
                                                let newparentItems = this.leveleditorcomponent.ModelEditor.FormArray(ParentItem.formItem, this.ClassName + "s");
                                                
                                                if(this.IsGuidId){
                                                    var newId = uuidv4();
                                                    this.Id = newId;
                                                    this.formItem.get("Id").setValue(newId);
                                                }

                                                (newparentItems as FormArray).push(this.formItem);
                                               
                                                //remove from old parent FormControl
                                                let oldparentItems = this.leveleditorcomponent.ModelEditor.FormArray(this.parentFormItem, this.ClassName + "s");
                                                
                                                let oldparentItemsArray: any[] = [];
                                                for (let i = 0; i < oldparentItems.length; i++) {
                                                    oldparentItemsArray.push(oldparentItems.at(i));
                                                }
                                                // Find the index of this.formItem within oldparentItems
                                                var index = oldparentItemsArray.indexOf(this.formItem);
                                                (oldparentItems as FormArray).removeAt(index);
                                            }
                                            this.parentFormItem = ParentItem.formItem;
                                            this.formItem.markAsDirty();
                                            this.leveleditorcomponent.toastService.success(this.ClassName + " moved to " + ParentItem.ClassName + ": " + ParentItem.formItem.get("Name")?.value);
                                            var foundParent = true;
                                        }
                                    }  
                                }
                                if(!foundParent){
                                    this.leveleditorcomponent.toastService.error(this.ClassName + " must be inside a " + this.ParentClassName);
                                    this.FabricItem.set({left: this.Left})
                                    this.FabricItem.set({top: this.Top})
                                    this.canvas.renderAll();
                                    return;
                                }
                            }
                        }
                    }

                    this.canvas.renderAll();
            });
            
            this.FabricItem.on('mousedown', () =>  {
                // Check if the group is locked (optional)
                if (this.Locked) {
                    this.leveleditorcomponent.toastService.info('This ' + this.ClassName + ' is Locked. Unlock it to make changes');
                }
                this.leveleditorcomponent.ItemSelected.emit(this);
            });

            this.FabricItem.on("selected", () => {
                this.leveleditorcomponent.ItemSelected.emit(this);
             });
    
             this.FabricItem.on("deselected", () => {
                this.leveleditorcomponent.ItemDeselected.emit(this);
            });

            this.canvas.add(this.FabricItem);
            this.FabricItem.moveTo(this.zIndex);
            this.canvas.renderAll();

            this.ImageLoadedAndUpdated.emit(this);
        });
    }
}