import { Component, Injector, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { FirmwareselectorComponent } from 'src/app/components/dialogs/firmwareselector/firmwareselector.component';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';
import { OrganizationsService } from 'src/app/Services/organizations.service';

@Component({
  selector: 'app-editIRsensor',
  templateUrl: './editIRsensor.component.html',
  styleUrls: ['./editIRsensor.component.scss']
})
export class EditIRSensorComponent extends ModelEditor implements OnInit {

  public ParkingLots: any[] | null = null;
  public TimeZoneName: any;

  constructor(private apiService: ApiServiceBase, public organizationsService: OrganizationsService, private route: ActivatedRoute, private toastService: ToastrService, private modalService: SimpleModalService, private injector: Injector) {
    super("infrastructure/irsensors", injector);

    organizationsService.GetTimeZoneName().then((x : string) => this.TimeZoneName = x);

  }
  public override DefaultModel(): any {
    return {

    };
  }
  public override AfterModelLoaded(): void {
  }
  public override BeforeSave(): void {

  }
  public override Validators(): any {
    return {
      "Name": [Validators.required],
    };
  }
}
