import { LevelEditorComponent } from "../../../leveleditor.component";
import { MapItemTool } from "./MapItemTool";

export class SmallTreeTool extends MapItemTool{
    public override ItemWidth: number = 10;
    public override ItemHeight: number = 10;
    public override Type = "SmallTree";
    public override OutlineColor = "0d5100";
    public override Opacity =  0.8;
    public override FillColor =  "025818";
    public override OutlineThickness = 2;
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }
}