<div *ngIf="BusinessDirectoryId != null" class="field has-addons">
  <div class="control is-expanded">
    <input class="input" [value]="Business.Name" disabled>
    
  </div>
  <div class="control">
    <button type="button" class="button is-light" (click)="ClearUser()">
      <i class="fa fa-trash"></i>
    </button>
  </div>
  
</div>
<div *ngIf="BusinessDirectoryId == null" class="ng-autocomplete position" style="width:100%;" (keypress)="KeyPress($event)">
    <ng-autocomplete 
      [data]="SearchResults"
      [searchKeyword]="SearchTerm"
      placeholder="Search Businesses"
      (selected)='ItemSelected($event)'
      (inputChanged)='SearchChanged($event)'
      [itemTemplate]="itemTemplate"
      [notFoundTemplate]="notFoundTemplate"
      [debounceTime]="300"
      [isLoading]="IsLoading"
      (inputCleared)="Cleared()"
      >
    </ng-autocomplete>
    
    <ng-template #itemTemplate let-item>
        <a [innerHTML]="item.Name"></a>
    </ng-template>
    
    <ng-template #notFoundTemplate let-notFound>
    <div [innerHTML]="notFound"></div>
    </ng-template>
</div>