import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';

import { animate, group, state, style, transition, trigger } from '@angular/animations';

export const SlideInOutAnimation = [
  trigger('slideInOut', [
    state('in', style({
      'opacity': '1',

    })),
    state('out', style({
      'opacity': '0',
    })),
    transition('in => out', [group([
      animate('300ms ease-in-out', style({
        'opacity': '0'
      })),
    ]
    )]),
    transition('out => in', [group([
      animate('1s ease-in-out', style({
        'opacity': '1',
      }))
    ]
    )])
  ]),
]


@Component({
  selector: 'app-validationsummary',
  templateUrl: './validationsummary.component.html',
  styleUrls: ['./validationsummary.component.scss'],
  animations: [
    SlideInOutAnimation
  ]
})
export class ValidationsummaryComponent implements OnInit {

  @Input()
  public Form !: UntypedFormGroup;
  @Input()
  public ShowValidationSummary: boolean = false;

  public formErrors: any[] = [];
  constructor() { }

  ngOnInit(): void {
    if (this.Form != null && this.Form instanceof UntypedFormGroup) {
      this.Form.valueChanges.subscribe(status => {

        this.formErrors = [];
        this.getErrorGromFormGroup(this.Form);
      });
    }
  }



  public showError() {
    if (this.formErrors.length == 0) {

      return false;
    }
    return true;
  }

  private getErrorGromFormGroup(fg: UntypedFormGroup) {
    Object.keys(fg.controls).forEach((element: any) => {
      let control = fg.get(element);
      if (control instanceof UntypedFormArray) {
        control.controls.forEach(elementControl => {
          if (elementControl instanceof UntypedFormGroup) {
            this.getErrorGromFormGroup(elementControl);
          }
        });
      } else {
        if (control instanceof AbstractControl) {
          this.generateErrorMessages(element, control.errors);
        }
      }
    }
    );
  }



  private generateErrorMessages(element: string, errors: any) {
    if (!errors)
      return;
    let errorMessage: string | null = null;
    let lables = this.Form.get('FormLabels')?.value;
    let lableName = element;
    if (lables) {
      // get the lable control
      let value = lables[element];
      if (value) {
        lableName = value;
      }
    }
    if (errors.minlength) {
      errorMessage = lableName + " requires minimum length of " + errors.minlength.requiredLength;
    }

    if (errors.required) {
      errorMessage = lableName + " is required ";
    }

    if (errors.maxlength) {
      errorMessage = lableName + " maximum length of " + errors.maxlength.requiredLength + " reached";
    }

    if (errors.min) {
      errorMessage = lableName + " value must be greater than " + errors.min.min;
    }

    if (errors.max) {
      errorMessage = lableName + " value must be less than " + errors.max.max;
    }

    if (errors.email) {
      errorMessage = lableName + " incorrect address";
    }

    if (errorMessage) {
      console.log("Push Message: " + errorMessage);

      this.formErrors.push(errorMessage);
    }

  }

}
