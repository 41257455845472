<app-loader [Parent]="this"></app-loader>
<div *ngIf="Model != null">
  <app-pageheader [Parent]="this">
      <form-edit-button>Edit</form-edit-button>
      <app-form-delete-button [Parent]="this" [Model]="Model" [DeleteCheck]="false"
      DeleteUrl="organization/roles/" DeleteNavLink="usermanagement/roles">
      </app-form-delete-button>
    </app-pageheader>
  <div class="columns">
    <div class="column is-6">
      <article class="panel">
        <p class="panel-heading">
          Role Information
        </p>
        <div class="panel-block" style="display:block;">
          <app-field Label="Id">
            <div class="control">
              <div class="input is-display">{{Model.Id}}</div>
            </div>
          </app-field>
          <app-field Label="Name">
            <div class="control">
              <div class="input is-display">{{Model.Name}}</div>
            </div>
          </app-field>
          <app-field Label="Role Type">
            <div class="control">
              <div class="input is-display">{{Model.IsAdminRole ? "Admin Role" : "End User Role"}}</div>
            </div>
          </app-field>
          <app-field Label="Role Permissions" *ngIf="Model.IsAdminRole">
            <div class="field is-grouped is-grouped-multiline">
              <div class="control" *ngFor="let p of Model.RolePermissions; let i = index;">
                <div class="tags has-addons">
                  <a class="tag">{{p.Permission}}<p *ngIf="p.PermissionLevelString != null">&nbsp;({{p.PermissionLevelString}})</p></a>
                </div>
              </div>
            </div>
          </app-field>
        </div>
      </article>
    </div>
    <div class="column is-6 image-container" style="text-align: center;">
      <img src="../../../../../assets/images/id-card-clip.svg" width="25%" alt="My Happy SVG"/>
    </div>
  </div>
  <div class="columns">
    <div class="column is-12">
      <article class="panel">
        <p class="panel-heading">
          Role Users
        </p>
        <app-datalistview *ngIf="Model != null && Model.Id != null" [Columns]="Columns" [PageSize]="10"
            [ShowMap]="false" [ApiUrl]="'organization/roles/' + Model?.Id + '/users/searches'"
            [AllowEdit]="true" [EditUrl]="'usermanagement/users/:id'">
        </app-datalistview>
      </article>
    </div>
</div>