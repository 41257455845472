<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Transition</p>
        </header>
        <section class="modal-card-body" style="min-width: 40em;">

            <nav class="panel">
                <a class="panel-block is-active" *ngIf="SelectedEvents.length == 0">
                    <span class="has-italic-text">No Events Selected!</span>
                </a>
                <a class="panel-block is-active" *ngFor="let s of SelectedEvents; let i = index">
                    <span class="panel-icon">
                      <i class="fa fa-bolt" aria-hidden="true"></i>
                    </span>
                    {{s.Event}}
                    <span *ngIf="s.Event == 'After' || s.Event == 'Presence'">
                        &nbsp;<input class="input" style="width: 4em;" type="number" step="1" min="1" [(ngModel)]="s.DelaySeconds" />
                    </span>
                    <span *ngIf="s.Event == 'After' || s.Event == 'Presence'">
                        &nbsp;seconds
                    </span>
                    <span *ngIf="s.IsCustom == true">
                        &nbsp;<input class="input" style="width: 20em" [(ngModel)]="s.CustomEvent" />
                    </span>
                    
                    <i class="fa fa-trash" style="float: right; margin-left: auto;" (click)="DeleteEvent(i)"></i>
                </a>
                <div class="panel-block">
                  <div class="field has-addons">
                    <div class="control has-text-centered">
                      <app-categorized-selector 
                        [transitions]="Transitions" 
                        (selectionChanged)="SelectedEvent = $event.event">
                      </app-categorized-selector>
                    </div>
                    <div class="control is-expanded">
                      <a class="button is-primary" (click)="AddSelectedEvent()">
                        Add
                      </a>
                    </div>
                  </div>
                </div>
                <div class="panel-block" >
                    <div style="width: 40em;">
                      <div *ngIf="SelectedEvent != null">
                        {{SelectedEvent.Description}}
                    </div>
                  </div>
                </div>
              </nav> 



            

        </section>
        <footer class="modal-card-foot">
            <button class="button is-success" (click)="Confirm()">OK</button>
        </footer>
    </div>
</div>