<div class="modal is-active" [formGroup]="Form">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit Sign Configuration</p>
      </header>
      <section class="modal-card-body"style="min-width: 800px;">
          <div class="field is-horizontal">
              <div class="field-label is-normal">
                  <label class="label">Name</label>
              </div>
              <div class="field-body">
                  <div class="field">
                  <p class="control">
                      <input class="input" type="text" placeholder="Name" formControlName="Name" />
                  </p>
                  </div>
              </div>  
          </div>
          <div class="field is-horizontal">
              <div class="field-label is-normal">
                  <label class="label">WakeUp Time</label>
              </div>
              <div class="field-body">
                  <div class="field">
                      <p class="control">
                          <input class="input" type="time" formControlName="WakeUpTime" />
                      </p>
                  </div>
              </div>  
          </div>
          <div class="field is-horizontal">
              <div class="field-label is-normal">
                  <label class="label">Sleep Time</label>
              </div>
              <div class="field-body">
                  <div class="field">
                      <p class="control">
                          <input class="input" type="time" formControlName="SleepTime" />
                      </p>
                  </div>
              </div>  
          </div>
          <div class="field is-horizontal">
              <div class="field-label is-normal">
                  <label class="label">Start of Day</label>
              </div>
              <div class="field-body">
                  <div class="field">
                      <p class="control">
                          <input class="input" type="time" formControlName="StartOfDayTime" />
                      </p>
                  </div>
              </div>  
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="label">End of Day</label>
            </div>
            <div class="field-body">
                <div class="field">
                    <p class="control">
                        <input class="input" type="time" formControlName="EndOfDayTime" />
                    </p>
                </div>
            </div>  
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="label">Brightness (Day)</label>
            </div>
            <div class="field-body">
                <div class="field">
                    <p class="control">
                        <input class="input" type="number" formControlName="DayBrightness" />
                    </p>
                </div>
            </div>  
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="label">Brightness (Night)</label>
            </div>
            <div class="field-body">
                <div class="field">
                    <p class="control">
                        <input class="input" type="number" formControlName="NightBrightness" />
                    </p>
                </div>
            </div>  
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="label">Mode</label>
            </div>
            <div class="field-body">
                <div class="field">
                    <p class="control">
                        <select class="input" formControlName="Mode">
                            <option value="0">0. Polling</option>
                            <option value="1">1. Local Device</option>
                            <option value="2">2. Polling Master</option>
                            <option value="3">3. Local device Master</option>
                            <option value="4">4. Slave</option>
                            <option value="5">5. Push</option>
                            <option value="6">6. Reserved</option>
                        </select>
                    </p>
                </div>
            </div>  
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="label">Display Rule Set</label>
            </div>
            <div class="field-body">
                <div class="field">
                    <p class="control">
                        <select class="input" formControlName="DisplayRuleSetId" name="DisplayRuleSetId">
                            <option *ngFor="let a of SignDisplayRuleSets" [ngValue]="a.Id">{{a.Name}}</option>
                        </select>
                    </p>
                </div>
            </div>
          </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" (click)="confirm()">OK</button>
      </footer>
    </div>
  </div>