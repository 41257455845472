<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Form]="Form" [Parent]="this" (click)="Save()"></app-form-save-button>
    </app-pageheader>

    <div class="columns">
        <div class="column is-6">
            <article class="panel">
                <p class="panel-heading">
                  Valet Type Details
                </p>
                <div class="panel-block" style="display: block;">
                  <app-field Label="Name">
                      <p class="control">
                          <input formControlName="Name" class="input is-fullwidth" type="text" />
                          <app-validator [For]="this.Form.get('Name')"></app-validator>
                      </p>
                  </app-field>
                  <app-field Label="Icon" [HasAddons]="true">
                    <div class="Icon"><i [ngClass]="'fa fa-' + this.Form.get('IconName')?.value" [ngStyle]="{color: '#' + this.Form.get('IconColor')?.value}"></i></div>
                    <p class="control">
                        <input [value]="IconDisplayName" class="input disabled" type="text"/>
                    </p>
                    <div class="control">
                        <app-colorpicker formControlName="IconColor"></app-colorpicker>
                    </div>
                    <div class="control is-fullwidth">
                        <app-fa-iconselector formControlName="IconName"></app-fa-iconselector>
                    </div>
                  </app-field> 
                  <app-field Label="Multi-Session">
                    <p class="control">
                        <app-toggleswitch formControlName="IsMultiSession"></app-toggleswitch>
                    </p>
                  </app-field>
                  <app-field Label="Has Billing key">
                    <p class="control">
                        <app-toggleswitch formControlName="RequiresBillingKey"></app-toggleswitch>
                    </p>
                  </app-field>
                  <app-field Label="Billing Key Name" *ngIf="this.Form.get('RequiresBillingKey')?.value">
                    <p class="control">
                        <input formControlName="BillingKeyName" class="input" type="text">
                    </p>
                  </app-field>
                  <app-field Label="Rate Set" *ngIf="RateSets != null">
                    <div class="control select">
                      <select formControlName="DefaultParkingRateSetId">
                        <option *ngFor="let a of RateSets" [ngValue]="a.Id">{{a.Name}}</option>
                      </select>
                    </div>
                  </app-field>
                </div>
              </article>
        </div>
    </div>