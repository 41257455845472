import { Geo } from "src/app/util/geo";
import { StructureViewerMapsComponent } from "./structureviewermaps.component";
import { SafeHtml, SafeUrl } from "@angular/platform-browser";
import { MapPolygon } from "@angular/google-maps";

export class StructureViewerLayer{
    public Type !: string;
    public DisplayName !: string | null;
    public ApplySearch: boolean = false;
    public ShowSelector: boolean = false;
    public SubItemTypes !: string | null;

    constructor(type: string, OverrideDisplayName: string | null = null, applySearch: boolean = false, showSelect: boolean = false, ItemTypes: string | null = null) {
        this.Type = type;
        this.DisplayName = OverrideDisplayName ?? this.GetDisplayString();
        this.ApplySearch = applySearch;
        this.ShowSelector = showSelect;
        this.SubItemTypes = ItemTypes;
      }

    public GetDisplayString() : string{
        return this.Type.replace(/([a-z])([A-Z])/g, '$1 $2') + 's';
    }
}

const AvailableLayers: StructureViewerLayer[] = [
  new StructureViewerLayer("ParkingLot"),
  new StructureViewerLayer("ParkingLevel"),
  new StructureViewerLayer("ParkingSpace"),
  new StructureViewerLayer("ParkingLevelMapItem", "Floor Map Items", false, false, "FloorItems"),
  new StructureViewerLayer("ParkingLevelMapItem", "Raised Map Items", false, false, "RaisedItems"),
  new StructureViewerLayer("IRSensor", "Sensors"),
  new StructureViewerLayer("LocalController", "Terminals"),
  new StructureViewerLayer("CarCounter"),
  new StructureViewerLayer("SignConfiguration", "Signs"),
  new StructureViewerLayer("GuidanceLightConfiguration", "Guidance Lights"),
  new StructureViewerLayer("GatewayConfiguration", "Gateways"),
  // Add more items as needed
];

export { AvailableLayers };