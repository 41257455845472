import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { FeaturesService } from 'src/app/Services/features.service';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { Busyable } from 'src/app/shared/editors/busyable';

@Component({
  selector: 'app-usershome',
  templateUrl: './usershome.component.html',
  styleUrls: ['./usershome.component.scss']
})
export class UserHomeComponent extends Busyable implements OnInit, AfterViewInit {

  public OrganizationUsersOverview : any = [];
  public AdminRouterLink : any = "";
  public RolePermission : boolean = false;

  public rightColumnheight: number = 0;
  public cdr: ChangeDetectorRef;
  public alerts: any = [];
  public showAlerts: boolean = false;

  @ViewChild("leftColumn")
  public leftColumn!: ElementRef;

  constructor(public apiService: ApiService, public router:Router, public permissions: PermissionsService, cdr: ChangeDetectorRef) { 
    super();
    this.cdr = cdr
  }

  ngOnInit(): void {
    this.Loading();
    this.apiService.Get<any>("organization/users/overview").then(result =>{
      this.OrganizationUsersOverview = result;
      this.StopLoading();
      this.showAlerts = true;
    });
  }

  ngAfterViewInit(): void {
    this.UpdateColumnHeight();
  }

  ngAfterContentInit() {
    this.permissions.PermissionExists("RolePermissionsFeature").then((value: boolean) => {
      this.RolePermission = value;
    });
  }

  public AdminRedirect(){
    this.router.navigate(['/usermanagement/users'], {queryParams : {"IsAdmin": true}});
  }

  public UserRedirect(){
    this.router.navigate(['/usermanagement/users'], {queryParams : {"IsAdmin": false}});
  }

  public AdminRolesRedirect(){
    this.router.navigate(['/usermanagement/roles'], {queryParams : {"UserRoles": false}});
  }

  public EndUserRolesRedirect(){
    this.router.navigate(['/usermanagement/roles'], {queryParams : {"UserRoles": true}});
  }

  public UpdateColumnHeight(){
    if (this.leftColumn) {
      this.rightColumnheight = this.leftColumn.nativeElement.offsetHeight;
      console.log(this.rightColumnheight)
    }
    this.cdr.detectChanges();
  }


}
