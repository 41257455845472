<div class="treenode">
    <div>
        <span class="fa fa-chevron-right" *ngIf="CanExpand && !Expanded" (click)="Toggle()"></span>
        <span class="fa fa-chevron-down" *ngIf="CanExpand && Expanded" (click)="Toggle()"></span>
        <span class="node-text" [ngClass]="{ 'is-active' : Selected}"  (click)="HandleClick($event)" (dblclick)="HandleDoubleClick($event)">
            <img class="node-image" *ngIf="Image != null" [src]="Image" />
            {{Text}}
        </span>
        <span *ngIf="CanDelete" (click)="HandleDelete($event)" class="has-text-grey-light"><i class="fa fa-trash"></i> </span>
    </div>
    <div class="node-content" *ngIf="Expanded">
        <ng-content></ng-content>
    </div>
</div>