<div class="modal is-active" [formGroup]="Form">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit Lane</p>
      </header>
      <section class="modal-card-body"style="min-width: 800px;">
            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="label">Name</label>
                </div>
                <div class="field-body">
                    <div class="field">
                    <p class="control">
                        <input formControlName="Name" class="input" type="text" placeholder="Name" />
                    </p>
                    </div>
                </div>  
            </div>
            <app-field Label="Controlled By">
                <div class="control">
                    <div class="select">
                        <select formControlName="LocalControllerId">
                            <option *ngFor="let c of DisplayControllers" [ngValue]="c.Id">
                                {{c.Name}}
                            </option>
                        </select>
                    </div>
                </div>
            </app-field>
            <div *ngIf="Form.get('AllowedDirections')?.value != 'Exit'">
                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label">Entry Configuration</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <div class="select">
                                    <select formControlName="EntryWorkflowId" (change)="EntryWorkflowChanged()">
                                        <option *ngFor="let w of Workflows" [value]="w.Id">{{w.Name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
            <div *ngIf="Form.get('AllowedDirections')?.value != 'Entry'">
                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label">Exit Configuration</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <div class="select">
                                    <select formControlName="ExitWorkflowId" (change)="ExitWorkflowChanged()">
                                        <option *ngFor="let w of Workflows" [value]="w.Id">{{w.Name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="label">Allowed Direction</label>
                </div>
                <div class="field-body">
                    <div class="field">
                        <div class="control">
                            <div class="select">
                                <select formControlName="AllowedDirections">
                                    <option value="Entry">Entry</option>
                                    <option value="Exit">Exit</option>
                                    <option value="Both">Both</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" (click)="confirm()">OK</button>
      </footer>
    </div>
  </div>