import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { Busyable } from 'src/app/shared/editors/busyable';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent extends Busyable implements OnInit {
  public ModelId: any;
  public Model: any;
  
  constructor(private apiService: ApiServiceBase, private parentRoute: ActivatedRoute) {
    super();
  }

  
  public Columns: Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("First Name", "FirstName"),
    new Datalistviewcolumn("Last Name", "LastName"),
    new Datalistviewcolumn("Email", "Email")
  ];

  ngOnInit(): void {
    this.Loading();
    this.parentRoute.params.subscribe(params => { this.ModelId = params['id']; });
    this.apiService.Get<any>("organization/roles/" + this.ModelId).then(result => {
      this.Model = result;
      this.StopLoading();
    });
  }
}
