import { AfterViewInit, Component, ContentChild, Input } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { TabsComponent } from 'src/app/shared/forms/tabs/tabs.component';

@Component({
  selector: 'app-clusterdesigner',
  templateUrl: './clusterdesigner.component.html',
  styleUrls: ['./clusterdesigner.component.scss']
})
export class ClusterdesignerComponent {

  @Input()
  public Parent !: ModelEditor;
  @Input()
  public Form !: FormGroup<any>;
  @ContentChild(TabsComponent)
  public Tabs !: TabsComponent;
  
  public CurrentDragItem : FormControl|null = null;
  public InitialDragItemValues : any;
  public CurrentDragStartEvent : MouseEvent | null = null;
  public SelectedElement : UntypedFormGroup | null = null;
  public SelectedIndex : number = -1;
  public Types : string[][] = [['Rectangle','rect'], ['Ellipse', 'ellipse'], ['Text', 'text'],['Space Type Icon', 'spaceTypeIcon']];
  public SelectedTool : string | null = null;

  public GetElementsArray() : any{
    if(this.Form == null) return null;
    const result = this.Form.get("Elements") as FormArray;
    return result.controls;
  } 

  public DesignSurfaceMouseDown(evt: MouseEvent){
    if(this.SelectedTool != null){
      const newItem = {Type: this.SelectedTool, X: evt.offsetX, Y: evt.offsetY, Width: "100", Height: 100, FillColor: "000000", StrokeColor: "FF0000", StrokeWidth: 3, Text: "", CornerRadius: "0", FontSize: "10", Opacity: "1", SpaceTypeCountIndex: null};
      if(this.SelectedTool == 'text') {
        newItem.Text = "Text";
        newItem.FontSize = "14";
      }
      this.Parent.AddToFormArray(this.Parent.FormArray(this.Form, "Elements"), newItem, 'Elements');
      this.SelectedTool = null;
      return;
    }
  }
  public ElementMouseDown(element:FormControl, evt: MouseEvent){
    
    this.CurrentDragItem = element;
    this.CurrentDragStartEvent = evt;
    this.InitialDragItemValues = element.value;
  }
  public DesignSurfaceMouseMove(evt: MouseEvent){
    if(this.CurrentDragItem != null && this.CurrentDragStartEvent != null){
      const x =this.CurrentDragItem.get('X');
      const y = this.CurrentDragItem.get('Y');
      const dx = evt.clientX - this.CurrentDragStartEvent.clientX;
      const dy = evt.clientY - this.CurrentDragStartEvent.clientY;
      const ix = parseInt(this.InitialDragItemValues.X);
      const iy = parseInt(this.InitialDragItemValues.Y);
      x?.setValue(ix + dx);
      y?.setValue(iy + dy);
    }
  }
  public DesignSurfaceMouseUp(evt: MouseEvent){
    this.CurrentDragItem = null;
    this.CurrentDragStartEvent = null;
  }

  public SelectElement(element : UntypedFormGroup, index : number){
    this.SelectedElement = element;
    this.SelectedIndex = index;
  }
  public SetTool(toolname: string){
    this.SelectedTool = toolname;
  }

  public GetElementCenterX(element : UntypedFormGroup) : number{
    if(element != null){
      return parseInt(element.get('X')?.value)+parseInt(element.get('Width')?.value)/2
    }
    return 0;
  }
  public GetElementCenterY(element : UntypedFormGroup) : number{
    if(element != null){
      return parseInt(element.get('Y')?.value)+parseInt(element.get('Height')?.value)/2
    }
    return 0;
  }
  public GetElementWidth(element : UntypedFormGroup) : number{
    if(element != null){
      return parseInt(element.get('Width')?.value);
    }
    return 0;
  }
  public GetElementHeight(element : UntypedFormGroup) : number{
    if(element != null){
      return parseInt(element.get('Height')?.value);
    }
    return 0;
  }

  public FillColorChanged(evt: string){
    this.SelectedElement?.get("Fill")?.setValue(evt);
  }
  public RemoveSelectedElement(){
    if(this.SelectedIndex < 0) return;
    let elems : FormArray = this.Form?.get("Elements") as FormArray;
    elems.removeAt(this.SelectedIndex);
  }
  public MoveElementUp(index: number){
    if(index < 1) return;
    let elems : FormArray = this.Form?.get("Elements") as FormArray;
    let temp = elems.at(index-1);
    elems.setControl(index-1, elems.at(index));
    elems.setControl(index, temp);
    this.UpdateElementLayerIndex();
  } 
  public MoveElementDown(index: number){
    if(index < 0) return;
    let elems : FormArray = this.Form?.get("Elements") as FormArray;
    if(index >= elems.length-1) return;
    let temp = elems.at(index+1);
    elems.setControl(index+1, elems.at(index));
    elems.setControl(index, temp);
    this.UpdateElementLayerIndex();
  }

  public UpdateElementLayerIndex(){
    let elems : FormArray = this.Form?.get("Elements") as FormArray;
    elems.controls.forEach((c, i) => {
      c.get("LayerIndex")?.setValue(i);
    });
  }

}
