<app-loader [Parent]="Busyable"></app-loader>

<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Firmware Versions</p>
      </header>
      <section class="modal-card-body" style="min-width: 800px; max-height: 80vh; overflow-y: auto;">
        <table class="table is-striped is-fullwidth">
            <thead>
                <tr>
                    <th>File</th>
                    <th>Version</th>
                    <th>Uploaded On</th>
                    <th>Size</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let v of FirmwareVersions">
                    <td>
                        {{v.FileName}}
                    </td>
                    <td>
                        {{v.Version}}
                    </td>
                    <td>
                        {{v.UploadedOnString}}
                    </td>
                    <td>
                        {{v.FileSize}}
                    </td>
                    <td>
                        <button type="button" class="button is-primary" (click)="SelectVersion(v)">
                            Select
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

      </section>
      <footer class="modal-card-foot">
      </footer>
    </div>
  </div>