<app-pageheader [Parent]="this">
  <add-button AddRoute="/usermanagement/users/new/edit"></add-button>
</app-pageheader>

<app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="organization/users/searches" 
  [AllowEdit]="true" EditUrl="/usermanagement/users/:id" [UseQueryParamStates]="true">
  <app-filter-set>
    <div app-search-filter></div>
    <div app-admin-filter [SelectedIsAdmin]="isAdmin"></div>
    <div app-search-button></div>
  </app-filter-set>
</app-datalistview>