import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-editcontrolledareacamera',
  templateUrl: './editcontrolledareacamera.component.html',
  styleUrls: ['./editcontrolledareacamera.component.scss']
})
export class EditcontrolledareacameraComponent extends SimpleModalComponent<any, null> {

  public Form !: UntypedFormGroup;

  constructor() {
    super();
  }

  isProduction() {
    if (environment.production) {
      return true;
    }
    return false;
  }
  confirm() {
    this.close();
  }
}