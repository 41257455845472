import { Component, Input, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-parkinglotselector',
  templateUrl: './parkinglotselector.component.html',
  styleUrls: ['./parkinglotselector.component.scss']
})
export class ParkinglotselectorComponent extends SimpleModalComponent<any, any> {
  
  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Spaces", "TotalSpaces"),
    new Datalistviewcolumn("Occupied", "OccupiedSpaces")
  ];

 
  
  constructor() {
    super();
  }

  public ItemSelected(item : any){
    this.result = item;
    this.close();
  }
  
  confirm() {
    
    this.close();
  }
}