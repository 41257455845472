<app-pageheader [Parent]="this">
  <add-button AddRoute="/valet/sessions/new/edit"></add-button>
</app-pageheader>

<app-datalistview #DataList [Columns]="Columns" [ShowMap]="false" ApiUrl="valet/sessions/searches"
  [AllowEdit]="true" EditUrl="/valet/sessions/:id" [HighLightCondition]="['Open']" [UseQueryParamStates]="false" [ShowIssues]="true" [PreLoad]="false">
  <app-filter-set>
    <div app-search-filter></div>
    <div app-valettypes-filter></div>
    <div app-subset-filter [Subsets]="Subsets"></div>
    <div app-search-button></div>
  </app-filter-set>
  <div class="tags" style="display: inline-flex; margin-right:3em">
    <span class="Item" *ngFor="let summaryItem of SummaryItems" [ngStyle]="{'cursor': summaryItem.Action != null ? 'pointer' : ''}" (click)="HandleSummaryItem(summaryItem)"><i class="Icon fa" [ngClass]="'fa-' + summaryItem.IconName" [ngStyle]="{'color': '#' + summaryItem.IconColor}"></i>{{summaryItem.Count}} {{summaryItem.Description}}</span>
  </div>
</app-datalistview>