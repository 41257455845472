<app-loader [Parent]="this"></app-loader>
<div *ngIf="Model != null">
  <app-pageheader [Parent]="this">
    <form-edit-button>Edit</form-edit-button>
    <app-form-delete-button [Parent]="this" [Model]="Model" DeleteUrl="parking/permittypes/" [DeleteCheck]="true" Api="parking" TypeName="ParkingPermitType"
    DeleteNavLink="/permits/permittypes"></app-form-delete-button>
  </app-pageheader>

  <div class="columns">
    <div class="column is-6">
      <article class="panel">
        <p class="panel-heading">
          Permit Type Details
        </p>
          <div class="panel-block">
            <span class="label">Active Dates</span>
            <span class="value" *ngIf="Model.ActiveStartDateLocal!=null || Model.ActiveEndDateLocal!=null"><app-daterangedisplay FieldName="Active Dates" [StartDate]="Model.ActiveStartDateLocalFriendlyString" [EndDate]="Model.ActiveEndDateLocalFriendlyString"></app-daterangedisplay></span>
            <span class="value" *ngIf="Model.ActiveStartDateLocal==null && Model.ActiveEndDateLocal==null">always active</span>
          </div>
          <div class="panel-block" *ngIf="Model.PurchaseStartDateLocalFriendlyString == '' && Model.PurchaseEndDateLocalFriendlyString == ''">
            <span class="label">Purchase Dates</span>
            <span class="value" *ngIf="Model.PurchaseStartDateLocal!=null || Model.PurchaseEndDateLocal!=null"><app-daterangedisplay FieldName="Purchase Dates" [StartDate]="Model.ActiveStartDateLocalFriendlyString" [EndDate]="Model.ActiveEndDateLocalFriendlyString"></app-daterangedisplay></span>
            <span class="value" *ngIf="Model.PurchaseStartDateLocal==null && Model.PurchaseEndDateLocal==null">always available</span>
          </div>
          <div class="panel-block" *ngIf="Model.PurchaseStartDateLocalFriendlyString == '' && Model.PurchaseEndDateLocalFriendlyString != ''">
            <span class="label">Purchase Dates</span>
            <span class="value"><app-daterangedisplay FieldName="Purchase Dates" [StartDate]="Model.ActiveStartDateLocalFriendlyString" [EndDate]="Model.PurchaseEndDateLocalFriendlyString"></app-daterangedisplay></span>
          </div>
          <div class="panel-block">
            <span class="label">Max Active Permits</span>
            <span class="value">{{Model.MaxActivePermits ?? 'No Limit'}}</span>
          </div>
          <div class="panel-block">
            <span class="label">Price</span>
            <span class="value">{{orgService.GetCurrencySymbol()}}{{Model.Price.toFixed(2)}} {{Model.ChargePeriodString}} {{((Model.MultiUser && Model.SplitPayments) ? ' - Split Payment' : '')}}</span>
          </div>
          <div class="panel-block">
            <span class="label">Users</span>
            <span class="value">{{BuildUsersString()}}</span>
          </div>
          <div class="panel-block" *ngIf="Model.MultiUser">
            <span class="label">Confirmations</span>
            <span class="value">{{(Model.RequireConfirmations ? 'Confirmation by members required within ' + Model.MaxConfirmationDistance + orgService.KMorMiles() : 'Not Required')}}</span>
          </div>
          <div class="panel-block">
            <span class="label">Requires Registered Vehicles</span>
            <span class="value">{{Model.RequireRegisteredVehicles ? "True" : "False"}}</span>
          </div>
          <div class="panel-block">
            <span class="label">Wait list Enabled</span>
            <span class="value">{{Model.EnableWaitlist ? "True" : "False"}}</span>
          </div>
          <div class="panel-block">
            <span class="label">Limit to a Parking Space</span>
            <span class="value">{{Model.LimitToParkingSpace ? "True" : "False"}}</span>
          </div>
          <div class="panel-block">
            <span class="label">Restrictions</span>
            <span class="value" style="width:65%"><div class="is-display" [innerHtml]="Model.Restriction" style="font-size:small;"></div></span>
          </div>
          <div class="panel-block">
            <span class="label">Incompatible permits</span>
            <span class="value" *ngIf='Model.MutuallyExclusivePermitTypes.length != null && Model.MutuallyExclusivePermitTypes.length > 0'>
              <div class="field is-grouped is-grouped-multiline">
                <div class="control" *ngFor="let x of Model.MutuallyExclusivePermitTypes; let i = index;">
                  <div class="tags has-addons">
                    <a class="tag" href="/permits/permittypes/{{x.Id}}">{{x.Name}}</a>
                  </div>
                </div>
              </div>
            </span>
            <span class="value" *ngIf='Model.MutuallyExclusivePermitTypes.length == 0 || Model.MutuallyExclusivePermitTypes.length == null'>
              No incompatible permits
            </span>
          </div>
      </article>
    </div>


    <div class="column is-6">
      <google-map [options]="MapOptions" height="100%" width="100%" [zoom]="18">
        <map-polygon *ngFor="let p of MapPolygons" [paths]="p" [options]="{strokeColor : '#555', fillColor : '#555'}">
        </map-polygon>
      </google-map>
    </div>
  </div>
  <div class="columns" *ngIf="Model.EnableWaitlist">
    <div class="column is-12">
      <article class="panel">
        <p class="panel-heading">
          Wait List
        </p>
        <app-datalistview #WhiteList [Columns]="Columns" [ShowMap]="false" ApiUrl='parking/permittypes/waitlists/{{Model.Id}}/searches'
          [AllowEdit]="false" [ShowPaging]="true" [EditUrlPerItem]="true" [AllowDelete]="true" [DeleteAction]="DeleteItem">
          <app-filter-set>
            <div app-search-filter></div>
            <div app-subset-filter [Subsets]="['Pending', 'Assigned', 'All']" Subset="Pending"></div>
            <div app-search-button></div>
            <div style="padding-left:20px"><add-button (AddObject)="AddWaitlistedUser()"></add-button></div>
          </app-filter-set>
        </app-datalistview>
      </article>
    </div>
  </div>