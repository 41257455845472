import { ThisReceiver } from "@angular/compiler";
import { Bounds, Geo } from "src/app/util/geo";
import { Geometry2d } from "src/app/util/geometry2d";
import * as THREE from "three";
import { Scene, Vector2, Vector3 } from "three";
import { LevelObjectMesh } from "./level-object-mesh";
import { meshanimationresult } from "./meshanimationresult";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { MeshConstants } from "src/app/util/mesh-constants";

export class SpaceMesh extends THREE.Mesh {
    private Scene !: Scene;
    private Space !: any;
    private VehicleMesh : any|null = null;
    private FrogMesh : any|null = null;
    private Offset !: Vector3;
    private Scale !: Vector2;
    public mixers: meshanimationresult[] = [];

    constructor(scene : Scene, space: any, offset : Vector3, scale: Vector2, mixers: meshanimationresult[]){
        super();
        this.Scene = scene;
        this.Space = space;
        this.Offset = offset;
        this.Scale = scale;
        this.mixers = mixers;

        let p = space.PolygonPoints;
        let shape = new THREE.Shape()
        shape.moveTo(p[1][0]*scale.x + offset.x, -1*p[1][1]*scale.y + offset.y);
        shape.lineTo(p[2][0]*scale.x + offset.x, -1*p[2][1]*scale.y + offset.y);

        let dx = p[2][0]*scale.x + offset.x - p[3][0]*scale.x + offset.x;
        let dy = -1*p[2][1]*scale.y + offset.y - -1*p[3][1]*scale.y + offset.y;
        let angle = Math.atan2(dy, dx);
        let length = 0.00005;
        let x = p[2][0]*scale.x + offset.x + length * Math.cos(angle);
        let y = -1*p[2][1]*scale.y + offset.y + length * Math.sin(angle);
        shape.lineTo(x, y);

        dx = p[1][0]*scale.x + offset.x - p[3][0]*scale.x + offset.x;
        dy = -1*p[1][1]*scale.y + offset.y - -1*p[3][1]*scale.y + offset.y;
        angle = Math.atan2(dy, dx);
        length = 0.00005;
        x = p[1][0]*scale.x + offset.x + length * Math.cos(angle);
        y = -1*p[1][1]*scale.y + offset.y + length * Math.sin(angle);
        shape.lineTo(x, y);

        dx = p[0][0]*scale.x + offset.x - p[2][0]*scale.x + offset.x;
        dy = -1*p[0][1]*scale.y + offset.y - -1*p[2][1]*scale.y + offset.y;
        angle = Math.atan2(dy, dx);
        length = 0.00005;
        x = p[0][0]*scale.x + offset.x + length * Math.cos(angle);
        y = -1*p[0][1]*scale.y + offset.y + length * Math.sin(angle);
        shape.lineTo(x, y);

        dx = p[3][0]*scale.x + offset.x - p[2][0]*scale.x + offset.x;
        dy = -1*p[3][1]*scale.y + offset.y - -1*p[2][1]*scale.y + offset.y;
        angle = Math.atan2(dy, dx);
        length = 0.00005;
        x = p[3][0]*scale.x + offset.x + length * Math.cos(angle);
        y = -1*p[3][1]*scale.y + offset.y + length * Math.sin(angle);
        shape.lineTo(x, y);

        shape.lineTo(p[3][0]*scale.x + offset.x, -1*p[3][1]*scale.y + offset.y);
        shape.lineTo(p[0][0]*scale.x + offset.x, -1*p[0][1]*scale.y + offset.y);
        shape.lineTo(p[1][0]*scale.x + offset.x, -1*p[1][1]*scale.y + offset.y);

        const extrudeSettings = {
            steps: 2,
            depth: 0.00001,
            bevelEnabled: false,
            bevelThickness: 0.0001,
            bevelSize: 0.0001,
            bevelOffset: 0,
            bevelSegments: 1
        };

        let geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
        geometry.translate(0,0,offset.z + 0.0001);


        const texture = new THREE.TextureLoader().load('/assets/textures/paint.jpg');
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.offset.set( 0, 0.5 );
        texture.repeat.set( 100,100 );
        texture.needsUpdate = true;

        let material = new THREE.MeshLambertMaterial({ color: 0x555555, map : texture });
        this.geometry = geometry;
        this.material = material;
        this.type = "space";

        if(this.Space.SensorChipId != null){
            this.ShowFrog(scale, scene, offset);
        }

        scene.add(this);

        if(space.IsOccupied){
            this.ShowVehicle();
        }
    }

    public HandleEvent(item: SpaceMesh, messagetype : string){
        if(messagetype=="CarOnEvent")
        {
          item.ShowVehicle();
          let date =  new Date().getTime();
          item.Space.OccupiedSince = date;
        }
        if(messagetype=="CarOffEvent")
        {
            item.HideVehicle();
        }
    }

    public UpdateVehicleColor(){
        if(this.VehicleMesh != null){
            if(this.Space.OccupiedSince != null){
                const now = new Date();
                const occupiedSince = new Date(this.Space.OccupiedSince + "Z");
                let minutesSinceOccupied = Math.floor(((now.getTime() - occupiedSince.getTime()) / 1000)/60);
                if(minutesSinceOccupied > 255) minutesSinceOccupied = 255;
                const hexValue = parseInt(minutesSinceOccupied.toString(16).padStart(2, '0'), 16);
                this.CalculateHeatmapColor(minutesSinceOccupied, this.VehicleMesh);
            }
        }
    }

    public ShowVehicle(){
        if(this.VehicleMesh != null && this.VehicleMesh.ResultingMesh != null) return;
        let bounds = Geo.GetPolygonPointBounds(this.Space);
        let angle = Geometry2d.FindRectangleRotation(this.Space.PolygonPoints);
        const vehicle : LevelObjectMesh = new LevelObjectMesh(this.Scene, {LocationPoints : bounds.Center, DisplayAngle: angle }, this.Offset, this.Scale, 'vehicle', false, null,  this.mixers);
        this.VehicleMesh = vehicle;

        if(this.Space.OccupiedSince != null)
        {
            const now = new Date();
            const occupiedSince = new Date(this.Space.OccupiedSince + "Z");
            let minutesSinceOccupied = Math.floor(((now.getTime() - occupiedSince.getTime()) / 1000)/60);
            if(minutesSinceOccupied > 255) minutesSinceOccupied = 255;
            const hexValue = parseInt(minutesSinceOccupied.toString(16).padStart(2, '0'), 16);
            this.CalculateHeatmapColor(minutesSinceOccupied, vehicle);
        }
        else if(this.Space.IsOccupied){
            vehicle.SetColor("Body", 0x202020);
        }
    }

    public ShowFrog(scale: Vector2, scene: Scene, offset : Vector3){
        let bounds = Geo.GetPolygonPointBounds(this.Space);
        let angle = Geometry2d.FindRectangleRotation(this.Space.PolygonPoints);
        
        let loader = new GLTFLoader();
        let factor = MeshConstants.scaleFactor + 0.001;

        loader.load('/assets/3d-models/frog.glb', (gltf: any) => {
            // adjust the size of the object to add into the scene
                    gltf.scene.position.x = bounds.Center[0] * scale.x + offset.x;
                    gltf.scene.position.y = -1 * (bounds.Center[1] * scale.y + offset.y);
                    gltf.scene.position.z = MeshConstants.floorThickness;
                    gltf.scene.rotation.x = Math.PI / 2;
                    
                    if (angle == 0) {
                      //gltf.scene.rotation.y = 0;
                    } else {
        
                        gltf.scene.rotation.y = ((-1*angle) * (Math.PI / 180))
                    }

                    gltf.scene.scale.set(factor, factor, factor);
                    this.FrogMesh = gltf;
                    scene.add(gltf.scene);

                  }, undefined, function (err: any) {
                    console.log(err);
                });
    }

    public CalculateHeatmapColor(value: number, vehicle: any): number {
    // Calculate the normalized value between 0 and 1
    const normalizedValue = value / 255;

    // Define the color stops
    const colorStops = [
        { value: 0.05, color: [224, 41, 20] },      // Red
        { value: 0.3, color: [208, 212, 21] },  // Yellow
        { value: 0.6, color: [50, 138, 21] },    // Green
        { value: 0.8, color: [21, 56, 138] },    // Blue
        { value: 1, color: [6, 24, 64] },      // Dark blue
    ];

    // Find the color stop before and after the normalized value
    let i;
    for (i = 0; i < colorStops.length - 1; i++) {
        if (normalizedValue <= colorStops[i + 1].value) {
        break;
        }
    }

        // Calculate the interpolation factor between the two color stops
        const factor = (normalizedValue - colorStops[i].value) / (colorStops[i + 1].value - colorStops[i].value);

        // Interpolate the color between the two color stops
        const color = [
            Math.round(colorStops[i].color[0] + factor * (colorStops[i + 1].color[0] - colorStops[i].color[0])),
            Math.round(colorStops[i].color[1] + factor * (colorStops[i + 1].color[1] - colorStops[i].color[1])),
            Math.round(colorStops[i].color[2] + factor * (colorStops[i + 1].color[2] - colorStops[i].color[2])),
        ];

        // Convert the color to a number in the format 0xRRGGBB
        const hexColor = (color[0] << 16) + (color[1] << 8) + color[2];

        vehicle.SetColor("Body", hexColor);
        // Return the color as a number
        return hexColor;
    }

    public HideVehicle(){
        if(this.VehicleMesh.ResultingMesh != null){
            this.Scene.remove(this.VehicleMesh.ResultingMesh);
            this.VehicleMesh = null;
        }
    }
}
