import { AfterContentInit, AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { Validators } from '@angular/forms';
import { DatalistviewComponent } from 'src/app/shared/datalistview/datalistview.component';
import { EditplateaccesslistplateComponent } from './plates/edit/editplateaccesslistplate.component';
import { ApiService } from 'src/app/Services/api.service';
import { DeleteCheckComponent } from 'src/app/shared/modals/delete-check/delete-check.component';

@Component({
  selector: 'app-editplateaccesslist',
  templateUrl: './editplateaccesslist.component.html',
  styleUrls: ['./editplateaccesslist.component.scss']
})
export class EditplateaccesslistComponent extends ModelEditor implements AfterContentInit {

  @ViewChild(DatalistviewComponent)
  public ListView !: DatalistviewComponent;

  constructor(private injector: Injector, private modalService: SimpleModalService, private apiService: ApiService) {
    super("parking/plateaccess", injector, 'listid');
  }

  public override DefaultModel(): any {
    return {
      Name: "New Access List",
      Description: ""
    };
  }

  public override AfterModelLoaded(): void {
  }

  public override BeforeSave(): boolean | void {
  }

  public override Validators(): any {
    return {
      "Name": [Validators.required, Validators.minLength(1)],
    }
  }


  ngAfterContentInit(): void {
  }

  public Columns: Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("License Plate", "PlateNumber"),
    new Datalistviewcolumn("Owner", "UserFullName"),
    //new Datalistviewcolumn("Expires", "ExpiresOn")
  ];

  public async AddUser(id: any) : Promise<void> {
    var m = {
      PlateAccessListName: "",
      PlateNumber: "",
      UserId: null,
      Id: null,
    };
    m.PlateAccessListName = this.Model?.Name;
    if(id != "new" && id.Id != "new") {
      m = id;
    }

    this.modalService.addModal(EditplateaccesslistplateComponent, {Model: m}).subscribe(async (result) => {
      if(result != null) {
        this.Loading();
        if(result.Id == null){
          await this.apiService.Post("parking/plateaccess/" + this.Model.Id + "/plates", result);
        }
        else if (result.Id != null) {
          await this.apiService.Put("parking/plateaccess/" + result.PlateAccessListId + "/plates/" + result.Id, result);
        }
        this.StopLoading();
        this.Refresh();
      }
        
    })
  }

  public async DeletePlate(plate: any) {
    this.modalService.addModal(DeleteCheckComponent, 
      { name: plate.PlateNumber, url: "parking/plateaccess/" + plate.PlateAccessListId + "/plates/", modelId: plate.Id, deleteNavLink: "parking/accesscontrol/accesslists/" + plate.PlateAccessListId, SkipCheck: true }
      ).subscribe((result) => {
        this.Refresh();
      });
  }

  public Refresh() {
    this.ListView?.Refresh();
  }
}
