import { Component, Input, OnInit, Self, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

@Component({
  selector: 'app-toggleswitch',
  templateUrl: './toggleswitch.component.html',
  styleUrls: ['./toggleswitch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleSwitchComponent),
      multi: true,
    },
  ],
})

export class ToggleSwitchComponent implements OnInit, ControlValueAccessor {

  @Input() 
  value: any;

  @Input()
  public SwitchStyle: string = "rounded";

  @Input()
  public Disabled: boolean = false;

  onChange: any = () => {};
  onTouched: any = () => {};

  onChangeFull(value : any) {
    this.onChange(value.target.checked);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.Disabled = isDisabled;
  }

  ngOnInit(): void {
  }

}
