import { Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList } from '@angular/core';
import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent> = new QueryList();
  @Output() SelectedIndexChanged = new EventEmitter<number>();
  @Output() TabInsertRequested = new EventEmitter<number>();
  @Output() TabAddRequested = new EventEmitter();
  @Output() TabDeleteRequested = new EventEmitter<number>();
  @Input()
  public AllowInsertRequest : boolean = false;
  @Input()
  public AllowAddRequest : boolean = false;
  @Input()
  public AllowDeleteRequest : boolean = false;
  @Input()
  public HeightPercent :number = 100;
  public CurrentTabIndex : number =0;

  constructor() { }

  ngOnInit(): void {
  }

  
  // contentChildren are set
  ngAfterContentInit() {
    // get all active tabs
    let activeTabs = this.tabs.filter((tab)=>tab.Active);
    
    // if there is no active tab set, activate the first
    if(activeTabs.length === 0 && this.tabs.length > 0) {
      this.selectTab(this.tabs.first);
    }

    this.tabs.changes.subscribe(() => {
      let activeTabs = this.tabs.filter((tab)=>tab.Active);
      if(activeTabs.length == 0)
      { 
        if(this.tabs.length > (this.CurrentTabIndex-1))
          this.selectTab(this.tabs.toArray()[this.CurrentTabIndex]);
        else if(this.tabs.length > 0) 
          this.selectTab(this.tabs.first);
      }

    });
  }
  
  selectTab(tab: TabComponent){
    // deactivate all tabs
    this.tabs.toArray().forEach(tab => tab.Active = false);
    // activate the tab the user has clicked on.
    tab.Active = true;
    
    let newTabIndex : number = 0;
    let arrayTabs = this.tabs.toArray();
    for(let i=0; i < arrayTabs.length; i++){
      if(arrayTabs[i] == tab){
        newTabIndex = i;
        break;
      }
    }
    this.CurrentTabIndex = newTabIndex;
    this.SelectedIndexChanged.emit(newTabIndex);
  }

  public Insert(index : number){
    this.TabInsertRequested.emit(index);
  }

  public AddTab(){
    this.TabAddRequested.emit();
  }
  public DeleteTab(index : number){
    this.TabDeleteRequested.emit(index);
  }
}
