<div class="modal is-active" [formGroup]="Form">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Barrier</p>
      </header>
      <section class="modal-card-body"style="min-width: 800px;">
        <app-tabs>
            <app-tab Title="Barrier Details">
                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label">Barrier Type</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                        <div class="control">
                            <div class="select">
                                <select formControlName="Type">
                                    <option value=""></option>
                                    <option value="mo24">ELKA Boom Gate</option>
                                    <option value="froggatev1">Frog Gate</option>
                                    <option value="tcp-kp-1202">1202 Relay</option>
                                </select>
                            </div>
                        </div>
                        </div>
                    </div>  
                </div>
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Serial Number</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                            <p class="control">
                                <input formControlName="SerialNumber" class="input" type="text" placeholder="Serial" />
                            </p>
                            </div>
                        </div>  
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">IP Address</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                            <p class="control">
                                <input formControlName="LocalAddress" class="input" type="text" placeholder="Name" />
                            </p>
                            </div>
                        </div>  
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Port</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                            <p class="control">
                                <input formControlName="LocalPort" class="input" type="text" placeholder="Name"/>
                            </p>
                            </div>
                        </div>  
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Enabled for</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <div class="control">
                                    <div class="select">
                                        <select formControlName="ActiveDirection">
                                            <option value="Entry">Entry</option>
                                            <option value="Exit">Exit</option>
                                            <option value="Both">Both</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Opening Direction</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <div class="control">
                                    <div class="select">
                                        <select formControlName="OpenDirection">
                                            <option value="Left">Left</option>
                                            <option value="Right">Right</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </app-tab>
        </app-tabs>
        
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" (click)="confirm()">OK</button>
      </footer>
    </div>
  </div>