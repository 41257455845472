import { Component, OnInit } from '@angular/core';
import { ColorDisplaycolumn, Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { MediaService } from 'src/app/Services/media.service';

@Component({
  selector: 'app-spacetypes',
  templateUrl: './spacetypes.component.html',
  styleUrls: ['./spacetypes.component.scss']
})
export class SpacetypesComponent implements OnInit {
  constructor(private mediaService: MediaService) { }

  public Columns : Array<Datalistviewcolumn> = [
    new ColorDisplaycolumn("Color", "Color", "5em", "MediaId", this.mediaService),
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Description", "Description")
  ];

  ngOnInit(): void {
  }
}