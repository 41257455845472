import { AfterContentChecked, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { LocalStorageService } from 'src/app/Services/local-storage.service';
import { FilterFiltersBase, IFilter, IFilterFilters } from '../ifilter';

@Component({
  selector: '[app-rates-filter]',
  templateUrl: './rates-filter.component.html',
  styleUrls: ['./rates-filter.component.scss'],
  host: {'class':'control select'}
})
export class RatesFilterComponent extends FilterFiltersBase implements OnInit, IFilterFilters {

  constructor(private apiService: ApiServiceBase, route: ActivatedRoute) {
    super(route);
  }
  public FilterName: string = "rateset";
  public Filters: any[] = [];
  public SelectedRateSet: any = "";
  public RateSets: any;
  @Input()
  public QueryParamPrefix: string= '';

  ngOnInit(): void {
    var vals = super.GetParamFields(this.QueryParamPrefix + this.FilterName);
    if(vals != null) {
      this.SelectedRateSet = vals[2];
    }

    if (this.SelectedRateSet != "") {
      this.Filters.push({
        Property: "ParkingRateSetId",
        Comparator: "==",
        Value: this.SelectedRateSet,
        FilterName: this.FilterName
      })
    }

    this.apiService.Get<any>("/parking/ratesets").then(result => {
      this.RateSets = result;

    });
  }

  public addRateSetFilter(event: any) {
    var val = event.target.value;
    this.Filters = this.Filters.filter(x => x.Property != "ParkingRateSetId");

    if (val != "") {
      this.Filters.push({
        Property: "ParkingRateSetId",
        Comparator: "==",
        Value: val,
        FilterName: this.FilterName ,
      });

      this.SelectedRateSet = val;
    }
  }

  public RateSetSelected(rateSet: any) {

    if (rateSet.Id == this.SelectedRateSet) {
      return true;
    }

    return false;
  }

}
