<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Are you sure you want to leave this page?</p>
      </header>
      <section class="modal-card-body" style="min-width: 800px;">
        <div>
            You have unsaved changes that will be lost if you leave this page
        </div>
      </section>
      <footer class="modal-card-foot is-justify-content-end">
        <button type="button" class="button is-danger" (click)="AbandonChanges()">
            Discard Changes
        </button>
        <button type="button" class="button is-primary is-outlined" (click)="Cancel()">
            Stay on this Page
        </button>
      </footer>
    </div>
  </div>