import { BusyableModalComponent } from "src/app/shared/editors/busyableModalComponent";
import { AggregateType, ChartType, DataSetRequest } from "../../charts/dashboardchart/ChartConfiguration";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { ReportingService } from "src/app/Services/reporting.service.spec";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { ModalBaseComponent } from "src/app/shared/modals/modal-base/modal-base.component";
import { ToastrService } from "ngx-toastr";

interface PropertyGroup {
  groupName: string;
  properties: any[];
}

@Component({
  selector: 'app-chart-edit-modal',
  templateUrl: './chart-edit-modal.component.html',
  styleUrls: ['./chart-edit-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule]
})

export class ChartEditModalComponent extends ModalBaseComponent<null, any> implements OnInit {
  @Input() DataSetRequest!: DataSetRequest;
  @Input() TotalDatasets!: number;
  groupedProperties: PropertyGroup[] = [];
  disableAggregation: boolean = false;

  constructor(public reportingService: ReportingService, private toastr: ToastrService) {
    super();
  }

  ngOnInit() {
    if (this.DataSetRequest?.TargetType) {
      const properties = this.reportingService.getReportableEntity(this.DataSetRequest.TargetType)?.Properties || [];
      
      const groupsMap = properties.reduce((acc, prop) => {
        const group = acc.get(prop.GroupName) || [];
        group.push(prop);
        acc.set(prop.GroupName, group);
        return acc;
      }, new Map<string, any[]>());

      this.groupedProperties = Array.from(groupsMap.entries())
        .map(([groupName, properties]) => ({
          groupName,
          properties: properties.sort((a, b) => a.Label.localeCompare(b.Label))
        }))
        .sort((a, b) => b.groupName.localeCompare(a.groupName));
    }
  }

  onMetricChange(){
    this.selectedMetric();
  }

  addFilter() {
    if (!this.DataSetRequest.Filters) {
      this.DataSetRequest.Filters = [];
    }
    this.DataSetRequest.Filters.push({ Property: '', Operator: '', Value: '' });
  }

  getSelectedProperty(property: string) {
    return this.groupedProperties.flatMap(g => g.properties).find(p => p.ColumnName === property);
  }

  removeFilter(index: number) {
    this.DataSetRequest.Filters?.splice(index, 1);
  }

  get selectedMetric() {
    const metric = this.groupedProperties
      .flatMap(g => g.properties)
      .find(p => p.ColumnName === this.DataSetRequest.Metric);
    
    // If metric exists, check for single available aggregation type
    if (metric) {
      const availableTypes = [
        { type: 'Count', enabled: metric.CanCount },
        { type: 'Sum', enabled: metric.CanSum },
        { type: 'Min', enabled: metric.CanMin },
        { type: 'Max', enabled: metric.CanMax },
        { type: 'Average', enabled: metric.CanAverage }
      ];

      const enabledTypes = availableTypes.filter(t => t.enabled);
      // If exactly one type is enabled, set it as the AggregateType
      if (enabledTypes.length === 1) {
        this.DataSetRequest.AggregateType = enabledTypes[0].type as AggregateType;
        this.disableAggregation = true;
      }
      else if (enabledTypes.length === 0) {
        this.DataSetRequest.AggregateType = 'None' as AggregateType;
        this.disableAggregation = true;
      }
      else{
        this.disableAggregation = false;
      }
    }
    
    return metric;
  }

  get isOnlyDataset(): boolean {
    return this.TotalDatasets === 1;
  }

  setChartType(chartType: ChartType) {
    this.DataSetRequest.ChartType = chartType;
  }

  override confirm() {
    // Check if metric and aggregation are selected
    if (!this.DataSetRequest.Metric || !this.DataSetRequest.AggregateType) {
      this.toastr.error('Please select both a metric and aggregation type.');
      return;
    }

    // Check if all filters have required fields
    if (this.DataSetRequest.Filters?.some(filter => 
      !filter.Property || !filter.Operator || !filter.Value
    )) {
      this.toastr.error('Please complete all filter fields or remove incomplete filters.');
      return;
    }

    // Check if Label is empty, null, or undefined
    if (!this.DataSetRequest.Label?.trim()) {
      this.toastr.error('Please enter a label for the dataset.');
      return;
    }

    // Proceed with closing if validation passes
    this.result = this.DataSetRequest;
    this.Closing = true;
    setTimeout(() => {
      (document.querySelector('.modal-card') as HTMLElement).style.height = '0';
      (document.querySelector('.modal-background') as HTMLElement).style.opacity = '0';
    }, 1);
    setTimeout(() => {
      this.close();
    }, 300);
  }
} 