import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-editcontrolledareadisplay',
  templateUrl: './editcontrolledareadisplay.component.html',
  styleUrls: ['./editcontrolledareadisplay.component.scss']
})
export class EditcontrolledareadisplayComponent extends SimpleModalComponent<any, null> {
  Form !: UntypedFormGroup;
  constructor() {
    super();
  }

  confirm() {
    this.close();
  }
}
