<section class="modal-card-body"style="min-width: 800px;">
    <article class="panel" *ngIf="Session != null">
        <div class="columns">
            <div class="column is-12">
                <a class="panel-block">
                    <span class="label">Pricing</span>
                    <span class="value">{{Session.ParkingRateSetName}}</span>
                </a>
                <a class="panel-block">
                    <span class="label">Fee</span>
                    <span class="value">{{Session.QuotedAmount | currency:'USD':true:'1.2-2'}}</span>
                </a>
            </div>
        </div>
    </article>
    <div class="field is-horizontal">
        <div class="field-label is-normal">
            <label class="label">Manual Payment</label>
        </div>
        <div class="field-body">
            <div class="field">
            <p class="select is-fullwidth">
                  <select class="input" [(ngModel)]="SelectedOption">
                      <option *ngFor="let p of PaymentOptions" [ngValue]="p">{{p.Name}}</option>
                  </select>
            </p>
            </div>
        </div>  
    </div>
</section>
<footer class="modal-card-foot">
    <app-modal-save-button (OnSave)="confirm()"></app-modal-save-button>
</footer>