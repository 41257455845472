import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { ApiService } from 'src/app/Services/api.service';
import { ActionEventInputHandlerComponent } from 'src/app/shared/modals/actioneventinputhandler/actioneventinputhandler.component';


@Component({
  selector: 'valetactionbuttons',
  templateUrl: './valetactionbuttons.component.html',
  styleUrls: ['./valetactionbuttons.component.scss']
})
export class ValetActionButtonsComponent implements OnInit {

  @Input()
  public AllowedActions: any[] | null = null;

  @Input()
  public ModelId: string | null = null;

  public apiService !: ApiService;
  public modalService !: SimpleModalService;

  @Output()
  public LoadPage = new EventEmitter;

  constructor(apiService: ApiService, modalService: SimpleModalService) { 
    this.apiService = apiService;
    this.modalService = modalService;
  }

  public ngOnInit(): void {
    
  }

  public performAction(allowedAction: any){
    var KeyValues : any[] = [];
    if(allowedAction.Inputs != null && allowedAction.Inputs.length > 0){
      this.modalService.addModal(ActionEventInputHandlerComponent, {EventName: allowedAction.Name, Inputs: allowedAction.Inputs}).subscribe((result: any) => {
        if(!result.Success){
          return;
        }
        KeyValues = result.KeyValues;
        this.apiService.Put<any>("valet/sessions/" + this.ModelId + "/state", {ActionId: allowedAction.Id, Arguments: KeyValues}).then(r => {
          this.LoadPage.emit();
        })
      })
    }
    else{
      this.apiService.Put<any>("valet/sessions/" + this.ModelId + "/state", {ActionId: allowedAction.Id, Arguments: KeyValues}).then(r => {
        this.LoadPage.emit();
      })
    }
  }
}