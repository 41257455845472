<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-delete-button *ngIf="ModelIsNew==false" [Parent]="this" [Form]="Form" [Model]="Model" DeleteUrl="parking/plateaccess/"
        Api="parking" TypeName="PlateAccessList" DeleteNavLink="parking/accesscontrol/accesslists" [DeleteCheck]="true"></app-form-delete-button>
        <span class="mr-3">
            <add-button *ngIf="ModelIsNew==false" (click)="AddUser('new')" ButtonText="Add Plate"></add-button>
        </span>
        <app-form-save-button [Parent]="this" [Form]="Form" (OnSave)="Save()"></app-form-save-button>
    </app-pageheader>

    <app-field Label="Access List Name">
        <div class="control">
            <input formControlName="Name" class="input">
        </div>
    </app-field>
    <app-field Label="Description">
        <div class="control">
            <input formControlName="Description" class="input">
        </div>
    </app-field>
    <section class="control">
        <h1 class="title">Plates</h1>

        <app-datalistview *ngIf="!ModelIsNew && Model != null && Model.Id != null" [Columns]="Columns" [PageSize]="10"
            [ShowMap]="false" [ApiUrl]="'parking/plateaccess/' + Model?.Id + '/plates/searches'" [AllowCustomAction]="true" [CustomAction]="AddUser"
            [AllowDelete]="true" [DeleteAction]="DeletePlate">
            <app-filter-set>
                <div app-search-filter></div>
                <div app-search-button></div>
              </app-filter-set>
        </app-datalistview>
        <div class="box" *ngIf="ModelIsNew">
            The Access List must be saved before plates can be added
        </div>
    </section>

</div>