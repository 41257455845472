import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: '[app-search-button]',
  templateUrl: './search-button.component.html',
  styleUrls: ['./search-button.component.scss'],
  host: {'class': 'control'}
})
export class SearchButtonComponent implements OnInit {

  @Output()
  public applySearch: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }


  public async Search() {
    console.log("raise search from search button");

    this.applySearch.emit();
  }
}
