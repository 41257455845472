import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-editcustomgroupstructures',
  templateUrl: './editcustomgroupstructures.component.html',
  styleUrls: ['./editcustomgroupstructures.component.scss']
})
export class EditcustomgroupstructuresComponent extends SimpleModalComponent<any, any> implements OnInit {
  
  public Form !: UntypedFormGroup;
  public Structures !: any;
  public StructuresToAdd : any[] = [];
  public SelectedItems : any[] = [];

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "LocationString"),
    new Datalistviewcolumn("Type", "Type")
  ];

  ngOnInit(): void {
    this.Structures.forEach((x: any) => {
      this.SelectedItems.push(x.ParkingStructureId);
    })
  }

  public ItemAddOrRemove(evt : any){
    console.log(evt);
    if(evt.Selected){
      this.StructuresToAdd.push({"ParkingStructureId": evt.Id, "ParkingStructureName": evt.Name, "ParkingStructureLocationString": evt.LocationString})
    }
    // else{
    //   this.Structures = this.Structures.Filter((x: any) => x.ParkingStructureId != evt.Id);
    // }
  }

  confirm() {
    this.result = this.StructuresToAdd;
    this.close();
  }
}
