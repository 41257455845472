<app-loader [Parent]="this"></app-loader>
<div class="reports has-text-centered">
    <div class="" *ngFor="let category of Categories">
        <div class="is-size-4" style="color: var(--frog-green2); font-weight: 600;">{{category}}</div>
        <div class="card" *ngFor="let report of Reports[category]" (click)="OpenReport(report)">
            <div class="card-image">
                <figure class="image">
                    <img [src]="report.ImageUrl" alt="Report Image">
                </figure>
            </div>
            <div class="card-content" style="text-align: left; padding-top:0px;">
                <div class="media">
                    <div class="media-content">
                        <p class="is-5" style="color: var(--frog-green2)">{{report.Name}}</p>
                    </div>
                </div>

                <div class="content" style="font-size: small;">
                    {{report.Description}}
                </div>
            </div>
        </div>
    </div>
</div>