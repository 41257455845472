import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ClassedViewColumn, CurrencyViewColumn, Datalistviewcolumn, IconViewColumn, StateViewColumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IconService } from 'src/app/Services/icon.service';
import { DatalistviewComponent } from 'src/app/shared/datalistview/datalistview.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { ActionEventInputHandlerComponent } from 'src/app/shared/modals/actioneventinputhandler/actioneventinputhandler.component';

@Component({
  selector: 'app-valetaddon',
  templateUrl: './valetaddon.component.html',
  styleUrls: ['./valetaddon.component.scss']
})
export class ValetAddonComponent extends Busyable implements OnInit {
  public ModelId: any;
  public Filters: any;
  public Model: any;
  public orgService: OrganizationsService;
  public imageHeight: number = 0;
  public router: Router;
  public san !: DomSanitizer;
  public iconService !: IconService;
  public Columns !: Datalistviewcolumn[];
  @ViewChild("DataList")
  public DataList!: DatalistviewComponent;


  constructor(private apiService: ApiServiceBase, private parentRoute: ActivatedRoute, organizationService: OrganizationsService, router: Router, private sanitizer: DomSanitizer, iconService: IconService, public modalService: SimpleModalService) {
    super();
    this.orgService = organizationService;
    this.router = router;
    this.san = sanitizer;
    this.iconService = iconService;

    this.Columns = [
      new ClassedViewColumn("Ticket", "ValetSessionTicketNumber"),
      new Datalistviewcolumn("Addon Description", "Description"),
      new Datalistviewcolumn("Vehicle Details", "ValetSessionVehicleDescription"),
      new IconViewColumn(undefined, "ValetTypeIconName", "ValetSessionValetTypeIconColor"),
      new Datalistviewcolumn("Valet Type", "ValetSessionValetTypeName"),
      new ClassedViewColumn("Addon Status", "Status"),
    ];
  }

  ngOnInit(): void {
    this.Loading();
    this.parentRoute.params.subscribe(params => { this.ModelId = params['id']; });
    this.apiService.Get<any>("valet/addons/" + this.ModelId).then(result => {
      this.Model = result;
      this.StopLoading();
    });
  }

  public CustomAction(item: any){
    this.router.navigate(["/valet/sessions/" + item.ValetSessionId]);
  }

  public performAddonAction(event: any){
    var allowedAction = event.AllowedAction;
    var addon = event.Addon;
    var NameValues : any[] = [];
    if(allowedAction.Inputs != null && allowedAction.Inputs.length > 0){
      this.modalService.addModal(ActionEventInputHandlerComponent, {EventName: allowedAction.Name, Inputs: allowedAction.Inputs}).subscribe((result: any) => {
        if(!result.Success){
          return;
        }
        NameValues = result.NameValues;
        this.apiService.Put<any>("valet/sessions/" + this.ModelId + "/addons/" + addon.Id + "/state", {ActionId: allowedAction.Id, Arguments: NameValues}).then(r => {
          this.DataList.Refresh();
        });
      })
    }
    else{
      this.apiService.Put<any>("valet/sessions/" + this.ModelId + "/addons/" + addon.Id + "/state", {ActionId: allowedAction.Id, Arguments: NameValues}).then(r => {
        this.DataList.Refresh();
      });
    }
  }
}
