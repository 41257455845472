import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-unvalidated-input',
  templateUrl: './unvalidated-input.component.html',
  styleUrls: ['./unvalidated-input.component.scss']
})
export class UnvalidatedInputComponent implements OnInit {

  @Input()
  LabelText : string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
