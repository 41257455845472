import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { FeaturesService } from 'src/app/Services/features.service';
import { ModalService } from 'src/app/Services/modal.service';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { Deviceicons } from 'src/app/util/deviceicons';
import { UploadStatementModal } from './uploadstatementmodal/uploadstatementmodal.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { DatalistviewComponent } from 'src/app/shared/datalistview/datalistview.component';

@Component({
  selector: 'app-statements',
  templateUrl: './statements.component.html',
  styleUrls: ['./statements.component.scss']
})
export class StatementsComponent implements OnInit {

  constructor(private apiService : ApiService, private featuresService : FeaturesService, private modalService: SimpleModalService) { }

  @ViewChild(DatalistviewComponent)
  public ListView !: DatalistviewComponent;
  
  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Id", "Id"),
    new Datalistviewcolumn("Provider", "ProviderType"),
    new Datalistviewcolumn("Transactions", "TotalTransactions"),
    new Datalistviewcolumn("To Reconcile", "TransactionsToReconcile"),
    new Datalistviewcolumn("Ignored Issues", "IgnoredIssues"),
    new Datalistviewcolumn("Date Created", "DateCreatedLocalFriendlyString"),
  ];

  ngOnInit(): void {
  }

  uploadNewFile(event: any) {
   this.modalService.addModal(UploadStatementModal).subscribe((result) => {
    if(result) {
      this.ListView.Refresh();
    }
   });
  }
}
