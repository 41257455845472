<app-loader [Parent]="this"></app-loader>
<div *ngIf="Model != null">
  <app-pageheader [Parent]="this">
    <form-edit-button>Edit</form-edit-button>
    <app-form-delete-button [Model]="Model" [Parent]="this" DeleteUrl="/calendar/daytypes/" [DeleteCheck]="true" DeleteNavLink="/settings/calendar/daytypes" Api="calendar" TypeName="CalendarDayType">
    </app-form-delete-button>
  </app-pageheader>
  <div class="columns" *ngIf="Model != null">
    <div class="column is-6">
      <article class="panel" style="height:100%">
        <p class="panel-heading">
          {{Model.Name}}
        </p>
        <a class="panel-block">
          <span class="label">Id</span>
          <span class="value">{{Model.Id}}</span>
        </a>
        <a class="panel-block">
          <span class="label">Description</span>
          <span class="value">{{Model.Description}}</span>
        </a>
        <a class="panel-block">
          <span class="label">Color</span>
          <span class="value">
            <div style="width:7em; margin-right: 10px; vertical-align: middle; text-align:center; height: 1.6em; border-radius:5px; border: 1px solid black; float:left;"
              [ngStyle]="{'background-color': '#' + Model.Color}"></div>
          </span>
        </a>
      </article>
    </div>
</div>