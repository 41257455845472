import { LevelEditorComponent } from "../../leveleditor.component";
import { PolygonMapComponent } from "./PolygonMapComponent";


export class Space extends PolygonMapComponent{
    public override ClassName: string = "Space"
    public override StrokeColor: string = "#FFFFFF";
    public override StrokeWidth: number = 2;
    public override HatchSize: number = 5;
    public override ShowLabel: boolean = true;
    public override TitleMargin: number = 5;
    public override RequiresParent: boolean = true;
    public override ParentClassName: string = "Row";
    public override zIndex: number = 100;
    public ParkingSpaceTypes !: any[];


    constructor(leveleditorcomponent: LevelEditorComponent, canvas: any, formItem: any, parentFormItem: any, forceClone: boolean = false) {
        super(leveleditorcomponent, canvas, formItem, parentFormItem, forceClone);
        this.AddFabricItem();
    }

    public override SetFormValues(){
        this.DisplayAngle = this.formItem.get('DisplayAngle')?.value;
        this.EntrySegment = this.formItem.get('EntrySegment')?.value;
        this.ExitSegment = this.formItem.get('ExitSegment')?.value;
        if(this.formItem.get("ParkingSpaceTypeId")?.value != null){
            var ParkingSpaceType = this.leveleditorcomponent.ParkingSpaceTypes.filter((x: any) => x.Id == this.formItem.get("ParkingSpaceTypeId")?.value)[0];
            this.PatternFill = true;
            this.PatternFillColor = '#' + ParkingSpaceType.Color;
            this.Opacity = 0.9;
        }
        else{
            this.PatternFill = false;
        }
    }

    public override Clone(formitem: any){
        return new Space(this.leveleditorcomponent, this.canvas, formitem, this.parentFormItem, true);
    }
}