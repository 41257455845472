import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { EditValidationUserComponent } from '../validationprofileusers/edit/editvalidationuser.component';
import { Validators } from '@angular/forms';
import { mustContainValidator } from 'src/app/shared/forms/validators/mustcontainvalidator/mustcontainvalidator';

@Component({
  selector: 'app-editvalidationprofile',
  templateUrl: './editvalidationprofile.component.html',
  styleUrls: ['./editvalidationprofile.component.scss']
})
export class EditvalidationprofileComponent extends ModelEditor implements OnInit {

  Discounts: any = { PageNumber: 1, PageSize: 0, Results: null };
  ControlledAreas: any = { PageNumber: 1, PageSize: 0, Results: null};
  RateSets: any = {PageNumber: 1, PageSize: 0, Results: null};

  CodeMustContainValues: string[] = ["X", "x"];

  public Columns: Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("User", "UserFullName"),
    new Datalistviewcolumn("Last Code Generation On", "CodeLastGeneratedAtLocalString")
  ];

  constructor(private apiService: ApiServiceBase, private injector: Injector, organizationService: OrganizationsService, private modalService: SimpleModalService, toastService: ToastrService, private cdRef: ChangeDetectorRef) {
    super("parking/validation/profiles", injector, 'listid');
    apiService.Post("parking/ratesets/searches", {PageNumber: 1, PageSize: 99999}).then(result => {
      this.RateSets = result;
    });
    apiService.Post("infrastructure/controlledareas/searches", {PageNumber: 1, PageSize: 99999}).then(result => {
      this.ControlledAreas = result;
    });
    apiService.Post("parking/discounts/searches", {PageNumber: 1, PageSize: 99999}).then(result => {
      if (result != null){
        this.Discounts = result;
      }
    });
  }

  public override DefaultModel() {
    return { Name: "", ValidationAction: "", ParkingRateSetId: null, DiscountTypeId: null, CodeGenerationMode: "", CodeGenerationOverlapHours: 0,
      CodeTemplate: "XXX-XXX", Users: [], ControlledAreaId: null };
  }
  public override AfterModelLoaded(): void {
    
  }
  public override BeforeSave(): boolean | void {
    
  }
  public override Validators() {
    return {"CodeTemplate": [Validators.required, mustContainValidator(["X", "x"], false)]};
  }
}
