<select class="input minimal" [value]="SelectedSearchDate" (change)="addDateFilter($event)">
    <optgroup label="Recommended">
        <option value="today,tomorrow">Todays ({{DateSearchDates.Today.displayString}})</option>
        <option value="yesterday,today">Yesterday ({{DateSearchDates.Yesterday.displayString}})
        </option>
        <option value="6daysago,tomorrow">Last 7 Days
            ({{DateSearchDates.Last7Days.displayString}})</option>
        <option value="startofthisweek,endofthisweek">This Week
            ({{DateSearchDates.ThisWeek.displayString}})</option>
        <option value="startoflastweek,startofthisweek">Last Week
            ({{DateSearchDates.LastWeek.displayString}})</option>
        <option value="startofthismonth,endofthismonth">This Month
            ({{DateSearchDates.Month.displayString}})</option>
        <option value="startoflastmonth,startofthismonth">Last Month
            ({{DateSearchDates.LastMonth.displayString}})</option>
        <option value="custom">{{CustomVal}}</option>
    </optgroup>
</select>
