import { Component, Input, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { EnforcementService } from 'src/app/Services/enforcement.service';
import { ActionviolationmodalComponent } from './actionviolationmodal.component';
import { Busyable } from 'src/app/shared/editors/busyable';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-actionviolation',
  templateUrl: './actionviolation.component.html',
  styleUrls: ['./actionviolation.component.scss']
})
export class ActionviolationComponent extends Busyable implements OnInit {
  @Input() violation: any = null;
  
  public form: FormGroup;

  constructor(
    private modalService: SimpleModalService,
    private apiService: ApiService,
    private enforcementService: EnforcementService,
    private toastService: ToastrService,
    private _parent: ActionviolationmodalComponent,
    private fb: FormBuilder
  ) {
    super();
    this.form = this.fb.group({
      actionedByUserId: [null, Validators.required],
      plateNumber: ['', Validators.required],
      ticketReference: ['', Validators.required],
      notes: ['']
    });
  }

  ngOnInit(): void {}

  public confirm() {
    if (this.form.invalid) {
      this.toastService.error('Please fill in all required fields');
      return;
    }

    this.Busy();
    this.apiService.Post(`enforcement/violations/${this.violation.Id}/action`, this.form.value)
      .then(() => {
        this.toastService.success('Violation actioned successfully');
        this._parent.close();
      })
      .catch(error => {
        this.toastService.error('Failed to action violation');
        console.error('Error actioning violation:', error);
      })
      .finally(() => {
        this.StopBusy();
      });
  }
} 