import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-parkingpermit',
  templateUrl: './parkingpermit.component.html',
  styleUrls: ['./parkingpermit.component.scss']
})
export class ParkingPermitComponent extends Busyable implements OnInit {
  public ModelId: any;
  public Filters: any;
  public Model: any;
  public orgService: OrganizationsService;
  public imageHeight: number = 0;
  public router: Router;
  public san !: DomSanitizer;

  constructor(private apiService: ApiServiceBase, private parentRoute: ActivatedRoute, organizationService: OrganizationsService, router: Router, private sanitizer: DomSanitizer) {
    super();
    this.orgService = organizationService;
    this.router = router;
    this.san = sanitizer;
  }

  ngOnInit(): void {
    this.Loading();
    this.parentRoute.params.subscribe(params => { this.ModelId = params['id']; });
    this.apiService.Get<any>("parking/permits/" + this.ModelId).then(result => {
      this.Model = result;

      if(this.Model.ParkingPermitType.MultiUser && this.Model.ParkingPermitType.RequireConfirmations){
        this.Columns.push(new Datalistviewcolumn("Confirmed by", "ConfirmedByUsers"));
      }

      this.StopLoading();
    });
  }

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Used on", "UsedOnString"),
    new Datalistviewcolumn("Applied by", "AppliedByUserFullName")
  ];

  public CustomAction(item: any){
    this.router.navigate(["/parking/parking/sessions/" + item.ParkingSessionId]);
  }

  public CancelPermit(){
    this.Loading();
    this.apiService.Delete<any>("parking/permits/" + this.ModelId, {}).then(result => {
      this.Model = result;
      this.StopLoading();
    });
  }

  public ReinstatePermit(){
    this.Loading();
    this.apiService.Post<any>("parking/permits/" + this.ModelId + '/reinstate', {}).then(result => {
      this.Model = result;
      this.StopLoading();
    });
  }
}
