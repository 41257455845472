<app-pageheader [Parent]="this">
</app-pageheader>
 <div style="height:4em">
   <p class="is-size-3 center" style="font-weight: bold; color: green; font-family: FrogFont;">Users</p>
</div>
<div class="columns" style="height: 80%;">
   <div class="column is-10 is-offset-1">
      <div class="columns" style="min-height:50%; margin-top: 0;">
         <div class="column is-6" id="leftColumn" #leftColumn>
            <div class="card center" style="height:100%;">
               <div class="columns" style="margin-top: 0; margin-bottom: 0.05em;">
                  <app-loader [Parent]="this"></app-loader>
                  <div class="column is-12">
                     <div class="center" (click)="UserRedirect()" style="cursor:pointer">
                        <div class="card-stat p-1" >
                           <div class="green-bg p-1 tag is-large is-rounded">
                              <p class="numbers is-size-1">{{OrganizationUsersOverview.UserCount}}</p>
                           </div>
                        </div>
                        <div class="white-bg" style="height:100%; margin-left:2em;">
                              <p class="text is-size-5">Users</p>
                        </div>
                     </div>
                     <div class="center" (click)="AdminRedirect()" style="cursor:pointer">
                        <div class="card-stat p-1">
                           <div class="green-bg p-1 tag is-large is-rounded">
                              <p class="numbers is-size-1">{{OrganizationUsersOverview.AdminUsersCount}}</p>
                           </div>
                        </div>
                        <div class="white-bg" style="height:100%; margin-left:2em;">
                              <p class="text is-size-5">Administrators</p>
                        </div>
                     </div>
                     <div class="center" (click)="AdminRolesRedirect()" style="cursor:pointer">
                        <div class="card-stat p-1">
                           <div class="green-bg p-1 tag is-large is-rounded">
                              <p class="numbers is-size-1">{{OrganizationUsersOverview.AdminRolesCount}}</p>
                           </div>
                        </div>
                        <div class="white-bg" style="height:100%; margin-left:2em;">
                              <p class="text is-size-5">Admin Roles</p>
                        </div>
                     </div>
                     <div class="center" (click)="EndUserRolesRedirect()" style="cursor:pointer">
                        <div class="card-stat p-1">
                           <div class="green-bg p-1 tag is-large is-rounded">
                              <p class="numbers is-size-1">{{OrganizationUsersOverview.EndUserRolesCount}}</p>
                           </div>
                        </div>
                        <div class="white-bg" style="height:100%; margin-left:2em;">
                              <p class="text is-size-5">End User Roles</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="column is-6" *ngIf="rightColumnheight != 0" [ngStyle]="{'height': (rightColumnheight != null ? rightColumnheight : 0) + 'px'}">
            <div class="card" style="height:100%">
               <app-loader [Parent]="this"></app-loader>
               <div style="width:100%;">
                  <p class="pt-4 is-size-4" style="font-weight: 500; text-align: center;">Alerts</p>
               </div>
               <div class="p-4" style="height: 90%;">
                  <div *ngIf="showAlerts" class="p-4 center" style="width: 100%; height: 80%;">
                     <div *ngIf="alerts.length > 0">
                        <div class="alertitem" *ngFor="let alert of alerts"><i class="fa fa-{{alert.icon}} alerticon" [ngStyle]="{'color': alert.iconcolor}"></i><p class="alerttext">{{alert.text}}</p></div>
                     </div>
                     <div *ngIf="alerts.length == 0">
                        <div class="alertitem"><i class="fa fa-check alerticon" style="color:green"></i><p class="alerttext" style="color:green; font-weight: 500;">You have no alerts</p></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="columns" style="height:50%;">
         <div class="column is-12">
            <div class="card">
               <app-usersovertimechart Title="Organization Users over time (1 Year)" ChartType="line" Period="year" [Height]="50">
               </app-usersovertimechart>
            </div>
         </div>
      </div>
   </div>
</div>