import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-parkinglevelselector',
  templateUrl: './parkinglevelselector.component.html',
  styleUrls: ['./parkinglevelselector.component.scss']
})
export class ParkinglevelselectorComponent extends SimpleModalComponent<any, null> implements OnInit {
  public ParkingLots: any[] | null = null;
  public SelectedLevel: any | null = null;

  constructor(private apiService: ApiServiceBase) {
    super();


  }

  public ngOnInit(): void {
    this.apiService.Get<any>("infrastructure/parkinglots").then((result) => {
      this.ParkingLots = result;
    });
  }

  confirm() {
    this.result = this.SelectedLevel?.Id;
    this.close();
  }
}