<div class="modal is-active">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Add Manual Payment</p>
    </header>
    
  
<section class="modal-card-body" style="min-width: 800px;">
  <app-loader *ngIf="loading"></app-loader>
  
  <form [formGroup]="form">
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Vehicle Plate</label>
      </div>
      <div class="field-body">
        <div class="field">
          <input class="input" type="text" formControlName="EntryLanePlate1Number" placeholder="Enter plate number">
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Controlled Area</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select formControlName="ControlledAreaId">
              <option value="">Select area</option>
              <option *ngFor="let area of controlledAreas" [value]="area.Id">{{area.Name}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Parking Lot</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select formControlName="ParkingLotId">
              <option value="">Select lot</option>
              <option *ngFor="let lot of parkingLots" [value]="lot.Id">{{lot.Name}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Rate Set</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select formControlName="ParkingRateSetId">
              <option value="">Select rate set</option>
              <option *ngFor="let rate of rateSets" [value]="rate.Id">{{rate.Name}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Entry Date</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="datetime-local" formControlName="ControlledAreaEntryDate">
          </div>
        </div>
      </div>
    </div>

    
  </form>
</section>

<footer class="modal-card-foot">
  <app-modal-save-button (OnSave)="confirm()"></app-modal-save-button>
</footer>
</div>
</div>