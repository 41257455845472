<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Form]="Form" [Parent]="this" (click)="Save()"></app-form-save-button>
    </app-pageheader>

    <div class="columns">
        <div class="column is-6">
            <article class="panel">
                <p class="panel-heading">
                    Details
                </p>
                <a class="panel-block">
                    <span class="label">Name</span>
                    <span class="value"><input formControlName="Name" class="input" type="text">
                        <app-validator [For]="this.Form.get('Name')"></app-validator>
                    </span>
                </a>
                <a class="panel-block">
                    <span class="label">Structures</span>
                    <span class="value" >
                        <div *ngFor="let l of this.FormArray(this.Form, 'Structures').controls; let i = index ">
                            <div class="tags has-addons" style="padding-top:2px;">
                                <p class="tag" style="width:90%; justify-content: left; font-size: large;"  disabled>{{l.get('ParkingStructureLocationString')?.value}}</p>
                                <p class="tag" style="height:36px;" (click)="removeStructure(i)" ><i class="fa fa-trash"></i></p>
                            </div>
                        </div>
                        <br>
                        <div>
                            <button class="button is-primary" (click)="AddStructure()">Add Structure</button>
                        </div>
                    </span>
                </a>
            </article>
        </div>
    </div>
</div>