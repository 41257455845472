<app-pageheader [Parent]="this">
</app-pageheader>

<div class="columns">
    <div class="column is-4">

    </div>
    <div class="column is-4">
        <div style="text-align:center">
            <span style="display:inline; font-size: xxx-large; font-weight: bolder;">{{ calendarCreator.getMonthName(monthNumber) }}&nbsp; {{ year }}</span>
        </div>
    </div>
    <div class="column is-4">
        <span style="display:inline;">
            <button class="button pagination-button ml-6" style="margin-top: 20px;" (click)="setCurrentMonth()">Today</button>
            <button class="button pagination-button ml-3" style="margin-top: 20px;" (click)="onPreviousMonth()"><i class="fa fa-chevron-left"></i></button>
            <button class="button pagination-button ml-3" style="margin-top: 20px;"(click)="onNextMonth()"><i class="fa fa-chevron-right"></i></button>
        </span>
    </div>
</div>
    <br>
    
    <div class="calendar">
        <ul class="weekdays">
          <li *ngFor='let weekDay of weekDaysName' >
            <abbr [title]="weekDay">{{weekDay}}</abbr>
          </li>
        </ul>
      
        <ol class="day-grid">
            <li *ngFor='let day of monthDays' class="day" [ngStyle]="{'border-radius':'10px', 'background-color': day.number != undefined ? '#eeeeee' : '#fbfbfb'}">
                <div class="daynumber">{{day.number}}</div>
                <div class="tags daytags" *ngIf="day.number != undefined">
                    <div class="tag daytag" [class]="getTextClass(x.CalendarDayTypeColor)" *ngFor="let x of day.DayTypes" [ngStyle]="{'background-color': '#' + x.CalendarDayTypeColor }">
                        {{x.CalendarDayTypeName}} <div class="daytrash" (click)="deleteDayTypeDay(day, x.Id, x.CalendarDayTypeName)"><i class="fa fa-trash"></i></div>
                    </div>
                    <div *ngIf="day.ShowAddType" style="width:100%">
                            <select class="input" style="font-size: x-small;" (change)="updateCalendarDayType($event, day)">
                                <option value="" disabled selected>Select day type ...</option>
                                <option *ngFor="let x of dayTypes" [value]="x.Id">{{x.Name}}</option>
                            </select>
                    </div>
                </div>
                <div class="tags newtags" *ngIf="day.number != undefined">
                    <div class="tag button calendarbutton" (click)="addCalendarDayType(day)">
                        +
                    </div>
                </div>
            </li>
        </ol>
    </div>