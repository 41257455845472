<app-pageheader [Parent]="this">
</app-pageheader>

<div class="columns">
    <div class="column is-12">
        <app-dashboardbox>
            <app-parkingsessionrevenuetodayline></app-parkingsessionrevenuetodayline>
        </app-dashboardbox>
    </div>
</div>

<div class="columns">
    <div class="column is-12">
        <app-dashboardbox>
            <app-parkingsessionrevenuethisweekbar></app-parkingsessionrevenuethisweekbar>
        </app-dashboardbox>
    </div>
</div>

<div class="columns">
    <div class="column is-12">
        <app-dashboardbox>
            <app-parkingsessionrevenuesixmonthbar></app-parkingsessionrevenuesixmonthbar>
        </app-dashboardbox>
    </div>
</div>

<div class="columns">
    <div class="column is-12">
        <app-dashboardbox>
            <table class="table is-striped">
                <thead>
                    <tr><th>Area</th><th>Revenue This Week</th><th>Transactions This Week</th></tr>
                </thead>
                <tbody>
                    <tr *ngFor="let structure of TableData">
                        <td>{{structure.StructureName}}</td>
                        <td>${{structure.TotalAmount.toFixed(2)}}</td>
                        <td>{{structure.TotalTransactions}}</td>
                    </tr>
                </tbody>
            </table>
        </app-dashboardbox>
    </div>
</div>