<app-pageheader [Parent]="this">
</app-pageheader>

<app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="enforcement/violations/searches"
  [AllowEdit]="true" EditUrl="enforcement/violations/:id">
  <app-filter-set>
    <div app-search-filter></div>
    <div app-date-filter></div>
    <div app-subset-filter [Subsets]="enforcementService.getViolationStatuses()"></div>
    <div app-search-button></div>
  </app-filter-set>
</app-datalistview>