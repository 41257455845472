import { Component, EventEmitter, Input, OnInit, Output, QueryList, Self, ViewChild, ViewChildren } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NgControl } from '@angular/forms';
import { GoogleMap, MapMarker, MapPolygon } from '@angular/google-maps';
import { Deviceicons } from 'src/app/util/deviceicons';
import { Geo } from 'src/app/util/geo';

@Component({
  selector: 'app-map-input',
  templateUrl: './map-input.component.html',
  styleUrls: ['./map-input.component.scss']
})
export class MapInputComponent implements ControlValueAccessor {

  @ViewChild(GoogleMap) 
  public Map!:GoogleMap; 
  @Input()
  public MapWidth : any = 400;
  @Input()
  public MapHeight : any = 300;
  @Input()
  public Zoom : number = 16;
  @Input()
  public Mode : string = "polygon";
  @Output()
  public BackgroundMarkerClicked = new EventEmitter<UntypedFormControl>();

  public NgControl : any | null = null;
  public Marker : google.maps.LatLngLiteral | null = null;
  public MarkerIcon : any;
  public Polygon : google.maps.LatLngLiteral[] | null = null;
  public BackgroundPolygons : any[] = [];
  public BackgroundMarkers : any[] | null = null;
  @Input() public Editable: boolean = true;
  
  public MapCenter !: google.maps.LatLngLiteral;
  public MapOptions: google.maps.MapOptions = {
    center: {lat: 40, lng: -20},
    zoom: 4,
  };
  @ViewChildren(MapPolygon) 
  public MapPolygons : QueryList<MapPolygon> | null = null; 
  @ViewChildren(MapMarker) 
  public MapMarkers : QueryList<MapMarker> | null = null; 


  constructor(@Self() public ngControl: NgControl) { 
    if (ngControl) {
      ngControl.valueAccessor = this;
      this.NgControl = ngControl;
    }
  }

  public writeValue(val : number[][] | number[] | null): void {
    if(val != null){
      console.log("map-input value updated to " + val);
      if((val.length == 0 && this.Mode == 'marker') || !Array.isArray(val[0])){
        //the element is 1-dimensional so we'll be editing a marker
        let point = val as number[];
        if(val.length > 0){
          if(this.ngControl.control != null){
            let img = Deviceicons.GenerateIconName(this.NgControl.control.parent, true, true);
            this.MarkerIcon = { url: img, scaledSize: new google.maps.Size(50,50) }
          }
          this.Marker = { lng: point[0], lat: point[1] };
        }
        this.Polygon = null;
      
        this.MapOptions.center = this.Marker;
        if(this.Map != null && this.Marker != null) 
          this.Map.panTo(this.Marker);
      }
      else if(val != null && val.length > 0){
        this.Polygon = [];
        this.Marker = null;
        let poly  = val as number[][];
        //sometimes there are duplicate points so clean them up
        while(val.length > 2 && poly[val.length-1][0] == poly[poly.length-2][0] && poly[poly.length-1][1] == poly[poly.length-2][1]){
          val.splice(val.length-1, 1);
        }

        for(let i =0; i < val.length-1; i++){
          this.Polygon.push( { lat : poly[i][1], lng: poly[i][0]});
        }
        let center = Geo.PolygonCenter(poly);
        this.MapOptions.center = center;
        if(this.Map != null) 
          this.Map.panTo(center);
      }
      else{
        //null input so both polygon & marker are null
        this.Polygon = null;
        this.Marker = null;
      }
    }
    else{
      this.Polygon = null;
    }
  }
  public onChange = (polygonPoints : number[][] | number[] | null) => {};
  public registerOnChange(fn: any): void {
      this.onChange = fn;
  }
  public onCollectionChange = (polygonPoints : number[][] | number[] | null) => {};
  public registerOnCollectionChange(fn: any): void {
      this.onCollectionChange = fn;
  }
  public onTouched = () => {};
  public registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }
  
  @Input()
  public set BackgroundPolygonControls(val : UntypedFormGroup[] | null){
    this.BackgroundPolygons = [];
    if(val == null) return;

    for(let item of val){

      let control = item.get('PolygonPoints');
      if(control == null)
        continue;

      let poly : google.maps.LatLngLiteral[] = [];
      for(let point of control.value){
          poly.push( { lat : point[1], lng: point[0]});
      }
      this.BackgroundPolygons.push({ paths: poly});
    }
  }
  @Input()
  public set BackgroundPolygonPoints(val : number[][][] | null){
    this.BackgroundPolygons = [];
    if(val == null) return;

    for(let item of val){
      let poly : google.maps.LatLngLiteral[] = [];
      for(let point of item){
          poly.push( { lat : point[1], lng: point[0]});
      }
      this.BackgroundPolygons.push({ paths: poly});
    }
  }
  @Input()
  public set BackgroundMarkerControls(val : any[] | null){
    this.BackgroundMarkers = [];
    if(val == null) return;

    for(let item of val){

      let nameControl = item.get('Name');
      let name = "";
      if(nameControl != null)
        name = nameControl.value;
      let id = item.get('Id')?.value;
      let control = item.get('LocationPoints');
      if(control != null){
        let marker : google.maps.LatLngLiteral = { lat : control.value[1], lng: control.value[0]};
        let img = Deviceicons.GenerateIconName(item, true, false);
        this.BackgroundMarkers.push({item: item, position : marker, label: name, icon: { url: img, scaledSize: new google.maps.Size(50,50) }});
      }
      else{
        //no LocationPoints, look for a polygon to mark the center of it
        control = item.get('PolygonPoints');
        if(control != null){
          let img = Deviceicons.GenerateIconName(item, true, false);
          this.BackgroundMarkers.push({item: item, position: Geo.PolygonCenter(control.value), label: name, icon: { url: img, scaledSize: new google.maps.Size(50,50) } });
        }
      }
    }
  }

  
  public EditPolygonMouseUp(evt : any){
    let paths : any[]= [];
    if(this.Polygon != null && this.MapPolygons != null){
      let p = this.MapPolygons.last.getPaths().getArray()[0].getArray();
      for(let i=0; i < p.length; i++){
        let point = p[i];
        paths.push( [ point.lng(), point.lat() ]);
      }
      paths.push(paths[0]); //server wants polygon point back to origin, in this map we trimmed that off for googles api
    }
    this.onChange(paths);
  }
  public ResetPolygon(){
    if(this.Polygon == null || this.Polygon.length == 0) {
      this.RecenterPolygon();
      return;
    };
    let topleft = this.Polygon[0];
    let size = 0.002
    this.Polygon = [{lng: topleft.lng, lat: topleft.lat}, { lng: topleft.lng+size, lat: topleft.lat}, {lng: topleft.lng+size, lat: topleft.lat+size}, {lng: topleft.lng, lat: topleft.lat+size}] ;

    let paths : any[]= [];
    for(let point of this.Polygon){
      paths.push( [ point.lng, point.lat ]);
    }
    paths.push(paths[0]); //server wants polygon point back to origin, in this map we trimmed that off for googles api
    this.onChange(paths);
  }
  public RecenterPolygon(){
    let newLoc = this.Map.getCenter();
    if(newLoc != null){
      let newPoly = Geo.NewPolygonAtCenter(newLoc, 0.005)
      this.Polygon = [];
      for(let i =0; i < newPoly.length; i++){
        this.Polygon.push( { lat : newPoly[i][1], lng: newPoly[i][0]});
      }
      this.onChange(newPoly);
    }
  }
  public RecenterMarker(){
    let newLoc = this.Map.getCenter();
    if(newLoc != null){
      this.Marker = { lng : newLoc.lng(), lat : newLoc.lat() };
      this.onChange([ newLoc.lng(), newLoc.lat() ]);
    }
  }
  public EditMarkerDragged(evt : any){
    if(this.Marker != null && this.MapMarkers != null){
      let p = this.MapMarkers.last.getPosition();
      let loc = null;
      if(p?.lng() && p.lat()){
        loc = [ p?.lng(), p?.lat() ];
        console.log("updated map location to " + loc[1] + ", " + loc[0]);
        this.Marker.lat = loc[1];
        this.Marker.lng = loc[0];
        this.onChange(loc)
      }
    }
  }
  public BackgroundMarkerClick(p : any){
    this.BackgroundMarkerClicked.emit(p.item as UntypedFormControl);
  }
}
