import { Observable } from 'rxjs';
import { LoginServiceBase as LoginServiceBase } from '../auth/login.service.base';


export abstract class ApiServiceBase {
  public loginService!: LoginServiceBase;
  public abstract Get<T>(url: string, ignoreCache?: boolean): Promise<T>;
  public abstract Post<T>(url: string, body: any): Promise<T>;
  public abstract Put<T>(url: string, body: any): Promise<T>;
  public abstract Delete<T>(url: string, body: any): Promise<T>;

}
