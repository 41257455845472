<app-pageheader [Parent]="this">
</app-pageheader>

<app-datalistview [Columns]="Columns" [ShowMap]="true" ApiUrl="infrastructure/localcontrollers/searches"
  [AllowEdit]="true"
  EditUrl="/parking/accesscontrol/terminals/:id" [StructureViewerLayers]="StructureViewerLayers" StructureViewerForType="Terminal">
  <app-filter-set>
    <div app-search-filter></div>
    <div app-search-button></div>
  </app-filter-set>
</app-datalistview>