import { Component } from '@angular/core';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { StructureViewerLayer } from 'src/app/shared/structureviewermaps/structureviewerlayers';

@Component({
  selector: 'app-signs',
  templateUrl: './signs.component.html',
  styleUrls: ['./signs.component.scss']
})
export class SignsComponent {
  constructor() { }

  ngOnInit(): void {
  }
  public Subsets: string[] = [];

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Id", "Id"),
    new Datalistviewcolumn("HID", "ChipId"),
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Location", "LocationString"),
    new Datalistviewcolumn("Sign Mode", "Mode"),
  ];

  public StructureViewerLayers: StructureViewerLayer[] = [new StructureViewerLayer("ParkingLot", null, false, true), new StructureViewerLayer("ParkingLevel"), new StructureViewerLayer("SignConfiguration", "Signs") ]
}
