import * as THREE from "three";
import { Object3D } from "three";
import { SelectionIndicator } from "./selection-indicator";

export class LevelitemMesh extends THREE.Mesh{
    constructor(levelItem: any){
        super();
        this.Item = levelItem;
    }
    public Item: any;

    public Object3D : Object3D | null = null;

    public ProblemIndicator : any;
    
    public ProblemWithItem(scene : any){
        if(this.ProblemIndicator != null){
            scene.remove(this.ProblemIndicator);
        }
    
      this.ProblemIndicator = new SelectionIndicator(this);
      scene.add(this.ProblemIndicator);
    }

    public RemoveItemProblem(scene : any){
        scene.remove(this.ProblemIndicator);
    }
}
