import { Component, Input, AfterViewInit, OnInit, ElementRef, ViewChild, OnChanges, SimpleChanges } from '@angular/core';


@Component({
  selector: 'app-workflowviewer',
  templateUrl: './workflowviewer.component.html',
  styleUrls: ['./workflowviewer.component.scss']
})
export class WorkflowviewerComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() workflow: any;
  @ViewChild('workflowSvg')
  svgElement!: ElementRef;
  
  private readonly visitedBorderColor = "#165B2E";
  private readonly visitedColor = "#23843C";
  private readonly inactiveColor = "#86888a";
  private readonly inactiveFillColor = "#F0EFEF";

  private readonly columnWidth = 150;
  private readonly stateWidth = 100;
  private readonly stateHeight = 70;
  private readonly statePadding = 25;
  private readonly columnSpacing = this.columnWidth;
  private readonly labelOffset = 10;
  private readonly curveControlPointOffset = 2;
  private readonly cornerRadius = 5;

  private homeStateName: string = "Home";
  private columns: any[][] = [];
  
  @Input() set useCasePath(value: any) {
    this._useCasePath = value;
    if (this.svgElement) {
      this.clearAndRender();
    }
  }
  get useCasePath() {
    return this._useCasePath;
  }
  private _useCasePath: any;

  @Input() changeCounter: any;

  ngOnInit() {
    this.preProcessWorkflow();
  }

  ngAfterViewInit() {
    const svg = this.svgElement.nativeElement as SVGSVGElement;
    const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');

    defs.appendChild(this.createMarker(this.inactiveColor, 'arrowhead'));
    defs.appendChild(this.createMarker(this.visitedBorderColor, 'arrowhead-visited'));
    svg.appendChild(defs);

    this.render();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['workflow']) {
      this.preProcessWorkflow();
      if (this.svgElement) {
        this.clearAndRender();
      }
    }
    if ((changes['useCasePath'] || changes['changeCounter']) && this.svgElement) {
      this.clearAndRender();
    }
  }

  private createMarker(color: string, id: string) {
    const marker = document.createElementNS('http://www.w3.org/2000/svg', 'marker');
    
    marker.setAttribute('id', id);
    marker.setAttribute('markerWidth', '10');
    marker.setAttribute('markerHeight', '7');
    marker.setAttribute('refX', '9');
    marker.setAttribute('refY', '3.5');
    marker.setAttribute('orient', 'auto');

    const polygon = document.createElementNS('http://www.w3.org/2000/svg', 'polygon');
    polygon.setAttribute('points', '0 0, 10 3.5, 0 7');
    polygon.setAttribute('fill', color);
    
    marker.appendChild(polygon);

    return marker;
  }

  private preProcessWorkflow() {
    const homeState = this.workflow.States.find((state: any) => state.Id === this.workflow.HomeStateId);
    if (homeState) {
      this.homeStateName = homeState.Name;
      this.columns = [];
      this.processState(homeState, [], 0);
    }
  }

  private processState(state: any, visitedStates: string[], columnIndex: number) {
    if (visitedStates.includes(state.Id)) {
      return; // Avoid processing the same state again
    }
    visitedStates.push(state.Id);

    if (!this.columns[columnIndex]) {
      this.columns[columnIndex] = [];
    }
    this.columns[columnIndex].push(state);

    state.Transitions.forEach((transition: any) => {
      const nextState = this.workflow.States.find((s: any) => s.Id === transition.ToStateId);
      if (transition.ToStateId === this.workflow.HomeStateId) {
        // Add a virtual home state in the next column
        const virtualHomeId = `virtualHome_${columnIndex + 1}`;
        if (!this.columns[columnIndex + 1]) {
          this.columns[columnIndex + 1] = [];
        }

        if(!this.columns[columnIndex + 1].some((x: any) => x.Id == virtualHomeId)) {
          const virtualHomeState = { Id: virtualHomeId, Name: this.homeStateName, Transitions: [] };
          this.columns[columnIndex + 1].push(virtualHomeState);
        }

        transition.ToStateId = virtualHomeId;
      } else if (nextState) {
        this.processState(nextState, visitedStates, columnIndex + 1);
      }
    });
  }

  private isStateVisited(stateId: string): boolean {
    if (!this.useCasePath || this.useCasePath.length === 0) return false;
    
    if (stateId.startsWith('virtualHome_')) {
      const targetColumn = parseInt(stateId.split('_')[1]);
      
      let currentPathLength = 0;
      for (let i = 0; i < this.useCasePath.length; i++) {
        const change = this.useCasePath[i];
        if (change.NewStateId === this.workflow.HomeStateId) {
          const fromState = this.workflow.States.find((s: any) => s.Id === change.PreviousStateId);
          if (fromState) {
            const fromStateColumn = this.columns.findIndex(col => 
              col.some((s: any) => s.Id === fromState.Id)
            );
            
            if (fromStateColumn === targetColumn - 1) {
              return true;
            }
          }
        }
        currentPathLength++;
      }
      return false;
    }
    
    return this.useCasePath.some((change: any) => 
      change.PreviousStateId === stateId || change.NewStateId === stateId
    );
  }

  private isTransitionVisited(fromStateId: string, toStateId: string): boolean {
    if (!this.useCasePath) return false;

    if (toStateId.startsWith('virtualHome_')) {
      const fromState = this.workflow.States.find((s: any) => s.Id === fromStateId);
      const transition = fromState?.Transitions.find((t: any) => t.ToStateId.startsWith('virtualHome_'));
      
      return this.useCasePath.some((change: any) => 
        change.PreviousStateId == fromStateId &&
        change.NewStateId == this.workflow.HomeStateId &&
        transition?.Name?.includes(change.TransitionName)
      );
    }

    return this.useCasePath.some((change: any) => 
      change.PreviousStateId === fromStateId && 
      change.NewStateId === toStateId
    );
  }

  private render() {
    const svg = this.svgElement.nativeElement as SVGSVGElement;
    const statePositions: { [key: string]: { x: number, y: number } } = {};

    // Calculate total width needed based on number of columns
    const totalWidth = 50 + (this.columns.length * this.columnWidth) + this.stateWidth;
    svg.setAttribute('width', totalWidth.toString());

    // Draw states in columns
    this.columns.forEach((column, columnIndex) => {
      const currentX = 50 + columnIndex * this.columnWidth;
      const totalHeight = column.length * this.stateHeight;
      const startY = 120 - totalHeight / 2;

      column.forEach((state: any, stateIndex: number) => {
        const currentY = startY + stateIndex * this.stateHeight;
        statePositions[state.Id] = { x: currentX, y: currentY };
        this.drawState(svg, state, currentX, currentY);
      });
    });

    // Draw transitions
    this.workflow.States.forEach((state: any) => {
      state.Transitions.forEach((transition: any) => {
        const toStatePosition = statePositions[transition.ToStateId];
        if (toStatePosition) {
          this.drawTransition(
            svg, 
            statePositions[state.Id], 
            toStatePosition,
            state,
            transition.ToStateId
          );
        }
      });
    });
  }

  private drawState(svg: SVGSVGElement, state: any, x: number, y: number) {
    const maxWidth = this.stateWidth - 20; // Padding of 10px on each side
    const lineHeight = 20;
    const words = state.Name.split(' ');
    const lines: string[] = [];
    let currentLine = words[0];

    // Split text into lines that fit within maxWidth
    for (let i = 1; i < words.length; i++) {
      const temp = document.createElementNS('http://www.w3.org/2000/svg', 'text');
      temp.textContent = currentLine + ' ' + words[i];
      svg.appendChild(temp);
      const width = temp.getBBox().width;
      svg.removeChild(temp);

      if (width < maxWidth) {
        currentLine += ' ' + words[i];
      } else {
        lines.push(currentLine);
        currentLine = words[i];
      }
    }
    lines.push(currentLine);

    // Calculate required height based on number of lines
    const requiredHeight = Math.max(50, (lines.length * lineHeight) + 20); // minimum 50px height

    const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    rect.setAttribute('x', x.toString());
    rect.setAttribute('y', y.toString());
    rect.setAttribute('width', this.stateWidth.toString());
    rect.setAttribute('height', requiredHeight.toString());
    rect.setAttribute('rx', this.cornerRadius.toString());
    rect.setAttribute('ry', this.cornerRadius.toString());
    
    const isVisited = this.isStateVisited(state.Id);
    rect.setAttribute('fill', isVisited ? this.visitedColor : this.inactiveFillColor);
    rect.setAttribute('stroke', isVisited ? this.visitedBorderColor : this.inactiveColor);
    rect.setAttribute('stroke-width', '2');
    svg.appendChild(rect);

    // Draw each line of text
    lines.forEach((line, index) => {
      const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
      text.setAttribute('x', (x + 10).toString());
      text.setAttribute('y', (y + 25 + (index * lineHeight)).toString());
      text.setAttribute('fill', isVisited ? '#FFFFFF' : '#000000');
      text.textContent = line;
      svg.appendChild(text);
    });
  }

  private drawTransition(svg: SVGSVGElement, from: { x: number, y: number }, to: { x: number, y: number }, fromState: any, toStateId: string) {
    const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    const hitbox = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    
    const transition = fromState.Transitions.find((t: any) => t.ToStateId === toStateId);
    if (!transition) return;

    const dx = to.x - from.x;
    const dy = to.y - from.y;
    
    let pathD: string;
    let labelX: number;
    let labelY: number;

    // If we're skipping over a column, draw an arc
    if (dx > this.columnSpacing) {
      const midX = from.x + this.columnSpacing / 3 + dx / 2;
      const controlY = (from.y + to.y) / 2 + this.statePadding - (this.stateHeight * this.curveControlPointOffset);
      
      pathD = `M ${from.x + this.stateWidth} ${from.y + this.statePadding} 
               Q ${midX} ${controlY} 
                 ${to.x} ${to.y + this.statePadding}`;

      labelX = from.x + this.stateWidth + dx / 2;
      labelY = Math.min(from.y, to.y) + Math.abs(from.y - to.y) / 2 - this.labelOffset;
    } else { // else a straight line from A to B 
      pathD = `M ${from.x + this.stateWidth} ${from.y + this.statePadding} L ${to.x} ${to.y + this.statePadding}`;
      labelX = from.x + this.stateWidth + (to.x - (from.x + this.stateWidth)) / 2;
      labelY = from.y + this.statePadding - this.labelOffset;
    }
    
    // set the path and hitbox
    path.setAttribute('d', pathD);
    hitbox.setAttribute('d', pathD);
    
    // pick the colours
    const isVisited = this.isTransitionVisited(fromState.Id, toStateId);
    path.setAttribute('stroke', isVisited ? this.visitedBorderColor : this.inactiveColor);
    path.setAttribute('stroke-width', '2'); 
    path.setAttribute('fill', 'none');
    path.setAttribute('marker-end', `url(#${isVisited ? 'arrowhead-visited' : 'arrowhead'})`);
    
    hitbox.setAttribute('stroke', 'transparent');
    hitbox.setAttribute('stroke-width', '20'); 
    hitbox.setAttribute('fill', 'none');
    hitbox.setAttribute('class', 'transition-hitbox');
    
    const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    text.setAttribute('x', labelX.toString());
    text.setAttribute('y', labelY.toString());
    text.setAttribute('text-anchor', 'middle');
    text.setAttribute('fill', isVisited ? this.visitedBorderColor : this.inactiveColor);
    text.setAttribute('font-size', '12');
    text.setAttribute('class', 'transition-label');
    text.setAttribute('opacity', '0');
    text.textContent = transition.Name || '';

    const background = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    background.setAttribute('class', 'transition-label-bg');
    background.setAttribute('opacity', '0');
    
    // hover event
    hitbox.addEventListener('mouseenter', () => {
      text.setAttribute('opacity', '1');
      background.setAttribute('opacity', '1');
      path.setAttribute('stroke-width', '3');
    });

    hitbox.addEventListener('mouseleave', () => {
      text.setAttribute('opacity', '0');
      background.setAttribute('opacity', '0');
      path.setAttribute('stroke-width', '2');
    });

    group.appendChild(hitbox);  
    group.appendChild(path);   
    group.appendChild(background);
    group.appendChild(text);
    
    svg.appendChild(group);

    const bbox = text.getBBox();
    const padding = 4;
    background.setAttribute('x', (bbox.x - padding).toString());
    background.setAttribute('y', (bbox.y - padding).toString());
    background.setAttribute('width', (bbox.width + 2 * padding).toString());
    background.setAttribute('height', (bbox.height + 2 * padding).toString());
    background.setAttribute('fill', 'white');
  }

  private clearAndRender() {
    const svg = this.svgElement.nativeElement as SVGSVGElement;
    // Clear existing content
    while (svg.firstChild) {
      svg.removeChild(svg.firstChild);
    }
    // Re-add defs with markers
    const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
    defs.appendChild(this.createMarker(this.inactiveColor, 'arrowhead'));
    defs.appendChild(this.createMarker(this.visitedBorderColor, 'arrowhead-visited'));
    svg.appendChild(defs);
    // Re-render the workflow
    this.render();
  }
}
