import { AfterContentInit, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { Datalistviewcolumn, IconViewColumn, CurrencyViewColumn, ClassedViewColumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { SimpleModalService } from 'ngx-simple-modal';
import { DatalistviewComponent } from 'src/app/shared/datalistview/datalistview.component';
import { AddValetSessionPaymentModalComponent } from './addpaymentmodal/addvaletsessionpaymentmodal.component';
import { RefundValetSessionModalComponent } from './recordrefundmodal/refundvaletsessionpaymentmodal.component';
import { ValetActionButtonsComponent } from 'src/app/shared/forms/buttons/valetactionbuttons/valetactionbuttons.component';
import { ActionLogComponent } from 'src/app/shared/modals/actionlog/actionlog.component';
import { ValetService } from 'src/app/Services/valet.service';
import { ValetAddonsModalComponent } from 'src/app/shared/modals/valetaddons/valetaddonsmodal.component';
import { ToastrService } from 'ngx-toastr';
import { ActionEventInputHandlerComponent } from 'src/app/shared/modals/actioneventinputhandler/actioneventinputhandler.component';

@Component({
  selector: 'app-valetsession',
  templateUrl: './valetsession.component.html',
  styleUrls: ['./valetsession.component.scss']
})
export class ValetSessionComponent extends Busyable implements OnInit, AfterViewInit {
  public ModelId: any;
  public Filters: any;
  public Model: any;
  public orgService: OrganizationsService;
  public imageHeight: number = 0;
  public router: Router;
  public san !: DomSanitizer;


  public isDragging: boolean = false;
  public previewFile?: SafeUrl;
  public selectedFile?: any;
  public fileInvalid: boolean = false;
  public isImageFile: boolean = true;
  public RequiredFileType: string = 'image/*';
  public OverrideUploadUrl ?: string | null;
  public ImageCategory?: string;

  @ViewChild("DataList")
  public DataList!: DatalistviewComponent;

  @ViewChild("ValetButtons")
  public ValetButtons!: ValetActionButtonsComponent;

  @ViewChild("PaymentsDataList")
  public PaymentsDataList!: DatalistviewComponent;

  @ViewChild("AddonsDataList")
  public AddonsDataList!: DatalistviewComponent;

  constructor(private apiService: ApiServiceBase, private parentRoute: ActivatedRoute, organizationService: OrganizationsService, router: Router, private sanitizer: DomSanitizer, private modalService: SimpleModalService, public valetService: ValetService, private toastr: ToastrService) {
    super();
    this.orgService = organizationService;
    this.router = router;
    this.san = sanitizer;

    this.PaymentColumns.push(new CurrencyViewColumn("Amount", "Amount", organizationService));
    this.PaymentColumns.push(new Datalistviewcolumn("Paid On", "PaidOnLocalFriendlyString"));
  }


  public Columns : Array<Datalistviewcolumn> = [
    new IconViewColumn(undefined, "circle-up", "2E8637", true),
    new Datalistviewcolumn("Parked", "ControlledAreaEntryDateLocalString"),
    new Datalistviewcolumn("Parked At", "ParkingLotName"),
    new Datalistviewcolumn("Requested On", "RequestedOnLocalFriendlyString"),
    new Datalistviewcolumn("Expected At", "ExpectedAtLocalFriendlyString"),
    new IconViewColumn(undefined, "circle-down", "E49B0F", true),
    new Datalistviewcolumn("Checked Out", "ControlledAreaExitDateLocalString")
  ];

  public PaymentColumns : Array<Datalistviewcolumn> = [
  ];

  
  public AddonColumns : Array<Datalistviewcolumn> = [
    new IconViewColumn(undefined, "ValetAddonIconName", "ValetAddonColor"),
    new Datalistviewcolumn("Addon", "Description"),
    new ClassedViewColumn("Status", "Status")
  ];

  public AddAddon(){
    this.modalService.addModal(ValetAddonsModalComponent, null).subscribe(result => {
      this.apiService.Put<any>("valet/sessions/" + this.ModelId + "/addons", { Addons: result.ItemsToAdd }).then(r => {
        this.ngOnInit();
        this.AddonsDataList.Search();
      })
    })
  }

  RemoveImage(image: any){
    this.apiService.Delete<any>("valet/sessions/" + this.ModelId + '/images/' + image.Id, null).then(result => {
      this.ngOnInit();
    });
  }

  ngOnInit(): void {
    this.Loading();
    this.parentRoute.params.subscribe(params => { this.ModelId = params['id']; });
    this.apiService.Get<any>("valet/sessions/" + this.ModelId).then(result => {
      this.Model = result;
      this.StopLoading();
    });
  }

  public performAddonAction(event: any){
    var allowedAction = event.AllowedAction;
    var addon = event.Addon;
    var KeyValues : any[] = [];
    if(allowedAction.Inputs != null && allowedAction.Inputs.length > 0){
      this.modalService.addModal(ActionEventInputHandlerComponent, {EventName: allowedAction.Name, Inputs: allowedAction.Inputs}).subscribe((result: any) => {
        if(!result.Success){
          return;
        }
        KeyValues = result.KeyValues;
        this.apiService.Put<any>("valet/sessions/" + this.ModelId + "/addons/" + addon.Id + "/state", {ActionId: allowedAction.Id, Arguments: KeyValues}).then(r => {
          this.AddonsDataList.Refresh();
        });
      })
    }
    else{
      this.apiService.Put<any>("valet/sessions/" + this.ModelId + "/addons/" + addon.Id + "/state", {ActionId: allowedAction.Id, Arguments: KeyValues}).then(r => {
        this.AddonsDataList.Refresh();
      });
    }
  }

  public GetDescription(addonId: number, optionId : number | null = null){
    return this.valetService.valetSessionDescription(addonId, optionId);
   }

  public ShowHistory(){
    this.modalService.addModal(ActionLogComponent, {Title: "Valet Session History", EntityType: "ValetSession", EntityId : this.Model.Id});
  }

  ngAfterViewInit(): void {
    this.valetButtonSubscription();
  }

  private valetButtonSubscription(): void {
    if (this.ValetButtons) {
      this.ValetButtons.LoadPage.subscribe(result => {
        this.ngOnInit();
      });
    } else {
        // For some reason, valetbuttons component refused to load straight away in AfterViewInit()
        setTimeout(() => {
            this.valetButtonSubscription();
        }, 1000); // Retry after 1 second
    }
}
  
  public MakeManualPayment(){
    this.modalService.addModal(AddValetSessionPaymentModalComponent, {Session: this.Model})
      .subscribe((result) => {
        this.Loading();
        this.parentRoute.params.subscribe(params => { this.ModelId = params['id']; });
        this.apiService.Get<any>("valet/sessions/" + this.ModelId).then(result => {
          this.Model = result;
          this.StopLoading();
        });
        this.PaymentsDataList.Refresh();
      });
  }

  // public UploadImageToSession(base64: string, fileTypeExtension: string){
  //   this.apiService.Post<any>("valet/sessions/" + this.ModelId + '/images', { Base64URL: base64, Extention: fileTypeExtension}).then(result => {
  //     this.ngOnInit();
  //   });
  // }

  public RecordRefund(item: any) {
    this.modalService.addModal(RefundValetSessionModalComponent, {Payment: item}).subscribe((result) => {
      this.Loading();
      this.parentRoute.params.subscribe(params => { this.ModelId = params['id']; });
      this.apiService.Get<any>("valet/sessions/" + this.ModelId).then(result => {
        this.Model = result;
        this.StopLoading();
      });
      this.PaymentsDataList.Refresh();
    })
  }

  public CopyToClipboard(val: any) {
    navigator.clipboard.writeText(val);
  }

  
  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragging = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDragging = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.isDragging = false;
  }

  onFileSelected(event: any): void {
    var file = event.target.files[0] as File;
    this.selectedFile = file;
    this.uploadMedia()
  }

  uploadMedia() {
    var fileByteArray: number[] = [];
    var reader = new FileReader();
    var toastrService: ToastrService = this.toastr;
    var file: any = this.selectedFile;
    var self = this;
    var invalid = false;

    reader.onload = function() {
      var arrayBuffer = this.result as ArrayBuffer,
        array = new Uint8Array(arrayBuffer)
        if(array.length > 10485760) {
          toastrService.error('File exceeds max size (10MB).\r\nPlease try a different file.');
          invalid = true;
        } else {
          invalid = false;
          for (var i = 0; i < array.length; i++) {
            fileByteArray.push(array[i]);
          }
          
          self.Loading();
          self.isDragging = false;
          self.apiService.Post(`/valet/sessions/${self.Model.Id}/images`, 
          {
            Bytes : fileByteArray,
            Extension: "." + file.name.split('.').pop()
          }).then((result : any) => {
            self.ngOnInit();      
          });
        }
    }
    var x = reader.readAsArrayBuffer(this.selectedFile);
    this.fileInvalid = invalid;
  }
}
