import { Component, OnInit } from '@angular/core';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { StructureViewerLayer } from 'src/app/shared/structureviewermaps/structureviewerlayers';

@Component({
  selector: 'app-carcounterlist',
  templateUrl: './carcounterlist.component.html',
  styleUrls: ['./carcounterlist.component.scss']
})
export class CarcounterlistComponent  implements OnInit {

  constructor() { }

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("HID", "ChipId"),
    new Datalistviewcolumn("Version", "Version"),
    new Datalistviewcolumn("Last Check In", "LastCheckinString"),
    new Datalistviewcolumn("Location", "LocationString"),
  ];

  public StructureViewerLayers: StructureViewerLayer[] = [
    new StructureViewerLayer("ParkingLot"), 
    new StructureViewerLayer("ParkingLevel"), 
    new StructureViewerLayer("CarCounter", null, true),
  ]

  ngOnInit(): void {
  }



}
