import { Component, Input, OnChanges } from '@angular/core';
import { MediaService } from 'src/app/Services/media.service';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent implements OnChanges {
  constructor(private mediaService: MediaService) {

  }

  ngOnChanges() {
    this.updateImages();
  }

  private updateImages() {
    this.Images = [];
    if(this.ImageIds.length > 0) {
      this.ImageIds.forEach(x => this.Images.push(this.mediaService.GetMediaUrl(x.toString())))
    }
    if(this.ImageUrls.length > 0) {
      this.ImageUrls.forEach(x => this.Images.push(x));
    }
  }

  @Input() 
  ImageUrls: string[] = [];

  @Input() 
  ImageIds: number[] = [];

  @Input()
  ImageCaptions: string[] = [];

  Images: string[] = [];
  
  currentIndex: number = 0;

  showNext() {
    if(this.currentIndex < this.Images.length - 1) {
      this.currentIndex = (this.currentIndex + 1);
    }
  }

  showPrevious() {
    if (!(this.currentIndex <= 0)) {
      this.currentIndex = (this.currentIndex - 1);
    }
  }

  show(i: number) {
    this.currentIndex = i;
  }

  getOpacity(index: number): number {
    const distance = Math.abs(index - this.currentIndex);
    if (distance === 0) return 1; 
    if (distance === 1) return 0.5; 
    if (distance === 2) return 0.25;
    return 0;
  }
}