import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { BusyableModalComponent } from 'src/app/shared/editors/busyableModalComponent';

@Component({
  selector: 'app-refundvaletsessionpaymentmodal',
  templateUrl: './refundvaletsessionpaymentmodal.component.html',
  styleUrls: ['./refundvaletsessionpaymentmodal.component.scss']
})
export class RefundValetSessionModalComponent extends BusyableModalComponent<any, null> implements OnInit {

  public Payment: any = null;

  constructor(private modalService: SimpleModalService, private apiService: ApiServiceBase, organizationsService: OrganizationsService) {
    super();
  }

  ngOnInit(): void {
  }

  cancel() {
    this.close()
  }

  record() {
    this.Busy();
    this.apiService.Post("valet/sessions/" + this.Payment.ValetSessionId + "/payments/" + this.Payment.Id + "/refunds", {}).then(() => this.close());
  }

}
