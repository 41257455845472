<app-pageheader [Parent]="this">
  <add-button AddRoute="/parking/accesscontrol/controlledareas/new/edit"></add-button>
</app-pageheader>

<app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="infrastructure/controlledareas/searches"
  [AllowEdit]="true"
  EditUrl="/parking/accesscontrol/controlledareas/:id">
  <app-filter-set>
    <div app-search-filter></div>
    <div app-search-button></div>
  </app-filter-set>
</app-datalistview>