import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { OrganizationsService } from 'src/app/Services/organizations.service';

@Component({
  selector: 'app-editdocumenttemplate',
  templateUrl: './editdocumenttemplate.component.html',
  styleUrls: ['./editdocumenttemplate.component.scss']
})
export class EditDocumentTemplate extends ModelEditor implements OnInit {

  constructor(private route: ActivatedRoute, private apiService: ApiServiceBase, private injector: Injector) {
    super("communications/documenttemplates", injector);
  };

  public override DefaultModel(): any {
    return {
      Name: "New Template",
      Body: "",
      PaperSize: "Letter",
      TargetType: null
    }
  }

  public override AfterModelLoaded(): void {
  }

  public override BeforeSave(): boolean | void {
  }

  public override Validators(): any {
  }
}
