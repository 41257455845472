import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { Datalistviewcolumn, SelectedViewColumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { DatalistviewComponent } from 'src/app/shared/datalistview/datalistview.component';
import { Busyable } from '../../editors/busyable';

@Component({
  selector: 'app-valetsessionpark',
  templateUrl: './valetsessionpark.component.html',
  styleUrls: ['./valetsessionpark.component.scss']
})
export class ValetSessionParkComponent extends SimpleModalComponent<any, any>  {
  
  public ParkingLots !: any[];
  public selectedLotId !: string;
  public apiService !: ApiService;
  public toastService !: ToastrService;
  public Busyable = new Busyable();

  constructor(apiService: ApiService, toastService: ToastrService) {
    super();
    this.apiService = apiService;
    this.toastService = toastService;
    this.Busyable.Loading();
    this.apiService.Get("infrastructure/parkinglots").then((result:any)=> {
      this.ParkingLots = result;
      this.selectedLotId = result[0].Id;
      this.Busyable.StopLoading();
    })
  }

  public ParkingLotSelected(event : any){
      this.selectedLotId = event.target.value;
  }
  
  confirm() {

    if(!this.selectedLotId){
      this.toastService.error("You must enter a location for the Vehicle.");
      return;
    }

    this.result = { Success: true, ParkingLotId: this.selectedLotId};
    this.close();
  }
}