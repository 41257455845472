import { FabricHelpers } from "../../../fabric.js/helpers";
import { LevelEditorComponent } from "../../../leveleditor.component";
import { MapItemTool } from "./MapItemTool";

export class BiDirectionalArrow extends MapItemTool{
    public override Type = "Arrow";
    public override OutlineColor = "FFFFFF";
    public override Opacity =  0.8;
    public override FillColor =  "FFFFFF";
    public override OutlineThickness = 2;
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }

    public override CreatePolygonFromXY(x: number, y: number) {
        var zoom = this.canvas.getZoom();
        var points = FabricHelpers.GetAdjustedXY(this.canvas, x, y);
        x = points[0];
        y = points[1];
        let size = 20; // Adjust arrow size as needed
        let poly: any[] = [];

        // left arrow
        poly.push([x - size * 2, y + size / 2]);
        poly.push([x - size * 2, y + size * 1.25]);
        poly.push([x - size * 3.5, y]); // End of arrow head
        poly.push([x - size * 2, y - size * 1.25]);
        poly.push([x - size * 2, y - size / 2]);
        poly.push([x - size * 1.75, y - size / 2]);
        
        poly = poly.concat(this.CreateLeftCurve(x - size * 1.75, y - size / 2, x - size * 0.75, y + size * 0.125, 15));
        poly = poly.concat(this.CreateRightCurve(x - size * 0.75, y + size * 0.125, x + size * 0.25, y - size / 2, 15));
        // right curve here
        
        // right arrow
        poly.push([x + size * 0.25, y - size / 2]);
        poly.push([x + size / 2, y - size / 2]);
        poly.push([x + size / 2, y - size * 1.25]);
        poly.push([x + size * 2, y]); // End of arrow head
        poly.push([x + size / 2, y + size * 1.25]);
        poly.push([x + size / 2, y + size / 2]);

        // lower portion
        poly = poly.concat(this.CreateRightCurve(x + size / 2, y + size / 2, x - size * 0.25, y + size * 1.5, 10));
        poly.push([x - size * 0.25, y + size * 3]);
        poly.push([x - size * 1.25, y + size * 3]);
        poly.push([x - size * 1.25, y + size * 1.5]);
        poly = poly.concat(this.CreateLeftCurve(x - size * 1.25, y + size * 1.5, x - size * 2, y + size / 2, 10));        
        // Scale points based on zoom and scaleFactor
        var results = poly.map((item: any) => [item[0] / this.ScaleFactor / zoom, item[1] / this.ScaleFactor / zoom]);
        return results;
    }

    private CreateLeftCurve(xStart: number, yStart: number, xEnd: number, yEnd: number, numPoints: number) {
        let curve : any[] = [];
        let xDis = (xEnd - xStart);
        let yDis = (yEnd - yStart);
        let xDirection = xDis >= 0 ? 1 : -1;
        let yDirection = yDis >= 0 ? 1 : -1;

        let radiusX = Math.abs(xDis);
        let radiusY = Math.abs(yDis);

        let angleIncrement = (Math.PI / 2) / numPoints;
        let midX = xStart;
        let midY = yEnd;

        if(yDirection < 0) {
            midX = xEnd;
            midY = yStart;
            for (let i = numPoints; i >= 0; i--) {
                let angle = (Math.PI / 2) - (angleIncrement * i);
                let x = midX + radiusX * Math.cos(angle);
                let y = midY - radiusY * Math.sin(angle); 
                curve.push([x, y]);
            }
        } else {
            for (let i = 0; i <= numPoints; i++) {
                let angle = (Math.PI / 2) - (angleIncrement * i);
                let x = midX + radiusX * Math.cos(angle);
                let y = midY - radiusY * Math.sin(angle); 
                curve.push([x, y]);
            }
        }
      
        return curve;
    }

    private CreateRightCurve(xStart: number, yStart: number, xEnd: number, yEnd: number, numPoints: number) {
        let curve : any[] = [];
        let xDis = (xEnd - xStart);
        let yDis = (yEnd - yStart);
        let xDirection = xDis >= 0 ? 1 : -1;
        let yDirection = yDis >= 0 ? 1 : -1;

        let radiusX = Math.abs(xDis);
        let radiusY = Math.abs(yDis);

        let angleIncrement = (Math.PI / 2) / numPoints;
        let midX = xStart;
        let midY = yEnd;

        if(yDirection < 0) {
            midX = xEnd;
            midY = yStart;
            for (let i = numPoints; i >= 0; i--) {
                let angle = (Math.PI / 2) - (angleIncrement * i);
                let x = midX - radiusX * Math.cos(angle);
                let y = midY - radiusY * Math.sin(angle); 
                curve.push([x, y]);
            }
        } else {
            for (let i = 0; i <= numPoints; i++) {
                let angle = (Math.PI / 2) - (angleIncrement * i);
                let x = midX - radiusX * Math.cos(angle);
                let y = midY - radiusY * Math.sin(angle); 
                curve.push([x, y]);
            }
        }
      
        return curve;
    }
}