import { Component, OnInit } from '@angular/core';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-clusterdesings',
  templateUrl: './clusterdesigns.component.html',
  styleUrls: ['./clusterdesigns.component.scss']
})
export class ClusterDesignsComponent implements OnInit {
  
  constructor() { 
  }

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "Name"),
  ];

  ngOnInit(): void {
  }


}
