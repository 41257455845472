<app-tabs #apptabs>
    <app-tab Title="Designer">
        <div class="mapeditor" style="height:100%; width:100%; overflow:hidden">
            <div class="columns" style="height: 100%;">
                <div class="column is-2">
                  <div class="toolbar" style="width: 100%; height: 100%;">
                    <div class="card-container">
                      <div *ngFor="let Parent of Tools">
                        <div class="parent-header" (click)="ToggleParentVisibility(Parent)">
                          <span [ngClass]="Parent.isVisible ? 'chevron-down' : 'chevron-right'"></span>
                          {{ Parent.Name }}
                        </div>
                        <div *ngIf="Parent.isVisible" style="margin-bottom:10px;">
                            <div class="card tool-card" *ngFor="let tool of Parent.Tools"
                                (click)="ToggleSelect(tool)"
                                [ngClass]="ToolIsSelected(tool) ? 'selected' : ''">
                                <img [src]="'/assets/documenteditor/tools/' + tool.Name.toLowerCase()  + '.png'" alt="{{ tool.Name }}" class="tool-image">
                                <div class="lock-icon" *ngIf="tool.Tool.Locked" (click)="ToggleLock($event, tool)"><i [ngClass]="tool.Tool.Locked ? 'fa fa-lock' : 'fa fa-unlock'"></i></div>
                            <div class="tool-name">{{ tool.Name }}</div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-7 gridview" style="height:100%">
                    <div class="canvas-wrapper" id="wrapper" #Wrapper style="height:100%; overflow: hidden;">
                        <canvas id="documentCanvas" style="z-index: 1;" #DesignSurface></canvas>
                    </div>
                </div>
                <div class="column is-3 properties-pane" style="padding:1em;">
                    <div class="is-small">
                      <table class="table is-striped properties-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngIf="SelectedItem == null">
                            <tr>
                              <td>Page Size</td>
                              <td>
                                <div class="select">
                                  <select class="input" [value]="GetPageSize()" (change)="UpdatePageSize($event)">
                                    <option>Letter</option>
                                    <option>A3</option>
                                    <option>A4</option>
                                    <option>A5</option>
                                  </select>
                                </div>
                              </td>
                            </tr>
                            <tr *ngIf="Form.get('TargetType')?.value == null">
                              <td>Target Type</td>
                              <td>
                                <div class="select">
                                  <select class="input" [value]="GetTargetType()" (change)="UpdateTargetType($event)">
                                    <option></option>
                                    <option *ngFor="let t of TargetTypes" [value]="t.DisplayName">{{t.DisplayName}}</option>
                                  </select>
                                </div>
                              </td>
                            </tr>
                            <tr *ngIf="Form.get('TargetType')?.value != null">
                              <td>Target Type</td>
                              <td><input class="input" [value]="Form.get('TargetType')?.value" disabled></td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="SelectedItem != null">
                            <ng-container *ngIf="SelectedItemIsText()">
                              <tr>
                                <td>Text</td>
                                <td>
                                  <textarea style="height:10em !important" class="input" rows="4" cols="50" type="Text" [value]="SelectedItemText()" (input)="UpdateSelectedItemText($event)" [disabled]="IsItemDisabled()"></textarea>
                                </td>
                              </tr>
                              <tr>
                                <td>Binding</td>
                                <td *ngIf="Form.get('TargetType')?.value != null">
                                  <select class="input" [value]="GetBindingType()" (change)="ChangeBindingType($event)">
                                    <option value="">None</option>
                                    <option value="value">Value</option>
                                    <option value="expression">Expression</option>
                                  </select>
                                </td>
                                <td *ngIf="Form.get('TargetType')?.value == null" (click)="DeselectItems()">
                                  <button type="text" class="input" style="color:red; border:none">Target type not set. Click here for page settings.</button>
                                </td>
                              </tr>
                              <tr *ngIf="TargetType != null && BindingIs('value')">
                                <td>Bound Value</td>
                                <td>
                                  <select class="input" [(ngModel)]="selectedProperty" (ngModelChange)="SetBoundValue($event)">
                                    <option value="">None</option>
                                    <option *ngFor="let ModelProperty of TargetType.Properties" [value]="ModelProperty.PropertyName">
                                        {{ ModelProperty.DisplayName }}
                                    </option>
                                  </select>
                                  <p style="margin-top:10px;">{{GetDescriptionOfProperty()}}</p>
                                </td>
                              </tr>
                              <tr *ngIf="BindingIs('expression')">
                                <td>Expression</td>
                                <td>
                                  <input class="input" [value]="GetDataExpression()" (change)="SetExpression($event)">
                                </td>
                              </tr>
                              <tr>
                                <td>Width</td>
                                <td>
                                  <input class="input" type="number" step="1" [value]="SelectedItemTextWidth()" (change)="UpdateSelectedItemTextWidth($event)">
                                </td>
                              </tr>
                              <tr>
                                <td>Color</td>
                                <td>
                                  <app-colorpicker [ColorValue]="SelectedItemFontColor()" (ColorChanged)="UpdateSelectedItemFontColor($event)"></app-colorpicker>
                                </td>
                              </tr>
                              <tr>
                                <td>Font</td>
                                <td>
                                  <div class="select">
                                    <select class="input" [value]="SelectedItemFontFamily()" [ngStyle]="{ 'font-family': SelectedItemFontFamily() }" (change)="UpdateSelectedItemFontFamily($event)">
                                      <option style="font-family: Arial" value="arial">Arial</option>
                                      <option style="font-family: Verdana" value="verdana">Verdana</option>
                                      <option style="font-family: Tahoma" value="tahoma">Tahoma</option>
                                      <option style="font-family: Trebuchet MS" value="trebuchet ms">Trebuchet MS</option>
                                      <option style="font-family: Times New Roman" value="times new roman">Times New Roman</option>
                                      <option style="font-family: Georgia" value="georgia">Georgia</option>
                                      <option style="font-family: Garamond" value="garamond">Garamond</option>
                                      <option style="font-family: Courier New" value="courier new">Courier New</option>
                                      <option style="font-family: Brush Script MT" value="brush script mt">Brush Script MT</option>
                                    </select>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Font Size</td>
                                <td>
                                  <input class="input" type="Number" min="8" max="120" [value]="SelectedItemFontSize()" (input)="UpdateSelectedItemFont($event)">
                                </td>
                              </tr>
                              <tr>
                                <td>Styles</td>
                                <td>
                                  <button title="Bold" class="button" style="background:none;" [ngStyle]="IsSelectedTextBold() ? {'border': '2px solid #007BFF', 'font-weight': 'bold'} : {}" (click)="UpdateSelectedTextBold()">
                                    <i class="fa fa-bold"></i>
                                  </button>
                                  <button title="Italic" class="button" style="background:none;" [ngStyle]="IsSelectedTextItalic() ? {'border': '2px solid #007BFF', 'font-weight': 'bold'} : {}" (click)="UpdateSelectedTextItalic()">
                                    <i class="fa fa-italic"></i>
                                  </button>
                                  <button title="Underline" class="button" style="background:none;" [ngStyle]="IsSelectedTextUnderlined() ? {'border': '2px solid #007BFF', 'font-weight': 'bold'} : {}" (click)="UpdateSelectedTextUnderlined()">
                                    <i class="fa fa-underline"></i>
                                  </button>
                                  <button title="Hyperlink" class="button" style="background:none;" [ngStyle]="SelectedItemIsLink() ? {'border': '2px solid #007BFF', 'font-weight': 'bold'} : {}" (click)="UpdateSelectedTextHref()">
                                    <i class="fa fa-link"></i>
                                  </button>
                                  <br>
                                  <div style="margin-top:10px;">
                                    <button title="Left" class="button" style="background:none;" [ngStyle]="SelectedItemTextAlign('left') ? {'border': '2px solid #007BFF', 'font-weight': 'bold'} : {}" (click)="UpdateSelectedTextAlign('left')">
                                      <i class="fa fa-align-left"></i>
                                    </button>
                                    <button title="Center" class="button" style="background:none;" [ngStyle]="SelectedItemTextAlign('center') ? {'border': '2px solid #007BFF', 'font-weight': 'bold'} : {}" (click)="UpdateSelectedTextAlign('center')">
                                      <i class="fa fa-align-center"></i>
                                    </button>
                                    <button title="Right" class="button" style="background:none;" [ngStyle]="SelectedItemTextAlign('right') ? {'border': '2px solid #007BFF', 'font-weight': 'bold'} : {}" (click)="UpdateSelectedTextAlign('right')">
                                      <i class="fa fa-align-right"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              <tr *ngIf="SelectedItemIsLink()">
                                <td>Link</td>
                                <td>
                                  <input type="Text" class="input" [value]="SelectedItemLink()" (input)="UpdateSelectedTextHrefLink($event)">
                                </td>
                              </tr>
                            </ng-container>
                            <ng-container *ngIf="SelectedItemIsImage()">
                              <tr>
                                <td>Media</td>
                                <td>
                                  <app-media-thumbnail *ngIf="MediaIdNotNull()" [MediaId]="SelectedItemMediaId()" Title="Media Item"  ModalImageWidth="300px" ModalImageHeight="300px" ThumbnailHeight="60px" ThumbnailWidth="60px"></app-media-thumbnail>&nbsp;&nbsp;
                                  <app-mediaupload [MediaId]="SelectedItemMediaId()" Type=".png, .jpg, .jpeg" (MediaIdChange)="MediaIdChanged($event)"></app-mediaupload>
                                </td>
                              </tr>
                              <tr>
                                <td>Href</td>
                                <td>
                                  <input class="input" type="text" [value]="GetHref()" (input)="UpdateHref($event)">
                                </td>
                              </tr>

                            </ng-container>
                            <ng-container *ngIf="SelectedItemIsRectangle()">
                              <tr>
                                <td>Fill Color</td>
                                <td>
                                  <app-colorpicker [ColorValue]="SelectedItemFillColor()" (ColorChanged)="UpdateSelectedItemFillColor($event)"></app-colorpicker>
                                </td>
                              </tr>
                              <tr>
                                <td>Border Color</td>
                                <td>
                                  <app-colorpicker [ColorValue]="SelectedItemBorderColor()" (ColorChanged)="UpdateSelectedItemBorderColor($event)"></app-colorpicker>
                                </td>
                              </tr>
                              <tr>
                                <td>Border Width</td>
                                <td>
                                  <input class="input" type="number" min="0" [value]="SelectedItemBorderWidth()" (input)="UpdateSelectedItemBorderWidth($event)">
                                </td>
                              </tr>
                              <tr>
                                <td>Border Radius</td>
                                <td>
                                  <input class="input" type="number" min="0" [value]="SelectedItemBorderRadius()" (input)="UpdateBorderRadius($event)">
                                </td>
                              </tr>
                              <tr>
                                <td>Box Shadow</td>
                                <td>
                                  <input type="checkbox" [checked]="SelectedItemHasBoxShadow()" (input)="UpdateSelectedItemBoxShadow($event)">
                                </td>
                              </tr>
                            </ng-container>
                          </ng-container>
                        </tbody>
                        </table>
                        <ng-container *ngIf="SelectedItem != null">
                            <button type="button" class="button is-small operatorconsole" (click)="BringToFront()">Send To Front</button>
                            <button type="button" class="button is-small operatorconsole" (click)="SendToBack()">Send To Back</button>
                            <button type="button" class="button is-small operatorconsole" (click)="BringForward()">Bring Forward</button>
                            <button type="button" class="button is-small operatorconsole" (click)="SendBackward()">Send Backward</button>
                      </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </app-tab>
    <app-tab Title="Html Editor">
      <app-codeeditor Mode="html" [(Model)]="Body" (ModelChange)="BodyChanged($event)" #editor>
      </app-codeeditor>
    </app-tab>
</app-tabs>
