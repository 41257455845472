import { Component, ContentChild, Input } from '@angular/core';

@Component({
  selector: 'form-edit-button',
  templateUrl: './form-edit-button.component.html',
  styleUrls: ['./form-edit-button.component.scss']
})
export class FormEditButtonComponent {
  @ContentChild('text', { static: false }) Text: any;
  @Input("routerLink")
  public RouterLink : string = "edit";

  @Input("icon")
  public Icon : string = "chevron-right";
}
