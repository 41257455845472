<app-loader [Parent]="this"></app-loader>
<div *ngIf="Model != null">
    <app-pageheader [Parent]="this">
        <form-edit-button routerLink="edit">Edit</form-edit-button>
    </app-pageheader>

    <app-tabs>
        <app-tab Title="Configuration">
            <div style="margin:0 !important;" class="columns">
                <div class="column is-6">
                    <article class="panel">
                        <div class="panel-block">
                            <div>
                                <app-field Label="Id" class="control flex-center">{{Model.Id}}</app-field>
                                <app-field Label="Name" class="control flex-center">{{Model.Name}}</app-field>
                                <app-field Label="Location" class="control flex-center">{{Model.LocationString}}</app-field>
                                <app-field Label="Mode" class="control flex-center">
                                    <div *ngIf="Model.Mode == 0">Polling</div>
                                    <div *ngIf="Model.Mode == 1">Device</div>
                                    <div *ngIf="Model.Mode == 2">Polling Master</div>
                                    <div *ngIf="Model.Mode == 3">Device Master</div>
                                    <div *ngIf="Model.Mode == 4">Slave</div>
                                </app-field>
                                <app-field Label="Vacant Colour" class="control flex-center"><span class="input mb-3" [ngStyle]="{'background-color':'#'+Model.VacantColour}"></span></app-field>
                                <app-field Label="Brightness" class="control flex-center">
                                    <div *ngIf="Model.Brightness == 0">Off</div>
                                    <div *ngIf="Model.Brightness == 1">Low</div>
                                    <div *ngIf="Model.Brightness == 2">Med</div>
                                    <div *ngIf="Model.Brightness == 3">High</div>
                                    <div *ngIf="Model.Brightness == 4">Max</div>
                                </app-field>
                                <app-field Label="Port Camera Assignments" class="control flex-center">
                                    2: {{Model.Port2CameraName}}, 3: {{Model.Port3CameraName}}, 4: {{Model.Port4CameraName}}, 5: {{Model.Port5CameraName}}, 6: {{Model.Port6CameraName}}
                                </app-field>
                                <app-field Label="Ports Affecting Colour" class="control flex-center">
                                    1: {{Model.Port1AffectsColour}}, 2: {{Model.Port2AffectsColour}}, 3: {{Model.Port3AffectsColour}}, 4: {{Model.Port4AffectsColour}}, 5: {{Model.Port5AffectsColour}}, 6: {{Model.Port6AffectsColour}}
                                </app-field>
                            </div>
                        </div>
                    </article>
                </div>
                <div class="column is-6">
                    <app-structureviewermaps [StructureId]="Model.ParkingLevelId" [SearchTerm]="Model.Id" StructureViewerForType="GuidanceLightConfiguration" [Layers]="StructureViewerLayers" [ShowLevelSelect]="false"></app-structureviewermaps>
                </div>
            </div>
            <div style="margin:0 !important;" class="columns">
                <div class="column is-6">
                    <article class="panel">
                        <p class="panel-heading">Hardware Assignment History</p>
                        <div class="panel-block">
                            <table class="table is-striped" style="width:100% !important">
                                <tr><th>Hardware Chip Id</th><th>Assigned Dates</th></tr>
                                <tr *ngFor="let item of Model.GuidanceLightAssignments">
                                    <td>{{item.ChipId}}</td>
                                    <td>{{item.DateInstalledString}} - {{item.IsActive ? 'Now' : item.DateUninstalledString}}</td>
                                </tr>
                            </table>
                        </div>
                    </article>
                </div>
                <div class="column is-6">
                    <article class="panel">
                        <p class="panel-heading">Port Assignments</p>
                        <div class="panel-block">
                            <table class="table is-striped" style="width:100% !important">
                                <tr><th>Port Number</th><th>Space Id</th> <th>Space Location</th></tr>
                                <tr *ngFor="let item of Model.ParkingSpacePortAssignments">
                                    <td>{{item.PortNumber}}</td>
                                    <td>{{item.ParkingSpaceIId}}</td>
                                    <td>{{item.ParkingSpaceLocationString}}</td>
                                </tr>
                            </table>
                        </div>
                    </article>
                </div>
            </div>
        </app-tab>
        <app-tab Title="Hardware" *ngIf="Model.Device != null">
            <div style="margin:0 !important;" class="columns">
                <div class="column is-6">
                    <article class="panel">
                        <p class="panel-heading">Guidance Light {{HardwareModel.ChipId}}</p>
                        <div class="panel-block">
                            <div>
                                <app-field Label="Chip Id" class="control flex-center">{{HardwareModel.ChipId}}</app-field>
                                <app-field Label="Installed On" class="control flex-center">{{HardwareModel.InstalledOnString}}</app-field>
                                <app-field Label="Status" class="control flex-center">{{HardwareModel.Status}}</app-field>
                                <app-field Label="Version" class="control flex-center">{{HardwareModel.VersionNumber}}</app-field>
                                <app-field Label="Most Recent Check-In" class="control flex-center">{{HardwareModel.LastCheckedInOnString}}</app-field>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </app-tab>
        <app-tab Title="Hardware Messages">
            <app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="{{'messaging/GuidanceLightConfiguration/hardwaremessages/'+Model.Id+'/searches'}}"
                [AllowEdit]="true" [EditAsModal]="true" [ModalComponentType]="HardwareMessageType" EditUrl="hardware/messages/:id" [Filters]="Filters">
                <app-filter-set>
                    <div app-search-filter></div>
                    <div app-date-filter [DateSearchProperty]="'ProcessedOn'"></div>
                    <div app-search-button></div>
                </app-filter-set>
                </app-datalistview>
        </app-tab>
    </app-tabs>
</div>