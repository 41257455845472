import { Component, OnInit } from '@angular/core';
import { Datalistviewcolumn, DateRangeViewColumn, ClassedViewColumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-parkingpermits',
  templateUrl: './parkingpermits.component.html',
  styleUrls: ['./parkingpermits.component.scss']
})

export class ParkingPermitsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public Subsets: string[] = ['Purchasing', 'Active', 'PendingUsers', 'NotEnoughUsers', 'Invited', 'Suspended', 'Cancelled', 'Expired'];

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("#", "Id"),
    new Datalistviewcolumn("Active Start Date", "ActiveStartDateLocalFriendlyString"),
    new Datalistviewcolumn("Active End Date", "ActiveEndDateLocalFriendlyString"),
    new Datalistviewcolumn("Type", "ParkingPermitTypeName"),
    new Datalistviewcolumn("Active Users", "UserDescription"),
    new Datalistviewcolumn("Times Used", "TimesUsed"),
    new ClassedViewColumn("Status", "StatusString"),
  ];
  
}
