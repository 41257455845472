import { Component, OnInit } from '@angular/core';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { BooleanCheckOrCrossViewColumn, BooleanValuesViewColumn, DataListFilterColumn, DatalastviewTextAndConditionalSuffixColumn, Datalistviewcolumn, IconViewColumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-valettypes',
  templateUrl: './valettypes.component.html',
  styleUrls: ['./valettypes.component.scss']
})
export class ValetTypesComponent {
  constructor() { }

  public Columns : Array<Datalistviewcolumn> = [
    new IconViewColumn(undefined, "IconName", "IconColor"),
    new Datalistviewcolumn("Name", "Name"),
    new BooleanCheckOrCrossViewColumn("Expect Returns", "IsMultiSession"),
    new Datalistviewcolumn("Billing Key", "BillingKeyName"),
    new Datalistviewcolumn("Rate Set", "ParkingRateSetName")
  ];
}
