<app-loader [Parent]="this"></app-loader>
  <div *ngIf="Model != null">
    <app-pageheader [Parent]="this">
      <form-edit-button>Edit</form-edit-button>
      <app-form-delete-button [Model]="Model" [Parent]="this" DeleteUrl="infrastructure/parkinglots/" [DeleteCheck]="true" Api="Infrastructure" TypeName="ParkingLot" DeleteNavLink="parking/parking/lots">
      </app-form-delete-button>
    </app-pageheader>
    <div class="columns">
    <div class="column is-6">
      <article class="panel">
        <p class="panel-heading">
          {{Model.Name}}
        </p>
        <a class="panel-block">
          <span class="label">Id</span>
          <span class="value">{{Model.Id}}</span>
        </a>
        <a class="panel-block">
          <span class="label">Parking Lot</span>
          <span class="value">{{Model.LocationString}} ({{Model.Levels.length}} {{((Model.Type >= 100) ? 'Levels' : 'Zones')}})</span>
        </a>
        <a class="panel-block">
          <span class="label">Description</span>
          <span class="value">{{Model.Description}}</span>
        </a>
        <a class="panel-block">
          <span class="label">Type</span>
          <span class="value">{{Model.TypeDescription}}</span>
        </a>
        <a class="panel-block">
          <span class="label">Spaces</span>
          <span class="value">Vacant: {{Model.AvailableSpaces}} Occupied: {{Model.OccupiedSpaces}}
            ({{Model.TotalSpaces}} total)</span>
        </a>
        <a class="panel-block">
          <span class="label">Count Mode</span>
          <span class="value">{{Model.AvailabilityCountMode}} <button *ngIf="Model.AvailabilityCountMode == 'VehiclesPresent'"
              style="margin-left:15px;" class="button is-link is-small" (click)="EditParkingLevelCounts()">Update
              Counts</button></span>
        </a>
        <a class="panel-block">
          <span class="label">Percent Occupied</span>
          <span class="value">
            <div class="{{Model.TextClass}}"
              style="width:7em; margin-right: 10px; vertical-align: middle; text-align:center; height: 1.6em; border-radius:5px; border: 1px solid black; float:left;"
              [ngStyle]="{'background-color': '#' + Model.ThresholdColor}">{{Model.PercentOccupied}} %</div>
          </span>
        </a>
        <a class="panel-block">
          <span class="label">Threshold Set</span>
          <span class="value">{{Model.ParkingThresholdSetName}}</span>
        </a>
        <a class="panel-block">
          <span class="label">Default Rate Set</span>
          <span class="value">{{Model.ParkingRateSetName}}</span>
        </a>
        <a class="panel-block">
          <span class="label">Reset Mode</span>
          <span class="value">{{GetResetMode(Model.ResetMode)}} at {{Model.ResetHour}}</span>
        </a>
        <a class="panel-block">
          <span class="label">Payment Target</span>
          <span class="value">{{Model.PaymentRequiresSpaceName ? "Marked Spaces - User must select a Space" : "Unmarked
            Spaces - User selects Lot only"}}</span>
        </a>
        <a class="panel-block">
          <span class="label">Paid Parking</span>
          <span class="value">{{Model.IsPaidParking ? "Yes" : "No"}}</span>
        </a>
        <a class="panel-block" *ngIf = "Model.IsPaidParking">
          <span class="label">Payment Mode</span>
          <span class="value">{{Model.PaymentMode == 1 ? "PrePay" : "PostPay"}}</span>
        </a>
        <a class="panel-block">
          <span class="label">Global Directory</span>
          <span class="value">{{Model.EnableGlobalDirectory ? "Included" : "Excluded"}}</span>
        </a>
        <a class="panel-block empty">
        </a>
      </article>
    </div>
    <div class="column is-6">
      <app-structureviewermaps [StructureId]="Model.Id" [Layers]="StructureViewerLayers" [ShowLevelSelect]="true"></app-structureviewermaps>
    </div>
  </div>
  <div class="columns">
    <div class="column is-6">
      <app-dashboardbox>
        <app-occupancyserieschart Title="Traffic - Today" [StructureId]="Model.Id" StructureType="parkinglot"
          ChartType="line" Period="today" DataType="inflows"></app-occupancyserieschart>
      </app-dashboardbox>
    </div>
    <div class="column is-6">
      <app-dashboardbox>
        <app-occupancyserieschart Title="Traffic - Last 7 Days" [StructureId]="Model.Id" StructureType="parkinglot"
          ChartType="bar" Period="last7days" DataType="inflows"></app-occupancyserieschart>
      </app-dashboardbox>
    </div>
  </div>
<div class="columns">
  <div class="column is-12">
    <article class="panel">
      <p class="panel-heading">
        {{((Model.Type >= 100) ? 'Levels' : 'Zones')}}
      </p>

      <a class="panel-block">
        <!-- //   <span class="value"> -->


        <table class="table is-striped is-bordered is-fullwidth">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Spaces</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let level of levels">
              <td><span type="text" class="input">
                  {{level.LevelNumber}}</span>
              </td>
              <td><span type="text" class="input ">
                  {{level.Name}}</span>
              </td>
              <td><span type="text" class="input">
                  {{level.TotalSpaces}}</span>
              </td>
              <td>
                <button type="button" class="button is-primary is-small"
                  routerLink="/parking/parking/lots/{{Model.Id}}/levels/{{level.Id}}">
                  <span class="icon-text">
                    <span class="icon pt-2">
                      <i class="fa fa-chevron-right"></i>
                    </span>
                    <span>View</span>
                  </span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- </span> -->

      </a>
    </article>
  </div>
</div>
<div class="columns">
  <div class="column is-12">
    <article class="panel">
      <p class="panel-heading">
        Devices
      </p>
      <app-datalistview [Columns]="Columns" [ShowMap]="false" [ApiUrl]="Model.Api"
        [AllowEdit]="true" [ShowPaging]="false" [EditUrlPerItem]="true"></app-datalistview>
    </article>
  </div>
</div>
</div>