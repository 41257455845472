import { ActivatedRoute } from "@angular/router";
import { Datalistrequestfilter } from "../request/datalistrequestfilter";

export interface IFilter {
    QueryParamPrefix: string;
}

export interface ISeachTermFilter extends IFilter {
    SearchTerm: string
}

export interface ISubsetsFilter extends IFilter {
    Subset: string
}

export interface IFilterFilters extends IFilter {
    Filters: Array<Datalistrequestfilter>;
    FilterName: string;
}

export class FilterFiltersBase {
    constructor(protected route: ActivatedRoute){}

    protected GetParamFields(paramName: string): string[] | null {
        var val = this.route.snapshot.queryParamMap.get(paramName);
        if(val != null) {
          var vals = decodeURIComponent(val).split(",");
          return vals;
        }
        return null;
    }
}

export function isSearchTermFilter(obj: any): obj is ISeachTermFilter {
    return 'SearchTerm' in obj;
}

export function isSubsetFilter(obj: any): obj is ISubsetsFilter {
    return 'Subset' in obj;
}

export function isFilterFilters(obj: any): obj is IFilterFilters {
    return 'Filters' in obj;
}