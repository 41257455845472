import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginServiceBase } from '../login.service.base';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-newtestorg',
  templateUrl: './newtestorg.component.html',
  styleUrls: ['./newtestorg.component.scss']
})
export class NewTestOrgComponent implements OnInit {

  constructor(private router: Router, private loginService: LoginServiceBase, private parentRoute: ActivatedRoute) { }

  ngOnInit(): void {
    let name = "Test_Organization " + this.parentRoute.snapshot.queryParams['name'];
    this.loginService.NewTestOrganization(name);
  }

}
