import { Component, Input, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-hardwaremessagesviewer',
  templateUrl: './hardwaremessagesviewer.component.html',
  styleUrls: ['./hardwaremessagesviewer.component.scss']
})
export class HardwaremessagesviewerComponent extends SimpleModalComponent<any, any> implements OnInit {

  @Input()
  public DeviceId: string = "";

  public CheckinData: any | null = null;

  constructor(private apiService: ApiServiceBase) {
    super();
  }

  ngOnInit(): void {
    this.RefreshCheckins();
  }

  public RefreshCheckins() {
    this.apiService.Post("infrastructure/devices/" + this.DeviceId + "/checkins/searches", { PageNumber: 1, PageSize: 25 })
      .then(result => {
        this.CheckinData = result;
      });
  }

}
