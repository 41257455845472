import { Component, Input, OnInit } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import * as moment from 'moment';
import { ApiServiceBase } from 'src/app/Services/api.service.base';


@Component({
  selector: 'app-devicecheckinchart',
  templateUrl: './devicecheckinchart.component.html',
  styleUrls: ['./devicecheckinchart.component.scss']
})
export class DevicecheckinchartComponent implements OnInit {

  @Input()
  public DeviceId : string = "";
  @Input()
  public PropertiesToDisplay : string[] = ['CpuTemp'];
  @Input()
  public Height : number = 200; 
  
  public Hours : number = 24;
  public ChartOptions : ChartOptions = {
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.2
      }
    },
    plugins: {
      legend : { display: true },
      tooltip: { callbacks: { label(this, tooltipItem) {
        return new Date(tooltipItem.parsed.x).toISOString().slice(0,16) + " " + this.chart.data.datasets[tooltipItem.datasetIndex].label + " : " + tooltipItem.formattedValue;
      },
      }}
    },
    scales : {
      x: { ticks: {callback: (value) => {return new Date(value).toISOString().slice(0,16);}}}, 
      y: { beginAtZero : true}
    },
  };
  public ChartData : ChartData = {
   datasets: []
  };
  public ChartColors: string[] = [
    'green'
  ];

  constructor(private apiService : ApiServiceBase) { }

  ngOnInit(): void {
    this.apiService.Get<any>("infrastructure/checkins/" + this.DeviceId + '/latest/' + this.Hours).then(results => {
      let datasets:any[] = [];
      for(let p of this.PropertiesToDisplay){
        let data : any[] = [];
        let dataset = { label : p, showLine: true, backgroundColor: 'green', borderColor: 'green', data: data };
        datasets.push(dataset);
        for(let r of results){
          if(r[p]){
            dataset.data.push({ x: moment(r.Timestamp).valueOf(), y: r[p]});
          }
        }
      }
      this.ChartData.datasets = datasets;
    });
  }

}
