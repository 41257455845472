import { edit } from "ace-builds";
import { LevelEditorComponent } from "../leveleditor.component";
import { PolygonMapComponent } from "../components/PolygonComponents/PolygonMapComponent";
import { map } from "rxjs";
import { PointMapComponent } from "../components/PointComponents/PointMapComponent";

export class UndoAction {
    public CanvasState !: any;
    public FormItem !: any;
    public Editor !: LevelEditorComponent;
    public FieldName !: string;
    public OldValue !: object | number | undefined;
    public NewValue !: object | number | undefined;
    public Children?: UndoAction[];

    constructor(
        canvasState: any,
        editor?: LevelEditorComponent,
        formItem?: any,
        fieldName?: string,
        oldValue?: object | number | undefined,
        newValue?: object | number | undefined,
        children?: UndoAction[]
    ) {
        if (children) {
            this.Children = children;
        } else {
            this.Editor = editor!;
            this.CanvasState = canvasState!;
            this.FormItem = formItem!;
            this.FieldName = fieldName!;
            this.OldValue = oldValue!;
            this.NewValue = newValue!;
        }
    }

    public Undo() {
        if (this.Children) {
            this.Children.forEach(child => child.Undo());
        } else {
            var mapItem = this.Editor.MapItems.find(x => x.Id == this.FormItem.get('Id')?.value);
            if(mapItem){
                if(mapItem instanceof PolygonMapComponent){
                    mapItem.formItem.get(this.FieldName).setValue(this.OldValue);
                    if(this.FieldName == 'PolygonPoints'){
                        mapItem.TransformedPoints = this.OldValue;
                    }
                    mapItem.AddFabricItem();
                    this.Editor.canvas.renderAll();
                };
                if(mapItem instanceof PointMapComponent){
                    mapItem.formItem.get(this.FieldName).setValue(this.OldValue);
                    mapItem.AddFabricItem();
                    this.Editor.canvas.renderAll();                
                };
            }

            this.Editor.canvas.discardActiveObject();
            this.Editor.SelectedItems = [];
        }
    }
}
