import { LevelEditorComponent } from "../../../leveleditor.component";
import { MapItemTool } from "./MapItemTool";

export class PillarTool extends MapItemTool{
    public override ItemWidth: number = 10;
    public override ItemHeight: number = 10;
    public override Type = "Pillar";
    public override OutlineColor = "555555";
    public override Opacity =  0.8;
    public override FillColor =  "e8e8e8";
    public override OutlineThickness = 2;
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }
}