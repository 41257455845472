import { Helpers } from "../Helpers/Helpers";
import { Style } from "../HtmlRenderComponents";
import { DocumentEditorComponent } from "../documenteditor.component";
import { DocumentComponentBase } from "./DocumentComponentBase";
import { fabric } from 'fabric';



export class ImageComponent extends DocumentComponentBase {

    public Styles !: Style;
    public Src !: string;
    public MediaId !: string | null;
    public Href !: string | null;

    constructor(documentEditorComponent: DocumentEditorComponent, id: string | null, src: string, styles: Style, href:string | null = null) {
        super(documentEditorComponent);
        this.Src = src; 
        this.MediaId = id;
        this.Styles = styles;
        this.Href = href;
    }

    public override RenderHtml() : string {
        let styleString = '';
  
        if (this.Styles.left !== null) styleString += `left: ${Helpers.convertPixelsToInches(this.Styles.left ?? 0)}in; `;
        if (this.Styles.top !== null) styleString += `top: ${Helpers.convertPixelsToInches(this.Styles.top ?? 0)}in; `;
        if (this.Styles.width !== null) styleString += `width: ${Helpers.convertPixelsToInches(this.Styles.width ?? 0)}in; `;
        if (this.Styles.height !== null) styleString += `height: ${Helpers.convertPixelsToInches(this.Styles.height ?? 0)}in; `;
        if (this.Styles.transform !== null) styleString += `transform: (0deg); `;
  
        styleString += `position: absolute;`
  
        let htmlString = `<img id="${this.MediaId}" style="${styleString}" src="${this.Src}"></img>`;

        if(this.Href){
            htmlString = `<a href="${this.Href}">${htmlString}</a>`;
        }
        return htmlString;
    }

    override Draw(canvas: fabric.Canvas){
        if(this.fabricItem){
            canvas.remove(this.fabricItem);
            this.fabricItem = null;
        };

        fabric.Image.fromURL(this.Src, (img) => {
            this.fabricItem = img;
            this.fabricItem.scaleToWidth(this.Styles.width ?? 40, true);

            this.fabricItem.lockRotation = true; // Lock rotation

            this.fabricItem.set({top: ((this.Styles.top ?? 0) + this.documentEditorComponent.offsets.y)});
            this.fabricItem.set({left:((this.Styles.left ?? 0) + this.documentEditorComponent.offsets.x)});

            this.fabricItem.on("selected", () => {
                this.documentEditorComponent.ItemSelected.emit(this);
             });
    
             this.fabricItem.on("deselected", () => {
                this.documentEditorComponent.ItemDeselected.emit(this);
            });
      
            this.fabricItem.on("modified", () => {
                
                const newLeft = this.fabricItem?.left ?? 0;
                const newTop = this.fabricItem?.top ?? 0;

                if(!Helpers.isPointInPolygon([newLeft, newTop], this.documentEditorComponent.RenderedDocument.Document)){
                    this.documentEditorComponent.toastService.info("Element cannot exist out side of document. Reverting move.");
                    this.fabricItem?.set({top: ((this.Styles.top ?? 0) + this.documentEditorComponent.offsets.y)});
                    this.fabricItem?.set({left:((this.Styles.left ?? 0) + this.documentEditorComponent.offsets.x)});
                }
                else{
                    this.Styles.width = this.fabricItem?.getScaledWidth() ?? this.Styles.width; // Adjusted for zoom level
                    this.Styles.height = this.fabricItem?.getScaledHeight() ?? this.Styles.height; // Adjusted for zoom level
                    this.Styles.left = (newLeft - this.documentEditorComponent.offsets.x);
                    this.Styles.top = (newTop - this.documentEditorComponent.offsets.y);
        
                    console.log(this.Styles.left, this.Styles.top);
                    this.documentEditorComponent.cdr.detectChanges();
                    this.documentEditorComponent.RenderHtml();
                }
            });
      
            canvas.add(this.fabricItem);
        });
    }

    public override Remove(canvas: any){
        canvas.remove(this.fabricItem);
    }

    public override BringToFront(){
        this.fabricItem?.bringToFront();
    }
  
    public override SendToBack(){
        this.fabricItem?.sendToBack();
    }
  
    public override BringForward(){
        this.fabricItem?.bringForward();
    }
  
    public override SendBackward(){
        this.fabricItem?.sendBackwards();
    }
    
}