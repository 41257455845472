<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Parent]="this" [Form]="Form" class="button is-primary" (OnSave)="Save()">
        </app-form-save-button>
    </app-pageheader>


    
    <div class="columns">
        <div class="column is-6">
            <article class="panel">
                <p class="panel-heading">Car Counter Configuration</p>
                <div class="panel-block">
                    <div class="three-quarters-width">
                        <app-field Label="Name" class="control">
                            <input formControlName="Name" class="input mb-3" type="text" />
                            <app-validator [For]="this.Form.get('Name')"></app-validator>
                        </app-field>
                        <app-field Label="Chip Id" class="control">
                            <input formControlName="ChipId" class="input mb-3" type="number" readonly/>
                            <app-validator [For]="this.Form.get('ChipId')"></app-validator>
                        </app-field>
                        <app-field Label="Sleep Time" class="control">
                            <input formControlName="SleepTime" class="input mb-3" type="time"/>
                            <app-validator [For]="this.Form.get('SleepTime')"></app-validator>
                        </app-field>
                        <app-field Label="Wake Up Time" class="control">
                            <input formControlName="WakeUpTime" class="input mb-3" type="time"/>
                            <app-validator [For]="this.Form.get('WakeUpTime')"></app-validator>
                        </app-field>
                        <app-field Label="Start of Day Time" class="control">
                            <input formControlName="StartOfDayTime" class="input mb-3" type="time"/>
                            <app-validator [For]="this.Form.get('StartOfDayTime')"></app-validator>
                        </app-field>
                        <app-field Label="End of Day Time" class="control">
                            <input formControlName="EndOfDayTime" class="input mb-3" type="time"/>
                            <app-validator [For]="this.Form.get('EndOfDayTime')"></app-validator>
                        </app-field>
                    </div>
                </div>
            </article>
            <article class="panel">
                <p class="panel-heading">Linked Structures</p>
                <app-field [HasAddons]="true">
                    <div class="control flex-grow" formArrayName="CarCounterRelationships">
                        <table class="table is-striped is-bordered input-table">
                            <thead>
                                <tr>
                                    <th>Parking Lot</th>
                                    <th>Parking Level</th>
                                    <th>Direction</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr [formGroupName]="i"
                                    *ngFor="let line of FormArray(Form, 'CarCounterRelationships').controls; let i = index">
                                    <td>
                                        <div class="select">
                                            <select class="input" formControlName="ParkingLotId">
                                                <option *ngFor="let p of ParkingLots" [ngValue]="p.Id">{{p.Name}}</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="select">
                                            <select class="input" formControlName="ParkingLevelId">
                                                <option [ngValue]="null"></option>
                                                <option *ngFor="let l of LevelsForLot(line.get('ParkingLotId')?.value)"
                                                    [ngValue]="l.Id">{{l.Name}}</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="select">
                                            <select class="input" formControlName="DirectionAIn" style="min-width: 10em;">
                                                <option [ngValue]="true">A is Entry</option>
                                                <option [ngValue]="false">A is Exit</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="control">
                                            <button class="button is-light" (click)="RemoveRelationship(i)">
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tr>
                                <td colspan="4">
                                    <div class="button is-primary" (click)="AddRelationship()">
                                        Add
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </app-field>
            </article>
        </div>
        <div class="column is-6">
            <article class="panel" *ngIf="Model.Device != null">
                <p class="panel-heading">Device Settings</p>
                <div class="panel-block">
                    <div formGroupName="Device" class="three-quarters-width">
                        <app-field Label="Hardware Version" class="control">
                            <input class="input mb-3" formControlName="HardwareVersionVersion" type="number" readonly/>
                        </app-field>
                        <app-field Label="Firmware Version" class="control mb-3">
                            <span class="select is-fullwidth">
                                <select formControlName="ExpectedFirmwareVersion">
                                    <option *ngFor="let item of FirmwareVersions" ngValue="item.Version">{{item.Version}}</option>
                                </select>
                            </span>
                        </app-field>
                        <app-field Label="Extended Configuration" class="control">
                            <input formControlName="ExtendedConfiguration" class="input mb-3" type="text"/>
                            <app-validator [For]="this.Form.get('ExtendedConfiguration')"></app-validator>
                        </app-field>
                        <app-field Label="TxPower" class="control">
                            <input formControlName="SimCardId" class="input mb-3" type="number" min="0" max="20"/>
                            <app-validator [For]="this.Form.get('TxPower')"></app-validator>
                        </app-field>
                    </div>
                </div>
            </article>
        </div>
    </div>
    </div>