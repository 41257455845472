<div class="mapeditor-outer">
    <app-loader [Parent]="Busyable"></app-loader>
    <google-map #map class="mapeditor-inner" [options]="MapOptions" height="100%" width="100%" [zoom]="15">
        <map-marker *ngFor="let m of PingSensors" [position]="{ lat: m.GeoLocationPoints.lat, lng: m.GeoLocationPoints.lng }"
          [options]="{ draggable: true, zIndex: 100, icon: getMarkerIcon(m.IsOccupied) }">
        </map-marker>
      </google-map>
    <div class="lotlevelselect">
        <div class="select" *ngIf="ShowLotSelect">
            <select class="input" (change)="SelectLot($event)" aria-placeholder="Select a Lot">
                <option value="All">All Lots</option>
                <option *ngFor="let i of ParkingLots" [value]="i.Id" [disabled]="LotForStructure(i)">{{i.Name}}</option>
            </select>
        </div>
        <div class="select" *ngIf="ShowLevelSelect && this.ParkingLevels != null && this.ParkingLotId != null">
            <select class="input" (change)="SelectLevel($event)" aria-placeholder="Select a Level">
                <option value="All">All {{(LotsOrZones())}}</option>
                <option *ngFor="let i of ParkingLevels" [value]="i.Id">{{i.Name}}</option>
            </select>
        </div>
    </div>
    <div class="menu">
        <div class="title"><i class="fa fa-chevron-left"></i></div>
        <article class="panel is-success nav">
            <a class="panel-block" *ngFor="let i of AvailableLayers">
                <input type="checkbox" [checked]="IsItemInLayers(i.Type, i.SubItemTypes)" (change)="ToggleLayer($event, i.Type, i.SubItemTypes)">
                {{ (i.Type == 'ParkingLevel' ? (ParkingLotId != null ? 'Parking' + LotsOrZones() : i.DisplayName) : i.DisplayName) }}
            </a>
        </article>
    </div>
</div>