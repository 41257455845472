import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { LevelEditorComponent } from "../../leveleditor.component";
import { v4 as uuidv4 } from 'uuid';
import { PointTool } from "./PointTool";
import { Geo } from "src/app/util/geo";
import { Barrier } from "../../components/PointComponents/Barrier";
import { PolygonMapComponent } from "../../components/PolygonComponents/PolygonMapComponent";
import { FabricHelpers } from "../../fabric.js/helpers";


export class BarrierTool extends PointTool{
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }

    public override CreateNew(canvasX : number, canvasY: number){
        const Lanes = this.editor.MapItemsByType.get('Lane');
        if(Lanes){
            for (let lane of Lanes) {
                var points = FabricHelpers.GetAdjustedXY(this.canvas, canvasX, canvasY);
                if (Geo.GetPolygonPointBounds((lane as PolygonMapComponent).formItem.get('PolygonPoints')?.value).Contains([points[0] / this.ScaleFactor, points[1] / this.ScaleFactor])) {     
                    let barriers = this.editor.ModelEditor.FormArray(lane.formItem, "Barriers");
                    let newBarrierFormGroup = new FormGroup({
                        Id: new FormControl(uuidv4()),
                        ActiveDirection: new FormControl(0),
                        DisplayAngle: new FormControl(0),
                        LocalAddress: new FormControl(null),
                        LocalPort: new FormControl(0),
                        OpenDirection: new FormControl(0),
                        SerialNumber: new FormControl(""),
                        Type: new FormControl("mo24"),
                        LocationPoints: new FormControl(this.CreatePointFromXY(canvasX, canvasY)),
                        EditorLocked: new FormControl(false),
                    });

                    (barriers as FormArray).push(newBarrierFormGroup);

                    var component = new Barrier(this.editor, this.canvas, newBarrierFormGroup, lane.formItem);
                    this.editor.Form.markAsDirty();
                    return component;
                }
            }
        }
        this.editor.toastService.error("Please place your Barrier within a Lane");
        return null;
    }
}