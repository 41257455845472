
    <div *ngFor="let pf of recursiveList; let parentIndex = index;" style="padding:10px">
        <article class="panel">
            <p class="panel-heading">
                {{pf.Name}}
            </p>
            <div *ngFor="let pfs of pf.Settings">
                <a class="panel-block" *ngIf="pfs.Type == 'boolean'">
                    <span class="label">{{pfs.DisplayName}}</span>
                        <span class="value"> 
                            <input type="checkbox" [checked]="pfs.Value" (change)="UpdateSettingItemValue(pfs, $event)" style="vertical-align:middle;"/>
                        </span>
                </a>
                <a class="panel-block" *ngIf="pfs.Type == 'color'">
                    <span class="label">{{pfs.DisplayName}}</span>
                        <span class="value"> 
                            <app-colorpicker [ColorValue]="pfs.Value" (ColorChanged)="UpdateSettingItemValue(pfs, $event)"></app-colorpicker>
                        </span>
                </a>
                <a class="panel-block" *ngIf="pfs.Type == 'media'">
                    <span class="label">{{pfs.DisplayName}}</span>
                        <span class="value"> 
                            <app-mediaupload [MediaId]="pfs.Value" [IsPublic]="true" (MediaIdChange)="UpdateSettingItemValue(pfs, $event)"></app-mediaupload>
                        </span>
                </a>
                <a class="panel-block" *ngIf="pfs.Type == 'currency'">
                    <span class="label">{{pfs.DisplayName}}</span>
                        <span class="value"> 
                            <app-currency-input [InternalValue]="pfs.Value" (ValueChanged)="UpdateSettingItemValue(pfs, $event)"></app-currency-input>
                        </span>
                </a>
                <a class="panel-block" *ngIf="pfs.Type == 'string'">
                    <span class="label">{{pfs.DisplayName}}</span>
                        <span class="value"> 
                            <input type="text" class="input" [value]="pfs.Value" (change)="UpdateSettingItemValue(pfs, $event)" style="vertical-align:middle;"/>
                        </span>
                </a>
                <a class="panel-block" *ngIf="pfs.Type == 'int'">
                    <span class="label">{{pfs.DisplayName}}</span>
                        <span class="value"> 
                            <input type="number" [value]="pfs.Value" (change)="UpdateSettingItemValue(pfs, $event)" class="input" style="vertical-align:middle;"/>
                        </span>
                </a>
                <a class="panel-block" *ngIf="pfs.Type == 'intOptions'">
                    <span class="label">{{pfs.DisplayName}}</span>
                        <span class="value"> 
                            <select class="input"  [(ngModel)]="pfs.Value" (change)="UpdateSettingItemValue(pfs, $event)">
                                <option *ngFor="let permissionlevel of pfs.PermissionLevels" [value]="permissionlevel.Level">{{permissionlevel.Permission}}</option>
                            </select>
                        </span>
                </a>
                <a class="panel-block" *ngIf="pfs.Type == 'stringOptions'">
                    <span class="label">{{pfs.DisplayName}}</span>
                        <span class="value"> 
                            <select class="input" [(ngModel)]="pfs.Value" (change)="UpdateSettingItemValue(pfs, $event)">
                                <option *ngFor="let permissionlevel of pfs.PermissionLevels" [value]="permissionlevel.Description">{{permissionlevel.Permission}}</option>
                            </select>
                        </span>
                </a>
                <a class="panel-block" *ngIf="pfs.Type == 'datetime-local'">
                    <span class="label">{{pfs.DisplayName}}</span>
                        <span class="value"> 
                            <input type="datetime-local" [value]="pfs.Value" (change)="UpdateSettingItemValue(pfs, $event)" class="input" style="vertical-align:middle;"/>
                        </span>
                </a>
                <a class="panel-block" *ngIf="pfs.Type == 'api'">
                    <span class="label">{{pfs.DisplayName}}</span>
                        <span class="value"> 
                            <select class="input" [(ngModel)]="pfs.Value" (change)="UpdateSettingItemValue(pfs, $event)">
                                <option *ngFor="let value of pfs.SelectItems" [value]="value.Id">{{value.Name}}</option>
                            </select>
                        </span>
                </a>     
            </div>
            <app-treenodepanelblock [recursiveList]="pf.Children" (updateSettingValue)="UpdateSettingItem($event)"></app-treenodepanelblock>
        </article>
    </div>
