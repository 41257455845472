import { ChangeDetectorRef, Component, Host, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { Datalistviewcolumn, SelectedViewColumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { DatalistviewComponent } from 'src/app/shared/datalistview/datalistview.component';
import { Busyable } from '../../editors/busyable';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { FormArray, FormBuilder, FormControl, FormGroupDirective } from '@angular/forms';
import { ValetService } from 'src/app/Services/valet.service';

@Component({
  selector: 'app-valetaddonsmodal',
  templateUrl: './valetaddonsmodal.component.html',
  styleUrls: ['./valetaddonsmodal.component.scss']
})
export class ValetAddonsModalComponent extends SimpleModalComponent<any, any>  {
  public apiService !: ApiService;
  public toastService !: ToastrService;
  public orgService !: OrganizationsService;
  public Busyable = new Busyable();
  public addons : any[] = [];
  public selectedAddOns : any[] = [];
  public AddedAddons: any[] = [];

  constructor(apiService: ApiService, toastService: ToastrService, orgService: OrganizationsService, private formBuilder: FormBuilder, public valetService: ValetService) {
    super();
    this.orgService = orgService;
    this.apiService = apiService;
    this.toastService = toastService;
    this.Busyable.Loading();
    this.addons = valetService.SessionAddons;
  }

  ngOnInit() {
  }

  public AddAddon(valetAddonId: any, valetAddonOptionId: any, event: any): void {
    if (event.target.checked) {

      const addon = {
        ValetAddonId: valetAddonId,
        ValetAddonOptionId: valetAddonOptionId,
        Status : "Requested"
      };

      this.AddedAddons.push(addon);
    } 
    else {
      // Remove from form array
      const index = this.findIndex(valetAddonId, valetAddonOptionId);
      if (index !== -1) {
        this.AddedAddons.splice(index, 1);
      }
    }
  }

  public checkIfValetAddonIdExists(valetAddonId: number, valetAddonOptionId: number | null): boolean {
    for (const addon of this.AddedAddons) {
      const addonIdControl = addon.ValetAddonId
      const optionIdControl = addon.ValetAddonOptionId;
      if (valetAddonOptionId === null) {
        // Check for matching item where ValetAddonId matches and ValetAddonOptionId is null
        if (addonIdControl === valetAddonId && optionIdControl === null) {
          return true; // Found a match
        }
      } else {
        // Check for matching item where both ValetAddonId and ValetAddonOptionId match
        if (addonIdControl === valetAddonId && optionIdControl === valetAddonOptionId) {
          return true; // Found a match
        }
      }
    }
    return false; // No match found
  }


  private findIndex(valetAddonId: any, valetAddonOptionId: any): number {
    return this.AddedAddons.findIndex(addon =>
      addon.ValetAddonId === valetAddonId &&
      addon.ValetAddonOptionId === valetAddonOptionId
    );
  }

  confirm() {
    this.result = { Success: true, ItemsToAdd: this.AddedAddons};
    this.close();
  }
}