import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as ace from 'ace-builds';
ace.config.set("basePath", "/scripts/ace");

@Component({
  selector: 'app-codeeditor',
  templateUrl: './codeeditor.component.html',
  styleUrls: ['./codeeditor.component.scss']
})
export class CodeeditorComponent implements OnInit {

  @Input()
  public Mode: any = 'html';

  @Input()
  public Model: any = '';

  @Output()
  public ModelChange = new EventEmitter<string>();

  public StyleEditorOptions: any = { maxLines: 8000, printMargin: false };

  @ViewChild('aceeditor') editor: any;


  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    const aceEditor = ace.edit(this.editor.nativeElement);
  }

  public OnChange(text: any) {
    this.ModelChange.emit(text);
  }

  mediaSelected(event: any) {
    if(event == null) return;
    const aceEditor = ace.edit(this.editor.nativeElement);

    var cursorPosition = aceEditor.getCursorPosition();
    var type = event.split('.').at(-1);
    if(type == 'm4v') type = 'mp4'; // needed otherwise embed doesn't play
    if(['mp4', 'webm'].includes(type)) {
      aceEditor.session.insert(cursorPosition, '<video autoplay muted loop> <source src="{MediaService}/' + event + '" type="video/' + type +'" /> </video>')
    } else {
      aceEditor.session.insert(cursorPosition, '<img src="{MediaService}/' + event + '" />');
    }
  }
}
