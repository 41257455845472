import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import { DocumentEditorComponent } from './documenteditor.component';
import { Style } from './HtmlRenderComponents';
import { DocumentComponentBase } from './Components/DocumentComponentBase';
import { Helpers } from './Helpers/Helpers';

export class DocumentRender {

    public Document !: fabric.Polygon | null;
    public RenderedHtml !: string;

    constructor(public DocumentEditor: DocumentEditorComponent, public documentComponents: DocumentComponentBase[], public canvas: fabric.Canvas) {
        this.RenderDocument();
        documentComponents.forEach((component: DocumentComponentBase) => {
            component.Draw(canvas);
        })
    }

    RenderDocument(){
        if(this.Document){
            this.canvas.remove(this.Document);
            this.Document = null;
        }

        const points = [
          { x: 0 + this.DocumentEditor.offsets.x, y: 0 + this.DocumentEditor.offsets.y },       // Top-left corner
          { x: this.DocumentEditor.Dimensions.width + this.DocumentEditor.offsets.x, y: 0 + this.DocumentEditor.offsets.y },   // Top-right corner
          { x: this.DocumentEditor.Dimensions.width + this.DocumentEditor.offsets.x, y: this.DocumentEditor.Dimensions.height + this.DocumentEditor.offsets.y }, // Bottom-right corner
          { x: 0 + this.DocumentEditor.offsets.x, y: this.DocumentEditor.Dimensions.height + this.DocumentEditor.offsets.y }   // Bottom-left corner
        ];
     
        this.Document = new fabric.Polygon(points, { 
          fill: 'white',
          stroke: 'black',
          strokeWidth: 1,
          opacity: 1,
          originX: 'center', // Set origin to center
          originY: 'center', // Set origin to center
          centeredRotation: true,
          selectable: false,
          hasControls: false,
          strokeUniform: true,
          absolutePositionedScaling: true
        } as fabric.IObjectOptions); 
    
        this.canvas.add(this.Document);
        this.Document.sendToBack();
        this.canvas.renderAll();
    }
    

    public RenderHtml(): string {
        this.RenderedHtml = `<!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=${Helpers.convertPixelsToInches(this.DocumentEditor.Dimensions.width)}in, height=${Helpers.convertPixelsToInches(this.DocumentEditor.Dimensions.height)}in, initial-scale=1.0" />
        </head>
        <body style="width: ${Helpers.convertPixelsToInches(this.DocumentEditor.Dimensions.width)}in; height: ${Helpers.convertPixelsToInches(this.DocumentEditor.Dimensions.height)}in; margin: 0; padding: 0;">`;
    
        // Render content from documentComponents
        if (this.documentComponents.length > 0) {
            for (const component of this.documentComponents) {
                this.RenderedHtml += `\n\t\t${component.RenderHtml().replace(/\n/g, '\n\t\t')}`;
            }
        }
    
        // Close the HTML tags
        this.RenderedHtml += `
        </body>
        </html>`;
    
        return this.RenderedHtml;
    }
}