import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Busyable } from 'src/app/shared/editors/busyable';

@Component({
  selector: 'app-modal-save-button',
  templateUrl: './modal-save-button.component.html',
  styleUrls: ['./modal-save-button.component.scss']
})
export class ModalSaveButtonComponent implements OnInit {

  @Output()
  public OnSave = new EventEmitter<any>();
  @Input()
  public Parent: Busyable | null = null;
  @Input()
  public ButtonType: string = "button";
  @Input()
  public ButtonContext: string = "Save";
  @Input()
  public Icon: string = "check";
  @Input()
  public Disabled: boolean = false;

  @Input()
  public Animate: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  public StopAnimations(){
    this.Animate = false;
  }
  
  public Clicked(evt: any) {
    //check if we are already busy or if the form isn't dirty
    if (this.Animate == true) {
      evt.stopPropagation();
    }
    else {
      this.Animate = true;
      this.OnSave.emit();
    }
  }
}
