import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  animations: [
    trigger('addRemove', [
      transition(':enter', [
        style({ height:'0', opacity: '0' }),
        animate('.3s ease-out', style({ height:'*' })),
        animate('.1s ease-out', style({ opacity: '1'}))
      ]),
      transition(':leave', [
        style({ height:'*', opacity: '1' }),
        animate('.1s ease-out', style({ opacity: '0'})),
        animate('.3s ease-out', style({ height:'0' }))
      ])
    ])
  ]
})
export class FieldComponent implements OnInit {

  @Input()
  public Label : string = '';
  @Input()
  public HasAddons : boolean = false;
  @Input()
  public class : string = "";
  @Input()
  public InputPadding : boolean = false;
  @Input()
  Visible : boolean = true;
  @Input()
  AnimateOnCreate : boolean = false;

  UseAnimation : boolean = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    setTimeout(() => {
      this.UseAnimation = true;
    }, 500);
   }

  ngOnInit(): void {

  }
}
