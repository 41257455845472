import { Component, OnInit } from '@angular/core';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ApiService } from 'src/app/Services/api.service';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-editdiscountcode',
  templateUrl: './editdiscountcode.component.html',
  styleUrls: ['./editdiscountcode.component.scss']
})

export class EditDiscountCodeComponent extends SimpleModalComponent<any, any> implements OnInit {

  constructor(public apiService: ApiService, private toastService: ToastrService) {
      super();
  }

  public ModelId : string | null = null;
  public CodeTypeId : string = "";
  public Busyable: Busyable = new Busyable();
  public DiscountCode: any;

  ngOnInit(): void {
    this.DiscountCode = { Code: "", MaxUses: 0, ActiveStartDateLocal: null, ActiveEndDateLocal: null}

    if(this.ModelId != null){
      this.Busyable.Loading();
      this.apiService.Get("/parking/discounts/" + this.CodeTypeId + '/codes/' + this.ModelId).then(result => {
        this.DiscountCode = result;
        this.Busyable.StopLoading();
      });
    }
    else{
      this.Busyable.Loading();
      this.apiService.Get("/parking/discounts/" + this.CodeTypeId + '/codes/generate').then((result: any) => {
        this.DiscountCode.Code = result.Code;
        this.Busyable.StopLoading();
      }).catch((reason: any) => {
        this.toastService.error(reason.error.Error.Message, "There was an error generating a code for the given template");
        this.Busyable.StopLoading();
      });
    }
  }

  public Save(){
    this.apiService.Post("/parking/discounts/" + this.CodeTypeId + '/codes/', this.DiscountCode).then(result => {
      this.Busyable.StopLoading();
      this.result = true;
      this.close();
    });
  }

  public update(property: any, evt: any){
    this.DiscountCode[property] = evt.target.value;
  }

  public UpdateCounts(){
    // this.apiService.Put("/infrastructure/parkinglots/" + this.ModelId + "/levels/UpdateVehiclesPresent", this.ParkingLevels).then(result => {
    //     this.result = result;
    //     this.close()
    // });
  };
}
