<div>
  <div class="field has-addons" *ngIf="UserId != null">
    <p class="control is-expanded">
        <input class="input is-fullwidth" [value]="User.FirstName + ' ' + User.LastName" disabled>
        <!--<a class="tag">{{ User.FirstName }} {{ User.LastName }}</a>-->
    </p>
    <p class="control">
      <a class="button" (click)="ClearUser()"><i class="fa fa-trash"></i></a>
    </p>
  </div>

  <div class="field">
    <p class="control mb-3">
      <app-userselector #userSelector [(UserId)]="UserId" (UserIdChange)="UserChanged($event)" [AllowCreateUser]="AllowCreateUser"></app-userselector>
    </p>
  </div>
</div>

