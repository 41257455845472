import { Component, OnInit } from '@angular/core';
import { FormArray, UntypedFormGroup } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-editcontrolledarealane',
  templateUrl: './editcontrolledarealane.component.html',
  styleUrls: ['./editcontrolledarealane.component.scss']
})
export class EditcontrolledarealaneComponent extends SimpleModalComponent<any, null> implements OnInit {
  Form !: UntypedFormGroup;
  Gate !: UntypedFormGroup;
  Controllers: any[] = [];
  DisplayControllers: any[] = [];

  public Workflows: any[] = [];
  constructor(private apiService: ApiServiceBase) {
    super();
  }

  ngOnInit(): void {
    this.apiService.Post<any>("infrastructure/localcontrollers/workflows/searches", { PageNumber: 1, PageSize: 99999 }).then(result => {
      this.Workflows = result.Results;
    });

    for (let c of this.Controllers) {
      let controller = { Id: c.value.Id, Name: c.value.Name }
      this.DisplayControllers.push(controller)
    }
  }

  confirm() {
    this.close();
  }

  public WorkflowChanged() {
    let wid = this.Form.get('WorkflowId')?.value;
    let wf = this.Workflows.filter(x => x.Id == wid)[0];
    this.Form.get('WorkflowName')?.setValue(wf.Name);
  }

  public EntryWorkflowChanged() {
    let wid = this.Form.get('EntryWorkflowId')?.value;
    let wf = this.Workflows.filter(x => x.Id == wid)[0];
    this.Form.get('EntryWorkflowName')?.setValue(wf.Name);
  }

  public ExitWorkflowChanged() {
    let wid = this.Form.get('ExitWorkflowId')?.value;
    let wf = this.Workflows.filter(x => x.Id == wid)[0];
    this.Form.get('ExitWorkflowName')?.setValue(wf.Name);
  }
}