<app-loader [Parent]="this"></app-loader>
<div *ngIf="Model != null">
  <app-pageheader [Parent]="this">
    <form-edit-button>Edit</form-edit-button>
    <app-form-delete-button [Parent]="this" [Model]="Model" DeleteUrl="valet/types/" Api="valet" TypeName="ValetType"
    DeleteNavLink="/valet/types"></app-form-delete-button>
  </app-pageheader>
  <div class="columns">
    <div class="column is-6">
      <article class="panel">
        <p class="panel-heading">
          Valet Type Details
        </p>
        <div class="panel-block">
          <span class="label">Id:</span>
          <span class="value">{{Model.Id}}</span>
        </div>
        <div class="panel-block">
          <span class="label">Valet Type Name:</span>
          <span class="value">{{Model.Name}}</span>
        </div>
        <div class="panel-block">
          <span class="label">Icon:</span>
          <span class="value">
            <i [ngClass]="'Icon fa fa-' + Model.IconName" style="width:2em !important" [ngStyle]="{color: '#' + Model.IconColor}"></i>
          </span>
          <span class="value">{{iconService.findIconName(Model.IconName)}} (#{{Model.IconColor}})</span>
        </div>
        <div class="panel-block">
          <span class="label">Is Multi-Session:</span>
          <span class="value">{{(Model.IsMultiSession ? 'True' : 'False')}}</span>
        </div>
        <div class="panel-block" *ngIf="Model.RequiresBillingKey">
          <span class="label">Billing Key Name:</span>
          <span class="value">{{Model.BillingKeyName}}</span>
        </div>
        <div class="panel-block" *ngIf="Model.DefaultParkingRateSetId != null">
          <span class="label">Rate Set</span>
          <span class="value">{{Model.ParkingRateSetName}}</span>
        </div>
      </article>
    </div>
    <div class="column is-6 image-container">
      <img src="../../../../../assets/images/Valet-type.png" alt="FrogOne Valet Type" />
    </div>
  </div>
</div>
<div class="columns">
  <div class="column is-6">
     <div class="card">
      <app-valettypeusageserieschart Title="Usage Today" ChartType="line" Period="today" [ValetTypeId]="this.Model.Id"
      DataType="inflows" [Height]="100"></app-valettypeusageserieschart>
     </div>
  </div>
  <div class="column is-6">
    <div class="card">
     <app-valettypeusageserieschart Title="Usage this Week" ChartType="line" Period="thisweek" [ValetTypeId]="this.Model.Id"
     DataType="inflows" [Height]="100"></app-valettypeusageserieschart>
    </div>
 </div>
</div>
