import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  faIconSet: { class: string, name: string }[] = [
    { class: 'car', name: 'Car' },
    { class: 'car-side', name: 'Car Side' },
    { class: 'truck', name: 'Truck' },
    { class: 'bicycle', name: 'Bicycle' },
    { class: 'motorcycle', name: 'Motorcycle' },
    { class: 'train-subway', name: 'Train' },
    { class: 'bus', name: 'Bus' },
    { class: 'plane', name: 'Plane' },
    { class: 'charging-station', name: 'Charging Station' },
    { class: 'road', name: 'Road' },
    { class: 'road-lock', name: 'Road Lock' },
    { class: 'road-circle-check', name: 'Road Check' },
    { class: 'road-barrier', name: 'Barrier' },
    { class: 'car-tunnel', name: 'Car Tunnel' },
    { class: 'bridge', name: 'Bridge' },
    { class: 'traffic-light', name: 'Traffic Light' },
    { class: 'user', name: 'User' },
    { class: 'check', name: 'Check' },
    { class: 'phone', name: 'Phone' },
    { class: 'star', name: 'Star' },
    { class: 'money-bill', name: 'Money' },
    { class: 'key', name: 'key' },
    { class: 'city', name: 'City' },
    { class: 'person', name: 'Person' },
    { class: 'envelope', name: 'Envelope' },
    { class: 'location-dot', name: 'Location' },
    { class: 'arrow-right', name: 'Right Arrow' },
    { class: 'arrow-left', name: 'Left Arrow' },
    { class: 'arrow-up', name: 'Up Arrow' },
    { class: 'arrow-down', name: 'Down Arrow' },
    { class: 'circle-xmark', name: 'Circle X' },
    { class: 'circle-info', name: 'Circle Info' },
    { class: 'calendar-days', name: 'Calendar' },
    { class: 'bell', name: 'Bell' },
    { class: 'cart-shopping', name: 'Cart' },
    { class: 'bolt', name: 'Bolt' },
    { class: 'circle-user', name: 'Circle User' },
    { class: 'pen', name: 'Pen' },
    { class: 'gift', name: 'Gift' },
    { class: 'film', name: 'Film' },
    { class: 'barcode', name: 'Barcode' },
    { class: 'tag', name: 'Tag' },
    { class: 'book', name: 'Book' },
    { class: 'trash', name: 'Trash' },
    { class: 'rotate-right', name: 'Rotate Right' },
    { class: 'rotate-left', name: 'Rotate Left' },
    { class: 'plus', name: 'Plus' },
    { class: 'minus', name: 'Minus' },
    { class: 'circle-exclamation', name: 'Circle Exclamation' },
    { class: 'handshake', name: 'Handshake' },
    { class: 'business-time', name: 'Business' },
    { class: 'users', name: 'Users' },
    { class: 'square-parking', name: 'Parking' },
    { class: 'suitcase', name: 'Suitcase' },
    { class: 'hotel', name: 'Hotel' },
    { class: 'clock', name: 'Clock' },
    { class: 'repeat', name: 'Repeat' },
    { class: 'hourglass-half', name: 'Hourglass'},
    { class: 'soap', name: 'Soap'},
    { class: 'tire', name: 'Car Tire'},
    { class: 'tire-flat', name: 'Flat Tire'},
    { class: 'vacuum', name: 'Vacuum'},
    { class: 'sparkle', name: 'Sparkle'},
    { class: 'badge-check', name: 'Badge Check'},
    { class: 'traffic-cone', name: 'Traffic Cone'},
    { class: 'brush', name: 'Brush'},
    { class: 'oil-can', name: 'Oil Can'},
    { class: 'gas-pump', name: 'Fuel'},
    { class: 'magnifying-glass', name: 'Inspection'},
    { class: 'tree', name: 'Tree'},
  ];

  constructor() { }

  getIcons(): { class: string, name: string }[] {
    return this.faIconSet;
  }

  public findIconName(icon: string) {
    var item = this.faIconSet.filter((x: any) => x.class == icon)
    if(item == null || item.length == 0){
      return null;
    }
    return item[0].name;
  }

  public getIconByName(icon: string) {
    var item = this.faIconSet.filter((x: any) => x.name == icon)
    if(item == null || item.length == 0){
      return null;
    }
    return item[0].class;
  }

}
