import { LevelEditorComponent } from "../../../leveleditor.component";
import { MapItemTool } from "./MapItemTool";

export class BusinessTool extends MapItemTool{
    public override ItemWidth: number = 60;
    public override ItemHeight: number = 60;
    public override Type = "Business";
    public override OutlineColor = "555555";
    public override Opacity =  1;
    public override FillColor =  "e8e8e8";
    public override OutlineThickness = 2;
    constructor(editor: LevelEditorComponent, canvas: fabric.Canvas){
        super(editor, canvas);
    }
}
