import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { ChartData, ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { ChartDataResponse, ChartResponse } from '../dashboardchart/ChartResponse';
import { GenericChartComponent } from '../generic-chart/generic-chart.component';

interface DateRange {
  start: string;
  end: string;
}

@Component({
  selector: 'app-generic-pie-chart',
  templateUrl: './generic-pie-chart.component.html',
  styleUrls: ['./generic-pie-chart.component.scss']
})
export class GenericPieChartComponent extends GenericChartComponent {
    @Input() height: number = 400;
    @Input() chartData !: ChartDataResponse;
    @Input() options?: ChartOptions;
    @Input() title: string = '';
    @Input() interactive: boolean = true;
    @Input() tooltipOptions: any = {};

    public chartType : ChartType = 'pie';

    private defaultColors = [
      '#FF9100', '#E57373', '#00897B', '#F06292', '#4A148C', '#FFD740', '#1565C0', '#F4FF81', '#FF80AB', '#90CAF9',
      '#C62828', '#FFCA28', '#BA68C8', '#FF9F40', '#81C784', '#757575', '#FF5252', '#0D47A1', '#F50057', '#DCE775',
      '#FB8C00', '#69F0AE', '#FF99CC', '#607D8B', '#1DE9B6', '#FF6384', '#CE93D8', '#FFB74D', '#36A2EB', '#424242',
      '#4BC0C0', '#F57F17', '#E040FB', '#26A69A', '#FFC400', '#B71C1C', '#2979FF', '#9E9E9E', '#AFB42B', '#80CBC4',
      '#FF1744', '#1B5E20', '#BDBDBD', '#F48FB1', '#FFE57F', '#C9CBCF', '#D500F9', '#90A4AE', '#4DB6AC', '#E91E63',
      '#FFA726', '#616161', '#F44336', '#2E7D32', '#64FFDA', '#9966FF', '#FFC107', '#43A047', '#757575', '#EEFF41',
      '#7B1FA2', '#FF8A80', '#00695C', '#E65100', '#FFD54F', '#B0BEC5', '#6A1B9A', '#A7FFEB', '#82B1FF', '#C0CA33',
      '#EC407A', '#448AFF', '#00796B', '#2196F3', '#F9A825', '#64B5F6', '#D81B60', '#009688', '#EA80FC', '#FF4081',
      '#4CAF50', '#EF9A9A', '#FFCD56', '#C2185B', '#FFD180', '#9C27B0', '#1E88E5', '#66FF99', '#A5D6A7', '#E53935',
      '#B9F6CA', '#FFAB40', '#00E676', '#FDD835', '#FF9800', '#E6EE9C', '#9E9D24', '#C6FF00', '#CDDC39', '#4BC0C0'
    ];

  ngOnChanges(changes: SimpleChanges) {
    if (changes['chartData']) {
      this.setupChartOptions();
      this.processChartData();
      this.updateChart();
    }
  }

  public override updateSingleValue(datasetIndex: number, valueIndex: number, newValue: number) {
    if (this.chart && this.processedChartData?.datasets[datasetIndex]) {
      this.processedChartData.datasets[datasetIndex].data[valueIndex] = newValue;
      this.chart.update('active'); // 'active' provides a smooth animation
    }
  }

  public override processChartData() {
    if(this.chartData && this.chartData.datasets && this.chartData.datasets.length !== 0){
      const dataset = this.chartData.datasets[0];
      this.processedChartData = {
        labels: this.chartData.labels,
        datasets: [{
          data: dataset.data.map((value: any) => Number(value)),
          backgroundColor: dataset.data.map((_, index) => 
            dataset.backgroundColor?.[index] ?? this.defaultColors[index % this.defaultColors.length]
          ),
          borderColor: dataset.data.map((_, index) => 
            dataset.borderColor?.[index] ?? '#ffffff'
          ),
          hoverBackgroundColor: dataset.data.map((_, index) => 
            dataset.backgroundColor?.[index] ?? this.defaultColors[index % this.defaultColors.length]
          )
        }]
      };
    }
  }

  protected override setupChartOptions() {
    this.chartOptions = this.createChartOptions();
    // Merge with any custom options provided
    this.chartOptions = { ...this.chartOptions, ...this.options };
  }

  protected override createChartOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
          onClick: this.interactive ? undefined : () => {},
          labels: {
            padding: 20,
            boxWidth: 12,
            font: {
              size: 12
            },
            textAlign: 'left',
            usePointStyle: true
          }
        },
        tooltip: {
          ...this.tooltipOptions,
          usePointStyle: true,
          boxWidth: 10,
          boxHeight: 10,
          callbacks: {
            labelColor: function(context) {
              return {
                borderColor: context.dataset.borderColor,
                backgroundColor: context.dataset.backgroundColor
              };
            }
          }
        }
      }
    };
  }
}