<div style="position: relative">
    <app-loader [Parent]="this"></app-loader>
    <div [ngStyle]="{ 'height' : Height + 'px'}" style="padding: 1em; width:100%;">
        <div class="columns" style="height: 100%;">
            <div class="column is-9 has-text-centered" style="padding: 2em; padding-top: 12%;">
                <div>
                    <div style="font-size: 6em; ">
                        {{OccupiedSpaces != null ? Percent : ''}}%
                    </div>
                    <div>
                        <div>OCCUPIED SPACES</div>
                        <div style="font-size: 2em;">{{OccupiedSpaces}}/{{TotalSpaces}}</div>
                    </div>
                </div>
            </div>
            <div class="column is-3">
                <div *ngFor="let i of GaugeBoxes"
                    style="border: solid 1px #aaa; border-radius: 3px; box-sizing: border-box;"
                    [ngStyle]="{ 'height': GaugePercentPerTick + '%', 'background': i }">
                </div>
            </div>
        </div>
    </div>
</div>