<app-loader [Parent]="this"></app-loader>
<div *ngIf="Model != null">
  <app-pageheader [Parent]="this">
    <form-edit-button>Edit</form-edit-button>
  </app-pageheader>
  <div class="columns">
    <div class="column is-6">
      <article class="panel">
        <p class="panel-heading">
          Discount Type Details
        </p>
        <div class="panel-block" style="display: block;">
          <app-field Label="Id">
            <div class="control">
              <p class="input">{{Model.Id}}</p>
            </div>
          </app-field>
          <app-field Label="Discount Name">
            <div class="control">
              <p class="input">{{Model.Name}}</p>
            </div>
          </app-field>
          <app-field [HasAddons]="true" Label="Discount">
            <div class="control is-expanded">
              <p class="input">{{Model.Description}}</p>
            </div>
          </app-field>
          <app-field Label="Active Dates">
            <div class="control">
              <p class="input">
                <app-daterangedisplay FieldName="Active Dates"
                  [StartDate]="Model.ActiveStartDateLocalFriendlyString"
                  [EndDate]="Model.ActiveEndDateLocalFriendlyString"></app-daterangedisplay>
              </p>
            </div>
          </app-field>
          <app-field Label="Auto Generate Codes">
            <div class="control">
                <app-toggleswitch [(ngModel)]="Model.AutoGenerateCodes" disabled></app-toggleswitch>
            </div>
          </app-field>
          <app-field Label="Generated Code Start Time" *ngIf="Model.AutoGenerateCodes && Model.GenerationTime != null">
            <div class="control">
              <p class="input">{{Model.GenerationTime}}</p>
            </div>
          </app-field>
          <app-field [HasAddons]="true" Label="Generation Frequency" *ngIf="Model.AutoGenerateCodes">
            <div class="control">
              <p class="input">Every {{Model.GenerationFrequency}} {{Model.GenerationFrequencyUnitString}}</p>
            </div>
          </app-field>
          <app-field [HasAddons]="true" Label="Default Code Valid Duration" *ngIf="Model.DefaultCodeValidTime != null">
            <div class="control">
              <p class="input">{{Model.DefaultCodeValidTime}} {{Model.DefaultCodeValidTimeUnitString}}</p>
            </div>
          </app-field>
          <app-field Label="Code Template">
            <div class="control">
              <p class="input">{{Model.CodeTemplate}}</p>
            </div>
          </app-field>
        </div>
      </article>
    </div>
    <div class="column is-6 image-container">
      <img src="../../../../../assets/images/frogcoupon.svg" alt="FrogOne Coupons" />
    </div>
  </div>
  <div class="columns">
    <div class="column is-full">
      <article class="panel">
        <p class="panel-heading">
          Discount Codes
          <actionbutton Icon="plus" ButtonType="primary" (PerformAction)="CreateDiscountCode()" >Add Discount Code</actionbutton>
        </p>
        <div class="panel-block" style="display: block;">
          <app-datalistview #DataList [Columns]="Columns" [ShowMap]="false" [ApiUrl]="ApiUrl" [AllowEdit]="false" [AllowDropdownActions]="true" [DropdownActions]="Actions">
            <app-filter-set>
              <div app-search-filter></div>
              <div app-discount-filter></div>
              <div app-search-button></div>
            </app-filter-set>
          </app-datalistview>
        </div>
      </article>
    </div>
  </div>
</div>
<div class="columns">
  <div class="column is-12">
     <div class="card">
      <app-discounttypeusageserieschart Title="Discount Usage" ChartType="line" Period="thisweek" [DiscountTypeId]="this.Model.Id"
      DataType="inflows" [Height]="50"></app-discounttypeusageserieschart>
     </div>
  </div>
</div>
