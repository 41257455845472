<div class="dropdown" [ngClass]="{ 'is-active' : ShowIconDropdown }" (click)="ShowIconDropdown = !ShowIconDropdown">
    <div class="dropdown-trigger">
      <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
        <span>Change Icon</span>
        <span class="icon is-small">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </button>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
      <div class="dropdown-content icondropdown">
        <input type="text" class="input" placeholder="Search icons" [(ngModel)]="searchQuery" (click)="preventDropdownClose($event)" (input)="filterIcons()">
        <a href="#" class="dropdown-item" *ngFor="let x of filteredIcons" (click)="handleItemClick(x)">
          <span class="icon is-small">
            <i [ngClass]="'fa fa-' + x.class"></i>
          </span>
          <span>{{x.name}}</span>
        </a>
      </div>
    </div>
  </div>
  