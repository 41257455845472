import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { Datalistrequestfilter } from 'src/app/shared/datalistview/request/datalistrequestfilter';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { UsermagicmoneyComponent } from '../modals/usermagicmoney/usermagicmoney.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { Toast, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent extends Busyable implements OnInit {
  public ModelId: any;
  public Filters: any;
  public Model: any;
  public ShowAlertsDropdown: boolean = false;
  public ShowPermits: boolean = false;
  public ShowSessions: boolean = false;
  public permissionService: PermissionsService;
  public SessionColumns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Status", "Status"),
    new Datalistviewcolumn("Plate", "PlateNumbers"),
    new Datalistviewcolumn("Entry Lane", "EntryLaneName"),
    new Datalistviewcolumn("Entry Date", "EntryDateLocalString"),
    new Datalistviewcolumn("Exit Gate", "ExitLaneName"),
    new Datalistviewcolumn("Exit Date", "ExitDateLocalString"),
    new Datalistviewcolumn("Duration", "DurationAsString"),
    new Datalistviewcolumn("Rate", "AccessMethodRateSetName"),
    new Datalistviewcolumn("Paid", "TotalPaid"),
    new Datalistviewcolumn("Controlled Area", "ControlledAreaName"),
  ];

  public PermitColumns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "ParkingPermitTypeName"),
    new Datalistviewcolumn("Status", "StatusString"),
    new Datalistviewcolumn("Uses", "TimesUsed"),
    new Datalistviewcolumn("Active from", "ActiveStartDateLocalFriendlyString"),
    new Datalistviewcolumn("Active to", "ActiveEndDateLocalFriendlyString"),
  ];

  public VehicleColumns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Plate", "PlateNumber"),
    new Datalistviewcolumn("Description", "Description"),
  ];
  
  constructor(private apiService: ApiServiceBase, private parentRoute: ActivatedRoute, permissionService: PermissionsService, private modalService: SimpleModalService, public toastService: ToastrService) {
    super();
    this.permissionService = permissionService;
  }

  ngOnInit(): void {
    this.Loading();
    this.parentRoute.params.subscribe(params => { this.ModelId = params['id']; });
    this.apiService.Get<any>("organization/users/" + this.ModelId).then(result => {
      this.Model = result;
      this.Filters = [
        new Datalistrequestfilter("UserId", "==", this.Model.UserId),
      ];
      this.StopLoading();
    });

    this.permissionService.CheckAdminPermission("PermitsFeature", 100).then(x => {
      this.ShowPermits = x;
    });

    this.permissionService.CheckAdminPermission("ParkingFeature", 100).then(x => {
      this.ShowSessions = x;
    });

  }

  public SendActivationEmail(){
    this.apiService.Post("organization/users/" + this.ModelId + "/resendactivation", null).then(result => {
      this.toastService.success("Activation Email Sent to User");
    });
  }

  public AddMagicMoney() {
    this.modalService.addModal(UsermagicmoneyComponent, { User: { OrgUserId: this.Model['Id'] } }, { closeOnEscape: true, closeOnClickOutside: true })
      .subscribe((result: any) => {
        if (result != null) {
          this.Model.MagicMoneyAccountBalance += result.MagicMoney;
        }
      })
  }
}
