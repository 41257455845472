<div class="field" style="width:100%; display: flex;">
  <div *ngIf="UserId == null" class="ng-autocomplete position" (keypress)="KeyPress($event)" style="width:100%;">
    <ng-autocomplete 
      [data]="SearchResults"
      [searchKeyword]="SearchOn"
      placeholder="Select user"
      (selected)='ItemSelected($event)'
      (inputChanged)='SearchChanged($event)'
      [itemTemplate]="itemTemplate"
      [notFoundTemplate]="notFoundTemplate"
      [debounceTime]="300"
      [isLoading]="IsLoading"
      (inputCleared)="Cleared()"
      >
    </ng-autocomplete>
  </div>
  <div class="control" *ngIf="AllowCreateUser && UserId == null">
    <a class="button is-primary" (click)="addUser()">Add New User</a>
  </div>

  <ng-template #itemTemplate let-item>
      <a [innerHTML]="item.FirstName + ' ' + item.LastName + ' (' + item.Email + ')'"></a>
  </ng-template>
  
  <ng-template #notFoundTemplate let-notFound>
  <div [innerHTML]="notFound"></div>
  </ng-template>
</div>

