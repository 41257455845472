import { Component, OnInit } from '@angular/core';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.scss']
})
export class EmailsComponent implements OnInit {
  
  constructor() { 
  }

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "EmailName"),
    new Datalistviewcolumn("Source", "Source"),
    new Datalistviewcolumn("Type", "TemplateType"),
    new Datalistviewcolumn("Use Status", "Enabled")
  ];

  ngOnInit(): void {
  }


}
