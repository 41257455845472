<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{(ModelId == null ? 'Create' : 'Edit')}} Discount Code</p>
      </header>
      <section class="modal-card-body" style="min-width: 800px; max-height: 80vh; overflow-y: auto;">
        <app-loader [Parent]="Busyable"></app-loader>
        <app-field Label="Discount Code">
          <div class="control">
            <input class="input" *ngIf="DiscountCode != null" [value]="DiscountCode.Code" type="text" (change)="update('Code', $event)" placeholder="Enter the discount code name">
          </div>
        </app-field>
        <app-field Label="Maximum Uses">
          <div class="control">
            <input class="input" *ngIf="DiscountCode != null" [value]="DiscountCode.MaxUses" type="number" (change)="update('MaxUses', $event)" placeholder="maximum number of uses">
          </div>
        </app-field>
        <app-field Label="Active Start Date">
          <div class="control">
            <input class="input" *ngIf="DiscountCode != null" [value]="DiscountCode.ActiveStartDateLocal | date:'yyyy-MM-ddTHH:mm'" (change)="update('ActiveStartDateLocal', $event)" type="datetime-local">
          </div>
        </app-field>
        <app-field Label="Active End Date">
          <div class="control">
            <input class="input" *ngIf="DiscountCode != null" [value]="DiscountCode.ActiveEndDateLocal | date:'yyyy-MM-ddTHH:mm'" (change)="update('ActiveEndDateLocal', $event)" type="datetime-local">
          </div>
        </app-field>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary" (click)="Save()">Save</button>
      </footer>
    </div>
  </div>