import { EditcontrolledareatrafficlightComponent } from "src/app/features/parking/accesscontrol/controlledareas/edit/editcontrolledareatrafficlight/editcontrolledareatrafficlight.component";
import { LevelEditorComponent } from "../../leveleditor.component";
import { PointMapComponent } from "./PointMapComponent";

export class TrafficLight extends PointMapComponent{
    public override ClassName: string = "TrafficLight";
    public override ImageUrl: string = "/assets/leveleditor/trafficlight.png";
    public override ImageScale = 0.05;
    public override RequiresParent: boolean = true;
    public override ParentClassName: string = "Lane";
    
    constructor(leveleditorcomponent: LevelEditorComponent, canvas: any, formItem: any, parentFormItem: any, forceClone: boolean = false) {
        super(leveleditorcomponent, canvas, formItem, parentFormItem, forceClone);
        this.AddFabricItem();
    }

    public override Clone(formitem: any){
        return new TrafficLight(this.leveleditorcomponent, this.canvas, formitem, this.parentFormItem, true);
    }

    public override ShowModal(): void {
        this.leveleditorcomponent.modalService.addModal(EditcontrolledareatrafficlightComponent, { Form: this.formItem })
        .subscribe((result) => {});
    }
}
