import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrIconClasses, ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { FeaturesService } from 'src/app/Services/features.service';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { Busyable } from 'src/app/shared/editors/busyable';
import { Deviceicons } from 'src/app/util/deviceicons';
import { ReconciliationDataModalComponent } from './datamodal/datamodal.component';

@Component({
  selector: 'app-reconciliationtool',
  templateUrl: './reconciliationtool.component.html',
  styleUrls: ['./reconciliationtool.component.scss']
})
export class ReconciliationToolComponent extends Busyable implements OnInit {

  TotalToReconcile: number | null = null;
  ActionItems: any[] = [];

  constructor(private apiService : ApiService, public orgService: OrganizationsService, private toast : ToastrService, private parentRoute: ActivatedRoute, private router: Router, private modalService: SimpleModalService) { super(); }

  ngOnInit(): void {
    this.Loading();
    this.apiService.Get<any>("accounting/reconciliation/reconcile/outstanding").then(result => {
      this.TotalToReconcile = result.TotalToReconcile;
      this.ActionItems = result.ActionableItems;
      this.StopLoading();
    });
  }

  doAction(action: any) {
    if(action.RequestedFields?.length > 0) {
      this.modalService.addModal(ReconciliationDataModalComponent, {Row: action}).subscribe((result) => {
        if(result) {
          this.submitAction(result)
        }
      });
    }
    else {
      this.submitAction(action);
    }
  }

  ignoreIssue(providerStatementRowId: string, providerStatementId: string) {
    let fauxAction = {
      RequestedFields: ["IgnoreReason"],
      Name: "IgnoreIssue",
      ActionValues: {
        StatementId: providerStatementId,
        StatementRowId: providerStatementRowId
      },
      RequestedValues: {}
    }
    this.modalService.addModal(ReconciliationDataModalComponent, {Row: fauxAction}).subscribe((result) => {
      if(result) {
        this.submitAction(result);
      }
    })
  }

  private submitAction(action: any) {
    this.Loading();
    this.apiService.Post<any>("accounting/reconciliation/reconcile/outstanding", action).then(result => {
      this.TotalToReconcile = result.TotalToReconcile;
      this.ActionItems = result.ActionableItems;
      this.toast.success("Action performed successfully");
      this.StopLoading();
    }).catch(result => {
      this.toast.error(result.error.Error.Message, "Failed to do the requested action");
      this.StopLoading();
    }).finally(() => this.StopLoading());
  }

}
