import { Component, OnInit } from '@angular/core';
import { ColorDisplaycolumn, Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-pricerules',
  templateUrl: './pricerules.component.html',
  styleUrls: ['./pricerules.component.scss']
})
export class PricerulesComponent implements OnInit {

  constructor() { }

  public Columns : Array<Datalistviewcolumn> = [
    new ColorDisplaycolumn("Color", "Color", "5em"),
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Description", "Description"),
    new Datalistviewcolumn("Type", "RateType")
  ];

  ngOnInit(): void {
  }

}
