<div class="tabs is-boxed">
    <ul>
        <li *ngFor="let tab of tabs; let i = index" (click)="selectTab(tab)" [class.is-active]="tab.Active" class="tab">
            <a>
                <span>{{tab.Title}}</span>
                <span *ngIf="AllowDeleteRequest" class="fa fa-trash inserticon" (click)="DeleteTab(i)"></span>
                <span *ngIf="AllowInsertRequest && i != tabs.length-1" class="fa fa-plus inserticon" (click)="Insert(i)"></span>
            </a>
        </li>
        <li class="tab" *ngIf="AllowAddRequest" (click)="AddTab()">
            <a><span class="icon"><i class="fa fa-plus"></i></span><span>Add</span></a>
        </li>
    </ul>
</div>
<div class="tab-body" [ngStyle]="{ 'height': HeightPercent + '%'}">
    <ng-content></ng-content>
</div>
