import { Component, OnInit, Type } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { Datalistrequestfilter } from 'src/app/shared/datalistview/request/datalistrequestfilter';
import { Busyable } from 'src/app/shared/editors/busyable';
import { StructureViewerLayer } from 'src/app/shared/structureviewermaps/structureviewerlayers';
import { hardwaremessagemodalComponent } from '../../../shared/hardwaremessagemodal/hardwaremessagemodal.component';

@Component({
  selector: 'app-carcounter',
  templateUrl: './carcounter.component.html',
  styleUrls: ['./carcounter.component.scss']
})
export class CarCounterComponent extends Busyable implements OnInit {
  public ModelId: any;
  public Filters: Array<Datalistrequestfilter> = [];
  public Model: any;
  public HardwareModel: any | null = null;
  public ParkingLots: any[] | null = null;

  public HardwareMessageType: Type<any> = hardwaremessagemodalComponent;
  
  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Id", "Id"),
    new Datalistviewcolumn("HID", "ChipId"),
    new Datalistviewcolumn("Gateway", "GatewayChipId"),
    new Datalistviewcolumn("Message", "MessageVersion"),
    new Datalistviewcolumn("Payload", "PayloadVersion"),
    new Datalistviewcolumn("Occurred", "OccurredOnLocalFriendlyString"),
    new Datalistviewcolumn("Sent", "SentOnLocalFriendlyString"),
    new Datalistviewcolumn("Processed", "ProcessedOnLocalFriendlyString"),
  ];

  public StructureViewerLayers: StructureViewerLayer[] = [
    new StructureViewerLayer("ParkingLevel"), 
    new StructureViewerLayer("ParkingSpace"), 
    new StructureViewerLayer("SignConfiguration", "Signs")
  ]

  constructor(private apiService: ApiServiceBase, private parentRoute: ActivatedRoute) {
    super();
    apiService.Get<any>("/infrastructure/parkinglots/summary").then(result => {
      this.ParkingLots = result;
    })
  }

  ngOnInit(): void {
    this.Loading();
    this.parentRoute.params.subscribe(params => { this.ModelId = params['id']; });
    this.apiService.Get<any>("infrastructure/carcounterconfigurations/" + this.ModelId).then(result => {
      this.Model = result;
      if (this.Model.Device != null){
        this.HardwareModel = this.Model.Device;
      }
      if(this.HardwareModel != null){
        if (this.HardwareModel.LastCheckedInOn != null) {
          this.HardwareModel.GlobalLastCheckIn = this.HardwareModel.LastCheckedInOn;
        }
        if (this.HardwareModel.LastNonCarCheckInOn != null && (this.HardwareModel.GlobalLastCheckIn == '-' || this.HardwareModel.LastNonCarCheckInOn > this.HardwareModel.GlobalLastCheckIn)) {
          this.HardwareModel.GlobalLastCheckIn = this.HardwareModel.LastNonCarCheckInOn;
        }
      }
      this.Model.CarCounterAssignments.sort((a :any, b:any) => { 
        if (a.DateCreated < b.DateCreated) return 1;  
        else if (a.DateCreated > b.DateCreated) return -1;
        else return 0;
      })
      this.CreateDetailsData();
      this.StopLoading();
    });
  }

  CreateDetailsData(): void {
    this.Model.DayTime = this.Model.StartOfDayTime + ' - ' + this.Model.EndOfDayTime;
    this.Model.TotalSleepTime = this.Model.SleepTime + ' - ' + this.Model.WakeUpTime;
  }

  GetLotName(lotId: number) {
      const lot = this.ParkingLots?.find(l => l.Id === lotId);
      return lot.Name;
  }

  GetLevelName(lotId: number, levelId: number) {
      const lot = this.ParkingLots?.find(l => l.Id === lotId);
      if (lot) {
        const level = lot.Levels.find((lv: any) => lv.Id === levelId);
        if(level){
          return level.Name;
        }
        return null;
      }
  }
}
