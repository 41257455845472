import { AfterContentInit, Component, ContentChild, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Datalistrequestfilter } from '../../request/datalistrequestfilter';
import { AdminFilterComponent } from '../admin-filter/admin-filter.component';
import { AdminRoleFilterComponent } from '../admin-role-filter/admin-role-filter.component';
import { DateFilterComponent } from '../date-filter/date-filter.component';
import { EnabledFilterComponent } from '../enabled-filter/enabled-filter.component';
import { IFilter, isFilterFilters, isSearchTermFilter, isSubsetFilter } from '../ifilter';
import { PropertyFilterComponent } from '../property-filter/property-filter.component';
import { RatesFilterComponent } from '../rates-filter/rates-filter.component';
import { SearchButtonComponent } from '../search-button/search-button.component';
import { SearchFilterComponent } from '../search-filter/search-filter.component';
import { SubsetFilterComponent } from '../subset-filter/subset-filter.component';
import { PermitsFilterComponent } from '../permits-filter/permits-filter.component';
import { PermitTypesFilterComponent } from '../permittypes-filter/permittypes-filter.component';
import { DiscountFilterComponent } from '../discount-filter/discount-filter.component';
import { ValetTypesFilterComponent } from '../valettypes-filter/valettypes-filter.component';

@Component({
  selector: 'app-filter-set',
  templateUrl: './filter-set.component.html',
  styleUrls: ['./filter-set.component.scss']
})
export class FilterSetComponent implements AfterContentInit, OnInit {

  @Input()
  public title: string | undefined;
  
  @Input()
  public subSection = false;

  @Output()
  public applySearch: EventEmitter<any> = new EventEmitter();

  private _queryParamPrefix: string = '';

  @Input()
  set QueryParamPrefix(value: string)  {
    this._queryParamPrefix = value;
    this.filters.forEach((filter) => filter.QueryParamPrefix = value);
  }

  get QueryParamPrefix() {
    return this._queryParamPrefix;
  }

  constructor() { }
  ngOnInit(): void {
    
  }


  search() {
    this.filters = [];
    this.loadAllFilters(this);
    console.log("raise search from filter set");

    this.applySearch.emit(this.getFilterValues());
  }

  ngAfterContentInit(): void {
    console.log("After content init");
    if (this.SearchButton != undefined) {
      console.log("Sub search from filter set");

      this.SearchButton.applySearch.subscribe(() => {
        this.search();
      });
    }
    this.loadAllFilters(this);
  }

  loadAllFilters(filterSet: FilterSetComponent) {
    if (filterSet.DateFilter != undefined) {

      console.log("filter set adding datefilers");
      this.filters.push(filterSet.DateFilter);
    }

    if (filterSet.RatesFilter != undefined) {
      console.log("filter set adding ratesfilers");
      this.filters.push(filterSet.RatesFilter);
    }

    if (filterSet.EnabledFilter != undefined) {
      console.log("filter set adding enabledfilters");
      this.filters.push(filterSet.EnabledFilter);
    }

    if (filterSet.SearchTermFilter != undefined) {
      console.log("adding search term");
      this.filters.push(filterSet.SearchTermFilter)
    }

    if (filterSet.PermitsFilter != undefined) {
      console.log("adding permit filter term");
      this.filters.push(filterSet.PermitsFilter)
    }

    if (filterSet.PropertyFilter != undefined) {
      console.log("adding property filter");
      this.filters.push(filterSet.PropertyFilter)
    }

    if (filterSet.SubSetFilter != undefined) {
      console.log("adding subet term");
      this.filters.push(filterSet.SubSetFilter)
    }

    if (filterSet.AdminFilter != undefined) {
      console.log("adding admin filter");
      this.filters.push(filterSet.AdminFilter);
    }

    if (filterSet.PermitTypesFilter != undefined) {
      console.log("adding permit type filter");
      this.filters.push(filterSet.PermitTypesFilter);
    }

    if (filterSet.AdminRoleFilter != undefined) {
      console.log("adding admin role filter");
      this.filters.push(filterSet.AdminRoleFilter);
    }

    if (filterSet.DiscountCodeFilter != undefined) {
      console.log("adding Discount code filter");
      this.filters.push(filterSet.DiscountCodeFilter);
    }

    if (filterSet.ValetTypesFilter != undefined) {
      console.log("adding Valet Type filter");
      this.filters.push(filterSet.ValetTypesFilter);
    }
  }

  public Filter = {
    PageNumber: 1,
    PageSize: 15,
    SearchTerm: "",
    Subset: "",
    Filters: [] as Array<Datalistrequestfilter>,
    OrderByProperty: "",
    Ascending: false
  };
  
  private filters: IFilter[] = [];

  @ContentChild(DateFilterComponent)
  public DateFilter?: DateFilterComponent;

  @ContentChild(EnabledFilterComponent)
  public EnabledFilter?: EnabledFilterComponent;

  @ContentChild(RatesFilterComponent)
  public RatesFilter?: RatesFilterComponent;

  @ContentChild(SearchFilterComponent)
  public SearchTermFilter?: SearchFilterComponent;

  @ContentChild(SubsetFilterComponent)
  public SubSetFilter?: SubsetFilterComponent;

  @ContentChild(AdminFilterComponent)
  public AdminFilter?: AdminFilterComponent;

  @ContentChild(PermitsFilterComponent)
  public PermitsFilter?: PermitsFilterComponent;

  @ContentChild(PermitTypesFilterComponent)
  public PermitTypesFilter?: PermitTypesFilterComponent;

  @ContentChild(AdminRoleFilterComponent)
  public AdminRoleFilter?: AdminRoleFilterComponent;

  @ContentChild(FilterSetComponent)
  public AdvancedFilterSet?: FilterSetComponent;

  @ContentChild(SearchButtonComponent)
  public SearchButton?: SearchButtonComponent;

  @ContentChild(PropertyFilterComponent)
  public PropertyFilter?: PropertyFilterComponent;

  @ContentChild(DiscountFilterComponent)
  public DiscountCodeFilter? : DiscountFilterComponent;

  @ContentChild(ValetTypesFilterComponent)
  public ValetTypesFilter? : ValetTypesFilterComponent;

  public getFilterValues(fromLoad: boolean = false, 
    params: { [key: string]: string; } | undefined = undefined, 
    paramPrefix: string = "",
    delayedSearchFields : string[] = []) {
    this.Filter.Filters = [];

    if(fromLoad && params != undefined) {
      var dateA = true;
      this.filters.forEach(element => {
        element.QueryParamPrefix = this.QueryParamPrefix;
        if (isSearchTermFilter(element)) {
          this.Filter.SearchTerm = params[paramPrefix + "searchTerm"] ?? "";
        }
        // if (isSubsetFilter(element)) {
        //   this.Filter.Subset = element.Subset.trim();
        // }
        if (isFilterFilters(element)) {
          if(element.FilterName == "dates"){
            this.ParseFromUri(params, paramPrefix, element.FilterName + (dateA ? "s" : "e"));
            dateA = !dateA;
          }
          this.ParseFromUri(params, paramPrefix, element.FilterName);
        }
      });

      delayedSearchFields.forEach((element) => {
        this.ParseFromUri(params, paramPrefix, element);
      });
    }
    else {
      this.filters.forEach(element => {
        if (isSearchTermFilter(element)) {
          this.Filter.SearchTerm = element.SearchTerm.trim();
        }
        if (isSubsetFilter(element)) {
          this.Filter.Subset = element.Subset.trim();
        }
        if (isFilterFilters(element)) {
          this.Filter.Filters = this.Filter.Filters.concat(element.Filters);
        }
      });
    }


    return this.Filter;
  }

  private ParseFromUri(params: { [key: string]: string; }, 
    paramPrefix: string = "", 
    elementName: string) {
      var vals = decodeURIComponent(params[paramPrefix + elementName]).split(",");
      var prop = vals[0];
      var comp = vals[1];
      var val = vals[2];
      if(prop != undefined && comp != undefined && val != undefined) {
        this.Filter.Filters.push(new Datalistrequestfilter(prop, comp, val, elementName));
      }
  }
}
