<app-loader [Parent]="this"></app-loader>
<div *ngIf="this.Model != null">
    <app-pageheader [Parent]="this">
        <app-form-delete-button [Parent]="this" [Model]="Model" DeleteUrl="accounting/reconciliation/statements/" [DeleteCheck]="false"
        [Name]="'Statement ' + this.Model.Id" DeleteNavLink="/revenue/reconciliation/statements"></app-form-delete-button>
    </app-pageheader>
    <!-- <app-validationsummary [Form]="Form"></app-validationsummary> -->
    <div class="columns">
        <div class="column is-half">
            <article class="panel" *ngIf="this.Model != null">
                <p class="panel-heading">Statement <span class="has-bold-text">#{{this.Model.Id}}</span></p>
                <div class="panel-block">
                    <span class="label">Uploaded File</span>
                    <span class="value">{{Model.FileName}}</span>
                </div>
                <div class="panel-block">
                    <span class="label">Provider</span>
                    <span class="value">{{Model.ProviderType}}</span>
                </div>
                <div class="panel-block">
                    <span class="label">Transactions</span>
                    <span class="value">{{Model.TotalTransactions}}</span>
                </div>
                <div class="panel-block">
                    <span class="label">To Reconcile</span>
                    <span class="value">{{Model.TransactionsToReconcile}}</span>
                </div>
                <div class="panel-block">
                    <span class="label">Ignored</span>
                    <span class="value">{{Model.IgnoredIssues}}</span>
                </div>
                <div class="panel-block">
                    <span class="label">Uploaded on</span>
                    <span class="value">{{Model.DateCreatedLocalFriendlyString}}</span>
                </div>
                <div class="panel-block">
                    <span class="label">Date Range</span>
                    <span class="value">
                        <app-daterangedisplay
                        [StartDate]="Model.StartDateLocalFriendlyString"
                        [EndDate]="Model.EndDateLocalFriendlyString"></app-daterangedisplay>
                    </span>
                </div>
            </article>
        </div>
        <div class="column is-half">
            <article class="panel"> 
            </article>
        </div>
    </div>
    <div class="columns">
        <div class="column is-fullwidth">
            <article class="panel" *ngIf="this.Model != null"> 
                <p class="panel-heading">Rows</p>
                <div class="panel-block is-fullwidth">
                    <app-datalistview style="width:100%;" [Columns]="Columns" [ShowMap]="false" [ApiUrl]="'accounting/reconciliation/statements/' + this.Model.Id + '/rows/searches'" [UseQueryParamStates]="true">
                    <app-filter-set>
                        <div app-search-filter></div>
                        <div app-subset-filter [Subsets]="Subsets"></div>
                        <div app-search-button></div>
                    </app-filter-set>
                    </app-datalistview>
                </div>
            </article>
        </div>
    </div>
</div>