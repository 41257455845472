import { forwardRef, Component, EventEmitter, Input, OnInit, Output, Self, ViewChild } from '@angular/core';
import { MediaService } from 'src/app/Services/media.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { Busyable } from 'src/app/shared/editors/busyable';

@Component({
  selector: 'app-mediaupload',
  templateUrl: './mediaupload.component.html',
  styleUrls: ['./mediaupload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MediaUploadComponent),
      multi: true,
    },
  ],
})
export class MediaUploadComponent extends Busyable implements OnInit, ControlValueAccessor {

  @Input()
  public MediaId: any;
  @Input()
  public base64string: SafeUrl = "";
  @Input()
  public Name: string = "";
  @Input()
  public Width: any = 400;
  @Input()
  public Height: any = 300;
  @Input()
  public Type: string = "";
  @Input()
  public Color: string = "";

  public FileType: string = "";

  @Input()
  public ImageCategory?: string;

  @Input()
  public IsPublic: boolean = false;

  @Output()
  public MediaIdChange = new EventEmitter<number | null>();

  constructor(public mediaService: MediaService, private domSanitizer: DomSanitizer) {
    super();
  }

  public writeValue(val: string): void {
    if(val != null){
      this.MediaId = val;
      this.FileType = "image/" + this.Type.replace(".", "") + (this.Type == ".svg" ? "+xml" : "");
      this.GetMediaBase64();
    }
  }
  public onChange = (val: string | null) => { };
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public onTouched = () => { };
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public GetMediaBase64() {
    this.mediaService.GetBase64Media(this.MediaId).subscribe(result => {
      var string = result.Base64Prefix + result.Base64String;
      this.base64string = this.domSanitizer.bypassSecurityTrustUrl(string);
      this.Name = result.Name;
      this.StopLoading();
    });
  }

  ngOnInit(): void {
  }

  public UploadImage(image: any, imageCategory?: string) {
    this.Loading();
    var file = image.target.files[0] as File;
    this.FileType = file.type;
    var mediaService: MediaService = this.mediaService;
    var med = this.MediaIdChange;
    var pub = this.IsPublic;

    var fileByteArray: number[] = [];
    var reader = new FileReader();
    reader.onload = function () {
      var arrayBuffer = this.result as ArrayBuffer,
        array = new Uint8Array(arrayBuffer)
      for (var i = 0; i < array.length; i++) {
        fileByteArray.push(array[i]);
      }
      mediaService.UploadMedia(fileByteArray, file.name, imageCategory, pub).subscribe(result => {
        med.emit(result);
      });
    }
    var x = reader.readAsArrayBuffer(image.target.files[0]);
  };

}
