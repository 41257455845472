import { MeshConstants } from "src/app/util/mesh-constants";
import * as THREE from "three";
import { Mesh, Scene, Vector2, Vector3 } from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";

export class LabelMesh extends THREE.Mesh {

    constructor(scene: Scene, meshToLabel: THREE.Mesh, label: string) {
        super();
            var camera = new THREE.PerspectiveCamera();
            const box = new THREE.Box3().setFromObject(scene);
            // load the object
            const size = box.getSize(new THREE.Vector3())
            
            var l = new FontLoader();

            l.load('https://threejsfundamentals.org/threejs/resources/threejs/fonts/helvetiker_regular.typeface.json', function (font) {
            // TextGeometry(String, Object)
            const textObj = new TextGeometry(label, {
                font: font,
                size: 20,
                height: 0.02,
                curveSegments: 12,
                bevelEnabled: false,
            });
            const material = new THREE.MeshBasicMaterial({color: 'black'});
            const mesh = new THREE.Mesh(textObj, material);
            mesh.scale.set(0.0001, 0.0001, 0.0001);

            mesh.position.set(meshToLabel.position.x, meshToLabel.position.y, meshToLabel.position.z);
            const box3 = new THREE.Box3().setFromObject(mesh);
            const vector = new Vector3();
            let size = box3.getSize(vector);
           
            mesh.translateZ(0.02);
            mesh.rotateX(Math.PI/2);
            scene.add(mesh);

            const geometry = new THREE.BoxGeometry(size.x, size.y, size.z);
            const newMat = new THREE.MeshBasicMaterial( {color: 'white'} );
            const cube = new THREE.Mesh( geometry, newMat);
            cube.position.set(mesh.position.x, mesh.position.y, mesh.position.z);
            cube.rotateX(Math.PI/2);
            cube.translateZ(size.z - 0.0001);
            cube.scale.set(1.2, 2, 1);
            scene.add( cube );


            mesh.translateX(-0.5 * size.x);
            mesh.translateY(-0.5 * size.y);


            //cube.position.x = obj.LocationPoints[0] * scale.x + offset.x - (size.x * MeshConstants.scaleFactor) + 0.001;
            //cube.position.y = -1 * ((obj.LocationPoints[1] * scale.y + offset.y)) + 0.000011;
            //cube.position.z = MeshConstants.floorThickness + 0.0041;
        });
    }
}
