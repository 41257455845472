import { MeshConstants } from "src/app/util/mesh-constants";
import * as THREE from "three";
import { Mesh, Scene, Vector2, Vector3 } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DeviceBox } from "./devicebox";
import { Geo } from "src/app/util/geo";

export class TreeMesh extends THREE.Mesh {
    constructor(scene: Scene, obj: any, offset: Vector3, scale: Vector2, objectType: string) {
      super();
      this.type = objectType;
      let self = this;
      // load the object
      let loader = new GLTFLoader();
      if (objectType == null || objectType == '') {
        return;
      }

      let factor = MeshConstants.scaleFactor;

      let minX = Number.MAX_VALUE;
        let minY = Number.MAX_VALUE;
        let maxX = Number.MIN_VALUE;
        let maxY = Number.MIN_VALUE;
  
        obj.PolygonPoints.forEach((point: any) => {
          minX = Math.min(minX, point[0]);
          minY = Math.min(minY, point[1]);
          maxX = Math.max(maxX, point[0]);
          maxY = Math.max(maxY, point[1]);
        });
  
        let distanceBetweenTrees = 0;
        if(objectType == 'talltree'){
            distanceBetweenTrees = MeshConstants.talltreesdistance;
        }
        else if (objectType == 'smalltree'){
            distanceBetweenTrees = MeshConstants.smalltreesdistance;
        }
  
        // Iterate over the bounding box and add trees
        for (let x = minX; x <= maxX; x += distanceBetweenTrees) {
            for (let y = minY; y <= maxY; y += distanceBetweenTrees) {
                // Check if the coordinate lies inside the polygon
                if (Geo.IsPointInsidePolygon(x, y, obj.PolygonPoints)) {
    
                    loader.load('/assets/3d-models/' + objectType + '.glb', (gltf: any) => {
                        // adjust the size of the object to add into the scene
                                gltf.scene.position.x = x * scale.x + offset.x;
                                gltf.scene.position.y = -1 * (y * scale.y + offset.y);
                                gltf.scene.position.z = MeshConstants.floorThickness;
                                gltf.scene.rotation.x = Math.PI / 2;
                                
                                const rotationAngle = Math.random() * Math.PI * 2; // Generate a random rotation angle between 0 and 2pi
                                gltf.scene.rotation.y = rotationAngle;

                                gltf.scene.scale.set(factor, factor, factor)
                                scene.add(gltf.scene);

                }, undefined, function (err: any) {
                    console.log(err);
                });
                }
            }   
        }
    }
  }
