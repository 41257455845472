import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, Self, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { AddUserComponent } from 'src/app/features/users/users/modals/add-user/add-user.component';

@Component({
  selector: 'app-userselector',
  templateUrl: './userselector.component.html',
  styleUrls: ['./userselector.component.scss']
})
export class UserselectorComponent implements OnInit {

  @Input()
  public UserId: string | null = null;
  @Output()
  public UserIdChange = new EventEmitter<string | null>();
  @Output()
  public UserCleared = new EventEmitter<string | null>();

  public InitialString: string = "";
  @Input()
  public User: any = { Email: "", FirstName: "", LastName: "" };

  @Input()
  public AllowCreateUser: boolean = false;

  public SearchResults: any[] = [];
  public SearchOn: string = "Name";
  public IsLoading: boolean = false;

  constructor(private apiService: ApiServiceBase, private modalService: SimpleModalService) {
  }
  ngOnInit(): void {
  }

  public ItemSelected(item: any) {
    if (item && item != null && item.Id) {
      this.User = { Id: item.Id, FirstName: item.FirstName, LastName: item.LastName, Email: item.Email };
      this.UserId = item.Id;
      this.UserIdChange.emit(this.User);
    }
  }

  public SearchChanged(val: string) {
    console.log("User Selector search event: '" + val + "'");
    if (val == null || val == "") return;
    this.User = null;
    this.IsLoading = true;
    this.apiService.Post<any>("users/searches", { PageNumber: 1, PageSize: 8, SearchTerm: val }).then(result => {
      this.SearchResults = result.Results;
      this.SearchResults.map(result => result.Name = `${result.FirstName} ${result.LastName} ${result.Email}`);
      this.IsLoading = false;
    });
  }

  public ClearUser() {
    this.UserCleared.emit(this.UserId);
    this.UserId = null;
  }
  public Cleared() {
    this.UserId = null;
  }

  public KeyPress(evt: any) {
    if (evt.keyCode == 13) {
      evt.stopPropagation();
      console.log("intercepted enter key on user selector");
    }
  }

  public addUser() {
    this.modalService.addModal(AddUserComponent).subscribe(result => {
      if(result != null)
        result.Id = result.UserId;
        this.ItemSelected(result);
    })
  }

  public InputKeyPress(evt: any) {

  }
}
