import { Component, OnInit } from '@angular/core';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { FeaturesService } from 'src/app/Services/features.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {

  public CurrentMenuPath : string = "?";
  public CurrentRoute : string = "";
  public MenuItems : any = [];

  constructor(private router : Router, private featuresService : FeaturesService) { 
    this.featuresService.MenuChanged.subscribe(() =>{
      console.log("SideMenu component received event: MenuChanged. Updating menu DOM elements");
      this.UpdateMenu();
    });
    router.events.subscribe((event) =>{
      if(event instanceof NavigationEnd){
        let activation = event as NavigationEnd;
        if(activation.url != this.CurrentRoute){
          console.log("Side menu received NavigationEnd for route " + activation.url);
          this.CurrentRoute = activation.url;
          this.UpdateMenu();
        }
      }
    });
  }

  ngOnInit(): void {
    //this.UpdateMenu();
  }
  
  private UpdateMenu(){
    this.CurrentMenuPath = (this.router.url.startsWith("/") ? this.router.url.substring(1) : this.router.url).split("/")[0];
    console.log("Side menu fetching items for path " + this.CurrentMenuPath);
    this.MenuItems = this.featuresService.GetMenuChildren(this.CurrentMenuPath);
    if(this.MenuItems != null)
      this.MenuItems = this.MenuItems.sort((n1: any,n2: any)=> n1.DisplayIndex > n2.DisplayIndex);
    console.log("Side menu found " + this.MenuItems?.length + " menu items");
  }
}
