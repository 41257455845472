import { Component, OnInit } from '@angular/core';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { StructureViewerLayer } from 'src/app/shared/structureviewermaps/structureviewerlayers';

@Component({
  selector: 'app-legacysigns',
  templateUrl: './legacysigns.component.html',
  styleUrls: ['./legacysigns.component.scss']
})
export class LegacysignsComponent implements OnInit {

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("HID", "ChipId"),
    new Datalistviewcolumn("Location", "LocationString"),
    new Datalistviewcolumn("Last Check In", "LastCheckinString"),
    new Datalistviewcolumn("Mode", "Mode"),
    new Datalistviewcolumn("Radio Channel", "RadioChannel"),
  ];

  public StructureViewerLayers: StructureViewerLayer[] = [new StructureViewerLayer("ParkingLot", null, false, true), new StructureViewerLayer("ParkingLevel"), new StructureViewerLayer("SignConfiguration", "Signs") ]

  constructor() { }

  ngOnInit(): void {
  }

}
