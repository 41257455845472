<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Select Parking Lots</p>
      </header>
      <section class="modal-card-body" style="min-width: 800px;">
       
            <app-datalistview [Columns]="Columns" 
            ApiUrl="infrastructure/parkinglots/searches" 
            [PageSize]="10"
            [ShowPaging]="true"
            [AllowSelect]="true"
            (ItemSelected)="ItemSelected($event)">
            <app-filter-set>
              <div app-search-filter></div>
              <div app-search-button></div>
            </app-filter-set>
          </app-datalistview>
        
      </section>
      <footer class="modal-card-foot">
        <button class="button is-danger" (click)="close()">Cancel</button>
      </footer>
    </div>
  </div>