import { EventEmitter, Injectable, Output } from '@angular/core';
import { LoginService } from '../auth/login.service';
import { ApiServiceBase } from './api.service.base';
import { ApiService } from './api.service';
import { Toast, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class ServiceBusService {

  @Output()
  public PermissionSetChanged = new EventEmitter<any>();
  private Result: any[] = [];

  constructor(private apiService: ApiServiceBase, private loginService: LoginService, private toast: ToastrService) {
  }

  public PowerCycle(DeviceId: string, voltageline: string) {
    this.apiService.Post<any>("infrastructure/localcontrollers/" + DeviceId + "/powercycle/" + voltageline, {}).then(result => {
      this.Result = result;
      if (this.Result) {
        this.toast.success('PowerCycle' + voltageline + 'Command for device was successful');
      }
    });
  }

  public ResetDatabase(DeviceId: string) {
    this.apiService.Post<any>("infrastructure/localcontrollers/" + DeviceId + "/databasereset", {}).then(result => {
      this.Result = result;
      if (this.Result) {
        this.toast.success('Reset Database command for device was sucessful');
      }
    });
  }
}
