import { Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { Busyable } from 'src/app/shared/editors/busyable';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { Geo } from 'src/app/util/geo';
import { v4 as uuidv4 } from 'uuid';
import { ParkinglotselectorComponent } from 'src/app/features/parking/parking/parkinglots/modals/parkinglotselector/parkinglotselector.component';
import { AddcontrolledareaterminalComponent } from './addcontrolledareaterminal/addcontrolledareaterminal.component';
import { EditcontrolledareaaccessmethodComponent } from './editcontrolledareaaccessmethod/editcontrolledareaaccessmethod.component';
import { EditcontrolledareabarrierComponent } from './editcontrolledareabarrier/editcontrolledareabarrier.component';
import { EditcontrolledareatrafficlightComponent } from './editcontrolledareatrafficlight/editcontrolledareatrafficlight.component';
import { EditcontrolledareacameraComponent } from './editcontrolledareacamera/editcontrolledareacamera.component';
import { EditcontrolledareadisplayComponent } from './editcontrolledareadisplay/editcontrolledareadisplay.component';
import { EditcontrolledareagateComponent } from './editcontrolledareagate/editcontrolledareagate.component';
import { EditcontrolledarealaneComponent } from './editcontrolledarealane/editcontrolledarealane.component';
import { EditcontrolledareapaymentterminalComponent } from './editcontrolledareapaymentterminal/editcontrolledareapaymentterminal.component';
import { EditcontrolledareaterminalComponent } from './editcontrolledareaterminal/editcontrolledareaterminal.component';
import { EditcontrolledareacellrouterComponent } from './editcontrolledareacellrouter/editcontrolledareacellrouter.component';
import { EditcontrolledareauserinputComponent } from './editcontrolledareauserinputdevice/editcontrolledareauserinput/editcontrolledareauserinput.component';

@Component({
  selector: 'app-editcontrolledarea',
  templateUrl: './editcontrolledarea.component.html',
  styleUrls: ['./editcontrolledarea.component.scss']
})
export class EditcontrolledareaComponent extends ModelEditor {

  public MapTarget: any | null = null;
  public MapTargetMode: string = "polygon";
  public HighlightTarget: any = {};
  public GateBackgroundPolygons: any[] = []; //backgruond items for device edit map 
  public GateBackgroundMarkers: any[] = []; //backgruond items for device edit map for currently selected gate
  public AreaBackgroundPolygons: any[] = []; //background items for main area edit map
  public AreaBackgroundMarkers: any[] = []; //background icons for main area edit map

  constructor(private modalService: SimpleModalService, private apiService: ApiServiceBase, private organizationsService: OrganizationsService, private injector: Injector) {
    super("infrastructure/controlledareas", injector);
  }
  public override DefaultModel() {
    return { Name: "New Area", SessionExitTimeMinutes: 10, Controllers: [], AccessMethods: [], ParkingLots: [], Gates: [] }
  }
  public override AfterModelLoaded(): void {
    //  let gates = this.FormArray(this.Form, "Gates");
    //  if(gates.controls.length > 0){
    //    this.SetMapTarget(null, null, gates.controls[0]);
    //  }

    this.Form.markAsPristine();
  }
  public override BeforeSave(): boolean | void {

  }

  public override FormLabels(): any {
    return {
      Name: 'Area Name',
      SessionExitTimeMinutes: 'Session Grace Period'
    }
  }

  public override Validators(): any {
    return {
      "Name": [Validators.required, Validators.minLength(2)],
      "SessionExitTimeMinutes": [Validators.required],
      "AccessMethods.RateSetId": [Validators.required],
      "AccessMethods.ClassName": [Validators.required]
    };
  }


  public GetAccessMethodText(className: string) {
    switch (className) {
      case "ControlledAreaAccessMethodPublic":
        return "Public";
      case "ControlledAreaAccessMethodWhiteList":
        return "Access List Only"
    }
    return "";
  }

  public GetAccessListDescription(control: AbstractControl): string {

    if (control.get('ClassName')?.value === "ControlledAreaAccessMethodPublic") {
      return "Public Access";
    } else {
      return control.get('PlateAccessListName')?.value;
    }
  }

  public MoveUp(index: number) {
    this.MoveAccessMethodSequence(index);

  }

  public MoveDown(index: number) {
    this.MoveAccessMethodSequence(index + 1);
  }

  // always move up
  private MoveAccessMethodSequence(index: number) {
    let accessMethods = this.FormArray(this.Form, 'AccessMethods').controls;
    if (index <= 0) {
      // this is the top element, return
      return;
    }
    if (index >= accessMethods.length) {
      // try to move the last one down
      return;
    }

    let am = accessMethods[index];
    accessMethods.splice(index, 1);
    accessMethods.splice(index - 1, 0, am);
    accessMethods.forEach((element, index) => {
      element.get('Sequence')?.setValue(index);
    });
  }

  public AddGate() {
    this.organizationsService.GetOrganizationDefaultPolygon().then(x => {
      let g = { Id: "", Name: "New Gate", 
      Lanes: [], PolygonPoints: x};
      this.organizationsService.GetOrganizationDefaultPolygon().then(x => g.PolygonPoints = x)
      this.EditGate(this.AddToFormArray(this.Form.get("Gates") as UntypedFormArray, g, "Gates"));
    })
  }
  public EditGate(fg: any) {
    this.modalService.addModal(EditcontrolledareagateComponent, { Form: fg })
      .subscribe((result) => {
        this.RefreshAreaBackgroundItems();
      });
  }
  public RemoveGate(index: number) {
    this.FormArray(this.Form, "Gates").removeAt(index);
  }

  public AddLane(gate: any) {
    let l = { Id: '', Name: "New Lane", WorkflowId: null, LocalControllerId: null, PolygonPoints: Geo.NewInnerPolygon(gate.get('GeoPolygonPoints').value, 0.0005), Cameras: [], Barriers: [], Displays: [], TrafficLights: [], PaymentTerminals: [], AllowedDirections:null };
    this.EditLane(this.AddToFormArray(gate.get('Lanes') as UntypedFormArray, l, "Gates.Lanes"), gate);
  }
  public EditLane(lane: any, gate: any) {
    let editingLane = lane;
    let allControllers = this.FormArray(this.Form, "Controllers").controls;
    this.modalService.addModal(EditcontrolledarealaneComponent, { Form: lane, Gate: gate, Controllers: allControllers })
      .subscribe((result) => {
        if (result != null) {
        }
      });
  }
  public RemoveLane(gate: AbstractControl, index: number) {
    this.FormArray(gate, "Lanes").removeAt(index);
  }

  public EditCellRouter(cellRouter: any){
    this.modalService.addModal(EditcontrolledareacellrouterComponent, {Form: cellRouter})
    .subscribe(result =>{
      this.RefreshAreaBackgroundItems();
    })
  }

  public AddCellRouter(lane: any){
    let c = { Id: uuidv4(), ClassName: "CellRouter", DeviceStatus:"New", Name:"New Cell Router", SerialNumber:"",Imei:"",Sim1Id:"", Sim2Id:"" };
    let fg = this.AddToFormArray(lane.get('CellRouters') as UntypedFormArray, c, "Gates.Lanes.CellRouters");

    this.EditCellRouter(fg);
  }

  public RemoveCellRouter(lane: AbstractControl, index: number){
    this.RemoveFromFormArray(lane.get('CellRouters') as UntypedFormArray, index);
  }

  public AddCamera(gate: any, lane: any) {
    let c = { Id: uuidv4(), Type: "dahua", LocalAddress: "192.168.1.110", LocalPort: 80, LocationPoints: Geo.PolygonCenterAsNumbers(lane.get('GeoPolygonPoints').value), ClassName: 'Camera', DeviceStatus: 'New', ActiveDirection: null, Username: null, Password: null, SerialNumber: null };
    let fg = this.AddToFormArray(lane.get('Cameras') as UntypedFormArray, c, "Gates.Lanes.Cameras");
    this.SetMapTarget(fg, lane, gate);
    this.EditCamera(fg);
  }
  public EditCamera(camera: any) {
    this.modalService.addModal(EditcontrolledareacameraComponent, { Form: camera })
      .subscribe((result) => {
        this.RefreshAreaBackgroundItems();
      });
  }
  public RemoveCamera(lane: AbstractControl, index: number) {
    this.RemoveFromFormArray(lane.get('Cameras') as UntypedFormArray, index);
  }

  public AddBarrier(lane: any) {
    let b = { Id: "", Type: "mo24", LocalAddress: "192.168.1.200", LocalPort: 80, LocationPoints: Geo.PolygonCenterAsNumbers(lane.get('GeoPolygonPoints').value), ClassName: 'Barrier', DeviceStatus: 'New', ActiveDirection: null, SerialNumber: null };
    this.EditBarrier(this.AddToFormArray(lane.get('Barriers') as UntypedFormArray, b, "Gates.Lanes.Barriers"))
  }
  public EditBarrier(barrier: any) {
    let editingBarrier = barrier;
    this.modalService.addModal(EditcontrolledareabarrierComponent, { Form: barrier })
      .subscribe((result) => {
        if (result != null) {
          this.RefreshAreaBackgroundItems();
        }
      });
  }
  public RemoveBarrier(lane: AbstractControl, index: number) {
    this.RemoveFromFormArray(lane.get('Barriers') as UntypedFormArray, index);
  }

  public AddTrafficLight(lane: any) {
    let t = {Id: "", Type:"k3sign", LocalAddress:"192.168.1.222", LocalPort: 5200, LocationPoints: Geo.PolygonCenterAsNumbers(lane.get('GeoPolygonPoints').value), ClassName: 'TrafficLight', DeviceStatus: 'New'};
    this.EditTrafficLight(this.AddToFormArray(lane.get('TrafficLights') as UntypedFormArray, t, "Gates.Lanes.TrafficLights"));
  }

  public EditTrafficLight(light: any) {
    let editingLight = light;
    this.modalService.addModal(EditcontrolledareatrafficlightComponent, { Form: light})
      .subscribe((result) => {
        if (result != null) {
          this.RefreshAreaBackgroundItems();
        }
      });
  }

  public RemoveTrafficLight(lane: AbstractControl, index: number) {
    this.RemoveFromFormArray(lane.get('TrafficLights') as UntypedFormArray, index);
  }

  public AddPaymentTerminal(lane: any) {
    let p = { Id: uuidv4(), LocalAddress: "127.0.0.1", LocalPort: 2008, ProviderType: "windcave", SerialNumber: null, ActiveDirection: null };
    this.EditPaymentTerminal(this.AddToFormArray(lane.get('PaymentTerminals') as UntypedFormArray, p, "Gates.Lanes.PaymentTerminals"))
  }
  public EditPaymentTerminal(terminal: any) {
    this.modalService.addModal(EditcontrolledareapaymentterminalComponent, { Form: terminal })
      .subscribe((result) => {
        if (result != null) {
        }
      });
  }
  
  public RemovePaymentTerminal(lane: AbstractControl, index: number) {
    this.RemoveFromFormArray(lane.get('PaymentTerminals') as UntypedFormArray, index);
  }

  public AddDisplay(lane: any) {
    let d = { Id: "", Type: "frogserver", LocalAddress: "127.0.0.1", LocalPort: 10165, SerialNumber: null, ActiveDirection: null };
    this.EditDisplay(this.AddToFormArray(lane.get('Displays') as UntypedFormArray, d, "Gates.Lanes.Displays"))
  }
  public EditDisplay(display: any) {
    let editingDisplay = display;
    this.modalService.addModal(EditcontrolledareadisplayComponent, { Form: display })
      .subscribe((result) => {
        if (result != null) {
        }
      });
  }
  public RemoveDisplay(lane: AbstractControl, index: number) {
    this.RemoveFromFormArray(lane.get('Displays') as UntypedFormArray, index);

  }

  public AddController(lane: any) {
    let targetLane = lane;
    this.modalService.addModal(AddcontrolledareaterminalComponent, { ExistingControllers: this.Form.get('Controllers'), Gates: this.Form.get('Gates'), Lane: lane })
      .subscribe((result) => {
        if (result != null) {
          if (result instanceof UntypedFormGroup) {
            //an existing controller was selected
            lane.get('LocalControllerId').setValue(result.get('Id')?.value);
          }
          else {
            lane.get('LocalControllerId').setValue(result.Id);
            this.AddToFormArray(this.Form.get('Controllers') as UntypedFormArray, result, "Controllers");
            this.RefreshAreaBackgroundItems();
          }
        }
      });
  }
  public EditController(id: any) {
    let allControllers = this.FormArray(this.Form, "Controllers").controls;
    let fg = allControllers.filter((x: any) => x.get('Id').value == id)[0];
    this.modalService.addModal(EditcontrolledareaterminalComponent, { Form: fg })
      .subscribe((result) => {
        if (result != null) {
          this.RefreshAreaBackgroundItems();
        }
      });
  }
  public RemoveController(lane: AbstractControl) {
    lane.get('LocalControllerId')?.setValue(null);
  }

  public GetController(id: string): any {
    let allControllers = this.FormArray(this.Form, "Controllers").controls;
    let fg = allControllers.filter((x: any) => x.get('Id').value == id);
    if (fg.length > 0)
      return fg[0];
    return null;
  }
  public GetControllerName(id: string): string {
    let controller = this.GetController(id);
    if (controller != null) {
      return controller.get("Name").value;
    }
    return "[Controller Not Found]";
  }

  public AddParkingLot() {
    this.modalService.addModal(ParkinglotselectorComponent, {})
      .subscribe((result) => {
        if (result != null) {
          this.apiService.Get("infrastructure/controlledareas/parkinglots/" + result.Id).then((lot: any) => {
            if (this.CheckFormArrayContains(this.FormArray(this.Form, "ParkingLots"), 'Id', lot.Id) == false) {
              this.AddToFormArray(this.FormArray(this.Form, "ParkingLots"), lot, "ParkingLots")
              this.RefreshAreaBackgroundItems();
            }
          })
        }
      });
  }
  public RemoveParkingLot(index: number) {
    //this.Model.ParkingLots.splice(index, 1);
    this.FormArray(this.Form, "ParkingLots").removeAt(index);
    this.RefreshAreaBackgroundItems();
    this.Form.markAsDirty();
  }

  public AddAccessMethod() {
    let p = { ClassName: "Public", RateSetId: null, RateSetName: '', Sequence: this.FormArray(this.Form, 'AccessMethods').controls.length };
    this.EditAccessMethod(this.AddToFormArray(this.FormArray(this.Form, "AccessMethods"), p, "AccessMethods"));

    /*this.modalService.addModal(EditcontrolledareaaccessmethodComponent, { Model: { Id: uuidv4(), ClassName: "ControlledAreaAccessMethodPublic" } })
      .subscribe((result) => {
        if (result != null) {
          this.AddToFormArray(this.FormArray(this.Form, "AccessMethods"), result, "AccessMethods")
        }
      });*/
  }
  public EditAccessMethod(model: any) {
    //let method = this.FormArray(this.Form, "AccessMethods").controls[index];
    this.modalService.addModal(EditcontrolledareaaccessmethodComponent, { Form: model })
      .subscribe((result) => {
        /*if (result != null) {
          method.setValue(result);
        }*/
      });
  }
  public RemoveAccessMethod(index: number) {
    this.FormArray(this.Form, "AccessMethods").removeAt(index);
    this.Form.markAsDirty();
  }

  public SetMapTarget(device: any, lane: any, gate: any) {
    this.GateBackgroundPolygons = [];
    if (device != null) {
      //a perhipheral was clicked
      this.MapTarget = device.get('LocationPoints');
      this.MapTargetMode = "marker";
      this.HighlightTarget = device;
      this.RefreshGateBackgroundItems(gate, device);
    }
    else if (lane != null) {
      //a lane was clicked
      this.MapTarget = lane.get('GeoPolygonPoints');
      this.MapTargetMode = "polygon";
      this.HighlightTarget = lane;
      this.RefreshGateBackgroundItems(gate, lane);
    }
    else if (gate != null) {
      //a gate was clicked
      this.HighlightTarget = gate;
      this.MapTarget = gate.get('GeoPolygonPoints');
      this.MapTargetMode = "polygon";
      this.RefreshGateBackgroundItems(gate, gate);
    }
  }
  public SetMapTargetControllerDevice(device: any, lane: any, gate: any) {
    this.SetMapTargetController(lane, gate);
    this.HighlightTarget = device;
  }
  public SetMapTargetController(lane: any, gate: any) {
    this.GateBackgroundPolygons = [lane, gate];
    if (lane.get('LocalControllerId')?.value != null) {
      let controller = this.GetController(lane.get('LocalControllerId')?.value);
      if (controller != null) {
        this.MapTarget = controller.get('LocationPoints');
        this.MapTargetMode = "marker";
        this.HighlightTarget = controller.get('Id')?.value;
      }
      this.RefreshGateBackgroundItems(gate, controller);
    }

  }
  public RefreshAreaBackgroundItems() {
    this.AreaBackgroundPolygons = [];
    this.AreaBackgroundMarkers = [];
    for (let p of this.FormArray(this.Form, "ParkingLots").controls) {
      this.AreaBackgroundPolygons.push(p);
      this.AreaBackgroundMarkers.push(p);
    }
    for (let g of this.FormArray(this.Form, "Gates").controls) {
      this.AreaBackgroundPolygons.push(g);
      for (let l of this.FormArray(g, "Lanes").controls) {
        this.AreaBackgroundPolygons.push(l);
      }
    }
  }
  public RefreshGateBackgroundItems(gate: any, exclude: any) {
    this.GateBackgroundPolygons = [];
    this.GateBackgroundMarkers = [];
    if (gate != exclude) {
      this.GateBackgroundPolygons.push(gate);
      this.GateBackgroundMarkers.push(gate);
    }
    for (let lane of this.FormArray(gate, "Lanes").controls) {
      if (lane != exclude) {
        this.GateBackgroundPolygons.push(lane);
        this.GateBackgroundMarkers.push(lane);
      }
      for (let camera of this.FormArray(lane, "Cameras").controls) {
        if (camera != exclude) {
          this.GateBackgroundPolygons.push(camera);
          this.GateBackgroundMarkers.push(camera);
        }
      }
      for (let barrier of this.FormArray(lane, "Barriers").controls) {
        if (barrier != exclude) {
          this.GateBackgroundPolygons.push(barrier);
          this.GateBackgroundMarkers.push(barrier);
        }
      }
      if (lane.get("LocalControllerId")?.value != null) {
        let controller = this.GetController(lane.get("LocalControllerId")?.value);
        if (controller != null && controller != exclude) {
          this.GateBackgroundPolygons.push(controller);
          this.GateBackgroundMarkers.push(controller);
        }
      }
    }
  }
  public MapMarkerClicked(formControl: UntypedFormControl) {
    let className = formControl.get('ClassName')?.value;
    switch (className) {
      case "Gate":
        this.SetMapTarget(null, null, formControl);
        break;
      case "Lane":
        this.SetMapTarget(null, formControl, formControl.parent?.parent);
        break;
      case "LocalController":
        //find the first lane that uses this controller
        let controllerId = formControl.get('Id')?.value;
        for (let g of this.FormArray(this.Form, 'Gates').controls) {
          for (let l of this.FormArray(g, 'Lanes').controls) {
            if (l.get('LocalControllerId')?.value == controllerId) {
              this.SetMapTargetController(l, g);
              break;
            }
          }
        }
        break;
      default:
        this.SetMapTarget(formControl, formControl.parent?.parent, formControl.parent?.parent?.parent?.parent);

    }

  }

  public AddUserInputDevice(lane: any) {
    let p = { Id: uuidv4(), ActiveDirection: "Both", Name: "Multi Scanner", Type: "MultiInputScanner", ClassName: "UserInput", DeviceStatus: "New" };
    this.EditUserInputDevice(this.AddToFormArray(lane.get('UserInputDevices') as UntypedFormArray, p, "Gates.Lanes.UserInputDevices"))
  }
  public EditUserInputDevice(device: any) {
    this.modalService.addModal(EditcontrolledareauserinputComponent, { Form: device })
      .subscribe((result) => {
        if (result != null) {
        }
      });
  }
  
  public RemoveUserInputDevice(lane: AbstractControl, index: number) {
    this.RemoveFromFormArray(lane.get('UserInputDevices') as UntypedFormArray, index);
  }

}
