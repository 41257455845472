<div style="width: 120px;">
    <div class="image-upload">
        <div *ngIf="(this.base64string != '')">
            <app-loader [Parent]="this"></app-loader>
            <img [src]="this.base64string" [alt]="this.Name" [width]="this.Width" [height]="this.Height" [ngStyle]="{'background-color': '#' + Color, 'padding':'10px'}" />
            <div class="container">
                <div class="button-wrap">
                  <label class="button" for="upload">Replace Image</label>
                  <input id="upload" type="file" class="file-upload" (change)="UploadImage($event, ImageCategory)" [accept]="this.Type">
                </div>
              </div>
        </div>
        <div *ngIf="(this.base64string == '')">
            <div class="container">
                <div class="button-wrap">
                  <label class="button" for="upload">Upload Image</label>
                  <input id="upload" type="file" class="file-upload" (change)="UploadImage($event, ImageCategory)" [accept]="this.Type">
                </div>
              </div>
        </div>
    </div>
</div>
