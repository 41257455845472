import { Component } from '@angular/core';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-signdisplayrulesets',
  templateUrl: './signdisplayrulesets.component.html',
  styleUrls: ['./signdisplayrulesets.component.scss']
})
export class SigndisplayrulesetsComponent {
  constructor() { }

  ngOnInit(): void {
  }
  public Subsets: string[] = [];

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Id", "Id"),
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Rules", "RuleCount")]
}
