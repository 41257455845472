<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Select a User</p>
      </header>
      <section class="modal-card-body" style="min-width: 800px;">
        <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="label">Search: </label>
            </div>
            <div class="field-body">
                <div class="field">
                    <div class="control">
                        <app-userselector formControlName="UserId" [User]="InitialUser" (UserIdChange)="UserIdChange($event)" class="newclass">
                        </app-userselector>
                    </div>
                </div>
            </div>  
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
              <label class="label">Notify Email: </label>
          </div>
          <div class="field-body">
              <div class="field">
                <div class="control">
                  <input type="checkbox" [(ngModel)]="Model.NotifyEmail" />
              </div>
              </div>
          </div>  
      </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-danger" (click)="close()">Cancel</button>
        <button class="button is-success" (click)="confirm()">OK</button>
      </footer>
    </div>
  </div>