import { LevelEditorComponent } from "../../leveleditor.component";
import { PolygonMapComponent } from "./PolygonMapComponent";

export class MapItem extends PolygonMapComponent{   
    public override ClassName: string = "MapItem";
    public override StrokeColor: string = "#FFFFFF";
    public override StrokeWidth: number = 2;
    public Type = "";
    public override zIndex: number = 110;
    
    constructor(leveleditorcomponent: LevelEditorComponent, canvas: any, formItem: any, parentFormItem: any, forceClone: boolean = false) {
        super(leveleditorcomponent, canvas, formItem, parentFormItem, forceClone);

        this.formItem = formItem;
        this.HatchSize = 5;
        this.PatternFill = true;

        if(this.Type == "Business"){
            this.ShowLabel = true;
        }

        if(this.Type == "GardenBed" || 
            this.Type == "GrassIsland"
        ){
            this.zIndex = 100;
        }

        if(this.Type == "Wall" || 
            this.Type == "Pillar" || 
            this.Type == "Bushes" || 
            this.Type == "TallTree" || 
            this.Type == "SmallTree" 
        ){
            this.zIndex = 120;
        }
        
        this.AddFabricItem();
    }

    public override SetFormValues(): void {
        this.PatternFillColor = '#' + this.formItem.get('FillColor')?.value;
        this.Type = this.formItem.get('Type')?.value;
        this.EntrySegment = this.formItem.get('EntrySegment')?.value;
        this.ExitSegment = this.formItem.get('ExitSegment')?.value;
    }

    public override Clone(formitem: any){
        return new MapItem(this.leveleditorcomponent, this.canvas, formitem, this.parentFormItem, true);
    }
}