<app-pageheader [Parent]="this">
    <add-button ButtonText="Upload" (AddObject)="uploadNewFile($event)"></add-button>
</app-pageheader>

<app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="accounting/reconciliation/statements/searches" [UseQueryParamStates]="true"
[AllowEdit]="true" EditUrl="/revenue/reconciliation/statements/:id" >
  <app-filter-set>
    <div app-search-filter></div>
    <div app-search-button></div>
  </app-filter-set>
</app-datalistview>