import { Component, Input, OnInit } from '@angular/core';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { ApiServiceBase } from 'src/app/Services/api.service.base';

@Component({
  selector: 'app-validationprofileusageserieschart',
  templateUrl: './validationprofileusageserieschart.component.html',
  styleUrls: ['./validationprofileusageserieschart.component.scss']
})
export class ValidationProfileUsageSeriesChart implements OnInit {
    @Input()
    public Title : string = "Validation Profile Usage";
    @Input()
    public Period : string = "thisweek";
    @Input()
    public ChartType : string = "line";
    @Input()
    public Height : number = 100;
    @Input()
    public ProfileId : number =  0;

    public Url : string= "";
  
    public ChartOptions : ChartOptions = {
      elements: {
        line: {
          tension: 0.2
        }
      },
      scales:{
        yAxes:{
          beginAtZero: true,
          ticks: {
            precision : 0
          }
        }
      },
      plugins: {
        legend : { display: false }
      }
    };
    public ChartData : ChartData = {
      datasets: [],
    };
    public ChartColors: string[] = [
      'green'
    ];
  
    constructor(private apiService : ApiServiceBase) { }
  
    ngOnInit(): void {

      this.Url = 'parking/validation/profiles/' + this.ProfileId + '/series/' + this.Period

      this.apiService.Get<any>(this.Url).then(result => {
        this.ChartData =  {
          datasets: [{
                  data: result.Values,
                  label: 'Validation Codes Used',
                  pointBackgroundColor: ['green'],
                  pointBorderColor: ['green'],
                  backgroundColor: ['green'],
                  borderColor: ['green']
          }],
          labels: result.Labels,
        };
      });
    }
  
  }
  