
<div class="">
    <button *ngIf="Mode == 'polygon' && Editable" class="button is-small is-light" (click)="ResetPolygon()">Reset Shape</button> 
    <button *ngIf="Mode == 'polygon' && Editable" class="button is-small is-light" (click)="RecenterPolygon()">Center Shape on Map</button> 
    <button *ngIf="Mode == 'marker' && Editable" class="button is-small is-light" (click)="RecenterMarker()">Center Marker on Map</button> 
</div>

<google-map [width]="MapWidth" [height]="MapHeight"  [zoom]="Zoom" [options]="MapOptions">
    <map-polygon *ngFor="let p of BackgroundPolygons" [paths]="p.paths" [options]="{strokeColor : '#555', fillColor : '#555'}" ></map-polygon>
    <map-marker *ngFor="let p of BackgroundMarkers" [position]="p.position" [label]="p.label" [icon]="p.icon"  [options]="{ clickable: true  }" (mapClick)="BackgroundMarkerClick(p)" ></map-marker>
    <map-polygon #polygon *ngIf="Polygon != null"  [paths]="Polygon" [options]="{ strokeColor: '#007237', fillColor: '#007237', editable: Editable, draggable: Editable }" (polygonMouseup)="EditPolygonMouseUp($event)" ></map-polygon>
    <map-marker #marker *ngIf="Marker != null" [position]="Marker" [icon]="MarkerIcon" [options]="{ draggable:  true }" (mapDragend)="EditMarkerDragged($event)" ></map-marker>
</google-map>